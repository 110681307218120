import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { DateTime } from 'luxon';
import { Contact, Company, Tag } from '@meetingflow/common/Api/data-contracts';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  CompaniesApiClient,
  ContactsApiClient,
  TagsApiClient,
  MembersApiClient,
} from '../../../../Services/NetworkCommon';
import {
  OrganizationCompanies,
  OrganizationContactsQuery,
  OrganizationMembersQuery,
  OrganizationOrganizerContactsQuery,
  OrganizationTagsQuery,
} from '../../../../QueryNames';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {
  DSFormControl,
  DSTextField,
  DSDatePicker,
  DSContactPicker,
  DSCompanyPicker,
  DSTagPicker,
  DSCheckbox,
  DSFormControlLabel,
  DSButton,
  DSMenuBarWithOverflow,
} from '../../DS';
import { StyledMeetingflowListFiltersContainer } from './DecisionSiteMeetingflowFilters.styles';
import {
  addArrayValue,
  collectToRecord,
  deleteValue,
  getArrayValues,
  getValue,
  removeAllArrayValues,
  removeArrayValue,
  setValue,
} from '../../../../Helpers/SearchParamHelpers';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { SearchOutlined } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import toast from 'react-hot-toast';

interface DecisionSiteMeetingflowFiltersProps {
  onFiltersChange?: (filters: string) => void;
  availableFilters?: {
    search: boolean;
    dateRange: boolean;
    attendees: boolean;
    companies: boolean;
    tags: boolean;
    hasRecording: boolean;
  };
}

export const DecisionSiteMeetingflowFilters: React.FC<
  DecisionSiteMeetingflowFiltersProps
> = ({ onFiltersChange, availableFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug } = useOrganization();

  const {
    data: companyData,
    isLoading: companyIsLoading,
    isRefetching: companyIsRefetching,
  } = useQuery(
    OrganizationCompanies(organizationSlug!, true),
    async () => {
      const token = await getAccessTokenSilently();
      return CompaniesApiClient.listCompanies(
        {
          organizationSlug: organizationSlug!,
          hasPlans: true,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!organizationSlug },
  );

  const {
    data: membersData,
    isLoading: membersLoading,
    isRefetching: membersRefetching,
  } = useQuery(
    OrganizationMembersQuery(organizationSlug!, true),
    async () => {
      const token = await getAccessTokenSilently();
      return MembersApiClient.listMembers(
        { organizationSlug: organizationSlug!, hasPlans: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!organizationSlug },
  );

  const {
    data: contactsData,
    isLoading: contactsLoading,
    isRefetching: contactsRefetching,
  } = useQuery(
    OrganizationContactsQuery(organizationSlug!, true),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        { organizationSlug: organizationSlug!, hasPlans: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!organizationSlug },
  );

  const {
    data: organizersData,
    isLoading: organizersLoading,
    isRefetching: organizersRefetching,
  } = useQuery(
    OrganizationOrganizerContactsQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        { organizationSlug: organizationSlug!, organizedPlans: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!organizationSlug },
  );

  const {
    data: tagsData,
    isLoading: tagsLoading,
    isRefetching: tagsRefetching,
  } = useQuery(
    OrganizationTagsQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return TagsApiClient.listTags(
        { organizationSlug: organizationSlug! },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug,
    },
  );

  const contacts = useMemo(() => {
    if (!contactsData?.data || !organizersData?.data) return [];
    return [...contactsData.data, ...organizersData.data];
  }, [contactsData?.data, organizersData?.data]);

  const companies = useMemo(() => {
    return companyData?.data ?? [];
  }, [companyData?.data]);

  const tags = useMemo(() => {
    return tagsData?.data ?? [];
  }, [tagsData?.data]);

  const [searchValue, setSearchValue] = useState(
    getValue(searchParams, 'q') ?? '',
  );
  const [debouncedSearch] = useDebounce(searchValue, 300);

  useEffect(() => {
    if (debouncedSearch !== getValue(searchParams, 'q')) {
      const newParams = new URLSearchParams(searchParams);
      if (debouncedSearch) {
        newParams.set('q', debouncedSearch);
      } else {
        newParams.delete('q');
      }
      setSearchParams(newParams, { replace: true });
    }
  }, [debouncedSearch, searchParams, setSearchParams]);

  useEffect(() => {
    onFiltersChange?.(searchParams.toString());
  }, [searchParams, onFiltersChange]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const selectedContacts = useMemo(() => {
    const contactIds = getArrayValues(searchParams, 'attendees');
    return contacts.filter((contact) =>
      contactIds.includes(contact.id.toString()),
    );
  }, [contacts, searchParams]);

  const selectedCompanies = useMemo(() => {
    const companyIds = getArrayValues(searchParams, 'companies');
    return companies.filter((company) =>
      companyIds.includes(company.id.toString()),
    );
  }, [companies, searchParams]);

  const selectedTags = useMemo(() => {
    const tagIds = getArrayValues(searchParams, 'tags');
    return tags.filter((tag) => tagIds.includes(tag.id.toString()));
  }, [tags, searchParams]);

  const handleDateRangeChange = (
    startOrEnd: 'start' | 'end',
    value: DateTime | null,
  ) => {
    // If clearing the date, just update params
    if (!value) {
      let newParams = new URLSearchParams(searchParams);
      newParams = deleteValue(newParams, `${startOrEnd}Date`);
      setSearchParams(newParams, { replace: true });
      return;
    }
    
    // Get current start and end dates
    const currentStartDate = getValue(searchParams, 'startDate') 
      ? DateTime.fromISO(getValue(searchParams, 'startDate') || '')
      : null;
    const currentEndDate = getValue(searchParams, 'endDate')
      ? DateTime.fromISO(getValue(searchParams, 'endDate') || '')
      : null;
    
    // Determine which date we're updating
    const newStartDate = startOrEnd === 'start' ? value : currentStartDate;
    const newEndDate = startOrEnd === 'end' ? value : currentEndDate;
    
    // Validate dates if both exist
    if (newStartDate && newEndDate && newEndDate < newStartDate) {
      toast.error('End date cannot be before start date');
      return;
    }
    
    // Only update params if date is valid
    let newParams = new URLSearchParams(searchParams);
    newParams = setValue(
      newParams,
      `${startOrEnd}Date`,
      value.toISO() ?? '',
    );
    setSearchParams(newParams, { replace: true });
  };

  const handleContactChange = (contacts: (Contact | null)[]) => {
    let newParams = new URLSearchParams(searchParams);
    newParams = removeAllArrayValues(newParams, 'attendees');
    contacts.forEach((contact) => {
      if (contact) {
        newParams = addArrayValue(
          newParams,
          'attendees',
          contact.id.toString(),
        );
      }
    });
    setSearchParams(newParams, { replace: true });
  };

  const handleCompanyChange = (companies: (Company | null)[]) => {
    let newParams = new URLSearchParams(searchParams);
    newParams = removeAllArrayValues(newParams, 'companies');
    companies.forEach((company) => {
      if (company) {
        newParams = addArrayValue(
          newParams,
          'companies',
          company.id.toString(),
        );
      }
    });
    setSearchParams(newParams, { replace: true });
  };

  const handleTagChange = (tags: Tag[]) => {
    let newParams = new URLSearchParams(searchParams);
    newParams = removeAllArrayValues(newParams, 'tags');
    tags.forEach((tag) => {
      newParams = addArrayValue(newParams, 'tags', tag.id.toString());
    });
    setSearchParams(newParams, { replace: true });
  };

  const handleCheckboxChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newParams = new URLSearchParams(searchParams);
      if (event.target.checked) {
        newParams.set(name, 'true');
      } else {
        newParams.delete(name);
      }
      setSearchParams(newParams, { replace: true });
    };

  return (
    <StyledMeetingflowListFiltersContainer>
      <DSMenuBarWithOverflow
        items={[
          availableFilters?.search && (
            <DSFormControl key="search" className="filter-item">
              <DSTextField
                size="small"
                label="Search"
                value={searchValue}
                onChange={handleSearchChange}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined fontSize="small" />
                      </InputAdornment>
                    ),
                  },
                }}
                fullWidth
              />
            </DSFormControl>
          ),

          availableFilters?.dateRange && (
            <div key="date-range" className="filter-item-pair">
              <LocalizationProvider key="dates" dateAdapter={AdapterLuxon}>
                <DSFormControl className="filter-item">
                  <DSDatePicker
                    size="xsmall"
                    label="Start Date"
                    value={
                      getValue(searchParams, 'startDate')
                        ? DateTime.fromISO(
                            getValue(searchParams, 'startDate') || '',
                          )
                        : null
                    }
                    onChange={(newValue) =>
                      handleDateRangeChange('start', newValue)
                    }
                  />
                </DSFormControl>

                <DSFormControl className="filter-item">
                  <DSDatePicker
                    size="xsmall"
                    label="End Date"
                    value={
                      getValue(searchParams, 'endDate')
                        ? DateTime.fromISO(
                            getValue(searchParams, 'endDate') || '',
                          )
                        : null
                    }
                    onChange={(newValue) =>
                      handleDateRangeChange('end', newValue)
                    }
                  />
                </DSFormControl>
              </LocalizationProvider>
            </div>
          ),

          availableFilters?.attendees && (
            <DSFormControl key="attendees" className="filter-item">
              <DSContactPicker
                label="Attendees"
                contacts={contacts}
                onChange={handleContactChange}
              />
            </DSFormControl>
          ),

          availableFilters?.companies && (
            <DSFormControl key="companies" className="filter-item">
              <DSCompanyPicker
                label="Companies"
                companies={companies}
                onChange={handleCompanyChange}
              />
            </DSFormControl>
          ),

          availableFilters?.tags && (
            <DSFormControl key="tags" className="filter-item">
              <DSTagPicker
                label="Tags"
                tags={tags}
                onChange={handleTagChange}
              />
            </DSFormControl>
          ),

          availableFilters?.hasRecording && (
            <DSFormControlLabel
              key="hasRecording"
              className="filter-item"
              control={
                <DSCheckbox
                  checked={getValue(searchParams, 'hasRecording') === 'true'}
                  onChange={handleCheckboxChange('hasRecording')}
                  size="small"
                />
              }
              label="Has Recording"
            />
          ),
        ].filter(Boolean)}
      />
    </StyledMeetingflowListFiltersContainer>
  );
};
