import React from 'react';
import { Avatar } from '@mui/material';
import { BasicContact } from '@meetingflow/common/Api/data-contracts';
import { avatarPropsFromString } from './DSUserAvatar';
import { stringToColor } from '@meetingflow/common/StringHelpers';
import { DSTooltip } from '../DS';

interface DealRoomContact {
  id: number;
  name?: string;
  email: string;
  avatarUrl?: string;
}

interface DSContactAvatarProps {
  contact: DealRoomContact | BasicContact;
  size?: number;
}

export const DSContactAvatar: React.FC<DSContactAvatarProps> = ({
  contact,
  size = 24,
}) => {
  const displayName = contact.name || contact.email;

  const avatarProps = {
    sx: {
      bgcolor: stringToColor(displayName),
      width: size,
      height: size,
      fontSize: `${size * 0.6}rem`,
    },
    children: contact.avatarUrl ? undefined : '', // No text if no avatar
  };

  return (
    <DSTooltip title={displayName}>
      <Avatar
        alt={displayName}
        src={contact.avatarUrl || undefined}
        sx={avatarProps.sx}
      >
        {avatarProps.children}
      </Avatar>
    </DSTooltip>
  );
};
