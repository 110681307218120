// Loom URL patterns
export const LOOM_URL_PATTERNS = [
  /(?:https?:\/\/)?(?:www\.)?loom\.com\/share\/([a-zA-Z0-9]+)/,
  /(?:https?:\/\/)?(?:www\.)?loom\.com\/embed\/([a-zA-Z0-9]+)/,
];

/**
 * Extracts the Loom video ID from various Loom URL formats
 * @param url The Loom URL to extract the video ID from
 * @returns The video ID if found, null otherwise
 */
export const getLoomVideoId = (url: string): string | null => {
  // If the input is already just a video ID, return it
  if (/^[a-zA-Z0-9]+$/.test(url)) {
    return url;
  }

  // Otherwise try to extract from URL
  for (const pattern of LOOM_URL_PATTERNS) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }
  return null;
};

/**
 * Gets the thumbnail URL for a Loom video
 * Note: This uses Loom's gifThumbnail API which returns an animated GIF preview
 * @param videoId The Loom video ID
 * @returns The thumbnail URL
 */
export const getLoomThumbnail = (videoId: string): string => {
  return `https://cdn.loom.com/sessions/thumbnails/${videoId}-with-play.gif`;
};

/**
 * Checks if a URL is a valid Loom video URL
 * @param url The URL to check
 * @returns True if the URL is a valid Loom video URL
 */
export const isLoomUrl = (url: string): boolean => {
  return LOOM_URL_PATTERNS.some(pattern => pattern.test(url));
};
