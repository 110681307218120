import React, { useMemo } from 'react';
import {
  getTheme,
  IconButton,
  mergeStyleSets,
  TextField,
} from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useNavigate } from 'react-router';
import useDeviceType from '../../../../Hooks/deviceDetection';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';

export interface ArtifactTitleProps {
  title: string | undefined;
  isEditingTitle: boolean;
  onStartEditTitle: () => void;
  onBlurTitle: () => Promise<void>;
  onChangeTitle: (value: string) => void;
  description: string | undefined;
  isEditingDescription: boolean;
  onStartEditDescription: () => void;
  onBlurDescription: () => Promise<void>;
  onChangeDescription: (value: string) => void;
  artifact: DealRoomArtifact;
}

/**
 * ArtifactTitle Component
 *
 * Displays and handles editing of the artifact title.
 * Supports both view and edit modes with proper keyboard interactions.
 */
export const ArtifactTitle: React.FC<ArtifactTitleProps> = ({
  title,
  isEditingTitle,
  onStartEditTitle,
  onBlurTitle,
  onChangeTitle,
  description,
  isEditingDescription,
  onStartEditDescription,
  onBlurDescription,
  onChangeDescription,
  artifact,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();

  const titleEditFieldStyles = useMemo(
    () => ({
      root: {
        height: '48px',
      },
      wrapper: {
        height: '48px',
      },
      fieldGroup: {
        height: '48px',
      },
      field: {
        fontSize: '40px',
        lineHeight: '48px',
        fontWeight: '400',
        color: DEALROOMS_COLORS.themePrimary,
        padding: '.5rem',
      },
    }),
    [],
  );

  const descriptionEditFieldStyles = useMemo(
    () => ({
      root: {
        height: '24px',
      },
      wrapper: {
        height: '24px',
      },
      fieldGroup: {
        height: '24px',
      },
      field: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: '400',
        color: DEALROOMS_COLORS.themeSecondary,
        padding: '.5rem',
      },
    }),
    [],
  );

  const useStyles = () => {
    const theme = getTheme();
    return mergeStyleSets({
      header: [
        theme.fonts.xLarge,
        {
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginBottom: '2rem',
          ...(isMobile ? { paddingLeft: '1.5rem' } : {}),

          '.title-field': {
            fontSize: '40px',
            fontWeight: '400',
            color: DEALROOMS_COLORS.themePrimary,
            overflowWrap: 'anywhere',

            ':hover': {
              cursor: 'pointer',
            },
          },
          '.description-field': {
            fontSize: '16px',
            fontWeight: '400',
            color: DEALROOMS_COLORS.themeSecondary,
            overflowWrap: 'anywhere',

            ':hover': {
              cursor: 'pointer',
            },
          },
        },
      ],
    });
  };

  const styles = useStyles();

  return (
    <div className={styles.header}>
      <div>
        {isEditingTitle ? (
          <TextField
            value={title}
            onChange={(e, newValue) => onChangeTitle(newValue || '')}
            onBlur={onBlurTitle}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await onBlurTitle();
              }
            }}
            autoFocus
            styles={titleEditFieldStyles}
          />
        ) : (
          <div className="title-field" onClick={onStartEditTitle}>
            {artifact.label || artifact.name}
          </div>
        )}
      </div>

      <div>
        {isEditingDescription ? (
          <TextField
            value={description}
            onChange={(e, newValue) => onChangeDescription(newValue || '')}
            onBlur={onBlurDescription}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await onBlurDescription();
              }
            }}
            autoFocus
            multiline
            styles={descriptionEditFieldStyles}
          />
        ) : (
          <div className="description-field" onClick={onStartEditDescription}>
            {artifact.description || <span style={{ fontStyle: 'italic' }}>Enter description here...</span>}
          </div>
        )}
      </div>
    </div>
  );
};
