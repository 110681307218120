import { Box, IconButton, styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const CarouselContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const LoadingContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  py: 2,
});

export const ContentContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
});

interface ScrollContentProps {
  isDragging: boolean;
  showLeftNav: boolean;
  showRightNav: boolean;
  showNavigation: boolean;
}

export const ScrollContent = styled(Box, {
  shouldForwardProp: (prop) =>
    !['isDragging', 'showLeftNav', 'showRightNav', 'showNavigation'].includes(
      prop as string,
    ),
})<ScrollContentProps>(
  ({ isDragging, showLeftNav, showRightNav, showNavigation }) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
    cursor: isDragging ? 'grabbing' : 'grab',
    marginLeft: showNavigation && showLeftNav ? '.5rem' : 0,
    marginRight: showNavigation && showRightNav ? '.5rem' : 0,
    transition: 'margin 0.15s ease-in-out',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    gap: '.5rem',
  }),
);

export const ItemContainer = styled(Box)(() => ({
  flex: '0 0 auto',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  gap: '.5rem',
  height: '100%',
}));

interface NavigationButtonProps {
  show: boolean;
}

export const NavigationButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'show',
})<NavigationButtonProps>(({ show }) => ({
  backgroundColor: DEALROOMS_COLORS.neutralLight,
  position: 'relative',
  flexShrink: 0,
  width: show ? '24px' : '0px',
  height: '24px',
  borderRadius: '50%',
  padding: show ? '4px' : 0,
  opacity: show ? 1 : 0,
  visibility: show ? 'visible' : 'hidden',
  transition: 'all 0.15s ease-in-out',
  pointerEvents: show ? 'auto' : 'none',
  overflow: 'hidden',
  margin: 0,
}));
