import { DrawerProps, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import { StyledDrawer, TRANSITION_DURATION } from './DSResponsiveDrawer.styles';

export interface DSResponsiveDrawerProps extends DrawerProps {
  /**
   * Width of the drawer in percentage. Default is 70%.
   */
  widthPercentage?: number;
  /**
   * Width of the drawer in percentage for mobile devices. Default is 90%.
   */
  mobileWidthPercentage?: number;
  /**
   * Minimum width of the drawer in pixels. Default is 500px.
   */
  minWidth?: number;
  /**
   * Minimum width of the drawer in pixels for mobile devices. Default is 300px.
   */
  mobileMinWidth?: number;
  /**
   * Maximum width of the drawer in pixels. Default is 800px.
   */
  maxWidth?: number;
  /**
   * Maximum width of the drawer in pixels for mobile devices. Default is none.
   */
  mobileMaxWidth?: number;
  /**
   * Padding for the drawer content. Passed directly to CSS.
   */
  padding?: string;
}

export const DSResponsiveDrawer: React.FC<DSResponsiveDrawerProps> = ({
  children,
  widthPercentage,
  mobileWidthPercentage,
  minWidth,
  mobileMinWidth,
  maxWidth,
  mobileMaxWidth,
  padding,
  open,
  ...rest
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledDrawer
      isMobile={isMobile}
      widthPercentage={widthPercentage}
      mobileWidthPercentage={mobileWidthPercentage}
      minWidth={minWidth}
      mobileMinWidth={mobileMinWidth}
      maxWidth={maxWidth}
      mobileMaxWidth={mobileMaxWidth}
      padding={padding}
      transitionDuration={TRANSITION_DURATION}
      variant="temporary"
      open={open}
      {...rest}
    >
      {children}
    </StyledDrawer>
  );
};

export default DSResponsiveDrawer;
