import React, { useCallback } from 'react';
import { matchPath } from 'react-router';
import { DSTextField } from '../DS';
import DealRoomAsyncCommandBarButton from '../Components/DealRoomButton';
import { AddArtifactIconProps } from '../../../utils/iconProps';
import { Stack, Box, Typography } from '@mui/material';
import { getStyles } from './UploadDealRoomAttachmentsDialog.styles';
import { DraggableFileUploaderContainer } from '../DraggableFileUploaderContainer';
import toast from 'react-hot-toast';
import { pickFile } from '../../../Helpers/FileHelpers';
import { isUrl } from '../../../Helpers/URLHelpers';

interface CommonUploadArtifactsProps {
  title: string;
  setTitle: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
}

interface CommonUploadArtifactsURLProps {
  url: string;
  setUrl: (value: string) => void;
  title: string;
  setTitle: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
}

interface CommonUploadArtifactsFileProps {
  actionItemId: number | null;
  selectedFile: File | null;
  setSelectedFile: (value: File | null) => void;
  title: string;
  setTitle: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
}

export const CommonUploadArtifactsFields = ({
  title,
  setTitle,
  description,
  setDescription,
}: CommonUploadArtifactsProps) => {
  return (
    <>
      <DSTextField
        value={title}
        label="Title"
        placeholder="Enter Artifact Title"
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
      />
      <DSTextField
        value={description}
        label="Description"
        multiline
        minRows={3}
        placeholder="Enter Artifact Description"
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
      />
    </>
  );
};

export const CommonUploadArtifactsURL = ({
  url,
  setUrl,
  title,
  setTitle,
  description,
  setDescription,
}: CommonUploadArtifactsURLProps) => {
  return (
    <>
      <DSTextField
        value={url}
        label="URL"
        placeholder="Enter Artifact URL"
        onChange={(e) => setUrl(e.target.value)}
        fullWidth
        error={!!(url && !isUrl(url))}
      />
      <CommonUploadArtifactsFields
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
      />
    </>
  );
};

export const CommonUploadArtifactsFile = ({
  actionItemId,
  selectedFile,
  setSelectedFile,
  title,
  setTitle,
  description,
  setDescription,
}: CommonUploadArtifactsFileProps) => {
  const styles = getStyles();

  const handleSelectFile = useCallback(async () => {
    try {
      const file = await pickFile({});
      if (!file) {
        return;
      }
      const hasExtension = file.name.lastIndexOf('.') > 0;
      if (!hasExtension) {
        toast.error(
          'Please select a file with an extension, like .txt or .pdf',
        );
        return;
      }
      setSelectedFile(file);
    } catch (err) {}
  }, [setSelectedFile]);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const droppedFiles = event?.dataTransfer?.files;
      if (droppedFiles && droppedFiles.length > 0) {
        const currentFile = Array.from(droppedFiles)[0];
        const hasExtension = currentFile?.name?.lastIndexOf('.') > 0;
        if (!hasExtension) {
          toast.error(
            'Please select a file with an extension, like .txt or .pdf',
          );
          return;
        }
        setSelectedFile(currentFile);
      }
    },
    [setSelectedFile],
  );

  return (
    <>
      <DraggableFileUploaderContainer handleOnDrop={handleDrop}>
        <DealRoomAsyncCommandBarButton
          customClasses={styles.selectFileButton}
          onClick={handleSelectFile}
          text={selectedFile ? 'Change File' : 'Select File'}
          buttonStyleType="DEAL_ROOM"
          iconProps={AddArtifactIconProps}
        />
        <div className={styles.dropContent}>
          {!selectedFile && (
            <Typography variant="body1" className={styles.uploadSectionMessage}>
              Select or drop a file to upload
              {actionItemId ? ' and associate with the Task' : ''}
            </Typography>
          )}
          {selectedFile && (
            <Typography
              variant="body1"
              className={styles.uploadSectionFileName}
            >
              {selectedFile.name || 'N/A'}
            </Typography>
          )}
        </div>
      </DraggableFileUploaderContainer>
      <Box sx={{ mt: 2 }}>
        <Stack spacing={2}>
          <CommonUploadArtifactsFields
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
          />
        </Stack>
      </Box>
    </>
  );
};

export const meetingflowUrlValidator = (
  url: string,
  organizationSlug: string,
) => {
  // Example meetingflow URL: https://app.meetingflow.com/organization/example-co/plan/5aef782f-0fd9-4398-9b41-6f7f48eea422
  try {
    const match = matchPath(
      '/organization/:organizationSlug/plan/:meetingPlanId',
      new URL(url).pathname,
    );
    if (!match) {
      return {
        isMeetingflowUrl: false,
        isMeetingflowUrlInCurrentOrg: false,
      };
    }
    const { organizationSlug: urlOrganizationSlug } = match.params;
    return {
      isMeetingflowUrl: true,
      isMeetingflowUrlInCurrentOrg: urlOrganizationSlug === organizationSlug,
    };
  } catch {
    return { isMeetingflowUrl: false, isMeetingflowUrlInCurrentOrg: false };
  }
};
