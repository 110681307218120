import React from 'react';
import { Text } from '@fluentui/react';
import { dsConfigurationMutualPlanStyles } from './styles';
import { DSResponsiveModal } from '../../../DS';

interface DSConfigurationMutualPlanDeleteMilestoneDialogProps {
  isOpen: boolean;
  milestoneName: string;
  onConfirm: () => void;
  onDismiss: () => void;
}

export const DSConfigurationMutualPlanDeleteMilestoneDialog: React.FC<
  DSConfigurationMutualPlanDeleteMilestoneDialogProps
> = ({ isOpen, milestoneName, onConfirm, onDismiss }) => {
  const styles = dsConfigurationMutualPlanStyles;

  return (
    // Render a confirmation dialog for milestone deletion
    <DSResponsiveModal
      open={isOpen}
      onDismiss={onDismiss}
      title="Delete Milestone"
      subText={`Are you sure you want to delete the milestone "${milestoneName}"?`}
      maxWidth="sm"
      primaryFooterButtons={[
        {
          children: 'Delete',
          onClick: onConfirm,
        },
      ]}
      secondaryFooterButtons={[
        {
          children: 'Cancel',
          onClick: onDismiss,
        },
      ]}
    >
      <div className={styles.warning}>
        <Text className={styles.warningText}>
          This action cannot be undone. The milestone and its associated tasks
          and comments will be permanently deleted.
        </Text>
      </div>
    </DSResponsiveModal>
  );
};
