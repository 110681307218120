import { useAuth0 } from '@auth0/auth0-react';
import {
  DefaultButton,
  FontIcon,
  FontWeights,
  ITooltipHostStyles,
  NeutralColors,
  PrimaryButton,
  TextField,
  TooltipHost,
  mergeStyles,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { MeetingflowSummaryType } from '@meetingflow/common/Api/data-contracts';
import {
  isClassifiedMeetingflowMessage,
  isErrorMessage,
  isThoughtMessage,
  isTokenMessage,
} from '@meetingflow/common/AssistantHelpers';
import {
  guessNameFromEmail,
  titleCase,
} from '@meetingflow/common/StringHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { isArray, isString } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Markdown from 'react-markdown';
import {
  Descendant as SlateDescendant,
  Element as SlateElement,
  Text as SlateText,
} from 'slate';
import { htmlToSlateDescendants } from '../../../Helpers/SlateHelpers';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { ApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { AsyncPrimaryButton } from '../../HOC/AsyncButton';
import { BaseModal } from './BaseModal';

const DEBUG = import.meta.env.DEV;

export type SummarizeMeetingflowDialogProps = {
  summaryType: MeetingflowSummaryType | 'CUSTOM';
  prompt?: string;
  title: string;
  organizationSlug: string;
  meetingflowId: string;
  onAccept: (result: {
    meetingflowType: string;
    summary: string;
    summaryHtml?: string;
    summarySlateNodes?: SlateDescendant[];
  }) => void;
  onDismiss: (reason?: unknown) => void;
};

export const SummarizeMeetingflowDialog = ({
  summaryType,
  prompt,
  title,
  organizationSlug,
  meetingflowId,
  onAccept,
  onDismiss,
}: SummarizeMeetingflowDialogProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const contentRef = useRef<HTMLDivElement>(null);

  const userName = useMemo(
    () =>
      (
        user!.name ||
        guessNameFromEmail(user!.name) ||
        titleCase(user!.email!.split('@')[0])
      )?.split(/\b/g)?.[0],
    [user],
  );

  const abortController = useRef<AbortController>();

  const [
    isGenerating,
    { setTrue: setGeneratingTrue, setFalse: setGeneratingFalse },
  ] = useBoolean(false);

  const [currentThought, setCurrentThought] = useState<string | null>(null);
  const [currentOutput, setCurrentOutput] = useState<string>('');
  const [meetingflowType, setMeetingflowType] = useState<string | undefined>(
    undefined,
  );
  const [summary, setSummary] = useState<string>('');
  const [userInput, setUserInput] = useState<string>('');

  const [feedbacks, setFeedbacks] = useState<string[]>([]);

  const [isEditing, setIsEditing] = useState(false);

  const { isDark } = useLightOrDarkMode();

  const client = useAppInsightsContext();

  const generate = useCallback(async () => {
    abortController.current?.abort();

    const newController = new AbortController();
    abortController.current = newController;

    const currentSummary = summary;
    const currentUserInput = userInput;

    setGeneratingTrue();
    setCurrentOutput('');
    setUserInput('');
    setCurrentThought(null);
    if (currentUserInput) {
      setFeedbacks((p) => [...p, currentUserInput]);
    }

    try {
      const token = await getAccessTokenSilently();
      const result = await new Promise<
        { error: true; message?: string | null } | string
      >((resolve, reject) => {
        fetch(
          `/api/organization/${organizationSlug}/plan/${meetingflowId}/stream-completion-2`,
          {
            method: 'POST',
            headers: {
              Accept: 'text/event-stream',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              summaryType,
              prompt: prompt || undefined,
              previousOutput: currentSummary || undefined,
              feedback: currentUserInput || undefined,
            }),
            signal: newController.signal,
          },
        )
          .then(async (res) => {
            let responseBody = '';
            let generated = '';

            if (!res.body) {
              reject(new Error(`res has no body`));
              return;
            }

            const reader = res.body
              .pipeThrough(new TextDecoderStream())
              .getReader();

            if (!reader) {
              reject(`Failed to get stream reader`);
            }

            let gotOutput = false;

            // eslint-disable-next-line no-constant-condition
            while (true) {
              gotOutput = false;
              const read = await reader.read();

              if (read.done && !responseBody) {
                resolve(generated);
                return;
              }

              const value = read.value;

              if (value) {
                responseBody += value;
              }

              let i = responseBody.indexOf('\n\n');

              while (i >= 0) {
                const eventPayload = responseBody.slice(0, i);
                responseBody = responseBody.slice(i + 2);

                if (eventPayload.startsWith('data: ')) {
                  const payload = eventPayload.slice(6);

                  if (payload === '[DONE]') {
                    if (DEBUG) {
                      console.info(`Got [DONE] event payload ${payload}`);
                    }

                    break;
                  }

                  let message: unknown = undefined;
                  try {
                    message = JSON.parse(payload);

                    if (DEBUG) {
                      console.info(`Parsed data chunk ${payload}`);
                    }
                  } catch (err: unknown) {
                    console.warn(`Error parsing payload data chunk ${payload}`);
                  }

                  if (isTokenMessage(message)) {
                    generated += message.token;
                    gotOutput = true;
                  } else if (isThoughtMessage(message)) {
                    setCurrentThought(message.thought);
                  } else if (isClassifiedMeetingflowMessage(message)) {
                    console.info(
                      `Meetingflow classified as ${message.meetingflowType}`,
                    );
                    setMeetingflowType(message.meetingflowType);
                  } else if (isErrorMessage(message)) {
                    resolve({ error: true, message: message.message });
                    return;
                  } else {
                    console.warn(
                      `Received unrecognized message type! ${payload}`,
                    );
                  }
                } else if (eventPayload.startsWith('done')) {
                  if (DEBUG) {
                    console.info(`Got "done" event payload ${eventPayload}`);
                  }
                  break;
                } else {
                  console.warn(
                    `Received unknown payload type: ${eventPayload}`,
                  );
                }

                if (gotOutput) {
                  setCurrentOutput(generated);
                }

                i = responseBody.indexOf('\n\n');
              }
            }
          })
          .catch((err) => reject(err));
      });

      if (isString(result)) {
        setCurrentOutput('');
        setSummary(result);
      } else {
        setCurrentOutput('');
        // setMessages([
        //   ...messages,
        //   {
        //     role: 'assistant',
        //     error: true,
        //     display: true,
        //     content: result.message || 'Something went wrong, please try again',
        //   },
        // ]);
      }
    } catch (err: unknown) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        console.info('Stream summary cancelled');
        return;
      } else {
        toast.error('Something went wrong');
      }
      console.error(typeof err, err);
    } finally {
      if (abortController.current === newController) {
        abortController.current = undefined;
        setCurrentThought(null);
        setCurrentOutput('');
        setGeneratingFalse();
      }
    }
  }, [
    getAccessTokenSilently,
    prompt,
    meetingflowId,
    organizationSlug,
    setGeneratingFalse,
    setGeneratingTrue,
    summary,
    summaryType,
    userInput,
  ]);

  useEffect(() => {
    if (!summary) {
      generate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      abortController.current?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedMarkdownSummary = useMemo(() => {
    return <Markdown>{summary}</Markdown>;
  }, [summary]);

  const renderedMarkdownCurrentOutput = useMemo(() => {
    return <Markdown>{currentOutput}</Markdown>;
  }, [currentOutput]);

  const toolTipHostStyles = useMemo(
    () =>
      ({
        root: {
          display: 'inline-block',
          height: '24px',
          width: '24px',
          marginLeft: '.25rem',
          position: 'absolute',
          top: '.25rem',
          left: '10.5rem',
          zIndex: 10,

          p: {
            margin: 0,
          },
        },
      }) as ITooltipHostStyles,
    [],
  );

  const tooltipCalloutProps = useMemo(
    () => ({
      styles: {
        root: {
          padding: 0,
          background: MEETINGFLOW_COLORS.magentaDark,
          color: MEETINGFLOW_COLORS.white,
        },
        beak: {
          background: MEETINGFLOW_COLORS.magentaDark,
        },
        calloutMain: {
          padding: '1rem',
          backgroundColor: MEETINGFLOW_COLORS.magentaDark,
          p: {
            margin: 0,
            borderBottomRightRadius: '.5rem',
            borderBottomLeftRadius: '.5rem',
            color: MEETINGFLOW_COLORS.white,

            strong: {
              color: MEETINGFLOW_COLORS.white,
              fontWeight: FontWeights.semibold,
            },
          },
        },
      },
    }),
    [],
  );

  const modalContentStyles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    height: '100%',

    '.summary-wrapper': {
      backgroundColor: isDark
        ? isGenerating
          ? MEETINGFLOW_COLORS.purpleMediumDarker
          : NeutralColors.gray180
        : isGenerating
          ? MEETINGFLOW_COLORS.purpleLight
          : MEETINGFLOW_COLORS.purpleUltraLight,
      padding: '.5rem',
      paddingBottom: 0,
      borderRadius: '.25rem',

      '.summary-output': {
        marginBottom: '0.5rem',
        height: '100%',
        flexDirection: 'row',
        position: 'relative',
        maxHeight: '40rem',
        minHeight: '40rem',
        overflow: 'auto',

        '.loading-icon-wrapper': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,

          i: {
            fontSize: '8rem',
            height: '8rem',
            width: '8rem',
            color: 'rgba(255, 255, 255, 0.5)',
            animation: 'blinker 2s ease-in-out infinite',
            textAlign: 'center',
          },
        },

        '.summary-output-textfield': {
          display: 'block',
          width: '100%',
          height: '100%',
          minHeight: '40rem',
          flex: ' 1 1 auto',
          overflow: 'auto',
          padding: '1rem',
          boxSizing: 'border-box',

          p: {
            margin: 0,
            marginBottom: '.5rem',
          },

          'ul, ol': {
            paddingLeft: '1rem',
          },

          'h1, h2, h3, h4, h5, h6, strong': {
            fontWeight: FontWeights.semibold,
          },

          '.ms-TextField-fieldGroup': {
            borderWidth: '0 !important',
            outlineWidth: '0 !important',
            marginLeft: '-.5rem',
            width: 'calc(100% + 1rem)',
            height: '100%',
            ':after': {
              border: isGenerating
                ? 'none !important'
                : `2px solid ${MEETINGFLOW_COLORS.teal}`,
              backgroundColor: 'transparent !important',
              outline: 'none !important',
              borderRadius: '.25rem',
            },

            '.ms-TextField-field': {
              width: '100%',
              minHeight: 'calc(40rem - 5rem)',
              transition: '.3s ease-in-out all',
              padding: isEditing ? '.5rem' : '0',
              backgroundColor: isDark
                ? NeutralColors.gray200
                : MEETINGFLOW_COLORS.purpleUltraLight,
              borderWidth: '0 !important',
              outlineWidth: '0 !important',
              resize: 'none',
            },
          },
        },

        '.summary-output-textfield.generating': {
          background: `linear-gradient(-45deg, ${MEETINGFLOW_COLORS.tealLight}, ${MEETINGFLOW_COLORS.teal}, ${MEETINGFLOW_COLORS.magenta}, ${MEETINGFLOW_COLORS.magentaDark})`,
          backgroundSize: `600% 600%`,
          animation: `gradient 15s ease infinite`,
          color: 'white !important',
        },
      },

      '.user-input': {
        display: 'flex',
        flex: '32px 0 auto',
        flexDirection: 'row',
        gap: '0.5rem',
        alignItems: 'start',
        position: 'relative',
        width: '100%',
        marginLeft: '-.5rem',
        padding: '.5rem',
        borderBottomRightRadius: '.25rem',
        borderBottomLeftRadius: '.25rem',
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.purpleMediumDarker
          : MEETINGFLOW_COLORS.purpleLighter,

        '.user-input-textfield': {
          flex: '1 1 auto',

          '.ms-TextField-fieldGroup': {
            borderWidth: '0 !important',
            outlineWidth: '0 !important',
            ':after': {
              transition: '.3s ease-in-out all',
              border: isGenerating
                ? `2px solid transparent`
                : `2px solid ${MEETINGFLOW_COLORS.teal}`,
              backgroundColor: 'transparent !important',
              outline: 'none !important',
            },
          },

          '.ms-TextField-field': {
            width: '100%',
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleUltraLight,
          },

          '.ms-TextField-description': {
            marginTop: '.25rem',
            display: 'inline-block',
          },
        },

        '.user-input-send': {
          marginTop: '1.75rem',
          flex: '0 0 auto',
          borderRadius: '.25rem',
          backgroundColor: MEETINGFLOW_COLORS.orange,
          borderColor: MEETINGFLOW_COLORS.orange,
          color: 'white',
          transition: '.3s ease-in-out all',

          ':hover': {
            backgroundColor: MEETINGFLOW_COLORS.orangeDark,
            borderColor: MEETINGFLOW_COLORS.orangeDark,
            color: 'white',
          },
        },
      },
    },

    '.buttons': {
      width: '100%',
      flex: '32px 0 auto',
      display: 'flex',
      justifyContent: 'end',
      gap: '0.5rem',

      button: {
        borderRadius: '.25rem',
        backgroundColor: MEETINGFLOW_COLORS.purpleMediumDarker,
        borderColor: MEETINGFLOW_COLORS.purpleMediumDarker,
        transition: '.3s ease-in-out all',
        color: 'white',

        ':hover': {
          backgroundColor: MEETINGFLOW_COLORS.purpleDark,
          borderColor: MEETINGFLOW_COLORS.purpleDark,
        },
      },

      '.ms-Button--primary': {
        backgroundColor: MEETINGFLOW_COLORS.magenta,
        borderColor: MEETINGFLOW_COLORS.magenta,
        color: 'white',

        ':hover': {
          backgroundColor: MEETINGFLOW_COLORS.magentaMedium,
          borderColor: MEETINGFLOW_COLORS.magentaMedium,
        },
      },
    },

    'button.is-disabled': {
      color: 'white !important',
      cursor: 'not-allowed',
      backgroundColor: `${isDark ? MEETINGFLOW_COLORS.purpleMediumDark : MEETINGFLOW_COLORS.purpleGreyMediumAlt} !important`,
      borderColor: `${isDark ? MEETINGFLOW_COLORS.purpleMediumDark : MEETINGFLOW_COLORS.purpleGreyMediumAlt} !important`,
    },
  });

  return (
    <BaseModal
      title={title}
      headerBackgroundColor={MEETINGFLOW_COLORS.purpleMedium}
      styles={{
        main: {
          width: '75%',
          maxWidth: '40rem',
          backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
        },
        root: {},
        layer: {},
      }}
      isOpen
      onDismiss={() => {
        abortController.current?.abort();
        client.trackEvent({
          name: 'CancelGenerateMeetingflowSummaryContent',
          properties: {
            meetingflowId,
            organizationSlug,
            meetingflowType,
            summaryType,
            summary,
          },
        });
        onDismiss();
      }}
      contentPadding="0 .5rem .5rem .5rem"
    >
      <div className={modalContentStyles}>
        <div className="summary-wrapper">
          <div className="summary-output">
            {isGenerating && currentOutput.length === 0 ? (
              <div className="loading-icon-wrapper">
                <FontIcon iconName="AISparkle" />
              </div>
            ) : null}

            {isGenerating || !isEditing ? (
              <div
                className={
                  isGenerating
                    ? 'summary-output-textfield generating'
                    : 'summary-output-textfield'
                }
                onClick={() => setIsEditing(true)}
              >
                {isGenerating
                  ? renderedMarkdownCurrentOutput
                  : renderedMarkdownSummary}
              </div>
            ) : (
              <>
                <TextField
                  className={
                    isGenerating
                      ? 'summary-output-textfield generating'
                      : 'summary-output-textfield editing'
                  }
                  multiline
                  disabled={isGenerating}
                  value={summary}
                  onChange={(_e, value) => {
                    if (!isGenerating) {
                      setSummary(value ?? '');
                    }
                  }}
                />
                <PrimaryButton
                  text="Done Editing"
                  styles={{
                    root: {
                      position: 'absolute',
                      bottom: '1rem',
                      right: '1rem',
                    },
                  }}
                  onClick={() => {
                    setIsEditing(false);
                  }}
                />
              </>
            )}
          </div>
          <div className="user-input">
            <TooltipHost
              styles={toolTipHostStyles}
              calloutProps={tooltipCalloutProps}
              content={
                <>
                  <p>
                    Meetingflow now learns from your feedback. Provide a prompt
                    like{' '}
                    <strong>"Add more detail about the action items"</strong>,{' '}
                    <strong>"More context on the next steps"</strong>,{' '}
                    <strong>"Clarify the risks?"</strong>, or{' '}
                    <strong>"Don't include emoji"</strong> below and click
                    "Regenerate" to refine the summary. Over time, Meetingflow
                    will become more attuned to your summarization preferences.
                  </p>
                </>
              }
            >
              <FontIcon
                iconName="Info"
                style={{
                  color: isDark
                    ? MEETINGFLOW_COLORS.white
                    : MEETINGFLOW_COLORS.magentaDark,
                }}
              />
            </TooltipHost>

            <TextField
              className="user-input-textfield"
              disabled={isGenerating}
              label="Feedback and Regenerate"
              value={userInput}
              placeholder="Enter feedback to refine the summary"
              onChange={(_e, value) => {
                setUserInput(value || '');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && userInput) {
                  generate();
                }
              }}
            />
            <PrimaryButton
              className="user-input-send"
              iconProps={{ iconName: 'AISparkle' }}
              disabled={!userInput || isGenerating}
              text={isGenerating ? 'Generating...' : 'Regenerate'}
              onClick={() => {
                if (userInput) {
                  generate();
                }
              }}
            />
          </div>
        </div>

        <div className="buttons">
          <DefaultButton
            text="Cancel"
            onClick={() => {
              abortController.current?.abort();
              client.trackEvent({
                name: 'CancelGenerateMeetingflowSummaryContent',
                properties: {
                  meetingflowId,
                  organizationSlug,
                  meetingflowType,
                  summaryType,
                  summary,
                },
              });
              onDismiss();
            }}
          />
          <AsyncPrimaryButton
            disabled={!summary || !meetingflowType || isGenerating}
            text="Accept"
            onClick={async () => {
              if (summary && meetingflowType) {
                const token = await getAccessTokenSilently();
                let html: string | undefined;
                let slateNodes: SlateDescendant[] | undefined;
                const clipboardContent: Record<string, Blob> = {};

                clipboardContent['text/plain'] = new Blob([summary], {
                  type: 'text/plain',
                });

                ApiClient.post(
                  `/organization/${organizationSlug}/plan/${meetingflowId}/accept-summary`,
                  {
                    summaryType,
                    summary,
                    feedbacks,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  },
                ).catch();

                try {
                  const Showdown = (await import('showdown')).default;
                  const converter = new Showdown.Converter({
                    disableForced4SpacesIndentedSublists: true,
                    ghCompatibleHeaderId: true,
                    openLinksInNewWindow: true,
                    simpleLineBreaks: true,
                    simplifiedAutoLink: true,
                    smartIndentationFix: true,
                    strikethrough: true,
                    tasklists: true,
                    underline: true,
                  });
                  html = converter.makeHtml(summary);
                  if (html) {
                    clipboardContent['text/html'] = new Blob([html], {
                      type: 'text/html',
                    });
                  }

                  const nodes = htmlToSlateDescendants(html);
                  const validateNode = (
                    c: SlateDescendant | SlateDescendant[],
                  ): boolean =>
                    (isArray(c) && c.every(validateNode)) ||
                    SlateText.isText(c) ||
                    (SlateElement.isElement(c) &&
                      c.children.every(validateNode));

                  if (validateNode(nodes)) {
                    slateNodes = nodes;
                  }
                } catch (err) {}

                if (typeof navigator?.clipboard?.write === 'function') {
                  await navigator.clipboard.write([
                    new ClipboardItem(clipboardContent),
                  ]);
                  toast.success(`Copied summary to clipboard`);
                } else if (
                  typeof navigator?.clipboard?.writeText === 'function'
                ) {
                  await navigator.clipboard.writeText(summary);
                  toast.success(`Copied summary to clipboard`);
                }

                onAccept({
                  meetingflowType,
                  summary,
                  summaryHtml: html,
                  summarySlateNodes: slateNodes,
                });
              }
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
};
