import { Stack, Text } from '@fluentui/react';
import { Box } from '@mui/material';
import { getStyles } from '../UploadDealRoomAttachmentsDialog.styles';
import { DraggableFileUploaderContainer } from '../../DraggableFileUploaderContainer';
import DealRoomAsyncCommandBarButton from '../../Components/DealRoomButton';
import { useFileUpload } from './hooks';
import { UploadFormProps } from './types';
import { DSTabs, DSTab } from '../../DS';
import React from 'react';
import {
  CommonUploadArtifactsFile,
  CommonUploadArtifactsURL,
} from '../CommonUploadArtifacts';

export const UploadForm: React.FC<UploadFormProps> = ({
  title,
  setTitle,
  description,
  setDescription,
  selectedFile,
  setSelectedFile,
  artifact,
  url,
  setUrl,
  pivotRef,
  selectedTab,
  setSelectedTab,
}) => {
  const styles = getStyles();

  const { handleSelectFile, handleDrop } = useFileUpload({
    setSelectedFile,
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <DSTabs
        ref={pivotRef}
        defaultValue="file"
        value={selectedTab}
        onChange={(_, newValue) => setSelectedTab?.(newValue as string)}
        aria-label="attachment tabs"
      >
        <DSTab
          value="file"
          label={artifact ? 'Update Version' : 'Upload File'}
          panelStyles={{
            padding: '1rem 0 0 0',
          }}
          panel={
            <>
              {artifact ? (
                <DraggableFileUploaderContainer
                  handleOnDrop={handleDrop}
                  className={styles.draggableContainer}
                >
                  <DealRoomAsyncCommandBarButton
                    customClasses={styles.selectFileButton}
                    onClick={handleSelectFile}
                    text="Select File"
                    buttonStyleType="DEAL_ROOM"
                    iconProps={{ iconName: 'AddArtifact' }}
                  />
                  <div className={styles.dropContent}>
                    <Text className={styles.uploadSectionMessage}>
                      Select or drop a file to upload.
                    </Text>
                    <Text className={styles.uploadSectionMessage}>
                      This file will{' '}
                      <span className={styles.highlighted}>replace</span> the
                      existing version.
                    </Text>
                    {selectedFile && (
                      <Text className={styles.uploadSectionFileName}>
                        {selectedFile.name || 'N/A'}
                      </Text>
                    )}
                  </div>
                </DraggableFileUploaderContainer>
              ) : (
                <CommonUploadArtifactsFile
                  actionItemId={null}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  title={title}
                  setTitle={setTitle}
                  description={description}
                  setDescription={setDescription}
                />
              )}
            </>
          }
        />
        {!artifact && (
          <DSTab
            value="url"
            label="Create from URL"
            panel={
              <Stack.Item className={styles.content}>
                <CommonUploadArtifactsURL
                  url={url}
                  setUrl={setUrl}
                  title={title}
                  setTitle={setTitle}
                  description={description}
                  setDescription={setDescription}
                />
              </Stack.Item>
            }
          />
        )}
      </DSTabs>
    </Box>
  );
};
