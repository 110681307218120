/**
 * DSCarousel - A reusable carousel component for displaying scrollable content
 * 
 * This component implements a horizontal carousel with the following features:
 * - Smooth scrolling navigation with left/right buttons
 * - Touch/mouse drag scrolling support
 * - Automatic navigation button visibility based on scroll position
 * - Responsive design with automatic overflow detection
 * - Loading state with spinner
 * - Support for any React nodes as carousel items
 * 
 * @example
 * ```tsx
 * <DSCarousel
 *   items={[<Item1 />, <Item2 />, <Item3 />]}
 *   loading={false}
 *   hideNavigation={false}
 *   initialScrollIndex={0}
 * />
 * ```
 */

import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  MouseEvent,
  useCallback,
} from 'react';
import { CircularProgress } from '@mui/material';
import {
  ChevronLeft as LeftIcon,
  ChevronRight as RightIcon,
} from '@mui/icons-material';
import {
  CarouselContainer,
  LoadingContainer,
  ContentContainer,
  ScrollContent,
  ItemContainer,
  NavigationButton,
} from './DSCarousel.styles';

/**
 * Props for the DSCarousel component
 * @interface DSCarouselProps
 * @property {ReactNode[]} items - Array of React elements to display in the carousel
 * @property {boolean} [hideNavigation] - Whether to hide the navigation buttons
 * @property {boolean} [loading] - Whether to show a loading spinner
 * @property {number} [initialScrollIndex] - Initial item index to scroll to on mount
 */
interface DSCarouselProps {
  items: ReactNode[];
  hideNavigation?: boolean;
  loading?: boolean;
  initialScrollIndex?: number;
}

/**
 * A carousel component that supports smooth scrolling, touch interactions, and responsive design
 * @param {DSCarouselProps} props - Component props
 */
const DSCarousel = ({
  items,
  loading = false,
  hideNavigation = false,
  initialScrollIndex = 0,
}: DSCarouselProps) => {
  // Track scroll position and dragging state
  const [scrollIndex, setScrollIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showNavigation, setShowNavigation] = useState(false);
  const [showLeftNav, setShowLeftNav] = useState(false);
  const [showRightNav, setShowRightNav] = useState(false);

  /**
   * Checks the current scroll position and updates navigation button visibility
   * Adds a small buffer (1px) to account for rounding errors in scroll calculations
   */
  const checkScrollPosition = () => {
    if (contentRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = contentRef.current;
      // Add a small buffer (1px) to account for rounding errors
      const isAtStart = scrollLeft <= 1;
      const isAtEnd = Math.abs(scrollLeft + clientWidth - scrollWidth) <= 1;

      setShowLeftNav(scrollLeft > 1);
      setShowRightNav(!isAtEnd);
    }
  };

  /**
   * Checks if content overflows the container and updates navigation visibility
   * Navigation is only shown when content width exceeds container width and hideNavigation is false
   */
  const checkOverflow = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const contentWidth = contentRef.current.scrollWidth;
      const hasOverflow = !hideNavigation && contentWidth > containerWidth;
      setShowNavigation(hasOverflow);
      if (hasOverflow) {
        checkScrollPosition();
      } else {
        setShowLeftNav(false);
        setShowRightNav(false);
      }
    }
  }, [hideNavigation]);

  /**
   * Sets up initial scroll position and resize observer
   * - Initializes scroll position based on initialScrollIndex
   * - Creates ResizeObserver to handle container size changes
   * - Cleans up observers and timers on unmount
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      checkOverflow();
      if (initialScrollIndex > 0 && contentRef.current) {
        const itemWidth = contentRef.current.children[0]?.clientWidth || 0;
        contentRef.current.scrollTo({
          left: itemWidth * initialScrollIndex,
          behavior: 'smooth',
        });
      }
    }, 100);

    const resizeObserver = new ResizeObserver(() => {
      // Add a small delay after resize to ensure accurate measurements
      setTimeout(checkOverflow, 50);
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      clearTimeout(timer);
    };
  }, [hideNavigation, items, initialScrollIndex, checkOverflow]);

  /**
   * Sets up scroll event listener to update navigation button visibility
   */
  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      const handleScroll = () => {
        // Add a small delay to ensure accurate scroll position
        requestAnimationFrame(checkScrollPosition);
      };

      content.addEventListener('scroll', handleScroll);
      return () => content.removeEventListener('scroll', handleScroll);
    }
  }, []);

  /**
   * Handles programmatic scrolling when navigation buttons are clicked
   * @param {number} direction - 1 for right, -1 for left
   */
  const handleScroll = (direction: number) => {
    if (containerRef.current && contentRef.current) {
      const scrollAmount = direction * containerRef.current.offsetWidth;

      // Create one-time scroll listener to check position after scrolling stops
      let lastScrollLeft = contentRef.current.scrollLeft;
      let scrollTimeout: number;

      const handleScrollEnd = () => {
        const newScrollLeft = contentRef.current?.scrollLeft;

        if (newScrollLeft === lastScrollLeft) {
          contentRef.current?.removeEventListener('scroll', handleScrollEnd);
          checkScrollPosition();
        } else {
          lastScrollLeft = newScrollLeft ?? lastScrollLeft;
          scrollTimeout = window.setTimeout(handleScrollEnd, 50);
        }
      };

      contentRef.current.addEventListener('scroll', handleScrollEnd);

      // Start the scroll
      contentRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });

      // Cleanup after 1 second in case something goes wrong
      setTimeout(() => {
        contentRef.current?.removeEventListener('scroll', handleScrollEnd);
        clearTimeout(scrollTimeout);
      }, 1000);
    }
  };

  /**
   * Initializes mouse drag scrolling
   * @param {MouseEvent<HTMLDivElement>} event - Mouse down event
   */
  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    if (contentRef.current) {
      setStartX(event.pageX - contentRef.current.offsetLeft);
      setScrollLeft(contentRef.current.scrollLeft);
    }
  };

  /**
   * Handles mouse up event to stop drag scrolling
   */
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  /**
   * Handles mouse move event to update scroll position during drag scrolling
   * @param {MouseEvent<HTMLDivElement>} event - Mouse move event
   */
  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !contentRef.current) return;
    event.preventDefault();
    const x = event.pageX - contentRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    contentRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <CarouselContainer>
      {loading ? (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      ) : (
        <>
          <NavigationButton
            onClick={() => handleScroll(-1)}
            size="small"
            show={showNavigation && showLeftNav}
          >
            <LeftIcon sx={{ fontSize: 16 }} />
          </NavigationButton>
          <ContentContainer ref={containerRef}>
            <ScrollContent
              ref={contentRef}
              isDragging={isDragging}
              showLeftNav={showLeftNav}
              showRightNav={showRightNav}
              showNavigation={showNavigation}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onMouseMove={handleMouseMove}
            >
              {items.map((item, index) => (
                <ItemContainer key={index}>{item}</ItemContainer>
              ))}
            </ScrollContent>
          </ContentContainer>
          <NavigationButton
            onClick={() => handleScroll(1)}
            size="small"
            show={showNavigation && showRightNav}
          >
            <RightIcon sx={{ fontSize: 16 }} />
          </NavigationButton>
        </>
      )}
    </CarouselContainer>
  );
};

export default DSCarousel;
