import { PropsWithChildren, ReactElement } from 'react';
import { Typography } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  InfoSectionContainer,
  InfoSectionHeader,
  HeaderIconWrapper,
  InfoSectionContent,
} from './InfoSection.styles';

interface InfoSectionProps {
  icon: ReactElement;
  title: string;
}

export const InfoSection = ({
  icon,
  title,
  children,
}: PropsWithChildren<InfoSectionProps>) => {
  return (
    <InfoSectionContainer>
      <InfoSectionHeader>
        <HeaderIconWrapper>{icon}</HeaderIconWrapper>
        <Typography
          variant="h6"
          sx={{
            color: DEALROOMS_COLORS.themePrimary,
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
            fontWeight: 400,
          }}
        >
          {title}
        </Typography>
      </InfoSectionHeader>
      <InfoSectionContent>{children}</InfoSectionContent>
    </InfoSectionContainer>
  );
};
