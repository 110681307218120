import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  dealRoomActionItemStatusValues,
  getStatusBackgroundColor,
} from '../milestonesUtils';
import { useCallback, useMemo } from 'react';
import useMilestonesActionItemsFiltersStore from '../../../../Stores/milestonesActionItemsFiltersStore';
import useDeviceType from '../../../../Hooks/deviceDetection';
import { DealRoomActionItemStatus } from '@meetingflow/common/Api/data-contracts';

export const StatusFilter = () => {
  const { isMobile } = useDeviceType();

  // Store values and setters
  const selectedStatuses = useMilestonesActionItemsFiltersStore(
    (state) => state.selectedStatuses,
  );
  const setSelectedStatuses = useMilestonesActionItemsFiltersStore(
    (state) => state.setSelectedStatuses,
  );

  // Calculate the width of the status box based on the longest status text
  const statusBoxWidth = useMemo(() => {
    const longestStatus = Object.values(dealRoomActionItemStatusValues)
      .map((status) => status.text)
      .reduce((a, b) => (a.length > b.length ? a : b));
    return `${longestStatus.length * 8}px`; // Multiply by 8 for approximate pixel width
  }, []);

  // Handle changes in status filter selection
  const handleStatusChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value;

      // Handle regular selection
      const newStatuses = typeof value === 'string' ? [] : value;
      setSelectedStatuses(newStatuses as DealRoomActionItemStatus[]);
    },
    [setSelectedStatuses],
  );

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: { xs: '100%', sm: 250 },
        maxWidth: 350,
      }}
    >
      <InputLabel size="small" id="status-select-label">
        Status
      </InputLabel>
      <Select
        labelId="status-select-label"
        label="Status"
        multiple
        value={selectedStatuses}
        onChange={handleStatusChange}
        renderValue={(selected) => {
          if (selected.length === 1) {
            const statusKey = Object.keys(dealRoomActionItemStatusValues).find(
              (key) => dealRoomActionItemStatusValues[key].key === selected[0],
            );
            const selectedStatus = statusKey
              ? dealRoomActionItemStatusValues[statusKey]
              : null;
            return (
              <Box
                sx={{
                  backgroundColor: getStatusBackgroundColor(
                    selectedStatus?.key,
                  ),
                  padding: '0 .5rem',
                  borderRadius: '.25rem',
                  width: 'fit-content',
                  display: 'inline-block',
                }}
              >
                {selectedStatus?.text || 'Unknown'}
              </Box>
            );
          }
          const firstStatusKey = Object.keys(
            dealRoomActionItemStatusValues,
          ).find(
            (key) => dealRoomActionItemStatusValues[key].key === selected[0],
          );
          const firstStatus = firstStatusKey
            ? dealRoomActionItemStatusValues[firstStatusKey]
            : null;
          const remainingCount = selected.length - 1;
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  backgroundColor: getStatusBackgroundColor(firstStatus?.key),
                  padding: '0 .5rem',
                  borderRadius: '.25rem',
                  width: 'fit-content',
                  display: 'inline-block',
                }}
              >
                {firstStatus?.text || 'Unknown'}
              </Box>
              {` +${remainingCount}`}
            </Box>
          );
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400,
              width: isMobile ? '90vw' : 'auto',
            },
            sx: {
              '& .MuiMenuItem-root': {
                py: 0.5,
                px: 1,
                minHeight: 'unset',
              },
              '& .MuiCheckbox-root': {
                padding: '4px',
              },
            },
          },
        }}
      >
        {Object.values(dealRoomActionItemStatusValues).map((status) => (
          <MenuItem key={status.key} value={status.key}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                width: '100%',
              }}
            >
              <Checkbox checked={selectedStatuses.includes(status.key)} />
              <Box
                sx={{
                  backgroundColor: getStatusBackgroundColor(status.key),
                  padding: '2px 6px',
                  borderRadius: '.25rem',
                  width: statusBoxWidth,
                  textAlign: 'center',
                }}
              >
                {status.text}
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
