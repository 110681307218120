import { useAuth0 } from '@auth0/auth0-react';
import {
  FontWeights,
  Icon,
  IPersonaProps,
  IPersonaSharedProps,
  Persona,
  PersonaSize,
} from '@fluentui/react';
import { useQuery } from 'react-query';
import { OrganizationRoleQuery } from '../QueryNames';
import { OrganizationsApiClient } from '../Services/NetworkCommon';
import { titleCase } from '../Helpers/Typography';
import { ROLE_ICONS } from '../Constants';
import { Organization } from '@meetingflow/common/Api/data-contracts';

interface OrganizationPersonaProps extends IPersonaProps {
  organization: Pick<Organization, 'name' | 'slug'>;
}

export const OrganizationPersona: React.FunctionComponent<
  OrganizationPersonaProps
> = (props) => {
  const { organization } = props;
  const { getAccessTokenSilently } = useAuth0();

  const { data: orgRoleData } = useQuery(
    OrganizationRoleQuery(organization.slug!),
    async () => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.getOrganizationRole(organization.slug!, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    { enabled: !!organization.slug! },
  );

  const orgPersona: IPersonaSharedProps = {
    text: organization?.name || '',
    // @ts-ignore
    secondaryText: orgRoleData?.data.role ? (
      <>
        <strong>Role:</strong> {titleCase(orgRoleData?.data.role)}
      </>
    ) : (
      ''
    ),
  };

  return (

    <Persona
      {...orgPersona}
      size={PersonaSize.size48}
      {...props}
      styles={{
        root: {
          '.ms-Persona': {
            height: '32px',
            width: '32px',
          },
          '.ms-Persona-details': {
            position: 'relative',
            top: '-.6rem',
            left: '-1rem',
          },
        },
        primaryText: {
          fontWeight: FontWeights.semibold,
        },
      }}
      coinProps={{
        styles: {
          coin: {
            '.ms-Persona-initials': {
              borderRadius: 2,
              height: '32px',
              width: '32px',
              lineHeight: '32px',
              span: {
                lineHeight: '32px',
              },
            },
          },
        },
      }}
    />
  );
};
