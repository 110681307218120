import React from 'react';
import { IconButton, Stack, TextField } from '@fluentui/react';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { CalloutCardFormData } from './DSConfigurationCalloutCards';
import { getCalloutCardStyles } from './DSConfigurationCalloutCard.styles';
import { DeleteIconProps, DragIconProps } from '../../../../../utils/iconProps';

interface DSConfigurationCalloutCardProps {
  card: CalloutCardFormData;
  index: number;
  onDelete: (index: number) => void;
  onChange: (
    index: number,
    field: keyof CalloutCardFormData,
    value: string | number | null,
  ) => void;
  artifacts: DealRoomArtifact[];
  dragHandleProps?: React.HTMLAttributes<HTMLElement> | undefined;
  isDragging?: boolean;
}

export const DSConfigurationCalloutCard: React.FC<
  DSConfigurationCalloutCardProps
> = ({
  card,
  index,
  onDelete,
  onChange,
  artifacts,
  dragHandleProps,
  isDragging,
}) => {
  const styles = getCalloutCardStyles(isDragging);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <IconButton
            iconProps={DragIconProps}
            className={styles.dragHandle}
            {...dragHandleProps}
            title="Touch and hold to reorder"
          />
          <h3>Card {index + 1}</h3>
        </div>
        <IconButton
          iconProps={DeleteIconProps}
          onClick={() => onDelete(index)}
          ariaLabel="Delete card"
        />
      </div>

      <Stack tokens={{ childrenGap: 10 }}>
        <TextField
          label="Title"
          required
          value={card.title}
          onChange={(_, value) => onChange(index, 'title', value || '')}
        />

        <TextField
          label="Link"
          placeholder={
            !Boolean(card.artifactId) ? 'https://augment.co' : undefined
          }
          value={card.link || undefined}
          onChange={(_, value) => {
            const savedValue = value ? value : null;
            onChange(index, 'link', savedValue);
          }}
          disabled={Boolean(card.artifactId)}
        />

        <Dropdown
          label="Associated Artifact"
          selectedKey={card.artifactId}
          onChange={(_, value) => {
            const savedValue =
              value?.key === undefined || value?.key === -1 ? null : value.key;
            onChange(index, 'artifactId', savedValue);
          }}
          options={[
            { key: -1, text: 'None' },
            ...artifacts.map((artifact) => ({
              key: artifact.id,
              text: artifact.name,
              data: { label: artifact.label },
            })),
          ]}
          disabled={Boolean(card.link)}
          onRenderOption={(option) => (
            <div>
              {option?.data?.label && <span>{option.data.label}: </span>}
              <span>{option?.text}</span>
            </div>
          )}
        />

        <TextField
          label="Description"
          required
          multiline
          rows={3}
          value={card.description}
          onChange={(_, value) => onChange(index, 'description', value || '')}
        />

        <TextField
          label="CTA Label"
          value={card.ctaLabel || ''}
          onChange={(_, value) => onChange(index, 'ctaLabel', value || '')}
        />
      </Stack>
    </div>
  );
};
