/**
 * DecisionSiteMeetingPanelHeader is a reusable component that provides
 * a consistent header layout for the meeting panel. It includes a content area
 * for custom elements and a close button.
 */

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { ReactNode } from 'react';
import {
  HeaderContainer,
  ContentWrapper,
  StyledIconButton,
} from './DecisionSiteMeetingPanelHeader.styles';

/**
 * Props interface for DecisionSiteMeetingPanelHeader
 * @property meetingflowId - Unique identifier for the meeting flow
 * @property onClickCloseButton - Callback function to handle panel closure
 * @property children - Optional content to be rendered in the header
 */
type DecisionSiteMeetingPanelHeaderProps = {
  meetingflowId: string | undefined;
  onClickCloseButton: () => void;
  children?: ReactNode;
};

/**
 * Header component that provides a consistent layout for the meeting panel
 * Includes a flexible content area and a close button
 */
export const DecisionSiteMeetingPanelHeader = ({
  meetingflowId,
  onClickCloseButton,
  children,
}: DecisionSiteMeetingPanelHeaderProps) => {
  return (
    <HeaderContainer>
      <ContentWrapper>{children}</ContentWrapper>
      <IconButton
        component={StyledIconButton}
        onClick={onClickCloseButton}
      >
        <CloseIcon />
      </IconButton>
    </HeaderContainer>
  );
};

export default DecisionSiteMeetingPanelHeader;
