import React, {
  forwardRef,
  useState,
  memo,
  useRef,
  useImperativeHandle,
} from 'react';
import { TabsProps, TabProps } from '@mui/material';
import {
  StyledTabs,
  StyledTab,
  TabLabelContainer,
  TabBadge,
  TabPanel,
} from './DSTabs.styles';

/**
 * Interface for the ref object exposed by DSTabs
 */
export interface TabsRef {
  focus: () => void;
}

/**
 * Props for tab panels
 */
export interface DSTabPanelProps {
  children?: React.ReactNode;
  index: string | number;
  value: string | number;
  style?: React.CSSProperties;
}

/**
 * Props for the DSTabs component
 * Extends Material-UI TabsProps but overrides value and onChange with our own types
 */
export interface DSTabsProps
  extends Omit<TabsProps, 'value' | 'onChange' | 'ref' | 'onClick'> {
  /** Current selected tab value. If not provided, component will manage its own state */
  value?: string | number;
  /** Initial tab value when uncontrolled. First tab will be selected if not provided */
  defaultValue?: string | number;
  /** Callback fired when tab selection changes */
  onChange?: (event: React.SyntheticEvent, value: string | number) => void;
  /** Callback fired when tab is clicked */
  onClick?: (event: React.SyntheticEvent, value: string | number) => void;
}

/**
 * TabPanel component that wraps content for each tab
 * Content is only rendered when the tab is active
 */
export const DSTabPanel = ({
  children,
  value,
  index,
  style,
}: DSTabPanelProps) => {
  return (
    <TabPanel value={value} index={index} style={style}>
      {children}
    </TabPanel>
  );
};

/**
 * DSTabs component provides a tabbed interface with consistent styling
 *
 * Features:
 * - Can be controlled (using value/onChange) or uncontrolled (using defaultValue)
 * - Automatically selects first tab if no value/defaultValue provided
 * - Supports string or number values for type safety
 * - Includes built-in count badge support
 * - Panels are only rendered when active for better performance
 *
 * @example
 * ```tsx
 * // Uncontrolled usage
 * <DSTabs defaultValue="tab1">
 *   <DSTab
 *     value="tab1"
 *     label="First Tab"
 *     count={5} // Optional count badge
 *     panel={<div>Tab 1 Content</div>}
 *   />
 *   <DSTab
 *     value="tab2"
 *     label="Second Tab"
 *     panel={<div>Tab 2 Content</div>}
 *   />
 * </DSTabs>
 *
 * // Controlled usage
 * const [value, setValue] = useState('tab1');
 * <DSTabs
 *   value={value}
 *   onChange={(event, newValue) => setValue(newValue)}
 * >
 *   <DSTab value="tab1" label="First Tab" panel={...} />
 *   <DSTab value="tab2" label="Second Tab" panel={...} />
 * </DSTabs>
 * ```
 */
export const DSTabs = memo(
  forwardRef<TabsRef, DSTabsProps>((props, ref) => {
    const {
      children,
      value,
      defaultValue,
      onChange,
      onClick,
      className,
      ...rest
    } = props;
    const tabsRef = useRef<HTMLDivElement>(null);

    // Expose the TabsRef methods
    useImperativeHandle(ref, () => ({
      focus: () => {
        tabsRef.current?.focus();
      },
    }));

    // Get first tab's value or index as default
    const getDefaultValue = () => {
      const firstChild = React.Children.toArray(children).find(
        (child) => React.isValidElement(child) && child.props.value,
      ) as React.ReactElement;
      return firstChild?.props.value;
    };

    const [internalValue, setInternalValue] = useState<string | number>(
      value ?? defaultValue ?? getDefaultValue(),
    );

    const handleChange = (
      event: React.SyntheticEvent,
      newValue: string | number,
    ) => {
      if (value === undefined) {
        setInternalValue(newValue);
      }
      onChange?.(event, newValue);
    };

    const handleClick = async (
      event: React.SyntheticEvent,
      tabValue: string | number,
    ) => {
      if (onClick) {
        await onClick(event, tabValue);
      }
    };

    const validChildren = React.Children.toArray(children).filter(
      (child): child is React.ReactElement =>
        React.isValidElement(child) && child.props.value,
    );

    return (
      <div className={className}>
        <StyledTabs
          ref={tabsRef}
          value={value ?? internalValue}
          onChange={handleChange}
          {...rest}
        >
          {validChildren.map((child) => (
            <StyledTab
              key={child.props.value}
              value={child.props.value}
              onClick={(e) => handleClick(e, child.props.value)}
              label={
                child.props.count !== undefined ? (
                  <TabLabelContainer>
                    {child.props.label}
                    <TabBadge
                      selected={(value ?? internalValue) === child.props.value}
                    >
                      {child.props.count}
                    </TabBadge>
                  </TabLabelContainer>
                ) : (
                  child.props.label
                )
              }
            />
          ))}
        </StyledTabs>
        {validChildren.map((child) => (
          <DSTabPanel
            key={child.props.value}
            value={value ?? internalValue}
            index={child.props.value}
            style={child.props.panelStyles}
          >
            {child.props.panel}
          </DSTabPanel>
        ))}
      </div>
    );
  }),
);

DSTabs.displayName = 'DSTabs';

/**
 * Props for the DSTab component
 */
export interface DSTabProps {
  /** Unique string or number identifier for the tab */
  value: string | number;
  /** Label to display for the tab */
  label: React.ReactNode;
  /** Optional count to display as a badge */
  count?: number;
  /** Optional styles to apply to the tab panel */
  panelStyles?: React.CSSProperties;
  /** Content to render when this tab is active */
  panel?: React.ReactNode;
}

/**
 * DSTab component represents a single tab within DSTabs
 * Must be used as a child of DSTabs
 */
export const DSTab: React.FC<DSTabProps> = memo(
  ({ count, label, panelStyles, panel, value }) => {
    const finalLabel =
      count !== undefined ? (
        <TabLabelContainer>
          {label}
          <TabBadge selected={true}>{count}</TabBadge>
        </TabLabelContainer>
      ) : (
        label
      );

    return <StyledTab label={finalLabel} value={value} />;
  },
);

DSTab.displayName = 'DSTab';

export default DSTabs;
