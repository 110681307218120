import {
  NeutralColors,
  mergeStyles,
  VerticalDivider,
  Stack,
  IVerticalDividerStyles,
} from '@fluentui/react';
import { useEffect, useRef } from 'react';
import { isIOS, isMacOs, isMobile } from 'react-device-detect';
import { BaseSelection, Editor, Range } from 'slate';
import { CreateDeferredPromise } from '../../Helpers/DeferredPromise';
import { AIActionContext } from '../../types/AIActionContext';
import { SidePanelShortcutContext } from '../../types/SidePanelShortcutContext';
import { CustomEditor } from '../../types/slate';
import { Portal } from '../Portal';
import { AIActionButton } from './Components/AIActionButton';
import { BlockButton } from './Components/BlockButton';
import { LinkButton } from './Components/LinkButton';
import { ListDeindentButton } from './Components/ListDeindentButton';
import { ListIndentButton } from './Components/ListIndentButton';
import { MarkButton } from './Components/MarkButton';
import { SidePanelShortcutButton } from './Components/SidePanelShortcutButton';
import { isListActive } from './Helpers/EditorHelpers';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { AddImageButton } from './Components/AddImageButton';

interface DecisionSiteEditorToolbarProps {
  selection: BaseSelection;
  inFocus: boolean;
  editor: CustomEditor;
  readonly?: boolean;
  hoverWithSelection?: boolean;
  includedButtonTypes?: (
    | 'MARK'
    | 'BLOCK'
    | 'LIST_INDENT'
    | 'LINK'
    | 'AI-ACTION'
    | 'SIDE-PANEL-SHORTCUT'
    | 'IMAGE'
  )[];

  organizationSlug: string;
  meetingPlanId?: string;
  getAIActionContext?: CreateDeferredPromise<
    AIActionContext,
    Partial<AIActionContext> | undefined
  >;
  getSidePanelShortcutContext?: CreateDeferredPromise<
    SidePanelShortcutContext,
    Partial<SidePanelShortcutContext> | undefined
  >;
}

const isMacOrIOS = isMacOs || isIOS;

const TOOLBAR_OFFSCREEN_POSITION = '-9999px';

const DecisionSiteEditorToolbar = ({
  selection,
  inFocus,
  editor,
  readonly,
  hoverWithSelection = false,
  includedButtonTypes = [
    'MARK',
    'BLOCK',
    'LIST_INDENT',
    'LINK',
    'AI-ACTION',
    'SIDE-PANEL-SHORTCUT',
    'IMAGE',
  ],
  organizationSlug,
  meetingPlanId,
  getAIActionContext,
  getSidePanelShortcutContext,
}: DecisionSiteEditorToolbarProps) => {
  const toolbarRef = useRef<HTMLDivElement>(null);

  const { isDark } = useLightOrDarkMode();

  useEffect(() => {
    const el = toolbarRef.current;

    if (!el || !hoverWithSelection) {
      return;
    }

    // No selection
    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.style.opacity = '0';
      el.style.top = TOOLBAR_OFFSCREEN_POSITION;
      el.style.left = TOOLBAR_OFFSCREEN_POSITION;
      el.querySelectorAll('button').forEach((button) => {
        button.style.opacity = '0';
      });
      return;
    }

    // Selection
    const domSelection = window.getSelection();
    const domRange = domSelection && domSelection.getRangeAt(0);
    const rect = domRange && domRange.getBoundingClientRect();
    if (rect) {
      el.style.opacity = '1';
      el.style.top = `calc(${
        // rect.top + window.pageYOffset - el.offsetHeight
        rect.top + window.pageYOffset
      }px - 2rem)`;
      const leftPosition =
        rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2;
      el.style.left = `${leftPosition < 0 ? 16 : leftPosition}px`; // Don't let the toolbar go offscreen

      el.querySelectorAll('button').forEach((button) => {
        button.style.opacity = '1';
      });
    }
  }, [editor, inFocus, selection, hoverWithSelection]);

  const editorToolbarClass = mergeStyles({
    position: !hoverWithSelection ? 'relative' : 'absolute',
    zIndex: '10000',
    display: !hoverWithSelection ? 'inline-block' : 'flex',
    flexWrap: 'nowrap',
    columnGap: '1px',
    width: 'auto',
    backgroundColor: hoverWithSelection
      ? isDark
        ? `rgba(255,255,255,1)`
        : `rgba(0,0,0,.95)`
      : 'transparent',
    padding: '.25rem',
    borderRadius: '.25rem',
    animationName: 'fadeInAnimation',
    animationDuration: '.3s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    top: !hoverWithSelection ? '-2px' : TOOLBAR_OFFSCREEN_POSITION,
    left: !hoverWithSelection ? 0 : TOOLBAR_OFFSCREEN_POSITION,
    transition: '.3 ease-in-out opacity',
    boxShadow: hoverWithSelection ? '2px 2px 5px rgba(0,0,0,.05)' : undefined,
    marginRight: '.25rem',

    button: {
      height: '1.25rem',
      width: '1.25rem',
      marginRight: '.25rem',
      i: {
        fontSize: '14px',
      },
      opacity: !hoverWithSelection ? '1' : '0',
      transition: '.1s ease-in-out all',
      color: hoverWithSelection
        ? undefined
        : isDark
          ? `${DEALROOMS_COLORS.white}`
          : `${DEALROOMS_COLORS.themeSecondary}`,

      '@container (width < 30rem)': {
        marginRight: '0',
        width: '1rem',
        i: {
          fontSize: '12px',
        },
      },

      ':hover': {
        backgroundColor: hoverWithSelection
          ? undefined
          : isDark
            ? `${DEALROOMS_COLORS.themePrimary}`
            : `${DEALROOMS_COLORS.themePrimary}`,
        color: hoverWithSelection
          ? undefined
          : isDark
            ? DEALROOMS_COLORS.themePrimary
            : '#fff',
      },
    },

    'button[data-meetingflow-is-active="true"]': {
      color: DEALROOMS_COLORS.woodsmoke,
      backgroundColor: `${DEALROOMS_COLORS.white} !important`,
    },
    opacity: !hoverWithSelection ? '1' : '0',
  });

  const verticalDividerStyles = {
    wrapper: {
      display: 'inline-block',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      width: '1px',
      height: '16px',
      position: 'relative',
      top: '.125rem',

      '@container (width < 30rem)': {
        display: 'none',
      },
    },
    divider: {
      display: 'inline-block',
      width: '1px',
      height: '16px',
      backgroundColor: DEALROOMS_COLORS.neutralLight,
    },
  } as IVerticalDividerStyles;

  const toolbar = (
    <div
      className={editorToolbarClass}
      onMouseDown={(e) => {
        // prevent toolbar from taking focus away from editor
        e.preventDefault();
      }}
      ref={toolbarRef}
    >
      {includedButtonTypes.includes('MARK') ? (
        <>
          <MarkButton
            format="bold"
            iconName="Bold"
            disabled={!!readonly}
            hint={isMobile ? undefined : isMacOrIOS ? 'Cmd+B' : 'Ctrl+B'}
          />
          <MarkButton
            format="italic"
            iconName="Italic"
            disabled={!!readonly}
            hint={isMobile ? undefined : isMacOrIOS ? 'Cmd+I' : 'Ctrl+I'}
          />
          <MarkButton
            format="underline"
            iconName="Underline"
            disabled={!!readonly}
            hint={isMobile ? undefined : isMacOrIOS ? 'Cmd+U' : 'Ctrl+U'}
          />
          <MarkButton
            format="strikethrough"
            iconName="Strikethrough"
            disabled={!!readonly}
            hint={
              isMobile ? undefined : isMacOrIOS ? 'Cmd+Shift+X' : 'Ctrl+Shift+X'
            }
          />
          <MarkButton
            format="highlight"
            iconName="Highlight"
            disabled={!!readonly}
            hint={
              isMobile ? undefined : isMacOrIOS ? 'Cmd+Ctrl+H' : 'Ctrl+Alt+H'
            }
          />
        </>
      ) : null}
      {includedButtonTypes.includes('BLOCK') ? (
        <>
          <VerticalDivider styles={verticalDividerStyles} />
          <BlockButton
            format="heading-one"
            formatName="heading one"
            iconName="Header1"
            disabled={!!readonly}
          />
          <BlockButton
            format="heading-two"
            formatName="heading two"
            iconName="Header2"
            disabled={!!readonly}
          />
          <BlockButton
            format="heading-three"
            formatName="heading three"
            iconName="Header3"
            disabled={!!readonly}
          />
          <BlockButton
            format="block-quote"
            formatName="block quote"
            iconName="RightDoubleQuote"
            disabled={!!readonly}
          />
          <VerticalDivider styles={verticalDividerStyles} />
          <BlockButton
            format="numbered-list"
            formatName="numbered list"
            iconName="NumberedList"
            disabled={!!readonly}
            hint={
              isMobile ? undefined : isMacOrIOS ? 'Cmd+Shift+7' : 'Ctrl+Shift+7'
            }
          />
          <BlockButton
            format="bulleted-list"
            formatName="bulleted list"
            iconName="BulletedList"
            disabled={!!readonly}
            hint={
              isMobile ? undefined : isMacOrIOS ? 'Cmd+Shift+8' : 'Ctrl+Shift+8'
            }
          />
          <BlockButton
            format="checklist-item"
            formatName="checklist"
            iconName="CheckList"
            disabled={!!readonly}
            hint={
              isMobile ? undefined : isMacOrIOS ? 'Cmd+Shift+9' : 'Ctrl+Shift+9'
            }
          />
        </>
      ) : null}
      <VerticalDivider styles={verticalDividerStyles} />
      {includedButtonTypes.includes('LINK') ? (
        <LinkButton
          hint={isMobile ? undefined : isMacOrIOS ? 'Cmd+K' : 'Ctrl+K'}
        />
      ) : null}

      {isListActive(editor) && includedButtonTypes.includes('LIST_INDENT') ? (
        <>
          <ListDeindentButton />
          <ListIndentButton />
        </>
      ) : null}
      {includedButtonTypes.includes('AI-ACTION') && getAIActionContext ? (
        <AIActionButton getAIActionContext={getAIActionContext} />
      ) : null}
      {includedButtonTypes.includes('SIDE-PANEL-SHORTCUT') &&
      getSidePanelShortcutContext ? (
        <SidePanelShortcutButton
          getSidePanelShortcutContext={getSidePanelShortcutContext}
        />
      ) : null}

      {includedButtonTypes.includes('IMAGE') ? (
        <>
          <AddImageButton
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlanId}
          />
        </>
      ) : null}
    </div>
  );

  return !hoverWithSelection ? (
    <div
      style={{
        display: 'inline-block',
      }}
    >
      {toolbar}
    </div>
  ) : (
    <Portal>{toolbar}</Portal>
  );
};

export default DecisionSiteEditorToolbar;
