import { useAuth0 } from '@auth0/auth0-react';
import { Spinner, Text, Icon } from '@fluentui/react';
import {
  DealRoomArtifact,
  DetailedDealRoom,
} from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { isBadRequest, isForbiddenError } from '../../../Helpers/AxiosHelpers';
import { useAddDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useAddDealRoomArtifactDialog';
import { useNavigate } from '../../../Hooks/useNavigate';
import { useTitle } from '../../../Hooks/useTitle';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DropTarget } from '../../Common/DropTarget';
import { DealRoomArtifactsTable } from './DealRoomArtifactsTable';
import { DSTooltip } from '../DS/DSTooltip';
import DSButton from '../DS/DSButton';
import toast from 'react-hot-toast';
import { DSTab, DSTabs } from '../DS';
import { useTheme } from '@mui/material';

type DealRoomArtifactsProps = {
  className?: string;
  artifactContainerClassName?: string;
  organizationSlug: string;
  dealRoomId: number;
  activity?: DetailedDealRoom['activity'];
  refreshDealRoom: () => Promise<unknown>;
  onClick?: (id: DealRoomArtifact['id']) => unknown;
  onAdd?: (item: DealRoomArtifact) => Promise<unknown>;
  onDelete?: (id: DealRoomArtifact['id']) => Promise<unknown>;
};

export const DealRoomArtifacts = ({
  className,
  organizationSlug,
  dealRoomId,
  onClick,
  onAdd,
  onDelete,
}: DealRoomArtifactsProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    createDeferred: showAddDealRoomArtifactDialog,
    dialog: addDealRoomArtifactDialog,
  } = useAddDealRoomArtifactDialog({
    organizationSlug,
    dealRoomId,
  });

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  useTitle('Artifacts');

  const {
    data: dealRoomArtifacts,
    isLoading: artifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
    async () => {
      const token = await getAccessTokenSilently();
      const artifacts = await DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId, includeDeleted: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return artifacts;
    },
    {
      enabled: !!organizationSlug && !!dealRoomId,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        if (isBadRequest(error)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  // meetingflows should not show up in artifacts table
  const filteredArtifacts = useMemo(() => {
    return dealRoomArtifacts?.data?.filter(
      (artifact) => !(artifact.type === 'MEETINGFLOW'),
    );
  }, [dealRoomArtifacts]);

  const undeletedArtifacts = useMemo(
    () => filteredArtifacts?.filter((artifact) => !artifact.deletedAt),
    [filteredArtifacts],
  );
  const deletedArtifacts = useMemo(
    () => filteredArtifacts?.filter((artifact) => artifact.deletedAt),
    [filteredArtifacts],
  );

  useEffect(() => {
    refetchArtifacts();
  }, [refetchArtifacts]);

  const refetch = useCallback(async () => {
    await Promise.all([refetchArtifacts()]);
  }, [refetchArtifacts]);

  const handleOnClick = useCallback(
    (id: DealRoomArtifact['id']) => {
      if (onClick) {
        onClick(id);
      }

      const artifact = dealRoomArtifacts?.data?.find((a) => a.id === id);

      if (!artifact) {
        return;
      }

      navigate(
        `/organization/${organizationSlug}/decisionsite/${dealRoomId}/artifact/${id}`,
        { preserveQuery: true },
      );
    },
    [dealRoomId, dealRoomArtifacts?.data, navigate, onClick, organizationSlug],
  );

  const handleOnDelete = useCallback(
    async (id: DealRoomArtifact['id']) => {
      await refetch();
      await onDelete?.(id);
    },
    [onDelete, refetch],
  );

  const handlePermanentlyDeleteAll = useCallback(async () => {
    if (!deletedArtifacts?.length) return;

    try {
      const token = await getAccessTokenSilently();

      // Delete all artifacts in parallel
      await Promise.all(
        deletedArtifacts.map((artifact) =>
          DealRoomsApiClient.deleteArtifact(
            {
              organizationSlug,
              dealRoomId,
              artifactId: artifact.id,
              soft: false,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
        ),
      );

      await refetchArtifacts();
      toast.success('Successfully emptied trash');
    } catch (err) {
      console.error(err);
      toast.error('Failed to delete artifacts permanently');
    }
  }, [
    deletedArtifacts,
    dealRoomId,
    organizationSlug,
    getAccessTokenSilently,
    refetchArtifacts,
  ]);

  const theme = useTheme();

  const panelStyles = useMemo(() => {
    return {
      backgroundColor: DEALROOMS_COLORS.white,
      marginTop: '1rem',
      padding: '0rem 1rem',
      borderRadius: '0.25rem',
      border: `1px solid #e8e8ec`,
    };
  }, []);

  const noArtifactsStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
  } as React.CSSProperties;

  return (
    <>
      <div className={classNames('dealroom-artifacts', className)}>
        <DSTabs>
          <DSTab
            label="All Artifacts"
            count={undeletedArtifacts?.length || 0}
            value="all"
            panelStyles={panelStyles}
            panel={
              <>
                {undeletedArtifacts?.length ? (
                  <DealRoomArtifactsTable
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                    dealRoomId={dealRoomId}
                    organizationSlug={organizationSlug}
                    refreshDealRoom={refetch}
                    artifacts={undeletedArtifacts ?? []}
                    refetchArtifacts={refetchArtifacts}
                  />
                ) : (
                  <div style={noArtifactsStyle}>
                    {!artifactsLoading ? (
                      <Text>No Artifacts</Text>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                )}
              </>
            }
          />
          <DSTab
            label="Trash"
            count={deletedArtifacts?.length || 0}
            value="trash"
            panelStyles={panelStyles}
            panel={
              <>
                {deletedArtifacts?.length ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginTop: '1rem',
                        gap: '1rem',
                      }}
                    >
                      {(deletedArtifacts?.length ?? 0) > 0 && (
                        <DSButton onClick={handlePermanentlyDeleteAll}>
                          Empty Trash
                        </DSButton>
                      )}
                      <DSTooltip title="Artifacts are available in Trash for 30 days before they are permanently deleted.">
                        <Icon
                          iconName="Info"
                          style={{
                            color: DEALROOMS_COLORS.themeSecondary,
                            fontSize: '14px',
                            cursor: 'help',
                          }}
                        />
                      </DSTooltip>
                    </div>
                    <DealRoomArtifactsTable
                      onClick={handleOnClick}
                      onDelete={handleOnDelete}
                      dealRoomId={dealRoomId}
                      organizationSlug={organizationSlug}
                      refreshDealRoom={refetch}
                      artifacts={deletedArtifacts ?? []}
                      refetchArtifacts={refetchArtifacts}
                    />
                  </>
                ) : (
                  <div style={noArtifactsStyle}>
                    {!artifactsLoading ? (
                      <Text>No Deleted Artifacts</Text>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                )}
              </>
            }
          />
        </DSTabs>
        {addDealRoomArtifactDialog}
      </div>
    </>
  );
};
