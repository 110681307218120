import { styled } from '@mui/material';
import { DealRoomActionItemStatus } from '@meetingflow/common/Api/data-contracts';
import {
  getStatusBackgroundColor,
  getStatusTextColor,
} from './milestonesUtils';

// Styled components
export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '1rem',
  padding: '1rem',
  flex: 1,
  overflowY: 'auto',
});

export const AttachmentsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const AttachmentsContainerTitle = styled('span')({
  fontSize: '16px',
  fontWeight: 600,
});

// Reusable styles objects
export const dropdownMenuStyles = {
  '& .MuiSelect-select': {
    backgroundColor: '#FFFFFF',
  },

  '&.Mui-disabled': {
    backgroundColor: '#f0f0f2 !important',
    '& .MuiSelect-select': {
      backgroundColor: '#f0f0f2 !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.1) !important',
    },
  },
};

export const statusDropdownStyles = (status: DealRoomActionItemStatus) => ({
  width: '100%',
  ...dropdownMenuStyles,
  '& .MuiSelect-select': {
    backgroundColor: '#FFFFFF',

    span: {
      backgroundColor: getStatusBackgroundColor(status),
      color: getStatusTextColor(status),
      padding: '.5rem .75rem',
      borderRadius: '.25rem',
    },
  },

  '& .MuiOutlinedInput-input': {
    color: getStatusTextColor(status),
  },
  '& .MuiSvgIcon-root': {
    color: getStatusTextColor(status),
  },
});
