import { styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  flexWrap: 'wrap',
  marginBottom: '.5rem',
  padding: '0 1rem 0 0 ',
  borderBottom: `1px solid ${DEALROOMS_COLORS.financialPrimaryGray}`,
});

export const Title = styled('div')({
  fontSize: '1.125rem',
  fontWeight: '400',
  color: DEALROOMS_COLORS.themePrimary,
  padding: '0',
});

export const ButtonsContainer = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
});

export const datePickerStyles = {
  position: 'relative',
  top: '1px',
  maxWidth: '120px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    backgroundColor: 'transparent',
  },

  '& .MuiOutlinedInput-input': {
    color: DEALROOMS_COLORS.cloudburst,
  },

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    color: DEALROOMS_COLORS.cloudburst,
  },
};
