import React from 'react';
import { Switch, SwitchProps, useTheme } from '@mui/material';
import { DSSwitchRoot } from './DSSwitch.styles';
import { useDealRoom } from '../../../Hooks/useDealRoom';

export interface DSSwitchProps extends SwitchProps {
  branded?: boolean;
}

const DSSwitch: React.FC<DSSwitchProps> = ({ branded = false, ...props }) => {
  const { dealRoom } = useDealRoom();
  const theme = useTheme();
  const brandColor = dealRoom?.primaryColor || theme.palette.primary.main;

  return (
    <DSSwitchRoot
      sx={{
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: branded ? brandColor : undefined,
        },
        '& .MuiSwitch-switchBase.Mui-focusVisible .MuiSwitch-thumb': {
          color: branded ? brandColor : undefined,
        },
      }}
      {...props}
    />
  );
};

export default DSSwitch;
