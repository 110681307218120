import { useAuth0 } from '@auth0/auth0-react';
import { Spinner, Stack } from '@fluentui/react';
import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';
import { useQueryClient } from 'react-query';
import { ReactEditor, Slate } from 'slate-react';
import { CustomEditable } from '../../Collab/CustomEditable';
import { getCoreEditor } from '../../Collab/Helpers/EditorHelpers';
import { useLinkDialog } from '../../../Hooks/Modals/useLinkDialog';
import { useMentions } from '../../../Hooks/editor/useMentions';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { DealRoomCommandBarButton } from '../Components/DealRoomButton';
import ConversationsElement from '../Components/ConversationsTab/ConversationsElement';
import {
  DealRoomArtifact,
  DealRoomComment,
  SlateElement,
} from '@meetingflow/common/Api/data-contracts';
import { AxiosResponse } from 'axios';
import { activeChords } from '../../../Helpers/KeyboardEventHelpers';
import { isIOS, isMacOs } from 'react-device-detect';
import { Editor, Transforms } from 'slate';
import { ConversationAttachments } from '../Components/ConversationsTab/ConversationAttachments';
import { Artifact } from '../../../types/MilestoneTypes';
import { useAddAttachmentDialog } from '../../../Hooks/Modals/DealRoom/useAddAttachmentDialog';
import { timeAgo } from '../Components/ConversationsTab/conversationTabUtils';
import { useArtifactComments } from '../../../Hooks/useArtifactComments';
import { useCommentsPanelStyles } from '../Components/ConversationsTab/useCommentsPanelStyles';
import { AddToIconProps } from '../../../utils/iconProps';
import { CommentsControls } from '../Components/ConversationsTab/CommentsControls';
import { useBoolean } from '@fluentui/react-hooks';
import useDeviceType from '../../../Hooks/deviceDetection';

const isMacOrIOS = isMacOs || isIOS;

type DealRoomArtifactCommentsPanelProps = {
  organizationSlug: string;
  dealRoomId: number;
  artifactId: number;
  currentThreadId: number | undefined;
  setCurrentThreadId: Function;
  currentContactId: number | undefined;
  currentContactCompanyId: number | undefined;
};

interface DealRoomConvWithComments extends DealRoomComment {
  allReplys: DealRoomComment[] | undefined;
  notifications: number | undefined;
}

const defaultValueCurrentComment: SlateElement[] = [
  { type: 'paragraph', children: [{ text: '' }] },
];

export const DealRoomArtifactCommentsPanel = forwardRef(
  (
    {
      organizationSlug,
      dealRoomId,
      artifactId,
      currentThreadId,
      setCurrentThreadId,
      currentContactId,
      currentContactCompanyId,
    }: DealRoomArtifactCommentsPanelProps,
    ref,
  ) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    const { isMobile } = useDeviceType();

    const { createDeferred: createLinkDialogDeferred, dialog: linkDialog } =
      useLinkDialog();

    const currentComment: MutableRefObject<SlateElement[]> = useRef([
      ...defaultValueCurrentComment,
    ]);

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [editConvId, setEditConvId] = useState<number | null>(null);

    const [
      highlightEditorBorder,
      {
        setFalse: setUnHighlightEditorBorder,
        setTrue: setHighlightEditorBorder,
      },
    ] = useBoolean(false);

    const convListRef = useRef<HTMLDivElement>(null);
    const selectedConvRef = useRef<HTMLDivElement>(null);
    const commentsListRef = useRef<HTMLDivElement | null>(null);

    const [conversationArtifacts, setConversationArtifacts] = useState<
      Artifact[]
    >([]);

    const {
      createDeferred: addAttachmentDeferred,
      dialog: addAttachmentDialog,
    } = useAddAttachmentDialog({
      organizationSlug,
      dealRoomId,
      actionItemId: null,
    });

    const handleOpenAttachmentDialog = useCallback(async () => {
      try {
        const result = await addAttachmentDeferred().promise;
        const currentAttachment = (result as DealRoomArtifact) || null;

        if (currentAttachment) {
          const existsSameAttachment = conversationArtifacts.some(
            (art) => art.id === currentAttachment.id,
          );

          if (existsSameAttachment) {
            return;
          }

          setConversationArtifacts([
            ...conversationArtifacts,
            currentAttachment as Artifact,
          ]);
        }
      } catch (err) {}
    }, [addAttachmentDeferred, conversationArtifacts]);

    const handleDeleteArtifact = (artifactId: number) => {
      setConversationArtifacts(
        conversationArtifacts.filter((artifact) => artifact.id !== artifactId),
      );
    };

    useEffect(() => {
      if (currentThreadId === undefined) {
        currentComment.current = defaultValueCurrentComment;
        setUnHighlightEditorBorder();
      }
    }, [currentThreadId, setUnHighlightEditorBorder]);

    useEffect(() => {
      // remove edited conversation id if we exit from the conversation
      if (currentThreadId === undefined) {
        setEditConvId(null);
      }
    }, [currentThreadId]);

    const {
      artifactComments,
      isLoading: artifactCommentsLoading,
      isFetched: artifactCommentsFetched,
      refetch: refetchArtifactComments,
    } = useArtifactComments(organizationSlug, dealRoomId, artifactId);

    useEffect(() => {
      // Whenever the artifact comments data changes or the edit conv id changes,
      // we need to update the artifacts list to show the correct artifacts in the
      // edit conversation panel.
      // We find the conversation with the matching id and use its artifacts list.
      // If we can't find the conversation, we set the artifacts list to empty.
      const currentConversation = artifactComments?.find(
        (conv) => conv.id === editConvId,
      );
      if (currentConversation && Array.isArray(currentConversation.artifacts)) {
        setConversationArtifacts(currentConversation.artifacts);
      } else {
        setConversationArtifacts([]);
      }
    }, [artifactComments, editConvId]);

    const commentEditor = useMemo(() => {
      return getCoreEditor(
        getAccessTokenSilently,
        organizationSlug,
        createLinkDialogDeferred,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      createLinkDialogDeferred,
      getAccessTokenSilently,
      organizationSlug,
      currentThreadId,
      artifactComments,
    ]);

    const {
      onKeyDown: mentionKeyDownHandler,
      onSlateChange: mentionSlateChangeHandler,
      resetMentions, // use to reset the mentions states when we exit from the conversation or change the conversation or reinitialize the editor
      mentionSuggestions,
    } = useMentions({
      editor: commentEditor,
      organizationSlug,
      allowMentions: true,
      contactId: currentContactId,
      companyId: currentContactCompanyId,
      useMaxZIndex: true,
      removeContactsLimit: true,
    });

    useImperativeHandle(ref, () => ({
      backToConvList() {
        if (selectedConvRef?.current) {
          selectedConvRef.current.style.transition = 'transform 0.3s ease';
          selectedConvRef.current.style.transform = 'translateX(100%)';
        }
        resetMentions();
        setTimeout(() => {
          setCurrentThreadId(undefined);
        }, 300);
      },
    }));

    const sendReply = async () => {
      if (
        JSON.stringify(currentComment.current) ===
          JSON.stringify(defaultValueCurrentComment) &&
        !conversationArtifacts.length
      )
        return;
      setInProgress(true);
      createArtifactConv(currentThreadId === 0 ? null : currentThreadId).then(
        () => {
          resetMentions();
          currentComment.current = defaultValueCurrentComment;
          setInProgress(false);
        },
      );
    };

    const createArtifactConv = async (threadId?: number | null) => {
      if (threadId === 0) {
        openConv(threadId).then();
        return;
      }
      if (
        JSON.stringify(currentComment.current) ===
          JSON.stringify(defaultValueCurrentComment) &&
        !conversationArtifacts.length
      )
        return;
      const token = await getAccessTokenSilently();
      const createdConv: AxiosResponse<DealRoomComment> =
        await DealRoomsApiClient.createArtifactComment(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            comment: currentComment.current,
            ...(threadId ? { threadId } : {}),
            artifacts: conversationArtifacts.map((artifact) => artifact.id),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

      await refetchArtifactComments();
      if (threadId === null && createdConv?.data?.id) {
        setCurrentThreadId(createdConv.data.id);
      }
    };

    const openConv = async (convId: number) => {
      if (convListRef?.current) {
        convListRef.current.style.transition = 'transform 0.3s ease';
        convListRef.current.style.transform = 'translateX(-100%)';
      }
      setTimeout(() => {
        setCurrentThreadId(convId);
      }, 300);
    };

    const deleteArtifactConv = async (convId: number) => {
      try {
        setInProgress(true);
        const token = await getAccessTokenSilently();
        await DealRoomsApiClient.deleteArtifactComment(
          organizationSlug,
          dealRoomId,
          artifactId,
          convId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        await refetchArtifactComments();
        if (editConvId === convId) {
          setEditConvId(null);
        }
        setInProgress(false);
      } catch (error) {
        setInProgress(false);
      }
    };

    // const onKeyDown = useCallback(
    //   (event: React.KeyboardEvent<HTMLDivElement>) => {
    //     const { ctrlChord, cmdChord } = activeChords(event);
    //
    //     if (
    //       ((isMacOrIOS && cmdChord) || (!isMacOrIOS && ctrlChord)) &&
    //       event.key === 'a'
    //     ) {
    //       event.preventDefault();
    //       event.stopPropagation();
    //
    //       Transforms.select(editor, []);
    //
    //       return true;
    //     }
    //
    //     // const mentionHandled = mentionKeyDownHandler(event);
    //     // if (mentionHandled) {
    //     //   return true;
    //     // }
    //   },
    //   [editor],
    // );
    const handleMentionClick = useCallback(() => {
      // Insert the '@' character at the end of the editor
      Transforms.insertText(commentEditor, '@', {
        at: Editor.end(commentEditor, []),
      });
      // Focus the editor and move the cursor to the end
      ReactEditor.focus(commentEditor);
      Transforms.select(commentEditor, Editor.end(commentEditor, []));
    }, [commentEditor]);

    const handleSetCommentForEdit = useCallback(
      (v: SlateElement[] | null, convId: number | null) => {
        // Update the current comment with the provided value or default
        currentComment.current = v ?? defaultValueCurrentComment;

        // Perform editor operations without normalizing
        Editor.withoutNormalizing(commentEditor, () => {
          // Remove existing content from the editor
          Transforms.removeNodes(commentEditor, {
            at: [0],
            voids: true,
          });

          // Insert the new comment content into the editor
          Transforms.insertNodes(
            commentEditor,
            v ?? defaultValueCurrentComment,
          );
        });

        // Set the ID of the conversation being edited
        setEditConvId(convId);
      },
      [commentEditor],
    );

    const handleEditConversation = useCallback(async () => {
      // Check if there's a comment to edit and a valid conversation ID
      if (
        !currentComment?.current ||
        editConvId === null ||
        editConvId === undefined
      )
        return;

      if (
        JSON.stringify(currentComment.current) ===
          JSON.stringify(defaultValueCurrentComment) &&
        !conversationArtifacts.length
      )
        return;

      try {
        setInProgress(true);

        // Send API request to update the artifact comment
        const response = await DealRoomsApiClient.updateArtifactComment(
          organizationSlug,
          dealRoomId,
          artifactId,
          editConvId,
          {
            comment: currentComment?.current,
            artifacts: conversationArtifacts.map((artifact) => artifact.id),
          },
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          },
        );

        if (response.status === 200) {
          // Invalidate and refetch comments query to update UI
          await refetchArtifactComments();
          resetMentions();
          // Reset comment state and exit edit mode
          currentComment.current = defaultValueCurrentComment;
          setEditConvId(null);
          setInProgress(false);
        } else {
          resetMentions();
          setInProgress(false);
        }
      } catch (error) {
        resetMentions();
        console.error('Failed to update comment:', error);
        setInProgress(false);
      }
    }, [
      artifactId,
      conversationArtifacts,
      dealRoomId,
      editConvId,
      getAccessTokenSilently,
      organizationSlug,
      refetchArtifactComments,
      resetMentions,
    ]);

    const getConversationListKey = useCallback(
      (currentConv: DealRoomConvWithComments | undefined) => {
        // create a unique key for each conversation based on its content (comments) to update all slate editors on conversation changes
        const currentComment = currentConv?.comment || [];
        const allRepliesComments =
          currentConv?.allReplys?.flatMap((reply) => reply.comment) || [];

        return JSON.stringify([...currentComment, ...allRepliesComments]);
      },
      [],
    );

    const styles = useCommentsPanelStyles({
      commentsPanelType: 'artifact',
      textEditorHighlightedBorder: highlightEditorBorder,
      isMobile,
    });

    const convList: DealRoomComment[] | [] =
      artifactComments?.filter(
        (conversation: DealRoomComment) =>
          !conversation.threadId && !conversation.replyToId,
      ) || [];

    const convListWithComments: DealRoomConvWithComments[] | [] = convList.map(
      (conv: DealRoomComment) => {
        let notifications = 0;
        const allReplys = artifactComments?.filter(
          (origConv: DealRoomComment) => origConv.threadId === conv.id,
        );
        allReplys?.forEach((convReply) => {
          if (timeAgo(convReply.updatedAt, true)) notifications++;
        });
        return {
          ...conv,
          allReplys,
          notifications,
        };
      },
    );

    const currentConv: DealRoomConvWithComments | undefined =
      convListWithComments?.find(
        (conversation: DealRoomConvWithComments) =>
          conversation?.id === currentThreadId,
      );

    useEffect(() => {
      // When the current conversation changes, scroll to the bottom of the comments list
      setTimeout(() => {
        commentsListRef?.current?.scrollTo({
          top: commentsListRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 200);
    }, [currentConv?.allReplys?.length]);

    const slateComp = (conversation: DealRoomComment | null) => (
      <Slate
        editor={getCoreEditor(
          getAccessTokenSilently,
          organizationSlug,
          createLinkDialogDeferred,
        )}
        // @ts-ignore
        initialValue={conversation?.comment}
      >
        <CustomEditable
          organizationSlug={organizationSlug}
          name={`ArtifactConversation_#${conversation?.id}`}
          editorMaxHeight={'fit-content'}
          onKeyDown={(event) => true}
          readonly={true}
          simpleTextRenderer={true}
          conversationsMode={false}
        />
      </Slate>
    );

    const setEditorBorderHighlight = useCallback(
      (currentValue: SlateElement[]) => {
        // If the current value matches the default value and there are no conversation artifacts, remove highlight else highlight the border of the editor
        if (
          JSON.stringify(currentValue) ===
            JSON.stringify(defaultValueCurrentComment) &&
          !conversationArtifacts.length
        ) {
          setUnHighlightEditorBorder();
        } else {
          setHighlightEditorBorder();
        }
      },
      [
        conversationArtifacts.length,
        setHighlightEditorBorder,
        setUnHighlightEditorBorder,
      ],
    );

    useEffect(() => {
      // Make the check for highlight border when the conversation artifacts list change
      setEditorBorderHighlight(currentComment.current);
    }, [conversationArtifacts.length, setEditorBorderHighlight]);

    const onKeyDownWithMentions = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { ctrlChord, cmdChord } = activeChords(event);

        setEditorBorderHighlight(currentComment.current);

        if (
          ((isMacOrIOS && cmdChord) || (!isMacOrIOS && ctrlChord)) &&
          event.key === 'a'
        ) {
          event.preventDefault();
          event.stopPropagation();

          Transforms.select(commentEditor, []);

          return true;
        }

        const mentionHandled = mentionKeyDownHandler(event);
        if (mentionHandled) {
          return true;
        }
      },
      [commentEditor, mentionKeyDownHandler, setEditorBorderHighlight],
    );

    const onSlateChange = useCallback(
      (v: SlateElement[]) => {
        mentionSlateChangeHandler(v);
        currentComment.current = v;
        setEditorBorderHighlight(v);
      },
      [mentionSlateChangeHandler, setEditorBorderHighlight],
    );

    const slateCompEditable = () => (
      <Slate
        editor={commentEditor}
        initialValue={currentComment?.current}
        // @ts-ignore
        onChange={onSlateChange}
      >
        <CustomEditable
          organizationSlug={organizationSlug}
          name={`ArtifactConversation_#${currentConv?.id || 'editable'}`}
          editorMaxHeight={'fit-content'}
          onKeyDown={onKeyDownWithMentions}
          readonly={false}
          simpleTextRenderer={true}
          conversationsMode={true}
          placeholder={
            currentConv?.id !== undefined ? 'Type a reply' : 'Type a comment'
          }
        />
        {mentionSuggestions}
      </Slate>
    );

    return (
      <div className={styles.convWrapper}>
        {currentThreadId === undefined && (
          <div ref={convListRef} className={styles.conversationsContainer}>
            <div className="addConvContainer">
              <DealRoomCommandBarButton
                customClasses="addConvButton"
                buttonStyleType="COMMAND_BAR"
                iconProps={AddToIconProps}
                onClick={(event) => {
                  // event.preventDefault();
                  event.stopPropagation();
                  return createArtifactConv(0);
                }}
              >
                New comment
              </DealRoomCommandBarButton>
            </div>
            <Stack className="conversationsList">
              {artifactCommentsLoading && !artifactCommentsFetched ? (
                <Spinner />
              ) : (
                convListWithComments.map(
                  (conversation: DealRoomConvWithComments) => {
                    return (
                      <ConversationsElement
                        key={conversation.id}
                        conversation={conversation}
                        convClick={openConv}
                        convDelete={deleteArtifactConv}
                        timeAgo={timeAgo(
                          conversation?.allReplys &&
                            conversation?.allReplys.length
                            ? conversation.allReplys[
                                conversation.allReplys.length - 1
                              ].updatedAt
                            : conversation.updatedAt,
                        )}
                        timeAgoNotification={timeAgo(
                          conversation?.allReplys &&
                            conversation?.allReplys.length
                            ? conversation.allReplys[
                                conversation.allReplys.length - 1
                              ].updatedAt
                            : conversation.updatedAt,
                          true,
                        )}
                        notifications={conversation.notifications}
                      >
                        {slateComp(conversation)}
                      </ConversationsElement>
                    );
                  },
                )
              )}
            </Stack>
          </div>
        )}
        {currentThreadId !== undefined && (
          <div ref={selectedConvRef} className={styles.selectedConvContainer}>
            <div ref={commentsListRef} className="commentsList">
              {currentConv?.comment && (
                <React.Fragment key={getConversationListKey(currentConv)}>
                  <ConversationsElement
                    key={currentConv.id}
                    isComment={true}
                    conversation={currentConv}
                    timeAgo={timeAgo(currentConv.updatedAt)}
                    handleSetCommentForEdit={handleSetCommentForEdit}
                    editConvId={editConvId}
                    isFirstComment={true}
                  >
                    {slateComp(currentConv)}
                  </ConversationsElement>
                  {currentConv?.allReplys?.map(
                    (conversation: DealRoomComment, convIndex: number) => (
                      <ConversationsElement
                        key={`${conversation.id}_${convIndex}`}
                        isComment={true}
                        conversation={conversation}
                        timeAgo={timeAgo(conversation.updatedAt)}
                        handleSetCommentForEdit={handleSetCommentForEdit}
                        editConvId={editConvId}
                        timeAgoNotification={timeAgo(
                          conversation.updatedAt,
                          true,
                        )}
                        convDelete={deleteArtifactConv}
                      >
                        {slateComp(conversation)}
                      </ConversationsElement>
                    ),
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="writeCommentContainer">
              <div className="writeMainCommentContainer">
                {!inProgress && slateCompEditable()}
                {inProgress && <div style={{ height: '2rem' }} />}
                <ConversationAttachments
                  artifacts={conversationArtifacts}
                  onDelete={handleDeleteArtifact}
                />
                <CommentsControls
                  handleOpenAttachmentDialog={handleOpenAttachmentDialog}
                  handleMentionClick={handleMentionClick}
                  handleSend={async () => {
                    if (editConvId) {
                      await handleEditConversation();
                    } else {
                      await sendReply();
                    }
                  }}
                  inProgress={inProgress}
                />
                {addAttachmentDialog}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);
