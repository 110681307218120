import {
  styled,
  Stack,
  Badge,
  IconButton,
  Avatar,
  Chip,
  Typography,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { isMobile } from 'react-device-detect';

export const CompanyStack = styled(Stack)(({ theme }) => ({
  paddingLeft: 0,
}));

export const CompanyInnerStack = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  marginTop: theme.spacing(1),
}));

export const CompanyAvatar = styled(Avatar)(() => ({
  width: 40,
  height: 40,
}));

export const CompanyBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    position: 'relative',
    transform: 'none',
    fontSize: '0.6rem',
    height: '16px',
    minWidth: '20px',
    lineHeight: '16px',
    marginLeft: theme.spacing(0.5),
    backgroundColor: DEALROOMS_COLORS.financialLightGray,
    color: DEALROOMS_COLORS.cloudburst,
  },
}));

export const InternalTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'component',
})(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(-0.25),
  color: DEALROOMS_COLORS.cloudburst,
}));

export const LinkedInButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'href' && prop !== 'target',
})<{ href?: string; target?: string }>`
  padding: 0;
  paddingleft: ${({ theme }) => theme.spacing(0.5)};
  filter: grayscale(100%);
  opacity: 0.6;
  position: absolute;
  left: -1.25rem;
  top: 0.2rem;
  transition: 0.3s ease-in-out all;
  &:hover {
    filter: grayscale(0%);
    opacity: 1;
  }
`;

export const LinkedInImage = styled('img')(() => ({
  width: 12,
  height: 12,
}));

export const AttendeeChip = styled(Chip)(() => ({
  backgroundColor: 'transparent',
  padding: 0,
  height: 'auto',
  maxWidth: '100%',

  '& .MuiChip-label': {
    color: DEALROOMS_COLORS.cloudburst,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    lineHeight: '1.2',
    padding: '4px 8px',
  },

  '&:hover': {
    backgroundColor: DEALROOMS_COLORS.cloudburst,

    '& .MuiChip-label': {
      color: DEALROOMS_COLORS.white,
    },
  },
}));

export const AttendeeName = styled(Typography)(({ theme }) => ({
  variant: 'subtitle2',
  component: 'div',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

export const CompanyName = styled(Typography)(({ theme }) => ({
  variant: 'subtitle2',
  component: 'div',
  display: 'flex',
  alignItems: 'center',
  fontWeight: '500',
  gap: theme.spacing(0.5),
}));

export const AIMenuButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  minWidth: '32px !important',
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  position: 'relative',
  color: DEALROOMS_COLORS.cloudburst,
  '&.MuiButton-root': {
    padding: 0,
    minWidth: '32px',
  },
  '& .MuiButton-startIcon': {
    margin: 0,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: DEALROOMS_COLORS.cloudburst,
  },
  '& .MuiSvgIcon-root': {
    fontSize: '22px',
    margin: 0,
  },
}));

export const AttendeeNameStack = styled(Stack)`
  align-items: flex-start;
  flex-direction: ${isMobile ? 'column' : 'row'} !important; // force flex-direction: row;
  gap: ${({ theme }) => isMobile ? 0 : theme.spacing(1)};
  position: relative;
`;

export const AttendeeEmail = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
