import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const InfoSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '.25rem',
  backgroundColor: DEALROOMS_COLORS.neutralGray,
  boxShadow: '0px 2px 3px 0px #00000008',
  containerType: 'inline-size',
}));

export const InfoSectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.breakpoints.down('sm') ? '1rem' : '.75rem 1rem',
  backgroundColor: DEALROOMS_COLORS.white,
  borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '.25rem .25rem 0 0',
  gap: '.5rem',
}));

export const HeaderIconWrapper = styled(Box)({
  display: 'flex',
  height: 'auto',
  // '& > svg': {
  //   color: DEALROOMS_COLORS.themePrimary,
  // },
});

export const InfoSectionContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '1rem',
  borderRadius: '0 0 .25rem .25rem',
  overflow: 'auto',
  '& > div': {
    overflow: 'visible',
    '& > button': {
      zIndex: 1,
    },
  },
}));
