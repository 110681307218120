import * as React from 'react';
import { Menu, styled } from '@mui/material';
import { BorderBottom } from '@mui/icons-material';
import { he } from 'date-fns/locale';

export const DSMenuRoot = styled(Menu)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),

    '.MuiMenu-paper': {
        // menu root
    },

    '.MuiList-root': {
        padding: 0,

        '& .MuiMenuItem-root': {
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
        },

        '.MuiMenuItem-divider': {
            padding: 0,
            border: 'none',
            height: '1px',
            backgroundColor: theme.palette.grey[400],
            minHeight: 0,
            margin: 0,
        },

        '& .MuiMenuItem-root:last-child': {
            borderBottom: 'none',
        },
    },
}));