import { useState, useCallback, useEffect, useMemo } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { InfoSection } from './InfoSection';
import toast from 'react-hot-toast';
import useDeviceType from '../../../../Hooks/deviceDetection';
import {
  Star,
  Edit as EditIconMui,
  Check as CheckIcon,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import DSButton from '../../DS/DSButton';
import {
  KeyFactCard,
  KeyFactHeader,
  KeyFactTitle,
  EditButton,
  EditIcon,
  EditContainer,
  StyledTextField,
  SaveButton,
  KeyFactValue,
  KeyFactsGrid,
} from './DealRoomKeyFacts.styles';

// Define the structure for callout card form data
interface KeyFactData {
  id: number;
  title: string;
  value: string;
  editableByBuyer: boolean;
}

export const DealRoomKeyFacts = () => {
  const [keyFacts, setKeyFacts] = useState<KeyFactData[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();

  const loadKeyFacts = useCallback(async () => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }

    const token = await getAccessTokenSilently();
    const response = await DealRoomsApiClient.listKeyFacts(
      organizationSlug,
      dealRoomId,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    setKeyFacts(response.data as KeyFactData[]);
  }, [organizationSlug, dealRoomId, getAccessTokenSilently, setKeyFacts]);

  useEffect(() => {
    loadKeyFacts();
  }, [loadKeyFacts]);

  const sortedKeyFacts = useMemo(() => {
    if (keyFacts.every((fact) => 'order' in fact)) {
      return [...keyFacts].sort(
        (a, b) => (a.order as number) - (b.order as number),
      );
    }
    return keyFacts;
  }, [keyFacts]);

  if (keyFacts.length === 0) {
    return null;
  }

  if (!organizationSlug || !dealRoomId) {
    return null;
  }

  return (
    <InfoSection
      icon={<Star fontSize="medium" color="secondary" />}
      title="Key Details"
    >
      <KeyFactsGrid>
        {sortedKeyFacts.map((keyFact) => (
          <DealRoomKeyFactCard
            key={keyFact.id}
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            keyFact={keyFact}
          />
        ))}
      </KeyFactsGrid>
    </InfoSection>
  );
};

const DealRoomKeyFactCard = ({
  organizationSlug,
  dealRoomId,
  keyFact,
}: {
  organizationSlug: string;
  dealRoomId: number;
  keyFact: KeyFactData;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [editing, { setTrue: setEditing, setFalse: setNotEditing }] =
    useBoolean(false);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    setTextValue(keyFact.value || '');
  }, [keyFact.value]);

  const handleSave = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      await DealRoomsApiClient.updateKeyFact(
        organizationSlug,
        dealRoomId,
        keyFact.id,
        {
          value: textValue,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      toast.success('Updated successfully');
    } catch (error) {
      toast.error('Failed to update');
    }
  }, [
    getAccessTokenSilently,
    textValue,
    organizationSlug,
    dealRoomId,
    keyFact,
  ]);

  return (
    <KeyFactCard>
      <KeyFactHeader>
        <KeyFactTitle variant="subtitle2">{keyFact.title}</KeyFactTitle>
        {keyFact.editableByBuyer && !editing && (
          <EditButton className="edit" onClick={setEditing} size="small">
            <EditIconMui fontSize="small" />
          </EditButton>
        )}
      </KeyFactHeader>
      {editing ? (
        <EditContainer>
          <StyledTextField
            placeholder="Insert text"
            value={textValue}
            onChange={(event) => {
              setTextValue(event.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                e.preventDefault();
                e.stopPropagation();
                setNotEditing();
              }
            }}
            fullWidth
            size="small"
          />
          <DSButton
            size="small"
            onClick={() => {
              handleSave();
              setNotEditing();
            }}
            component={SaveButton}
          >
            <CheckIcon fontSize="small" />
          </DSButton>
        </EditContainer>
      ) : (
        <Stack direction="row" spacing={0.5}>
          <KeyFactValue variant="body1" isLongText={textValue?.length > 12}>
            {textValue || 'N/A'}
          </KeyFactValue>
        </Stack>
      )}
    </KeyFactCard>
  );
};
