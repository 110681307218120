import { IconButton, Menu } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useCallback, useState } from 'react';
import { useDeleteTaskDialog } from '../../../Hooks/Modals/DealRoom/useDeleteTaskDialog';
import { DSMenu, DSMenuItem } from '../DS';

export const ActionItemMoreOptions = ({
  actionItemId,
  isSidePanel,
  organizationSlug,
  dealRoomId,
  handleViewTask,
  deleteCallback,
}: {
  actionItemId: number;
  isSidePanel?: boolean | undefined;
  organizationSlug: string;
  dealRoomId: number;
  handleViewTask?: () => void;
  deleteCallback?: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { createDeferred: deleteTaskDeferred, dialog: deleteTaskDialog } =
    useDeleteTaskDialog({
      organizationSlug,
      dealRoomId,
      taskId: actionItemId,
    });

  const handleClickDeleteTask = useCallback(() => {
    deleteTaskDeferred()
      .promise.then(() => {
        if (deleteCallback) deleteCallback();
        setAnchorEl(null);
      })
      .catch(() => {});
  }, [deleteCallback, deleteTaskDeferred]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewClick = () => {
    if (handleViewTask) {
      handleViewTask();
      setAnchorEl(null);
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{
          padding: '4px',
        }}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <DSMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!isSidePanel && (
          <DSMenuItem
            onClick={handleViewClick}
            disabled={!!isSidePanel}
            icon={<VisibilityIcon fontSize="small" />}
            primaryText="View Task"
          />
        )}
        <DSMenuItem
          onClick={handleClickDeleteTask}
          disabled={actionItemId === undefined || actionItemId === null}
          icon={<DeleteIcon fontSize="small" />}
          primaryText="Delete Task"
        />
      </DSMenu>
      {deleteTaskDialog}
    </div>
  );
};
