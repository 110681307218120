import { mergeStyleSets } from '@fluentui/react';
import type { IStyle } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

interface IKeyFactStyles {
  container: IStyle;
  dragHandle: IStyle;
}

export const getKeyFactStyles = (isDragging?: boolean) =>
  mergeStyleSets<IKeyFactStyles>({
    container: {
      padding: 20,
      border: `1px solid ${DEALROOMS_COLORS.lightGray}`,
      backgroundColor: isDragging
        ? DEALROOMS_COLORS.neutralGray
        : DEALROOMS_COLORS.white,
      borderRadius: '4px',
      boxShadow: isDragging
        ? '0 12px 24px rgba(0,0,0,0.15)'
        : '0 2px 4px rgba(0,0,0,0.1)',
      transition: 'transform 200ms cubic-bezier(0.2, 0, 0, 1)',
      transform: isDragging ? 'scale(1.02)' : 'scale(1)',
      touchAction: 'none', // Prevent browser touch actions
      userSelect: 'none', // Prevent text selection while dragging
    },
    dragHandle: {
      cursor: 'grab',
      padding: '.5rem',
      minWidth: '2rem',
      height: '2rem',
      selectors: {
        ':hover': { backgroundColor: DEALROOMS_COLORS.neutralGray },
        ':active': { cursor: 'grabbing' }, // Change cursor while dragging
        '@media (hover: none)': {
          backgroundColor: DEALROOMS_COLORS.neutralGray,
          padding: '0.75rem', // Larger touch target on mobile
          minWidth: '2.5rem',
          height: '2.5rem',
        },
      },
    },
  });
