/**
 * Core hook for managing meeting flow data and state.
 * This hook centralizes the fetching and management of all meeting-related data including:
 * - Meeting plan details
 * - Call recording status
 * - Real-time transcription
 * - Speaker information and styling
 *
 * This hook is designed to be a single source of truth for meeting data,
 * providing a unified interface for accessing and manipulating meeting state.
 */
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
  UseQueryResult,
} from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { MeetingflowsApiClient } from '../../../../../Services/NetworkCommon';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import {
  Utterance,
  GetCallRecordingStatusData,
  CallRecordingStatus,
  DetailedMeetingflow,
  Contact,
  Attendee,
  Company,
} from '@meetingflow/common/Api/data-contracts';
import { useMeetingflowRecorderStatus } from './useMeetingflowRecorderStatus';
import { useMeetingflowTranscript } from './useMeetingflowTranscript';
import { useMeetingflowSpeakers } from './useMeetingflowSpeakers';
import {
  transcriptify,
  getTranscriptStats,
} from '@meetingflow/common/TranscriptHelpers';
import { useCallback, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

/**
 * Query key for meeting plan data. Used by react-query for caching and invalidation.
 *
 * This query key is used to identify the meeting plan data in the react-query cache,
 * allowing for efficient caching and invalidation of meeting data.
 */
export const MeetingPlanQuery = (meetingflowId: string) => [
  'meetingflow',
  meetingflowId,
];

/**
 * Return type definition for useMeetingflow hook.
 * Includes all essential data and utilities for rendering a meeting interface.
 *
 * This type definition provides a clear interface for the data and utilities
 * provided by the useMeetingflow hook, making it easier to understand and use.
 */
export type UseMeetingflowReturnType = {
  meetingflow: DetailedMeetingflow | undefined;
  meetingflowFetched: boolean;
  refetchMeetingflow: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    QueryObserverResult<AxiosResponse<DetailedMeetingflow, any>, unknown>
  >;
  meetingflowLoading: boolean;
  meetingflowError: unknown;
  recorderStatus: CallRecordingStatus | null;
  callRecorderStatus: GetCallRecordingStatusData;
  recorderStatusLoading: boolean;
  refetchRecorderStatus: () => Promise<
    UseQueryResult<GetCallRecordingStatusData>
  >;
  transcript: Utterance[];
  transcriptLoading: boolean;
  refetchTranscript: () => Promise<UseQueryResult<Utterance[]>>;
  speakerNames: string[];
  getContactForParticipant: (participantId: string) => Contact | undefined;
  getColorForSpeaker: (speakerId: string) => string;
  callStats: ReturnType<typeof getTranscriptStats> | undefined;
  turns: ReturnType<typeof transcriptify>;
  isLoading: boolean;
  companies: Company[] | undefined;
  internalCompanies: Company[] | undefined;
  externalCompanies: Company[] | undefined;
  attendees: Attendee[] | undefined;
  internalAttendees: Attendee[] | undefined;
  externalAttendees: Attendee[] | undefined;
  attendeesByDomain: Record<string, Attendee[]>;
  sortedAttendeesByDomain: { [key: string]: Attendee[] };
  withDealRoomContacts: Contact[];
  isWithDealRoomContacts: boolean;
  isInPast: boolean;
  isInFuture: boolean;
  isCurrentlyHappening: boolean;
  isVideoMeeting: boolean;
  scheduleCallRecording: (e: React.MouseEvent) => Promise<void>;
  isRecordingScheduledOrActive: boolean;
  timeToStart: number;
  timeToEnd: number;
  isBeforeStart: boolean;
  isBeforeEnd: boolean;
};

/**
 * Main hook for managing meeting flow state and data.
 * Coordinates multiple sub-hooks to provide a complete meeting data solution.
 *
 * This hook is the central point for managing meeting data and state,
 * providing a unified interface for accessing and manipulating meeting data.
 *
 * @param meetingflowId - Unique identifier for the meeting session
 * @returns Object containing meeting data, transcripts, speaker info, and utility functions
 */
export const useMeetingflow = (
  meetingflowId?: string,
  onRecorderStatusChange?: (newStatus: CallRecordingStatus) => void,
): UseMeetingflowReturnType => {
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug, internalDomains } = useOrganization();
  const { dealRoom } = useDealRoom();
  const appInsights = useAppInsightsContext();

  // Fetch the core meeting plan data, which includes meeting configuration,
  // attendee information, and call recording details
  const {
    data: meetingflowData,
    isFetched: meetingflowFetched,
    refetch: refetchMeetingflow,
    isLoading: meetingflowLoading,
    error: meetingflowError,
  } = useQuery(
    MeetingPlanQuery(meetingflowId!),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getMeetingflow(
        organizationSlug!,
        meetingflowId!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!meetingflowId && !!organizationSlug, // Only fetch when we have required IDs
      refetchOnReconnect: true, // Ensure data consistency after connection issues
      refetchOnWindowFocus: true, // Keep data fresh when user returns to tab
      refetchOnMount: true, // Refresh data when component mounts
    },
  );

  const meetingflow = meetingflowData?.data;

  // Manage call recording status tracking. This is crucial for determining
  // when transcription data becomes available
  const {
    recorderStatus,
    isLoading: recorderStatusLoading,
    refetch: refetchRecorderStatus,
    callRecorderStatus,
  }: {
    recorderStatus: CallRecordingStatus | null;
    isLoading: boolean;
    refetch: () => Promise<UseQueryResult<GetCallRecordingStatusData>>;
    callRecorderStatus: GetCallRecordingStatusData;
  } = useMeetingflowRecorderStatus({
    meetingPlanId: meetingflowId,
    meetingPlanCallRecordingId: meetingflow?.callRecording?.id,
    meetingPlanCallRecordingLastStatus: meetingflow?.callRecording?.lastStatus,
  });

  // Handle real-time transcript data management. This updates as new
  // transcription data becomes available during the meeting
  const {
    transcript,
    isLoading: transcriptLoading,
    refetch: refetchTranscript,
  } = useMeetingflowTranscript({
    meetingPlanId: meetingflowId,
    recorderStatus,
    meetingPlanTranscript: meetingflow?.callRecording?.transcript,
  });

  // Manage speaker identification and styling. This provides consistent
  // representation of speakers across the UI
  const { speakerNames, getContactForParticipant, getColorForSpeaker } =
    useMeetingflowSpeakers({
      transcript,
      attendees: meetingflow?.attendees,
    });

  // Calculate analytics and statistics from the transcript data.
  // This is memoized to prevent unnecessary recalculations
  const callStats = useMemo(
    () =>
      transcript.length
        ? getTranscriptStats(transcriptify(transcript))
        : undefined,
    [transcript],
  );

  // Process the transcript into conversation turns for structured display.
  // This transformation helps in presenting the conversation flow
  const turns = useMemo(() => transcriptify(transcript, false), [transcript]);

  /**
   * Filter attendees based on various criteria:
   * - Remove common resource domains
   * - Apply creator/organizer exclusions
   * - Filter internal/external based on domain
   */
  const filteredAttendees: Attendee[] = useMemo(() => {
    // NOTE: Removed filtering for now, may return
    return meetingflow?.attendees ?? [];
  }, [meetingflow?.attendees]);

  /**
   * Separate attendees into internal and external groups
   * based on organization's domain list
   */
  const internalAttendees = useMemo(
    () =>
      filteredAttendees.filter((a) => internalDomains.includes(a.emailDomain)),
    [filteredAttendees, internalDomains],
  );

  const externalAttendees = useMemo(
    () =>
      filteredAttendees.filter((a) => !internalDomains.includes(a.emailDomain)),
    [filteredAttendees, internalDomains],
  );

  /**
   * Group attendees by their email domains
   * Used for company-based organization
   */
  const attendeesByDomain = useMemo(
    () => GroupBy(filteredAttendees, (a) => a.emailDomain),
    [filteredAttendees],
  );

  /**
   * Sort domains by number of attendees
   * Creates a hierarchy of most represented domains
   */
  const sortedAttendeesByDomain = useMemo(
    () =>
      Object.keys(attendeesByDomain)
        .sort(
          (a, b) => attendeesByDomain[b].length - attendeesByDomain[a].length,
        )
        // @ts-ignore
        // eslint-disable-next-line no-sequences
        .reduce((acc, key) => ((acc[key] = attendeesByDomain[key]), acc), {}),
    [attendeesByDomain],
  );

  /**
   * Identify which DealRoom contacts are present in the meeting
   * Only considers external attendees
   */
  const withDealRoomContacts = useMemo(() => {
    if (!dealRoom?.contacts || !externalAttendees) {
      return [];
    }

    return dealRoom.contacts.filter((contact) =>
      externalAttendees.some(
        (attendee) =>
          attendee.email.toLowerCase() === contact.email.toLowerCase(),
      ),
    );
  }, [dealRoom?.contacts, externalAttendees]);

  const isWithDealRoomContacts = withDealRoomContacts.length > 0;

  /**
   * Filter and organize companies based on attendee domains
   * Supports limiting to specific companies if provided
   */
  const filteredCompanies = useMemo(() => {
    const domainKeys = Object.keys(attendeesByDomain);

    return meetingflow?.companies.filter((c) =>
      c.domains?.some((d) => domainKeys.includes(d.domain)),
    );
  }, [meetingflow?.companies, attendeesByDomain]);

  /**
   * Separate companies into internal and external groups
   * Used for organizational display and filtering
   */
  const externalCompanies = useMemo(
    () => filteredCompanies?.filter((c) => !c.isInternal),
    [filteredCompanies],
  );
  const internalCompanies = useMemo(
    () => filteredCompanies?.filter((c) => !!c.isInternal),
    [filteredCompanies],
  );

  // Calculate meeting timing states
  const timeToStart = meetingflow
    ? DateTime.fromISO(meetingflow.startTime).diffNow().milliseconds
    : 0;
  const timeToEnd = meetingflow
    ? DateTime.fromISO(meetingflow.endTime).diffNow().milliseconds
    : 0;
  const isBeforeStart = timeToStart > 0;
  const isBeforeEnd = timeToEnd > 0;

  // Check if meeting is in an active or scheduled state
  const isRecordingScheduledOrActive =
    meetingflow?.callRecording?.lastStatus &&
    [
      'scheduled',
      'ready',
      'joining_call',
      'in_waiting_room',
      'in_call_not_recording',
      'in_call_recording',
    ].includes(meetingflow.callRecording.lastStatus);

  /**
   * Handles recording actions for scheduled meetings
   * - Starts/stops recording based on current state
   * - Handles scheduling for future meetings
   * - Manages cancellation of scheduled recordings
   * - Updates UI and triggers analytics events
   */
  const scheduleCallRecording = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!meetingflow) return;

      if (!meetingflow.conferenceInfo && !isRecordingScheduledOrActive) return;

      const conferenceType = meetingflow?.conferenceInfo?.type;
      if (
        conferenceType &&
        ['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(conferenceType)
      ) {
        switch (conferenceType) {
          case 'ciscoWebEx':
            toast.error(`WebEx is not a supported platform`);
            return;
          case 'skypeForBusiness':
            toast.error(`Skype For Business is not a supported platform`);
            return;
          case 'skype':
            toast.error(`Skype is not a supported platform`);
            return;
        }
        return;
      }

      if (!meetingflow.callRecording && !isBeforeEnd) return;

      const botStatus = !meetingflow.callRecording
        ? ''
        : meetingflow.callRecording?.lastStatus || 'unknown';

      const headers = (token: string) => ({ Authorization: `Bearer ${token}` });
      const validateStatus = (status: number) => [200, 201].includes(status);
      const deleteStatus = (status: number) => [204].includes(status);

      if (
        isBeforeEnd &&
        (!meetingflow.callRecording || botStatus === 'deleted')
      ) {
        getAccessTokenSilently().then((token) =>
          toast.promise(
            MeetingflowsApiClient.startOrScheduleCallRecording(
              organizationSlug!,
              meetingflow.id,
              undefined,
              {
                headers: headers(token),
                validateStatus,
              },
            ),
            {
              loading: isBeforeStart
                ? `Scheduling Call Recording`
                : `Starting Call Recording`,
              error: (err) => {
                console.error(err);
                return isBeforeStart
                  ? `Something went wrong scheduling call recording`
                  : `Something went wrong starting call recording`;
              },
              success: () => {
                refetchMeetingflow().then(() => {
                  if (meetingflow?.callRecording?.lastStatus) {
                    onRecorderStatusChange?.(
                      meetingflow?.callRecording?.lastStatus,
                    );
                  }
                });
                appInsights.trackEvent({
                  name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                  properties: {
                    organizationSlug,
                    meetingflowId,
                    change: isBeforeStart ? 'scheduled' : 'started',
                  },
                });
                return isBeforeStart
                  ? `Successfully scheduled call recording`
                  : `Successfully started call recording`;
              },
            },
          ),
        );
      } else if (
        isRecordingScheduledOrActive &&
        [
          'scheduled',
          'ready',
          'joining_call',
          'in_waiting_room',
          'in_call_not_recording',
          'in_call_recording',
        ].includes(botStatus)
      ) {
        getAccessTokenSilently().then((token) =>
          toast.promise(
            MeetingflowsApiClient.stopOrCancelCallRecording(
              organizationSlug!,
              meetingflow.id,
              {
                headers: headers(token),
                validateStatus: deleteStatus,
              },
            ),
            {
              loading: isBeforeStart
                ? `Cancelling scheduled call recording`
                : `Stopping call recording`,
              error: (err) => {
                console.error(err);
                return isBeforeStart
                  ? `Something went wrong cancelling scheduled call recording`
                  : `Something went wrong stopping call recording`;
              },
              success: () => {
                refetchRecorderStatus();
                refetchMeetingflow().then(() => {
                  if (meetingflow?.callRecording?.lastStatus) {
                    onRecorderStatusChange?.(
                      meetingflow?.callRecording?.lastStatus,
                    );
                  }
                });
                appInsights.trackEvent({
                  name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                  properties: {
                    organizationSlug,
                    meetingPlanId: meetingflow.id,
                    change: isBeforeStart ? 'cancelled' : 'stopped',
                  },
                });
                return isBeforeStart
                  ? `Successfully cancelled scheduled call recording`
                  : `Successfully stopped call recording`;
              },
            },
          ),
        );
      }
    },
    [
      appInsights,
      isBeforeEnd,
      isBeforeStart,
      getAccessTokenSilently,
      meetingflow,
      meetingflowId,
      onRecorderStatusChange,
      organizationSlug,
      refetchRecorderStatus,
      refetchMeetingflow,
      isRecordingScheduledOrActive,
    ],
  );

  const isVideoMeeting = Boolean(
    meetingflow?.conferenceInfo &&
      'type' in meetingflow.conferenceInfo &&
      (meetingflow.conferenceInfo.type === 'zoom' ||
        meetingflow.conferenceInfo.type === 'msTeams'),
  );

  const isInFuture = meetingflow?.startTime
    ? new Date(meetingflow.startTime) > new Date()
    : false;

  const isInPast = meetingflow?.endTime
    ? new Date(meetingflow.endTime) < new Date()
    : false;

  const isCurrentlyHappening = !isInPast && !isInFuture;

  return {
    // Core meetingflow data and operations
    meetingflow,
    meetingflowFetched,
    refetchMeetingflow,
    meetingflowLoading,
    meetingflowError,

    // Call recording status tracking
    recorderStatus,
    callRecorderStatus,
    recorderStatusLoading,
    refetchRecorderStatus,

    // Transcript data and management
    transcript,
    transcriptLoading,
    refetchTranscript,

    // Speaker identification and styling utilities
    speakerNames,
    getContactForParticipant,
    getColorForSpeaker,

    // Conversation analysis and structure
    callStats,
    turns,

    // Global loading state for UI coordination
    isLoading: meetingflowLoading || recorderStatusLoading || transcriptLoading,

    // Companies
    companies: filteredCompanies,
    internalCompanies,
    externalCompanies,

    // Attendees
    attendees: filteredAttendees,
    internalAttendees,
    externalAttendees,
    attendeesByDomain,
    sortedAttendeesByDomain,
    withDealRoomContacts,
    isWithDealRoomContacts,

    isVideoMeeting,
    isInFuture,
    isInPast,
    isCurrentlyHappening,

    timeToStart,
    timeToEnd,
    isBeforeStart,
    isBeforeEnd,

    isRecordingScheduledOrActive: isRecordingScheduledOrActive || false,
    scheduleCallRecording,
  };
};
