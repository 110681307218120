import { FontWeights, mergeStyles } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import useDeviceType from '../../../Hooks/deviceDetection';
import { DSAnalyticsActivityTable } from './DSAnalyticsActivityTable';

export const DSAnalyticsRoot = () => {
  const { isMobile } = useDeviceType();

  const styles = {
    root: mergeStyles({
      display: 'flex',
      gap: '2rem',
      minHeight: '70vh',
      height: '100%',
      flexDirection: isMobile ? 'column' : 'row',
    }),
  };

  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();

  if (!organizationSlug || !dealRoomId) {
    return null;
  }

  return (
    <div className={styles.root}>
      <DSAnalyticsActivityTable />
    </div>
  );
};
