import { FontWeights, mergeStyles } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const MainContainerWrapperStyles = () =>
  mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
    overflow: 'hidden',
    height: 'auto',
    maxHeight: '100%',
    transition: '1.3s ease-in-out max-height height',

    h5: {
      margin: 0,
    },
  });

export const MainContainerStyles = (
  isMobile: boolean,
  biggerThanTablet: boolean,
) =>
  mergeStyles({
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    padding: isMobile ? '0.2rem' : '1rem 0.2rem',
    backgroundColor: DEALROOMS_COLORS.white,
    boxShadow: '0px 2px 2px 0px #00000005',
    transition: 'all 0.3s ease',

    '.fit_table_cell': {
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: biggerThanTablet ? '200px' : '110px',
      width: 'auto',
      '&:nth-of-type(2)': {
        width: '100%',
      },
    },

    '.actions_table_cell': {
      width: '40px',
      minWidth: '40px',
    },

    '.featured_artifact': {
      width: '20px',
      minWidth: '20px',
      padding: 0,
      paddingLeft: '.5rem',
    },

    '.table_row:hover': {
      backgroundColor: DEALROOMS_COLORS.financialLighterGray,
    },
  });

export const detailsHeader = mergeStyles({
  fontSize: '13px !important',
  lineHeight: '16px !important',
  fontWeight: '400 !important',
  minHeight: '16px',
  height: '16px',
  backgroundColor: DEALROOMS_COLORS.white,
  color: `${DEALROOMS_COLORS.themeSecondary} !important`,
});

export const artifactName = mergeStyles({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  fontWeight: FontWeights.semibold,
  width: '100%',
  maxWidth: '100%',
  height: '100%',

  span: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
  },

  ':hover': {
    color: DEALROOMS_COLORS.plum100,
  },

  i: {
    color: DEALROOMS_COLORS.neutralDark,
    fontSize: '15px',
  },
});

export const mobileMetadata = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  fontSize: '13px',
  color: DEALROOMS_COLORS.themeSecondary,
});

export const mobileMetadataRow = mergeStyles({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

export const deletedArtifact = mergeStyles({
  cursor: 'not-allowed !important',
  color: DEALROOMS_COLORS.neutralSecondary,

  ':hover': {
    color: DEALROOMS_COLORS.neutralSecondary,
  },
});
