import { useBoolean } from '@fluentui/react-hooks';
import {
  Text,
  Icon,
  Image,
  ImageLoadState,
  mergeStyles,
} from '@fluentui/react';
import { useCallback, useMemo } from 'react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  buyerOrientationSurveyOptions,
  stepNamesBasedOnIndex,
} from './buyerOrientationSurveyUtils';
import classNames from 'classnames';
import useDeviceType from '../../../../Hooks/deviceDetection';

type BuyerOrientationSurveySecondaryHeaderProps = {
  avatarUrl?: string | null;
  step: number;
  ownerName?: string;
  userName?: string;
};

export const BuyerOrientationSurveySecondaryHeader = ({
  avatarUrl = '',
  step,
  ownerName,
  userName,
}: BuyerOrientationSurveySecondaryHeaderProps) => {
  const { isMobile } = useDeviceType();

  const [imageError, { setTrue: setImageError, setFalse: setNoImageError }] =
    useBoolean(true);

  const handleOnImageStateChange = useCallback(
    (state: ImageLoadState) => {
      if (state === 1) {
        setNoImageError();
      } else {
        setImageError();
      }
    },
    [setImageError, setNoImageError],
  );

  const headerStyle = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: isMobile ? '0 1rem 1.5rem' : '0 4rem 1.5rem',
    position: 'relative',

    '.secondary-header-close-button': {
      position: 'absolute',
      top: '0.2rem',
      right: '1rem',
    },

    '.secondary-header-avatar': {
      borderRadius: '50%',
      backgroundColor: 'red',
      ...(imageError ? { display: 'none' } : {}),
    },

    '.secondary-header-content': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '.5rem',

      '.secondary-header-title': {
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: '400',
        lineHeight: '2.5rem',
        color: DEALROOMS_COLORS.themePrimary,
      },

      '.secondary-header-with-smaller-fontSize': {
        fontSize: '1.5rem',
      },

      '.secondary-header-subtitle': {
        textAlign: 'center',
        fontSize: '1.125rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        color: DEALROOMS_COLORS.themeSecondary,
      },
    },

    '.secondary-header-avatar-placeholder': {
      width: '72px',
      height: '72px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: DEALROOMS_COLORS.themeSecondary,
      ...(!imageError ? { display: 'none' } : {}),

      '.secondary-header-avatar-placeholder-icon': {
        fontSize: '2rem',
        color: DEALROOMS_COLORS.white,
      },
    },
    '.primary-text': {
      color: DEALROOMS_COLORS.userSurveyPrimary,
    },
  });

  const contentDetails = useMemo(() => {
    const stepName = stepNamesBasedOnIndex[`${step}`];
    return {
      title: buyerOrientationSurveyOptions[stepName]?.title || 'N/A',
      subtitle: buyerOrientationSurveyOptions[stepName]?.subtitle || 'N/A',
    };
  }, [step]);

  return (
    <div className={headerStyle}>
      <div className="secondary-header-avatar-placeholder">
        <Icon
          className="secondary-header-avatar-placeholder-icon"
          iconName="UserSync"
        />
      </div>
      <Image
        className="secondary-header-avatar"
        src={avatarUrl || undefined}
        width={72}
        height={75}
        onLoadingStateChange={handleOnImageStateChange}
      />
      <div className="secondary-header-content">
        {step === 1 && (
          <Text className="secondary-header-title">
            Hello
            {userName ? `, ${userName.split(' ')[0]}` : ''}! My name is{' '}
            {ownerName ? (
              <span className="primary-text">{ownerName.split(' ')[0]}.</span>
            ) : null}
          </Text>
        )}
        <Text
          className={classNames('secondary-header-title', {
            ['secondary-header-with-smaller-fontSize']: step === 1,
          })}
        >
          {contentDetails?.title || 'N/A'}
        </Text>
        <Text className="secondary-header-subtitle">
          {contentDetails?.subtitle || 'N/A'}
        </Text>
      </div>
    </div>
  );
};
