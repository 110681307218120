/**
 * Custom hook for managing speaker visualization and identification in meeting transcripts.
 *
 * Key features:
 * - Extracts and deduplicates speaker names from transcript
 * - Maps speakers to attendee contact information
 * - Provides consistent color coding for speakers
 * - Handles speaker-attendee matching for contact details
 */

import { useMemo } from 'react';
import { Attendee, Utterance } from '@meetingflow/common/Api/data-contracts';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { tryMatchAttendeesToParticipants } from '@meetingflow/common/TranscriptHelpers';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

/**
 * Predefined color palette for speaker identification in the transcript UI.
 * Colors are selected for optimal readability and visual distinction.
 * If there are more speakers than colors, the palette will cycle through these colors.
 */
export const speakerColors = [
  DEALROOMS_COLORS.orchid,
  DEALROOMS_COLORS.peacock,
  DEALROOMS_COLORS.cloudburst,
  DEALROOMS_COLORS.woodsmoke,
  DEALROOMS_COLORS.crimson,
  DEALROOMS_COLORS.plum50,
  DEALROOMS_COLORS.plum25,
  DEALROOMS_COLORS.oink,
  DEALROOMS_COLORS.frost,
  DEALROOMS_COLORS.peach,
  DEALROOMS_COLORS.artic,
];

/**
 * Props for the useMeetingflowSpeakers hook
 * @property transcript - Array of utterances containing speaker information and content
 * @property attendees - Optional array of meeting attendees for contact information matching.
 *                      Can be null if attendee information is not available.
 */
interface UseMeetingflowSpeakersProps {
  transcript: Utterance[];
  attendees?: Attendee[] | null;
}

export const useMeetingflowSpeakers = ({
  transcript,
  attendees,
}: UseMeetingflowSpeakersProps) => {
  /**
   * Extracts and processes unique speaker names from the transcript.
   * - Filters out null/undefined speakers
   * - Deduplicates names
   * - Sorts alphabetically for consistent ordering
   */
  const speakerNames = useMemo(
    () =>
      DeduplicateArray(
        transcript
          .map((u) => u.speaker)
          .filter(Truthy)
          .sort(),
      ),
    [transcript],
  );

  /**
   * Maps speaker names to their corresponding attendee data.
   * Uses fuzzy matching to handle variations in speaker names.
   * Updates automatically when attendee list or speaker names change.
   */
  const speakerContactMatches = useMemo(
    () => tryMatchAttendeesToParticipants(speakerNames, attendees || null),
    [attendees, speakerNames],
  );

  /**
   * Retrieves contact information for a specific participant.
   * @param participant - Participant's name or identifier
   * @returns Matched attendee contact information or undefined if no match found
   */
  const getContactForParticipant = (participant: string) => {
    return speakerContactMatches[participant];
  };

  /**
   * Assigns a consistent color to each speaker for visual identification.
   * @param speaker - Speaker's name or identifier
   * @returns A color from the speakerColors palette, cycling if needed
   *
   * Note: The same speaker will always get the same color within a session,
   * making it easier to follow conversations in the transcript.
   */
  const getColorForSpeaker = (speaker: string) => {
    const speakerIdx = speakerNames.indexOf(speaker);
    return speakerColors[speakerIdx % speakerColors.length];
  };

  return {
    speakerNames, // Array of unique, sorted speaker names
    getContactForParticipant, // Function to lookup attendee contact info
    getColorForSpeaker, // Function to get consistent speaker colors
  };
};
