import { useAuth0 } from '@auth0/auth0-react';
import { FontWeights, mergeStyles, useTheme } from '@fluentui/react';
import {
  CalendarEventAttachment,
  Resource,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import saveAs from 'file-saver';
import toast from 'react-hot-toast';
import { isAxiosErrorResponse } from '../../../Helpers/AxiosHelpers';
import { externalize } from '../../../Helpers/URLHelpers';
import { useAddResourceDialog } from '../../../Hooks/Modals/useAddResourceDialog';
import { Chip } from '../../Chip';
import { DropTarget } from '../../Common/DropTarget';
import { AddResourceChip } from '../AddResourceChip';

type MeetingPlanResourcesProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  eventAttachments?: CalendarEventAttachment[] | null;
  resources: Resource[];
  showAdd?: boolean;
  onAdd: (item: Resource) => void;
  onUpdate: (idx: number | string, props: Partial<Resource>) => void;
  onDelete: (idx: number | string) => void;
};

export const MeetingPlanResources = ({
  organizationSlug,
  meetingPlanId,
  eventAttachments,
  resources,
  showAdd,
  onAdd,
  onUpdate,
  onDelete,
}: MeetingPlanResourcesProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const appInsights = useAppInsightsContext();
  const { createDeferred: showAddResourceDialog, dialog: addResourceDialog } =
    useAddResourceDialog({
      organizationSlug,
      meetingPlanId,
    });

  const containerStyle = mergeStyles({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'space-around',
    gap: '0.1rem 0.25rem',
    flex: '1 1 auto',
    width: '100%',
    height: '100%',
  });

  return (
    <>
      <div id="meetingflow-resources" className={containerStyle}>
        <DropTarget
          overlayText="Drop here to add to the Meetingflow"
          onDrop={(file) => {
            showAddResourceDialog(file).promise.then((item) => onAdd(item));
          }}
        >
          {!!eventAttachments?.length
            ? eventAttachments.map((attachment) => {
                return (
                  <Chip
                    id="meetingflow-resources-calendar-attachment"
                    key={attachment.id}
                    type={'link'}
                    text={attachment.title}
                    mimeType={attachment.mimeType}
                    onClick={() =>
                      window.open(externalize(attachment.url), '_blank')
                    }
                  />
                );
              })
            : null}
          {Array.isArray(resources)
            ? resources.map((resource, idx) => {
                return (
                  <Chip
                    id="meetingflow-resources-resource"
                    key={resource.id || idx}
                    type={resource.type}
                    text={resource.title}
                    mimeType={
                      resource.type === 'file' ? resource.mimeType : undefined
                    }
                    onClick={() => {
                      if (resource.type === 'file') {
                        getAccessTokenSilently().then(async (token) => {
                          const headers = new Headers();
                          headers.append('Authorization', `Bearer ${token}`);
                          if (resource.mimeType) {
                            headers.append('Accept', resource.mimeType);
                          }
                          const fetchPromise = fetch(resource.url, {
                            method: 'GET',
                            headers,
                          });

                          toast.promise(fetchPromise, {
                            loading: 'Downloading file',
                            success: 'Successfully downloaded file',
                            error: 'Something went wrong downloading file',
                          });

                          const response = await fetchPromise;
                          const blob = await response.blob();
                          saveAs(blob, resource.name);
                        });
                      } else {
                        window.open(externalize(resource.url), '_blank');
                      }
                    }}
                    onDelete={
                      showAdd
                        ? () => {
                            onDelete(resource.id || idx);
                            if (resource.type === 'file') {
                              getAccessTokenSilently()
                                .then((token) => {
                                  const headers = new Headers();
                                  headers.append(
                                    'Authorization',
                                    `Bearer ${token}`,
                                  );
                                  return fetch(resource.url, {
                                    method: 'DELETE',
                                    headers,
                                  });
                                })
                                .catch((err) => {
                                  appInsights.trackEvent({
                                    name: `DELETE_PLAN_FILE_RESOURCE_FAILED`,
                                    properties: {
                                      organizationSlug,
                                      meetingPlanId,
                                      resource: resource.id,
                                      status:
                                        isAxiosErrorResponse(err) &&
                                        err.response?.status,
                                      statusText:
                                        isAxiosErrorResponse(err) &&
                                        err.response?.statusText,
                                    },
                                  });
                                });
                            }
                          }
                        : undefined
                    }
                  />
                );
              })
            : null}
        </DropTarget>
      </div>
      {showAdd ? (
        <AddResourceChip
          headlineText="Add an Artifact"
          iconOnly
          onClick={() => {
            showAddResourceDialog()
              .promise.then((item) => onAdd(item))
              .catch((err) => {});
          }}
        />
      ) : null}
      {addResourceDialog}
    </>
  );
};
