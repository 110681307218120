import { Autocomplete, AutocompleteProps } from '@mui/material';
import { DSAutocompleteRoot } from './DSAutocomplete.styles';
import { DSTextField } from './DSTextField';

interface DSAutocompleteProps {
  autoCompleteProps: AutocompleteProps<string, boolean, boolean, boolean>;
  label?: string;
  placeholder?: string;
}

export const DSAutocomplete = ({
  autoCompleteProps,
  label,
  placeholder,
}: DSAutocompleteProps) => {
  return (
    <DSAutocompleteRoot>
      <Autocomplete
        {...autoCompleteProps}
        renderInput={(params) => (
          <DSTextField {...params} label={label} placeholder={placeholder} size="small" />
        )}
      />
    </DSAutocompleteRoot>
  );
};

export default DSAutocomplete;
