/**
 * DecisionSiteMeetingPanelAttendees is a component that displays and manages meeting attendees.
 * It provides a rich interface for viewing and interacting with both internal and external
 * participants, including company affiliations, LinkedIn profiles, and AI-powered chat suggestions.
 *
 * Key Features:
 * - Attendee grouping by company and domain
 * - Internal/external participant differentiation
 * - LinkedIn profile integration
 * - AI-powered chat suggestions
 * - Email interaction support
 * - Company logo and attendee count display
 */

import {
  Attendee,
  Company,
  Contact,
  DetailedMeetingflow,
  GPTChatBoxMessage,
} from '@meetingflow/common/Api/data-contracts';
import React, { useCallback, useMemo } from 'react';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';
import { COMMON_INTERNAL_RESOURCE_DOMAINS } from '@meetingflow/common/PublicEmailDomains';
import { Stack, Link } from '@mui/material';
import { shuffle } from 'lodash';
import { suggestChatPrompts } from '../../../../Helpers/ContactHelpers';
import { DateTime } from 'luxon';
import LinkedInIcon from '../../../../Static/Images/linkedin.png';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { GPTChatBoxContext } from '../../../../Components/GPT/GPTChatBoxContext';
import { useSearchParams } from 'react-router-dom';
import DSMenuButton from '../../DS/DSMenuButton';
import DSMenuItem from '../../DS/DSMenuItem';
import { SmartToyOutlined, Try } from '@mui/icons-material';
import {
  CompanyStack,
  CompanyInnerStack,
  CompanyAvatar,
  CompanyBadge,
  InternalTypography,
  LinkedInButton,
  LinkedInImage,
  AttendeeChip,
  AIMenuButton,
  AttendeeName,
  AttendeeNameStack,
  AttendeeEmail,
  CompanyName,
} from './DecisionSiteMeetingPanelAttendees.styles';
import { useMeetingflow } from './hooks/useMeetingflow';

/**
 * Main component props interface
 * @property meetingflow - Complete meeting data including attendees and companies
 * @property chatboxContext - Optional AI chat context for suggestions
 * @property setChatBoxContext - Optional handler to update chat context
 */
interface DecisionSiteMeetingPanelAttendeesProps {
  meetingflow: DetailedMeetingflow;
  chatboxContext?: GPTChatBoxContext;
  setChatBoxContext?: (context: GPTChatBoxContext | undefined) => void;
}

/**
 * Attendee list filtering props interface
 * @property meetingflow - Meeting data source
 * @property excludeCreator - Option to hide meeting creator
 * @property excludeOrganizer - Option to hide meeting organizer
 * @property excludeInternal - Option to hide internal participants
 * @property excludeExternal - Option to hide external participants
 * @property limitToCompanies - Option to show only specific companies
 * @property chatboxContext - AI chat context for suggestions
 * @property setChatBoxContext - Handler to update chat context
 */
interface AttendeeListProps {
  meetingflow: DetailedMeetingflow;
  excludeCreator?: boolean;
  excludeOrganizer?: boolean;
  excludeInternal?: boolean;
  excludeExternal?: boolean;
  limitToCompanies?: Company[];
  chatboxContext?: GPTChatBoxContext;
  setChatBoxContext?: (context: GPTChatBoxContext | undefined) => void;
}

/**
 * AttendeeList component handles the display and organization of meeting participants
 * It supports filtering, grouping, and interactive features for attendee management
 */
const AttendeeList: React.FC<AttendeeListProps> = ({
  meetingflow,
  excludeCreator = false,
  excludeOrganizer = false,
  excludeInternal = false,
  excludeExternal = false,
  limitToCompanies,
  chatboxContext,
  setChatBoxContext,
}) => {
  const { internalDomains } = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    companies,
    internalCompanies,
    externalCompanies,
    attendees,
    internalAttendees,
    externalAttendees,
    attendeesByDomain,
    sortedAttendeesByDomain,
  } = useMeetingflow(meetingflow.id);

  /**
   * Generate AI chat suggestions for each attendee
   * Based on meeting context and attendee information
   */
  const getSuggestedChatPrompts = useCallback(
    (contact: Contact) => {
      if (meetingflow && contact) {
        return suggestChatPrompts(DateTime.now(), meetingflow, contact);
      }
      return [];
    },
    [meetingflow],
  );

  /**
   * Create shuffled prompt suggestions for each attendee
   * Limits to top 5 suggestions per person
   */
  const attendeePrompts = useMemo(() => {
    const prompts: Record<string, GPTChatBoxMessage[]> = {};
    attendees?.forEach((attendee) => {
      prompts[attendee.email] = shuffle(
        getSuggestedChatPrompts(attendee),
      ).slice(0, 5);
    });
    return prompts;
  }, [attendees, getSuggestedChatPrompts]);

  /**
   * Handle AI chat prompt selection
   * Updates chat context and switches to AI chat tab
   */
  const handlePromptClick = useCallback(
    (prompt: GPTChatBoxMessage) => {
      if (setChatBoxContext) {
        // Update chat context while preserving existing messages
        setChatBoxContext({
          key: chatboxContext?.key || prompt.content,
          initialMessages: [...(chatboxContext?.initialMessages || []), prompt],
        });

        // Switch to AI Chat tab
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev);
          newParams.set('tab', 'ai-chat');
          return newParams;
        });
      }
    },
    [setChatBoxContext, setSearchParams, chatboxContext],
  );

  /**
   * Render company section with attendee list
   * Includes:
   * - Company logo and name
   * - Attendee count badge
   * - Internal/external indicator
   * - Attendee details with LinkedIn and email
   * - AI chat suggestions
   */
  const getCompanyAttendees = useCallback(
    (c: Company) => {
      const attendees =
        c.domains
          ?.map((domain) => domain.domain)
          ?.flatMap(
            (domain) =>
              Object.keys(attendeesByDomain)
                .filter((key) => key === domain || key.endsWith(domain))
                .flatMap((key) => attendeesByDomain[key]) ?? [],
          ) ?? [];

      return (
        <CompanyStack spacing={0}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <CompanyAvatar
              src={c.logo || undefined}
              alt={c.name}
              variant="rounded"
            >
              {!c.logo && c.name?.charAt(0)}
            </CompanyAvatar>
            <Stack direction="column" alignItems="flex-start">
              <CompanyName>
                {c.name}
                <CompanyBadge badgeContent={attendees.length} color="primary" />
              </CompanyName>
              {internalDomains.includes(c.domains?.[0].domain) && (
                <InternalTypography variant="caption">
                  Internal
                </InternalTypography>
              )}
            </Stack>
          </Stack>
          <CompanyInnerStack spacing={2}>
            {attendees.map((a) => (
              <Stack key={a.id} direction="row" alignItems="flex-start" gap={2}>
                <Stack
                  key={a.id}
                  spacing={0}
                  direction="column"
                  alignItems="flex-start"
                >
                  <AttendeeName variant="body2">
                    <AttendeeNameStack>
                      {a.name || a.email}
                      <AttendeeEmail variant="body2">
                        <Link
                          href={`mailto:${a.email}`}
                          color="inherit"
                          underline="hover"
                        >
                          {a.email}
                        </Link>
                      </AttendeeEmail>
                      {a.linkedInUrl && (
                        <LinkedInButton
                          size="small"
                          href={a.linkedInUrl}
                          target="_blank"
                        >
                          <LinkedInImage src={LinkedInIcon} alt="LinkedIn" />
                        </LinkedInButton>
                      )}
                    </AttendeeNameStack>
                  </AttendeeName>
                  <Stack
                    direction="row"
                    spacing={0}
                    alignItems="center"
                    sx={{
                      position: 'relative',
                      top: '-.25rem',
                      left: '-.5rem',
                    }}
                  >
                    {attendeePrompts[a.email].slice(0, 1).map((prompt) => (
                      <AttendeeChip
                        key={prompt.content}
                        label={prompt.displayText || prompt.content}
                        size="small"
                        onClick={
                          setChatBoxContext
                            ? () => handlePromptClick(prompt)
                            : undefined
                        }
                      />
                    ))}
                    {attendeePrompts[a.email].length > 1 && (
                      <DSMenuButton
                        label="More AI Suggestions"
                        iconOnly
                        showChevron={false}
                        startIcon={
                          <Try sx={{ color: DEALROOMS_COLORS.cloudburst }} />
                        }
                        variant="text"
                        size="medium"
                        menuItems={
                          <>
                            {attendeePrompts[a.email].slice(2).map((prompt) => (
                              <DSMenuItem
                                key={prompt.content}
                                primaryText={
                                  prompt.displayText || prompt.content
                                }
                                secondaryText={prompt.summaryType}
                                icon={
                                  <Try
                                    sx={{ color: DEALROOMS_COLORS.cloudburst }}
                                  />
                                }
                                onClick={
                                  setChatBoxContext
                                    ? () => handlePromptClick(prompt)
                                    : undefined
                                }
                              />
                            ))}
                          </>
                        }
                        component={AIMenuButton}
                      />
                    )}
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </CompanyInnerStack>
        </CompanyStack>
      );
    },
    [
      attendeesByDomain,
      attendeePrompts,
      handlePromptClick,
      internalDomains,
      setChatBoxContext,
    ],
  );

  /**
   * Sort companies by attendee count
   * Filter out companies with single attendee
   */
  const sortedInternalCompanies = useMemo(
    () =>
      internalCompanies
        ?.sort(
          (a, b) =>
            getCompanyAttendees(b).props.children.length -
            getCompanyAttendees(a).props.children.length,
        )
        .filter((c) => getCompanyAttendees(c).props.children.length > 1),
    [internalCompanies, getCompanyAttendees],
  );

  const sortedExternalCompanies = useMemo(
    () =>
      externalCompanies
        ?.sort(
          (a, b) =>
            getCompanyAttendees(b).props.children.length -
            getCompanyAttendees(a).props.children.length,
        )
        .filter((c) => getCompanyAttendees(c).props.children.length > 1),
    [externalCompanies, getCompanyAttendees],
  );

  return (
    <Stack spacing={2}>
      {sortedExternalCompanies && sortedExternalCompanies?.length > 0 ? (
        <Stack spacing={1} className="company-attendee-group">
          {sortedExternalCompanies?.map((c) => (
            <div key={c.id}>{getCompanyAttendees(c)}</div>
          ))}
        </Stack>
      ) : null}
      {sortedInternalCompanies && sortedInternalCompanies?.length > 0 ? (
        <Stack spacing={1} className="company-attendee-group">
          {sortedInternalCompanies?.map((c) => (
            <div key={c.id}>{getCompanyAttendees(c)}</div>
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
};

/**
 * Main component that renders the attendee panel
 * Provides company-grouped view of meeting participants
 */
export const DecisionSiteMeetingPanelAttendees: React.FC<
  DecisionSiteMeetingPanelAttendeesProps
> = ({ meetingflow, chatboxContext, setChatBoxContext }) => {
  return (
    <AttendeeList
      meetingflow={meetingflow}
      chatboxContext={chatboxContext}
      setChatBoxContext={setChatBoxContext}
    />
  );
};

export default DecisionSiteMeetingPanelAttendees;
