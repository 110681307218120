// Vimeo URL patterns
export const VIMEO_URL_PATTERNS = [
  /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/([0-9]+)/,
  /(?:https?:\/\/)?player\.vimeo\.com\/video\/([0-9]+)/,
];

/**
 * Extracts the Vimeo video ID from various Vimeo URL formats
 * @param url The Vimeo URL to extract the video ID from
 * @returns The video ID if found, null otherwise
 */
export const getVimeoVideoId = (url: string): string | null => {
  // If the input is already just a video ID, return it
  if (/^[0-9]+$/.test(url)) {
    return url;
  }

  // Otherwise try to extract from URL
  for (const pattern of VIMEO_URL_PATTERNS) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }

  return null;
};

/**
 * Checks if a URL is a valid Vimeo video URL
 * @param url The URL to check
 * @returns True if the URL is a valid Vimeo video URL
 */
export const isVimeoUrl = (url: string): boolean => {
  return VIMEO_URL_PATTERNS.some((pattern) => pattern.test(url));
};
