import { mergeStyleSets } from '@fluentui/react';
import type { IStyle } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

interface IKeyFactsStyles {
  container: IStyle;
  factsGrid: IStyle;
  errorMessage: IStyle;
  headerButtons: IStyle;
}

export const getKeyFactsStyles = () =>
  mergeStyleSets<IKeyFactsStyles>({
    container: {
      width: '100%',
    },
    factsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '1.25rem',
      width: '100%',
    },
    errorMessage: {
      color: DEALROOMS_COLORS.configurationsErrorMessage,
      marginTop: '.5rem',
    },
    headerButtons: {
      selectors: {
        '> button': {
          maxHeight: '1.25rem',
          padding: '0 10px',
        },
      },
    },
  });
