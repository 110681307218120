import React, { useState } from 'react';
import { Stack, DatePicker, Icon, IconButton, Text } from '@fluentui/react';
import { DealRoomMilestoneType } from '@meetingflow/common/Api/data-contracts';
import { MilestoneObject } from '../../../../../types/MilestoneTypes';
import { dsConfigurationMutualPlanStyles as styles } from './styles';
import { DSConfigurationMutualPlanDeleteMilestoneDialog } from './DSConfigurationMutualPlanDeleteMilestoneDialog';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import {
  EyeOffIconProps,
  EyeOpenIconProps,
} from '../../../../../utils/iconProps';

interface DSConfigurationMutualPlanMilestoneItemProps {
  milestone: MilestoneObject;
  onClickMilestone: (type: DealRoomMilestoneType) => void;
  onChangeDueDate: (
    type: DealRoomMilestoneType,
    date: Date | null | undefined,
  ) => void;
  onDeleteMilestone: (type: DealRoomMilestoneType) => void;
}

export const DSConfigurationMutualPlanMilestoneItem: React.FC<
  DSConfigurationMutualPlanMilestoneItemProps
> = ({ milestone, onClickMilestone, onChangeDueDate, onDeleteMilestone }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleDelete = (e: React.MouseEvent) => {
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    onDeleteMilestone(milestone.type);
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      <div
        className={`${styles.milestone} ${milestone.visible ? styles.milestoneVisible : ''}`}
      >
        <IconButton
          className={styles.eyeIconButton}
          iconProps={milestone.visible ? EyeOpenIconProps : EyeOffIconProps}
          onClick={() => onClickMilestone(milestone.type)}
        />
        <div className={styles.milestoneNameAndCount}>
          <Text className={styles.milestoneName}>{`${milestone.type}`}</Text>
          {/* Display number of tasks if any */}
          {milestone.numberOfTasks > 0 && (
            <Text
              className={styles.tasksNumber}
            >{`${milestone.numberOfTasks} ${milestone.numberOfTasks === 1 ? 'Task' : 'Tasks'}`}</Text>
          )}
          {/* Additional controls for visible milestones */}
        </div>
        <Stack
          horizontal
          horizontalAlign="end"
          styles={{ root: { width: '100%' } }}
        >
          {milestone.visible && (
            <>
              {/* Date picker for due date */}
              <div className={styles.datePickerContainer}>
                <DatePicker
                  className={styles.datePicker}
                  placeholder="Due Date..."
                  value={
                    milestone.dueDate ? new Date(milestone.dueDate) : undefined
                  }
                  onSelectDate={(newDate) => {
                    onChangeDueDate(milestone.type, newDate);
                  }}
                />
              </div>
            </>
          )}

          {/* Delete button */}
          <IconButton
            iconProps={{
              iconName: 'Delete',
              styles: {
                root: {
                  color: DEALROOMS_COLORS.darkerRed,
                  fontSize: 16,
                },
              },
            }}
            onClick={(e) => {
              handleDelete(e as React.MouseEvent);
            }}
            ariaLabel="Delete milestone"
          />
        </Stack>
      </div>
      {/* Delete confirmation dialog */}
      <DSConfigurationMutualPlanDeleteMilestoneDialog
        isOpen={isDeleteDialogOpen}
        milestoneName={milestone.type}
        onConfirm={handleConfirmDelete}
        onDismiss={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};
