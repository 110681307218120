import { styled, Box, DialogContent, Stack, TableCell } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const StyledDialogContent = styled(DialogContent)({
  padding: 0,
  height: '100%',
  maxHeight: 'calc(100vh - 64px)',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

export const StyledStack = styled(Stack)({
  padding: '1rem 1.5rem 1.5rem',
  flex: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'table-cell',
  },
}));

export const StyledArtifactBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const StyledIconBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '& i': {
    fontSize: '16px',
    color: DEALROOMS_COLORS.financialLighterGray,
  },
});

export const StyledPersonaBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const StyledPersonaNameBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    marginLeft: '8px',
  },
}));

export const StyledFooterBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '1rem',
  flexWrap: 'wrap',
  '> .cancel-button': {
    padding: '0.5rem 1rem',
    justifyContent: 'center',
    '.ms-Button-label': {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '400',
      color: DEALROOMS_COLORS.darkerGray,
    },
  },
  '> .save-button': {
    padding: '0.5rem 1rem',
    '.ms-Button-label': {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '400',
      color: DEALROOMS_COLORS.white,
    },
  },
  '> .save-button-disabled': {
    backgroundColor: DEALROOMS_COLORS.neutralDark,
    color: DEALROOMS_COLORS.lightGray,
    borderColor: DEALROOMS_COLORS.neutralDark,
    cursor: 'not-allowed',
  },
});
