import { useAuth0 } from '@auth0/auth0-react';
import {
  BaseButton,
  Button,
  FontIcon,
  FontSizes,
  FontWeights,
  NeutralColors,
  Persona,
  PersonaSize,
  mergeStyles,
} from '@fluentui/react';
import { CalendarEvent, Company } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { AsyncSpan } from '../../../Components/HOC/AsyncIntrinsicElement';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useModifierAwareNavigate } from '../../../Hooks/useModifierAwareNavigate';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { CompanyCard } from '../../MeetingPlans/MeetingPlanAttendees';
import StyledDateTime from '../../StyledDateTime';
import { useOrganization } from '../../../Hooks/useOrganization';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';
import {
  AttendeesAvatar,
  AttendeesBadge,
  AttendeesList,
  AttendeesListItem,
  AttendeesListSubheader,
  AttendeesTypography,
  EventCardContainer,
  EventCardStack,
  JoinConferenceButton,
  MeetingflowIcon,
  MeetingflowIconContainer,
  StyledAvatarGroup,
} from './DecisionSiteEventCard.styles';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { createMeetingflowAndDecisionSiteArtifact } from '../../../utils/DecisionSiteMeetingflowHelpers';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import { titleCase } from '@meetingflow/common/StringHelpers';
import {
  AssignmentInd,
  StarRounded,
  VideoCall,
  Videocam,
} from '@mui/icons-material';
import { DSTooltip } from '../../../Components/DealRoom/DS/DSTooltip';
import { HubSpotBrowserSidePanelPinnedContent } from 'src/Components/MeetingPlans/SidePanels/HubSpot/HubSpotBrowserSidePanelPinnedContent';

interface DecisionSiteEventCardProps {
  event: CalendarEvent;
  organizationSlug: string;
  onClick?: () => void;
  disableCreateViewMeetingflowOnClick?: boolean;
  showTextLabelOnCreateButton?: boolean;
  showTimesOnly?: boolean;
  allowScheduleCallRecording?: boolean;
  cardTitleAttr?: string;
  hideNewMeetingflowButton?: boolean;
  hideConferenceIcon?: boolean;
  hideAttendeesIcon?: boolean;
  showAddToDecisionSiteButton?: boolean;
}

const DecisionSiteEventCard = ({
  event,
  organizationSlug,
  showTimesOnly = false,
  allowScheduleCallRecording = false,
  cardTitleAttr,
  hideConferenceIcon = false,
  showAddToDecisionSiteButton = true,
}: DecisionSiteEventCardProps) => {
  const { isDark } = useLightOrDarkMode();
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();
  const appInsights = useAppInsightsContext();
  const modifierAwareNavigate = useModifierAwareNavigate();
  const { dealRoomId, dealRoomRole, dealRoom } = useDealRoom();
  const { organization, canCreatePlans, internalDomains } = useOrganization();

  const eventIsInPast = useMemo(() => {
    const now = DateTime.now();
    const endTime = DateTime.fromISO(event.endTime);
    return now > endTime;
  }, [event.endTime]);

  const eventIsNow = useMemo(() => {
    const now = DateTime.now();
    const startTime = DateTime.fromISO(event.startTime);
    const endTime = DateTime.fromISO(event.endTime);
    return now > startTime && now < endTime;
  }, [event.startTime, event.endTime]);

  const eventIsSoon = useMemo(() => {
    const startTime = DateTime.fromISO(event.startTime);
    return startTime.diffNow('minutes').minutes < 60;
  }, [event.startTime]);

  const eventIsNowOrSoon = useMemo(
    () => !eventIsInPast && (eventIsNow || eventIsSoon),
    [eventIsInPast, eventIsNow, eventIsSoon],
  );

  const recordLinkClass = mergeStyles({
    color: MEETINGFLOW_COLORS.white,
    cursor: 'pointer',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    borderRadius: '.5rem',
    padding: '.0 .5rem .0 .25rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    justifyItems: 'center',
    maxHeight: '1rem',
    textTransform: 'uppercase',
    transition: 'all .3s ease-in-out',
    lineHeight: '18px',
    whiteSpace: 'nowrap',

    i: {
      marginRight: '.25rem',
      fontWeight: FontWeights.regular,
    },

    '&.not-scheduled': {
      backgroundColor: isDark
        ? DEALROOMS_COLORS.themePrimary
        : DEALROOMS_COLORS.plum100,

      ':hover': {
        backgroundColor: DEALROOMS_COLORS.plum75,
      },
    },

    '&.scheduled': {
      backgroundColor: isDark
        ? NeutralColors.gray160
        : MEETINGFLOW_COLORS.purpleGrey,
      color: MEETINGFLOW_COLORS.purpleMedium,

      ':hover': {
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.purpleDark
          : MEETINGFLOW_COLORS.purpleMedium,
        color: MEETINGFLOW_COLORS.white,
      },
    },
  });

  const addLinkClass = mergeStyles({
    color: MEETINGFLOW_COLORS.white,
    cursor: 'pointer',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    backgroundColor: DEALROOMS_COLORS.themeSecondary,
    borderRadius: '.5rem',
    padding: '.0 .5rem .0 .5rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    justifyItems: 'center',
    maxHeight: '1rem',
    textTransform: 'uppercase',
    transition: 'all .3s ease-in-out',
    lineHeight: '18px',
    position: 'relative',
    top: '-2px',

    '&:hover': {
      backgroundColor: DEALROOMS_COLORS.themePrimary,
    },

    i: {
      marginRight: '.25rem',
      fontWeight: FontWeights.regular,
      color: 'white',
    },
  });

  const completedLinkClass = mergeStyles({
    marginRight: '0',
    color: isDark ? DEALROOMS_COLORS.themePrimary : DEALROOMS_COLORS.woodsmoke,
    cursor: 'default',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.regular,
    borderRadius: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    justifyItems: 'center',
    width: '16px',
    height: '16px',
    transition: 'all .3s ease-in-out',
    backgroundColor: isDark ? NeutralColors.gray160 : NeutralColors.gray30,

    i: {
      margin: 0,
      fontSize: '10px',
    },
  });

  const beforeEnd = event
    ? DateTime.fromISO(event.endTime).diffNow().milliseconds > 0
    : true;

  const createMeetingflow = async (
    e: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement
    >,
    scheduleCallRecording = false,
  ): Promise<unknown> => {
    e.stopPropagation();
    if (!!event?.meetingplanId) {
      modifierAwareNavigate(
        `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${event.meetingplanId}`,
        e,
      );
      return;
    }

    const token = await getAccessTokenSilently();

    try {
      if (!dealRoomId) {
        throw new Error('No dealRoomId provided');
      }

      return await createMeetingflowAndDecisionSiteArtifact({
        event,
        organizationSlug,
        scheduleCallRecording,
        token,
        dealRoomId,
        appInsights,
        queryClient: client,
        onSuccess: (meetingflowId) => {
          modifierAwareNavigate(
            `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${meetingflowId}${
              scheduleCallRecording ? '?scheduleCallRecording=true' : ''
            }`,
            e,
          );
        },
      });
    } catch (error) {
      console.error('Error creating meetingflow:', error);
      return error;
    }
  };

  const createMeetingflowAndScheduleCallRecording = (
    e: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement
    >,
  ): Promise<unknown> => createMeetingflow(e, true);

  const callRecordingButton = useMemo(() => {
    if (!event || dealRoomRole !== 'SELLER') {
      return null;
    }

    if (!canCreatePlans) {
      return null;
    }

    if (beforeEnd && event?.conferenceInfo?.joinUri) {
      return allowScheduleCallRecording ? (
        <AsyncSpan
          className={classNames(recordLinkClass, 'not-scheduled')}
          onClick={createMeetingflowAndScheduleCallRecording}
        >
          <FontIcon iconName={'CircleFill'} />
          Record
        </AsyncSpan>
      ) : null;
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, beforeEnd, allowScheduleCallRecording, recordLinkClass]);

  const addToDecisionSiteButton = useMemo(() => {
    if (!event || dealRoomRole !== 'SELLER') {
      return null;
    }

    return (
      <AsyncSpan
        className={classNames(addLinkClass, '')}
        onClick={createMeetingflow}
      >
        Add
      </AsyncSpan>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, recordLinkClass]);

  const externalCompanies = event.companies.filter((c) => !c.isInternal);

  const externalAttendees = useMemo(() => {
    if (!event?.attendees) {
      return [];
    }
    return event.attendees.filter((a) => {
      return !internalDomains.includes(a.emailDomain);
    });
  }, [event?.attendees, internalDomains]);

  const findCompanyByDomain = useCallback(
    (companies: Company[] | undefined, domain: string) => {
      return event.companies?.find((c) =>
        c.domains?.some((d) => d.domain === domain),
      );
    },
    [event.companies],
  );
  const onConferenceIconClick = useCallback(() => {
    window.open(event?.conferenceInfo?.joinUri, '_blank');
    appInsights.trackEvent({
      name: 'JOIN_CONFERENCE_CALL',
      properties: {
        organizationId: organization?.id,
        type: event?.conferenceInfo?.type,
      },
    });
  }, [
    appInsights,
    event?.conferenceInfo?.joinUri,
    event?.conferenceInfo?.type,
    organization?.id,
  ]);

  const mfIconSx = useMemo(
    () => ({
      color: DEALROOMS_COLORS.cloudburst,
      cursor: 'pointer',
      height: '16px',
      width: '16px',
      borderRadius: '50%',
    }),
    [],
  );

  const sortedAttendeesByDomain = useMemo(() => {
    return GroupBy(event?.attendees || [], (a) => a.emailDomain);
  }, [event?.attendees]);

  /**
   * Identify which DealRoom contacts are present in the meeting
   * Only considers external attendees
   */
  const withDealRoomContacts = useMemo(() => {
    if (!dealRoom?.contacts || !externalAttendees) {
      return [];
    }

    return dealRoom.contacts.filter((contact) =>
      externalAttendees.some(
        (attendee) =>
          attendee.email.toLowerCase() === contact.email.toLowerCase(),
      ),
    );
  }, [dealRoom?.contacts, externalAttendees]);

  const attendeesIcon = useMemo(() => {
    if (event.attendees.length) {
      return (
        <DSTooltip
          title={
            <>
              {Object.keys(sortedAttendeesByDomain).map((domain) => (
                <div key={domain}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontWeight: 600,
                        color: DEALROOMS_COLORS.white,
                      }}
                    >
                      {(() => {
                        const matchingCompany = findCompanyByDomain(
                          event.companies,
                          domain,
                        );
                        return matchingCompany ? (
                          <>
                            <AttendeesAvatar
                              src={matchingCompany.logo || undefined}
                              variant="rounded"
                            >
                              {matchingCompany.name?.[0] || domain[0]}
                            </AttendeesAvatar>
                            <span>{matchingCompany.name || domain}</span>
                          </>
                        ) : domain === 'internal' ? (
                          'Internal'
                        ) : (
                          domain
                        );
                      })()}
                      <AttendeesBadge
                        badgeContent={sortedAttendeesByDomain[domain].length}
                        color="primary"
                      />
                    </Typography>
                  </Box>
                  <AttendeesList
                    dense
                    subheader={<AttendeesListSubheader disableSticky />}
                  >
                    {sortedAttendeesByDomain[domain].map((person) => (
                      <AttendeesListItem key={person.id}>
                        <AttendeesTypography variant="body2">
                          {withDealRoomContacts?.some(
                            (contact) => contact.email === person.email,
                          ) && (
                            <StarRounded
                              sx={{
                                position: 'absolute',
                                left: '-.75rem',
                                fontSize: '1rem',
                                color: DEALROOMS_COLORS.white,
                              }}
                            />
                          )}
                          {person.name || person.email}
                        </AttendeesTypography>
                      </AttendeesListItem>
                    ))}
                  </AttendeesList>
                </div>
              ))}
            </>
          }
        >
          <MeetingflowIconContainer>
            {withDealRoomContacts?.length > 0 && (
              <StyledAvatarGroup max={3}>
                {withDealRoomContacts.map((contact) => (
                  <Avatar
                    key={contact.id}
                    src={contact.avatarUrl || undefined}
                    alt={contact.name || contact.email}
                    sx={{ bgcolor: DEALROOMS_COLORS.peacock }}
                  >
                    {contact.name?.charAt(0)}
                  </Avatar>
                ))}
              </StyledAvatarGroup>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                padding: withDealRoomContacts?.length ? '4px' : 0,
              }}
            >
              <MeetingflowIcon
                sx={{
                  ...mfIconSx,
                  ...(withDealRoomContacts?.length
                    ? {
                        color: DEALROOMS_COLORS.white,
                      }
                    : {}),
                }}
              >
                <AssignmentInd />
              </MeetingflowIcon>
            </Box>
          </MeetingflowIconContainer>
        </DSTooltip>
      );
    }
    return null;
  }, [
    event,
    sortedAttendeesByDomain,
    withDealRoomContacts,
    findCompanyByDomain,
    mfIconSx,
  ]);

  const conferenceIcon = useMemo(() => {
    if (!event || !event?.conferenceInfo || hideConferenceIcon) {
      return null;
    }

    return (
      <DSTooltip
        title={`Join ${titleCase(event?.conferenceInfo?.type || 'Online')} Meeting`}
      >
        <JoinConferenceButton onClick={onConferenceIconClick}>
          <Videocam
            sx={{
              height: '16px',
              width: '16px',
            }}
          />
        </JoinConferenceButton>
      </DSTooltip>
    );
  }, [event, onConferenceIconClick, hideConferenceIcon]);

  return (
    <EventCardContainer title={cardTitleAttr || undefined}>
      <div className="event-details">
        <EventCardStack>
          <Typography component={'span'} className="event-title">
            {eventIsInPast && (
              <span
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: '.25rem',
                }}
              >
                <DSTooltip
                  title={`This event is in the past and can no longer be recorded. ${showAddToDecisionSiteButton && 'You can still add to your Decision Site.'}`}
                  placement="top"
                >
                  <span className={completedLinkClass}>
                    <FontIcon iconName="CheckMark" />
                  </span>
                </DSTooltip>
              </span>
            )}
            {showTimesOnly && event?.startTime ? (
              <span className="short-time">
                <StyledDateTime
                  useRelativeDates
                  dateTime={event?.startTime}
                  displayComponents={
                    showTimesOnly ? ['time'] : ['date', 'time']
                  }
                  shortMonths
                />
              </span>
            ) : null}
            {event.title}
          </Typography>
          <Typography component={'span'} className="event-datetime">
            {!showTimesOnly && event?.startTime ? (
              <StyledDateTime
                useRelativeDates
                dateTime={event?.startTime}
                displayComponents={showTimesOnly ? ['time'] : ['date', 'time']}
                shortMonths
              />
            ) : null}{' '}
          </Typography>
        </EventCardStack>
      </div>

      <div
        className="event-icons"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexGrow: 0.5,
          gap: '0.25rem',
        }}
      >
        {attendeesIcon}
        {conferenceIcon}
        {callRecordingButton}
        {showAddToDecisionSiteButton && <div>{addToDecisionSiteButton}</div>}
      </div>
    </EventCardContainer>
  );
};

export default DecisionSiteEventCard;
