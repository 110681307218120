export const ChevronDownIconProps = { iconName: 'ChevronDown' };

export const ChromeCloseIconProps = { iconName: 'ChromeClose' };

export const CheckMarkIconProps = { iconName: 'CheckMark' };

export const ChevronRightMedIconProps = { iconName: 'ChevronRightMed' };

export const ZoomIconProps = { iconName: 'Zoom' };

export const DownloadIconProps = { iconName: 'Download' };

export const DeleteIconProps = { iconName: 'Delete' };

export const AddArtifactIconProps = { iconName: 'AddArtifact' };

export const UpdateArtifactIconProps = { iconName: 'UpdateArtifact' };

export const BackIconProps = { iconName: 'Back' };

export const AddToIconProps = { iconName: 'AddTo' };

export const EyeOffIconProps = { iconName: 'EyeOff' };

export const EyeOpenIconProps = { iconName: 'EyeOpen' };

export const MoreIconProps = { iconName: 'More' };

export const ShareIconProps = { iconName: 'Share' };

export const CancelIconProps = { iconName: 'Cancel' };

export const MailIconProps = { iconName: 'Mail' };

export const BookIconProps = { iconName: 'Book' };

export const EditIconProps = { iconName: 'Edit' };

export const AcceptIconProps = { iconName: 'Accept' };

export const ChatIconProps = { iconName: 'Chat' };

export const DragIconProps = { iconName: 'DragObject' };
