/**
 * Custom hook for analyzing meeting transcript data and generating conversation metrics.
 * 
 * Key features:
 * - Calculates conversation statistics (talk time, turn counts, etc.)
 * - Processes raw utterances into logical conversation turns
 * - Memoizes calculations for performance optimization
 * - Handles empty transcripts gracefully
 */

import { useMemo } from 'react';
import { Utterance } from '@meetingflow/common/Api/data-contracts';
import {
  transcriptify,
  getTranscriptStats,
} from '@meetingflow/common/TranscriptHelpers';

/**
 * Props for the useMeetingflowStats hook
 * @property transcript - Array of time-ordered utterances from the meeting recording.
 *                       Each utterance contains speaker and content information.
 */
interface UseMeetingflowStatsProps {
  transcript: Utterance[];
}

export const useMeetingflowStats = ({
  transcript,
}: UseMeetingflowStatsProps) => {
  /**
   * Processes transcript data to generate conversation statistics.
   * 
   * Includes metrics such as:
   * - Speaker participation rates
   * - Talk time distribution
   * - Turn-taking patterns
   * 
   * Returns undefined for empty transcripts to allow proper UI handling.
   */
  const callStats = useMemo(
    () =>
      transcript.length
        ? getTranscriptStats(transcriptify(transcript))
        : undefined,
    [transcript],
  );

  /**
   * Transforms raw utterances into logical conversation turns.
   * 
   * A turn represents a continuous segment of speech by one speaker,
   * making it easier to analyze conversation flow and speaker interactions.
   * 
   * @param false - Prevents merging of adjacent turns by the same speaker,
   *                preserving granular timing information
   */
  const turns = useMemo(() => transcriptify(transcript, false), [transcript]);

  return {
    callStats, // Conversation metrics and statistics
    turns,     // Processed conversation structure
  };
};
