import { DealRoomMeetingflowArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';
import { toContentPath } from '../../../Helpers/FileHelpers';

export type MeetingflowArtifactCardProps = BaseArtifactCardProps &
  Pick<
    DealRoomMeetingflowArtifact,
    'thumbnailFileName' | 'thumbnailUrl' | 'meetingflowId'
  >;

export const MeetingflowArtifactCard = ({
  orgSlug,
  dealRoomId,
  thumbnailFileName,
  thumbnailUrl,
  meetingflowId,
  label,
  name,
  ...rest
}: MeetingflowArtifactCardProps) => {
  return (
    <BaseArtifactCard
      className="meetingflow-artifact"
      orgSlug={orgSlug}
      dealRoomId={dealRoomId}
      label={label}
      name={name}
      {...rest}
    >
      <ImageWithFallback
        src={toContentPath(thumbnailFileName, orgSlug, meetingflowId) || thumbnailUrl || undefined}
        alt={label || name}
        fallback={<ArtifactIconPreview type="MEETINGFLOW" />}
      />
    </BaseArtifactCard>
  );
};
