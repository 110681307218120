import { Icon, Stack, Text, mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

const styles = mergeStyleSets({
  container: {
    padding: '1rem 0',
    gap: '.5rem',
    borderRadius: '.25rem',
  },
  icon: {
    fontSize: '2rem',
    color: DEALROOMS_COLORS.neutralSecondary,
    marginBottom: '.5rem',
  },
  text: {
    color: DEALROOMS_COLORS.neutralSecondary,
    textAlign: 'center',
    fontWeight: '500',
  },
});

export const NoMilestonesAvailable = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      className={styles.container}
    >
      <Icon iconName="ClipboardList" className={styles.icon} />
      <Text variant="large" className={styles.text}>
        No Milestones Set
      </Text>
    </Stack>
  );
};
