import {
  styled,
  Box,
  TextField,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const KeyFactsGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
  gridAutoFlow: 'row',
  gridAutoRows: 'auto',
  gap: '1rem',
  width: '100%',
  '@container (max-width: 600px)': {
    gridTemplateColumns: 'none',
  },
}));

export const KeyFactCard = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '0',
  borderRadius: '.5rem',
  padding: '1rem',
  backgroundColor: DEALROOMS_COLORS.financialSecondaryGray,
  height: '100%',
}));

export const KeyFactHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  // justifyContent: 'space-between',
  alignItems: 'center',
  height: '1.5rem',
  marginBottom: '.5rem',
}));

export const KeyFactTitle = styled(Typography)(({ theme }) => ({
  color: DEALROOMS_COLORS.themePrimary,
  lineHeight: '1rem',
}));

export const EditButton = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  top: '-.25rem',
  right: '-.25rem',
}));

export const EditIcon = styled('span')(({ theme }) => ({
  fontSize: '1rem',
}));

export const EditContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: 'white',
  },
}));

export const SaveButton = styled(Box)(({ theme }) => ({
  minWidth: 'unset',
  padding: '.25rem',
}));

interface KeyFactValueProps {
  isLongText: boolean;
}

export const KeyFactValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isLongText',
})<KeyFactValueProps>(({ isLongText, theme }) => ({
  fontSize: isLongText ? '.875rem' : '1.5rem',
  lineHeight: isLongText ? '1.25rem' : '1rem',
  paddingTop: isLongText ? '0' : '.5rem',
  color: DEALROOMS_COLORS.themePrimary,
}));
