/**
 * DecisionSiteMeetingPanelAIChat provides an AI-powered chat interface for meeting interactions.
 * 
 * Key Features:
 * - Pre-meeting assistance: agenda planning, prep emails, question suggestions
 * - Post-meeting analysis: decisions, concerns, action items, summaries
 * - Context-aware actions based on meeting state and timing
 * - Support for both internal and external meetings
 * - Integration with meeting transcripts and recordings
 * - Real-time meeting state awareness
 */

import { mergeStyles } from '@fluentui/react';
import {
  Contact,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { GPTChatBox } from '../../../../Components/GPT/GPTChatBox';
import { GPTChatBoxContext } from '../../../../Components/GPT/GPTChatBoxContext';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { GPTChatBoxAction } from '../../../../Components/GPT/GPTChatBoxAction';
import { DecisionSiteGPTChatBox } from '../../../../Components/GPT/DecisionSiteGPTChatBox';

/**
 * Props interface defining all required and optional parameters
 * @property organizationSlug - Unique identifier for the organization
 * @property chatboxContext - Current state of the chat interface
 * @property setChatBoxContext - Updates the chat interface state
 * @property internalDomains - Organization's verified email domains
 * @property externalDomains - Known external collaborator domains
 * @property externalContactIds - IDs of external participants
 * @property onMeetingflowClick - Meeting selection handler
 * @property onContactClick - Contact interaction handler
 * @property onCompanyClick - Company selection handler
 * @property meetingPlan - Complete meeting details and configuration
 * @property onBack - Optional navigation handler
 * @property onClose - Panel close handler
 */
export type DecisionSiteMeetingPanelAIChatProps = {
  organizationSlug: string;
  chatboxContext: GPTChatBoxContext | undefined;
  setChatBoxContext: (context: GPTChatBoxContext | undefined) => unknown;
  internalDomains: string[];
  externalDomains: string[];
  externalContactIds: number[];
  onMeetingflowClick: (meetingflowId: DetailedMeetingflow['id']) => void;
  onContactClick: (c: Contact['id']) => void;
  onCompanyClick: (id: number) => void;
  meetingPlan: DetailedMeetingflow;
  onBack?: () => void;
  onClose: () => void;
};

export const DecisionSiteMeetingPanelAIChat = ({
  organizationSlug,
  meetingPlan,
  internalDomains,
  externalDomains,
  externalContactIds,
  chatboxContext,
  setChatBoxContext,
  onBack,
  onClose,
}: DecisionSiteMeetingPanelAIChatProps) => {
  /**
   * Organization and user context
   * Controls feature access and UI customization
   */
  const { isGuest, hasEntitlement } = useOrganization(organizationSlug);
  const { isDark } = useLightOrDarkMode();
  const { user: mfUser } = useUserProfile();

  /**
   * Styling configuration for panel sections
   * Ensures consistent spacing and empty state handling
   */
  const sidePanelSectionClass = mergeStyles({
    margin: '0 0 1.5rem 0',
    ':empty': {
      margin: 0,
    },
  });

  /**
   * Meeting timing calculations
   * Used to determine available features and actions
   * - beforeStart: Meeting hasn't begun
   * - beforeEnd: Meeting is ongoing or hasn't started
   * - afterEnd: Meeting has concluded
   * - afterEnd24Hours: Meeting ended more than 24 hours ago
   */
  const beforeStart = meetingPlan
    ? DateTime.fromISO(meetingPlan.startTime).diffNow().milliseconds > 0
    : true;
  const beforeEnd = meetingPlan
    ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds > 0
    : true;
  const afterEnd = meetingPlan
    ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds < 0
    : false;
  const afterEnd24Hours = meetingPlan
    ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds < -86400000
    : false;

  /**
   * External meeting detection
   * Checks attendee email domains against organization's internal domains
   * Used to enable/disable certain features for external meetings
   */
  const isExternalMeeting = meetingPlan.attendees.some((attendee) => {
    return !internalDomains.includes(attendee.emailDomain);
  });

  /**
   * Transcript availability check
   * Enables post-meeting analysis features when transcript exists
   */
  const hasTranscript = !!meetingPlan.callRecording?.transcriptRawText?.length;

  /**
   * Initial chat interface messages
   * Sets up the conversation starter and context
   */
  const initialMessages: {
    role: 'user' | 'assistant' | 'system';
    content: string;
    display?: boolean;
  }[] = useMemo(() => {
    return [
      {
        role: 'assistant',
        content: `What would you like to know?`,
        display: true,
      },
    ];
  }, []);

  /**
   * Available chat actions configuration
   * Actions are grouped into:
   * 1. Pre-meeting actions:
   *    - Meeting briefing
   *    - Agenda suggestions
   *    - Prep email drafting
   *    - Question suggestions (external meetings only)
   * 
   * 2. Post-meeting actions:
   *    - Decision analysis
   *    - Concern identification
   *    - Action item extraction
   *    - Meeting assessment
   *    - Summary generation
   *    - Follow-up suggestions
   *    - Follow-up email drafting
   * 
   * Each action's availability is controlled by:
   * - Meeting timing (before/after)
   * - User permissions (guest/member)
   * - Data availability (transcript)
   * - Meeting type (internal/external)
   */
  const AVAILABLE_ACTIONS: GPTChatBoxAction[] = useMemo(
    () => [
      // Pre-meeting actions
      {
        key: 'brief',
        type: 'action',
        label: 'Brief Me',
        iconName: 'Handwriting',
        enabled: beforeEnd && !isGuest,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Brief me on this Meetingflow',
          };
        },
      },
      {
        key: 'suggest-agenda',
        type: 'action',
        label: 'Suggest Agenda',
        iconName: 'CalendarAgenda',
        enabled: beforeEnd && !isGuest,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Can you suggest an agenda for the meeting?',
          };
        },
      },
      {
        key: 'draft-prep-email',
        type: 'action',
        label: 'Draft Email',
        iconName: 'Mail',
        enabled: beforeEnd && !isGuest,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Can you draft an email reminder to the attendees for me?',
          };
        },
      },
      {
        key: 'suggest-questions',
        type: 'action',
        label: 'Suggest Questions',
        iconName: 'Mail',
        enabled: beforeEnd && !isGuest && isExternalMeeting,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content:
              'Suggest some questions that would be good for me to ask during the meeting.',
          };
        },
      },
      // Post-meeting analysis actions
      {
        key: 'decisions-made',
        type: 'action',
        label: 'Decisions Made',
        iconName: 'DecisionSolid',
        enabled: afterEnd && !isGuest && hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Were any decisions made during the call?',
          };
        },
      },
      {
        key: 'concerns-raised',
        type: 'action',
        label: 'Concerns',
        iconName: 'WarningSolid',
        enabled: afterEnd && !isGuest && hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Were any issues or concerns raised during the call?',
          };
        },
      },
      {
        key: 'action-items',
        type: 'action',
        label: 'Action Items',
        iconName: 'TaskLogo',
        enabled: afterEnd && !isGuest && hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Were there any action items or follow-up tasks?',
          };
        },
      },
      {
        key: 'how-it-go',
        type: 'action',
        label: "How'd the call go?",
        iconName: 'UnknownCall',
        enabled: afterEnd && !isGuest && hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'How did the call go?',
          };
        },
      },
      {
        key: 'summarize',
        type: 'action',
        label: 'Summarize',
        iconName: 'Handwriting',
        enabled: afterEnd && !isGuest && !hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Please summarize this Meetingflow for me!',
          };
        },
      },
      {
        key: 'follow-up-ideas',
        type: 'action',
        label: 'Follow up Ideas',
        iconName: 'Lightbulb',
        enabled: afterEnd && !isGuest && !hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content:
              'Can you suggest ways in which I might follow up on this meeting?',
          };
        },
      },
      {
        key: 'draft-followup-email',
        type: 'action',
        label: 'Draft Follow-up Email',
        iconName: 'Mail',
        enabled: afterEnd && !isGuest && !hasTranscript,
        onClick: () => {
          return {
            role: 'user',
            display: true,
            content: 'Draft a follow up email to the attendees for me.',
          };
        },
      },
    ],
    [beforeEnd, afterEnd, isGuest, isExternalMeeting, hasTranscript],
  );

  /**
   * Chat context initialization
   * Sets up the chat interface with:
   * - Unique key for session management
   * - Initial welcome messages
   * - Available actions based on current state
   * Only initializes if context doesn't exist and user is authenticated
   */
  const thisChatBotContext: GPTChatBoxContext | undefined = useMemo(() => {
    if (!chatboxContext && mfUser && meetingPlan) {
      const actions = AVAILABLE_ACTIONS.filter((action) => {
        return action.enabled;
      });
      const context: GPTChatBoxContext = {
        key: `chatbot-${meetingPlan.id}`,
        initialMessages,
        actions,
      };
      return context;
    } else return chatboxContext;
  }, [chatboxContext, meetingPlan, mfUser, initialMessages, AVAILABLE_ACTIONS]);

  return (
    <>
      {thisChatBotContext ? (
        <DecisionSiteGPTChatBox
          key={thisChatBotContext.key}
          organizationSlug={organizationSlug!}
          meetingPlanId={meetingPlan.id}
          initialMessages={thisChatBotContext.initialMessages}
          defaultActions={thisChatBotContext.actions}
          showChatInput
          chatInputPlaceholder="Ask me a question."
        />
      ) : null}
    </>
  );
};
