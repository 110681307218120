import { useAuth0 } from '@auth0/auth0-react';
import { ITooltipHostStyles, NeutralColors, TextField } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import PublicEmailDomains from '@meetingflow/common/PublicEmailDomains';
import { titleize } from 'inflection';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import tlds from 'tlds';
import * as yup from 'yup';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { OrganizationsApiClient } from '../../Services/NetworkCommon';
import { AsyncPrimaryButton } from '../HOC/AsyncButton';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { AutomaticallyShareRecordingsPreference } from '@meetingflow/common/Api/data-contracts';

type CreateOrganizationFormData = {
  slug: string;
  name: string;
  allowJoinRequests: boolean;
  approveJoinRequestsAutomatically: boolean;
  approveJoinRequestsAutomaticallyAsRole?: 'CREATOR' | 'COLLABORATOR';
  automaticallyShareRecordings: AutomaticallyShareRecordingsPreference;
};

export type WelcomeCreateOrganizationProps = {
  companyName?: string;
  onOrganizationCreated: (slug: string) => void;
};

export const WelcomeCreateOrganization = ({
  companyName,
  onOrganizationCreated,
}: WelcomeCreateOrganizationProps) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { isDark } = useLightOrDarkMode();
  const domains = PublicEmailDomains;
  const emailDomain = useMemo(() => {
    return user!.email!.toLowerCase().split('@')[1];
  }, [user]);

  const userDomain = useMemo(() => {
    const matchingTld = tlds
      .filter((tld) => emailDomain?.endsWith(tld))
      .reduce((a, b) => (a.length <= b.length ? b : a));
    if (matchingTld) {
      const trimmedDomain = emailDomain?.replace(`.${matchingTld}`, '');
      if ((trimmedDomain?.length ?? 0) >= 5) {
        return trimmedDomain;
      }
    }

    return emailDomain;
  }, [emailDomain]);

  const defaultSlug = useMemo(() => {
    if (domains?.includes(emailDomain)) {
      const replaced = user!
        .email!.toLowerCase()
        .replaceAll(/[^a-z0-9]/g, '-')
        .replaceAll(/-{2,}/g, '-');
      if (replaced.length < 4) {
        return `${replaced}-flow`;
      }
      return replaced;
    }
    if (companyName) {
      return companyName
        .toLowerCase()
        .replaceAll(/[^a-z0-9]/g, '-')
        .replaceAll(/-{2,}/g, '-');
    }
    if (!userDomain) {
      return '';
    }
    const replaced = userDomain
      .toLowerCase()
      .replaceAll(/[^a-z0-9]/g, '-')
      .replaceAll(/-{2,}/g, '-');
    if (replaced.length < 4) {
      return `${replaced}-flow`;
    }
    return replaced;
  }, [companyName, domains, emailDomain, user, userDomain]);

  const defaultName = useMemo(() => {
    if (domains?.includes(emailDomain)) {
      if (user?.given_name && user.family_name) {
        return titleize(
          `${user.given_name}${
            user.given_name.endsWith('s') ? "'" : "'s"
          } Workspace`,
        );
      }
      if (user?.name) {
        return titleize(
          `${user.name}${user.name.endsWith('s') ? "'" : "'s"} Workspace`,
        );
      }
      return titleize(
        `${user!.email}${user!.email!.endsWith('s') ? "'" : "'s"} Workspace`,
      );
    }
    if (companyName) {
      return titleize(companyName);
    }

    if (!userDomain) {
      return '';
    }
    return titleize(userDomain.replaceAll('.', '-'));
  }, [companyName, domains, emailDomain, user, userDomain]);

  const formSchema = useMemo(
    () =>
      yup
        .object({
          slug: yup
            .string()
            .min(5, 'The workspace identifier must be at least 5 characters')
            .matches(
              /^[a-z][-a-z0-9]{3,}[a-z0-9]$/,
              'The identifier must start with a letter, and contain letters, numbers and dashes',
            )
            .test(
              'Available?',
              'The specified identifier is not available',
              async (value) => {
                if (!value || !value.match(/^[a-z][-a-z0-9]{3,}[a-z0-9]$/)) {
                  return false;
                }
                const token = await getAccessTokenSilently();
                try {
                  const result =
                    await OrganizationsApiClient.checkSlugAvailable(value, {
                      headers: { Authorization: `Bearer ${token}` },
                    });
                  return result.data;
                } catch (err) {
                  return false;
                }
              },
            )
            .required(),
          name: yup.string().required(),
          allowJoinRequests: yup.boolean(),
          approveJoinRequestsAutomatically: yup.boolean(),
          approveJoinRequestsAutomaticallyAsRole: yup
            .string()
            .oneOf(['COLLABORATOR', 'CREATOR'], 'Invalid role'),
          automaticallyShareRecordings: yup
            .string()
            .oneOf(['ENABLED', 'DISABLED']),
        })
        .required(),
    [getAccessTokenSilently],
  );

  const disableDiscoverability = !domains || domains.includes(emailDomain);

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    watch,
    trigger,
    setValue,
  } = useForm<CreateOrganizationFormData>({
    defaultValues: {
      slug: defaultSlug,
      name: defaultName,
      allowJoinRequests: disableDiscoverability ? false : true,
      approveJoinRequestsAutomatically: disableDiscoverability ? false : true,
      approveJoinRequestsAutomaticallyAsRole: 'COLLABORATOR',
    },
    resolver: yupResolver(formSchema, { abortEarly: false }),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('slug', defaultSlug, { shouldValidate: true });
    setValue('name', defaultName, { shouldValidate: true });
  }, [defaultSlug, defaultName, setValue]);

  useEffect(() => {
    if (disableDiscoverability) {
      setValue('allowJoinRequests', false);
      setValue('approveJoinRequestsAutomatically', false);
    } else {
      setValue('allowJoinRequests', true);
      setValue('approveJoinRequestsAutomatically', true);
    }
  }, [disableDiscoverability, setValue]);

  // Validate org slug on mount
  useEffect(() => {
    trigger('slug');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyName) {
      setValue('name', companyName);
      setValue(
        'slug',
        companyName
          .toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/[^-a-z0-9]/g, ''),
      );
    }
  }, [companyName, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    if (isValid) {
      const token = await getAccessTokenSilently();
      const result = await OrganizationsApiClient.putOrganization(
        data.slug,
        {
          name: data.name,
          automaticallyShareRecordings: data.automaticallyShareRecordings,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (result.status === 201) {
        if (
          data.allowJoinRequests &&
          data.approveJoinRequestsAutomaticallyAsRole
        ) {
          // Add the domain rules
          const ruleType = data.approveJoinRequestsAutomatically
            ? 'AUTO_APPROVE'
            : 'CAN_REQUEST_ACCESS';
          const roleType = data.approveJoinRequestsAutomaticallyAsRole;
          await OrganizationsApiClient.putDomainRule(
            data.slug,
            emailDomain,
            { roleType, ruleType },
            { headers: { Authorization: `Bearer ${token}` } },
          );
        }

        toast.success(`The workspace ${data?.name} has been created!.`);

        onOrganizationCreated(data.slug);
      }
    }
  });

  return (
    <div>
      <div className="welcome-wizard-form">
        <div className="elements column">
          <div className="elements row">
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onBlur, onChange } }) => (
                <TextField
                  value={value}
                  onChange={(_e, newValue) => onChange(newValue || '')}
                  onBlur={onBlur}
                  errorMessage={errors.name?.message}
                  label="Name"
                  styles={{ root: { flex: '.5 1 0px !important' } }}
                  description="Choose a name for your Workspace. It can be anything you like."
                />
              )}
            />

            <Controller
              name="slug"
              control={control}
              render={({ field: { value, onBlur, onChange } }) => (
                <TextField
                  prefix="https://app.meetingflow.com/organization/"
                  styles={{
                    prefix: {
                      backgroundColor: isDark
                        ? NeutralColors.gray190
                        : NeutralColors.gray20,
                    },
                  }}
                  value={value}
                  onChange={(_e, newValue) => onChange(newValue || '')}
                  onBlur={onBlur}
                  errorMessage={errors.slug?.message}
                  label="URL Identifier"
                  description={`Choose a custom URL identifier for your Workspace. It will be visible when you share Meetingflow links with others.`}
                />
              )}
            />
          </div>
        </div>
        <div className="controls">
          <AsyncPrimaryButton
            disabled={!isValid}
            text="Create Workspace"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeCreateOrganization;
