import { mergeStyleSets, Spinner, Text, useTheme } from '@fluentui/react';
import { useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import ResourceNotFound from '../ResourceNotFound';

import { DealRoomBuyersArtifactsTab } from './Tabs/DealRoomBuyersArtifactsTab';
import { DealRoomBuyersOverviewTab } from './Tabs/DealRoomBuyersOverviewTab';
import { DealRoomBuyersJourneyTab } from './Tabs/DealRoomBuyersJourneyTab';
import { DSConfigurationRoot } from './Configuration/DSConfigurationRoot';
import { UserSettingsWrapper } from './Components/UserSettings/UserSettingsWrapper';
import { useUserProfile } from '../../Hooks/useProfile';
import { DealRoomMutualProgressTab } from './Tabs/DealRoomMutualProgressTab';
import { useQueryClient } from 'react-query';
import useDeviceType from '../../Hooks/deviceDetection';
import { useAddDealRoomArtifactDialog } from '../../Hooks/Modals/DealRoom/useAddDealRoomArtifactDialog';
import DealRoomAsyncCommandBarButton from './Components/DealRoomButton';
import { DSButton, DSTooltip } from './DS';
import { AddOutlined, PlusOne } from '@mui/icons-material';
import { createMeetingflowAndDecisionSiteArtifact } from '../../utils/DecisionSiteMeetingflowHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DSAnalyticsRoot } from './Analytics/DSAnalyticsRoot';
export type DealRoomBuyersViewProps = {
  organizationSlug: string;
  dealRoomId: number;
};

export const DealRoomBuyersView = ({
  organizationSlug,
  dealRoomId,
}: DealRoomBuyersViewProps) => {
  const { isMobile } = useDeviceType();

  const { user } = useUserProfile();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const {
    dealRoom,
    isLoading,
    isFetched,
    refetch: refetchDealRoomRoot,
  } = useDealRoom(organizationSlug, dealRoomId);

  const queryClient = useQueryClient();

  const refetchDealRoom = useMemo(
    () => async () => {
      await queryClient.invalidateQueries({
        queryKey: ['artifacts', dealRoomId, organizationSlug],
      });
      await refetchDealRoomRoot();
    },
    [queryClient, dealRoomId, organizationSlug, refetchDealRoomRoot],
  );

  const {
    createDeferred: showAddDealRoomArtifactDialog,
    dialog: addDealRoomArtifactDialog,
  } = useAddDealRoomArtifactDialog({
    organizationSlug,
    dealRoomId,
  });

  const { dealRoomRole } = useDealRoom();

  const location = useLocation();

  const activeTab = useMemo(() => {
    const path = `/organization/:organizationSlug/decisionsite/:dealRoomId`;

    if (matchPath(`${path}/artifacts`, location.pathname)) {
      return 'artifacts';
    }

    if (matchPath(`${path}/journey`, location.pathname)) {
      return 'journey';
    }

    if (matchPath(`${path}/mutual-action-plan`, location.pathname)) {
      return 'mutual-action-plan';
    }

    if (matchPath(`${path}/settings`, location.pathname)) {
      return 'settings';
    }

    if (matchPath(`${path}/config`, location.pathname)) {
      return 'config';
    }

    if (matchPath(`${path}/analytics`, location.pathname)) {
      return 'analytics';
    }

    return 'overview';
  }, [location.pathname]);

  const styles = mergeStyleSets({
    header: {
      // ...(activeTab === 'mutual-action-plan'
      //   ? {
      //       maxWidth: 'calc(80% - 42px)',
      //     }
      //   : {}),
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gridTemplateRows: 'auto auto',
      gridTemplateAreas: `
        "title actions"
        "subtitle actions"
      `,

      marginBottom: '1rem',

      '.title': {
        gridArea: 'title',
      },

      '.section-title': {
        fontWeight: 400,
        fontSize: '1.75rem',
        lineHeight: '2rem',
        color: DEALROOMS_COLORS.themePrimary,
      },

      '.subtitle': {
        gridArea: 'subtitle',
      },

      '.actions': {
        gridArea: 'actions',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '1rem',
      },
    },

    content: {
      '& [role="tabpanel"]': {
        margin: '0.5rem',
      },
    },

    mainContent: {
      width: '100%',
      height: '100%',
      overflowX: 'auto',
      padding: isMobile ? '1rem' : '2rem',
    },

    prioritiesWrapper: {
      paddingBottom: '3.5rem',
    },

    headerContentWrapper: {
      marginLeft: 'auto',
    },

    tabHeaderContentWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      button: {
        height: '2.5rem',
      },
    },

    mainWrapper: {
      display: 'flex',
      width: '100%',
      minHeight: '100%',
    },
  });

  const content = useMemo(() => {
    if (!dealRoom) {
      return null;
    }

    switch (activeTab) {
      case 'overview':
        return (
          <DealRoomBuyersOverviewTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoom={dealRoom!}
            refreshDealRoom={refetchDealRoom}
          />
        );
      case 'artifacts':
        return (
          <DealRoomBuyersArtifactsTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoom={dealRoom!}
            refreshDealRoom={refetchDealRoom}
          />
        );
      case 'journey':
        return <DealRoomBuyersJourneyTab />;
      case 'mutual-action-plan':
        return (
          <DealRoomMutualProgressTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
          />
        );
      case 'settings':
        return (
          <UserSettingsWrapper
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            refetchDealRoom={refetchDealRoom}
          />
        );
      case 'config':
        return <DSConfigurationRoot />;

      case 'analytics':
        return <DSAnalyticsRoot />;
      default:
        return null;
    }
  }, [activeTab, dealRoom, dealRoomId, organizationSlug, refetchDealRoom]);

  const activeTabTitle = useMemo(() => {
    const name = user?.firstName || user?.name?.split(/\\s/)?.[0];
    switch (activeTab) {
      case 'overview':
        if (!name) {
          return 'Hello!';
        }
        return `Hello ${name}!`;
      case 'artifacts':
        return 'Artifacts';
      case 'journey':
        return 'Journey';
      case 'mutual-action-plan':
        return 'Mutual Plan';
      case 'config':
        return 'Configuration';
      case 'analytics':
        return 'Analytics';
      case 'settings':
        return 'Settings';
    }
  }, [activeTab, user]);

  const appInsights = useAppInsightsContext();

  const [isCreatingQuickMeeting, setIsCreatingQuickMeeting] = useState(false);

  const activeTabHeaderContent = useMemo(() => {
    switch (activeTab) {
      case 'artifacts':
        return (
          <>
            <DSButton
              onClick={async (ev) => {
                try {
                  const result = await showAddDealRoomArtifactDialog().promise;
                  if (result) {
                    await refetchDealRoom();
                  }
                } catch (error) {
                  // Ignore synthetic events and cancellation
                  if (
                    (error &&
                      typeof error === 'object' &&
                      '_reactName' in error) ||
                    error === 'Cancelled' ||
                    error === undefined
                  ) {
                    return;
                  }
                  throw error;
                }
              }}
            >
              <AddOutlined />
              Add Artifact
            </DSButton>
          </>
        );
      case 'journey':
        return (
          <>
            {dealRoomRole === 'SELLER' && (
              <>
                <DSTooltip
                  title={
                    <span>
                      A Quick Meeting is a meeting that is not on your calendar.
                      To add a meeting from your calendar, select "Add" next to
                      the event below.
                    </span>
                  }
                  placement="bottom"
                >
                  <DSButton
                    onClick={async () => {
                      setIsCreatingQuickMeeting(true);
                      try {
                        const token = await getAccessTokenSilently();
                        const result =
                          await createMeetingflowAndDecisionSiteArtifact({
                            organizationSlug,
                            dealRoomId,
                            token,
                            appInsights,
                            queryClient,
                            scheduleCallRecording: false,
                          });
                        navigate(
                          `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${result.meetingflowId}`,
                        );
                      } finally {
                        setIsCreatingQuickMeeting(false);
                      }
                    }}
                    disabled={isCreatingQuickMeeting}
                  >
                    {isCreatingQuickMeeting
                      ? 'Creating...'
                      : 'Add Quick Meeting'}
                  </DSButton>
                </DSTooltip>
              </>
            )}
          </>
        );

        return null;
      case 'overview':
      case 'config':
        return null;
      case 'analytics':
        return null;
      case 'settings':
        return null;
      case 'mutual-action-plan':
        return null;
      default:
        return null;
    }
  }, [
    activeTab,
    showAddDealRoomArtifactDialog,
    refetchDealRoom,
    getAccessTokenSilently,
    organizationSlug,
    dealRoomId,
    appInsights,
    queryClient,
    navigate,
    dealRoomRole,
    isCreatingQuickMeeting,
  ]);

  if (isLoading && !isFetched) {
    return <Spinner />;
  }

  if (!dealRoom) {
    return <ResourceNotFound resourceType="Deal Room" />;
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.mainContent}>
        <div className={styles.header}>
          <Text className="section-title" variant="xxLargePlus" block>
            {activeTabTitle}
          </Text>
          <div className={styles.tabHeaderContentWrapper}>
            {activeTabHeaderContent}
          </div>
        </div>
        <div className={styles.content}>{content}</div>
      </div>
      {addDealRoomArtifactDialog}
    </div>
  );
};

export default DealRoomBuyersView;
