import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

type UseCommentsPanelStylesProps = {
  commentsPanelType: 'task' | 'artifact';
  textEditorHighlightedBorder?: boolean;
  isMobile?: boolean;
};

export const useCommentsPanelStyles = ({
  commentsPanelType,
  textEditorHighlightedBorder = false,
  isMobile = false,
}: UseCommentsPanelStylesProps) => {
  return mergeStyleSets({
    convWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      ...(commentsPanelType === 'task'
        ? {
            backgroundColor: DEALROOMS_COLORS.white,
            borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
          }
        : {}),
    },
    conversationsContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      '.addConvContainer': {
        display: 'flex',
        flexFlow: 'column',
        ...(commentsPanelType === 'artifact'
          ? {
              padding: isMobile
                ? '1rem .5rem 1rem .5rem'
                : '2rem 1.5rem 1rem 1.5rem',
            }
          : { padding: isMobile ? '1rem .5rem 1rem' : '2rem 1rem 1.5rem' }),
      },
      '.addConvButton': {},
      '.conversationsList': {
        display: 'flex',
        ...(commentsPanelType === 'artifact'
          ? {
              padding: isMobile
                ? '.5rem .5rem 1rem .5rem'
                : '.5rem 1.5rem 1.5rem 1.5rem',
            }
          : {
              padding: isMobile ? '.5rem' : '.5rem 1rem',
            }),
        gap: '0.5rem',
        overflow: 'auto',
      },
    },
    selectedConvContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      '.ms-layer:empty': {
        display: 'none',
      },
      '.commentsList': {
        padding: isMobile ? '.5rem' : '1rem .75rem',
        overflow: 'auto',
        flexGrow: '1',
        gap: '.5rem',
      },
      '.writeCommentContainer': {
        borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
        padding: isMobile ? '.75rem' : '1.5rem',
        '.writeMainCommentContainer': {
          border: `1px solid ${textEditorHighlightedBorder ? DEALROOMS_COLORS.themeSecondary : DEALROOMS_COLORS.neutralDark}`,
          borderRadius: '0.25rem',
          padding: '1rem',
        },
      },
    },
  });
};
