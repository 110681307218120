import { styled, Box } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const PanelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

export const HeaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 1rem',
  position: 'relative',

  borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  backgroundColor: 'transparent',
  zIndex: 1,
  flex: '0 0 auto', // Don't grow or shrink, use auto height
});

export const MinimizeButtonWrapper = styled('div')({
  position: 'absolute',
  bottom: '-1rem',
  right: '1rem',
  transform: 'translate(-50%, 0)',
  display: 'inline-block',
  zIndex: 10,
});

export const MinimizeButton = styled('button')({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  backgroundColor: DEALROOMS_COLORS.white,
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  color: DEALROOMS_COLORS.cloudburst,
  '&:hover': {
    backgroundColor: DEALROOMS_COLORS.cloudburst,
    color: DEALROOMS_COLORS.white,
  },
  padding: 0,
  outline: 'none',
  '&:focus': {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  },
});

export const ContentWrapper = styled('div')<{ contentMinimized?: boolean }>(
  ({ contentMinimized }) => ({
    padding: contentMinimized ? 0 : '1.5rem 1rem 1rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto', // Don't grow, only take space needed
    overflow: 'visible',
    backgroundColor: 'transparent',
    boxShadow: 'inset 0px -2px 4px rgba(0, 0, 0, 0.05)',
    maxHeight: contentMinimized ? '0' : 'none',
    transition: 'all 0.3s ease-in-out',

    '.transcript-timeline': {
      // borderBottomLeftRadius: '.25rem',
      // borderBottomRightRadius: '.25rem',
      margin: 0,
      padding: '1rem',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      backgroundColor: DEALROOMS_COLORS.white,
      borderTop: 'none',
      position: 'relative',
      bottom: '0',
      left: 0,
      lineHeight: '.5rem',
      overflow: 'hidden',

      '.ms-TooltipHost, .speaker-turn, .speaker-turn-wrapper': {
        position: 'relative',
        height: '.5rem',
        lineHeight: '.5rem',
        borderRadius: '.125rem',
        cursor: 'pointer',

        '.speaker-name': {
          position: 'absolute',
          top: '0',
          left: '0',
          display: 'none',

          ':first-child': { display: 'block' },
        },
      },

      dl: {
        margin: 0,
        minWidth: 'calc(50% - 1.25rem)',
      },

      //   '@container (width >= 30rem)': {
      //     width: `calc(100% - .5rem) !important`,
      //   },
    },
  }),
);

export const VideoPlayerWrapper = styled('div')({
  backgroundColor: '#000',
  containerType: 'inline-size',
});

export const VideoPlayerContainer = styled('div')<{ isMinimized?: boolean }>(
  ({ isMinimized }) => ({
    position: isMinimized ? 'fixed' : 'relative',
    bottom: isMinimized ? '20px' : 'auto',
    right: isMinimized ? '20px' : 'auto',
    width: isMinimized ? '100%' : '100%',
    aspectRatio: '16/9',
    backgroundColor: DEALROOMS_COLORS.white,
    borderRadius: isMinimized ? '8px' : '0',
    boxShadow: isMinimized ? '0 4px 12px rgba(0, 0, 0, 0.15)' : 'none',
    zIndex: isMinimized ? 9999 : 'auto',
    cursor: isMinimized ? 'move' : 'default',
    transition: 'all 0.2s ease',
    overflow: 'hidden',
    maxWidth: !isMinimized
      ? 'min(100%, max(200px, 70vh))'
      : 'min(100%, max(200px, 40vh))',
    margin: '0 auto',

    '&:hover': {
      transform: isMinimized ? 'scale(1.02)' : 'none',
    },
  }),
);

export const VideoPlayerContent = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  '& > *': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

export const TabsWrapper = styled('div')({
  position: 'relative',
  backgroundColor: 'white',
  borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto', // Grow to fill remaining space
  minHeight: 0, // Allow proper scrolling in flex container
  overflow: 'hidden',
  paddingTop: '1rem',

  '& .meeting-panel-tabs': {
    flex: '1 1 auto', // Grow to fill remaining space
    minHeight: '100%', // Allow proper scrolling in flex container
    maxHeight: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& .MuiTabs-scroller': {
      padding: '0 1rem',
    },
  },

  '& .editor-toolbar': {
    backgroundColor: DEALROOMS_COLORS.neutralLight,
    position: 'sticky',
    top: '-1rem',
    zIndex: 1,
  },

  '& #editor-host': {
    marginBottom: 0,
    paddingBottom: 0,
  },
});

export const TabLabelWithIcon = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const AttendeesTabContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  '& #meetingflow-attendees': {
    width: '100%',
  },

  '& .company-attendee-group': {
    width: 'auto',
    backgroundColor: DEALROOMS_COLORS.financialLighterGray,
    borderRadius: '4px',
    padding: '1rem',
  },
});

export const RecordingStatusBarWrapper = styled('div')({
  width: '100%',
  backgroundColor: '#f5f5f5',
  // borderRadius: '4px',
  color: '#666',
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  padding: '.5rem 0 0 0',
});

export const StatusDisplay = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
});
