/**
 * A React component that provides recording controls for meeting sessions.
 *
 * Key features:
 * - Manages meeting recording state (start, stop, schedule, cancel)
 * - Handles different conference platforms (Zoom, Teams, etc.)
 * - Integrates with real-time collaboration
 * - Provides visual feedback through toast notifications
 * - Tracks analytics events
 * - Supports both scheduled and quick meetings
 */

import { useAuth0 } from '@auth0/auth0-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { MeetingflowsApiClient } from '../../../../Services/NetworkCommon';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { RecordButton } from './DecisionSiteMeetingflowRecordButton.styles';
import { DSTooltip } from '../../../../Components/DealRoom/DS';
import {
  FiberManualRecord,
  Stop,
  Cancel,
  Block,
  Error as ErrorIcon,
  VideocamOff,
  Circle,
  OndemandVideo,
  PlayCircle,
} from '@mui/icons-material';
import { useGetConferenceJoinLinkDialog } from '../../../../Hooks/Modals/useGetConferenceJoinLinkDialog';
import { readClipboardContents } from '../../../../Helpers/ClipboardHelpers';
import { tryParseConferenceInfo } from '@meetingflow/common/StringHelpers';
import { sendWSMessage } from '../../../..//Helpers/WSHelpers';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import { MeetingPlanCallRecorderStatusQuery } from '../../../..//QueryNames';
import { useCollabProvider } from '../../../..//Hooks/useCollabProvider';
import { useUserProfile } from '../../../..//Hooks/useProfile';
import randomColor from 'randomcolor';
import { Y } from '@syncedstore/core';
import { useOrganization } from '../../../..//Hooks/useOrganization';
import { useMeetingflow } from './hooks/useMeetingflow';
import { CallRecordingStatus } from '@meetingflow/common/Api/data-contracts';

/**
 * Props for the DecisionSiteMeetingflowRecordButton component
 * @property meetingflowId - Unique identifier for the meeting session
 * @property canCreatePlans - Whether the user has permission to create meeting plans
 * @property dealRoomRole - User's role in the deal room (default: 'SELLER')
 * @property onStatusChange - Callback for recording status changes
 * @property mini - Whether to show a compact version of the button
 * @property hideCallRecordingButton - Whether to hide the call recording button
 * @property iconOnly - Whether to show only the icon
 */
interface DecisionSiteMeetingflowRecordButtonProps {
  meetingflowId: string;
  canCreatePlans?: boolean;
  dealRoomRole?: string;
  onStatusChange?: (newStatus: CallRecordingStatus) => void;
  mini?: boolean;
  hideCallRecordingButton?: boolean;
  iconOnly?: 'always' | 'when-inactionable';
}

export const DecisionSiteMeetingflowRecordButton: React.FC<
  DecisionSiteMeetingflowRecordButtonProps
> = ({
  meetingflowId,
  canCreatePlans = true,
  dealRoomRole = 'SELLER',
  onStatusChange,
  mini,
  hideCallRecordingButton = false,
  iconOnly,
}) => {
  // Authentication and user context
  const { user, getAccessTokenSilently } = useAuth0();
  const { name, email, picture } = user!;
  const appInsights = useAppInsightsContext();
  const { isDark } = useLightOrDarkMode();
  const { hasEntitlement, slug: organizationSlug } = useOrganization();

  // User profile and preferences
  const { user: mfUser } = useUserProfile();

  // Generate consistent color for user in collaboration
  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  // Initialize Yjs document for collaboration
  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, meetingflowId]);

  // Dialog for getting conference join link
  const {
    createDeferred: createGetConferenceJoinLinkDeferred,
    dialog: getConferenceJoinLinkDialog,
  } = useGetConferenceJoinLinkDialog();

  // React Query client for cache management
  const client = useQueryClient();

  // Meeting data and state management
  const {
    meetingflow,
    refetchMeetingflow,
    isInPast,
    isRecordingScheduledOrActive,
    refetchRecorderStatus,
    scheduleCallRecording,
    isBeforeEnd,
  } = useMeetingflow(meetingflowId, onStatusChange);

  /**
   * Handles WebSocket messages for real-time updates
   * Triggers appropriate refetches based on message type
   */
  const onWSMessage = useCallback(
    (messageType: number) => {
      switch (messageType) {
        case WS_MESSAGE.REFRESH_PLAN: {
          refetchMeetingflow();
          return true;
        }
        // case WS_MESSAGE.REFRESH_ACTION_ITEMS: {
        //   refetchTasks();
        //   return true;
        // }
        case WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS: {
          if (
            !!organizationSlug &&
            !!meetingflowId &&
            hasEntitlement('CALL_RECORDING') &&
            !!meetingflow?.callRecording?.id
          ) {
            client.removeQueries(
              MeetingPlanCallRecorderStatusQuery(
                organizationSlug,
                meetingflowId,
              ),
            );

            refetchRecorderStatus();
          }
          return true;
        }

        case WS_MESSAGE.HEARTBEAT:
        case WS_MESSAGE.RECREATE:
        case WS_MESSAGE.SAVE: {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    [
      client,
      hasEntitlement,
      meetingflowId,
      organizationSlug,
      meetingflow?.callRecording?.id,
      refetchRecorderStatus,
      refetchMeetingflow,
    ],
  );

  /**
   * Real-time collaboration setup using Yjs
   * Handles presence awareness and message routing
   */
  const {
    provider,
    awarenessStates,
    hasConnected: collabHasConnected,
    isConnected: collabIsConnected,
    isSynced: collabIsSynced,
    isError: collabIsError,
    recreateProvider,
  } = useCollabProvider({
    providerName: 'MEETINGPLAN',
    documentName: `MeetingPlan__${organizationSlug}__${meetingflowId}`,
    ydoc,
    color,
    email: email!,
    name: mfUser?.name || user?.name,
    picture: mfUser?.avatarFileUrl || mfUser?.avatarUrl || user?.picture,
    onMessage: onWSMessage,
  });

  /**
   * Handles scheduling recording for quick (unscheduled) meetings
   * - Attempts to parse conference link from clipboard
   * - Validates conference platform support
   * - Initiates recording and updates UI
   */
  const scheduleCallRecordingForQuickMeeting = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const clipboardContent = await readClipboardContents();
      const initialJoinUri = clipboardContent
        ? tryParseConferenceInfo(clipboardContent) || undefined
        : undefined;
      const { conferenceInfo } = await createGetConferenceJoinLinkDeferred({
        joinURI: initialJoinUri?.joinUri,
      }).promise;

      if (!conferenceInfo) {
        return;
      }

      if (!conferenceInfo.joinUri) {
        return;
      }

      const conferenceType = conferenceInfo?.type;
      if (
        conferenceType &&
        ['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(conferenceType)
      ) {
        switch (conferenceType) {
          case 'ciscoWebEx':
            toast.error(`WebEx is not a supported platform`);
            return;
          case 'skypeForBusiness':
            toast.error(`Skype For Business is not a supported platform`);
            return;
          case 'skype':
            toast.error(`Skype is not a supported platform`);
            return;
        }
        return;
      }

      const headers = (token: string) => ({
        Authorization: `Bearer ${token}`,
      });
      const validateStatus = (status: number) => [200, 201].includes(status);

      const token = await getAccessTokenSilently();
      await toast.promise(
        MeetingflowsApiClient.startOrScheduleCallRecording(
          organizationSlug!,
          meetingflowId!,
          {
            joinURI: conferenceInfo.joinUri,
          },
          {
            headers: headers(token),
            validateStatus,
          },
        ),
        {
          loading: `Starting Call Recording`,
          error: (err) => {
            console.error(err);
            return `Something went wrong starting call recording`;
          },
          success: (result) => {
            sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_PLAN);
            sendWSMessage(
              provider?.ws,
              WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS,
            );

            client.removeQueries(
              MeetingPlanCallRecorderStatusQuery(
                organizationSlug!,
                meetingflowId,
              ),
            );

            refetchRecorderStatus();
            refetchMeetingflow();

            onStatusChange?.('scheduled');

            appInsights.trackEvent({
              name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
              properties: {
                organizationSlug,
                meetingflowId,
                change: 'started',
              },
            });

            return result.status === 201
              ? `Successfully scheduled call recording`
              : `Successfully started call recording`;
          },
        },
      );
    },
    [
      meetingflowId,
      organizationSlug,
      appInsights,
      client,
      createGetConferenceJoinLinkDeferred,
      getAccessTokenSilently,
      onStatusChange,
      provider?.ws,
      refetchRecorderStatus,
      refetchMeetingflow,
    ],
  );

  /**
   * Renders the appropriate button variant based on meeting state
   * - Shows nothing for unsupported platforms or insufficient permissions
   * - Displays "Record" for new recordings
   * - Shows "Stop"/"Cancel" for active/scheduled recordings
   * - Adapts to meeting timing (before/during/after)
   */
  const button = useMemo(() => {
    if (hideCallRecordingButton) {
      return null;
    }

    if (!meetingflow || dealRoomRole !== 'SELLER') {
      return null;
    }

    if (!canCreatePlans) {
      return null;
    }

    if (isInPast) {
      const status = meetingflow.callRecording?.lastStatus;
      const wasRecorded =
        status &&
        !['not_recorded', 'deleted', undefined, null].includes(status) &&
        meetingflow.callRecording?.recordingFileName;
      const hasVideoCapability = Boolean(
        meetingflow.conferenceInfo &&
          'type' in meetingflow.conferenceInfo &&
          !['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
            meetingflow.conferenceInfo.type,
          ),
      );

      if (wasRecorded) {
        const showIconOnly =
          iconOnly === 'always' || (iconOnly === 'when-inactionable' && true);

        const isActivelyRecording =
          meetingflow.callRecording?.lastStatus === 'in_call_recording';
        return (
          <DSTooltip
            title={
              isActivelyRecording
                ? 'Recording in progress'
                : 'This meeting has been recorded'
            }
          >
            <RecordButton
              variant={isActivelyRecording ? 'recording' : 'recorded'}
              isDark={isDark}
              mini={mini || (iconOnly === 'when-inactionable' && true)}
              iconOnly={showIconOnly}
            >
              {isActivelyRecording ? (
                <Circle fontSize="small" />
              ) : (
                <PlayCircle fontSize="small" />
              )}
              {!showIconOnly &&
                (isActivelyRecording ? 'Recording' : 'Recorded')}
            </RecordButton>
          </DSTooltip>
        );
      }

      // Show a skipped icon for non-video meetings and non-recorded meetings. Commented out for now, decided we'd rather show nothing.

      // if (hasVideoCapability) {
      //   const showIconOnly =
      //     iconOnly === 'always' || (iconOnly === 'when-inactionable' && true);
      //   return (
      //     <DSTooltip title="This meeting was not recorded">
      //       <RecordButton
      //         variant="disabled"
      //         isDark={isDark}
      //         mini={mini || (iconOnly === 'when-inactionable' && true)}
      //         iconOnly={showIconOnly}
      //       >
      //         <VideocamOff fontSize="small" />
      //         {!showIconOnly && 'Skipped'}
      //       </RecordButton>
      //     </DSTooltip>
      //   );
      // } else {
      //   const showIconOnly =
      //     iconOnly === 'always' || (iconOnly === 'when-inactionable' && true);
      //   return (
      //     <DSTooltip title="This was not a video meeting.">
      //       <RecordButton
      //         variant="disabled"
      //         isDark={isDark}
      //         mini={mini || (iconOnly === 'when-inactionable' && true)}
      //         iconOnly={showIconOnly}
      //       >
      //         <VideocamOff fontSize="small" />
      //         {!showIconOnly && 'Skipped'}
      //       </RecordButton>
      //     </DSTooltip>
      //   );
      // }

      return null;
    }

    if (isRecordingScheduledOrActive) {
      const isInCall = ['in_call_not_recording', 'in_call_recording'].includes(
        meetingflow.callRecording?.lastStatus || '',
      );
      const isRecording =
        meetingflow.callRecording?.lastStatus === 'in_call_recording';

      return (
        <DSTooltip
          title={
            isInCall
              ? 'Stop recording this meeting'
              : 'Cancel scheduled recording'
          }
        >
          <RecordButton
            variant={isRecording ? 'recording' : 'scheduled'}
            isDark={isDark}
            onClick={scheduleCallRecording}
            mini={mini}
            iconOnly={iconOnly === 'always'}
          >
            {isInCall ? <Stop fontSize="small" /> : <Cancel fontSize="small" />}
            {!(iconOnly === 'always') && (isInCall ? 'Recording' : 'Cancel')}
          </RecordButton>
        </DSTooltip>
      );
    }

    const conferenceType = meetingflow?.conferenceInfo?.type;
    if (
      conferenceType &&
      ['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(conferenceType)
    ) {
      const showIconOnly =
        iconOnly === 'always' || (iconOnly === 'when-inactionable' && true);
      return (
        <DSTooltip
          title={`Recording is not supported for ${conferenceType} meetings`}
        >
          <RecordButton
            variant="disabled"
            isDark={isDark}
            mini={mini || (iconOnly === 'when-inactionable' && true)}
            iconOnly={showIconOnly}
          >
            <ErrorIcon fontSize="small" />
            {!showIconOnly && `${conferenceType} Not Supported`}
          </RecordButton>
        </DSTooltip>
      );
    }

    if (
      (!meetingflow.callRecording ||
        meetingflow.callRecording.lastStatus === 'deleted') &&
      isBeforeEnd
    ) {
      const isQuickMeeting =
        !meetingflow.externalId && !meetingflow.conferenceInfo;
      return (
        <DSTooltip
          title={
            isQuickMeeting
              ? 'Start a quick meeting recording'
              : 'Schedule meeting recording'
          }
        >
          <RecordButton
            variant="not-scheduled"
            isDark={isDark}
            onClick={
              isQuickMeeting
                ? scheduleCallRecordingForQuickMeeting
                : scheduleCallRecording
            }
            mini={mini}
            iconOnly={iconOnly === 'always'}
          >
            <Circle fontSize="small" />
            {!(iconOnly === 'always') && 'Record'}
          </RecordButton>
        </DSTooltip>
      );
    }

    const status = meetingflow.callRecording?.lastStatus || 'not_recorded';
    const getTooltipText = () => {
      if (
        (isInPast || status === 'done') &&
        status !== 'not_recorded' &&
        status !== 'deleted' &&
        meetingflow?.callRecording?.recordingFileName
      ) {
        return 'This meeting has been recorded';
      }
      switch (status) {
        case 'not_recorded':
          return 'This meeting was not recorded';
        case 'deleted':
          return 'The recording for this meeting has been deleted';
        case 'fatal':
          return 'There was an error with the recording';
        default:
          return `Recording Status: ${status
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}`;
      }
    };

    const getStatusIcon = () => {
      if (
        (isInPast || status === 'done') &&
        status !== 'not_recorded' &&
        status !== 'deleted' &&
        meetingflow?.callRecording?.recordingFileName
      ) {
        return <OndemandVideo fontSize="small" />;
      }
      switch (status) {
        case 'not_recorded':
          return <VideocamOff fontSize="small" />;
        case 'deleted':
          return <Block fontSize="small" />;
        case 'fatal':
          return <ErrorIcon fontSize="small" />;
        default:
          return <FiberManualRecord fontSize="small" />;
      }
    };

    const statusText =
      status === 'not_recorded'
        ? 'Not Recorded'
        : status === 'deleted'
          ? 'Recording Deleted'
          : status
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');

    const showIconOnly =
      iconOnly === 'always' || (iconOnly === 'when-inactionable' && true);

    return (
      <DSTooltip title={getTooltipText()}>
        <RecordButton
          variant={
            (isInPast || status === 'done') &&
            status !== 'not_recorded' &&
            status !== 'deleted' &&
            meetingflow?.callRecording?.recordingFileName
              ? 'recorded'
              : 'disabled'
          }
          isDark={isDark}
          mini={mini || (iconOnly === 'when-inactionable' && true)}
          iconOnly={showIconOnly}
        >
          {getStatusIcon()}
          {!showIconOnly && statusText}
        </RecordButton>
      </DSTooltip>
    );
  }, [
    isBeforeEnd,
    meetingflow,
    scheduleCallRecording,
    scheduleCallRecordingForQuickMeeting,
    canCreatePlans,
    dealRoomRole,
    isDark,
    mini,
    isRecordingScheduledOrActive,
    hideCallRecordingButton,
    isInPast,
    iconOnly,
  ]);

  return (
    <>
      {button}
      <div style={{ display: 'none' }}>{getConferenceJoinLinkDialog}</div>
    </>
  );
};

export default DecisionSiteMeetingflowRecordButton;
