import {
  Transcript,
  TranscriptStats,
  TranscriptTurn,
  TranscriptUtterance,
} from '@meetingflow/common/TranscriptHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { VideoPlayerControls } from '../Video/VideoPlayer';
import { TooltipHost, Text, FontWeights, FontSizes } from '@fluentui/react';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { Duration } from 'luxon';

interface CallRecordingTimelineProps {
  speakers: string[];
  turns: Transcript;
  callStats: TranscriptStats | undefined;
  meetingPlanId: string;
  getColorForSpeaker: (speaker: string) => string;
  videoPlayerControls?: VideoPlayerControls | null | undefined;
}

const CallRecordingTimeline: React.FC<CallRecordingTimelineProps> = ({
  speakers,
  turns,
  callStats,
  meetingPlanId,

  getColorForSpeaker,
  videoPlayerControls,
}) => {
  const appInsights = useAppInsightsContext();

  const getWidthPercentageForSpeakerTurn = (turn: TranscriptTurn) => {
    let totalDuration = turns.slice(-1)[0]?.endTime - turns[0]?.startTime || 0;
    if (callStats?.silenceTime) {
      totalDuration -= callStats.silenceTime;
    }
    const turnDuration = turn?.endTime - turn?.startTime || 0;
    return (turnDuration / totalDuration) * 100;
  };

  const formatSeconds = (seconds: number) =>
    Duration.fromObject({ seconds }).toFormat('h:mm:ss');

  return turns?.map((t: TranscriptUtterance) => (
    <div
      className="speaker-turn-wrapper"
      style={{
        backgroundColor: t.speaker ? getColorForSpeaker(t.speaker) : undefined,
        top: t.speaker ? `${speakers.indexOf(t.speaker) * 0.5}rem` : undefined,
        width: `${getWidthPercentageForSpeakerTurn(t)}%`,
      }}
      key={`${t.speaker}-${t.startTime}`}
      onClick={(e) => {
        videoPlayerControls?.seek(t.startTime);
        appInsights.trackEvent({
          name: 'CALL_RECORDING_TIMELINE_SPEAKER_TURN_CLICK',
          properties: {
            meetingPlanId,
            recordingTimestamp: t.startTime,
          },
        });
      }}
    >
      <TooltipHost
        onTooltipToggle={(visible) => {
          if (visible) {
            appInsights.trackEvent({
              name: 'CALL_RECORDING_TIMELINE_SPEAKER_TURN_TOOLTIP_OPEN',
              properties: {
                meetingPlanId,
                recordingTimestamp: t.startTime,
              },
            });
          }
        }}
        calloutProps={{
          backgroundColor: t.speaker
            ? getColorForSpeaker(t.speaker)
            : undefined,
          calloutMaxWidth: 450,
        }}
        content={
          <>
            <Text
              block
              style={{
                fontWeight: FontWeights.semibold,
                fontSize: FontSizes.medium,
                color: MEETINGFLOW_COLORS.white,
                marginBottom: '.5rem',
                textShadow: '1px 1px 2px rgba(0, 0, 0, .25)',
              }}
            >
              {t.speaker} spoke for {formatSeconds(t.endTime - t.startTime)}
            </Text>
            <Text
              style={{
                fontSize: FontSizes.small,
                color: MEETINGFLOW_COLORS.white,
                textShadow: '1px 1px 2px rgba(0, 0, 0, .25)',
              }}
              block
            >
              {t.text}
            </Text>
          </>
        }
        tooltipProps={{
          styles: {
            root: {
              padding: 0,
            },
            content: {
              borderRadius: '.25rem',
              padding: '.5rem',
            },
          },
        }}
      >
        <div
          key={`${t.speaker}-${t.startTime}`}
          className="speaker-turn"
          title={`${t.speaker} spoke for ${formatSeconds(
            t.endTime - t.startTime,
          )}`}
        />
      </TooltipHost>
    </div>
  ));
};

export default CallRecordingTimeline;
