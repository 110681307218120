import { mergeStyleSets } from '@fluentui/react';
import type { IStyle } from '@fluentui/react';
import { CSS } from '@dnd-kit/utilities';

interface ISortableItemStyles {
  root: IStyle;
}

export const getSortableItemStyles = (
  transform: ReturnType<typeof CSS.Transform.toString>,
  transition: string | undefined,
  isDragging: boolean,
) =>
  mergeStyleSets<ISortableItemStyles>({
    root: {
      transform,
      transition,
      zIndex: isDragging ? 1000 : 'auto',
      position: isDragging ? 'relative' : 'static',
      opacity: isDragging ? 0.8 : 1,
      boxShadow: isDragging ? '0 8px 16px rgba(0,0,0,0.2)' : 'none',
      touchAction: isDragging ? 'none' : 'manipulation',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      WebkitTouchCallout: 'none',
      selectors: {
        '@media (hover: none)': {
          touchAction: 'manipulation',
        },
      },
    },
  });
