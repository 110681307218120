import React, { useState, useCallback } from 'react';
import { mergeStyles, Modal, Stack, Text, IconButton } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import {
  DealRoomCommandBarButton,
  DealRoomPrimaryButton,
} from '../Components/DealRoomButton';
import { useWelcomeArtifact } from '../../../Hooks/useWelcomeArtifact';

export type WelcomeVideoDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  onDimiss: () => void;
};

export const WelcomeVideoDialog = ({
  organizationSlug,
  dealRoomId,
  onDimiss,
}: WelcomeVideoDialogProps) => {
  const [showModal, setShowModal] = useState(true);
  const handleSetShowModal = useCallback(
    (value: boolean) => {
      setShowModal(value);
      if (!value && onDimiss) {
        onDimiss();
      }
    },
    [onDimiss],
  );

  const modalStyle = mergeStyles({
    '.ms-Dialog-main': {
      minHeight: 'auto',
      minWidth: 'auto',
      height: 'fit-content',
      width: 'calc(100% - 4rem)',
      maxWidth: '910px',
      maxHeight: 'calc(100% - 4rem)',
      boxShadow: '0px 2px 2px 0px #00000005',
      borderRadius: '4px',
      backgroundColor: DEALROOMS_COLORS.neutralGray,
    },
    '.modal-content': {
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '.close-button': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '1rem',
    },
  });

  const { welcomeVideo } = useWelcomeArtifact({
    organizationSlug,
    dealRoomId,
  });

  if (!welcomeVideo) {
    return null;
  }

  return (
    <Modal
      className={modalStyle}
      isOpen={showModal}
      onDismiss={() => handleSetShowModal(false)}
    >
      <Stack>
        <div className="close-button">
          <IconButton
            ariaLabel="Close"
            iconProps={{ iconName: 'Cancel' }}
            onClick={() => handleSetShowModal(false)}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            gap: '1rem',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 2rem',
          }}
        >
          <Text variant="xxLarge" style={{ fontWeight: 500 }}>
            {welcomeVideo.label || ''}
          </Text>
          <Text
            variant="medium"
            style={{ color: DEALROOMS_COLORS.neutralSecondary }}
          >
            {welcomeVideo.description || ''}
          </Text>
        </div>
        <div className="modal-content">
          <video
            controls
            src={welcomeVideo.fileUrl}
            style={{ maxWidth: '100%' }}
          >
            Your browser does not support the video tag.
          </video>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0rem 2rem 1rem 2rem',
          }}
        >
          <DealRoomCommandBarButton
            buttonStyleType="COMMAND_BAR"
            text={"I'll watch this later"}
            onClick={() => handleSetShowModal(false)}
          />

          <DealRoomPrimaryButton
            text="Done"
            onClick={() => handleSetShowModal(false)}
          />
        </div>
      </Stack>
    </Modal>
  );
};
