import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';

export const getArtifactLink = (
    organizationSlug: string,
    dealRoomId: number,
    artifact: DealRoomArtifact,
) => {
    switch (artifact.type) {
        case 'MEETINGFLOW':
            return `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${artifact.meetingflowId}`;
        default:
            return `/organization/${organizationSlug}/decisionsite/${dealRoomId}/artifact/${artifact.id}`;
    }
};