import React from 'react';
import { AssignmentInd, StarRounded } from '@mui/icons-material';
import { DSTooltip } from '../../DealRoom/DS/DSTooltip';
import { 
  MeetingflowIconContainer, 
  MeetingflowIcon,
  AttendeesAvatar,
  AttendeesBadge,
  AttendeesList,
  AttendeesListSubheader,
  AttendeesListItem,
  AttendeesTypography,
  StyledAvatarGroup
} from '../DecisionSiteMeetingflowCard.styles';
import { DetailedMeetingflow, Company, Contact } from '@meetingflow/common/Api/data-contracts';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { Avatar, Box, Typography, SxProps, Theme } from '@mui/material';

interface Props {
  meetingflow: DetailedMeetingflow;
  hideAttendeesIcon?: boolean;
  sortedAttendeesByDomain: Record<string, Contact[]>;
  companies?: Company[];
  withDealRoomContacts?: Contact[];
  mfIconSx: SxProps<Theme>;
  findCompanyByDomain: (companies: Company[] | undefined, domain: string) => Company | undefined;
}

export const DecisionSiteMeetingflowCardAttendeesIcon: React.FC<Props> = ({ 
  meetingflow,
  hideAttendeesIcon,
  sortedAttendeesByDomain,
  companies,
  withDealRoomContacts,
  mfIconSx,
  findCompanyByDomain
}) => {
  if (!meetingflow || !sortedAttendeesByDomain || hideAttendeesIcon) {
    return null;
  }

  return (
    <DSTooltip
      title={
        <>
          {Object.keys(sortedAttendeesByDomain).map((domain) => (
            <div key={domain}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    fontWeight: 600,
                    color: DEALROOMS_COLORS.white,
                  }}
                >
                  {(() => {
                    const matchingCompany = findCompanyByDomain(
                      companies,
                      domain,
                    );
                    return matchingCompany ? (
                      <>
                        <AttendeesAvatar
                          src={matchingCompany.logo || undefined}
                          variant="rounded"
                        >
                          {matchingCompany.name?.[0] || domain[0]}
                        </AttendeesAvatar>
                        <span>{matchingCompany.name || domain}</span>
                      </>
                    ) : domain === 'internal' ? (
                      'Internal'
                    ) : (
                      domain
                    );
                  })()}
                  <AttendeesBadge
                    badgeContent={sortedAttendeesByDomain[domain].length}
                    color="primary"
                  />
                </Typography>
              </Box>
              <AttendeesList
                dense
                subheader={<AttendeesListSubheader disableSticky />}
              >
                {sortedAttendeesByDomain[domain].map((person) => (
                  <AttendeesListItem key={person.id}>
                    <AttendeesTypography variant="body2">
                      {withDealRoomContacts?.some(
                        (contact) => contact.email === person.email,
                      ) && (
                        <StarRounded
                          sx={{
                            position: 'absolute',
                            left: '-.75rem',
                            fontSize: '1rem',
                            color: DEALROOMS_COLORS.white,
                          }}
                        />
                      )}
                      {person.name || person.email}
                    </AttendeesTypography>
                  </AttendeesListItem>
                ))}
              </AttendeesList>
            </div>
          ))}
        </>
      }
    >
      <MeetingflowIconContainer>
        {withDealRoomContacts && withDealRoomContacts.length > 0 && (
          <StyledAvatarGroup max={3}>
            {withDealRoomContacts.map((contact) => (
              <Avatar
                key={contact.id}
                src={contact.avatarUrl || undefined}
                alt={contact.name || contact.email}
                sx={{ bgcolor: DEALROOMS_COLORS.peacock }}
              >
                {contact.name?.charAt(0)}
              </Avatar>
            ))}
          </StyledAvatarGroup>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            padding: withDealRoomContacts && withDealRoomContacts.length > 0 ? '4px' : 0,
          }}
        >
          <MeetingflowIcon
            sx={{
              ...mfIconSx,
              ...(withDealRoomContacts && withDealRoomContacts.length > 0
                ? {
                    color: DEALROOMS_COLORS.white,
                  }
                : {}),
            }}
          >
            <AssignmentInd />
          </MeetingflowIcon>
        </Box>
      </MeetingflowIconContainer>
    </DSTooltip>
  );
};
