/**
 * DecisionSiteRecordingStatusBar is a component that displays the current state of
 * meeting recording functionality and provides recording controls. It handles various
 * meeting states and provides appropriate visual feedback and actions.
 *
 * Key Features:
 * - Real-time recording status updates
 * - Dynamic status messages and icons
 * - Recording timeline tracking
 * - Meeting state awareness
 * - Recording controls integration
 * - Comprehensive error handling
 * - Support for multiple meeting types
 * - AI analysis status tracking
 */

import { FC, useState } from 'react';
import {
  Call,
  CheckCircle,
  Delete,
  HourglassEmpty,
  Videocam,
  VideocamOff,
} from '@mui/icons-material';
import { CallRecording } from '@meetingflow/common/Api/data-contracts';
import { DecisionSiteMeetingflowRecordButton } from './DecisionSiteMeetingflowRecordButton';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useMeetingflow } from './hooks/useMeetingflow';
import {
  RecordingStatusBarWrapper,
  StatusDisplay,
} from './DecisionSiteMeetingPanel.styles';

/**
 * Props interface for DecisionSiteRecordingStatusBar
 * @property hasRecording - Flag indicating if meeting has an existing recording
 * @property isVideoMeeting - Flag for video conference meetings (Zoom/Teams)
 * @property isInFuture - Flag for meetings scheduled in the future
 * @property isInPast - Flag for completed meetings
 * @property isCurrentlyHappening - Flag for active meetings
 * @property recorderStatus - Current status of recording process
 * @property callRecording - Detailed recording information and metadata
 * @property meetingflowId - Unique identifier for the meeting
 * @property isAssociatedWithEvent - Flag for calendar-linked meetings
 * @property refetchMeetingflow - Callback to refresh meeting data
 */
interface DecisionSiteRecordingStatusBarProps {
  hasRecording: boolean;
  recorderStatus: string | null;
  callRecording?: CallRecording | null;
  meetingflowId: string;
  refetchMeetingflow?: () => Promise<unknown>;
}

/**
 * Status bar component for meeting recordings
 * Provides:
 * - Visual status indicators
 * - Recording timeline information
 * - Recording controls when applicable
 * - AI analysis progress tracking
 */
export const DecisionSiteRecordingStatusBar: FC<
  DecisionSiteRecordingStatusBarProps
> = ({
  hasRecording,
  recorderStatus,
  callRecording,
  meetingflowId,
  refetchMeetingflow,
}) => {
  const { slug: organizationSlug } = useOrganization();

  const { isVideoMeeting, isCurrentlyHappening, isInFuture, isInPast } =
    useMeetingflow(meetingflowId);

  /**
   * Terminal states where recording is no longer possible
   * When in these states, the record button will be hidden
   */
  const terminalStates = [
    'done', // Recording is complete and processed
    'deleted', // Recording was deleted
    'fatal', // Recording encountered an error
    'analysis_done', // Recording and analysis are complete
    'completed', // Recording is complete
    'media_expired', // Recording media is no longer available
  ];

  /**
   * Record Button States based on recorderStatus:
   * - scheduled: Cancel button (meeting is scheduled to record)
   * - ready: Cancel button (recorder is ready to join)
   * - joining_call: Cancel button (recorder is joining)
   * - in_waiting_room: Cancel button (recorder is waiting)
   * - in_call_not_recording: Start button (recorder is in call but not recording)
   * - in_call_recording: Stop button (actively recording)
   * - call_ended: Hidden (terminal state)
   * - done/analysis_done: Hidden (terminal state)
   * - fatal/deleted: Hidden (terminal state)
   * - null or undefined: Start button (no recording status)
   *
   * Button Visibility Rules:
   * - Always visible UNLESS:
   *   1. Meeting is in the past (isInPast), OR
   *   2. Meeting is in a terminal state (isTerminalState)
   */
  const isTerminalState = terminalStates.includes(recorderStatus || '');

  /**
   * Handles recording status changes
   * Triggers a data refresh to ensure UI reflects current state
   */
  const handleStatusChange = () => {
    refetchMeetingflow?.();
  };

  /**
   * Generates a formatted timeline of recording events
   * Includes:
   * - Recording start time
   * - Recording end time
   * - AI analysis completion time
   * @returns Formatted string of recording timeline details
   */
  const getRecordingDetails = () => {
    if (!callRecording) return '';

    const details = [];
    if (callRecording.callJoinedAt) {
      details.push(
        `Recording started at ${new Date(callRecording.callJoinedAt).toLocaleTimeString()}.`,
      );
    }
    if (callRecording.callLeftAt) {
      details.push(
        `ended at ${new Date(callRecording.callLeftAt).toLocaleTimeString()}.`,
      );
    }
    if (callRecording.analysisCompletedAt) {
      details.push(
        `AI analysis completed at ${new Date(callRecording.analysisCompletedAt).toLocaleTimeString()}.`,
      );
    }
    return details.length > 0 ? ` (${details.join(', ')})` : '';
  };

  /**
   * Determines the appropriate status display content
   * Handles multiple states:
   * - Non-video meetings
   * - Future meetings
   * - Past meetings
   * - Active meetings
   * - Recording states
   * - Error conditions
   *
   * @returns Object containing icon and text for current status
   */
  const getStatusContent = () => {
    const currentStatus = recorderStatus;

    // Handle non-video meetings
    if (!isVideoMeeting && !callRecording) {
      return {
        icon: <VideocamOff />,
        text: isInPast ? (
          <>This meeting wasn't associated with a conference call</>
        ) : (
          <>
            This meeting isn't associated with a conference call <br />
            You can still record it if you have a video conference URL.
          </>
        ),
      };
    }

    // Handle past meetings without recordings
    if (isInPast && !callRecording) {
      return {
        icon: <VideocamOff />,
        text: (
          <>
            This meeting's call has ended <br />
            It can no longer be recorded
          </>
        ),
      };
    }

    // Handle in-progress meetings without recordings
    if (!callRecording && !isInFuture && !isInPast) {
      return {
        icon: <VideocamOff />,
        text: (
          <>
            This meeting's call is in progress <br />
            It is not being recorded
          </>
        ),
      };
    }

    // Check recorder status first, regardless of callRecording existence
    if (currentStatus === 'scheduled') {
      return {
        icon: <HourglassEmpty />,
        text: (
          <>
            {`This meeting's call is scheduled to be recorded`}
            <br />
            {getRecordingDetails()}
          </>
        ),
      };
    }

    // Handle active recordings with various states
    if (hasRecording && callRecording) {
      switch (currentStatus) {
        case 'ready':
          return {
            icon: <Call />,
            text: (
              <>
                {`Meeting Note Taker is ready to join the call`}
                <br />
                {getRecordingDetails()}
              </>
            ),
          };
        case 'joining_call':
          return {
            icon: <Call />,
            text: (
              <>
                {`Meeting Note Taker is joining the call`}
                <br />
                {getRecordingDetails()}
              </>
            ),
          };
        case 'in_waiting_room':
          return {
            icon: <HourglassEmpty />,
            text: (
              <>
                {`Meeting Note Taker is in the waiting room`}
                <br />
                {getRecordingDetails()}
              </>
            ),
          };
        case 'in_call_not_recording':
          return {
            icon: <VideocamOff />,
            text: (
              <>
                {`Meeting Note Taker is in the call but is not yet recording`}
                <br />
                {getRecordingDetails()}
              </>
            ),
          };
        case 'in_call_recording':
          return {
            icon: <Videocam />,
            text: (
              <>
                {`Meeting Note Taker is recording`}
                <br />
                {getRecordingDetails()}
              </>
            ),
          };
        case 'call_ended':
          return {
            icon: <CheckCircle />,
            text: (
              <>
                {`The call has ended and the recording will be available soon`}
                <br />
                {getRecordingDetails()}
              </>
            ),
          };
        case 'done':
        case 'analysis_done':
          if (!callRecording.analysisCompletedAt) {
            return {
              icon: <CheckCircle />,
              text: (
                <>
                  Meeting Note Taker finished recording. <br />
                  Analyzing transcript{getRecordingDetails()}
                </>
              ),
            };
          } else {
            return {
              icon: <CheckCircle />,
              text: (
                <>
                  {`Recording and analysis complete`}
                  <br />
                  {getRecordingDetails()}
                </>
              ),
            };
          }
        case 'media_expired':
          return {
            icon: <CheckCircle />,
            text: (
              <>
                {getRecordingDetails()}
              </>
            ),
          };
        case 'fatal':
          return {
            icon: <Delete />,
            text: (
              <>
                Meeting Note Taker encountered an error recording the meeting.
              </>
            ),
          };
        case 'deleted':
          return {
            icon: <Delete />,
            text: <>A call recording for this meeting was deleted.</>,
          };
        default:
          return {
            icon: <Videocam />,
            text: (
              <>
                {`Recording is available`}
                <br />
                {getRecordingDetails()}
              </>
            ),
          };
      }
    }

    // Handle future meeting states
    if (isInFuture) {
      return {
        icon: <VideocamOff />,
        text: isVideoMeeting ? (
          <>This meeting is not set to record.</>
        ) : (
          <>This is not a video meeting and cannot be recorded.</>
        ),
      };
    }

    // Handle currently active meetings
    if (isCurrentlyHappening) {
      return {
        icon: <Videocam />,
        text:
          recorderStatus === 'in_call_recording' ? (
            <>This meeting is currently being recorded.</>
          ) : (
            <>This meeting is currently happening and can be recorded.</>
          ),
      };
    }

    // Default state for non-recordable meetings
    return {
      icon: <VideocamOff />,
      text: (
        <>
          {isVideoMeeting
            ? 'This meeting was not recorded'
            : 'This was not a video meeting'}{' '}
          <br />
          {!isVideoMeeting && 'It could not be recorded'}
        </>
      ),
    };
  };

  const { icon, text } = getStatusContent();

  return (
    <RecordingStatusBarWrapper>
      {/* Status display with icon and message */}
      <StatusDisplay>
        {icon}
        <span>{text}</span>
      </StatusDisplay>

      {/* Recording controls - Only hidden for past meetings and terminal states */}
      <DecisionSiteMeetingflowRecordButton
        meetingflowId={meetingflowId}
        onStatusChange={handleStatusChange}
      />
    </RecordingStatusBarWrapper>
  );
};
