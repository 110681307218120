/**
 * Custom hook for managing real-time meeting transcripts with adaptive caching.
 *
 * Key features:
 * - Dynamic caching strategy based on recording state
 * - Real-time updates during active recordings (3s polling)
 * - Optimized caching for completed recordings (15min)
 * - Entitlement-based access control
 * - Fallback handling for offline/error states
 */

import { useQuery, QueryObserverResult } from 'react-query';
import { MeetingPlanCallTranscriptQuery } from '../../../../../QueryNames';
import { MeetingflowsApiClient } from '../../../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import { Duration } from 'luxon';
import { Utterance } from '@meetingflow/common/Api/data-contracts';
import { EMPTY_ARRAY } from '../../../../../Constants';
import axios, { AxiosResponse, AxiosError } from 'axios';

/**
 * Props for the useMeetingflowTranscript hook
 * @property meetingPlanId - Unique identifier for the meeting session
 * @property recorderStatus - Current recording state, affects caching strategy
 *                           'in_call_recording' enables real-time updates
 * @property meetingPlanTranscript - Optional cached transcript data for fallback
 */
interface UseMeetingflowTranscriptProps {
  meetingPlanId: string | undefined;
  recorderStatus: string | null;
  meetingPlanTranscript?: Utterance[] | null;
}

export const useMeetingflowTranscript = ({
  meetingPlanId,
  recorderStatus,
  meetingPlanTranscript,
}: UseMeetingflowTranscriptProps): {
  transcript: Utterance[];
  isLoading: boolean;
  refetch: () => Promise<QueryObserverResult<Utterance[], Error>>;
} => {
  const { getAccessTokenSilently } = useAuth0();
  const { organization, hasEntitlement } = useOrganization();

  /**
   * Fetches and manages transcript data with adaptive caching strategy.
   *
   * Active Recording Mode (recorderStatus === 'in_call_recording'):
   * - Stale time: 3 seconds
   * - Cache time: 1 second
   * - Auto-refresh: Enabled
   * - Polling interval: Every 3 seconds
   *
   * Completed Recording Mode:
   * - Stale/Cache time: 15 minutes
   * - Auto-refresh: Disabled
   * - Polling: Disabled
   *
   * Security:
   * - Requires valid organization
   * - Checks CALL_RECORDING entitlement
   * - Uses Auth0 token for API calls
   */
  const {
    data: callTranscript,
    isLoading,
    refetch,
  } = useQuery<Utterance[], Error>(
    organization?.slug && meetingPlanId
      ? [MeetingPlanCallTranscriptQuery(organization.slug, meetingPlanId)]
      : ['transcript', null],
    async () => {
      if (!organization?.slug || !meetingPlanId) {
        throw new Error('Missing required parameters');
      }
      const token = await getAccessTokenSilently();
      const response: AxiosResponse<Utterance[]> =
        await MeetingflowsApiClient.getCallTranscript(
          organization.slug,
          meetingPlanId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      return response.data;
    },
    {
      // Adaptive caching configuration
      staleTime:
        recorderStatus === 'in_call_recording'
          ? Duration.fromObject({ seconds: 3 }).as('milliseconds')
          : Duration.fromObject({ minutes: 15 }).as('milliseconds'),
      cacheTime:
        recorderStatus === 'in_call_recording'
          ? Duration.fromObject({ seconds: 1 }).as('milliseconds')
          : Duration.fromObject({ minutes: 15 }).as('milliseconds'),

      // Real-time update configuration
      refetchOnWindowFocus: recorderStatus === 'in_call_recording',
      refetchOnMount: recorderStatus === 'in_call_recording',
      refetchOnReconnect: recorderStatus === 'in_call_recording',

      // Polling configuration for active recordings
      refetchInterval: () => {
        if (recorderStatus !== 'in_call_recording') {
          return false;
        }
        return Duration.fromObject({ seconds: 3 }).as('milliseconds');
      },

      // Security and access control
      enabled:
        !!organization?.slug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        // Only enable if we have a transcript or are currently recording
        (!!meetingPlanTranscript || recorderStatus === 'in_call_recording'),

      // Retry configuration
      retry: (failureCount: number, error: Error) => {
        // Don't retry 404s since they indicate no transcript exists
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          return false;
        }
        // Retry other errors up to 3 times
        return failureCount < 3;
      },
    },
  );

  return {
    transcript: callTranscript || meetingPlanTranscript || EMPTY_ARRAY,
    isLoading,
    refetch,
  };
};
