import React from 'react';
import { DealRoomArtifact, UserActivity, UserActivityType } from '@meetingflow/common/Api/data-contracts';
import { DSTooltip } from '../DS';
import {
    Upload,
    Visibility,
    Share,
    Star,
    StarOutline,
    ThumbUp,
    ThumbDown,
    Delete,
    Restore,
    Event,
    Edit,
    Assignment,
    CheckCircle,
    PersonAdd,
    PersonRemove,
    Comment,
    Create
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { ArtifactLink, HighlightedText } from './DSAnalyticsActivityTable.styles';

interface ActivityRendererProps {
    activity: UserActivity;
    dealRoomId?: string;
    allArtifacts?: DealRoomArtifact[];
}

interface ActivityRenderer {
    renderer: (props: ActivityRendererProps) => React.ReactNode;
}

const renderActivityIcon = (icon: React.ReactElement) => (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'action.active',
        mr: 1
    }}>
        {icon}
    </Box>
);

const renderActivityLayout = (icon: React.ReactElement, text: React.ReactNode) => (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        {icon}
        <Box component="span">
            {text}
        </Box>
    </Box>
);

const renderArtifactLink = ({
    activity,
    dealRoomId,
    allArtifacts,
}: ActivityRendererProps) => {
    const artifactId = activity.additionalDetails?.artifactId;
    if (!artifactId) return null;

    const artifact = allArtifacts?.find(
        (a) => a.id === artifactId,
    );

    if (!artifact) {
        let label = "a deleted artifact";
        if (activity.additionalDetails?.name) {
            label = label + " - " + activity.additionalDetails?.name
        }
        return (
            (<>{label}</>)
        );
    }

    if (activity.additionalDetails.artifactType === 'MEETINGFLOW') {
        return (
            <ArtifactLink
                to={`/organization/augment-ai/decisionsite/${dealRoomId}/journey?meeting=${activity.additionalDetails.meetingflowId}`}
            >
                {artifact.name || 'Untitled'}
            </ArtifactLink>
        );
    }

    return (
        <ArtifactLink
            to={`/organization/augment-ai/decisionsite/${dealRoomId}/artifact/${artifact.id}`}
        >
            {artifact.name || 'Untitled'}
        </ArtifactLink>
    );
};

const renderMeetingflowActivityIcon = () => renderActivityIcon(<Event fontSize="small" />);

export const ANALYTICS_TYPES: Partial<Record<UserActivityType, ActivityRenderer>> = {
    UPLOAD_ARTIFACT: {
        renderer: ({ activity, dealRoomId, allArtifacts }: ActivityRendererProps) => {
            const isMeetingflow = activity.additionalDetails?.artifactType === 'MEETINGFLOW';
            const icon = isMeetingflow ? renderMeetingflowActivityIcon() : renderActivityIcon(<Upload fontSize="small" />);
            return renderActivityLayout(
                icon,
                <>Uploaded {renderArtifactLink({ activity, dealRoomId, allArtifacts })}</>
            );
        },
    },
    VIEW_ARTIFACT: {
        renderer: ({ activity, dealRoomId, allArtifacts }: ActivityRendererProps) => {
            const isMeetingflow = activity.additionalDetails?.artifactType === 'MEETINGFLOW';
            const icon = isMeetingflow ? renderMeetingflowActivityIcon() : renderActivityIcon(<Visibility fontSize="small" />);
            return renderActivityLayout(
                icon,
                <>Viewed {renderArtifactLink({ activity, dealRoomId, allArtifacts })}</>
            );
        },
    },
    FEATURE_ARTIFACT: {
        renderer: ({ activity, dealRoomId, allArtifacts }: ActivityRendererProps) => {
            const isMeetingflow = activity.additionalDetails?.artifactType === 'MEETINGFLOW';
            const icon = isMeetingflow ? renderMeetingflowActivityIcon() : renderActivityIcon(<Star fontSize="small" />);
            return renderActivityLayout(
                icon,
                <>Featured {renderArtifactLink({ activity, dealRoomId, allArtifacts })}</>
            );
        },
    },
    UNFEATURE_ARTIFACT: {
        renderer: ({ activity, dealRoomId, allArtifacts }: ActivityRendererProps) => {
            const isMeetingflow = activity.additionalDetails?.artifactType === 'MEETINGFLOW';
            const icon = isMeetingflow ? renderMeetingflowActivityIcon() : renderActivityIcon(<StarOutline fontSize="small" />);
            return renderActivityLayout(
                icon,
                <>Unfeatured {renderArtifactLink({ activity, dealRoomId, allArtifacts })}</>
            );
        },
    },
    UPVOTE_ARTIFACT: {
        renderer: ({ activity, dealRoomId, allArtifacts }: ActivityRendererProps) => {
            const isMeetingflow = activity.additionalDetails?.artifactType === 'MEETINGFLOW';
            const icon = isMeetingflow ? renderMeetingflowActivityIcon() : renderActivityIcon(<ThumbUp fontSize="small" />);
            return renderActivityLayout(
                icon,
                <>Upvoted {renderArtifactLink({ activity, dealRoomId, allArtifacts })}</>
            );
        },
    },
    DOWNVOTE_ARTIFACT: {
        renderer: ({ activity, dealRoomId, allArtifacts }: ActivityRendererProps) => {
            const isMeetingflow = activity.additionalDetails?.artifactType === 'MEETINGFLOW';
            const icon = isMeetingflow ? renderMeetingflowActivityIcon() : renderActivityIcon(<ThumbDown fontSize="small" />);
            return renderActivityLayout(
                icon,
                <>Downvoted {renderArtifactLink({ activity, dealRoomId, allArtifacts })}</>
            );
        },
    },
    DELETE_ARTIFACT: {
        renderer: ({ activity }: ActivityRendererProps) => {
            const isSoftDelete = activity.additionalDetails?.soft;
            let label = "Permanently trashed an artifact";
            if (isSoftDelete) {
                label = "Trashed an artifact"
            }

            if (activity.additionalDetails?.name) {
                label = label + " - " + activity.additionalDetails?.name
            }
            return renderActivityLayout(
                renderActivityIcon(<Delete fontSize="small" />),
                <>{label}</>
            );
        },
    },
    UNDELETE_ARTIFACT: {
        renderer: ({ activity, dealRoomId, allArtifacts }: ActivityRendererProps) => {
            const isMeetingflow = activity.additionalDetails?.artifactType === 'MEETINGFLOW';
            const icon = isMeetingflow ? renderMeetingflowActivityIcon() : renderActivityIcon(<Restore fontSize="small" />);
            return renderActivityLayout(
                icon,
                <>Restored {renderArtifactLink({ activity, dealRoomId, allArtifacts })}</>
            );
        },
    },
    CREATE_DEAL_ROOM: {
        renderer: () => renderActivityLayout(
            renderActivityIcon(<Create fontSize="small" />),
            <>Created this Decision Site</>
        ),
    },
    ADD_DEAL_ROOM_CONTACT: {
        renderer: ({ activity }) => {
            // needed to check both fields for backwards compatability
            const email = activity.additionalDetails?.dealRoomEmail || activity.additionalDetails?.contactEmail; 

            return renderActivityLayout(
                renderActivityIcon(<PersonAdd fontSize="small" />),
                <>Shared this Decision Site with <HighlightedText>{email}</HighlightedText></>
            )
        },
    },
    UPDATE_DEAL_ROOM_CONTACT: {
        renderer: ({ activity }) => {
            // Convert the changes object into a readable format
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const readableChanges = (obj: Record<string, (any)>): string => {
                if (!obj) return '';
                
                return Object.entries(obj)
                    .filter(([key, value]) => value !== undefined)
                    .map(([key, value]) => {
                        switch(key) {
                            case 'communicationPreference': return `preferred communication method to ${value}`;
                            case 'consumptionPreference': return `content format preference to ${value}`;
                            case 'assistancePreference': return `assistance level to ${value}`;
                            case 'phoneNumber': return `phone number to ${value}`;
                            case 'priorities': return `priorities to ${Array.isArray(value) ? value.join(', ') : value}`;
                            case 'role': return `role to ${value}`;
                            case 'hasWatchedWelcomeVideo': return value ? 'completed watching welcome video' : 'unwatched welcome video';
                            case 'hasCompletedBuyerOrientation': return value ? 'completed buyer orientation' : 'incomplete buyer orientation';
                            case 'fields': return readableChanges(value); // needed for now for backwards compatability
                            default: return undefined;
                        }
                    })
                    .filter(Boolean)
                    .join(', ');
            };

            // needed to check both fields for backwards compatability
            const email = activity.additionalDetails?.dealRoomEmail || activity.additionalDetails?.contactEmail; 

            return renderActivityLayout(
                renderActivityIcon(<Assignment fontSize="small" />),
                <>Updated <HighlightedText>{email}</HighlightedText> - { readableChanges(activity.additionalDetails) }</>
            )
        },
    },
    REMOVE_DEAL_ROOM_CONTACT: {
        renderer: ({ activity }) => {
            // needed to check both fields for backwards compatability
            const email = activity.additionalDetails?.dealRoomEmail || activity.additionalDetails?.contactEmail; 
            
            return renderActivityLayout(
                renderActivityIcon(<PersonRemove fontSize="small" />),
                <>Unshared this Decision Site with <HighlightedText>{email}</HighlightedText></>
            )
        },
    },
};
