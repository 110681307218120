import { mergeStyleSets } from '@fluentui/react';
import { useMemo } from 'react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { ArtifactIllustration } from '../Components/Illustrations/ArtifactIllustration';
import { ActionItem } from '../../../types/MilestoneTypes';
import { useNavigate } from '../../../Hooks/useNavigate';
import { getArtifactLink } from '../utils/artifactUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';

type ActionItemAttachmentsListProps = {
  actionItem: ActionItem;
  excludeArtifactIds?: number[];
};

const numberOfAttachmentsToShow = 3;

export const ActionItemAttachmentsList = ({
  actionItem,
  excludeArtifactIds = [],
}: ActionItemAttachmentsListProps) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();

  const remainingFilesThatAreNotShow = useMemo(() => {
    if (!actionItem) return 0;
    if (!actionItem.artifacts?.length) return 0;
    return Math.max(0, actionItem.artifacts.length - numberOfAttachmentsToShow);
  }, [actionItem]);

  const styles = mergeStyleSets({
    filesContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '.5rem',
    },
    fileContainer: {
      display: 'flex',
      alignItems: 'center',
      transition: '.3s ease-in-out all',
      // backgroundColor: DEALROOMS_COLORS.inputLightGray,
      borderRadius: '.25rem',
      // gap: '.125rem',
      padding: '0 .25rem',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralLight,
      },
    },
    artifactName: {
      fontSize: '.75rem',
      lineHeight: '1.125rem',
      color: DEALROOMS_COLORS.cloudburst,
    },
  });

  if (!actionItem?.artifacts?.length) {
    return null;
  }

  return (
    <div className={styles.filesContainer}>
      {actionItem.artifacts
        .filter((artifact) => !excludeArtifactIds.includes(artifact.id))
        .slice(0, numberOfAttachmentsToShow)
        .map((artifact) => (
          <div
            key={artifact.id}
            className={styles.fileContainer}
            onClick={async () => {
              const token = await getAccessTokenSilently();
              const fullArtifact = await DealRoomsApiClient.getArtifact(
                organizationSlug!,
                dealRoomId!,
                artifact.id,
                {
                  headers: { Authorization: `Bearer ${token}` },
                },
              );
              navigate(
                getArtifactLink(
                  organizationSlug!,
                  dealRoomId!,
                  fullArtifact.data,
                ),
                { preserveQuery: true },
              );
            }}
          >
            <ArtifactIllustration />
            <span className={styles.artifactName}>{artifact.name}</span>
          </div>
        ))}
      {remainingFilesThatAreNotShow > 0 && (
        <div className={styles.fileContainer}>
          <span className={styles.artifactName}>
            +{remainingFilesThatAreNotShow}
          </span>
        </div>
      )}
    </div>
  );
};
