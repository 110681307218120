import React from 'react';
import { Stack, TextField, Toggle, IconButton } from '@fluentui/react';
import { getKeyFactStyles } from './DSConfigurationKeyFact.styles';
import { DeleteIconProps, DragIconProps } from '../../../../../utils/iconProps';

interface KeyFactData {
  id?: number;
  title: string;
  value: string;
  editableByBuyer: boolean;
  order: number;
}

interface DSConfigurationKeyFactProps {
  fact: KeyFactData;
  index: number;
  onDelete: (index: number) => void;
  onChange: (
    index: number,
    field: keyof KeyFactData,
    value: string | boolean | null,
  ) => void;
  dragHandleProps?: Record<string, unknown>;
  isDragging?: boolean;
}

export const DSConfigurationKeyFact: React.FC<DSConfigurationKeyFactProps> = ({
  fact,
  index,
  onDelete,
  onChange,
  dragHandleProps,
  isDragging,
}) => {
  const styles = getKeyFactStyles(isDragging);

  return (
    <Stack tokens={{ childrenGap: 10 }} className={styles.container}>
      {/* Card header and delete button */}
      <Stack horizontal horizontalAlign="space-between">
        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
          <IconButton
            {...dragHandleProps}
            iconProps={DragIconProps}
            className={styles.dragHandle}
            title="Touch and hold to reorder"
          />
          <h3 style={{ margin: 0 }}>Fact {index + 1}</h3>
        </Stack>
        <IconButton
          iconProps={DeleteIconProps}
          onClick={() => onDelete(index)}
          ariaLabel="Delete fact"
        />
      </Stack>

      <TextField
        label="Title"
        required
        value={fact.title}
        onChange={(_, value) => onChange(index, 'title', value || '')}
      />

      <TextField
        label="Value"
        required
        multiline
        rows={3}
        value={fact.value}
        onChange={(_, value) => onChange(index, 'value', value || '')}
      />

      <Toggle
        label="Editable By Buyer"
        checked={fact.editableByBuyer}
        onChange={(_, checked) =>
          onChange(index, 'editableByBuyer', checked || false)
        }
      />
    </Stack>
  );
};
