import { Icon, mergeStyles } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import classNames from 'classnames';

type BuyerOrientationSurveyStepIndicatorProps = {
  step: number;
  numberOfSteps: number;
};

export const BuyerOrientationSurveyStepIndicator = ({
  step,
  numberOfSteps,
}: BuyerOrientationSurveyStepIndicatorProps) => {
  const stepsIndicatorStyles = mergeStyles({
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1.5rem',
    '.indicator': {
      position: 'absolute',
      top: '50%',
      left: '0',
      transform: 'translate(0, -50%)',
      height: '1px',
      backgroundColor: DEALROOMS_COLORS.neutralLight,
      width: `100%`,
    },

    '.step-container': {
      boxSizing: 'border-box',
      width: '2rem',
      height: '2rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      backgroundColor: DEALROOMS_COLORS.neutralLight,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.625rem',
      lineHeight: '0.625rem',
      fontWeight: '400',
      color: DEALROOMS_COLORS.themeSecondary,
      zIndex: '1',
    },

    '.active-step': {
      border: `1px solid ${DEALROOMS_COLORS.userSurveyPrimary}`,
      backgroundColor: DEALROOMS_COLORS.white,
      color: DEALROOMS_COLORS.userSurveyPrimary,
    },

    '.completed-step': {
      backgroundColor: DEALROOMS_COLORS.userSurveyPrimary,
      i: {
        color: DEALROOMS_COLORS.white,
        fontSize: '0.625rem',
      },
    },
  });

  return (
    <div className={stepsIndicatorStyles}>
      <div className="indicator" />
      {new Array(numberOfSteps).fill(null).map((_, index) => (
        <div
          key={index}
          className={classNames(
            'step-container',
            {
              ['active-step']: index + 1 === step,
            },
            { ['completed-step']: index + 1 < step },
          )}
        >
          {index + 1 < step ? <Icon iconName="CheckMark" /> : index + 1}
        </div>
      ))}
    </div>
  );
};
