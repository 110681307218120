import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const useArtifactSidebarStyles = (
  isSidebarOpen: boolean,
  isMobile: boolean,
  isTablet: boolean,
) => {
  return mergeStyleSets({
    sidePanelDummyForMobile: {
      width: '1rem',
      height: '100%',
    },
    sidePanelContentWrapper: {
      backgroundColor: DEALROOMS_COLORS.financialLightGray,
      borderRight: `1px solid ${DEALROOMS_COLORS.artifactsSidePanelBorder}`,
      width: isSidebarOpen ? '16.5rem' : '1rem',
      minWidth: isSidebarOpen ? '16.5rem' : '1rem',
      willChange: 'width',
      transition: 'all 0.5s linear',
      padding: isSidebarOpen ? '2.1875rem 1rem' : '2.1875rem 0',
      display: 'flex',
      flexDirection: 'column',
      position: isMobile || isTablet ? 'absolute' : 'relative',
      gap: '1rem',
      boxSizing: 'border-box',
      height: isMobile || isTablet ? 'calc(100% - 4.7rem)' : '100%',
      zIndex: '89',
    },
    sidePanelContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      height: '100%',
      opacity: 0,
      width: '100%',
      alignItems: 'stretch',
      visibility: 'hidden',
    },
    sidePanelContentVisible: {
      opacity: '1 ',
      visibility: 'visible',
      transition: 'opacity .5s linear .45s',
    },
    resizer: {
      cursor: 'col-resize',
      width: '16px',
      height: '100%',
      position: 'absolute',
      right: '0',
      top: '0',
      display: isSidebarOpen ? 'block' : 'none',
    },
    expandButton: {
      position: 'absolute',
      zIndex: 1,
      width: '1rem',
      height: '1.5rem',
      borderColor: DEALROOMS_COLORS.artifactsSidePanelBorder,
      backgroundColor: DEALROOMS_COLORS.financialLightGray,
      border: `1px solid ${DEALROOMS_COLORS.artifactsSidePanelBorder}`,
      borderLeft: 'none',
      borderRadius: '0 .25rem .25rem 0',
      top: '2.625rem',
      left: 'calc(100% - 1px)',
      i: {
        color: DEALROOMS_COLORS.themeSecondary,
        fontSize: '.5rem',
        ...(isSidebarOpen ? { transform: 'rotate(180deg)' } : {}),
        transition: 'transform 0.5s linear',
      },
      ':hover': {
        backgroundColor: DEALROOMS_COLORS.financialLightGray,
      },
      ':active': {
        backgroundColor: DEALROOMS_COLORS.financialLightGray,
      },
    },
    addAttachment: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      svg: {
        width: '1.25rem',
        height: '1.25rem',
      },
      '.ms-Button-textContainer': {
        flexGrow: 0,
      },
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: DEALROOMS_COLORS.inputLightGray,
      borderRadius: '4px',
      padding: '0 .625rem 0 .625rem',
      gap: '2px',
    },
    searchButton: {
      width: '1.25rem',
      height: '1.25rem',
      cursor: 'initial',
      i: {
        fontSize: '.875rem',
        color: DEALROOMS_COLORS.userSurveyInputPlaceholder,
      },
      ':hover': {
        backgroundColor: 'transparent',
        i: {
          color: DEALROOMS_COLORS.userSurveyInputPlaceholder,
        },
      },
      ':active': {
        backgroundColor: 'transparent',
        i: {
          color: DEALROOMS_COLORS.userSurveyInputPlaceholder,
        },
      },
    },
    searchInput: {
      flex: '1',
      '.ms-TextField-fieldGroup': {
        height: 'auto',
        border: 'none',

        '::after': {
          border: 'none ',
          outline: `none `,
        },
      },
      input: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        fontSize: '.875rem',
        lineHeight: '2rem',
        padding: '0 .5rem 0 .2rem',
      },
    },
    attachmentListTitle: {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      color: DEALROOMS_COLORS.themePrimary,
      padding: '.5rem 0 0 1rem',
    },
    attachmentListContainer: {
      gap: '0.25rem',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      height: 'fit-content',
    },
    endLine: {
      width: '100%',
      height: '1px',
      backgroundColor: DEALROOMS_COLORS.artifactsSidePanelBorder,
    },
  });
};
