import {
  DetailsList,
  FontSizes,
  FontWeights,
  IColumn,
  mergeStyles,
  SelectionMode,
  Text,
} from '@fluentui/react';
import { OrganizationInviteItem } from '../OrganizationInviteItem';
import { titleCase } from '../../Helpers/Typography';
import { useQueryClient } from 'react-query';
import {
  InvitesQuery,
  OrganizationQuery,
  OrganizationsQuery,
} from '../../QueryNames';
import { useNavigate } from 'react-router';
import { EMPTY_ARRAY } from '../../Constants';
import { useCallback, useMemo } from 'react';
import {
  OrganizationInvite,
  OrganizationUserRole,
} from '@meetingflow/common/Api/data-contracts';
import StyledDateTime from '../StyledDateTime';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';

export type WelcomeInvitationsProps = {
  organizationSlug?: string;
  existingRole?: OrganizationUserRole;
  invites: OrganizationInvite[];
  refreshInvites: () => Promise<unknown>;
};
export const WelcomeInvitations = ({
  organizationSlug,
  existingRole,
  invites,
  refreshInvites,
}: WelcomeInvitationsProps) => {
  const client = useQueryClient();
  const navigate = useNavigate();

  const onAcceptInvite = useCallback(
    async (slug: string) => {
      await client.invalidateQueries(InvitesQuery());
      await client.invalidateQueries(InvitesQuery('RECEIVED'));
      await client.invalidateQueries(OrganizationsQuery);
      if (!organizationSlug) {
        navigate(`/organization/${slug}`);
      } else {
        await client.invalidateQueries(OrganizationQuery(organizationSlug));
      }
    },
    [client, navigate, organizationSlug],
  );

  const inviteColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'Invited By',
        minWidth: 100,
        fieldName: 'name',
        onRender: (invite: OrganizationInvite) => (
          <div>
            <span
              style={{
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.black,
              }}
            >
              {invite.inviter.name}
            </span>
            <span style={{ display: 'block' }}>{invite.inviter.email}</span>
          </div>
        ),
      },
      {
        key: 'workspace',
        name: 'Workspace',
        minWidth: 100,
        fieldName: 'organization.name',
        onRender: (invite: OrganizationInvite) => (
          <span
            style={{
              fontWeight: FontWeights.semibold,
              color: MEETINGFLOW_COLORS.black,
            }}
          >
            {invite.organization.name}
          </span>
        ),
      },
      {
        key: 'role',
        name: 'Role',
        minWidth: 100,
        fieldName: 'role',
        onRender: (invite: OrganizationInvite) => <>{titleCase(invite.role)}</>,
      },
      {
        key: 'createdAt',
        name: 'Created',
        minWidth: 180,
        fieldName: 'createdAt',
        onRender: (invite: OrganizationInvite) => (
          <StyledDateTime dateTime={invite.createdAt} />
        ),
      },
      {
        key: 'accept',
        name: 'Accept',
        minWidth: 100,
        headerClassName: 'right',
        onRender: (invite: OrganizationInvite) => (
          <div
            className="welcome-wizard-form"
            style={{ margin: 0, padding: 0 }}
          >
            <div
              className="controls"
              style={{ marginTop: 0, border: 0, paddingTop: 0 }}
            >
              <OrganizationInviteItem
                key={invite.id}
                invite={invite}
                onAcceptInvite={() => {
                  onAcceptInvite(invite.organization.slug);
                }}
                onDeclineInvite={() => {
                  if (organizationSlug) {
                    client.invalidateQueries(
                      OrganizationQuery(organizationSlug),
                    );
                  }

                  refreshInvites();
                }}
              />
            </div>
          </div>
        ),
      },
    ],
    [client, onAcceptInvite, organizationSlug, refreshInvites],
  );

  const firstInvite = invites && invites[0];

  const singleInviteClass = mergeStyles({
    textAlign: 'center',
    width: '75%',
    margin: '0 auto',
    padding: '.5rem',
    color: MEETINGFLOW_COLORS.black,
  });

  if (!invites.length) {
    return (
      <Text block style={{ fontStyle: 'italic' }}>
        You have no outstanding received invites.
      </Text>
    );
  }

  if (invites.length === 1) {
    if (existingRole) {
      return (
        <div className={singleInviteClass}>
          <Text
            variant="xxLarge"
            style={{
              fontWeight: FontWeights.regular,
            }}
          >
            <strong style={{ fontWeight: FontWeights.semibold }}>
              {firstInvite.inviter.name || firstInvite.inviter.email}
            </strong>{' '}
            invited you to become a{' '}
            <strong style={{ fontWeight: FontWeights.semibold }}>
              {firstInvite.role.toLowerCase()}
            </strong>{' '}
            in{' '}
            <strong style={{ fontWeight: FontWeights.semibold }}>
              {firstInvite.organization.name}
            </strong>
            .
            <br />
            <br />
            Would you like to accept or continue as{' '}
            <strong style={{ fontWeight: FontWeights.semibold }}>
              {existingRole.toLowerCase()}
            </strong>
            ?
          </Text>
          <OrganizationInviteItem
            key={firstInvite.id}
            invite={firstInvite}
            onAcceptInvite={() => onAcceptInvite(firstInvite.organization.slug)}
            iconsOnly={false}
            onDeclineInvite={() => {
              if (organizationSlug) {
                client.invalidateQueries(OrganizationQuery(organizationSlug));
              }
            }}
          />
        </div>
      );
    }

    return (
      <div className="welcome-wizard-form">
        <Text className={singleInviteClass}>
          <strong style={{ fontWeight: FontWeights.semibold }}>
            {firstInvite.inviter.name || firstInvite.inviter.email}
          </strong>{' '}
          invited you to join their workspace{' '}
          <strong style={{ fontWeight: FontWeights.semibold }}>
            {firstInvite.organization.name}
          </strong>
          . Would you like to accept?
        </Text>
        <div className="controls">
          <OrganizationInviteItem
            key={firstInvite.id}
            invite={firstInvite}
            onAcceptInvite={() => onAcceptInvite(firstInvite.organization.slug)}
            iconsOnly={false}
            onDeclineInvite={refreshInvites}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="welcome-wizard-form">
      <div>
        <DetailsList
          items={invites || EMPTY_ARRAY}
          columns={inviteColumns}
          selectionMode={SelectionMode.none}
          styles={{
            root: {
              backgroundColor: 'transparent',
              '.right .ms-DetailsHeader-cellTitle': {
                textAlign: 'right',
                justifyContent: 'flex-end',
              },

              '.ms-DetailsHeader , .ms-DetailsRow': {
                borderBottom: `.5px solid ${MEETINGFLOW_COLORS.purpleMediumDark} !important`,
                padding: 0,
              },

              '.ms-DetailsHeader , .ms-DetailsHeader-cell, .ms-DetailsRow, .ms-DetailsRow-cell':
                {
                  fontSize: FontSizes.medium,
                  height: 'auto',
                  backgroundColor: 'transparent !important',
                  color: `${MEETINGFLOW_COLORS.black} !important`,
                },
            },
          }}
        />
      </div>
    </div>
  );
};

export default WelcomeInvitations;
