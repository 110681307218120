import React, { useState } from 'react';

import { useQuery } from 'react-query';
import {
  FontWeights,
  mergeStyles,
  Persona,
  PersonaSize,
  PrimaryButton,
  TextField,
  ITag,
  Icon,
} from '@fluentui/react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';

import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { SendInviteIllustrations } from '../UserSettingsIllustrations';
import { DealRoomContactsQuery } from '../../../../../QueryNames';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import { useUserProfile } from '../../../../../Hooks/useProfile';
import useDeviceType from '../../../../../Hooks/deviceDetection';
import { RemoveCircle, RemoveModerator } from '@mui/icons-material';
import { UserRemoveIcon } from '@fluentui/react-icons-mdl2';
import { UserSettingsInviteForm } from './UserSettingsInviteForm';
import { UserSettingsMembersTable } from './UserSettingsMembersTable';

interface UserSettingsInviteProps {
  isSaving: boolean;
  organizationSlug: string;
  setIsSaving: (isSaving: boolean) => void;
}

export const UserSettingsInvite = ({
  isSaving,
  setIsSaving,
  organizationSlug,
}: UserSettingsInviteProps) => {
  const { isMobile } = useDeviceType(undefined, 1100);

  const { getAccessTokenSilently } = useAuth0();

  const { dealRoomId } = useDealRoom();

  const { data: dealRoomContactsData, refetch: refetchContacts } = useQuery(
    DealRoomContactsQuery(organizationSlug!, dealRoomId!),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRoomContacts(
        organizationSlug,
        dealRoomId!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const inviteStyle = mergeStyles({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: isMobile ? '0 0 1rem' : '0',
    flex: '1',

    '.invite-content': {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',

      '.people-access-label': {
        fontSize: '1rem',
        paddingBottom: '0.5rem',
      },
      '.people-access-subtitle': {
        fontSize: '0.85rem',
        color: DEALROOMS_COLORS.themeSecondary,
      },
    }
  });

  return (
    <div className={inviteStyle}>
      <div className="invite-content">
        <div>
          <div className="people-access-label">Invite Someone</div>
          <div className="people-access-subtitle">
            Invite someone to give them access to this Decision Site.          </div>
        </div>

        <UserSettingsInviteForm setIsSaving={setIsSaving} isSaving={isSaving} refetchContacts={refetchContacts} />
        <UserSettingsMembersTable
          dealRoomContactsData={dealRoomContactsData?.data}
          refetchContactsData={refetchContacts} 
          />
      </div>
    </div>
  );
};
