import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Dropdown, Icon } from '@fluentui/react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Box,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { artifactTypeToIconName } from '../../../Helpers/IconHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryClient } from 'react-query';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { isForbiddenError } from '../../../Helpers/AxiosHelpers';
import toast from 'react-hot-toast';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { DSResponsiveModal, DSCheckbox, DSTabs, DSTab } from '../DS';
import { StyledArtifactBox, StyledIconBox } from './AddAttachmentDialog.styles';
import {
  CommonUploadArtifactsFile,
  CommonUploadArtifactsURL,
  meetingflowUrlValidator,
} from './CommonUploadArtifacts';
import { isUrl } from '../../../Helpers/URLHelpers';

type AddAttachmentDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  actionItemId: number | null; // if null it's used for conversations attachments, so the file shouldn't be attached to a task
  onResolve: (value: unknown) => void;
  onDismiss: () => void;
};

const AttachTabOptions = {
  selectAttach: 'selectAttach',
  uploadAttach: 'uploadAttach',
  uploadURL: 'uploadURL',
};

export const AddAttachmentDialog = ({
  organizationSlug,
  dealRoomId,
  actionItemId,
  onResolve,
  onDismiss,
}: AddAttachmentDialogProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const client = useQueryClient();

  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const taskOptionsList = useMemo(() => {
    if (!mutualPlan?.milestones) return [];

    return mutualPlan.milestones
      .flatMap((milestone) => milestone.actionItems || [])
      .map((actionItem) => ({
        key: actionItem?.id,
        text: actionItem?.actionItem,
      }));
  }, [mutualPlan]);

  const [selectedItemID, setSelectedItemID] = useState<null | number>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedArtifactId, setSelectedArtifactId] = useState<number | null>(
    null,
  );
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [value, setValue] = useState<string>(AttachTabOptions.selectAttach);

  useEffect(() => {
    setSelectedItemID(actionItemId);
  }, [actionItemId]);

  const {
    data: dealRoomArtifacts,
    isLoading: artifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listArtifacts(
        {
          organizationSlug,
          dealRoomId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // Enable the query only if organizationSlug and dealRoomId are valid
      enabled: !!organizationSlug && !!dealRoomId,

      // Retry logic for the query
      /**
       * Determines whether to retry fetching data based on the failure count and error type.
       *
       * @param {number} failureCount - The number of times the query has failed.
       * @param error - The error that occurred during the query.
       * @returns {boolean} - Returns true if the query should be retried, otherwise false.
       */
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          // Do not retry if the error is a forbidden error
          return false;
        }
        // Retry up to 3 times for other errors
        return failureCount < 3;
      },
    },
  );

  // Refetch artifacts when the dialog is opened to ensure the list is up to date
  useEffect(() => {
    refetchArtifacts();
  }, [refetchArtifacts]);

  const handleActualUpload = useCallback(async () => {
    if (!selectedFile) return;
    try {
      const token = await getAccessTokenSilently();
      return await DealRoomsApiClient.uploadFileArtifact(
        organizationSlug,
        dealRoomId,
        {
          file: selectedFile,
          ...(title ? { name: title } : {}),
          ...(description ? { description } : {}),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      toast.error(
        'Something went wrong uploading file, please try again later',
      );
      onDismiss();
      return err;
    }
  }, [
    dealRoomId,
    getAccessTokenSilently,
    onDismiss,
    organizationSlug,
    selectedFile,
    title,
    description,
  ]);

  const validateMeetingflowUrl = useCallback(
    () => meetingflowUrlValidator(url, organizationSlug),
    [organizationSlug, url],
  );

  const { isMeetingflowUrl, isMeetingflowUrlInCurrentOrg } =
    validateMeetingflowUrl();

  const handleUrlAttach = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      if (isMeetingflowUrl && !isMeetingflowUrlInCurrentOrg) {
        toast.error(
          'It looks like this is a Meetingflow from a different organization. To add a Meetingflow, provide a URL from the current organization.',
        );
        return Promise.reject();
      }

      const result = await DealRoomsApiClient.createLinkArtifact(
        organizationSlug,
        dealRoomId,
        {
          type: 'LINK',
          url,
          name: title || url,
          description: description || undefined,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      toast.success(`Successfully uploaded artifact`);

      // Refetch artifacts
      await client.refetchQueries(
        OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
      );

      return result;
    } catch (err) {
      toast.error(
        'Something went wrong with upload url, please try again later',
      );
      onDismiss();
      return err;
    }
  }, [
    getAccessTokenSilently,
    isMeetingflowUrl,
    isMeetingflowUrlInCurrentOrg,
    organizationSlug,
    dealRoomId,
    url,
    title,
    description,
    client,
    onDismiss,
  ]);

  const handleLinkAttachment = useCallback(
    async (attachID: number, uploadedAttachment?: DealRoomArtifact) => {
      if (actionItemId === null) {
        // this block is used to get the artifact that was just uploaded in the conversations part
        if (uploadedAttachment) {
          onResolve(uploadedAttachment || null);
          return;
        }

        const currentSelectedAttachment = dealRoomArtifacts?.data?.find(
          (artifact) => artifact.id === attachID,
        );

        onResolve(currentSelectedAttachment || null);
        return;
      }

      if (selectedItemID === null) return;
      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.putActionItemArtifact(
            organizationSlug,
            dealRoomId,
            selectedItemID,
            attachID,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Uploading Artifact',
            success: () => {
              onResolve(null);
              refetchMutualPlan().then();
              return 'Successfully uploaded artifact';
            },
            error: () => {
              onDismiss();
              return 'Failed to upload artifact';
            },
          },
        );
      } catch (err) {
        onDismiss();
      }
    },
    [
      dealRoomArtifacts?.data,
      onResolve,
      getAccessTokenSilently,
      organizationSlug,
      dealRoomId,
      actionItemId,
      selectedItemID,
      refetchMutualPlan,
      onDismiss,
    ],
  );

  const handleUploadAndLinkAttachment = useCallback(async () => {
    const response = await (value === AttachTabOptions.uploadURL
      ? handleUrlAttach()
      : handleActualUpload());
    if (
      response &&
      typeof response === 'object' &&
      'data' in response &&
      response.data &&
      typeof response.data === 'object' &&
      'id' in response.data &&
      typeof response.data.id === 'number'
    ) {
      await handleLinkAttachment(
        response.data.id,
        response.data as DealRoomArtifact, // this is used just in conversations side
      );
    }
  }, [handleActualUpload, handleLinkAttachment, handleUrlAttach, value]);

  return (
    <DSResponsiveModal
      open
      maxWidth="sm"
      fullScreenOnMobile
      onDismiss={onDismiss}
      customMaxWidth={538}
      title={`${
        actionItemId === null ? 'Add Artifact' : 'Add Artifact to Task'
      }`}
      primaryFooterButtons={[
        {
          children:
            actionItemId === null ? 'Add Artifact' : 'Add Artifact to Task',
          onClick: async () => {
            const currentTab = value;
            if (currentTab === AttachTabOptions.selectAttach) {
              await handleLinkAttachment(selectedArtifactId || -1);
            } else if (
              currentTab === AttachTabOptions.uploadAttach ||
              currentTab === AttachTabOptions.uploadURL
            ) {
              await handleUploadAndLinkAttachment();
            }
          },
          disabled:
            value === AttachTabOptions.selectAttach
              ? !selectedArtifactId
              : value === AttachTabOptions.uploadAttach
                ? !(selectedFile && title)
                : !(isUrl(url) && title),
        },
      ]}
      secondaryFooterButtons={[
        {
          children: 'Cancel',
          onClick: onDismiss,
        },
      ]}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <DSTabs
          aria-label="attachment tabs"
          defaultValue={AttachTabOptions.selectAttach}
          value={value}
          onChange={(e, v) => setValue(v as string)}
        >
          <DSTab
            value={AttachTabOptions.selectAttach}
            label="Select Artifact"
            panelStyles={{
              padding: '0.5rem 0 0 0',
            }}
            panel={
              <Stack spacing={2}>
                <TableContainer sx={{ width: '100%', maxHeight: '450px' }}>
                  <Table size="small" sx={{ width: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox" sx={{ width: '48px' }}>
                          Select
                        </TableCell>
                        <TableCell>Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dealRoomArtifacts?.data.map((artifact) => (
                        <TableRow
                          key={`artifact_item_${artifact.id}`}
                          hover
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell
                            padding="checkbox"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <DSCheckbox
                              checked={selectedArtifactId === artifact.id}
                              onChange={() =>
                                setSelectedArtifactId(artifact.id)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <StyledArtifactBox>
                              <StyledIconBox
                                sx={{ color: DEALROOMS_COLORS.cloudburst }}
                              >
                                <Icon
                                  iconName={artifactTypeToIconName(
                                    artifact.type,
                                    // @ts-ignore
                                    artifact?.mimeType,
                                  )}
                                  style={{ color: DEALROOMS_COLORS.cloudburst }}
                                />
                              </StyledIconBox>
                              <span>{artifact.name}</span>
                            </StyledArtifactBox>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            }
          />
          <DSTab
            value={AttachTabOptions.uploadAttach}
            label="Upload File"
            panelStyles={{
              padding: '1rem 0 0 0',
            }}
            panel={
              <CommonUploadArtifactsFile
                actionItemId={actionItemId}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
              />
            }
          />
          <DSTab
            value={AttachTabOptions.uploadURL}
            label="Create from URL"
            panelStyles={{
              padding: '0.5rem 0 0 0',
            }}
            panel={
              <>
                <Box sx={{ mt: 2 }}>
                  <Stack spacing={2}>
                    <CommonUploadArtifactsURL
                      url={url}
                      setUrl={setUrl}
                      title={title}
                      setTitle={setTitle}
                      description={description}
                      setDescription={setDescription}
                    />
                  </Stack>
                </Box>
              </>
            }
          />
        </DSTabs>
        {actionItemId !== null && (
          <Dropdown
            className="taskDropdown"
            options={taskOptionsList}
            onChange={(_e, option) => {
              if (option?.key !== undefined) {
                setSelectedItemID(+option.key);
              }
            }}
            selectedKey={selectedItemID}
            label="Task"
            placeholder="Select task"
          />
        )}
      </Box>
    </DSResponsiveModal>
  );
};
