import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const useCommentsButtonStyles = () =>
  mergeStyleSets({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    commentsButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '.25rem',
      minWidth: '7.75rem',
      padding: '0.4375rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      backgroundColor: DEALROOMS_COLORS.sidebarTextPrimary,
      borderRadius: '.25rem',
      '.ms-Button-label': {
        fontWeight: '400',
        fontSize: '1rem',
        color: DEALROOMS_COLORS.neutralDarker,
      },
    },
    title: {
      fontWeight: '400',
      fontSize: '1rem',
      color: DEALROOMS_COLORS.neutralDarker,
    },
  });
