/**
 * DecisionSiteMeetingPanelParticipantCard displays participant information and engagement metrics
 * in a visually appealing card format. It includes talk time statistics, speaker turns,
 * and a circular progress indicator for participation percentage.
 *
 * Key Features:
 * - Talk time statistics display
 * - Speaker turn counting
 * - Participation percentage visualization
 * - Interactive participant selection
 * - Dark mode support
 * - Custom color coding per participant
 */

import {
  FontSizes,
  FontWeights,
  NeutralColors,
  mergeStyles,
} from '@fluentui/react';
import { TranscriptStats } from '@meetingflow/common/TranscriptHelpers';
import classNames from 'classnames';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import {
  DEALROOMS_COLORS,
  MEETINGFLOW_COLORS,
} from '../../../../Themes/Themes';

/**
 * Props interface for the participant card component
 * @property participant - Name or identifier of the participant
 * @property callStats - Transcript statistics including talk time and turns
 * @property getColorForSpeaker - Function to get consistent color for participant
 * @property onParticipantClick - Optional handler for participant selection
 */
interface DecisionSiteCallRecordingParticipantCardProps {
  participant: string;
  callStats: TranscriptStats | undefined;
  getColorForSpeaker: (participant: string) => string;
  onParticipantClick?: (participant: string) => void;
}

/**
 * Component that renders a participant card with engagement metrics
 * Displays:
 * - Participant name
 * - Number of speaking turns
 * - Total talk time in minutes
 * - Percentage of total conversation
 * - Circular progress indicator
 */
const DecisionSiteCallRecordingParticipantCard = ({
  participant,
  callStats,
  onParticipantClick,
  getColorForSpeaker,
}: DecisionSiteCallRecordingParticipantCardProps) => {
  /**
   * Calculate engagement metrics from call statistics
   * - minutesTalkTime: Total speaking time in minutes
   * - percentTalkTime: Percentage of total conversation (2 decimals)
   * - percentTalkTimeRounded: Percentage for display (0 decimals)
   * - speakerTurns: Number of times participant spoke
   */
  const minutesTalkTime = (
    (callStats?.talkTime[participant] || 0) / 60
  )?.toFixed(0);

  const percentTalkTime = callStats?.talkPercentage[participant]?.toFixed(2);

  const percentTalkTimeRounded =
    callStats?.talkPercentage[participant]?.toFixed(0);

  const speakerTurns = callStats?.speakerTurns[participant];

  const { isDark } = useLightOrDarkMode();

  /**
   * Styles for the participant card
   * Features:
   * - Consistent card dimensions
   * - Hover and click interactions
   * - Dark mode support
   * - Circular progress indicator styling
   * - Text formatting and shadows
   */
  const participantCardStyles = mergeStyles({
    // Card container styling
    position: 'relative',
    height: '48px',
    minHeight: '48px',
    cursor: 'unset',
    margin: '0 !important',
    padding: '.5rem 3.5rem 0 1rem',
    marginRight: '1rem',
    borderRadius: '.25rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : NeutralColors.gray120,

    // Circular progress indicator
    '.pie-chart': {
      height: 'var(--w)',
      lineHeight: 'var(--w)',
      width: 'var(--w)',
      aspectRatio: 1,
      display: 'inline-grid',
      placeContent: 'center',
      margin: '5px',
      fontSize: '11px',
      color: DEALROOMS_COLORS.woodsmoke,
      fontWeight: '700',
      fontFamily: 'Georgia, serif',
      backgroundColor: `white`,
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '50%',
      position: 'absolute',
      top: '2px',
      right: '.25rem',
    },

    // Progress indicator border animation
    'div.pie-chart-border': {
      content: '',
      position: 'absolute',
      borderRadius: '50%',
      inset: 0,
      background: ' conic-gradient(var(--c) calc(var(--p)*1%),#0000 0)',
      '-webkit-mask':
        'radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)))',
      mask: 'radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)))',
    },

    // Text styling for participant info
    dt: {
      margin: `0 !important`,
    },

    'dt, dd': {
      color: `${MEETINGFLOW_COLORS.white} !important`,
      textShadow: '1px 1px 2px rgba(0, 0, 0, .25)',
      margin: `0 !important`,

      strong: {
        fontWeight: FontWeights.semibold,
      },
    },
  });

  return (
    <dl
      key={participant}
      className={classNames(participantCardStyles, 'call-participant')}
      onClick={() => onParticipantClick?.(participant)}
      style={{
        backgroundColor: getColorForSpeaker(participant),
      }}
    >
      {/* Participant name */}
      <dt>{participant}</dt>

      {/* Speaking statistics */}
      {minutesTalkTime && speakerTurns ? (
        <dd>
          <strong>{speakerTurns}</strong> turns,{' '}
          <strong>{minutesTalkTime}</strong> min
        </dd>
      ) : null}

      {/* Circular progress indicator */}
      {percentTalkTimeRounded ? (
        <div
          className={'pie-chart'}
          style={{
            // CSS variables for dynamic progress display
            // @ts-ignore
            '--p': percentTalkTime,
            '--b': '5px',
            '--c': getColorForSpeaker(participant),
            '--w': '40px',
          }}
        >
          <div className="pie-chart-border" />
          <>{percentTalkTimeRounded || 0}%</>
        </div>
      ) : null}
    </dl>
  );
};

export default DecisionSiteCallRecordingParticipantCard;
