import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  DefaultButton,
  FontIcon,
  ISearchBox,
  ISearchBoxStyles,
  IToggleStyles,
  ITooltipHostStyles,
  PrimaryButton,
  SearchBox,
  Spinner,
  Text,
  Toggle,
  TooltipHost,
  mergeStyles,
  Link,
} from '@fluentui/react';
import { FavoriteStarFillIcon } from '@fluentui/react-icons-mdl2';
import { FontSizes, FontWeights, NeutralColors } from '@fluentui/theme';
import {
  CalendarEvent,
  MeetingflowTemplate,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AsyncLink } from '../../Components/HOC/AsyncLink';
import { BaseModal } from '../../Components/MeetingPlans/Dialogs/BaseModal';
import EventCard from '../../Components/Organization/Library/EventCard';
import StyledDateTime from '../../Components/StyledDateTime';
import { EMPTY_ARRAY } from '../../Constants';
import { isAxiosErrorResponse } from '../../Helpers/AxiosHelpers';
import { isGoogleUser } from '../../Helpers/IdentityHelpers';
import {
  MeetingPlanQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationSampleMeetingPlansQuery,
  OrganizationTemplatesQuery,
  OrganizationUpcomingMeetings,
} from '../../QueryNames';
import {
  ApiClient,
  EventsApiClient,
  MeetingflowsApiClient,
  TemplatesApiClient,
} from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { useDeferredPromise } from '../useDeferredPromise';
import { useLightOrDarkMode } from '../useLightOrDarkMode';
import { useOrganization } from '../useOrganization';
import { useUserProfile } from '../useProfile';

const CONDUCT_WIZARD_A_B_TEST = true;

export type CreateMeetingflowResult = {
  event: CalendarEvent | undefined;
  template: MeetingflowTemplate | undefined;
  added: boolean;
};

export type CreateMeetingflowModalOptions = {
  event?: CalendarEvent;
  template?: MeetingflowTemplate;
};

export const useCreateMeetingflowModal = (
  { event, template }: CreateMeetingflowModalOptions = {
    event: undefined,
    template: undefined,
  },
) => {
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently, user } = useAuth0();
  const {
    userId,
    user: userProfile,
    updateUserProfileAsync,
  } = useUserProfile();
  const { slug: organizationSlug, canCreatePlans } = useOrganization();

  const { createDeferred, resolve, reject, deferred } = useDeferredPromise<
    CreateMeetingflowResult | undefined,
    CreateMeetingflowModalOptions | undefined
  >();

  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | undefined>(
    event || undefined,
  );

  const [selectedTemplate, setSelectedTemplate] = useState<
    MeetingflowTemplate | undefined
  >(template);

  const [makeTemplateDefault, setMakeTemplateDefault] = useState(false);

  const [activePanel, setActivePanel] = useState<'event' | 'template'>(
    event ? 'template' : 'event',
  );

  const { isDark } = useLightOrDarkMode();

  const client = useQueryClient();

  const [searchParams] = useSearchParams();
  const firstVisit = searchParams.get('firstVisit') === 'true';

  const ABTestGroup = useMemo(() => {
    if (!user?.sub) {
      return 'A_WIZARD';
    }

    const hash = user.sub.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

    return 'B_NON_WIZARD'; // hash % 2 === 0 ? 'A_WIZARD' : 'B_NON_WIZARD';
  }, [user]);

  const SHOW_AS_WIZARD = CONDUCT_WIZARD_A_B_TEST
    ? ABTestGroup === 'A_WIZARD'
    : false;

  const { mutate: setDefaultTemplate } = useMutation({
    mutationKey: 'setDefaultTemplate',
    mutationFn: async (update: {
      id: MeetingflowTemplate['id'];
      defaultType: 'user' | 'organization';
    }) => {
      const token = await getAccessTokenSilently();
      switch (update.defaultType) {
        case 'organization':
          return TemplatesApiClient.setOrgDefaultTemplate(
            organizationSlug!,
            {
              templateId: update.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );

        case 'user':
          TemplatesApiClient.setUserDefaultTemplate(
            organizationSlug!,
            userId!,
            {
              templateId: update.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
      }
    },
    onMutate: async ({ id, defaultType }) => {
      const previousTemplates = client.getQueryData<
        AxiosResponse<MeetingflowTemplate[]>
      >(OrganizationTemplatesQuery(organizationSlug!));

      client.setQueryData(
        OrganizationTemplatesQuery(organizationSlug!),
        (old: AxiosResponse<MeetingflowTemplate[]> | undefined) => {
          if (!old) {
            return {
              config: {},
              headers: {},
              status: 404,
              statusText: '',
              request: {},
              data: [] as MeetingflowTemplate[],
            } as AxiosResponse<MeetingflowTemplate[]>;;
          }
          return {
            ...old,
            data: old.data.map((template) => ({
              ...template,
              isOrgDefault:
                defaultType === 'organization'
                  ? template.id === id
                    ? true
                    : false
                  : template.isOrgDefault,
              isUserDefault:
                defaultType === 'user'
                  ? template.id === id
                    ? true
                    : false
                  : template.isUserDefault,
            })),
          } as AxiosResponse<MeetingflowTemplate[]>;
        },
      );

      return { previousTemplates };
    },
    onError: (err, { defaultType }, context) => {
      if (context?.previousTemplates) {
        client.setQueryData(
          OrganizationTemplatesQuery(organizationSlug!),
          context.previousTemplates,
        );
      }
      console.error(err);
      toast.error(
        `Something went wrong updating the default ${defaultType} template, please try again.`,
      );
    },
    onSuccess: (data, { defaultType }) => {
      const updatedTemplate = data?.data;
      if (updatedTemplate) {
        client.setQueryData(
          OrganizationTemplatesQuery(organizationSlug!),
          (old: AxiosResponse<MeetingflowTemplate[]> | undefined) => {
            if (!old) {
              return {
                config: {},
                headers: {},
                status: 200,
                statusText: '',
                request: {},
                data: [updatedTemplate],
              } as AxiosResponse<MeetingflowTemplate[]>;
            }

            return {
              ...old,
              data:
                old?.data?.map((template) =>
                  template.id === updatedTemplate.id
                    ? updatedTemplate
                    : template,
                ) || [],
            } as AxiosResponse<MeetingflowTemplate[]>;;
          },
        );

        appInsights.trackEvent({
          name: 'SET_DEFAULT_TEMPLATE',
          properties: {
            organizationSlug,
            templateId: updatedTemplate.id,
            templateName: updatedTemplate.name,
            defaultType,
            surface: 'NEW_MEETINGFLOW_DIALOG',
            test: CONDUCT_WIZARD_A_B_TEST ? 'WIZARD_VS_NON_WZARD' : undefined,
            testGroup: CONDUCT_WIZARD_A_B_TEST ? ABTestGroup : undefined,
          },
        });
      }
      toast.success(`Default ${defaultType} template was updated`);
    },
    onSettled: () => {
      client.refetchQueries(OrganizationTemplatesQuery(organizationSlug!));
    },
  });

  const createOrViewMeetingflow = async (
    event?: CalendarEvent | undefined,
    template?: MeetingflowTemplate | undefined,
  ) => {
    if (!!event?.meetingplanId) {
      navigate(`/organization/${organizationSlug}/plan/${event.meetingplanId}`);
    } else {
      const token = await getAccessTokenSilently();

      if (template && !template?.isUserDefault && makeTemplateDefault) {
        setDefaultTemplate({
          id: template.id,
          defaultType: 'user',
        });
      }

      let reqBody = {};
      if (event) {
        reqBody = {
          source: event.source,
          eventId: event.externalId,
          eventTime: event.startTime,
        };
      }

      if (template) {
        reqBody = {
          ...reqBody,
          templateId: template.id,
        };
      }

      await toast.promise(
        MeetingflowsApiClient.postMeetingflow(organizationSlug!, reqBody, {
          headers: { Authorization: `Bearer ${token}` },
          validateStatus: (code) => code === 201 || code === 302,
        }),
        {
          loading: 'Creating Meetingflow',
          success: (result) => {
            Promise.all([
              client.invalidateQueries(
                OrganizationMeetingPlansQuery(organizationSlug!),
              ),
              client.invalidateQueries(
                OrganizationMeetingsHappeningSoon(organizationSlug!),
              ),
              client.invalidateQueries(
                OrganizationUpcomingMeetings(organizationSlug!),
              ),
            ]);

            client.setQueryData(
              MeetingPlanQuery(organizationSlug!, result.data.id),
              result,
            );

            setActivePanel('event');
            setSelectedEvent(undefined);
            setSelectedTemplate(undefined);

            navigate(
              `/organization/${organizationSlug}/plan/${result.data.id}`,
            );

            if (result.status === 201) {
              appInsights.trackEvent({
                name: `CREATE_PLAN${event ? '_FOR_EVENT' : '_ADHOC'}`,
                properties: {
                  organizationSlug,
                  source: event ? event?.source : '',
                  eventId: event ? event?.externalId : '',
                  createdFromGlobalButton: true,
                  templateId: selectedTemplate?.id ? selectedTemplate?.id : '',
                  surface: 'NEW_MEETINGFLOW_DIALOG',
                  test: CONDUCT_WIZARD_A_B_TEST
                    ? 'WIZARD_VS_NON_WIZARD'
                    : undefined,
                  testGroup: CONDUCT_WIZARD_A_B_TEST ? ABTestGroup : undefined,
                },
              });

              return `A Meetingflow ${
                event ? `for ${event?.title}` : ''
              } has been created!`;
            } else if (result.status === 302) {
              return `A Meetingflow ${
                event ? `for ${event?.title}` : ''
              } has been created!`;
            }

            return null;
          },
          error: (err) => {
            if (err && isAxiosErrorResponse(err, 403)) {
              return `You don't have permission to create Meetingflows in this workspace`;
            }

            appInsights.trackException({
              exception: err instanceof Error ? err : new Error(err),
              properties: {
                organizationSlug,
                eventSource: event?.source,
                eventId: event?.externalId,
                status: isAxiosErrorResponse(err)
                  ? err.response?.status
                  : undefined,
                statusText: isAxiosErrorResponse(err)
                  ? err.response?.statusText
                  : undefined,
              },
            });

            appInsights.trackEvent({
              name: 'CREATE_PLAN_FAILED',
              properties: {
                organizationSlug,
                source: event?.source,
                eventId: event?.externalId,
                status: isAxiosErrorResponse(err)
                  ? err.response?.status
                  : undefined,
                statusText: isAxiosErrorResponse(err)
                  ? err.response?.statusText
                  : undefined,
                surface: 'NEW_MEETINGFLOW_DIALOG',
                test: CONDUCT_WIZARD_A_B_TEST
                  ? 'WIZARD_VS_NON_WIZARD'
                  : undefined,
                testGroup: CONDUCT_WIZARD_A_B_TEST ? ABTestGroup : undefined,
              },
            });

            return 'Something went wrong creating the Meetingflow, please try again';
          },
        },
      );
    }
  };

  const toggleAlwaysShowDialog = async (value: boolean) => {
    const result = await updateUserProfileAsync({
      preferenceNewMeetingflowDialog: value ? 'ENABLED' : 'DISABLED',
    });
    if (result.status === 200) {
      if (
        result.data.preferenceNewMeetingflowDialog !==
        userProfile?.preferenceNewMeetingflowDialog
      ) {
        appInsights.trackEvent({
          name: 'NEW_MEETINGFLOW_DIALOG_PREFERENCE_TOGGLE',
          properties: {
            status: result.data.preferenceNewMeetingflowDialog,
            surface: 'NEW_MEETINGFLOW_DIALOG',
            value: value.toString(),
            test: CONDUCT_WIZARD_A_B_TEST ? 'WIZARD_VS_NON_WIZARD' : undefined,
            testGroup: CONDUCT_WIZARD_A_B_TEST ? ABTestGroup : undefined,
          },
        });
      }
      toast.success(
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p>
            New Meetingflow dialog will {value ? '' : 'not'} be shown when
            creating Meetingflows.{' '}
            {value
              ? ''
              : 'Meetingflows will be created using your default template.'}
          </p>
          <Link
            styles={{
              root: {
                marginLeft: '1rem',
                backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
                color: MEETINGFLOW_COLORS.white,
                padding: '.25rem .5rem',
                borderRadius: '.25rem',
              },
            }}
            onClick={toggleAlwaysShowDialog.bind(null, !value)}
          >
            Undo
          </Link>
        </div>,
        {
          duration: 7000,
        },
      );
    }
  };

  const {
    data: sampleMeetingflowsData,
    isLoading: sampleMeetingflowsLoading,
    isFetching: sampleMeetingflowsFetching,
    isRefetching: sampleMeetingflowsRefetching,
    refetch: refetchPlans,
  } = useQuery(
    OrganizationSampleMeetingPlansQuery(organizationSlug!),
    async ({ pageParam }) => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.listPlans(
        { organizationSlug: organizationSlug!, isSample: true },
        { headers: { Authorization: `Bearer ${token}` } },
      );
    },
  );

  const welcomeSample = useMemo(() => {
    return sampleMeetingflowsData?.data?.find(
      (s) => s.sampleType === 'WELCOME',
    );
  }, [sampleMeetingflowsData]);

  const goToWelcomeSampleMeetingflow = async () => {
    if (welcomeSample) {
      appInsights.trackEvent({
        name: 'VIEW_SAMPLE_PLAN_CLICK',
        properties: {
          organizationSlug,
          sampleMeetingflowId: welcomeSample.id,
          surface: 'NEW_MEETINGFLOW_DIALOG',
          test: CONDUCT_WIZARD_A_B_TEST ? 'WIZARD_VS_NON_WIZARD' : undefined,
          testGroup: CONDUCT_WIZARD_A_B_TEST ? ABTestGroup : undefined,
        },
      });

      setActivePanel('event');
      setSelectedEvent(undefined);
      setSelectedTemplate(undefined);
      navigate(`/organization/${organizationSlug}/plan/${welcomeSample.id}`);
      resolve?.(undefined);
    }
  };

  const newMeetingflowDialogContentClass = mergeStyles({
    width: '80vw',
    maxWidth: SHOW_AS_WIZARD || event ? '500px' : '980px',
    padding: '1.5rem 1.5rem 0 1.5rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.purpleMediumDark
      : MEETINGFLOW_COLORS.purpleMedium,
    containerType: 'inline-size',
    '.intro': {
      position: 'relative',
      top: '-.5rem',

      h2: {
        display: 'none',
        fontSize: FontSizes.size24,
        fontWeight: FontWeights.semibold,
        margin: '0 0 .5rem 0',
        color: MEETINGFLOW_COLORS.white,
        textAlign: 'left',
      },
      p: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.size16,
        margin: '0 0 1rem 0',
        maxWidth: SHOW_AS_WIZARD || event ? undefined : '75%',
        textAlign: 'left',
        color: MEETINGFLOW_COLORS.purpleGrey,

        span: {
          color: MEETINGFLOW_COLORS.white,
        },
      },
    },

    '.main': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      columnGap: '1.5rem',
      containerType: 'inline-size',

      '@container (width < 45rem)': {
        flexDirection: 'column',
        columnGap: '0',
      },

      '.select-event, .select-template': {
        flexDirection: 'column',
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.purpleMedium
          : MEETINGFLOW_COLORS.purpleGreyLight,
        flex: '1 1 0',
        height: '100%',
        width: 'calc(100% - 1rem)',
        padding: '.5rem',
        borderRadius: '.25rem',
        marginBottom: '1rem',
        position: 'relative',
        animationName: 'fadeInAnimation',
        animationDuration: '.3s',
        transitionTimingFunction: 'linear',
        animationIterationCount: '1',
        animationFillMode: 'forwards',
        transition: '.3s ease-in-out all',

        '.no-content': {
          textAlign: 'center',
          maxWidth: '80%',
          margin: '.5rem auto',
          padding: '.25rem',
          backgroundColor: 'white',
          borderRadius: '.25rem',
          color: isDark
            ? MEETINGFLOW_COLORS.magentaDark
            : MEETINGFLOW_COLORS.magenta,
        },

        '.filter-controls': {
          display: 'flex',
          flexDirection: 'row',
          columnGap: '.5rem',

          '.search-calendar': {
            flex: '1 1 0',
          },
          '.toggle-external': {
            position: 'relative',
            top: '0',
          },
        },

        '.step-number': {
          display: event ? 'none' : 'block',
          fontSize: FontSizes.size16,
          fontWeight: FontWeights.bold,
          color: MEETINGFLOW_COLORS.white,
          position: 'absolute',
          top: 'calc(-.75rem - 1.5px)',
          left: 'calc(-.75rem - 1.5px)',
          height: '24px',
          width: '24px',
          borderRadius: '50%',
          textAlign: 'center',
          lineHeight: '22px',
          backgroundColor: MEETINGFLOW_COLORS.teal,
          border: `1.5px solid ${MEETINGFLOW_COLORS.white}`,
        },

        h3: {
          fontSize: FontSizes.size20,
          margin: '0 0 .5rem 0',
          fontWeight: FontWeights.semibold,
          color: isDark
            ? MEETINGFLOW_COLORS.white
            : MEETINGFLOW_COLORS.purpleMedium,

          i: {
            fontSize: FontSizes.size14,
            display: 'inline-block',
            position: 'relative',
            top: '-.25rem',
            marginLeft: '.5rem',
            color: MEETINGFLOW_COLORS.purpleMedium,
          },
        },

        h4: {
          fontSize: FontSizes.size12,
          margin: '.5rem 0',
          fontWeight: FontWeights.semibold,
        },

        p: {
          fontSize: FontSizes.size12,
          fontWeight: FontWeights.semibold,
          margin: '0 0 .5rem 0',
          color: MEETINGFLOW_COLORS.purpleMedium,
        },

        '.suggested-events, .suggested-templates': {
          margin: '.5rem 0',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          columnGap: '.25rem',
          rowGap: '.25rem',
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.purpleMedium
            : MEETINGFLOW_COLORS.purpleGreyLight,
          borderRadius: '.25rem',
          height: '152px',
          overflowY: 'auto',
          alignContent: 'baseline',
          containerType: 'inline-size',
          borderTop: `1px solid ${
            isDark
              ? MEETINGFLOW_COLORS.purpleMediumDark
              : MEETINGFLOW_COLORS.purpleGrey
          }`,
          paddingTop: '.5rem',

          '.event-card, .template-card': {
            '@container (width < 25rem)': {
              width: '100% !important',
              minWidth: '100% !important',
              maxWidth: '100% !important',
            },
          },
        },

        '.options': {
          display: 'flex',
          padding: '1rem 0 0 0',
          flexDirection: 'column',
          borderTop: `1px solid ${
            isDark
              ? MEETINGFLOW_COLORS.purpleMediumDark
              : MEETINGFLOW_COLORS.purpleGrey
          }`,

          '.ms-Checkbox-text': {
            fontWeight: FontWeights.semibold,
            fontSize: FontSizes.size12,
            color: MEETINGFLOW_COLORS.black,
          },

          p: {
            fontSize: FontSizes.size12,
            fontWeight: FontWeights.regular,
            margin: '0 0 .5rem 1.75rem',
            color: isDark
              ? MEETINGFLOW_COLORS.purpleDarkest
              : NeutralColors.gray100,
          },
        },

        '.options-calendar': {
          position: 'relative',

          '.or-highlight': {
            display: 'inline-block',
            width: '32px',
            height: '24px',
            lineHeight: '24px',
            textAlign: 'center',
            fontSize: FontSizes.size12,
            fontWeight: FontWeights.bold,
            textTransform: 'uppercase',
            color: MEETINGFLOW_COLORS.white,
            backgroundColor: MEETINGFLOW_COLORS.teal,
            borderRadius: '.25rem',
            position: 'absolute',
            top: '-12px',
            left: 'calc(50% - 12px)',
            border: `1.25px solid ${MEETINGFLOW_COLORS.white}`,
          },
        },
      },
      '.select-event': {
        display: event
          ? 'none'
          : !SHOW_AS_WIZARD || (SHOW_AS_WIZARD && activePanel === 'event')
            ? 'flex'
            : 'none',
        '.event-selector': {
          '.suggested-events': {
            '.event-card': {
              flex: '1 1 0',
              width: '100%',
              maxWidth: 'calc(50% - 2px)',
              minWidth: 'calc(50% - 2px)',
              boxSizing: 'border-box',
              borderRadius: '.25rem',

              '> div': {
                padding: '.5rem',
                backgroundColor: isDark
                  ? `rgba(0,0,0,.25)`
                  : MEETINGFLOW_COLORS.white,
                transition: '.3s ease-in-out all',

                ':hover': {
                  backgroundColor: isDark
                    ? MEETINGFLOW_COLORS.purpleMediumDark
                    : MEETINGFLOW_COLORS.purpleLighter,
                },

                '.event-title': {
                  display: 'block',
                  fontSize: FontSizes.medium,
                  fontWeight: FontWeights.semibold,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  lineHeight: '20px',
                },

                '.event-datetime': {
                  display: 'block',
                  fontSize: FontSizes.size12,
                  fontWeight: FontWeights.regular,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  lineHeight: '16px',
                  height: '16px',
                  color: isDark
                    ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
                    : NeutralColors.gray100,

                  span: {
                    color: isDark
                      ? NeutralColors.gray50
                      : NeutralColors.gray100,
                  },
                },
              },
            },

            '.selected': {
              '> div': {
                backgroundColor: `${
                  isDark ? `rgba(0,0,0,.5)` : MEETINGFLOW_COLORS.purpleMedium
                } !important`,

                span: {
                  color: `${MEETINGFLOW_COLORS.white} !important`,
                },
              },
            },
          },
        },
      },
      '.select-template': {
        display:
          !SHOW_AS_WIZARD || (SHOW_AS_WIZARD && activePanel === 'template')
            ? 'flex'
            : 'none',
        '.step-number': {
          backgroundColor: MEETINGFLOW_COLORS.teal,
        },

        '.template-selector': {
          '.suggested-templates': {},
          '.template-card': {
            flex: '1 1 0',
            width: '100%',
            maxWidth: 'calc(50% - 2px)',
            minWidth: 'calc(50% - 2px)',
            boxSizing: 'border-box',
            borderRadius: '.25rem',
            cursor: 'pointer',
            padding: '.5rem',
            position: 'relative',
            backgroundColor: isDark
              ? `rgba(0,0,0,.25)`
              : MEETINGFLOW_COLORS.white,

            ':hover': {
              backgroundColor: isDark
                ? MEETINGFLOW_COLORS.purpleMediumDark
                : MEETINGFLOW_COLORS.purpleLighter,
              transition: '.3s ease-in-out all',
            },

            '.title': {
              display: 'block',
              fontSize: FontSizes.medium,
              fontWeight: FontWeights.semibold,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              lineHeight: '20px',
            },

            '.description': {
              display: 'block',
              fontSize: FontSizes.size12,
              fontWeight: FontWeights.regular,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              lineHeight: '16px',
              minHeight: '16px',
              color: isDark ? NeutralColors.gray50 : NeutralColors.gray100,

              span: {
                color: isDark ? NeutralColors.gray50 : NeutralColors.gray100,
              },
            },
          },

          '.selected': {
            backgroundColor: `${
              isDark ? `rgba(0,0,0,.5)` : MEETINGFLOW_COLORS.purpleMedium
            } !important`,

            span: {
              color: MEETINGFLOW_COLORS.white,
            },

            '.description': {
              color: NeutralColors.white,
            },

            '.user-default-icon': {
              color: `${MEETINGFLOW_COLORS.orange} !important`,
            },
          },

          '.user-default-icon': {
            position: 'absolute',
            top: '-.35rem',
            right: '-.35rem',
            width: '.75rem',
            height: '.75rem',
            padding: '.35rem',
            backgroundColor: MEETINGFLOW_COLORS.purpleGreyLight,
            color: `${MEETINGFLOW_COLORS.orangeLight} !important`,
            borderRadius: '50%',
            zIndex: 1,
          },
        },
      },
    },
    '.calls-to-action': {
      height: '2.5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0',
      width: '100%',
      flexBasis: '100%',
      marginBottom: '1rem',

      '.ms-Button--primary': {
        color: `${MEETINGFLOW_COLORS.white} !important`,
        backgroundColor: `${MEETINGFLOW_COLORS.purpleDarkest} !important`,
        border: `2px solid ${MEETINGFLOW_COLORS.purpleMediumDark} !important`,

        ':hover': {
          backgroundColor: `${MEETINGFLOW_COLORS.purpleDarker} !important`,
          border: `2px solid ${MEETINGFLOW_COLORS.purpleGreyMediumAlt} !important`,
        },
      },

      '.ms-Button--default': {
        color: `${MEETINGFLOW_COLORS.purpleGrey} !important`,
        backgroundColor: `${MEETINGFLOW_COLORS.purpleGreyMediumAlt2} !important`,
      },

      button: {
        borderRadius: '.25rem',
        whiteSpace: 'nowrap',
        border: 'none',
        transition: '.3s ease-in-out all',
        padding: '0 .5rem',
        height: '2.5rem',
      },

      '.primary': {
        flexBasis: '50%',
        display: 'flex',
        justifyContent: 'flex-end',
        columnGap: '1rem',
      },

      '.secondary': {
        flexBasis: '50%',
        display: 'flex',
        justifyContent: 'flex-start',
        columnGap: '1rem',

        '.ms-Link': {
          color: MEETINGFLOW_COLORS.purpleGrey,
          transition: '.3s ease-in-out all',
          padding: '0 .5rem',
          height: '2.5rem',

          ':hover': {
            textDecoration: 'none',
            backgroundColor: MEETINGFLOW_COLORS.purpleLighter,
            color: MEETINGFLOW_COLORS.purpleMedium,
          },
        },
      },
    },
    '.tertiary': {
      flexBasis: '100%',
      display: 'flex',
      justifyContent: 'center',
      columnGap: '1rem',
      position: 'absolute',
      top: '-2rem',
      right: '3rem',
      paddingRight: '.75rem',
      borderRight: `1px solid rgba(255,255,255,.15)`,

      '.ms-Checkbox': {
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.purpleMedium
          : MEETINGFLOW_COLORS.purpleMediumDark,
        maxWidth: '350px',
        padding: '.25rem',
        paddingLeft: '.5rem',
        borderTopLeftRadius: '.25rem',
        borderTopRightRadius: '.25rem',
      },
    },
  });

  const toolTipHostStyles = useMemo(
    () =>
      ({
        root: {
          display: 'inline-block',
          height: '24px',
          width: '24px',
          marginLeft: '.25rem',

          p: {
            margin: 0,
          },
        },
      }) as ITooltipHostStyles,
    [],
  );

  const tooltipCalloutProps = useMemo(
    () => ({
      styles: {
        root: {
          padding: 0,
          background: MEETINGFLOW_COLORS.purpleDarker,
          color: isDark ? undefined : MEETINGFLOW_COLORS.white,
        },
        beak: {
          background: MEETINGFLOW_COLORS.purpleDarker,
        },
        calloutMain: {
          padding: '1rem',
          backgroundColor: isDark ? undefined : MEETINGFLOW_COLORS.purpleDarker,
          p: {
            margin: 0,
            borderBottomRightRadius: '.5rem',
            borderBottomLeftRadius: '.5rem',
            color: MEETINGFLOW_COLORS.white,
          },
        },
      },
    }),
    [isDark],
  );

  const checkboxStyles = {
    root: {
      '&.is-disabled': {
        opacity: '.35',
        cursor: 'not-allowed !important',
      },

      ':hover': {
        '.ms-Checkbox-text': {
          color: `${
            isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.purpleDark
          } !important`,
        },
      },

      '.ms-Checkbox-text': {
        transition: '.3s ease-in-out all',
        fontSize: FontSizes.size12,
        color: `${
          isDark ? MEETINGFLOW_COLORS.purpleGrey : MEETINGFLOW_COLORS.purpleDark
        } !important`,
      },
      '.ms-Checkbox-checkbox': {
        transition: '.3s ease-in-out all',
        backgroundColor: `${
          isDark ? `rgba(0,0,0,.25)` : MEETINGFLOW_COLORS.purpleMedium
        } !important`,
        borderColor: `${MEETINGFLOW_COLORS.purpleMedium} !important`,
      },
      '.ms-Checkbox-checkmark': {
        transition: '.3s ease-in-out all',
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },
    },
  };

  const dialog = (
    <BaseModal
      title={`Create ${
        firstVisit && !event ? 'your first' : event ? 'this' : 'a'
      } Meetingflow`}
      isOpen={deferred?.isPending}
      contentPadding="0"
      headerPadding=".25rem 1rem"
      headerBackgroundColor={
        isDark
          ? MEETINGFLOW_COLORS.purpleMedium
          : MEETINGFLOW_COLORS.purpleMediumDark
      }
      layerProps={{
        onLayerDidMount: () => {
          appInsights.trackEvent({
            name: 'VIEW_NEW_MEETINGFLOW_DIALOG',
            properties: {
              surface: 'NEW_MEETINGFLOW_DIALOG',
              eventSource: event?.source,
              eventId: event?.externalId,
              test: CONDUCT_WIZARD_A_B_TEST
                ? 'WIZARD_VS_NON_WIZARD'
                : undefined,
              testGroup: CONDUCT_WIZARD_A_B_TEST ? ABTestGroup : undefined,
            },
          });
        },
      }}
      onDismiss={() => {
        setActivePanel('event');
        setSelectedEvent(undefined);
        setSelectedTemplate(undefined);
        resolve?.(undefined);
      }}
    >
      <div className={newMeetingflowDialogContentClass}>
        <div className="intro">
          <h2>Create {event ? 'this' : 'a'} Meetingflow</h2>
          {event ? (
            <>
              {canCreatePlans ? (
                <p>
                  A Meetingflow for{' '}
                  <span>
                    {event.title} (
                    <StyledDateTime dateTime={event.startTime} />)
                  </span>{' '}
                  has not been created. You can create one for yourself and
                  other internal attendees.
                </p>
              ) : (
                <p>
                  A Meetingflow for {event.title} ({event.startTime}) has not
                  been created, and your account does not allow for creating
                  Meetingflows. Ask a creator in your workspace to create the
                  Meetingflow for this event.`
                </p>
              )}
            </>
          ) : (
            <p>
              {firstVisit ? "Let's create your first Meetingflow! " : ''}A
              Meetingflow is a collaborative surface for you and your team,
              optimizing the entire lifecycle of a meeting: preparing, engaging,
              and following up.{' '}
              <span>You'll be on the same page — literally.</span>
            </p>
          )}
        </div>
        <div className="main">
          <div className="select-event">
            <span className="step-number">1</span>
            <h3>
              Select Event
              <TooltipHost
                styles={toolTipHostStyles}
                calloutProps={tooltipCalloutProps}
                onMouseOver={() => {
                  appInsights.trackEvent({
                    name: 'VIEW_TOOLTIP',
                    properties: {
                      surface: 'NEW_MEETINGFLOW_DIALOG',
                      name: 'SELECT_EVENT_INFO',
                      test: CONDUCT_WIZARD_A_B_TEST
                        ? 'WIZARD_VS_NON_WIZARD'
                        : undefined,
                      testGroup: CONDUCT_WIZARD_A_B_TEST
                        ? ABTestGroup
                        : undefined,
                    },
                  });
                }}
                content={
                  <>
                    <p>
                      Select the event on your calendar you'd like to make a
                      Meetingflow for. Don't worry — your calendar won't be
                      modified, and other attendees won't be notified.
                    </p>
                  </>
                }
              >
                <FontIcon iconName="Info" />
              </TooltipHost>
            </h3>
            <NewMeetingflowDialogEventSelector
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
            />
            <div className="options options-calendar">
              <span className="or-highlight">Or</span>
              <Checkbox
                label="Make a Meetingflow without an event"
                checked={!selectedEvent}
                styles={checkboxStyles}
                onChange={(e, checked) => {
                  if (checked) {
                    setSelectedEvent(undefined);
                  }
                }}
              />
              <p>
                Take shared notes, or optimize the workflow for an impromptu
                meeting.
              </p>
            </div>
          </div>
          <div className="select-template">
            <span className="step-number">2</span>
            <h3>
              Select Template
              <TooltipHost
                styles={toolTipHostStyles}
                calloutProps={tooltipCalloutProps}
                onMouseOver={() => {
                  appInsights.trackEvent({
                    name: 'VIEW_TOOLTIP',
                    properties: {
                      surface: 'NEW_MEETINGFLOW_DIALOG',
                      name: 'SELECT_TEMPLATE_INFO',
                      test: CONDUCT_WIZARD_A_B_TEST
                        ? 'WIZARD_VS_NON_WIZARD'
                        : undefined,
                      testGroup: CONDUCT_WIZARD_A_B_TEST
                        ? ABTestGroup
                        : undefined,
                    },
                  });
                }}
                content={
                  <>
                    <p>
                      Templates can provide structure and walk you though a
                      particular process or methodology. You can use the
                      included templates, modify them, and/or create your own.
                      Templates are shared with all members of your workspace.
                    </p>
                  </>
                }
              >
                <FontIcon iconName="Info" />
              </TooltipHost>
            </h3>
            <NewMeetingflowDialogTemplateSelector
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              autoFocus={event ? true : false}
            />

            <div className="options">
              <Checkbox
                label="Make this my default template"
                disabled={!selectedTemplate || selectedTemplate?.isUserDefault}
                checked={makeTemplateDefault || selectedTemplate?.isUserDefault}
                styles={checkboxStyles}
                onChange={(e, checked) => {
                  if (checked) {
                    setMakeTemplateDefault(true);
                  } else {
                    setMakeTemplateDefault(false);
                  }
                }}
              />
              <p
                style={{
                  opacity: selectedTemplate?.isUserDefault ? '.5' : undefined,
                }}
              >
                {selectedTemplate?.isUserDefault
                  ? `This template is your default.`
                  : `Use the selected template by default when creating new
                Meetingflows.`}
              </p>
            </div>
          </div>
        </div>
        <div className="calls-to-action">
          <div className="secondary">
            {welcomeSample &&
            (!SHOW_AS_WIZARD || (SHOW_AS_WIZARD && activePanel === 'event')) ? (
              <Link onClick={goToWelcomeSampleMeetingflow}>
                View a Sample Meetingflow
              </Link>
            ) : null}
            {!SHOW_AS_WIZARD ||
            (SHOW_AS_WIZARD && activePanel === 'template') ? (
              <Link
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'CREATE_TEMPLATE_LINK_CLICK',
                    properties: {
                      surface: 'NEW_MEETINGFLOW_DIALOG',
                      test: CONDUCT_WIZARD_A_B_TEST
                        ? 'WIZARD_VS_NON_WIZARD'
                        : undefined,
                      testGroup: CONDUCT_WIZARD_A_B_TEST
                        ? ABTestGroup
                        : undefined,
                    },
                  });
                  setActivePanel('event');
                  setSelectedEvent(undefined);
                  setSelectedTemplate(undefined);
                  navigate(
                    `/organization/${organizationSlug}/library/templates/new`,
                  );
                  resolve?.(undefined);
                }}
              >
                Create a Template
              </Link>
            ) : null}
          </div>
          <div className="primary">
            {SHOW_AS_WIZARD && activePanel === 'template' ? (
              <>
                <DefaultButton
                  text="◀ Select Event"
                  onClick={() => {
                    appInsights.trackEvent({
                      name: 'NEW_MEEETINGFLOW_DIALOG_BACK_BUTTON_CLICK',
                      properties: {
                        surface: 'NEW_MEETINGFLOW_DIALOG',
                        test: CONDUCT_WIZARD_A_B_TEST
                          ? 'WIZARD_VS_NON_WIZARD'
                          : undefined,
                        testGroup: CONDUCT_WIZARD_A_B_TEST
                          ? ABTestGroup
                          : undefined,
                      },
                    });
                    setActivePanel('event');
                  }}
                />
              </>
            ) : null}

            <PrimaryButton
              text={
                SHOW_AS_WIZARD && activePanel === 'event'
                  ? 'Select Template ▶'
                  : 'Create Meetingflow'
              }
              onClick={() => {
                if (SHOW_AS_WIZARD && activePanel === 'event') {
                  appInsights.trackEvent({
                    name: 'NEW_MEEETINGFLOW_DIALOG_NEXT_BUTTON_CLICK',
                    properties: {
                      surface: 'NEW_MEETINGFLOW_DIALOG',
                      test: CONDUCT_WIZARD_A_B_TEST
                        ? 'WIZARD_VS_NON_WIZARD'
                        : undefined,
                      testGroup: CONDUCT_WIZARD_A_B_TEST
                        ? ABTestGroup
                        : undefined,
                    },
                  });
                  setActivePanel('template');
                  return;
                } else {
                  appInsights.trackEvent({
                    name: 'NEW_MEETINGFLOW_DIALOG_CREATE_MEETINGFLOW_BUTTON_CLICK',
                    properties: {
                      surface: 'NEW_MEETINGFLOW_DIALOG',
                      event: selectedEvent?.externalId,
                      template: selectedTemplate?.id,
                      templateName: selectedTemplate?.name,
                      test: CONDUCT_WIZARD_A_B_TEST
                        ? 'WIZARD_VS_NON_WIZARD'
                        : undefined,
                      testGroup: CONDUCT_WIZARD_A_B_TEST
                        ? ABTestGroup
                        : undefined,
                    },
                  });
                  createOrViewMeetingflow(selectedEvent, selectedTemplate);
                  resolve?.({
                    event: selectedEvent,
                    template: selectedTemplate,
                    added: true,
                  });
                }
              }}
            />
          </div>
        </div>
        <div className="tertiary">
          <Checkbox
            label="Don't show this again"
            boxSide="end"
            checked={userProfile?.preferenceNewMeetingflowDialog === 'DISABLED'}
            onChange={(_e, checked) => {
              toggleAlwaysShowDialog(!checked);
            }}
            styles={{
              root: {
                ':hover': {
                  '.ms-Checkbox-text': {
                    color: `${MEETINGFLOW_COLORS.white} !important`,
                  },
                },

                '.ms-Checkbox-text': {
                  transition: '.3s ease-in-out all',
                  fontSize: FontSizes.size12,
                  color: `${MEETINGFLOW_COLORS.purpleGreyMedium} !important`,
                },
                '.ms-Checkbox-checkbox': {
                  backgroundColor: `${MEETINGFLOW_COLORS.purpleGreyMedium} !important`,
                  borderColor: `${MEETINGFLOW_COLORS.purpleGreyMedium} !important`,
                },
                '.ms-Checkbox-checkmark': {
                  color: `${MEETINGFLOW_COLORS.purpleDark} !important`,
                },
              },
            }}
          />
        </div>
      </div>
    </BaseModal>
  );

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};

interface NewMeetingflowDialogTemplateSelectorProps {
  selectedTemplate: MeetingflowTemplate | undefined;
  setSelectedTemplate: (template: MeetingflowTemplate | undefined) => void;
  autoFocus?: boolean;
}

export const NewMeetingflowDialogTemplateSelector: React.FC<
  NewMeetingflowDialogTemplateSelectorProps
> = ({ selectedTemplate, setSelectedTemplate, autoFocus = false }) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const searchBoxRef = useRef<ISearchBox>(null);
  const { organization, slug: organizationSlug } = useOrganization();
  const appInsights = useAppInsightsContext();
  const { isDark } = useLightOrDarkMode();

  const ABTestGroup = useMemo(() => {
    if (!user?.sub) {
      return 'A_WIZARD';
    }

    const hash = user.sub.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

    return hash % 2 === 0 ? 'A_WIZARD' : 'B_NON_WIZARD';
  }, [user]);

  const {
    data: templates,
    isLoading: templatesLoading,
    isRefetching: templatesRefetching,
    refetch: refetchTempaltes,
  } = useQuery(OrganizationTemplatesQuery(organizationSlug!), async () => {
    const token = await getAccessTokenSilently();
    return TemplatesApiClient.getTemplates(
      {
        organizationSlug: organizationSlug!,
        name: searchTerm || undefined,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(debounce(refetchTempaltes, 500), [
    refetchTempaltes,
  ]);

  useEffect(() => {
    debouncedRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, organizationSlug]);

  const items = useMemo(
    () => templates?.data.map((t) => ({ key: `${t.id}`, ...t })) || EMPTY_ARRAY,
    [templates?.data],
  ) as MeetingflowTemplate[];

  // in items, move the t.isUserDefault ones to the top of the list
  items.sort((a, b) => {
    if (a.isUserDefault && !b.isUserDefault) {
      return -1;
    } else if (!a.isUserDefault && b.isUserDefault) {
      return 1;
    } else {
      return 0;
    }
  });

  const userDefaultTemplate = useMemo(
    () => items.find((t) => t.isUserDefault),
    [items],
  );

  // if no template is selected initially, select the user's default template
  useEffect(() => {
    if (!selectedTemplate && userDefaultTemplate) {
      setSelectedTemplate(userDefaultTemplate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchBoxStyles = {
    root: {
      backgroundColor: isDark ? MEETINGFLOW_COLORS.purpleMediumDark : undefined,
      borderColor: `${MEETINGFLOW_COLORS.purpleMedium} !important`,
      ':after': {
        borderColor: isDark
          ? MEETINGFLOW_COLORS.purpleMediumDark
          : MEETINGFLOW_COLORS.purpleMedium,
        outlineColor: isDark
          ? MEETINGFLOW_COLORS.purpleMediumDark
          : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
    field: {
      color: isDark ? MEETINGFLOW_COLORS.white : undefined,

      '::placeholder': {
        color: isDark
          ? `rgba(255,255,255,.5)`
          : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
    icon: {
      color: isDark ? MEETINGFLOW_COLORS.white : undefined,
    },
  } as Partial<ISearchBoxStyles>;

  return (
    <div className="template-selector">
      <SearchBox
        className="search-templates"
        componentRef={searchBoxRef}
        autoFocus={autoFocus}
        placeholder="Search templates by name..."
        onClear={() => {
          setSearchTerm(undefined);
        }}
        onChange={(e, nv) => {
          setSearchTerm(nv || '');
        }}
        onSearch={(nv) => {
          appInsights?.trackEvent({
            name: 'NEW_MEETINGFLOW_DIALOG_TEMPLATE_SEARCH',
            properties: {
              organizationId: organization?.id,
              organizationSlug: organizationSlug,
              userId: user?.sub,
              searchTerm: nv,
              test: CONDUCT_WIZARD_A_B_TEST
                ? 'WIZARD_VS_NON_WIZARD'
                : undefined,
              testGroup: CONDUCT_WIZARD_A_B_TEST ? ABTestGroup : undefined,
            },
          });
        }}
        styles={searchBoxStyles}
      />
      <div className="suggested-templates">
        {templatesLoading || templatesRefetching ? (
          <Spinner styles={{ root: { margin: '1rem auto' } }} />
        ) : (
          <>
            {items.length ? (
              items?.map((t) => (
                <div
                  className={`template-card ${
                    t.id === selectedTemplate?.id ? 'selected' : ''
                  } ${
                    t.isUserDefault
                      ? 'user-default'
                      : t.isOrgDefault
                        ? 'org-default'
                        : ''
                  }`}
                  onClick={() => {
                    t.id === selectedTemplate?.id
                      ? setSelectedTemplate(undefined)
                      : setSelectedTemplate(t);
                  }}
                  key={t.id}
                >
                  {t.isUserDefault ? (
                    <FavoriteStarFillIcon
                      title="Your default template"
                      className="user-default-icon"
                    />
                  ) : null}
                  <span className="title">{t.name}</span>
                  <span className="description">{t.description}</span>
                </div>
              ))
            ) : (
              <p className="no-content">
                {searchTerm
                  ? `No templates matching the name ${searchTerm} were found.`
                  : 'No templates were found in your Workspace!'}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

interface NewMeetingflowDialogEventSelectorProps {
  selectedEvent: CalendarEvent | undefined;
  setSelectedEvent: (event: CalendarEvent | undefined) => void;
}

export const NewMeetingflowDialogEventSelector = ({
  selectedEvent,
  setSelectedEvent,
}: NewMeetingflowDialogEventSelectorProps) => {
  const { getAccessTokenSilently, user, getAccessTokenWithPopup } = useAuth0();

  const { organization, slug: organizationSlug } = useOrganization();
  const { user: userProfile } = useUserProfile();
  const appInsights = useAppInsightsContext();

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const searchBoxRef = useRef<ISearchBox>(null);

  const [externalOnly, setExternalOnly] = useState<boolean>(true);

  const { isDark } = useLightOrDarkMode();
  const client = useQueryClient();

  const now = DateTime.now();

  const ABTestGroup = useMemo(() => {
    if (!user?.sub) {
      return 'A_WIZARD';
    }

    const hash = user.sub.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

    return hash % 2 === 0 ? 'A_WIZARD' : 'B_NON_WIZARD';
  }, [user]);

  // Use the user's preference for externalOnly to set the default
  useEffect(() => {
    if (userProfile?.preferenceAgendaMeetingFilter === 'EXTERNAL_ONLY') {
      setExternalOnly(false);
    } else {
      setExternalOnly(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: eventsData,
    isLoading: eventsLoading,
    isRefetching: eventsRefetching,
    refetch: refetchEvents,
    isError: eventsErrored,
    error: eventsError,
  } = useQuery(
    OrganizationUpcomingMeetings(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return EventsApiClient.listEvents(
        {
          organizationSlug: organizationSlug!,
          q: searchTerm || undefined,
          externalOnly: externalOnly,
          minDate: now.startOf('day').toISO()!,
          maxDate: searchTerm
            ? undefined
            : now.plus({ days: 30 }).endOf('day').toISO()!,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 15 minutes
      refetchInterval: 900000,
      staleTime: 900000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      retry: 1,
      enabled: !!organizationSlug,
      queryHash: `${OrganizationUpcomingMeetings(
        organizationSlug!,
      )}_NewMeetingflowDialog_${searchTerm}_${now
        .startOf('week')
        .startOf('day')
        .toISO()}`,
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(debounce(refetchEvents, 500), [
    refetchEvents,
  ]);

  useEffect(() => {
    debouncedRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchTerm,
    organizationSlug,
    userProfile?.preferenceAgendaMeetingFilter,
  ]);

  // refetch calendar events when the externalOnly filter changes
  useEffect(() => {
    refetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalOnly]);

  const events = useMemo(
    () => eventsData?.data.filter((e) => !e.meetingplanId) || EMPTY_ARRAY,
    [eventsData?.data],
  );

  const fadeInClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInSlideAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
  });

  const errorComponent = useMemo(() => {
    if (!eventsErrored) {
      return null;
    }
    if (isAxiosErrorResponse(eventsError)) {
      return (
        <div
          className={fadeInClass}
          style={{
            backgroundColor: MEETINGFLOW_COLORS.teal,
            margin: 0,
            width: '100%',
            height: '100%',
            border: `5px solid ${MEETINGFLOW_COLORS.white}`,
            boxSizing: 'border-box',
          }}
        >
          <div
            className={fadeInClass}
            style={{
              padding: '1rem 1.5rem',
              borderRadius: '.25rem',
              textAlign: 'center',
              fontSize: FontSizes.mediumPlus,
              color: 'white',
              margin: '.5rem',
            }}
          >
            <AsyncLink
              styles={{
                root: {
                  display: 'inline-block',
                  textDecoration: 'none !important',
                  backgroundColor: MEETINGFLOW_COLORS.white,
                  color: MEETINGFLOW_COLORS.purpleSecondary,
                  fontSize: FontSizes.mediumPlus,
                  borderRadius: '1.5rem',
                  padding: '.25rem .5rem',
                  margin: '.5rem auto',
                  fontWeight: FontWeights.semibold,
                  transition: 'all .3s ease-in-out',
                  textAlign: 'center',

                  ':hover': {
                    backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
                    color: MEETINGFLOW_COLORS.white,
                  },
                },
              }}
              onClick={async () => {
                appInsights.trackEvent({
                  name: 'CONNECT_CALENDAR_CLICK',
                  properties: {
                    organizationSlug,
                    emailAddress: user?.email,
                    provider: isGoogleUser(user!.sub!) ? 'GOOGLE' : 'M365',
                    surface: 'NEW_MEETINGFLOW_DIALOG',
                    test: CONDUCT_WIZARD_A_B_TEST
                      ? 'WIZARD_VS_NON_WIZARD'
                      : undefined,
                    testGroup: CONDUCT_WIZARD_A_B_TEST
                      ? ABTestGroup
                      : undefined,
                  },
                });
                const token = await getAccessTokenWithPopup({
                  cacheMode: 'off',
                  authorizationParams: {
                    display: 'popup',
                    login_hint: user?.email,
                    connection: isGoogleUser(user!.sub!)
                      ? 'google-oauth2'
                      : 'Microsoft',
                  },
                });

                await ApiClient.delete(
                  `/token/${isGoogleUser(user!.sub!) ? 'GOOGLE' : 'M365'}`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );

                client.invalidateQueries(
                  OrganizationMeetingsHappeningSoon(organizationSlug!),
                );

                refetchEvents();
              }}
            >
              Connect your {isGoogleUser(user!.sub!) ? 'Google' : 'Microsoft'}{' '}
              Calendar
            </AsyncLink>
            <Text
              block
              style={{
                color: `rgba(255,255,255,.75)`,
                fontSize: FontSizes.small,
                margin: '0 1rem 0 1rem',
              }}
            >
              Alternatively, create a Meetingflow without an event.
            </Text>
          </div>
        </div>
      );
    }
    return (
      <div>
        Something went wrong fetching your agenda, refresh the page to try again
      </div>
    );
  }, [
    eventsErrored,
    eventsError,
    refetchEvents,
    organizationSlug,
    user,
    appInsights,
    client,
    getAccessTokenWithPopup,
    fadeInClass,
    ABTestGroup,
  ]);

  const searchBoxStyles = {
    root: {
      backgroundColor: isDark ? MEETINGFLOW_COLORS.purpleMediumDark : undefined,
      borderColor: `${MEETINGFLOW_COLORS.purpleMedium} !important`,
      ':after': {
        borderColor: isDark
          ? MEETINGFLOW_COLORS.purpleMediumDark
          : MEETINGFLOW_COLORS.purpleMedium,
        outlineColor: isDark
          ? MEETINGFLOW_COLORS.purpleMediumDark
          : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
    field: {
      color: isDark ? MEETINGFLOW_COLORS.white : undefined,

      '::placeholder': {
        color: isDark
          ? `rgba(255,255,255,.5)`
          : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
    icon: {
      color: isDark ? MEETINGFLOW_COLORS.white : undefined,
    },
  } as Partial<ISearchBoxStyles>;

  const toggleStyles = {
    root: {
      display: 'inline-block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      position: 'relative',
      top: '-.4rem',
      marginBottom: '0',
      minWidth: '6rem',
      '.ms-Toggle-innerContainer': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        marginTop: '.25rem',
        button: {
          transition: 'all .3s ease-in-out',
          backgroundColor: isDark
            ? `rgba(0,0,0,.25)`
            : MEETINGFLOW_COLORS.purpleGrey,
        },
      },
      'label, button': {
        marginRight: '.25rem',
        textAlign: 'left',
        fontSize: FontSizes.small,
        color: isDark ? MEETINGFLOW_COLORS.white : NeutralColors.gray120,
        border: 'none !important',
      },
      label: {
        height: '.75rem',
        lineHeight: '.75rem',
        fontSize: FontSizes.mini,
        marginBottom: '.5rem',
        color: isDark ? MEETINGFLOW_COLORS.white : NeutralColors.gray120,
      },
      button: {
        position: 'relative',
        top: '.1rem',
      },

      'button[aria-checked="false"]': {
        '.ms-Toggle-thumb': {
          backgroundColor: MEETINGFLOW_COLORS.purpleLight,
        },
      },

      '.ms-Toggle-thumb': {
        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
      },
      'button:disabled .ms-Toggle-thumb': {
        backgroundColor: isDark ? NeutralColors.gray170 : NeutralColors.gray70,
      },
      'button:hover': {
        backgroundColor: isDark
          ? NeutralColors.gray190
          : MEETINGFLOW_COLORS.purpleLight,

        '.ms-Toggle-thumb': {
          backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
        },
      },
    },
  } as Partial<IToggleStyles>;

  const toolTipHostStyles = useMemo(
    () =>
      ({
        root: {
          display: 'inline-block',
          height: '16px',
          width: '16px',
          marginLeft: '.25rem',

          p: {
            margin: 0,
          },
        },
      }) as ITooltipHostStyles,
    [],
  );

  const tooltipCalloutProps = useMemo(
    () => ({
      styles: {
        root: {
          padding: 0,
          background: MEETINGFLOW_COLORS.purpleDarker,
          color: isDark ? undefined : MEETINGFLOW_COLORS.white,
        },
        beak: {
          background: MEETINGFLOW_COLORS.purpleDarker,
        },
        calloutMain: {
          padding: '1rem',
          backgroundColor: isDark ? undefined : MEETINGFLOW_COLORS.purpleDarker,
          p: {
            margin: 0,
            borderBottomRightRadius: '.5rem',
            borderBottomLeftRadius: '.5rem',
            color: MEETINGFLOW_COLORS.white,
          },
        },
      },
    }),
    [isDark],
  );

  return (
    <div className="event-selector">
      <div className="filter-controls">
        <SearchBox
          className="search-calendar"
          componentRef={searchBoxRef}
          autoFocus
          placeholder="Search your calendar by title or attendee..."
          onClear={() => {
            setSearchTerm(undefined);
          }}
          onChange={(e, nv) => {
            setSearchTerm(nv || '');
          }}
          onSearch={(nv) => {
            appInsights?.trackEvent({
              name: 'NEW_MEETINGFLOW_DIALOG_EVENT_SEARCH',
              properties: {
                organizationId: organization?.id,
                organizationSlug: organizationSlug,
                userId: user?.sub,
                searchTerm: nv,
                test: CONDUCT_WIZARD_A_B_TEST
                  ? 'WIZARD_VS_NON_WIZARD'
                  : undefined,
                testGroup: CONDUCT_WIZARD_A_B_TEST ? ABTestGroup : undefined,
              },
            });
          }}
          styles={searchBoxStyles}
        />

        <Toggle
          label={
            <>
              Only External{' '}
              <TooltipHost
                styles={toolTipHostStyles}
                calloutProps={tooltipCalloutProps}
                onMouseOver={() => {
                  appInsights.trackEvent({
                    name: 'VIEW_TOOLTIP',
                    properties: {
                      surface: 'NEW_MEETINGFLOW_DIALOG',
                      name: 'SELECT_EVENT_EXTERNAL_ONLY_INFO',
                      test: CONDUCT_WIZARD_A_B_TEST
                        ? 'WIZARD_VS_NON_WIZARD'
                        : undefined,
                      testGroup: CONDUCT_WIZARD_A_B_TEST
                        ? ABTestGroup
                        : undefined,
                    },
                  });
                }}
                content={
                  <>
                    <p>
                      Meetingflow classifies events as external when they have
                      attendees with email addresses from domains outside of
                      your organization. Enabling this filter will exclude
                      internal events.
                    </p>
                  </>
                }
              >
                <FontIcon iconName="Info" />
              </TooltipHost>
            </>
          }
          styles={toggleStyles}
          checked={externalOnly}
          className="toggle-external"
          onChange={(_e, checked) => {
            appInsights.trackEvent({
              name: 'NEW_MEETINGFLOW_DIALOG_CHANGE_CONTROLS',
              properties: {
                property: 'externalOnly',
                organizationSlug,
                emailAddress: user?.email,
                externalOnly: !!checked,
                test: CONDUCT_WIZARD_A_B_TEST
                  ? 'WIZARD_VS_NON_WIZARD'
                  : undefined,
                testGroup: CONDUCT_WIZARD_A_B_TEST ? ABTestGroup : undefined,
              },
            });

            setExternalOnly(!!checked);
          }}
          role="checkbox"
        />
      </div>

      <div className="suggested-events">
        {eventsLoading || eventsRefetching ? (
          <Spinner styles={{ root: { margin: '1rem auto' } }} />
        ) : (
          <>
            {eventsErrored && !events?.length && organizationSlug
              ? errorComponent
              : null}
            {!eventsErrored && events?.length && organizationSlug ? (
              events?.map((event: CalendarEvent) => (
                <div
                  className={`event-card ${
                    event.externalId === selectedEvent?.externalId
                      ? 'selected'
                      : ''
                  }`}
                  key={event.externalId}
                >
                  <EventCard
                    event={event}
                    organizationSlug={organizationSlug}
                    createMeetingflowOnClick={false}
                    hideNewMeetingflowButton
                    allowScheduleCallRecording={false}
                    disableCreateViewMeetingflowOnClick
                    showCompanies
                    onClick={() => {
                      event.externalId === selectedEvent?.externalId
                        ? setSelectedEvent(undefined)
                        : setSelectedEvent(event);
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="no-content">
                {!eventsErrored
                  ? searchTerm
                    ? `No ${
                        externalOnly ? 'external ' : ''
                      }events without Meetingflows that match "${searchTerm}" were found. Try searching for a specific event${
                        externalOnly
                          ? ', or remove the "External Only" filter'
                          : ''
                      }.`
                    : `No ${
                        externalOnly ? 'external ' : ''
                      }events were found on your calendar in the next month! Try searching for a specific event${
                        externalOnly
                          ? ', or remove the "External Only" filter. You can also make a Meetingflow without an event.'
                          : ''
                      }.`
                  : null}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default useCreateMeetingflowModal;
