import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { mergeStyleSets } from '@fluentui/react';

export const dsConfigurationMutualPlanStyles = mergeStyleSets({
  // Main component styles
  contentContainer: {
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 1.5rem',
    width: 'calc(100% - 3rem)',
    backgroundColor: DEALROOMS_COLORS.neutralGray,
  },
  headerTitle: {
    color: DEALROOMS_COLORS.themePrimary,
    fontSize: '1.5rem',
    fontWeight: '400',
    lineHeight: '2.5rem',
  },
  content: {
    width: 'calc(100% - 0.2rem)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },

  // Milestone styles
  milestones: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'flex-start',
    containerType: 'inline-size',
  },
  milestone: {
    width: 'calc(100% - 2rem)',
    borderRadius: '.25rem',
    backgroundColor: DEALROOMS_COLORS.neutralLighter,
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    boxShadow: '0px 2px 2px 0px #00000005',
    padding: '0.75rem 1rem',
    minHeight: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '.5rem',
    transition: 'all 0.2s ease-in-out',
    opacity: 0.7,
    transform: 'scale(0.98)',
    '@container (width < 400px)': {
      flexWrap: 'wrap',
    },
  },
  milestoneVisible: {
    backgroundColor: DEALROOMS_COLORS.white,
    opacity: 1,
    transform: 'scale(1)',
  },
  milestoneName: {
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    color: DEALROOMS_COLORS.themePrimary,
  },
  datePickerContainer: {
    marginLeft: 'auto',
  },
  datePicker: {
    minWidth: '150px',
    '.ms-TextField > span > div:empty': {
      display: 'none',
    },
    '.ms-TextField-fieldGroup > div': {
      marginRight: '.5rem',
    },
  },
  checkBox: {
    input: {
      margin: '0',
      width: '1rem',
      height: '1rem',
    },
    '.ms-Checkbox-checkbox': {
      width: '1rem',
      height: '1rem',
      margin: '0',
      borderRadius: '0.125rem',
    },
    selectors: {
      '&.ms-Checkbox.is-checked .ms-Checkbox-checkbox': {
        backgroundColor: DEALROOMS_COLORS.activeCheckBox,
        borderColor: DEALROOMS_COLORS.activeCheckBox,
      },
    },
  },

  // Template styles
  templateContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
  },
  templateCard: {
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    borderRadius: '4px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    cursor: 'pointer',
    backgroundColor: DEALROOMS_COLORS.white,
    transition: 'all 0.2s ease-in-out',
    width: '175px',
    selectors: {
      ':hover': {
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        transform: 'translateY(-2px)',
        borderColor: DEALROOMS_COLORS.themePrimary,
      },
    },
  },
  templateName: {
    color: DEALROOMS_COLORS.themePrimary,
    fontSize: '16px',
    fontWeight: '600',
  },
  templateDescription: {
    color: DEALROOMS_COLORS.themeSecondary,
    fontSize: '14px',
    lineHeight: '20px',
  },

  // Template confirmation modal styles
  modal: {
    '.ms-Modal-scrollableContent': {
      maxHeight: '80vh',
    },
  },
  modalContainer: {
    maxWidth: 'calc(100vw - 6rem)',
    backgroundColor: DEALROOMS_COLORS.white,
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    gap: '12px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: '600',
    color: DEALROOMS_COLORS.themePrimary,
  },
  modalDescription: {
    fontSize: '14px',
    color: DEALROOMS_COLORS.themeSecondary,
    lineHeight: '14px',
  },
  warning: {
    backgroundColor: 'red',
    padding: '12px',
    borderRadius: '4px',
    border: '1px solid red',
  },
  warningText: {
    color: 'white',
    fontSize: '14px',
    lineHeight: '14px',
  },
  milestonesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  milestonesHeader: {
    fontSize: '16px',
    fontWeight: '600',
    color: DEALROOMS_COLORS.themePrimary,
    marginBottom: '8px',
  },
  milestonesItem: {
    padding: '8px 16px',
    backgroundColor: DEALROOMS_COLORS.neutralLighter,
    borderRadius: '4px',
    fontSize: '14px',
    color: DEALROOMS_COLORS.themeSecondary,
  },

  // Dialog common styles
  dialog: {
    '.ms-Dialog-main': {
      minWidth: 'auto',
      maxWidth: '600px',
    },
  },
  dialogTitle: {
    color: 'white',
    fontWeight: '600',
  },
  dialogSubText: {
    color: 'white',
    fontSize: '14px',
    lineHeight: '20px',
  },
  deleteButton: {
    backgroundColor: 'red',
    borderColor: 'red',
    selectors: {
      ':hover': {
        backgroundColor: 'red',
        borderColor: 'red',
      },
    },
  },

  // Create milestone dialog styles
  required: {
    color: 'red',
    marginLeft: '4px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
    fontSize: '14px',
    fontWeight: '600',
  },
  field: {
    marginBottom: '16px',
  },

  // Common button styles
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    flexWrap: 'wrap',
  },
  closeButton: {
    color: DEALROOMS_COLORS.themePrimary,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    i: {
      color: DEALROOMS_COLORS.themePrimary,
    },
  },
  eyeIcon: {
    ':has(> svg)': {
      display: 'flex',
      height: 'auto',
    },
  },
  eyeIconButton: {
    i: {
      ':has(> svg)': {
        display: 'flex',
        height: 'auto',
      },
    },
  },
  tasksNumber: {
    display: 'block',
    fontWeight: '400',
    lineHeight: '1.5rem',
    fontSize: '1rem',
    color: DEALROOMS_COLORS.neutralDark,
  },
  milestoneNameAndCount: {
    minWidth: '8rem',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    color: DEALROOMS_COLORS.themePrimary,
  },
});
