import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import { useUserProfile } from '../../../../../Hooks/useProfile';
import {
  DealRoomContactsQuery,
  OrganizationUpcomingMeetings,
} from '../../../../../QueryNames';
import {
  DealRoomsApiClient,
  EventsApiClient,
} from '../../../../../Services/NetworkCommon';
import {
  CalendarEvent,
  DealRoomMeetingflowArtifact,
  ListDealRoomContactsData,
  ListEventsData,
} from '@meetingflow/common/Api/data-contracts';
import { DateTime, Duration } from 'luxon';
import { AxiosResponse } from 'axios';

interface UseUserCalendarProps {
  searchString: string;
  selectedDate?: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  showInternal: boolean;
  hasMeetingflow?: boolean;
  refreshKey?: string;
  queryKey?: (organizationSlug: string) => string;
}

type UseUserCalendarReturnType = {
  // Data
  events: CalendarEvent[];
  eventsData: AxiosResponse<ListEventsData> | undefined;
  dealRoomContactsData: AxiosResponse<ListDealRoomContactsData> | undefined;

  // Loading states
  loading: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isRefetching: boolean;
  isError: boolean;

  // Errors
  eventsError: unknown;
  dealRoomContactsError: unknown;

  // Actions
  refetchEvents: ReturnType<typeof useQuery>['refetch'];
  refetchContacts: ReturnType<typeof useQuery>['refetch'];
  getAccessTokenWithPopup: ReturnType<typeof useAuth0>['getAccessTokenWithPopup'];
  isEventInDecisionSite: (event: CalendarEvent) => boolean;

  meetingflowArtifacts: DealRoomMeetingflowArtifact[];
};

export const useUserCalendar = ({
  searchString,
  selectedDate,
  minDate,
  maxDate,
  showInternal,
  hasMeetingflow,
  refreshKey,
  queryKey = OrganizationUpcomingMeetings,
}: UseUserCalendarProps): UseUserCalendarReturnType => {
  // Authentication and organization context hooks
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const {
    slug: organizationSlug,
    internalDomains,
    isLoading: orgIsLoading,
  } = useOrganization();
  const { dealRoom, isLoading: dealRoomIsLoading } = useDealRoom();

  // Data fetching hooks - Deal Room Contacts
  const {
    data: dealRoomContactsData,
    isLoading: dealRoomContactsLoading,
    error: dealRoomContactsError,
    refetch: refetchContacts,
  } = useQuery(
    [DealRoomContactsQuery, dealRoom?.id],
    async () => {
      if (!dealRoom?.id) {
        throw new Error('No deal room ID');
      }
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRoomContacts(
        organizationSlug ?? '',
        dealRoom.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: Boolean(dealRoom?.id && organizationSlug),
    },
  );

  // Data fetching hooks - Upcoming Events
  const {
    data: eventsData,
    isLoading: eventsLoading,
    refetch: refetchEvents,
    isError: eventsErrored,
    isFetching: eventsFetching,
    isRefetching: eventsRefetching,
    error: eventsError,
  } = useQuery(
    [
      queryKey(organizationSlug ?? ''),
      refreshKey,
      searchString,
      selectedDate?.startOf('day').toISO(),
      minDate?.startOf('day').toISO(),
      maxDate?.endOf('day').toISO(),
    ],
    async () => {
      if (!organizationSlug) {
        throw new Error('No organization slug available');
      }
      const token = await getAccessTokenSilently();

      // Determine date range based on provided parameters
      let minDateISO: string | undefined;
      let maxDateISO: string | undefined;

      if (selectedDate) {
        // Use week-based range if selectedDate is provided
        minDateISO = selectedDate.startOf('week').startOf('day').toISO() || undefined;
        maxDateISO = selectedDate.plus({ days: 7 }).endOf('day').toISO() || undefined;
      } else if (minDate || maxDate) {
        // Use explicit date range if provided
        minDateISO = minDate?.startOf('day').toISO() || undefined;
        maxDateISO = maxDate?.endOf('day').toISO() || undefined;
      }

      return EventsApiClient.listEvents(
        {
          organizationSlug,
          q: searchString || undefined,
          minDate: minDateISO,
          maxDate: maxDateISO,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      refetchInterval: Duration.fromObject({ minutes: 15 }).as('milliseconds'),
      staleTime: 0, // Consider data immediately stale
      cacheTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
      refetchOnMount: 'always', // Always refetch on mount
      refetchOnWindowFocus: true,
      retry: 1,
      enabled: Boolean(organizationSlug),
    },
  );

  // We need to query for artifacts so that we can check in an event already has a related meetingflow in the decision site.
  const { data: allArtifactsData } = useQuery(
    ['artifacts-analytics', dealRoom?.id, organizationSlug],
    async () => {
      const token = await getAccessTokenSilently();
      if (!organizationSlug || !dealRoom?.id) {
        throw new Error('Missing required parameters');
      }
      const slug = organizationSlug;
      const id = dealRoom.id;

      return DealRoomsApiClient.listArtifacts(
        {
          organizationSlug: slug,
          dealRoomId: id,
          includeDeleted: true,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: Boolean(organizationSlug) && Boolean(dealRoom?.id),
    },
  );

  const meetingflowArtifacts = useMemo(() => {
    const artifacts = allArtifactsData?.data ?? [];
    return artifacts.filter((artifact) => artifact.type === 'MEETINGFLOW');
  }, [allArtifactsData]);

  // Check if a calendar event has a related meetingflow in the decision site
  const isEventInDecisionSite = (event: CalendarEvent) => {
    return meetingflowArtifacts.some((artifact) => {
      return artifact.meetingflowId === event.meetingplanId;
    });
  };

  // Memoized data transformations
  const events: CalendarEvent[] = useMemo(() => {
    if (!eventsData?.data) {
      return [];
    }

    let filteredEvents: CalendarEvent[] = eventsData.data;

    // Only filter by selectedDate if it's provided
    if (selectedDate) {
      filteredEvents = filteredEvents.filter(
        (event) =>
          DateTime.fromISO(event?.startTime).startOf('day') >=
          selectedDate.startOf('day'),
      );
    }

    // Filter for events with meetingflow if requested
    if (hasMeetingflow) {
      filteredEvents = filteredEvents.filter((event) => Boolean(event.meetingplanId));
    }

    if (!showInternal) {
      filteredEvents = filteredEvents.filter((event) => {
        return (
          (event.organizer?.emailDomain &&
            !internalDomains.includes(event.organizer.emailDomain)) ||
          event.attendees?.some(
            (attendee) =>
              attendee.emailDomain &&
              !internalDomains.includes(attendee.emailDomain),
          )
        );
      });
    }

    return filteredEvents;
  }, [eventsData?.data, selectedDate, showInternal, internalDomains, hasMeetingflow]);

  return {
    // Data
    events,
    eventsData,
    dealRoomContactsData,

    // Loading states
    loading:
      !events.length && (eventsLoading || eventsFetching || orgIsLoading),
    isLoading: eventsLoading || eventsFetching || orgIsLoading,
    isFetching: eventsFetching,
    isRefetching: eventsRefetching,
    isError: eventsErrored,

    // Errors
    eventsError,
    dealRoomContactsError,

    // Actions
    refetchEvents,
    refetchContacts,
    getAccessTokenWithPopup,
    isEventInDecisionSite,

    // MeetingflowArtifacts
    meetingflowArtifacts,
  };
};

export default useUserCalendar;
