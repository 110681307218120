import {
  humanizeDateTime,
  HumanizeDateTimeOptions,
} from '@meetingflow/common/DateHelpers';
import { DateTime } from 'luxon';
import React, { CSSProperties } from 'react';

type Props = {
  dateTime?: string | DateTime | null;
  style?: CSSProperties;
  className?: string;
  title?: string;
  includeTimeZone?: boolean;
  shortMonths?: boolean;
} & HumanizeDateTimeOptions;

const StyledDateTime = React.memo(
  ({ style, className, title, dateTime, includeTimeZone, shortMonths, ...rest }: Props) => (
    <span className={className} style={style} title={title}>
      {humanizeDateTime(dateTime, {
        ...rest,
        dateFormatOptions: {
          ...rest.dateFormatOptions,
          month: shortMonths ? 'short' : 'long',
          day: 'numeric',
          year: 'numeric'
        },
      })}
      {includeTimeZone ? <> {DateTime.local().offsetNameShort} </> : null}
    </span>
  ),
);

export default StyledDateTime;
