import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useStore from '../../../../Stores/userSettingsStore';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { mergeStyles, PrimaryButton } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

import { UpdateContactPayload } from '@meetingflow/common/Api/data-contracts';

import { useUserProfile } from '../../../../Hooks/useProfile';
import { useAuth0 } from '@auth0/auth0-react';
import { useTitle } from '../../../../Hooks/useTitle';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../Hooks/useOrganization';
import {
  DealRoomsApiClient,
  UserApiClient,
} from '../../../../Services/NetworkCommon';

import {
  colorType as illustrationsColorType,
  CheckmarkSettingsIllustrations,
  BuyerPrefSettingsIllustrations,
  InviteSettingsIllustrations,
  ProfileSettingsIllustrations,
} from './UserSettingsIllustrations';

import { UserSettingsProfile } from './UserSettingsProfile';
import { UserSettingsBuyerPref } from './UserSettingsBuyerPref';
import { UserDetails } from '../../../../types/BuyerOrientationSurveyTypes';
import { optionValuesThatNeedPhoneNumber } from '../BuyerOrientationSurvey/buyerOrientationSurveyUtils';
import { UserSettingsInvite } from './UserSettingsInvite/UserSettingsInvite';
import useDeviceType from '../../../../Hooks/deviceDetection';

interface UserSettingsWrapperProps {
  organizationSlug: string;
  dealRoomId: number;
  refetchDealRoom: () => unknown | Promise<unknown>;
  settingGroup?: keyof UserSettingsGroupsType;
}

interface UserSettingsGroupElemType {
  value: keyof UserSettingsGroupsType;
  label: string;
  icon: (color: illustrationsColorType) => JSX.Element; //React.FC<IconProps>;
}

interface UserSettingsGroupsType {
  profile: UserSettingsGroupElemType;
  buyerPrefSettings: UserSettingsGroupElemType;
  invite: UserSettingsGroupElemType;
}

export const UserSettingsGroups: UserSettingsGroupsType = {
  profile: {
    value: 'profile',
    label: 'Profile',
    icon: (color: illustrationsColorType) => (
      <ProfileSettingsIllustrations color={color} />
    ),
  },
  buyerPrefSettings: {
    value: 'buyerPrefSettings',
    label: 'Preferences',
    icon: (color: illustrationsColorType) => (
      <BuyerPrefSettingsIllustrations color={color} />
    ),
  },
  invite: {
    value: 'invite',
    label: 'Invite Someone',
    icon: (color: illustrationsColorType) => (
      <InviteSettingsIllustrations color={color} />
    ),
  },
};

export const UserSettingsGroupsIterable: UserSettingsGroupElemType[] =
  Object.keys(UserSettingsGroups).map(
    (key: string): UserSettingsGroupElemType =>
      UserSettingsGroups[key as keyof UserSettingsGroupsType],
  );

const phonePattern = /^\+?(\d{1,3})?[-.\s]?\(?\d+\)?[-.\s]?\d+([-.\s]?\d+)*$/;

export const UserSettingsWrapper = ({
  organizationSlug,
  dealRoomId,
  refetchDealRoom,
  settingGroup,
}: UserSettingsWrapperProps) => {
  const { isMobile } = useDeviceType();

  const buttonsListRef = useRef<HTMLDivElement>(null);

  const { user, getAccessTokenSilently } = useAuth0();
  const [queryParams, setQueryParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    user: profileData,
    updateUserProfileAsync,
    refetch: refetchProfile,
  } = useUserProfile();

  useTitle('Settings');

  const { role: orgRole } = useOrganization();

  const { dealRoom, refetch: refetchDealRoomData } = useDealRoom(
    organizationSlug,
    dealRoomId,
  );

  const currentUserDetails = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return null;
    return (
      dealRoom?.contacts?.find(
        (contact) =>
          contact.email.toLowerCase() === profileData?.email.toLowerCase(),
      ) || null
    );
  }, [dealRoom?.contacts, profileData?.email]);

  const userRole = useMemo(
    () => currentUserDetails?.role || null,
    [currentUserDetails],
  );

  const isSaving = useStore((state) => state.isLoading);
  const setIsSaving = useStore((state) => state.setIsLoading);

  const resetSettings = useStore((state) => state.resetAllSettings);

  const selectedAvatar = useStore((state) => state.selectedAvatar);
  const setSelectedAvatar = useStore((state) => state.setSelectedAvatar);
  const setUploadedImgAsPath = useStore((state) => state.setUploadedImgAsPath);

  const userProfileDataState = useStore((state) => state.userProfileData);
  const resetUserProfileStateData = useStore(
    (state) => state.resetUserProfileData,
  );

  const buyerPrefsState = useStore((state) => state.buyerPrefs);
  const resetBuyerPrefsState = useStore((state) => state.resetBuyerPrefs);

  const [selectedSettingsGroup, setSelectedSettingsGroup] = useState<
    keyof UserSettingsGroupsType
  >(settingGroup || 'profile');

  const prevSelectedSettingsGroup = useRef<keyof UserSettingsGroupsType | null>(
    null,
  );

  useEffect(() => {
    const group = queryParams.get('group') as keyof UserSettingsGroupsType;
    if (group && group !== selectedSettingsGroup) {
      setSelectedSettingsGroup(group);
    } else if (
      !group &&
      prevSelectedSettingsGroup.current !== selectedSettingsGroup
    ) {
      queryParams.set('group', selectedSettingsGroup);
      setQueryParams(queryParams);
    }
    prevSelectedSettingsGroup.current = selectedSettingsGroup;
  }, [queryParams, selectedSettingsGroup, setQueryParams]);

  useEffect(() => {
    return resetSettings;
  }, [resetSettings]);

  // Check if an element is fully visible within a container
  const isElementFullyVisibleInContainer = useCallback(
    (element: Element, container: HTMLDivElement) => {
      const elementRect = element.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      // If the element or container does not exist, return true and do not scroll
      if (!elementRect || !containerRect) return true;

      // Check if all edges of the element are within the container
      return (
        elementRect.top >= containerRect.top &&
        elementRect.left >= containerRect.left &&
        elementRect.bottom <= containerRect.bottom &&
        elementRect.right <= containerRect.right
      );
    },
    [],
  );

  // Scroll to a specific button in the settings group
  const handleScrollToSpecificButton = useCallback(
    (buttonIndex: number) => {
      // Buttons list container
      const container = buttonsListRef?.current;
      if (!container) return;

      // Target button
      const element = container.children[buttonIndex];
      if (!element) return;

      // If the element is already fully visible, no need to scroll
      if (isElementFullyVisibleInContainer(element, container)) return;

      // Calculate the offset and scroll smoothly to the element
      const containerRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      const offsetLeft =
        elementRect.left - containerRect.left + container.scrollLeft;
      container.scrollTo({ left: offsetLeft, behavior: 'smooth' });
    },
    [isElementFullyVisibleInContainer],
  );

  // Handle scrolling to the correct button based on the current group on mobile
  useEffect(() => {
    // Only apply this effect on mobile devices
    if (!isMobile) return;

    // Add a small delay to ensure DOM is ready
    const timeout = setTimeout(() => {
      const currentGroup = queryParams?.get('group');
      // Determine which button to scroll to based on the current group
      switch (currentGroup) {
        case UserSettingsGroups.profile.value:
          handleScrollToSpecificButton(0);
          break;
        case UserSettingsGroups.buyerPrefSettings.value:
          handleScrollToSpecificButton(1);
          break;
        case UserSettingsGroups.invite.value:
          handleScrollToSpecificButton(2);
          break;
        default:
          break;
      }
      clearTimeout(timeout);
    }, 100);
  }, [handleScrollToSpecificButton, isMobile, queryParams]);

  const renderGroup = useMemo(() => {
    switch (selectedSettingsGroup) {
      case UserSettingsGroups.profile.value:
        return (
          <UserSettingsProfile
            orgRole={orgRole}
            profileData={profileData}
            setSelectedAvatar={setSelectedAvatar}
            isSaving={isSaving}
            setUploadedImgAsPath={setUploadedImgAsPath}
            userProfileDataState={userProfileDataState}
          />
        );
      case UserSettingsGroups.buyerPrefSettings.value:
        return (
          <UserSettingsBuyerPref
            isSaving={isSaving}
            currentUserDetails={currentUserDetails as UserDetails}
            refetchDealRoom={refetchDealRoom}
            buyerPrefsState={
              buyerPrefsState as { [key: string]: string | null }
            }
          />
        );
      case UserSettingsGroups.invite.value:
        return (
          <UserSettingsInvite
            isSaving={isSaving}
            setIsSaving={setIsSaving}
            organizationSlug={organizationSlug}
          />
        );
      default:
        return null;
    }
  }, [
    orgRole,
    organizationSlug,
    profileData,
    selectedSettingsGroup,
    setSelectedAvatar,
    isSaving,
    setIsSaving,
    setUploadedImgAsPath,
    userProfileDataState,
    currentUserDetails,
    buyerPrefsState,
    refetchDealRoom,
  ]);

  const wrapperStyle = mergeStyles({
    backgroundColor: DEALROOMS_COLORS.neutralGray,
    border: `1px solid${DEALROOMS_COLORS.neutralLight}`,
    padding: isMobile ? '1rem' : '1.5rem',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    '.settings_group_btns': {
      width: isMobile ? '100%' : '11rem',
      maxWidth: isMobile ? '100%' : '11rem',
      padding: isMobile ? '0 0 1rem' : '0 1.5rem 0 0',
      display: 'flex',
      flexDirection: isMobile ? 'row' : 'column',
      containerType: 'inline-size',
      ...(isMobile
        ? {
            overflow: 'auto',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            'scrollbar-width': 'none',
          }
        : {}),
      '.settings_group_content': {
        display: 'flex',
        flexDirection: 'row',
        verticalAlign: 'middle',
        alignItems: 'flex-start',
        justifyContent: 'start',
        padding: '0.6rem 0.5rem',
        position: 'relative',
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        width: isMobile ? 'auto' : '100%',
        minWidth: isMobile ? 'fit-content' : 'auto',
        outline: 'none',
        ':hover': {
          backgroundColor: DEALROOMS_COLORS.inputLightGray,
          color: DEALROOMS_COLORS.darkerGray,
        },
        ':focus-visible': {
          border: '1px solid ' + DEALROOMS_COLORS.buttonBorder,
          outline: 'none',
        },
        '::after': {
          content: '',
          outline: 'none',
        },
        '::before': {
          content: '',
          outline: 'none',
        },
        '.settings_group_label': {
          padding: '0 0 0 0.3rem',
          color: DEALROOMS_COLORS.themeSecondary,
        },
        '.settings_group_label_active': {
          color: DEALROOMS_COLORS.darkerGray,
          fontWeight: 600,
        },
      },
      '.settings_group_content_active': {
        ...(isMobile
          ? {
              '::before': {
                content: '""',
                position: 'absolute',
                top: '100%',
                left: '0',
                backgroundColor: DEALROOMS_COLORS.notificationBubble,
                width: '100%',
                height: '2px',
                borderRadius: '1rem',
                outline: 'none',
              },
            }
          : {
              '::before': {
                content: '""',
                position: 'absolute',
                top: '30%',
                left: '0',
                backgroundColor: DEALROOMS_COLORS.notificationBubble,
                width: '0.2rem',
                height: '40%',
                borderRadius: '1rem',
                outline: 'none',
              },
            }),
      },
    },
    '.settings_section_content': {
      flex: '1',
      backgroundColor: DEALROOMS_COLORS.white,
      border: `1px solid${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '0.25rem',
      padding: isMobile ? '1rem .5rem 1rem .5rem' : '1rem 1.5rem 1.5rem 1.5rem',
      '>div:nth-child(1)': {
        // Group settings title
        color: DEALROOMS_COLORS.themePrimary,
        fontSize: '1.5rem',
        padding: '0 0 1.5rem 0',
      },
      '.saveChangesBtn': {
        backgroundColor: DEALROOMS_COLORS.themePrimary,
        color: DEALROOMS_COLORS.white,
        width: 'content',
        borderRadius: '0.25rem',
        margin: '1.5rem 0 0 0',
        fontSize: '0.85rem',
        padding: '0.3rem 1rem 0.3rem 0.7rem',
        ':hover': {
          backgroundColor: DEALROOMS_COLORS.neutralDarker,
        },
        svg: {
          margin: '0 0.3rem 0 0',
        },
        ':disabled': {
          backgroundColor: DEALROOMS_COLORS.sidebarTextSecondary,
        },
      },
    },
  });

  const handleUploadAvatar = useCallback(async () => {
    if (!selectedAvatar) return null;

    const token = await getAccessTokenSilently();

    try {
      return await UserApiClient.uploadAvatar(
        {
          file: selectedAvatar,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (e) {
      return null;
    }
  }, [getAccessTokenSilently, selectedAvatar]);

  const handleUpdateUserProfileData = useCallback(async () => {
    if (userProfileDataState.linkedinUrl?.length == 0) { 
      userProfileDataState.linkedinUrl = null; 
    }

    if (userProfileDataState.schedulingUrl?.length == 0) { 
      userProfileDataState.schedulingUrl = null; 
    }

    if (userProfileDataState.phoneNumber?.length == 0) { 
      userProfileDataState.phoneNumber = null; 
    }

    if (!Object.keys(userProfileDataState).length) return null;

    try {
      return await updateUserProfileAsync(userProfileDataState);
    } catch (e) {
      return null;
    }
  }, [updateUserProfileAsync, userProfileDataState]);

  const handleUpdateBuyerPrefs = useCallback(async () => {
    if (
      !organizationSlug ||
      !dealRoomId ||
      !profileData?.email ||
      !userRole ||
      !Object.keys(buyerPrefsState).length
    ) {
      return null;
    }

    try {
      const token = await getAccessTokenSilently();

      const newBuyerPrefsState = {
        ...buyerPrefsState,
      };

      // Don't save empty phone number
      if ('phoneNumber' in buyerPrefsState && !buyerPrefsState.phoneNumber) {
        delete newBuyerPrefsState.phoneNumber;
      }

      if (
        buyerPrefsState.phoneNumber &&
        !phonePattern.test(buyerPrefsState.phoneNumber)
      ) {
        toast.error('Phone number is not correct');
      }

      const response = await DealRoomsApiClient.updateContact(
        organizationSlug,
        dealRoomId,
        profileData.email,
        {
          role: userRole,
          ...newBuyerPrefsState,
        } as UpdateContactPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      toast.success('Preferences successfully updated.');
      return response;
    } catch (e) {
      toast.error(
        'Something went wrong setting preferences. Please try again later',
      );
      return null;
    }
  }, [
    buyerPrefsState,
    dealRoomId,
    getAccessTokenSilently,
    organizationSlug,
    profileData?.email,
    userRole,
  ]);

  // disable save button if:
  // - no avatar is being uploaded
  // - no user profile data has been changed
  // - no buyer preferences have been changed
  // - if user tries to save an empty phone number in buyer preferences
  const disableSaveSettings =
    !isSaving &&
    !selectedAvatar &&
    !Object.keys(userProfileDataState).length &&
    (!Object.keys(buyerPrefsState).length ||
      // disable if user tries to save an empty phone number
      (optionValuesThatNeedPhoneNumber.includes(
        buyerPrefsState?.communicationPreference || '',
      ) &&
        (('phoneNumber' in buyerPrefsState &&
          // if phone number exists, make sure it's valid
          (!buyerPrefsState.phoneNumber ||
            !phonePattern.test(buyerPrefsState.phoneNumber))) ||
          // if phone number doesn't exist, don't allow saving
          !('phoneNumber' in buyerPrefsState))));

  const handleSaveSettings = async () => {
    if (disableSaveSettings) return;
    setIsSaving(true);
    const handleUploadAvatarResponse = await handleUploadAvatar();
    if (handleUploadAvatarResponse !== null) {
      setSelectedAvatar(null);
      setUploadedImgAsPath(null);
    }

    const handleUpdateUserProfileDataResponse =
      await handleUpdateUserProfileData();
    if (handleUpdateUserProfileDataResponse !== null) {
      // if firstName is updated, we need to refetch the dealroom data
      // in order to update the dealroom owner name from navbar contact button
      if (
        'firstName' in userProfileDataState ||
        'schedulingUrl' in userProfileDataState ||
        'linkedinUrl' in userProfileDataState ||
        'phoneNumber' in userProfileDataState
      ) {
        await refetchDealRoomData();
      }

      resetUserProfileStateData();
    }

    await refetchProfile();

    const handleUpdateBuyerPrefsResponse = await handleUpdateBuyerPrefs();
    if (handleUpdateBuyerPrefsResponse !== null) {
      if (refetchDealRoomData) {
        await refetchDealRoomData();
      }
      // after updating buyer prefs, reset the store data
      resetBuyerPrefsState();
    }

    setIsSaving(false);
  };

  return (
    <div className={wrapperStyle}>
      <div className="settings_group_btns" ref={buttonsListRef}>
        {UserSettingsGroupsIterable.map((group, index) => (
          <PrimaryButton
            key={`settings_group_${index}`}
            className={classNames('settings_group_content', {
              ['settings_group_content_active']:
                group.value === selectedSettingsGroup,
            })}
            onClick={() => {
              setSelectedSettingsGroup(group.value);
              queryParams.set('group', group.value);
              navigate({
                pathname: location.pathname,
                search: queryParams.toString(),
              });
            }}
          >
            {group.icon(
              group.value === selectedSettingsGroup
                ? DEALROOMS_COLORS.notificationBubble
                : null,
            )}
            <div
              className={classNames('settings_group_label', {
                ['settings_group_label_active']:
                  group.value === selectedSettingsGroup,
              })}
            >
              {group.label}
            </div>
          </PrimaryButton>
        ))}
      </div>
      {selectedSettingsGroup !== UserSettingsGroups.invite.value && (
        <div className="settings_section_content">
          <div>{UserSettingsGroups[selectedSettingsGroup].label}</div>
          {renderGroup}
          <PrimaryButton
            className="saveChangesBtn"
            onClick={handleSaveSettings}
            disabled={disableSaveSettings || isSaving}
          >
            <CheckmarkSettingsIllustrations />
            Save Changes
          </PrimaryButton>
        </div>
      )}
      {selectedSettingsGroup === UserSettingsGroups.invite.value && renderGroup}
    </div>
  );
};
