import {
  DocumentCard,
  DocumentCardActivity,
  DocumentCardTitle,
  FontSizes,
  FontWeights,
  mergeStyleSets,
} from '@fluentui/react';
import {
  DealRoomArtifact,
  DealRoomArtifactBase,
} from '@meetingflow/common/Api/data-contracts';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { PropsWithChildren } from 'react';
import { useShareDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import { useConfirmationDialog } from '../../../Hooks/Modals/useConfirmationDialog';
import { useUserProfile } from '../../../Hooks/useProfile';
import classNames from 'classnames';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useQueryClient } from 'react-query';
import { ArtifactsTableActions } from './ArtifactsTableActions';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';

export type BaseArtifactCardProps = Pick<
  DealRoomArtifactBase,
  | 'id'
  | 'createdAt'
  | 'label'
  | 'name'
  | 'description'
  | 'downvoteCount'
  | 'downvoted'
  | 'upvoteCount'
  | 'upvoted'
  | 'creator'
  | 'type'
  | 'deletedAt'
  | 'updatedAt'
  | 'featuredAt'
> & {
  className?: string;
  orgSlug: string;
  dealRoomId: number;
  viewCount?: number;
  refreshDealRoom: () => Promise<unknown>;
  onClick?: (artifactId: DealRoomArtifact['id']) => void;
  onDelete: (artifactId: number) => void;
};

export const BaseArtifactCard = ({
  className,
  id,
  createdAt,
  deletedAt,
  updatedAt,
  featuredAt,
  creator,
  type,
  name,
  description,
  label,
  downvoted,
  downvoteCount,
  upvoted,
  upvoteCount,
  children,
  dealRoomId,
  orgSlug,
  viewCount,
  refreshDealRoom,
  onClick,
  onDelete,
}: PropsWithChildren<BaseArtifactCardProps>) => {
  const { userId } = useUserProfile();
  const organizationSlug = useOrganizationSlug();
  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);
  const client = useQueryClient();

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete Artifact?`,
    subText:
      'Deleting this Artifact will delete it for all users and cannot be undone. Are you sure you want to delete this Artifact?',
    primaryAction: 'CANCEL',
  });

  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug: orgSlug,
    artifactName: name,
  });

  const ownerId = creator?.id;

  const baseArtifactCardStyles = mergeStyleSets({
    root: {
      border: `1px solid ${DEALROOMS_COLORS.financialLightGray}`,
      borderRadius: '0.25rem',
      transition: '.3s ease-in-out all',
      padding: '.5rem 1rem .5rem 1rem',
      gap: '.5rem',
      display: 'flex',
      flexDirection: 'column',

      '.header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '.5rem',
        height: 'auto',
        position: 'relative',
      },

      '.artifact-type-tag': {
        backgroundColor: DEALROOMS_COLORS.financialLighterGray,
        color: DEALROOMS_COLORS.themePrimary,
        padding: '0.25rem 0.5rem',
        borderRadius: '0.25rem',
        fontSize: FontSizes.xSmall,
        fontWeight: FontWeights.regular,
        marginBottom: '0.5rem',
        width: 'fit-content',
        margin: 0,
      },

      '.artifact-actions-button': {
        display: 'flex',
        gap: '.5rem',
        position: 'absolute',
        right: '-.5rem',
        top: 0,
      },

      '.ms-DocumentCardTitle': {
        fontSize: '1rem',
        fontWeight: FontWeights.regular,
        margin: '0',
        padding: 0,
        height: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },

      '.preview': {
        width: '100%',
        aspectRatio: '16/9',
        overflow: 'hidden',
        border: `1px solid ${DEALROOMS_COLORS.financialLightGray}`,
        backgroundColor: DEALROOMS_COLORS.financialLighterGray,
        borderRadius: '0.25rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '> *': {
          width: '100%',
          height: '100%',
        },
      },

      '.activity': {
        padding: '.5rem .5rem',
      },

      selectors: {
        '&:hover': {
          '::after': {
            inset: 'unset',
            border: 'none !important',
          },
        },
      },
    },
  });

  return (
    <>
      <DocumentCard
        className={classNames('artifact', className)}
        onClick={onClick ? () => onClick(id) : undefined}
        styles={baseArtifactCardStyles}
      >
        <div className="header">
          <div className="artifact-type-tag">{type}</div>
          <div className="artifact-actions-button">
            <ArtifactsTableActions
              userId={userId}
              dealRoomRole={dealRoomRole}
              organizationSlug={orgSlug}
              dealRoomId={dealRoomId}
              refreshDealRoom={refreshDealRoom}
              onDelete={async (artifactId) => {}}
              refetchArtifacts={async () =>
                await client.refetchQueries({
                  queryKey: ['artifacts', dealRoomId, orgSlug],
                })
              }
              itemData={{
                artifactId: id,
                sharedOn: createdAt,
                creator,
                description: description,
                owner: creator?.name || '',
                lastModified: updatedAt,
                type,
                name,
                downvoted,
                downvotes: downvoteCount,
                upvoted,
                mimeType: '',
                upvotes: upvoteCount,
                deletedAt,
                featured: !!featuredAt,
                key: id,
                updatedAt,
                featuredAt,
              }}
            />
          </div>
        </div>
        <DocumentCardTitle className="title" title={label || name} />
        <div className={'preview'}>{children}</div>
        <DocumentCardActivity
          className="activity"
          activity={`Created ${humanizeDateTime(createdAt, { displayComponents: ['date'] })}`}
          people={
            creator && (!!creator.avatarFileUrl || !!creator.avatarUrl)
              ? [
                  {
                    name: creator.name ?? creator.email,
                    profileImageSrc: (creator.avatarFileUrl ||
                      creator.avatarUrl)!,
                  },
                ]
              : []
          }
        />
      </DocumentCard>
      {confirmDeleteDialog}
      {confirmShareDialog}
    </>
  );
};
