import { Container, Embed } from './DSYouTubeEmbed.styles';

/**
 * Features that can be enabled for the YouTube embed
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#allow
 */
export type YouTubeEmbedFeature =
  | 'accelerometer'
  | 'autoplay'
  | 'clipboard-write'
  | 'encrypted-media'
  | 'gyroscope'
  | 'picture-in-picture';

export const DEFAULT_YOUTUBE_FEATURES: YouTubeEmbedFeature[] = [
  'accelerometer',
  'autoplay',
  'clipboard-write',
  'encrypted-media',
  'gyroscope',
  'picture-in-picture',
];

export interface DSYouTubeEmbedProps {
  /**
   * The YouTube video ID
   */
  videoId: string;
  /**
   * Title for the iframe (for accessibility)
   */
  title: string;
  /**
   * Whether to show related videos from other channels at the end
   * @default false
   */
  showRelatedVideos?: boolean;
  /**
   * Whether to show YouTube branding on the player
   * @default false
   */
  showBranding?: boolean;
  /**
   * Whether to enable the YouTube IFrame API
   * @default true
   */
  enableApi?: boolean;
  /**
   * List of features to enable for the embed
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#allow
   * @default DEFAULT_YOUTUBE_FEATURES
   */
  allowedFeatures?: YouTubeEmbedFeature[];
}

/**
 * A responsive YouTube video embed component that maintains 16:9 aspect ratio
 */
export const DSYouTubeEmbed = ({
  videoId,
  title,
  showRelatedVideos = false,
  showBranding = false,
  enableApi = true,
  allowedFeatures = DEFAULT_YOUTUBE_FEATURES,
}: DSYouTubeEmbedProps) => {
  const params = new URLSearchParams({
    // rel: Controls related videos shown at the end of playback
    // '0': Only show related videos from the same channel
    // '1': Show related videos from all channels (YouTube default)
    rel: showRelatedVideos ? '1' : '0',

    // modestbranding: Controls YouTube branding on the player
    // '1': Hide YouTube logo in the control bar (shows smaller YouTube text label)
    // '0': Show full YouTube logo in the control bar
    // Note: The parameter value is inverted from the prop for YouTube's API
    modestbranding: showBranding ? '0' : '1',

    // enablejsapi: Enables the YouTube IFrame Player API
    // '1': Enable JavaScript API for custom player control and events
    // '0': Disable JavaScript API
    // Required for features like custom play/pause, tracking player state, etc.
    enablejsapi: enableApi ? '1' : '0',
  });

  return (
    <Container>
      <Embed
        src={`https://www.youtube.com/embed/${videoId}?${params.toString()}`}
        title={title}
        allow={allowedFeatures.join('; ')}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </Container>
  );
};

export default DSYouTubeEmbed;
