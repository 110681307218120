/**
 * DecisionSiteMeetingTranscriptInsights is a component that displays AI-generated
 * insights from meeting transcripts. It organizes and presents key topics,
 * questions, and concerns discussed during the meeting.
 *
 * Key Features:
 * - AI-powered topic extraction and summarization
 * - Hierarchical organization of discussion points
 * - Speaker-attributed questions and concerns
 * - Participant engagement statistics
 * - Visual participant cards with metrics
 * - Dynamic content loading states
 * - Responsive layout design
 */

import React from 'react';
import { DetailedMeetingflow } from '@meetingflow/common/Api/data-contracts';
import { Stack, Typography, Box } from '@mui/material';
import CallRecordingParticipantCard from '../../../../Components/MeetingPlans/CallRecordingParticipantCard';
import { useMeetingflow } from './hooks/useMeetingflow';
import { useMeetingflowSpeakers } from './hooks/useMeetingflowSpeakers';
import { useMeetingflowStats } from './hooks/useMeetingflowStats';
import { mergeStyles } from '@fluentui/react';
import DecisionSiteCallRecordingParticipantCard from './DecisionSiteMeetingPanelParticipantCard';

/**
 * Props interface for DecisionSiteMeetingTranscriptInsights
 * @property meetingflowId - Unique identifier for the meeting session
 */
interface DecisionSiteMeetingTranscriptInsightsProps {
  meetingflowId: string;
}

/**
 * Interface representing a discussion topic with its details
 * @property timestamp - When the topic was discussed
 * @property title - Main topic heading
 * @property summary - Concise topic overview
 * @property questions - List of questions raised during discussion
 * @property concerns - List of concerns identified in the conversation
 */
interface Topic {
  timestamp: number;
  title: string;
  summary: string;
  questions?: Array<{
    timestamp: number;
    speaker?: string;
    question: string;
  }>;
  concerns?: Array<{
    timestamp: number;
    speaker?: string;
    concern: string;
  }>;
}

/**
 * Interface for the complete transcript analysis structure
 * Contains an array of analyzed topics from the meeting
 */
interface TranscriptAnalysis {
  topics: Topic[];
}

/**
 * Component that renders AI-analyzed insights from meeting transcripts
 * Organizes and displays:
 * - Participant statistics and engagement
 * - Key discussion topics and summaries
 * - Questions raised by participants
 * - Concerns identified in discussions
 */
export const DecisionSiteMeetingTranscriptInsights: React.FC<
  DecisionSiteMeetingTranscriptInsightsProps
> = ({ meetingflowId }) => {
  /**
   * Custom hooks for data management
   * - meetingPlanData: Core meeting information and recording details
   * - Speaker management: Participant tracking and identification
   * - Statistics: Engagement metrics and participation data
   */
  const { meetingflow, meetingflowLoading } = useMeetingflow(meetingflowId);
  const transcriptAnalysis: TranscriptAnalysis | null | undefined =
    meetingflow?.callRecording?.transcriptAnalysis;

  const { getContactForParticipant, getColorForSpeaker, speakerNames } =
    useMeetingflowSpeakers({
      transcript: meetingflow?.callRecording?.transcript || [],
      attendees: meetingflow?.attendees,
    });

  const { callStats, turns } = useMeetingflowStats({
    transcript: meetingflow?.callRecording?.transcript || [],
  });

  /**
   * Loading state handler
   * Displays a placeholder while transcript analysis is being fetched
   */
  if (meetingflowLoading) {
    return (
      <Box p={2}>
        <Typography>Loading transcript analysis...</Typography>
      </Box>
    );
  }

  /**
   * No data state handler
   * Shows when transcript analysis is unavailable
   */
  if (!transcriptAnalysis) {
    return (
      <Box>
        <Typography>No transcript analysis available</Typography>
      </Box>
    );
  }

  /**
   * Styles for participant cards container
   * Features:
   * - Flex layout for responsive grid
   * - Consistent spacing between cards
   * - Smooth transitions for interactions
   * - Custom title formatting
   */
  const participantContainerClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: '0',
    rowGap: '.5rem',
    marginBottom: '1rem',

    '.title': {
      backgroundColor: 'transparent',
      cursor: 'default',
      transition: '.3s ease-in-out all',
      width: '5rem',
      flexGrow: 0, // Prevent the title from growing
      flexShrink: 0, // Prevent the title from shrinking
      padding: `0.25rem 0.5rem !important`,
      ':after': {
        content: '":"',
      },
    },

    '> *:not(.title)': {
      flexGrow: 1,
      minWidth: '100px', // Optional: prevent items from getting too narrow
    },
  });

  return (
    <Stack spacing={2}>
      {/* Participant Cards Section */}
      {speakerNames.length ? (
        <div className={participantContainerClass}>
          {speakerNames.map((p) => (
            <DecisionSiteCallRecordingParticipantCard
              key={`${meetingflow?.id}-participant-${p}`}
              participant={p}
              getColorForSpeaker={getColorForSpeaker}
              callStats={callStats}
            />
          ))}
        </div>
      ) : null}

      {/* Topics Section Header */}
      <Box>
        <Typography variant="h6" fontWeight="600">
          Meeting Topics
        </Typography>
      </Box>

      {/* Topics Content Section */}
      {transcriptAnalysis.topics.map((topic: Topic, topicIndex: number) => (
        <Box key={topicIndex}>
          {/* Topic Title and Overview */}
          <Typography variant="subtitle1" fontWeight="600">
            {topic.title}
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 2 }}>
            {topic.summary}
          </Typography>

          {/* Questions Subsection */}
          {topic.questions && topic.questions.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" fontWeight="600">
                Questions
              </Typography>
              {topic.questions.map((q, qIndex: number) => (
                <Typography key={qIndex} color="text.secondary" sx={{ ml: 2 }}>
                  • {q.speaker ? `${q.speaker}: ` : ''}
                  {q.question}
                </Typography>
              ))}
            </Box>
          )}

          {/* Concerns Subsection */}
          {topic.concerns && topic.concerns.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle2" fontWeight="600">
                Concerns
              </Typography>
              {topic.concerns.map((c, cIndex: number) => (
                <Typography key={cIndex} color="text.secondary" sx={{ ml: 2 }}>
                  • {c.speaker ? `${c.speaker}: ` : ''}
                  {c.concern}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Stack>
  );
};
