import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import useDeviceType from '../../../../Hooks/deviceDetection';
import useMilestonesActionItemsFiltersStore from '../../../../Stores/milestonesActionItemsFiltersStore';
import { useCallback, useMemo } from 'react';

type OwnerFilterProps = {
  organizationSlug: string;
  dealRoomId: number;
};

export const OwnerFilter = ({
  organizationSlug,
  dealRoomId,
}: OwnerFilterProps) => {
  const { isMobile } = useDeviceType();
  const { dealRoom } = useDealRoom(organizationSlug, dealRoomId);

  // Store values and setters from the global filter state
  const selectedOwnerEmails = useMilestonesActionItemsFiltersStore(
    (state) => state.selectedOwnerEmails,
  );
  const setSelectedOwnerEmails = useMilestonesActionItemsFiltersStore(
    (state) => state.setSelectedOwnerEmails,
  );

  // Check if 'No Owner' option is selected
  const isNoOwnerSelected = useMemo(
    () => selectedOwnerEmails.includes('no-owner'),
    [selectedOwnerEmails],
  );

  // Handle changes in owner filter selection
  const handleOwnerChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value;

      // Handle regular selection (excluding special options)
      const filteredValue = (typeof value === 'string' ? [] : value)
      setSelectedOwnerEmails(filteredValue);
    },
    [setSelectedOwnerEmails],
  );

  return (
    <FormControl
      size="small"
      sx={{ minWidth: { xs: '100%', sm: 350 }, maxWidth: 350 }}
    >
      <InputLabel id="owner-select-label">Assignee</InputLabel>
      <Select
        labelId="owner-select-label"
        id="owner-select"
        label="Assignee"
        multiple
        value={selectedOwnerEmails}
        onChange={handleOwnerChange}
        renderValue={(selected) => {
          // Filter contacts based on selected emails
          const selectedContacts =
            (dealRoom?.contacts?.filter((contact) =>
              selected.includes(contact.email),
            ) || []).map ((contact) => ({ email: contact.email, name: contact.name })) || [];

          // Add 'no-owner' as a contact if selected
          if (selected.includes('no-owner')) {
            selectedContacts.unshift({ email: 'no-owner', name: 'Unassigned' });
          }

          // Display single owner if only one is selected
          if (selectedContacts.length === 1) {
            return selectedContacts[0].name || selectedContacts[0].email;
          }

          // Display first owner name/email plus count of additional owners
          const firstContact = selectedContacts[0];
          const remainingCount = selectedContacts.length - 1;
          const displayName = firstContact.name || firstContact.email;

          return `${displayName} +${remainingCount}`;
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400,
              width: isMobile ? '90vw' : 'auto',
            },
          },
          sx: {
            '& .MuiMenuItem-root': {
              py: 0.5,
              px: 1,
              minHeight: 'unset',
            },
            '& .MuiCheckbox-root': {
              padding: '4px',
            },
          },
        }}
      >
        <MenuItem value="no-owner">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              width: '100%',
            }}
          >
            <Checkbox checked={isNoOwnerSelected} />
            <ListItemText primary="Unassigned" />
          </Box>
        </MenuItem>
        {dealRoom?.contacts?.map((contact) => (
          <MenuItem
            key={contact.email}
            value={contact.email}
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                width: '100%',
              }}
            >
              <Checkbox
                checked={selectedOwnerEmails.includes(contact.email)}
              />
              <ListItemText primary={contact.name || contact.email} />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
