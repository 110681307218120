import { DSConfigurationHeaderRow } from '../../Components/DSConfigurationHeaderRow';
import React, { useCallback, useEffect, useState } from 'react';
import { PrimaryButton, Stack } from '@fluentui/react';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { useOrganizationSlug } from '../../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../../Hooks/useDealRoomId';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-hot-toast';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { DragEndEvent } from '@dnd-kit/core';
import { DSConfigurationKeyFact } from './DSConfigurationKeyFact';
import { getKeyFactsStyles } from './DSConfigurationKeyFacts.styles';
import { DSSortableGrid } from '../../Components/DSSortableGrid';

// Define the structure for callout card form data
interface KeyFactData {
  id?: number;
  title: string;
  value: string;
  editableByBuyer: boolean;
  order: number;
}

// Define an empty callout card template
const emptyKeyFact: KeyFactData = {
  title: '',
  value: '',
  editableByBuyer: false,
  order: 0,
};

export const DSConfigurationKeyFacts: React.FC = () => {
  // State for storing callout cards
  const [keyFacts, setKeyFacts] = useState<KeyFactData[]>([]);
  // State to track if there are unsaved changes
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  // State for storing error messages
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Hooks for getting organization and deal room context
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { getAccessTokenSilently } = useAuth0();

  // load key facts cards from the API
  const loadKeyFacts = useCallback(async () => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }

    const token = await getAccessTokenSilently();

    const response = await DealRoomsApiClient.listKeyFacts(
      organizationSlug,
      dealRoomId,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    const cardsWithOrder = (response.data as unknown as KeyFactData[]).map(
      (card, index) => ({
        ...card,
        order: card.order ?? index,
      }),
    );
    const sortedCards = cardsWithOrder.sort((a, b) => a.order - b.order);
    setKeyFacts(sortedCards);
  }, [organizationSlug, dealRoomId, getAccessTokenSilently]);

  // load key facts when component mounts or dependencies change
  useEffect(() => {
    loadKeyFacts();
  }, [loadKeyFacts]);

  // add a new empty key fact
  const handleAddKeyFact = () => {
    // setKeyFacts([...keyFacts, { ...emptyKeyFact }]);
    setKeyFacts((prevCards) => {
      const newOrder =
        prevCards.length > 0
          ? Math.max(...prevCards.map((card) => card.order)) + 1
          : 0;
      return [...prevCards, { ...emptyKeyFact, order: newOrder }];
    });
    setUnsavedChanges(true);
  };

  // delete a key fact
  const handleDeleteKeyFact = async (index: number) => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }

    const token = await getAccessTokenSilently();
    const fact = keyFacts[index];

    // key fact is not saved in DB - just a temporary key fact user was creating
    // no need to show toast for this case
    if (!fact.id) {
      const newFacts = [...keyFacts];
      newFacts.splice(index, 1);
      setKeyFacts(newFacts);
      return;
    }

    // key fact was saved in the DB
    await toast.promise(
      (async () => {
        await DealRoomsApiClient.deleteKeyFact(
          organizationSlug,
          dealRoomId,
          fact.id!,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        await loadKeyFacts();
      })(),
      {
        loading: 'Deleting key fact...',
        success: 'Key fact deleted successfully',
        error: 'Failed to delete key fact',
      },
    );
  };

  // handle changes in key fact fields
  const handleKeyFactChange = (
    index: number,
    field: keyof KeyFactData,
    value: string | boolean | null,
  ) => {
    const newFacts = [...keyFacts];
    newFacts[index] = { ...newFacts[index], [field]: value };
    setKeyFacts(newFacts);
    setUnsavedChanges(true);
  };

  // save all key facts
  const handleSave = async () => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }

    // Validate that each fact has a title and description
    const invalidFacts = keyFacts.filter((fact) => {
      const hasTitle = Boolean(fact.title);
      const hasValue = Boolean(fact.value);
      return !hasTitle || !hasValue;
    });

    if (invalidFacts.length > 0) {
      setErrorMessage('Each fact must have a title and value');
      return;
    }

    const token = await getAccessTokenSilently();
    const promises = keyFacts.map(async (fact) => {
      if (fact.id) {
        await DealRoomsApiClient.updateKeyFact(
          organizationSlug,
          dealRoomId,
          fact.id,
          fact,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      } else {
        await DealRoomsApiClient.createKeyFact(
          organizationSlug,
          dealRoomId,
          fact,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      }
    });
    await toast
      .promise(Promise.all(promises), {
        loading: 'Saving key facts...',
        success: () => {
          setErrorMessage(null);
          setUnsavedChanges(false);
          return 'Key facts saved successfully';
        },
        error: (error) => {
          const message = error?.message || 'Failed to save key facts';
          setErrorMessage(message);
          return message;
        },
      })
      .then(async () => {
        await loadKeyFacts();
      });
  };

  // Handle drag end event
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) { return; }
    setKeyFacts((cards) => {
      const oldIndex = cards.findIndex(
        (card) => (card.id || card.order) === active.id,
      );
      const newIndex = cards.findIndex(
        (card) => (card.id || card.order) === over.id,
      );

      // Create new array with updated orders
      const reorderedCards = [...cards];
      const [movedCard] = reorderedCards.splice(oldIndex, 1);
      reorderedCards.splice(newIndex, 0, movedCard);

      // Update order values for all cards
      const updatedCards = reorderedCards.map((card, index) => ({
        ...card,
        order: index,
      }));

      setUnsavedChanges(true);
      return updatedCards;
    });
  };

  const styles = getKeyFactsStyles();

  return (
    <Stack tokens={{ childrenGap: 20 }} className={styles.container}>
      {/* Header with buttons */}
      <DSConfigurationHeaderRow
        title="Key Facts"
        description="Key Facts are metrics and statistics on the Overview page. They can be edited by the buyer."
      >
        <Stack
          horizontal
          tokens={{ childrenGap: 10 }}
          className={styles.headerButtons}
        >
          <PrimaryButton onClick={handleAddKeyFact} text="Add New Key Fact" />

          <PrimaryButton
            onClick={handleSave}
            text="Save Changes"
            disabled={!unsavedChanges}
          />
        </Stack>
      </DSConfigurationHeaderRow>

      {/* Error message display */}
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}

      {/* Render key facts in grid */}
      <DSSortableGrid
        items={keyFacts}
        onDragEnd={handleDragEnd}
        className={styles.factsGrid}
        renderItem={(fact, index) => (
          <DSConfigurationKeyFact
            fact={fact}
            index={index}
            onDelete={handleDeleteKeyFact}
            onChange={handleKeyFactChange}
          />
        )}
      />
    </Stack>
  );
};
