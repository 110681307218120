import { useAuth0 } from '@auth0/auth0-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { BaseRange, Descendant, Editor, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { insertMention } from '../../Components/Collab/Helpers/EditorHelpers';
import { OrganizationContactsQuery } from '../../QueryNames';
import { ApiClient, ContactsApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { useLightOrDarkMode } from '../useLightOrDarkMode';
import { Popper, Typography, Box, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';

export type UseMentionsParams = {
  organizationSlug: string;
  meetingflowId?: string;
  companyId?: number;
  contactId?: number;
  editor: Editor;
  allowMentions?: boolean;
  defaultMentionSuggestions?: {
    id: number;
    name: string | null;
    email: string;
  }[];
  useMaxZIndex?: boolean;
  removeContactsLimit?: boolean;
};

export const useMentions = ({
  organizationSlug,
  meetingflowId,
  companyId,
  contactId,
  editor,
  allowMentions,
  defaultMentionSuggestions,
  useMaxZIndex = false,
  removeContactsLimit = false,
}: UseMentionsParams) => {
  const { getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();
  const { isDark } = useLightOrDarkMode();

  const ref = useRef<HTMLDivElement>(null);
  const [target, setTarget] = useState<BaseRange | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');

  const { data: contacts, refetch: refetchSuggestions } = useQuery(
    OrganizationContactsQuery(
      organizationSlug!,
      false,
      true,
      true,
      undefined,
      search,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        {
          organizationSlug,
          q: search,
          isInternal: true,
          isInvitable: true,
          ...(removeContactsLimit
            ? {}
            : {
                limit: 5,
              }),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-meetingplan-id': meetingflowId,
          },
        },
      );
    },
    {
      enabled:
        !!allowMentions &&
        !!(meetingflowId || companyId || contactId) &&
        (!!search || !defaultMentionSuggestions?.length) &&
        !!target,
    },
  );

  useEffect(() => {
    if (
      !!allowMentions &&
      !!(meetingflowId || companyId || contactId) &&
      (!!search || !defaultMentionSuggestions?.length) &&
      !!target
    ) {
      refetchSuggestions();
    }
  }, [
    allowMentions,
    companyId,
    contactId,
    defaultMentionSuggestions?.length,
    meetingflowId,
    refetchSuggestions,
    search,
    target,
  ]);

  useEffect(() => {
    if (index < 0) {
      setIndex(0);
    }
    if (
      !search &&
      defaultMentionSuggestions?.length &&
      index >= defaultMentionSuggestions.length
    ) {
      setIndex(defaultMentionSuggestions.length - 1);
    } else if (
      search &&
      !!contacts?.data?.length &&
      index >= contacts?.data?.length
    ) {
      setIndex(contacts.data.length - 1);
    }
  }, [contacts?.data, defaultMentionSuggestions?.length, index, search]);

  const [popupPosition, setPopupPosition] = useState<{
    top: number;
    left: number;
  }>({ top: -9999, left: -9999 });

  const [virtualElement, setVirtualElement] = useState<{
    getBoundingClientRect: () => DOMRect;
  } | null>(null);

  useLayoutEffect(() => {
    if (
      target &&
      allowMentions &&
      ((!search &&
        (contacts?.data?.length || defaultMentionSuggestions?.length)) ||
        (search && contacts?.data?.length))
    ) {
      try {
        const domRange = ReactEditor.toDOMRange(editor, target);
        const rect = domRange.getBoundingClientRect();

        setVirtualElement({
          getBoundingClientRect: () => ({
            width: rect.width,
            height: 0,
            top: rect.bottom,
            right: rect.right,
            bottom: rect.bottom,
            left: rect.left,
            x: rect.left,
            y: rect.bottom,
            toJSON: () => null,
          }),
        });
      } catch (error) {
        console.error('Error creating virtual element:', error);
        setVirtualElement(null);
      }
    } else {
      setVirtualElement(null);
    }
  }, [
    allowMentions,
    contacts?.data?.length,
    defaultMentionSuggestions?.length,
    editor,
    search,
    target,
  ]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        target
      ) {
        setTarget(undefined);
        setSearch('');
        setIndex(0);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [target]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!allowMentions) {
        return;
      }

      if (
        target &&
        ((search && contacts?.data?.length) ||
          (!search && defaultMentionSuggestions?.length))
      ) {
        const suggestions = search ? contacts?.data : defaultMentionSuggestions;

        if (!suggestions) {
          return;
        }

        switch (event.key) {
          case 'ArrowDown': {
            event.preventDefault();
            const prevIndex = index >= suggestions.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            return true;
          }
          case 'ArrowUp': {
            event.preventDefault();
            const nextIndex = index <= 0 ? suggestions.length - 1 : index - 1;
            setIndex(nextIndex);
            return true;
          }
          case 'Tab':
          case 'Enter': {
            const contact = suggestions[index];

            if (!contact) {
              return;
            }

            event.preventDefault();

            Transforms.select(editor, target);
            insertMention(editor, 'contact', contact);
            setTarget(undefined);
            setSearch('');

            if (!(meetingflowId || companyId || contactId)) {
              return true;
            }

            getAccessTokenSilently().then((token) => {
              let url = '';
              if (meetingflowId) {
                url = `/organization/${organizationSlug}/plan/${meetingflowId}/mention`;
              } else if (companyId) {
                url = `/organization/${organizationSlug}/companies/${companyId}/mention`;
              } else if (contactId) {
                url = `/organization/${organizationSlug}/contact/${contactId}/mention`;
              } else {
                return;
              }

              ApiClient.post(
                url,
                { email: contact.email },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'x-meetingplan-id': meetingflowId,
                  },
                  validateStatus: (code) => [200, 204].includes(code),
                },
              ).catch((err) => {
                appInsights.trackException({
                  exception: err instanceof Error ? err : new Error(err),
                });
              });
            });
            return true;
          }
          case 'Escape':
            event.preventDefault();
            setTarget(undefined);
            setSearch('');
            setIndex(0);
            return true;
        }
      }
    },
    [
      allowMentions,
      appInsights,
      companyId,
      contactId,
      contacts?.data,
      defaultMentionSuggestions,
      editor,
      getAccessTokenSilently,
      index,
      meetingflowId,
      organizationSlug,
      search,
      target,
    ],
  );

  const onSlateChange = useCallback(
    (v: Descendant[]) => {
      if (!allowMentions) {
        return;
      }

      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection);

        // Check next character for whitespace
        const nextCharacter = Editor.after(editor, start, {
          unit: 'character',
        });
        if (
          nextCharacter &&
          !Editor.string(
            editor,
            Editor.range(editor, start, nextCharacter),
          ).match(/^\s?$/)
        ) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        // Check preceding character for @
        const precedingCharacter = Editor.before(editor, start, {
          unit: 'character',
        });
        if (!precedingCharacter) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        // Check if we just typed @
        if (
          Editor.string(
            editor,
            Editor.range(editor, precedingCharacter, start),
          ).match(/^@$/)
        ) {
          const secondPrecedingCharacter = Editor.before(
            editor,
            precedingCharacter,
          );
          if (
            !secondPrecedingCharacter ||
            Editor.string(
              editor,
              Editor.range(
                editor,
                secondPrecedingCharacter,
                precedingCharacter,
              ),
            ).match(/^\s?$/)
          ) {
            setTarget(Editor.range(editor, precedingCharacter, start));
            setSearch('');
            setIndex(0);
            return;
          }
        }

        // Check for multi-word mentions
        const precedingWord = Editor.before(editor, start, {
          unit: 'word',
        });
        if (!precedingWord) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        const characterPrecedingWord = Editor.before(editor, precedingWord, {
          unit: 'character',
        });
        if (!characterPrecedingWord) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        // Check if word is part of a mention
        if (
          Editor.string(
            editor,
            Editor.range(editor, characterPrecedingWord, precedingWord),
          ).match(/^@$/)
        ) {
          const secondCharacterPrecedingWord = Editor.before(
            editor,
            characterPrecedingWord,
            { unit: 'character' },
          );
          if (
            !secondCharacterPrecedingWord ||
            Editor.string(
              editor,
              Editor.range(
                editor,
                secondCharacterPrecedingWord,
                characterPrecedingWord,
              ),
            ).match(/^\s?$/)
          ) {
            setTarget(Editor.range(editor, characterPrecedingWord, start));
            setSearch(
              Editor.string(
                editor,
                Editor.range(editor, precedingWord, start),
              ).trim(),
            );
            setIndex(0);
            return;
          }
        }

        // Check second preceding word for multi-word mentions
        const secondPrecedingWord = Editor.before(editor, precedingWord, {
          unit: 'word',
        });
        if (!secondPrecedingWord) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        const characterPrecedingSecondWord = Editor.before(
          editor,
          secondPrecedingWord,
          { unit: 'character' },
        );
        if (!characterPrecedingSecondWord) {
          setTarget(undefined);
          setSearch('');
          setIndex(0);
          return;
        }

        // Check if second word is part of a mention
        if (
          Editor.string(
            editor,
            Editor.range(
              editor,
              characterPrecedingSecondWord,
              secondPrecedingWord,
            ),
          ).match(/^@$/)
        ) {
          const secondCharacterPrecedingSecondWord = Editor.before(
            editor,
            characterPrecedingSecondWord,
            { unit: 'character' },
          );
          if (
            !secondCharacterPrecedingSecondWord ||
            Editor.string(
              editor,
              Editor.range(
                editor,
                secondCharacterPrecedingSecondWord,
                characterPrecedingSecondWord,
              ),
            ).match(/^\s?$/)
          ) {
            setTarget(
              Editor.range(editor, characterPrecedingSecondWord, start),
            );
            setSearch(
              Editor.string(
                editor,
                Editor.range(editor, secondPrecedingWord, start),
              ).trim(),
            );
            setIndex(0);
            return;
          }
        }
      }

      setTarget(undefined);
      setSearch('');
      setIndex(0);
    },
    [allowMentions, editor],
  );

  const resetMentions = useCallback(() => {
    // reset the mentions states
    setTarget(undefined);
    setSearch('');
    setIndex(0);
  }, []);

  const StyledMentionsPopper = styled(Popper)(({ theme }) => ({
    zIndex: 1000001,
    '& .MentionsPopperPaper': {
      padding: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.grey[800]
          : theme.palette.background.paper,
      color: theme.palette.text.primary,
      maxWidth: '17rem',
      maxHeight: '60vh',
      overflowY: 'auto',
    },
    '& .MentionItem': {
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      '&.selected': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.primary.main,
      },
    },
    '& .SearchHint': {
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.action.hover,
    },
  }));

  return {
    onKeyDown,
    onSlateChange,
    resetMentions,
    mentionSuggestions:
      allowMentions && target && virtualElement ? (
        <StyledMentionsPopper
          open={true}
          anchorEl={virtualElement}
          placement="bottom-start"
          modifiers={[
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport',
                padding: 8,
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top-start'],
                padding: 8,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ]}
        >
          <Box className="MentionsPopperPaper">
            {(search
              ? contacts?.data
              : defaultMentionSuggestions || contacts?.data
            )?.map((user, i) => (
              <Box
                key={user.id}
                className={`MentionItem ${i === index ? 'selected' : ''}`}
                onClick={() => {
                  Transforms.select(editor, target);
                  insertMention(editor, 'contact', user);
                  setTarget(undefined);
                  getAccessTokenSilently()
                    .then((token) => {
                      let url = '';
                      if (meetingflowId) {
                        url = `/organization/${organizationSlug}/plan/${meetingflowId}/mention`;
                      } else if (companyId) {
                        url = `/organization/${organizationSlug}/companies/${companyId}/mention`;
                      } else if (contactId) {
                        url = `/organization/${organizationSlug}/contact/${contactId}/mention`;
                      } else {
                        return;
                      }

                      ApiClient.post(
                        url,
                        { email: user.email },
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                            'x-meetingplan-id': meetingflowId,
                          },
                          validateStatus: (code) => [200, 204].includes(code),
                        },
                      );
                    })
                    .catch((err) => {
                      appInsights.trackException({
                        exception: err instanceof Error ? err : new Error(err),
                      });
                    });
                }}
                onMouseEnter={() => {
                  if (index !== i) {
                    setIndex(i);
                  }
                }}
              >
                <Typography variant="body2" fontWeight="medium">
                  {user.name || user.email}
                </Typography>
                {user.name && (
                  <Typography variant="caption" display="block">
                    {user.email}
                  </Typography>
                )}
              </Box>
            ))}
            {!search && (
              <Box className="SearchHint">
                <Typography variant="body2">Type to filter</Typography>
              </Box>
            )}
          </Box>
        </StyledMentionsPopper>
      ) : null,
  };
};
