import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DealRoomActionItem,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { MeetingPlanTasksQuery } from '../../../../../QueryNames';
import { tryMatchAttendeesToParticipants } from '@meetingflow/common/TranscriptHelpers';
import {
  CallRecordingsApiClient,
  TasksApiClient,
} from '../../../../../Services/NetworkCommon';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../../Hooks/useOrganization';

const ALLOWED_ORG_SLUGS = ['augment-ai', 'augment-demo'];

type MeetingFlowActionItem = {
  actionItem: Partial<DealRoomActionItem>;
  meetingflowId: string;
  speaker?: string;
};

export const useDecisionSiteMeetingflowActionItems = (
  meetingflow: DetailedMeetingflow,
  refetchMeetingflow: () => Promise<unknown>,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const { dealRoom, dealRoomId } = useDealRoom();
  const { slug: organizationSlug, internalDomains } = useOrganization();
  const isAllowedOrg =
    organizationSlug && ALLOWED_ORG_SLUGS.includes(organizationSlug);

  const {
    data: tasksData,
    isLoading: tasksLoading,
    refetch: refetchTasks,
  } = useQuery(
    MeetingPlanTasksQuery(organizationSlug!, meetingflow.id),
    async () => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.listTasks(
        {
          organizationSlug: organizationSlug!,
          meetingPlanId: meetingflow.id,
          sortBy: 'createdAt',
          sortOrder: 'asc',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  );

  const extractSuggestedActionItemsFromMeetingFlow = useCallback(() => {
    const suggestedActionItems =
      meetingflow?.callRecording?.transcriptActionItems;

    if (!meetingflow || !meetingflow.callRecording || !suggestedActionItems) {
      return [];
    }

    let recordingActionItems = [...suggestedActionItems];

    const participantNames =
      meetingflow.callRecording.participants?.map((p) => p.name) ?? [];

    const matches = tryMatchAttendeesToParticipants(
      participantNames,
      meetingflow.attendees,
    );

    const internalParticipants = (participantNames || []).filter((p) => {
      const matchedAttendee = meetingflow.attendees.find(
        (a) => a.id === matches[p]?.id,
      );
      const emailDomain = matchedAttendee?.emailDomain || '';
      return internalDomains.includes(emailDomain);
    });

    // Only filter by internal participants if we have any
    if (internalParticipants.length > 0) {
      recordingActionItems = recordingActionItems.filter((item) => {
        const isInternalAssignee = item.assignee
          ? internalParticipants.some((p) =>
              item.assignee!.toLowerCase().includes(p.toLowerCase()),
            )
          : false;

        const isInternalSpeaker = internalParticipants.some((p) =>
          item.speaker.toLowerCase().includes(p.toLowerCase()),
        );
        return isInternalAssignee || isInternalSpeaker;
      });
    }

    const transformedActionItems = recordingActionItems.map(
      (t): MeetingFlowActionItem => ({
        actionItem: {
          actionItem: t.text,
          dueDate: t.deadline || DateTime.now().plus({ days: 7 }).toISO(),
          status: 'NOT_STARTED',
          assignee: null,
          id: undefined,
        },
        meetingflowId: meetingflow.id,
        speaker: t.speaker,
      }),
    );
    return transformedActionItems;
  }, [meetingflow, internalDomains]);

  const extractUserAddedActionItemsFromMeetingFlow = useCallback(() => {
    const userAddedActionItems = tasksData?.data || [];
    const transformedActionItems = userAddedActionItems.map(
      (t): MeetingFlowActionItem => ({
        actionItem: {
          actionItem: t.text,
          dueDate: DateTime.now().plus({ days: 7 }).toISO(), // Default due date for all tasks
          status: 'NOT_STARTED',
          assignee: t.assignee
            ? {
                ...t.assignee,
                timezone: 'UTC', // Default timezone since BasicContact doesn't have it
              }
            : null,
          id: -1, // Temporary ID for new items
        },
        meetingflowId: meetingflow.id,
      }),
    );
    return transformedActionItems;
  }, [tasksData, meetingflow]);

  const suggestedActionItems = extractSuggestedActionItemsFromMeetingFlow();
  const userAddedActionItems = extractUserAddedActionItemsFromMeetingFlow();

  const meetingflowArtifactId = useMemo(() => {
    return (
      dealRoom?.artifacts.find(
        (artifact) =>
          artifact.type === 'MEETINGFLOW' &&
          artifact.meetingflowId === meetingflow.id,
      )?.id ?? null
    );
  }, [meetingflow.id, dealRoom?.artifacts]);

  const mppActionItemsForMeetingflow = dealRoom?.mutualActionItems.filter(
    (item) =>
      item.artifacts?.some(
        (artifact) =>
          artifact.type === 'MEETINGFLOW' &&
          artifact.id === meetingflowArtifactId,
      ),
  );

  const mppTaskCount = mppActionItemsForMeetingflow?.length ?? 0;
  const userAddedTaskCount = userAddedActionItems?.length ?? 0;
  const aiSuggestedTaskCount = suggestedActionItems?.length ?? 0;
  const totalTaskCount =
    mppTaskCount + userAddedTaskCount + aiSuggestedTaskCount;

  const deleteSuggestedActionItem = useCallback(
    async (index: number) => {
      if (!organizationSlug) return;

      try {
        const token = await getAccessTokenSilently();
        await CallRecordingsApiClient.deleteSuggestedActionItem(
          {
            organizationSlug,
            meetingPlanId: meetingflow.id,
            index,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        // Wait for both refetches to complete
        await Promise.all([refetchMeetingflow(), refetchTasks()]);
      } catch (error) {
        console.error('Error deleting suggested action item:', error);
      }
    },
    [
      getAccessTokenSilently,
      meetingflow.id,
      organizationSlug,
      refetchMeetingflow,
      refetchTasks,
    ],
  );

  return {
    userAddedActionItems,
    suggestedActionItems,
    isLoading: false,
    mutualPlanActionItems: mppActionItemsForMeetingflow,
    mutualPlanTaskCount: mppTaskCount,
    userAddedTaskCount,
    aiSuggestedTaskCount,
    totalTaskCount,
    refetchTasks,
    deleteSuggestedActionItem,
  };
};
