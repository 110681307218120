import { ReactElement } from 'react';
import { Tooltip, type TooltipProps } from '@mui/material';
import { tooltipStyles } from './DSTooltip.styles';

export type DSTooltipProps = Omit<TooltipProps, 'children'> & {
  children: ReactElement;
};

export const DSTooltip = ({ children, ...props }: DSTooltipProps) => {
  return (
    <Tooltip
      {...props}
      arrow
      slotProps={{
        tooltip: {
          sx: tooltipStyles,
        },
      }}
    >
      <span style={{ display: 'inline-block' }}>{children}</span>
    </Tooltip>
  );
};

export default DSTooltip;
