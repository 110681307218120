import { DealRoomMilestone } from '@meetingflow/common/Api/data-contracts';
import { mergeStyleSets } from '@fluentui/react';
import DealRoomAsyncCommandBarButton, {
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import { MilestoneMoreOptions } from './MilestoneMoreOptions';
import { DSButton, DSTooltip, DSDatePicker } from '../DS';
import { MoveToInbox, AddTask } from '@mui/icons-material';
import { DateTime } from 'luxon';
import {
  HeaderContainer,
  Title,
  ButtonsContainer,
  datePickerStyles,
} from './MilestoneHeader.styles';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/useField';

type MilestoneHeaderProps = {
  milestone: DealRoomMilestone;
  organizationSlug: string;
  dealRoomId: number;
  onDueDateChange: (
    newDate: DateTime | null,
    context: FieldChangeHandlerContext<string | null>,
  ) => void;
  onRequestArtifact: () => Promise<void>;
  onAddTask: () => void;
  milestoneDueDate: string | null;
};

export const MilestoneHeader = ({
  milestone,
  organizationSlug,
  dealRoomId,
  onDueDateChange,
  onRequestArtifact,
  onAddTask,
  milestoneDueDate,
}: MilestoneHeaderProps) => {
  const handleDueDateChange = (
    newDate: DateTime | null,
    context: FieldChangeHandlerContext<string | null>,
  ) => {
    onDueDateChange(newDate, context);
  };

  return (
    <HeaderContainer>
      <Title>{milestone.type}</Title>
      <DSDatePicker
        value={milestoneDueDate ? DateTime.fromISO(milestoneDueDate) : null}
        onChange={handleDueDateChange}
        size="xsmall"
        sx={datePickerStyles}
      />
      <ButtonsContainer>
        <DSTooltip title="Request Artifact">
          <span>
            <DSButton
              onClick={onRequestArtifact}
              startIcon={<MoveToInbox />}
              iconOnly
              variant="text"
              sx={{
                '& .MuiSvgIcon-root': { color: DEALROOMS_COLORS.cloudburst },
              }}
            >
              Request Artifact
            </DSButton>
          </span>
        </DSTooltip>

        <span>
          <DSButton
            onClick={onAddTask}
            startIcon={<AddTask />}
            variant="text"
            sx={{
              '& .MuiSvgIcon-root': { color: DEALROOMS_COLORS.cloudburst },
            }}
          >
            Add Task
          </DSButton>
        </span>

        <MilestoneMoreOptions
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          milestoneType={milestone.type}
        />
      </ButtonsContainer>
    </HeaderContainer>
  );
};
