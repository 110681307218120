import { styled, Select, Typography } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DealRoomActionItemStatus } from '@meetingflow/common/Api/data-contracts';

interface StatusSelectProps {
  $backgroundColor: string;
  $textColor: string;
}

export const Container = styled('div')<{ isLastItem?: boolean }>(
  ({ isLastItem }) => ({
    container: 'milestone-item / inline-size',
    transition: 'opacity 0.2s ease-in-out',
    display: 'flex',
    gap: '0rem',
    flexDirection: 'column',
    cursor: 'pointer',
    paddingTop: '.25rem',
    paddingBottom: '.25rem',
    borderBottom: isLastItem
      ? 'none'
      : `1px solid ${DEALROOMS_COLORS.financialLightGray}70`,
  }),
);

export const Row = styled('div')({
  display: 'flex',
  gap: '.5rem',
  alignItems: 'center',
  '@container milestone-item (max-width: 512px)': {
    flexDirection: 'column',
    alignItems: 'stretch',
    '& .title-section': {
      flexDirection: 'row',
    },
    '& .controls-section': {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
});

export const TitleSection = styled('div')({
  display: 'flex',
  gap: '.5rem',
  flex: 1,
  minWidth: 0,
  '& .more-options': {
    '@container milestone-item (min-width: 768px)': {
      display: 'none',
    },
  },
});

export const ControlsSection = styled('div')({
  display: 'flex',
  gap: '.5rem',
  alignItems: 'center',
});

export const MoreOptionsLarge = styled('div')({
  '@container milestone-item (max-width: 768px)': {
    display: 'none',
  },
});

export const TaskTitle = styled(Typography)<{ $isPlaceholder?: boolean }>(
  ({ $isPlaceholder }) => ({
    flex: 1,
    minWidth: 0,
    fontSize: '14px',
    lineHeight: '32px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    color: $isPlaceholder
      ? DEALROOMS_COLORS.cloudburst
      : DEALROOMS_COLORS.woodsmoke,
  }),
);

export const StyledSelect = styled(Select)(({ theme }) => ({
  height: '32px',
  minWidth: '150px',
  fontSize: '0.75rem',
  maxWidth: '130px',
  backgroundColor: 'white',
  '& .MuiSelect-select': {
    fontSize: '0.75rem',
    backgroundColor: DEALROOMS_COLORS.neutralLighter,
    paddingRight: '1rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input': {
    color: DEALROOMS_COLORS.woodsmoke,
  },
  '& .MuiSvgIcon-root': {
    color: DEALROOMS_COLORS.woodsmoke,
  },
}));

export const StatusSelect = styled(
  Select<DealRoomActionItemStatus>,
)<StatusSelectProps>(({ $backgroundColor, $textColor }) => ({
  height: '32px',
  minWidth: '80px',
  fontSize: '0.75rem',
  backgroundColor: $backgroundColor,
  '& .MuiSelect-select': {
    padding: '0 .5rem 0 .5rem !important',
    fontSize: '0.75rem',
    color: $textColor,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    backgroundColor: 'transparent',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input': {
    color: $textColor,
    textAlign: 'left',
  },
  '& .MuiSvgIcon-root': {
    color: $textColor,
  },
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  gap: '8px',
});

export const AttachmentsContainer = styled('div')<{ hasAttachments?: boolean }>(
  ({ hasAttachments }) => ({
    // marginBottom: '.25rem',
    // marginTop: hasAttachments ? '.5rem' : '0px',
  }),
);
