import { DSConfigurationHeaderRow } from '../Components/DSConfigurationHeaderRow';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { UserDetails } from '../../../../types/BuyerOrientationSurveyTypes';
import { DealRoomFirstTimeUserExperienceInner } from '../../DealRoomFirstTimeUserExperience';
import { useState, useCallback, useMemo, useEffect } from 'react';
import {
  PrimaryButton,
  Modal,
  Stack,
  Text,
  IconButton,
  mergeStyleSets,
  TextField,
} from '@fluentui/react';
import { ChromeCloseIconProps } from '../../../../utils/iconProps';
import {
  DealRoomCommandBarButton,
  DealRoomAsyncCommandBarButton,
} from '../../Components/DealRoomButton';
import { pickFile } from '../../../../Helpers/FileHelpers';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import toast from 'react-hot-toast';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useWelcomeArtifact } from '../../../../Hooks/useWelcomeArtifact';
import useDeviceType from '../../../../Hooks/deviceDetection';

export const DSConfigurationFirstTimeUserExperience = () => {
  const { isMobile } = useDeviceType();

  const [showExperience, setShowExperience] = useState(false);
  const { user } = useUserProfile();
  const { dealRoom } = useDealRoom();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();

  // Find current user's details from deal room contacts
  const currentUserDetails = useMemo(() => {
    if (!dealRoom) return null;
    if (!user || !user.email) return null;
    if (!Array.isArray(dealRoom.contacts)) return null;

    const contact = dealRoom.contacts.find(
      (contact) => contact.email.toLowerCase() === user.email.toLowerCase(),
    );

    if (!contact) {
      toast.error(
        `You are able to view this Decision Site because you are an organization admin,
                 but you are not a contact in this Decision Site.
                 Please ask the Decision Site owner to invite you as a contact.`,
      );
      return null;
    }

    return (contact as UserDetails) || null;
  }, [dealRoom, user]);

  const dealRoomOwner = useMemo(() => {
    if (!dealRoom) return null;
    return dealRoom.owner;
  }, [dealRoom]);

  // Get list of deal room contacts excluding current user
  const dealRoomContacts = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return [];
    if (!user) return [];

    return dealRoom.contacts
      .filter(
        (contact) =>
          contact.userId !== user.id && contact.email !== user?.email,
      )
      .map((contact) => contact.name || 'N/A');
  }, [dealRoom?.contacts, user]);

  if (!organizationSlug || !dealRoomId) return null;
  if (!user || !dealRoom) return null;
  if (!dealRoomOwner) return null;

  const stackStyles = {
    root: {
      padding: isMobile ? '1rem' : '0',
      display: 'flex',
      flexFlow: isMobile ? 'column wrap' : 'row wrap',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      button: {
        borderRadius: '4px',
        padding: '0.3rem',
      },
      '> *': {
        margin: '0 !important',
      },
    },
  };

  return (
    <DSConfigurationHeaderRow
      title="First Time User Experience"
      description="See what your buyers will see when they first enter your Decision Site."
    >
      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ childrenGap: '10px' }}
        styles={stackStyles}
      >
        <PrimaryButton
          text="Show First Time User Experience"
          onClick={() => setShowExperience(true)}
        />
        <DSConfigurationWelcomeVideoUpload />

        {showExperience && (
          <DealRoomFirstTimeUserExperienceInner
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoomOwner={dealRoomOwner}
            dealRoomContacts={dealRoomContacts}
            currentUser={user}
            currentUserDetails={currentUserDetails}
            displayWelcomeVideo={true}
            displayBuyerOrientation={true}
            setHasWatchedWelcomeVideo={async () => {}}
            setHasCompletedBuyerOrientation={async () => {
              setShowExperience(false);
            }}
          />
        )}
      </Stack>
    </DSConfigurationHeaderRow>
  );
};

/**
 * Component that handles uploading and managing welcome videos through a modal interface.
 * Allows users to select, upload, preview and remove video files.
 */
const DSConfigurationWelcomeVideoUpload = () => {
  // Core state and hooks
  const [showModal, setShowModal] = useState(false);
  const dealRoomId = useDealRoomId();
  const organizationSlug = useOrganizationSlug();
  const { getAccessTokenSilently } = useAuth0();
  const { dealRoom, refetch: refetchDealRoom } = useDealRoom(
    organizationSlug,
    dealRoomId,
  );

  // State management for file and metadata
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // Get existing welcome video artifact if present
  const { welcomeVideo: welcomeArtifact, refetch: refetchWelcomeVideo } =
    useWelcomeArtifact({
      organizationSlug: organizationSlug!,
      dealRoomId: dealRoomId!,
    });

  const [videoLabel, setVideoLabel] = useState<string>(() => {
    return welcomeArtifact?.label || '';
  });

  const [videoDescription, setVideoDescription] = useState<string>(() => {
    return welcomeArtifact?.description || '';
  });

  // Add a new state for the video URL
  const [videoUrl, setVideoUrl] = useState<string>(() => {
    return welcomeArtifact?.fileUrl || '';
  });

  const reinitializeState = useCallback(() => {
    setVideoLabel(welcomeArtifact?.label || '');
    setVideoDescription(welcomeArtifact?.description || '');
    setVideoUrl(welcomeArtifact?.fileUrl || '');
  }, [
    welcomeArtifact?.description,
    welcomeArtifact?.fileUrl,
    welcomeArtifact?.label,
  ]);

  useEffect(() => {
    reinitializeState();
  }, [welcomeArtifact, reinitializeState]);

  // Styles definition
  const styles = mergeStyleSets({
    modalWrapper: {
      '& > .ms-Overlay': {
        backdropFilter: 'blur(3px)',
      },
      '.ms-Dialog-main': {
        minHeight: 'fit-content',
        backgroundColor: 'transparent',
      },
    },
    contentContainer: {
      width: 'calc(100vw - 3rem)',
      padding: '1rem 1.5rem 1.5rem',
      maxWidth: '538px',
      backgroundColor: DEALROOMS_COLORS.white,
      borderRadius: '.5rem',
      boxShadow: '0px 8px 16px 0px #00000024',
      gap: '1.5rem',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      fontWeight: '400',
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '1rem 0',
    },
    footerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
    },
    fileName: {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      color: DEALROOMS_COLORS.themePrimary,
      wordBreak: 'break-word',
      textAlign: 'center',
    },
    textField: {
      width: '100%',
    },
  });

  // Handler for file selection
  const handleSelectFile = useCallback(async () => {
    try {
      const file = await pickFile({ fileTypes: 'video' });
      if (file) setSelectedFile(file);
    } catch (err) {
      toast.error('Error selecting file');
    }
  }, []);

  // Reset modal state
  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setSelectedFile(null);
    reinitializeState();
  }, [reinitializeState]);

  // Handler for video upload
  const handleUpload = useCallback(async () => {
    if (!selectedFile || !organizationSlug || !dealRoomId) return;

    const token = await getAccessTokenSilently();

    // First remove any existing welcome videos
    try {
      const existingArtifacts = await DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId, placement: 'WELCOME' },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      await Promise.all(
        existingArtifacts.data.map((artifact) =>
          DealRoomsApiClient.deleteArtifact(
            {
              organizationSlug,
              dealRoomId,
              artifactId: artifact.id,
              soft: false,
            },
            { headers: { Authorization: `Bearer ${token}` } },
          ),
        ),
      );
    } catch (err) {
      console.error('Error deleting existing welcome videos:', err);
    }

    // Upload new welcome video
    await toast.promise(
      DealRoomsApiClient.uploadFileArtifact(
        organizationSlug,
        dealRoomId,
        {
          file: selectedFile,
          label: videoLabel || selectedFile.name,
          name: selectedFile.name,
          placement: 'WELCOME',
          description: videoDescription,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      ),
      {
        loading: 'Uploading Welcome Video...',
        success: () => {
          refetchWelcomeVideo().then(() => {
            handleCloseModal();
          });
          refetchDealRoom();
          return 'Successfully uploaded Welcome Video';
        },
        error: 'Failed to upload Welcome Video',
      },
    );
  }, [
    selectedFile,
    organizationSlug,
    dealRoomId,
    getAccessTokenSilently,
    videoLabel,
    videoDescription,
    refetchWelcomeVideo,
    refetchDealRoom,
    handleCloseModal,
  ]);

  // Handler for removing existing welcome video
  const handleRemoveVideo = useCallback(async () => {
    if (!organizationSlug || !dealRoomId) return;
    const token = await getAccessTokenSilently();

    try {
      const existingArtifacts = await DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId, placement: 'WELCOME' },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      await toast.promise(
        Promise.all(
          existingArtifacts.data.map((artifact) =>
            DealRoomsApiClient.deleteArtifact(
              {
                organizationSlug,
                dealRoomId,
                artifactId: artifact.id,
                soft: false,
              },
              { headers: { Authorization: `Bearer ${token}` } },
            ),
          ),
        ),
        {
          loading: 'Removing Welcome Video...',
          success: () => {
            setSelectedFile(null);
            setVideoLabel('');
            setVideoDescription('');
            setVideoUrl('');
            refetchWelcomeVideo();
            refetchDealRoom();
            return 'Successfully removed Welcome Video';
          },
          error: 'Failed to remove Welcome Video',
        },
      );
    } catch (err) {
      console.error('Error removing welcome video:', err);
      toast.error('Failed to remove Welcome Video');
    }
  }, [
    organizationSlug,
    dealRoomId,
    getAccessTokenSilently,
    refetchWelcomeVideo,
    refetchDealRoom,
  ]);

  // Memoized video preview component
  const videoPreview = useMemo(() => {
    const videoSrc = selectedFile
      ? URL.createObjectURL(selectedFile)
      : videoUrl;
    if (!videoSrc) return null;

    return (
      <>
        <video
          controls
          src={videoSrc}
          style={{ maxWidth: '100%', marginTop: '1rem' }}
        >
          Your browser does not support the video tag.
        </video>
      </>
    );
  }, [selectedFile, videoUrl]);

  return (
    <>
      <Stack tokens={{ childrenGap: 8 }}>
        <PrimaryButton
          onClick={() => setShowModal(true)}
          styles={{ root: { minWidth: 'fit-content' } }}
        >
          Upload Welcome Video
        </PrimaryButton>
      </Stack>

      {showModal && (
        <Modal
          className={styles.modalWrapper}
          isOpen
          onDismiss={handleCloseModal}
        >
          <Stack className={styles.contentContainer}>
            <Stack.Item className={styles.headerContainer}>
              <Text className={styles.title}>Upload Welcome Video</Text>
              <IconButton
                ariaLabel="Close"
                iconProps={ChromeCloseIconProps}
                onClick={handleCloseModal}
              />
            </Stack.Item>

            <Stack.Item className={styles.content}>
              <DealRoomAsyncCommandBarButton
                onClick={handleSelectFile}
                text="Select New Video"
                buttonStyleType="DEAL_ROOM"
              />
              {videoPreview}

              <TextField
                label="Welcome Video Label"
                value={videoLabel}
                onChange={(_, newValue) => setVideoLabel(newValue || '')}
                className={styles.textField}
                placeholder="Enter a label for the video"
              />
              <TextField
                label="Welcome Video Description"
                value={videoDescription}
                onChange={(_, newValue) => setVideoDescription(newValue || '')}
                className={styles.textField}
                placeholder="Enter a description for the video"
                multiline
                rows={3}
              />
            </Stack.Item>

            <Stack.Item className={styles.footerContainer}>
              <Stack horizontal tokens={{ childrenGap: 12 }}>
                <DealRoomCommandBarButton
                  buttonStyleType="COMMAND_BAR"
                  text="Cancel"
                  onClick={handleCloseModal}
                />
                {welcomeArtifact && (
                  <DealRoomAsyncCommandBarButton
                    onClick={handleRemoveVideo}
                    text="Remove Video"
                    buttonStyleType="COMMAND_BAR"
                    styles={{
                      root: {
                        backgroundColor: DEALROOMS_COLORS.neutralDark,
                      },
                    }}
                  />
                )}
              </Stack>
              <DealRoomAsyncCommandBarButton
                onClick={handleUpload}
                text="Upload Video"
                buttonStyleType="COMMAND_BAR"
                disabled={!selectedFile}
                styles={{
                  root: { opacity: !selectedFile ? 0.5 : 1 },
                }}
              />
            </Stack.Item>
          </Stack>
        </Modal>
      )}
    </>
  );
};
