import React, { useCallback, useEffect, useState } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { useOrganizationSlug } from '../../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../../Hooks/useDealRoomId';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-hot-toast';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { useCalloutCards } from '../../../../../Hooks/useCalloutCards';
import { DSConfigurationHeaderRow } from '../../Components/DSConfigurationHeaderRow';
import { isUrl } from '../../../../../Helpers/URLHelpers';
import { DragEndEvent } from '@dnd-kit/core';
import { DSConfigurationCalloutCard } from './DSConfigurationCalloutCard';
import { getCalloutCardsStyles } from './DSConfigurationCalloutCards.styles';
import { DSSortableGrid } from '../../Components/DSSortableGrid';

// Define the structure for callout card form data
export interface CalloutCardFormData {
  id?: number;
  title: string;
  description: string;
  ctaLabel?: string | null;
  link?: string | null;
  artifactId?: number | null;
  order: number;
}

// Define an empty callout card template
const emptyCalloutCard: CalloutCardFormData = {
  title: '',
  link: null,
  description: '',
  ctaLabel: '',
  artifactId: null,
  order: 0,
};

export const DSConfigurationCalloutCards: React.FC = () => {
  // State for storing callout cards
  const [calloutCards, setCalloutCards] = useState<CalloutCardFormData[]>([]);
  // State to track if there are unsaved changes
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  // State for storing error messages
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  // State for storing available artifacts
  const [artifacts, setArtifacts] = useState<Array<DealRoomArtifact>>([]);

  // Hooks for callout cards in order to easy update them on the other places from the project
  const { refetch: refetchCalloutCards } = useCalloutCards();

  // Hooks for getting organization and deal room context
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { getAccessTokenSilently } = useAuth0();

  const styles = getCalloutCardsStyles();

  // load callout cards from the API
  const loadCalloutCards = useCallback(async () => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }

    const token = await getAccessTokenSilently();

    const response = await DealRoomsApiClient.listCalloutCards(
      organizationSlug,
      dealRoomId,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    // Sort cards by order and assign order if not present
    const cardsWithOrder = (
      response.data as unknown as CalloutCardFormData[]
    ).map((card, index) => ({
      ...card,
      order: card.order ?? index,
    }));

    const sortedCards = cardsWithOrder.sort((a, b) => a.order - b.order);
    setCalloutCards(sortedCards);
  }, [organizationSlug, dealRoomId, getAccessTokenSilently]);

  // load artifacts from the API
  const loadArtifacts = useCallback(async () => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }

    const token = await getAccessTokenSilently();
    const response = await DealRoomsApiClient.listArtifacts(
      {
        organizationSlug,
        dealRoomId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    setArtifacts(response.data);
  }, [organizationSlug, dealRoomId, getAccessTokenSilently]);

  // load callout cards when component mounts or dependencies change
  useEffect(() => {
    loadCalloutCards();
  }, [loadCalloutCards]);

  // load artifacts when component mounts or dependencies change
  useEffect(() => {
    loadArtifacts();
  }, [loadArtifacts]);

  // add a new empty card
  const handleAddCalloutCard = () => {
    setCalloutCards((prevCards) => {
      const newOrder =
        prevCards.length > 0
          ? Math.max(...prevCards.map((card) => card.order)) + 1
          : 0;
      return [...prevCards, { ...emptyCalloutCard, order: newOrder }];
    });
    setUnsavedChanges(true);
  };

  // delete a card
  const handleDeleteCalloutCard = async (index: number) => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }

    const token = await getAccessTokenSilently();
    const card = calloutCards[index];

    // card is not saved in DB - just a temporary card user was creating
    // no need to show toast for this case
    if (!card.id) {
      const newCards = [...calloutCards];
      newCards.splice(index, 1);
      setCalloutCards(newCards);
      return;
    }

    // card was saved in the DB
    await toast.promise(
      (async () => {
        await DealRoomsApiClient.deleteCalloutCard(
          organizationSlug,
          dealRoomId,
          card.id!,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        await loadCalloutCards();
      })(),
      {
        loading: 'Deleting callout card...',
        success: 'Callout card deleted successfully',
        error: 'Failed to delete callout card',
      },
    );
  };

  // handle changes in card fields
  const handleCalloutCardChange = (
    index: number,
    field: keyof CalloutCardFormData,
    value: string | number | null,
  ) => {
    const newCards = [...calloutCards];
    newCards[index] = { ...newCards[index], [field]: value };
    setCalloutCards(newCards);
    setUnsavedChanges(true);
  };

  // save all callout cards
  const handleSave = async () => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }

    // NOTE - unfortunately, a lot of callout cards have been saved with empty string links
    // rather than null values - this chunk of code fixes the issue at time of save going forward.
    // This can likely be removed in June of 2025.
    const fixedCards = calloutCards.map((card) => {
      return {
        ...card,
        link: card.link?.length === 0 ? null : card.link,
      };
    });

    // Validate that each card has exactly one of link or artifactId
    const invalidCards = fixedCards.filter((card) => {
      const hasLink = card.link
        ? isUrl(card.link.trim(), { allowMailto: true })
        : false;
      const hasArtifactId = Boolean(card.artifactId);
      return (!hasLink && !hasArtifactId) || (hasLink && hasArtifactId);
    });

    // Validate that each card has a title and description
    const invalidFields = calloutCards.some(
      (card) => !card.title || !card.description,
    );

    if (invalidFields) {
      setErrorMessage('Each card must have a title and description.');
      return;
    }

    if (invalidCards.length > 0) {
      setErrorMessage(
        'Each card must have a valid URL or an associated artifact.',
      );
      return;
    }

    const token = await getAccessTokenSilently();
    const promises = fixedCards.map(async (card) => {
      if (card.id) {
        await DealRoomsApiClient.updateCalloutCard(
          organizationSlug,
          dealRoomId,
          card.id,
          card,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      } else {
        await DealRoomsApiClient.createCalloutCard(
          organizationSlug,
          dealRoomId,
          card,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      }
    });
    await toast
      .promise(Promise.all(promises), {
        loading: 'Saving callout cards...',
        success: () => {
          setErrorMessage(null);
          setUnsavedChanges(false);
          // update the callout cards from the Overview tab
          refetchCalloutCards();
          return 'Callout cards saved successfully';
        },
        error: (error) => {
          const message = error?.message || 'Failed to save callout cards';
          setErrorMessage(message);
          return message;
        },
      })
      .then(async () => {
        await loadCalloutCards();
      });
  };

  // Handle drag end event
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) { return; }

    setCalloutCards((cards) => {
      const oldIndex = cards.findIndex(
        (card) => (card.id || card.order) === active.id,
      );
      const newIndex = cards.findIndex(
        (card) => (card.id || card.order) === over.id,
      );

      // Create new array with updated orders
      const reorderedCards = [...cards];
      const [movedCard] = reorderedCards.splice(oldIndex, 1);
      reorderedCards.splice(newIndex, 0, movedCard);

      // Update order values for all cards
      const updatedCards = reorderedCards.map((card, index) => ({
        ...card,
        order: index,
      }));

      setUnsavedChanges(true);
      return updatedCards;
    });
  };

  return (
    <div className={styles.container}>
      {/* Header with buttons */}
      <DSConfigurationHeaderRow
        title="Callout Cards"
        description="Callout Cards are displayed on the Overview page. They can be used to highlight important information or link to other pages."
      >
        <PrimaryButton
          onClick={handleAddCalloutCard}
          text="Add New Callout Card"
          className={styles.addButton}
        />

        <PrimaryButton
          onClick={handleSave}
          text="Save Changes"
          disabled={!unsavedChanges}
        />
      </DSConfigurationHeaderRow>

      {/* Error message display */}
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}

      {/* Render callout cards in grid */}
      <DSSortableGrid
        items={calloutCards}
        onDragEnd={handleDragEnd}
        className={styles.cardsGrid}
        renderItem={(card, index) => (
          <DSConfigurationCalloutCard
            card={card}
            index={index}
            onDelete={handleDeleteCalloutCard}
            onChange={handleCalloutCardChange}
            artifacts={artifacts}
          />
        )}
      />
    </div>
  );
};
