import { mergeStyleSets } from '@fluentui/react';

export const getDSSortableGridStyles = () =>
  mergeStyleSets({
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '1.25rem',
      width: '100%',
      position: 'relative',
      touchAction: 'none',
    },
  });
