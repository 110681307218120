import { Box, Button } from '@mui/material';
import useMilestonesActionItemsFiltersStore from '../../../../Stores/milestonesActionItemsFiltersStore';
import { useCallback, useEffect, useMemo } from 'react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { StatusFilter } from './StatusFilter';
import { OwnerFilter } from './OwnerFilter';
import { DateRangeFilter } from './DateRangeFilter';

type MilestonesActionItemsFiltersProps = {
  organizationSlug: string;
  dealRoomId: number;
};

export const MilestonesActionItemsFilters = ({
  organizationSlug,
  dealRoomId,
}: MilestonesActionItemsFiltersProps) => {
  // Store values and setters
  const selectedStatuses = useMilestonesActionItemsFiltersStore(
    (state) => state.selectedStatuses,
  );
  const startDate = useMilestonesActionItemsFiltersStore(
    (state) => state.startDate,
  );
  const endDate = useMilestonesActionItemsFiltersStore(
    (state) => state.endDate,
  );
  const selectedOwnerEmails = useMilestonesActionItemsFiltersStore(
    (state) => state.selectedOwnerEmails,
  );
  const resetFilters = useMilestonesActionItemsFiltersStore(
    (state) => state.resetFilters,
  );

  // Reset all filters to initial values
  const handleResetFilters = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  // Check if any filter is active
  const hasActiveFilters = useCallback(() => {
    return (
      selectedStatuses.length > 0 ||
      startDate !== null ||
      endDate !== null ||
      selectedOwnerEmails.length > 0
    );
  }, [selectedStatuses, startDate, endDate, selectedOwnerEmails]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, [resetFilters]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 2,
        alignItems: { xs: 'stretch', sm: 'flex-end' },
        flexWrap: 'wrap',
        justifyContent: { xs: 'stretch', sm: 'flex-start' },
        padding: '1rem 1.5rem',
        borderBottom: `2px solid ${DEALROOMS_COLORS.neutralLight}`,
      }}
    >
      <StatusFilter />
      <OwnerFilter
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
      />
      <DateRangeFilter />

      <Button
        variant="contained"
        size="small"
        onClick={handleResetFilters}
        disabled={!hasActiveFilters()}
        sx={{
          marginLeft: 'auto',
          height: 40,
          width: { xs: '100%', sm: 'auto' },
          backgroundColor: DEALROOMS_COLORS.themePrimary,
          color: DEALROOMS_COLORS.white,
          '&:hover': {
            backgroundColor: DEALROOMS_COLORS.themePrimary,
          },
          '&.Mui-disabled': {
            backgroundColor: DEALROOMS_COLORS.neutralLight,
            color: DEALROOMS_COLORS.white,
          },
        }}
      >
        Clear Filters
      </Button>
    </Box>
  );
};
