import React from 'react';
import { Container, Embed } from './DSLoomEmbed.styles';

export interface DSLoomEmbedProps {
  /**
   * The Loom video ID or share URL
   */
  videoId: string;
  /**
   * Title for the iframe (for accessibility)
   */
  title: string;
  /**
   * Whether to hide the owner name
   * @default false
   */
  hideOwner?: boolean;
  /**
   * Whether to hide the share controls
   * @default false
   */
  hideShare?: boolean;
  /**
   * Whether to start the video muted
   * @default false
   */
  startMuted?: boolean;
}

/**
 * A responsive Loom video embed component that maintains 16:9 aspect ratio
 */
export const DSLoomEmbed = ({
  videoId,
  title,
  hideOwner = false,
  hideShare = false,
  startMuted = false,
}: DSLoomEmbedProps) => {
  // Extract video ID from URL if full URL is provided
  const extractedVideoId = videoId.includes('loom.com')
    ? videoId.split('/').pop()
    : videoId;

  const params = new URLSearchParams({
    hide_owner: hideOwner ? '1' : '0',
    hide_share: hideShare ? '1' : '0',
    muted: startMuted ? '1' : '0',
  });

  return (
    <Container>
      <Embed
        title={title}
        src={`https://www.loom.com/embed/${extractedVideoId}?${params.toString()}`}
        allowFullScreen
        allow="autoplay; fullscreen"
      />
    </Container>
  );
};

export default DSLoomEmbed;
