import { mergeStyleSets } from '@fluentui/react';
import type { IStyle } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

interface ICalloutCardsStyles {
  container: IStyle;
  cardsGrid: IStyle;
  addButton: IStyle;
  errorMessage: IStyle;
}

export const getCalloutCardsStyles = () =>
  mergeStyleSets<ICalloutCardsStyles>({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      selectors: {
        '@media (max-width: 480px)': {
          gap: 16,
        },
      },
    },
    cardsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
      gap: 20,
      width: '100%',
      selectors: {
        '@media (max-width: 480px)': {
          // gridTemplateColumns: '1fr',
          gap: 16,
        },
      },
    },
    errorMessage: {
      color: DEALROOMS_COLORS.configurationsErrorMessage,
      marginTop: '.5rem',
    },
    addButton: {
      alignSelf: 'flex-start',
      selectors: {
        '@media (max-width: 480px)': {
          width: '100%',
        },
      },
    },
  });
