import { Box, Typography, styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const UpNextContainer = styled(Box)({
  borderRadius: '0.25rem',
  height: '100%',
  maxWidth: '100%',
  width: 'auto',
  transition: '.3s ease-in-out all',
  position: 'relative',
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
});

export const EventCardContainer = styled(Box)({
  backgroundColor: DEALROOMS_COLORS.white,
  borderRadius: '0.25rem',
  height: '100%',
  minWidth: '150px',
  width: 'auto',
  transition: '.3s ease-in-out all',
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  '& > div': {
    padding: '.5rem .75rem',
  },
});

export const NoEventsContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '0.25rem',
  height: '100%',
  minWidth: '150px',
  width: 'auto',
  padding: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Title = styled(Typography)({
  color: DEALROOMS_COLORS.woodsmoke,
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  padding: '1rem',
  backgroundColor: DEALROOMS_COLORS.white,
  borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderTopLeftRadius: '0.25rem',
  borderTopRightRadius: '0.25rem',
});

export const TitleIcon = styled(Box)({
  color: DEALROOMS_COLORS.cloudburst,
});

export const ContentContainer = styled(Box)({
  backgroundColor: DEALROOMS_COLORS.neutralGray,
  padding: '.5rem .5rem .5rem .5rem',
});

export const NowIndicator = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '50px',
  width: '1px',
  backgroundColor: DEALROOMS_COLORS.peacock,
  opacity: 0.5,
  position: 'relative',
  marginTop: '4px',
  marginLeft: '1rem',
  marginRight: '1rem',
});

export const NowIcon = styled(Box)({
  color: DEALROOMS_COLORS.peacock,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: DEALROOMS_COLORS.white,
});
