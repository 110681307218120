import {
  FontIcon,
  FontSizes,
  FontWeights,
  IModalProps,
  Modal,
  NeutralColors,
  Text,
  mergeStyles,
} from '@fluentui/react';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';

export type BaseModalProps = PropsWithChildren<
  IModalProps & {
    title?: string;
    subtitle?: string;
    modalWidth?: string;
    contentPadding?: string;
    headerPadding?: string;
    headerBackgroundColor?: string;
    headerFontColor?: string;
    headerFontSize?: string;
    subtitleFontColor?: string;
    subtitleFontSize?: string;
    modalBodyClassName?: string;
  }
>;
export const BaseModal = ({
  title,
  subtitle,
  onDismiss,
  children,
  modalBodyClassName,
  modalWidth,
  contentPadding = '.5rem',
  headerPadding = '.25rem .5rem',
  headerBackgroundColor = MEETINGFLOW_COLORS.purpleMediumDark,
  headerFontColor = NeutralColors.white,
  headerFontSize = FontSizes.large,
  subtitleFontColor = NeutralColors.white, 
  subtitleFontSize = FontSizes.small,
  ...rest
}: BaseModalProps) => {
  const modalContentClass = mergeStyles({
    '.modal-header': {
      display: 'flex',
      backgroundColor: headerBackgroundColor,
      padding: headerPadding,
      '.modal-title': {
        flexBasis: '100%',
        lineHeight: '1.75rem',
        fontWeight: FontWeights.semibold,
        fontSize: headerFontSize,
        color: headerFontColor,
        '.modal-subtitle': {
          fontSize: subtitleFontSize,
          fontWeight: FontWeights.regular,
          lineHeight: '1.15rem',
          position: 'relative',
          marginTop: '8px',
          
          color: subtitleFontColor,
        },
      },
    },

    '.modal-content': {
      padding: contentPadding,
    },
  });

  return (
    <Modal onDismiss={onDismiss} styles={{ main: { width: modalWidth } }} {...rest}>
      <div className={classNames(modalContentClass, modalBodyClassName)}>
        {title ? (
          <div className="modal-header">
            <Text block className="modal-title">
              {title ? title : null}
              {subtitle ? (
                <Text block className="modal-subtitle">
                  {subtitle}
                </Text>
              ) : null}
            </Text>
          </div>
        ) : null}

        <div className="modal-content">{children}</div>
      </div>
    </Modal>
  );
};
