import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const Container = styled('div')({
  width: '100%',
  maxWidth: '800px',
});

export const ArtifactLink = styled(Link)({
  color: 'inherit',
  fontWeight: '500',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const HighlightedText = styled('span')({
  color: 'inherit',
  fontWeight: '500',
});

export const ActivityIcon = styled('span')({
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: '8px',
});
