import { styled, Tooltip } from '@mui/material';
import type { Theme } from '@mui/material';

export const tooltipStyles = (theme: Theme) => ({
  bgcolor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  p: 2,
  fontSize: '.875rem',
  boxShadow: 2,
  maxWidth: 300,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '.25rem',
  lineHeight: 1.25,
  '& .MuiTooltip-arrow': {
    color: theme.palette.secondary.main,
    '&::before': {
      border: `1px solid ${theme.palette.divider}`,
    },
  },
});
