import { FC, useState, useEffect } from 'react';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { DecisionSiteUserCalendar } from '../Components/Journey/DecisionSiteUserCalendar';
import { DealRoomBuyersJourneyTabContainer } from './DealRoomBuyersJourneyTab.styles';
import DecisionSiteMeetingflowList from '../Components/Journey/DecisionSiteMeetingflowList';
import { DecisionSiteMeetingPanel } from '../Components/Journey/DecisionSiteMeetingPanel';

import { useOrganization } from '../../../Hooks/useOrganization';
import DSResponsiveDrawer from '../../../Components/Common/DSResponsiveDrawer';
import DecisionSiteMeetingflowListWithFilters from '../Components/Journey/DecisionSiteMeetingflowListWithFilters';
import {
  createMeetingflowAndDecisionSiteArtifact,
  createMeetingflowLinkArtifact,
} from '../../../utils/DecisionSiteMeetingflowHelpers';
import { useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Drawer } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

interface DealRoomBuyersJourneyTabProps {}

export const DealRoomBuyersJourneyTab: FC<
  DealRoomBuyersJourneyTabProps
> = () => {
  const { dealRoomId, dealRoomRole } = useDealRoom();
  const { slug: organizationSlug } = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  if (!dealRoomId || !organizationSlug) return null;

  const showCalendar = dealRoomRole === 'SELLER';
  const selectedMeetingflowId = searchParams.get('meeting') || undefined;

  const handleSelectMeetingflow = (meetingflowId: string) => {
    // Update URL with meeting parameter and remove tab parameter
    const newParams = new URLSearchParams(searchParams);
    newParams.set('meeting', meetingflowId);
    newParams.delete('tab');
    setSearchParams(newParams, { replace: true });
  };

  const handleCloseMeetingPanel = () => {
    // Remove meeting and tab parameters from URL
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('meeting');
    newParams.delete('tab');
    setSearchParams(newParams, { replace: true });
  };

  return (
    <DealRoomBuyersJourneyTabContainer>
      <div className="content">
        <div className="column">
          <DecisionSiteMeetingflowListWithFilters
            onSelectMeetingflow={handleSelectMeetingflow}
          />
        </div>

        {showCalendar && (
          <div className="column">
            <DecisionSiteUserCalendar
              onSelectMeetingflow={handleSelectMeetingflow}
            />
          </div>
        )}
      </div>
      <DSResponsiveDrawer
        anchor="right"
        // onClose={onDismiss}
        widthPercentage={70}
        mobileWidthPercentage={90}
        open={Boolean(selectedMeetingflowId)}
        padding={'0'}
      >
        {selectedMeetingflowId && (
          <DecisionSiteMeetingPanel
            meetingPlanId={selectedMeetingflowId}
            onClickCloseButton={handleCloseMeetingPanel}
          />
        )}
      </DSResponsiveDrawer>
    </DealRoomBuyersJourneyTabContainer>
  );
};
