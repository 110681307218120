import { styled, keyframes } from '@mui/material/styles';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

const pulseAnimation = keyframes`
  0% {
    background-color: ${DEALROOMS_COLORS.plum100};
  }
  50% {
    background-color: ${DEALROOMS_COLORS.crimson};
  }
  100% {
    background-color: ${DEALROOMS_COLORS.plum100};
  }
`;

export const RecordButton = styled('span', {
  shouldForwardProp: (prop) =>
    prop !== 'variant' &&
    prop !== 'isDark' &&
    prop !== 'mini' &&
    prop !== 'iconOnly',
})<{
  variant?:
    | 'scheduled'
    | 'not-scheduled'
    | 'disabled'
    | 'recorded'
    | 'recording';
  isDark?: boolean;
  mini?: boolean;
  iconOnly?: boolean;
}>(({ theme, variant, isDark, mini, iconOnly }) => ({
  color: DEALROOMS_COLORS.white,
  cursor: ['disabled', 'recorded'].includes(variant || '')
    ? 'default'
    : 'pointer',
  fontSize: mini ? '10px' : '1rem',
  fontWeight: mini ? 500 : 400,
  borderRadius: iconOnly ? '50%' : mini ? '0.5rem' : '0.25rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  maxHeight: mini ? '16px' : 'auto',
  width: iconOnly ? '16px' : 'auto',
  height: iconOnly ? '16px' : mini ? '16px' : 'auto',
  minWidth: iconOnly ? '16px' : 'auto',
  minHeight: iconOnly ? '16px' : 'auto',
  padding: iconOnly
    ? '0'
    : mini
      ? '0 .5rem 0 .25rem'
      : '.25rem 1rem .25rem .5rem',
  transition: 'all 0.3s ease-in-out',
  lineHeight: mini ? '18px' : '18px',
  boxSizing: 'border-box',
  textTransform: mini ? 'uppercase' : undefined,

  '& .MuiSvgIcon-root': {
    marginRight: iconOnly ? '0' : mini ? '.25rem' : '0.5rem',
    fontWeight: 400,
    fontSize: iconOnly ? '16px' : mini ? '10px' : '1rem',
  },

  ...(variant === 'not-scheduled' && {
    backgroundColor: isDark
      ? DEALROOMS_COLORS.themePrimary
      : DEALROOMS_COLORS.plum100,
    '&:hover': {
      backgroundColor: DEALROOMS_COLORS.plum75,
    },
  }),

  ...(variant === 'scheduled' && {
    backgroundColor: DEALROOMS_COLORS.cloudburst,
    '&:hover': {
      backgroundColor: DEALROOMS_COLORS.woodsmoke,
    },
  }),

  ...(variant === 'disabled' && {
    backgroundColor: isDark ? theme.palette.grey[800] : theme.palette.grey[400],
    opacity: 0.7,
  }),

  ...(variant === 'recorded' && {
    color: mini ? DEALROOMS_COLORS.cloudburst : DEALROOMS_COLORS.white,
    backgroundColor: mini ? 'none' : DEALROOMS_COLORS.cloudburst,
  }),

  ...(variant === 'recording' && {
    backgroundColor: DEALROOMS_COLORS.plum100,
    animation: `${pulseAnimation} 2s ease-in-out infinite`,
  }),
}));
