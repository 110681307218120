// YouTube URL patterns
export const YOUTUBE_URL_PATTERNS = [
  /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/,
  /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^?]+)/,
  /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/,
];

export const getYouTubeVideoId = (url: string): string | null => {
  for (const pattern of YOUTUBE_URL_PATTERNS) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }
  return null;
};

export const getYouTubeThumbnail = (videoId: string): string => {
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
};
