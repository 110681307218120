import { create } from 'zustand';
import { DateTime } from 'luxon';
import { DealRoomActionItemStatus } from '@meetingflow/common/Api/data-contracts';

type MilestonesActionItemsFiltersStore = {
  selectedStatuses: DealRoomActionItemStatus[];
  startDate: DateTime | null;
  endDate: DateTime | null;
  selectedOwnerEmails: string[];
  setSelectedStatuses: (statuses: DealRoomActionItemStatus[]) => void;
  setStartDate: (date: DateTime | null) => void;
  setEndDate: (date: DateTime | null) => void;
  setSelectedOwnerEmails: (emails: string[]) => void;
  resetFilters: () => void;
};

// Define the initial state for the filters
const initialState: Pick<
  MilestonesActionItemsFiltersStore,
  'selectedStatuses' | 'startDate' | 'endDate' | 'selectedOwnerEmails'
> = {
  selectedStatuses: [], // No statuses selected by default
  startDate: null, // No start date set initially
  endDate: null, // No end date set initially
  selectedOwnerEmails: [], // No owners selected by default
};

// Create the store with Zustand
const useMilestonesActionItemsFiltersStore =
  create<MilestonesActionItemsFiltersStore>((set) => ({
    ...initialState,

    // Update selected statuses
    setSelectedStatuses: (selectedStatuses) => {
      set({ selectedStatuses });
    },

    // Set the start date for filtering
    setStartDate: (startDate) => {
      set({ startDate });
    },

    // Set the end date for filtering
    setEndDate: (endDate) => {
      set({ endDate });
    },

    // Update selected owner emails
    setSelectedOwnerEmails: (selectedOwnerEmails) => {
      set({ selectedOwnerEmails });
    },

    // Reset all filters to their initial state
    resetFilters: () => {
      set(initialState);
    },
  }));

export default useMilestonesActionItemsFiltersStore;
