import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  keyframes,
  List,
  ListItem,
  ListSubheader,
  Stack,
  styled,
  Typography,
} from '@mui/material/';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const EventCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(1),
  minHeight: '24px',
  transition: '.3s ease-in-out all',
  position: 'relative',

  '.event-details': {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0, // Prevents flex items from overflowing
    flexGrow: 1,

    '.event-title': {
      display: 'block',
      paddingRight: '.5rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      fontSize: '14px',
    },

    '.event-datetime': {
      fontSize: '10px',
    },

    '.short-time': {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: 'regular',
      color: theme.palette.text.secondary,
      marginRight: '.5rem',
    },
  },

  '.event-actions-column': {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: theme.spacing(5),
  },
}));

export const EventCardStack = styled(Stack)(({ onClick }) => ({
  cursor: onClick ? 'pointer' : 'default',
}));

export const JoinConferenceButton = styled('button')(({ theme }) => ({
  backgroundColor: `${DEALROOMS_COLORS.peacock}80`,
  cursor: 'pointer',
  fontSize: '10px',
  textTransform: 'uppercase',
  display: 'flex',
  gap: '0.25rem',
  color: DEALROOMS_COLORS.white,
  padding: '0 0.5rem',
  borderRadius: '0.5rem',
  fontWeight: '500',
  lineHeight: '16px',
  border: 'none',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: `${DEALROOMS_COLORS.peacock}`,
  },
}));

export const AttendeesList = styled(List)(({ theme }) => ({
  py: 0,
}));

export const AttendeesListSubheader = styled(ListSubheader)(({ theme }) => ({
  py: 0,
  lineHeight: 1.2,
}));

export const AttendeesListItem = styled(ListItem)(({ theme }) => ({
  py: 0.25,
  minHeight: 0,
}));

export const AttendeesTypography = styled(Typography)(({ theme }) => ({
  lineHeight: 1.2,
  paddingLeft: '1rem',
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  position: 'relative',
}));

export const AttendeesAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  fontSize: '0.875rem',
}));

export const AttendeesBadge = styled(Badge)(({ theme }) => ({
  marginLeft: '.5rem',
  '& .MuiBadge-badge': {
    fontSize: '0.65rem',
    height: '16px',
    lineHeight: '16px',
    minWidth: '16px',
    padding: '0 4px',
    textAlign: 'center',
    backgroundColor: DEALROOMS_COLORS.white,
    color: DEALROOMS_COLORS.cloudburst,
  },
}));

export const StyledAvatarGroup = styled(AvatarGroup)({
  '& .MuiAvatar-root': {
    width: '16px',
    height: '16px',
    fontSize: '10px',
    border: '1px solid white',
    marginRight: '4px',
  },
});

export const MeetingflowIconContainer = styled(Box)(({ theme }) => ({
  minWidth: '26px',
  height: '26px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
}));

export const MeetingflowIcon = styled(Box)(({ theme }) => ({
  color: DEALROOMS_COLORS.cloudburst,
  cursor: 'pointer',
  height: '16px',
  width: '16px',
  borderRadius: '50%',
  '& svg': {
    color: 'inherit',
    height: '16px',
    width: '16px',
  },
}));
