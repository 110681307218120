import {
  useTheme,
  useMediaQuery,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { ReactNode, useCallback } from 'react';
import {
  StyledDialog,
  StyledDialogContent,
  StyledContentStack,
  StyledDialogHeader,
  StyledTitle,
  StyledSubtitle,
  StyledDialogFooter,
  StyledFooterButtonGroup,
  StyledPrimaryGroup,
  StyledHeaderContent,
  StyledCloseButton,
  StyledKeepOpenLabel,
} from './DSResponsiveModal.styles';
import DSButton from './DSButton';
import DSSwitch from './DSSwitch';
import CloseIcon from '@mui/icons-material/Close';

export interface FooterButton {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  variant?: 'primary' | 'outlined';
}

export interface DSResponsiveModalProps {
  /**
   * Whether the modal is open
   */
  open: boolean;
  /**
   * Callback when the modal is dismissed
   */
  onDismiss: () => void;
  /**
   * The content of the modal
   */
  children: ReactNode;
  /**
   * Maximum width of the modal on desktop. Uses MUI's maxWidth values.
   * @default "md"
   */
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /**
   * Custom maximum width in pixels. Overrides maxWidth if provided.
   */
  customMaxWidth?: number;
  /**
   * Whether to show a fullscreen modal on mobile devices
   * @default true
   */
  fullScreenOnMobile?: boolean;
  /**
   * Optional title for the modal
   */
  title?: string;
  /**
   * Optional subtitle for the modal
   */
  subText?: string;
  /**
   * Optional primary buttons that appear on the right side of the footer
   */
  primaryFooterButtons?: FooterButton[];
  /**
   * Optional secondary buttons that appear on the left side of the footer
   */
  secondaryFooterButtons?: FooterButton[];
  /**
   * Whether to show the close button in the top right
   * @default true
   */
  dismissable?: boolean;
  /**
   * Whether to show a toggle that controls if the modal stays open when primary buttons are clicked
   * @default false
   */
  showKeepOpenToggle?: boolean;
  /**
   * Label for the keep-open toggle
   * @default "Keep open"
   */
  keepOpenToggleLabel?: string;
  /**
   * Value for the keep-open toggle
   */
  keepOpenToggleValue?: boolean;
  /**
   * Callback when the keep-open toggle value changes
   */
  onKeepOpenToggleChange?: (value: boolean) => void;
}

/**
 * A responsive modal component that adapts to screen size.
 * Goes fullscreen on mobile devices and maintains a traditional modal appearance on larger screens.
 */
export const DSResponsiveModal = ({
  open,
  onDismiss,
  title,
  subText,
  children,
  primaryFooterButtons,
  secondaryFooterButtons,
  maxWidth = 'md',
  customMaxWidth,
  fullScreenOnMobile = true,
  dismissable = true,
  showKeepOpenToggle = false,
  keepOpenToggleLabel = 'Keep open',
  keepOpenToggleValue = false,
  onKeepOpenToggleChange,
}: DSResponsiveModalProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = useCallback(() => {
    onDismiss();
  }, [onDismiss]);

  const handleButtonClick = useCallback(
    async (button: FooterButton) => {
      try {
        // Call the button's click handler and wait for it to complete
        const result = await Promise.resolve(button.onClick());

        // Only close if it's not a primary button or if keep-open is off
        const isPrimaryButton = primaryFooterButtons?.includes(button);
        const shouldClose = !isPrimaryButton || !keepOpenToggleValue;

        if (shouldClose) {
          onDismiss();
        }
      } catch (error) {
        // Prevent synthetic events from being thrown in promise rejections
        if (error && typeof error === 'object' && '_reactName' in error) {
          return;
        }
        // Re-throw other errors
        throw error;
      }
    },
    [onDismiss, primaryFooterButtons, keepOpenToggleValue],
  );

  const hasHeader = title || subText;
  const hasFooter =
    primaryFooterButtons?.length || secondaryFooterButtons?.length;

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullScreenOnMobile && isMobile}
      isMobile={isMobile}
      fullScreenOnMobile={fullScreenOnMobile}
      customMaxWidth={customMaxWidth}
    >
      {(hasHeader || dismissable) && (
        <StyledDialogHeader>
          <StyledHeaderContent>
            {title && <StyledTitle variant="h6">{title}</StyledTitle>}
            {subText && (
              <StyledSubtitle variant="body2">{subText}</StyledSubtitle>
            )}
          </StyledHeaderContent>
          {dismissable && (
            <StyledCloseButton onClick={onDismiss} aria-label="close">
              <CloseIcon />
            </StyledCloseButton>
          )}
        </StyledDialogHeader>
      )}
      <StyledDialogContent
        isMobile={isMobile}
        fullScreenOnMobile={fullScreenOnMobile}
      >
        <StyledContentStack
          spacing={3}
          isMobile={isMobile}
          fullScreenOnMobile={fullScreenOnMobile}
        >
          {children}
        </StyledContentStack>
      </StyledDialogContent>
      {hasFooter && (
        <StyledDialogFooter>
          <StyledFooterButtonGroup>
            {secondaryFooterButtons?.map((button, index) => (
              <DSButton
                key={index}
                onClick={() => handleButtonClick(button)}
                disabled={button.disabled}
                variant="outlined"
              >
                {button.children}
              </DSButton>
            ))}
          </StyledFooterButtonGroup>
          <StyledPrimaryGroup>
            {showKeepOpenToggle && (
              <StyledKeepOpenLabel
                control={
                  <DSSwitch
                    checked={keepOpenToggleValue}
                    onChange={(e) => onKeepOpenToggleChange?.(e.target.checked)}
                    branded
                  />
                }
                label={keepOpenToggleLabel}
              />
            )}
            {primaryFooterButtons?.map((button, index) => (
              <DSButton
                key={index}
                onClick={() => handleButtonClick(button)}
                disabled={button.disabled}
              >
                {button.children}
              </DSButton>
            ))}
          </StyledPrimaryGroup>
        </StyledDialogFooter>
      )}
    </StyledDialog>
  );
};
