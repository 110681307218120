import { Box, FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useMilestonesActionItemsFiltersStore from '../../../../Stores/milestonesActionItemsFiltersStore';
import useDeviceType from '../../../../Hooks/deviceDetection';
import { useCallback } from 'react';
import { DateTime } from 'luxon';

export const DateRangeFilter = () => {
  const { isMobile } = useDeviceType();

  // Store values and setters
  const startDate = useMilestonesActionItemsFiltersStore(
    (state) => state.startDate,
  );
  const endDate = useMilestonesActionItemsFiltersStore(
    (state) => state.endDate,
  );
  const setStartDate = useMilestonesActionItemsFiltersStore(
    (state) => state.setStartDate,
  );
  const setEndDate = useMilestonesActionItemsFiltersStore(
    (state) => state.setEndDate,
  );

  // Handle start date changes
  const handleStartDateChange = useCallback(
    (newValue: DateTime | null) => {
      setStartDate(newValue);
      // Clear end date if it's before the new start date
      if (newValue && endDate && endDate < newValue) {
        setEndDate(null);
      }
    },
    [endDate, setStartDate, setEndDate],
  );

  // Handle end date changes
  const handleEndDateChange = useCallback(
    (newValue: DateTime | null) => {
      // Set end date only if it's valid (null, or after start date)
      if (!newValue || !startDate || newValue >= startDate) {
        setEndDate(newValue);
      }
    },
    [startDate, setEndDate],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 2,
        width: { xs: '100%', sm: 'auto' },
      }}
    >
      <FormControl size="small" sx={{ width: { xs: '100%', sm: 250 } }}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          slotProps={{
            textField: {
              size: 'small',
              label: 'Start Date',
              id: 'start-date',
              fullWidth: true,
            },
            dialog: {
              sx: {
                '& .MuiDialog-paper': {
                  margin: isMobile ? 2 : 'auto',
                },
              },
            },
          }}
        />
      </FormControl>
      <FormControl size="small" sx={{ width: { xs: '100%', sm: 250 } }}>
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          minDate={startDate || undefined}
          slotProps={{
            textField: {
              size: 'small',
              label: 'End Date',
              id: 'end-date',
              fullWidth: true,
            },
            dialog: {
              sx: {
                '& .MuiDialog-paper': {
                  margin: isMobile ? 2 : 'auto',
                },
              },
            },
          }}
        />
      </FormControl>
    </Box>
  );
};
