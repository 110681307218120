import { Icon, IconButton, mergeStyleSets } from '@fluentui/react';
import { artifactTypeToIconName } from '../../../Helpers/IconHelpers';
import { useCallback } from 'react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useNavigate } from '../../../Hooks/useNavigate';
import { Artifact } from '../../../types/MilestoneTypes';
import { DeleteIconProps } from '../../../utils/iconProps';
import { getArtifactLink } from '../utils/artifactUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';

type MilestoneArtifactsTableProps = {
  artifacts: Artifact[];
  organizationSlug: string;
  dealRoomId: number;
  handleClickDeleteArtifact: (artifactId: number) => Promise<void>;
};

export const MilestoneArtifactsTable = ({
  artifacts,
  organizationSlug,
  dealRoomId,
  handleClickDeleteArtifact,
}: MilestoneArtifactsTableProps) => {
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate();

  const handleOnClick = useCallback(async (artifactId: number) => {
    const token = await getAccessTokenSilently();
    const artifact = await DealRoomsApiClient.getArtifact(
      organizationSlug!,
      dealRoomId!,
      artifactId,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    navigate(
      getArtifactLink(organizationSlug, dealRoomId, artifact.data),
      { preserveQuery: true },
    );
  },
    [getAccessTokenSilently,dealRoomId, navigate, organizationSlug],
  );

  const iconName = useCallback((artifact: Artifact) => {
    return artifactTypeToIconName(
      artifact.type,
      artifact.mimeType || undefined,
    );
  }, []);

  const styles = mergeStyleSets({
    attachmentsList: {
      display: 'flex',
      flexDirection: 'column',
    },
    attachment: {
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
    },
    noAttachment: {
      lineHeight: '1.75rem',
      fontSize: '.8125rem',
      color: DEALROOMS_COLORS.themePrimary,
    },
    attachmentDetails: {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      padding: '.5rem 1rem',
    },
    bottomLine: {
      width: '100%',
      height: '1px',
      backgroundColor: DEALROOMS_COLORS.neutralLight,
    },
    fileIcon: {
      fontSize: '1.25rem',
      color: DEALROOMS_COLORS.cloudburst,
      marginRight: '.5rem',
    },
    removeIconButton: {
      marginLeft: 'auto',
      height: '1.25rem',
      width: '1.25rem',
      i: {
        fontSize: '1.25rem',
      },
    },
    fileName: {
      flex: 1,
      maxWidth: '240px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '400',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem 0 0 0 ',
    },
    headerBottomLine: {
      width: '100%',
      height: '1px',
      backgroundColor: DEALROOMS_COLORS.neutralLight,
      marginTop: '.5rem',
    },
  });

  if (!artifacts.length) {
    return null;
  }

  return (
    <>
      <div className={styles.header}>
        <span>Name</span>
        <div className={styles.headerBottomLine}></div>
      </div>
      <div className={styles.attachmentsList}>
        {artifacts.map((artifact) => (
          <div
            key={artifact.id}
            className={styles.attachment}
            onClick={() => handleOnClick(artifact.id)}
          >
            <div className={styles.attachmentDetails}>
              <Icon className={styles.fileIcon} iconName={iconName(artifact)} />
              <span className={styles.fileName}>
                {artifact.name || artifact.label || 'Artifact'}
              </span>
              <IconButton
                className={styles.removeIconButton}
                iconProps={DeleteIconProps}
                onClick={async (e) => {
                  e.stopPropagation();
                  await handleClickDeleteArtifact(artifact.id);
                }}
              />
            </div>
            <div className={styles.bottomLine}></div>
          </div>
        ))}
      </div>
    </>
  );
};
