import { Companies } from '@meetingflow/common/Api/Companies';
import { Contacts } from '@meetingflow/common/Api/Contacts';
import { DealRooms } from '@meetingflow/common/Api/DealRooms';
import { Events } from '@meetingflow/common/Api/Events';
import { ExternalServices } from '@meetingflow/common/Api/ExternalServices';
import { Invites } from '@meetingflow/common/Api/Invites';
import { Meetingflows } from '@meetingflow/common/Api/Meetingflows';
import { Members } from '@meetingflow/common/Api/Members';
import { Organizations } from '@meetingflow/common/Api/Organizations';
import { Reporting } from '@meetingflow/common/Api/Reporting';
import { Requests } from '@meetingflow/common/Api/Requests';
import { Search } from '@meetingflow/common/Api/Search';
import { Tags } from '@meetingflow/common/Api/Tags';
import { Tasks } from '@meetingflow/common/Api/Tasks';
import { Templates } from '@meetingflow/common/Api/Templates';
import { User } from '@meetingflow/common/Api/User';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import axios, { AxiosRequestConfig, ParamsSerializerOptions } from 'axios';
import { stringify } from 'qs';

axios.defaults.withCredentials = true;

const defaultParamsSerializer = (
  params: Record<string, unknown>,
  options?: ParamsSerializerOptions,
  //@ts-ignore
) => {
  const stringified = stringify(params, {
    arrayFormat: 'brackets',
    indices: false,
    encodeValuesOnly: true,
    skipNulls: false,
    strictNullHandling: true,
  });

  return stringified;
};

export const CompaniesApiClient = new Companies({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const ContactsApiClient = new Contacts({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const DealRoomsApiClient = new DealRooms({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const EventsApiClient = new Events({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const ExternalServicesApiClient = new ExternalServices({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const InvitesApiClient = new Invites({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const MeetingflowsApiClient = new Meetingflows({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const MembersApiClient = new Members({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const OrganizationsApiClient = new Organizations({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const ReportingApiClient = new Reporting({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const RequestsApiClient = new Requests({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const SearchApiClient = new Search({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const TagsApiClient = new Tags({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const TasksApiClient = new Tasks({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const TemplatesApiClient = new Templates({
  paramsSerializer: { serialize: defaultParamsSerializer },
});
export const UserApiClient = new User({
  paramsSerializer: { serialize: defaultParamsSerializer },
});

export const ApiClient = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: { serialize: defaultParamsSerializer },
  withCredentials: true,
});

export const RecordingShareApiClient = axios.create({
  baseURL: '/recording-share',
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: { serialize: defaultParamsSerializer },
  withCredentials: true,
});

export const TextClient = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'text/plain',
  },
  paramsSerializer: { serialize: defaultParamsSerializer },
  withCredentials: true,
});

export const FileClient = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  paramsSerializer: { serialize: defaultParamsSerializer },
  withCredentials: true,
});

export const CallRecordingsApiClient = {
  deleteSuggestedActionItem: (
    params: {
      organizationSlug: string;
      meetingPlanId: string;
      index: number;
    },
    config?: AxiosRequestConfig
  ) =>
    axios.delete<void>(
      `/api/organization/${params.organizationSlug}/plan/${params.meetingPlanId}/call-recording/suggested-action-item/${params.index.toString()}`,
      config
    ),
};

export const setAppInsights = (appInsights: ApplicationInsights) => {
  CompaniesApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  ContactsApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  DealRoomsApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  EventsApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  ExternalServicesApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  InvitesApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  MeetingflowsApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  MembersApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  OrganizationsApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  ReportingApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  RequestsApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  SearchApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  TagsApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  TasksApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  UserApiClient.instance.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  ApiClient.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  TextClient.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
  FileClient.interceptors.request.use((config) => {
    config.headers!['X-Session-Id'] = appInsights.context.getSessionId();
    config.headers!['X-Tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
};
