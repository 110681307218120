import {
  DefaultButton,
  Dialog,
  DialogFooter,
  ITextField,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { externalize, isUrl } from '../../Helpers/URLHelpers';
import { LinkContent } from '../../types/LinkContent';
import { useDeferredPromise } from '../useDeferredPromise';
import { useLightOrDarkMode } from '../useLightOrDarkMode';
import { ConferenceInfo } from '@meetingflow/common/Api/data-contracts';
import { tryParseConferenceInfo } from '@meetingflow/common/StringHelpers';

export type ConferenceJoinLinkContent = {
  conferenceInfo: ConferenceInfo;
};

type ConferenceJoinLinkDialogProps = {
  initialJoinURI?: string;
  onSubmit: (
    value: ConferenceJoinLinkContent | PromiseLike<ConferenceJoinLinkContent>,
  ) => void;
  onCancel: (reason?: unknown) => void;
};

const ConferenceJoinLinkDialog = ({
  initialJoinURI,
  onSubmit,
  onCancel,
}: ConferenceJoinLinkDialogProps) => {
  const { isDark } = useLightOrDarkMode();

  const [joinURI, setJoinURI] = useState<string>(initialJoinURI || '');

  const conferenceInfo = useMemo(
    () => (joinURI ? tryParseConferenceInfo(joinURI) || null : null),
    [joinURI],
  );

  useEffect(() => {
    setJoinURI(initialJoinURI || '');
  }, [initialJoinURI]);

  const joinURIFieldRef = useRef<ITextField>(null);

  useEffect(() => {
    // Effects run after the render pass, but before the DOM has necessarily finished updating, so the refs may be null
    // Use set immediate to execute after one tick, so the DOM has finished updating and the refs are bound
    setImmediate(() => {
      if (joinURIFieldRef.current) {
        joinURIFieldRef.current.focus();
      }
    });
  }, [initialJoinURI, joinURIFieldRef]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && conferenceInfo?.joinUri) {
        onSubmit({ conferenceInfo: conferenceInfo });
      }
    },
    [conferenceInfo, onSubmit],
  );

  const errorMessage = useMemo(() => {
    if (!joinURI) {
      return undefined;
    }
    if (!conferenceInfo) {
      return `${joinURI} is not a recognized video conferencing invite link.`;
    }
    if (conferenceInfo.type === 'ciscoWebEx') {
      return `WebEx is not a supported platform`;
    } else if (conferenceInfo.type === 'skypeForBusiness') {
      return `Skype for Business is not a supported platform`;
    } else if (conferenceInfo.type === 'skype') {
      return `Skype is not a supported platform`;
    }
  }, [conferenceInfo, joinURI]);

  return (
    <Dialog
      dialogContentProps={{
        title: 'Record Call',
        subText: `Enter a video conferencing invite link from your meeting provider (Zoom, Teams, Google Meet, etc.) to record the call.`,
        showCloseButton: true,
        styles: {
          subText: { marginTop: -12 }
        },
      }}
      hidden={false}
      styles={{ main: { width: '60%' } }}
      onDismiss={onCancel}
    >
      <div>
        <TextField
          placeholder="Enter video conferencing invite link"
          errorMessage={errorMessage}
          value={joinURI}
          componentRef={joinURIFieldRef}
          onKeyDown={handleKeyPress}
          onChange={(_e, newText) => setJoinURI(newText || '')}
        />
      </div>
      <DialogFooter>
        <PrimaryButton
          text="Record"
          disabled={!conferenceInfo || !!errorMessage}
          onClick={() => {
            if (!conferenceInfo) {
              return;
            }
            onSubmit({ conferenceInfo });
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
        <DefaultButton text="Cancel" onClick={onCancel} />
      </DialogFooter>
    </Dialog>
  );
};

export const useGetConferenceJoinLinkDialog = () => {
  const { createDeferred, deferred, context, resolve, reject } =
    useDeferredPromise<
      ConferenceJoinLinkContent,
      { joinURI?: string } | undefined
    >();

  const dialog = useMemo(() => {
    if (!deferred?.isPending) {
      return null;
    }

    return (
      <ConferenceJoinLinkDialog
        initialJoinURI={context?.joinURI}
        onSubmit={resolve}
        onCancel={reject}
      />
    );
  }, [context, deferred?.isPending, reject, resolve]);

  return {
    createDeferred,
    dialog,
  };
};
