import React from 'react';
import { Storage } from '@mui/icons-material';
import { DSTooltip } from '../../DealRoom/DS/DSTooltip';
import { MeetingflowIconContainer, MeetingflowIcon } from '../DecisionSiteMeetingflowCard.styles';
import { DetailedMeetingflow } from '@meetingflow/common/Api/data-contracts';
import StyledDateTime from '../../StyledDateTime';

interface Props {
  meetingflow: DetailedMeetingflow;
  hideCRMIcon?: boolean;
}

export const DecisionSiteMeetingflowCardLastLoggedIcon: React.FC<Props> = ({ 
  meetingflow,
  hideCRMIcon
}) => {
  if (!meetingflow?.lastLoggedToCrm?.loggedAt || hideCRMIcon) {
    return null;
  }

  return (
    <DSTooltip
      title={
        <>
          Logged To CRM,
          <StyledDateTime
            useRelativeDates
            dateTime={meetingflow.lastLoggedToCrm.loggedAt}
          />{' '}
          by {meetingflow.lastLoggedToCrm.loggedBy?.name}
        </>
      }
    >
      <MeetingflowIconContainer>
        <MeetingflowIcon>
          <Storage />
        </MeetingflowIcon>
      </MeetingflowIconContainer>
    </DSTooltip>
  );
};
