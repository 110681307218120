import { DealRoomLinkArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';
import { getYouTubeVideoId } from '../utils/youtubeUtils';
import { DSYouTubeEmbed } from '../DS/DSYouTubeEmbed';
import { getLoomVideoId } from '../utils/loomUtils';
import { getVimeoVideoId } from '../utils/vimeoUtils';
import { DSLoomEmbed, DSVimeoEmbed } from '../DS';

export type LinkArtifactCardProps = BaseArtifactCardProps &
  Pick<DealRoomLinkArtifact, 'url' | 'thumbnailUrl'>;

export const LinkArtifactCard = ({
  url,
  orgSlug,
  dealRoomId,
  label,
  name,
  thumbnailUrl,
  ...rest
}: LinkArtifactCardProps) => {
  // For Artifacts with type LINK, we are special-casing YouTube videos based on their URL
  // so that we can embed them in the card in a useful way using YouTube's embed API
  const youtubeVideoId = url ? getYouTubeVideoId(url) : null;
  const isYouTubeVideo = !!youtubeVideoId;

  const loomVideoId = url ? getLoomVideoId(url) : null;
  const isLoomVideo = !!loomVideoId;

  const vimeoVideoId = url ? getVimeoVideoId(url) : null;
  const isVimeoVideo = !!vimeoVideoId;

  return (
    <BaseArtifactCard
      className="link-artifact"
      orgSlug={orgSlug}
      dealRoomId={dealRoomId}
      label={label}
      name={name}
      {...rest}
    >
      {isYouTubeVideo ? (
        <DSYouTubeEmbed videoId={youtubeVideoId} title={label || name} />
      ) : isLoomVideo ? (
        <DSLoomEmbed videoId={loomVideoId} title={label || name} />
      ) : isVimeoVideo ? (
        <DSVimeoEmbed videoId={vimeoVideoId} title={label || name} />
      ) : (
        <ImageWithFallback
          src={
            thumbnailUrl ||
            `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=50`
          }
          fallbackSrc={
            thumbnailUrl
              ? `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=50`
              : undefined
          }
          alt={label || name}
          fallback={<ArtifactIconPreview type="LINK" />}
          objectFit="contain"
        />
      )}
    </BaseArtifactCard>
  );
};
