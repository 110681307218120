import React from 'react';
import { Container, Embed } from './DSVimeoEmbed.styles';

export interface DSVimeoEmbedProps {
  /**
   * The Vimeo video ID or share URL
   */
  videoId: string;
  /**
   * Title for the iframe (for accessibility)
   */
  title: string;
  /**
   * Whether to hide the title
   * @default false
   */
  hideTitle?: boolean;
  /**
   * Whether to hide the byline (author)
   * @default false
   */
  hideByline?: boolean;
  /**
   * Whether to start the video muted
   * @default false
   */
  startMuted?: boolean;
}

/**
 * A responsive Vimeo video embed component that maintains 16:9 aspect ratio
 */
export const DSVimeoEmbed = ({
  videoId,
  title,
  hideTitle = false,
  hideByline = false,
  startMuted = false,
}: DSVimeoEmbedProps) => {
  // Extract video ID from URL if full URL is provided
  const extractedVideoId = videoId.includes('vimeo.com')
    ? videoId.split('/').pop()
    : videoId;

  const params = new URLSearchParams({
    title: hideTitle ? '0' : '1',
    byline: hideByline ? '0' : '1',
    muted: startMuted ? '1' : '0',
    dnt: '1', // Do Not Track
  });

  return (
    <Container>
      <Embed
        title={title}
        src={`https://player.vimeo.com/video/${extractedVideoId}?${params.toString()}`}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </Container>
  );
};

export default DSVimeoEmbed;
