import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const useCommentsContainerStyles = () =>
  mergeStyleSets({
    panel: {
      backgroundColor: DEALROOMS_COLORS.financialLighterGray,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    headerButton: {
      marginLeft: '1rem',
      i: {
        fontSize: '1.25rem',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.25rem',
      height: '100%',
      overflow: 'hidden',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width: '100%',
      flexGrow: 1,
      overflow: 'auto',
      height: '100%',
    },
    backToConvsButton: {
      border: 'none',
      padding: '0.8rem 0.75rem;',
      i: {
        margin: '0 1rem 0 0',
      },
      ':hover': {
        color: 'inherit',
        i: {
          color: 'inherit',
        },
      },
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '.25rem',
      padding: '0 1rem',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '0.9375rem',
      lineHeight: '2rem',
      fontWeight: '500',
    },
    taskName: {
      padding: '0 1rem',
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: '400',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });
