import { TableRowItem } from './DealRoomArtifactsTable';
import {
  IButtonStyles,
  Icon,
  IContextualMenuItem,
  IContextualMenuProps,
  mergeStyles,
  PrimaryButton,
} from '@fluentui/react';
import { useShareDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useHandleOnFeature } from './useArtifactsOnFeature';

export interface ArtifactsTableActionsProps {
  useForTable?: boolean;
  userId: number | undefined;
  dealRoomRole: string | undefined;
  itemData: TableRowItem;
  organizationSlug: string;
  dealRoomId: number;
  refreshDealRoom: () => Promise<unknown>;
  refetchArtifacts: () => Promise<unknown>;
  onDelete: ((id: DealRoomArtifact['id']) => Promise<unknown>) | undefined;
}

export const ArtifactsTableActions: React.FC<ArtifactsTableActionsProps> = ({
  useForTable,
  userId,
  dealRoomRole,
  itemData,
  organizationSlug,
  dealRoomId,
  refreshDealRoom,
  refetchArtifacts,
  onDelete,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug: organizationSlug || '',
    artifactName: itemData.name,
  });

  const handleClickDelete = useCallback(
    async (
      e:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.KeyboardEvent<HTMLElement>
        | undefined,
      soft: boolean,
    ) => {
      e?.preventDefault();
      e?.stopPropagation();

      try {
        if (userId !== itemData.creator?.id || !onDelete) {
          return;
        }
        const token = await getAccessTokenSilently();

        // DELETE ITEM
        await toast.promise(
          DealRoomsApiClient.deleteArtifact(
            {
              organizationSlug,
              dealRoomId,
              artifactId: itemData.artifactId,
              soft: soft,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Deleting Artifact',
            success: (_response) => {
              refetchArtifacts();
              onDelete(itemData.artifactId);
              if (soft) {
                return 'Successfully deleted Artifact.';
              } else {
                return 'Successfully removed Artifact from Trash.';
              }
            },
            error: (err: unknown) => {
              return 'Something went wrong deleting the artifact, please try again later.';
            },
          },
        );
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [
      organizationSlug,
      dealRoomId,
      itemData.artifactId,
      itemData.creator?.id,
      onDelete,
      getAccessTokenSilently,
      userId,
      refetchArtifacts,
    ],
  );

  const handleClickRestore = useCallback(
    async (
      e:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.KeyboardEvent<HTMLElement>
        | undefined,
    ) => {
      e?.preventDefault();
      e?.stopPropagation();
      try {
        if (!onDelete) {
          return;
        }
        const token = await getAccessTokenSilently();

        // RESTORE ITEM
        await toast.promise(
          DealRoomsApiClient.undeleteArtifact(
            organizationSlug!,
            dealRoomId,
            itemData.artifactId,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Restoring Artifact',
            success: (_response) => {
              refetchArtifacts();
              onDelete(itemData.artifactId);
              return 'Successfully restored Artifact.';
            },
            error: (err: unknown) => {
              return 'Something went wrong restoring Artifact, please try again later';
            },
          },
        );
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [
      organizationSlug,
      getAccessTokenSilently,
      dealRoomId,
      itemData.artifactId,
      onDelete,
      refetchArtifacts,
    ],
  );

  const handleUpvote = async (
    e:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
      | undefined,
  ) => {
    e?.preventDefault();
    e?.stopPropagation();
    try {
      const token = await getAccessTokenSilently();
      await toast.promise(
        !itemData.upvoted
          ? DealRoomsApiClient.upvoteArtifact(
              organizationSlug!,
              dealRoomId,
              itemData.artifactId,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            )
          : DealRoomsApiClient.removeArtifactUpvote(
              organizationSlug!,
              dealRoomId,
              itemData.artifactId,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
        {
          loading: 'Upvoting Artifact',
          success: (_response) => {
            refreshDealRoom();
            return `Upvoted Artifact`;
          },
          error: (err: unknown) => {
            return 'Something went wrong upvoting Artifact, please try again later';
          },
        },
      );
      await refetchArtifacts();
      await refreshDealRoom();
    } catch (err: unknown) {
      console.error(err);
    }
  };

  const handleDownvote = async (
    e:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
      | undefined,
  ) => {
    e?.preventDefault();
    e?.stopPropagation();
    try {
      const token = await getAccessTokenSilently();
      await toast.promise(
        !itemData.downvoted
          ? DealRoomsApiClient.downvoteArtifact(
              organizationSlug!,
              dealRoomId,
              itemData.artifactId,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            )
          : DealRoomsApiClient.removeArtifactDownvote(
              organizationSlug!,
              dealRoomId,
              itemData.artifactId,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
        {
          loading: 'Downvoting Artifact',
          success: (_response) => {
            return 'Downvoted Artifact';
          },
          error: (err: unknown) => {
            return 'Something went wrong downvoting Artifact, please try again later';
          },
        },
      );
      await refetchArtifacts();
      await refreshDealRoom();
    } catch (err: unknown) {
      console.error(err);
    }
  };

  const { handleOnFeature } = useHandleOnFeature({
    organizationSlug,
    dealRoomId,
    refetchArtifacts,
  });

  const containerStyles = mergeStyles({
    display: 'flex',
    alignItems: 'flex-end',
    gap: '.25rem',
  });

  const buttonStyles = {
    menuIcon: {
      display: 'none',
    },
    root: {
      padding: '0',
      minWidth: '2rem',
      backgroundColor: 'transparent !important',
      border: 'none',
      transition: 'all 0.3s ease-in-out',

      selectors: {
        i: { color: DEALROOMS_COLORS.themePrimary },
      },
    },
    rootDisabled: {
      opacity: '0.6',
      cursor: 'not-allowed',
    },
    rootHovered: {
      border: `1px solid ${DEALROOMS_COLORS.themePrimary}`,
      backgroundColor: 'transparent !important',
    },
    rootPressed: {
      border: `1px solid ${DEALROOMS_COLORS.themePrimary}`,
      backgroundColor: 'transparent !important',
    },
    rootFocused: {
      border: `1px solid transparent`,
      backgroundColor: 'transparent !important',
    },
    rootExpanded: {
      border: `1px solid transparent`,
      backgroundColor: 'transparent !important',
    },
  } as IButtonStyles;

  // @ts-ignore
  const menuProps: () => IContextualMenuProps = () => ({
    styles: {
      root: {
        backgroundColor: DEALROOMS_COLORS.white,
      },
    },
    calloutProps: {
      styles: {
        root: {},
        container: {
          backgroundColor: DEALROOMS_COLORS.white,
          border: `1px solid ${DEALROOMS_COLORS.financialLightGray}`,

          '.ms-ContextualMenu-item': {
            color: DEALROOMS_COLORS.cloudburst,

            '.ms-ContextualMenu-item-linkText': {
              color: DEALROOMS_COLORS.themePrimary,
            },

            '.is-disabled *': {
              color: `${DEALROOMS_COLORS.financialPrimaryGray} !important`,
            },

            selectors: {
              ':hover': {
                color: DEALROOMS_COLORS.themePrimary,
                cursor: 'pointer',

                '.ms-ContextualMenu-itemText': {
                  color: DEALROOMS_COLORS.themePrimary,
                },

                i: {
                  color: DEALROOMS_COLORS.themePrimary,
                },
              },
            },

            i: {
              color: DEALROOMS_COLORS.cloudburst,
            },
          },
        },
      },
    },
    items: [
      ...(itemData.deletedAt
        ? [
            {
              disabled: userId !== itemData.creator?.id,
              key: 'restore',
              text: 'Restore',
              iconProps: { iconName: 'Undo' },
              onClick: (
                ev?:
                  | React.MouseEvent<HTMLElement>
                  | React.KeyboardEvent<HTMLElement>,
                item?: IContextualMenuItem,
              ) => {
                handleClickRestore(ev);
              },
            },
            {
              disabled: userId !== itemData.creator?.id,
              key: 'delete',
              text: 'Remove from Trash',
              iconProps: { iconName: 'Delete' },
              onClick: (
                ev?:
                  | React.MouseEvent<HTMLElement>
                  | React.KeyboardEvent<HTMLElement>,
                item?: IContextualMenuItem,
              ) => {
                handleClickDelete(ev, false); // hard delete
              },
            },
          ]
        : [
            {
              disabled: userId !== itemData.creator?.id,
              key: 'delete',
              text: 'Delete',
              iconProps: { iconName: 'Delete' },
              onClick: (
                ev?:
                  | React.MouseEvent<HTMLElement>
                  | React.KeyboardEvent<HTMLElement>,
                item?: IContextualMenuItem,
              ) => {
                handleClickDelete(ev, true); // soft delete
              },
            },
          ]),
      {
        disabled: !!itemData.deletedAt,
        key: 'upvote',
        text: itemData.upvoted ? 'Remove Upvote' : 'Upvote',
        iconProps: { iconName: itemData.upvoted ? 'LikeSolid' : 'Like' },
        onClick: (
          ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem,
        ) => {
          handleUpvote(ev);
        },
      },
      {
        disabled: !!itemData.deletedAt,
        key: 'downvote',
        text: itemData.downvoted ? 'Remove Downvote' : 'Downvote',
        iconProps: {
          iconName: itemData.downvoted ? 'DislikeSolid' : 'Dislike',
        },
        onClick: (
          ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
          item?: IContextualMenuItem,
        ) => {
          handleDownvote(ev);
        },
      },
      ...(!useForTable
        ? [
            {
              disabled: dealRoomRole !== 'SELLER' || !!itemData.deletedAt,
              key: 'feature',
              text: itemData.featuredAt
                ? 'Remove from Featured Artifacts'
                : 'Add to Featured Artifacts',
              iconProps: itemData.featuredAt
                ? { iconName: 'FavoriteStarFill' }
                : { iconName: 'FavoriteStar' },
              onClick: (
                ev?:
                  | React.MouseEvent<HTMLElement>
                  | React.KeyboardEvent<HTMLElement>,
                item?: IContextualMenuItem,
              ) => {
                handleOnFeature(!itemData.featuredAt, itemData.artifactId);
              },
            },
          ]
        : []),
    ].filter(Boolean),
  });

  return (
    <div className={containerStyles}>
      <PrimaryButton
        menuProps={menuProps()}
        styles={buttonStyles}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Icon iconName="More" />
      </PrimaryButton>
      {confirmShareDialog}
      {/* {confirmDeleteDialog} */}
    </div>
  );
};
