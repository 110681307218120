import { styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const PreviewContainer = styled('div')({
  padding: '1.5rem',
  width: 'calc(100% - 3rem)',
  height: 'calc(100% - 3rem)',
  background: DEALROOMS_COLORS.neutralGray,
  overflow: 'auto',
});

export const YouTubeContainer = styled('div')({
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '4px',
  overflow: 'hidden',
});

export const LoomContainer = styled('div')({
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '4px',
  overflow: 'hidden',
});

export const VimeoContainer = styled('div')({
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '4px',
  overflow: 'hidden',
});

export const YouTubeEmbed = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 'none',
});

export const AudioPreview = styled('audio')({
  width: '100%',
  maxWidth: 'calc(100% - 2rem - 2px)',
  maxHeight: 'calc(100% - 2rem - 2px)',
  padding: '1rem',
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '4px',
});

export const ImagePreview = styled('div')({
  height: 'auto',
  maxWidth: 'calc(100% - 2rem - 2px)',
  // maxHeight: 'calc(100% - 2rem - 2px)',
  padding: '1rem',
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '4px',
});

export const VideoPreview = styled('video')({
  width: '100%',
  maxWidth: 'calc(100% - 2rem - 2px)',
  maxHeight: 'calc(100% - 2rem - 2px)',
  padding: '1rem',
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '4px',
});

export const DocumentPreview = styled('div')({
  width: '100%',
  height: '100%',
  backgroundColor: DEALROOMS_COLORS.white,
  color: DEALROOMS_COLORS.black,
  maxWidth: 'calc(100% - 2rem - 2px)',
  maxHeight: 'calc(100% - 2rem - 2px)',
  padding: '1rem',
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '4px',
  overflow: 'auto',

  '#pdf-controls': {
    display: 'flex',
    flexDirection: 'row',
    gap: '.25rem',
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    'a, button, svg, svg *': {
      boxShadow: 'none',
      backgroundColor: DEALROOMS_COLORS.financialLighterGray,
      color: DEALROOMS_COLORS.themeSecondary,
      fill: DEALROOMS_COLORS.themeSecondary,
    },

    svg: {
      backgroundColor: 'transparent !important',
    },

    '.pdf-page-count': {
      color: DEALROOMS_COLORS.themeSecondary,
    },
  },
});
