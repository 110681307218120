import { FontWeights, mergeStyles } from '@fluentui/react';
import {
  DealRoom,
  DetailedDealRoom,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useUserProfile } from '../../../Hooks/useProfile';
import { isBuyer } from '../../../utils/buyerDecisionSiteUtils';
import { SplitViewLayout } from '../../Layouts/SplitViewLayout';
import { DealRoomUserPrioritiesContainer } from '../Components/DealRoomUserPrioritiesContainer';
import { RightSidePanelCTAs } from '../Components/RightSidePanelCTAs';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useTitle } from '../../../Hooks/useTitle';
import { DealRoomKeyFacts } from '../Components/InfoSection/DealRoomKeyFacts';
import { useAuth0 } from '@auth0/auth0-react';
import { useCalloutCards } from '../../../Hooks/useCalloutCards';
import useDeviceType from '../../../Hooks/deviceDetection';
import { ImportContacts, Schedule } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { InfoSection } from '../Components/InfoSection/InfoSection';
import { DSFeaturedArtifacts } from '../Components/DSFeaturedArtifacts';
import DecisionSiteUpNext from '../Components/DecisionSiteUpNext';

type DealRoomBuyersOverviewTabProps = {
  className?: string;
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  dealRoom: DetailedDealRoom;
  refreshDealRoom: () => Promise<unknown>;
};

export const DealRoomBuyersOverviewTab = ({
  className,
  organizationSlug,
  dealRoomId,
  dealRoom,
  refreshDealRoom,
}: DealRoomBuyersOverviewTabProps) => {
  const { isMobile } = useDeviceType();

  const { user } = useUserProfile();

  useTitle('Overview');

  const { calloutCards } = useCalloutCards(organizationSlug, dealRoomId);

  const hasCalloutCards = useMemo(
    () => (calloutCards ? calloutCards.length > 0 : false),
    [calloutCards],
  );

  const theme = useTheme();

  const styles = mergeStyles({
    '.headerContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '3.5rem',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',

    '.artifacts': {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      overflowX: 'auto',
      gap: '1rem',

      '.artifact': {
        minWidth: isMobile ? 'calc(100% - 2rem - 2px)' : '20rem',
        maxWidth: isMobile ? 'calc(100% - 2rem - 2px)' : '20rem',
      },
    },

    '.pricing-and-timeline': {
      display: 'grid',
      gridTemplateColumns: '1fr max-content',
      gridTemplateAreas: `
        "pricing timeline"
      `,
      gap: '1rem',
      flexDirection: 'row',

      '.pricing': {
        gridArea: 'pricing',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      },

      '.timeline': {
        gridArea: 'timeline',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      },
    },

    '.relevant-artifacts': {},

    '.shared-with-you-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0rem',
      border: `1px solid ${DEALROOMS_COLORS.financialLightGray}`,
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',

      '.shared-with-you-title': {
        fontSize: '1.5rem',
        lineHeight: '2.5rem',
        fontWeight: FontWeights.regular,
        backgroundColor: DEALROOMS_COLORS.white,
        margin: 0,
        borderBottom: `1px solid ${DEALROOMS_COLORS.financialLightGray}`,
        padding: isMobile ? '1rem' : '1rem 1.5rem',
        width: isMobile ? 'calc(100% - 2rem)' : 'calc(100% - 3rem)',
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px',
        display: 'flex',
        alignItems: 'center',

        'i, svg': {
          marginRight: '.5rem',
        },
      },

      '#dealroom-artifacts': {
        border: 'none',
        paddingRight: 0,
      },
    },
  });

  const showBuyerPriorities = useMemo(() => {
    const currentUserDetails = dealRoom?.contacts?.find(
      (contact) => contact.email.toLowerCase() === user?.email?.toLowerCase(),
    );
    if (!currentUserDetails) return false;

    return isBuyer(currentUserDetails.role);
  }, [dealRoom?.contacts, user?.email]);

  return (
    <SplitViewLayout
      layoutName="DealRoomBuyersViewInner"
      header={
        <div className={classNames(className, styles)}>
          {showBuyerPriorities && (
            <DealRoomUserPrioritiesContainer
              dealRoomId={dealRoomId}
              organizationSlug={organizationSlug}
            />
          )}
        </div>
      }
      gap="12px"
      fullWidthHeader
      sidebar={!isMobile && <RightSidePanelCTAs />}
      sidebarWidthPercent={!isMobile && hasCalloutCards ? 25 : 0}
      // footer={<FooterInfoCard />}
      footer={isMobile && <RightSidePanelCTAs />}
    >
      <div className={classNames(className, styles)}>
        <DealRoomKeyFacts />
        <DecisionSiteUpNext />
        <InfoSection
          icon={<ImportContacts fontSize="medium" color="secondary" />}
          title="Featured Artifacts"
        >
          <DSFeaturedArtifacts
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            refreshDealRoom={refreshDealRoom}
            artifacts={dealRoom.artifacts}
            activity={dealRoom.activity}
          />
        </InfoSection>

        <div className="relevant-artifacts"></div>
      </div>
    </SplitViewLayout>
  );
};
