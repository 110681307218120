import {
    FontSizes,
    FontWeights,
    mergeStyles,
    NeutralColors,
    Spinner,
    Text,
    useTheme,
} from '@fluentui/react';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';

// Styles for the wizard modal content
export const modelContentClass = () => {
    return mergeStyles({
        maxWidth: '1024px',
        minHeight: '200px',
        width: '1024px',
        margin: '0 auto',
        boxSizing: 'border-box',
        backgroundColor:  MEETINGFLOW_COLORS.purpleMedium,
        color: MEETINGFLOW_COLORS.black,
        padding: '1rem 1.5rem 1.5rem 1.5rem',

        '.welcome-modal-header': {
            backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
            fontWeight: FontWeights.semibold,
            textAlign: 'left',
            padding: '0',
            marginBottom: '.25rem',
            boxSizing: 'border-box',
            color: MEETINGFLOW_COLORS.white,
            textShadow: '0 0 1px rgba(0,0,0,.5)',
            maxWidth: '75%',

            h2: {
                fontSize: FontSizes.size28,
                fontWeight: FontWeights.semibold,
                margin: '0',
                color: MEETINGFLOW_COLORS.white,
                textAlign: 'left',
            },
            p: {
                fontWeight: FontWeights.semibold,
                fontSize: FontSizes.size16,
                margin: '0 0 1rem 0',
                textAlign: 'left',
                color: MEETINGFLOW_COLORS.purpleGrey,

                span: {
                    color: MEETINGFLOW_COLORS.white,
                },
            },
        },

        '.welcome-modal-header.center': {
            textAlign: 'center',
            margin: '0 auto',

            '*': {
                textAlign: 'center',
            },
        },

        '.wizard-page': {
            // padding: '1rem',
            borderRadius: '.25rem',
            backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
            '.ms-Link': {
                color: MEETINGFLOW_COLORS.teal,
                textDecoration: 'underline',
            },
        },

        '.welcome-wizard-form': {
            display: 'flex',
            flexDirection: 'column',
            gap: '.5rem',
            backgroundColor: MEETINGFLOW_COLORS.white,
            padding: '1rem',
            borderRadius: '.25rem',

            h4: {
                fontSize: FontSizes.size18,
                lineHeight: '20px',
                fontWeight: FontWeights.semibold,
                margin: '0',
                color: MEETINGFLOW_COLORS.black,
                textAlign: 'left',
            },

            'h4.separator': {
                paddingTop: '1rem',
                marginTop: '1rem',
                borderTop: `.5px solid ${MEETINGFLOW_COLORS.purpleMediumDark}`,
            },

            'h4 + p': {
                marginTop: '-.25rem',
                marginBottom: '1rem',
            },

            p: {
                maxWidth: '75%',
                margin: '0 0 1rem 0',
                color: MEETINGFLOW_COLORS.black,
                strong: {
                    fontWeight: FontWeights.semibold,
                },

                a: {
                    color: MEETINGFLOW_COLORS.teal,
                    textDecoration: 'underline',
                },
            },

            'p.discoverability-disabled': {
                display: 'block',
                margin: '0 0 1rem 0',
                backgroundColor: MEETINGFLOW_COLORS.magentaMedium,
                padding: '.25rem .5rem .5rem .5rem',
                borderRadius: '.25rem',
                color: MEETINGFLOW_COLORS.white,

                ':before': {
                    content: '"⚠️"',
                    display: 'inline-block',
                    marginRight: '.25rem',
                },
            },

            '.elements': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: '.5rem',

                '> div': {
                    flex: '1 1 0',
                    position: 'relative',
                },

                'p[class*=errorMessage]': {
                    backgroundColor: MEETINGFLOW_COLORS.magentaMedium,
                    color: MEETINGFLOW_COLORS.white,
                    padding: '.25rem .5rem',
                    borderRadius: '.25rem',
                    display: 'inline-block',
                    position: 'absolute',
                    top: 0,
                    right: 0,

                    span: {
                        ':before': {
                            content: '"⚠️"',
                            display: 'inline-block',
                            marginRight: '.25rem',
                        },
                    },
                },

                '.help-text': {
                    display: 'block',
                    fontSize: FontSizes.size10,
                    color: `rgb(48, 48, 48)`,
                    lineHeight: '1rem',
                    margin: 0,
                    marginTop: '.25rem',
                },
            },

            '.elements.column': {
                flexDirection: 'column',
            },

            '.elements.row': {
                flexDirection: 'row',
            },

            '.elements.center': {
                justifyContent: 'center',
                '*': {
                    margin: '0 auto',
                    textAlign: 'center',
                },
            },

            '.controls': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',

                paddingTop: '1rem',
                marginTop: '1rem',
                borderTop: `.5px solid ${MEETINGFLOW_COLORS.purpleMediumDark}`,

                '.ms-Button, .ms-Button--icon': {
                    transition: 'all .3s ease-in-out',

                    backgroundColor: MEETINGFLOW_COLORS.purpleMediumDark,
                    borderColor: MEETINGFLOW_COLORS.purpleMediumDark,
                    color: MEETINGFLOW_COLORS.white,
                    cursor: 'pointer',

                    ':hover': {
                        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
                        borderColor: MEETINGFLOW_COLORS.purpleMedium,
                    },
                },

                '.ms-Button--primary, .ms-Button.primary': {
                    backgroundColor: MEETINGFLOW_COLORS.teal,
                    borderColor: MEETINGFLOW_COLORS.teal,
                    color: MEETINGFLOW_COLORS.white,
                    cursor: 'pointer',

                    ':hover': {
                        backgroundColor: MEETINGFLOW_COLORS.tealMediumLight,
                        borderColor: MEETINGFLOW_COLORS.tealMediumLight,
                    },
                },
            },

            '.ms-TextField-fieldGroup, .ms-Dropdown, .ms-Dropdown-title': {
                borderColor: `rgba(255,255,255,.35) !important`,
                backgroundColor: NeutralColors.gray20,
                color: MEETINGFLOW_COLORS.black,

                ':after': {
                    // display: 'none',
                    borderColor: `${MEETINGFLOW_COLORS.white} !important`,
                    borderWidth: '1px !important',
                },
            },

            '.ms-Checkbox-checkbox': {
                backgroundColor: MEETINGFLOW_COLORS.teal,
                borderColor: MEETINGFLOW_COLORS.teal,
            },
            '.ms-Checkbox-text': {
                color: MEETINGFLOW_COLORS.black,
            },

            '.ms-TextField-prefix': {
                backgroundColor: MEETINGFLOW_COLORS.purpleMediumDark,
                color: MEETINGFLOW_COLORS.black,
                opacity: '.5',
            },
            '.ms-TextField-description,.ms-TextField-field, .ms-Label, .ms-TextField-fieldGroup': {
                color: MEETINGFLOW_COLORS.black,
            },
        },
    })
};