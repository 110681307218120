import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  mergeStyles,
  NeutralColors,
  Spinner,
  Text,
  useTheme,
} from '@fluentui/react';
import {
  DetailedUser,
  OrganizationInvite,
  OrganizationUserRole,
} from '@meetingflow/common/Api/data-contracts';
import PublicEmailDomains from '@meetingflow/common/PublicEmailDomains';
import { getDomain } from '@meetingflow/common/StringHelpers';
import { useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { CompanyByDomain, OrgSuggestionsQuery } from '../../QueryNames';
import {
  ApiClient,
  MeetingflowsApiClient,
  OrganizationsApiClient,
} from '../../Services/NetworkCommon';
import { BigPictureCompanyDetails } from '../../types/BigPictureCompanyDetails';
import { WelcomeCreateOrganization } from './WelcomeCreateOrganization';
import { WelcomeInvitations } from './WelcomeInvitations';
import { WelcomeOrganizationForbidden } from './WelcomeOrganizationForbidden';
import { WelcomeOrganizationJoin } from './WelcomeOrganizationJoin';
import { WelcomeProfile } from './WelcomeProfile';
import { WelcomeSuggestions } from './WelcomeSuggestions';
import { WelcomeTOS } from './WelcomeTOS';
import { humanizeNumber } from '../../Helpers/Typography';
import { BaseModal } from '../../Components/MeetingPlans/Dialogs/BaseModal';
import { modelContentClass } from './welcomeWizardStyles';
import { useOrganizationSlug } from '../../Hooks/useOrganizationSlug';

/**
 * The WelcomeWizard component guides new users through the onboarding process.
 * It handles:
 * 1. Terms of Service acceptance
 * 2. User profile setup (timezone, etc.)
 * 3. Organization invitations
 * 4. Organization suggestions or creation
 * 
 * @param userRole - The user's role in the organization
 * @param userProfile - The user's profile information
 * @param organizationSlug - The organization's slug
 * @param pendingInvites - List of pending organization invites
 * @param refetchUser - Function to refresh user data
 * @param refetchInvites - Function to refresh invites data
 * @param refetchAll - Function to refresh all data
 */
export type WelcomeWizardProps = {
  userRole?: OrganizationUserRole;
  userProfile?: DetailedUser;
  organizationSlug?: string;
  pendingInvites?: OrganizationInvite[];
  refetchUser: () => Promise<unknown>;
  refetchInvites: () => Promise<unknown>;
  refetchAll: () => Promise<unknown>;
};

/** Development flags to force specific wizard screens to appear */
const SHOW_INVIATIONS = false;
const SHOW_SUGGESTIONS = false;

/**
 * The WelcomeWizard component handles the onboarding flow for new users.
 * The flow consists of:
 * 1. Terms of Service acceptance
 * 2. User profile setup (timezone)
 * 3. Organization invitations
 * 4. Organization suggestions or creation
 */
export const WelcomeWizard = ({
  userRole,
  userProfile,
  organizationSlug,
  pendingInvites,
  refetchUser,
  refetchInvites,
  refetchAll,
}: WelcomeWizardProps) => {
  const { getAccessTokenSilently, user: auth0User } = useAuth0();

  // Extract email domain from auth0 user for organization suggestions
  const emailDomain = useMemo(() => {
    return getDomain(auth0User!.email!);
  }, [auth0User]);

  const client = useQueryClient();

  const navigate = useNavigate();

  const [forceCreate, setForceCreate] = useState<boolean>(false);

  const {
    data: organizationSuggestions,
    isLoading: areOrganizationSuggestionsLoading,
    isFetched: areOrganizationSuggestionsFetched,
    refetch: refetchOrganizationSuggestions,
  } = useQuery(
    OrgSuggestionsQuery,
    async () => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.listOrganizationSuggestions(
        { organization: organizationSlug },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !PublicEmailDomains.includes(emailDomain),
    },
  );

  const {
    data: companyDetails,
    isLoading: areCompanyDetailsLoading,
    isFetched: areCompanyDetailsFetched,
    refetch: refetchCompanyDetails,
  } = useQuery(
    CompanyByDomain(emailDomain),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<BigPictureCompanyDetails>(
        `/company/${emailDomain}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !PublicEmailDomains.includes(emailDomain) },
  );

  // Function to navigate to organization page after setup
  const navigateToOrganization = useCallback(async (organizationSlug: string) => {
    try {
      navigate(`/organization/${organizationSlug}/?firstVisit=true`);
      return await client.invalidateQueries();
    } catch {
      navigate(`/organization/${organizationSlug}`);
      return client.invalidateQueries();
    }
  },
    [client, navigate],
  );

  // Determine which wizard page to show based on user state
  const wizardPage = useMemo(() => {
    if ((areOrganizationSuggestionsLoading && !areOrganizationSuggestionsFetched) || (areCompanyDetailsLoading && !areCompanyDetailsFetched)) {
      return {
        content: <Spinner />,
      };
    }

    const didUserAcceptTOS = !!(userProfile?.tosAccepted);
    const didUserSetTimezone = !!(userProfile?.timezone);
    const doesUserHavePendingInvites = !!(pendingInvites?.length);
    const doesUserHaveOrganizationSuggestions = !!(organizationSuggestions?.data?.length);
    const isUserOnOrganizationPage = !!organizationSlug;

    if (!didUserAcceptTOS) {
      return {
        content: <WelcomeTOS user={userProfile} refetchUser={refetchUser} />,
        title: '👋 Welcome to Meetingflow.',
        subtitle: 'Meetingflow is a powerful tool to help you run better meetings. After a quick setup, you`ll be recording meetings, receiving AI summaries generated from transripts, and a interacting with a powerful AI Chat that learns from your meetings.',
      };
    }

    if (!didUserSetTimezone) {
      return {
        content: <WelcomeProfile user={userProfile} refetchUser={refetchUser} />,
        title: '🙋Your User Profile',
        subtitle: `We need some basic information to get you started. You can change these later in your User Profile.`,
      };
    }

    // list of organizations the user is invited to
    if (doesUserHavePendingInvites || SHOW_INVIATIONS) {
      return {
        title: '💌 You have Workspace Invitations!',
        subtitle: pendingInvites?.length === 1 ? `You've been invited to join a Workspace.` : `You've been invited to join ${humanizeNumber(pendingInvites?.length || 0)} Workspaces.`,
        content: <WelcomeInvitations organizationSlug={organizationSlug} existingRole={userRole} invites={pendingInvites || []} refreshInvites={refetchInvites} />
      };
    }

    if (!isUserOnOrganizationPage || SHOW_SUGGESTIONS) {
      if ((doesUserHaveOrganizationSuggestions && !forceCreate) || SHOW_SUGGESTIONS) {
        return {
          title: '🕵️ You have Workspace Suggestions!',
          subtitle: organizationSuggestions?.data?.length === 1 ? 'Based on your email domain, we found a Workspace you may want to join!' : `Based on your email domain, we found ${humanizeNumber(organizationSuggestions?.data?.length || 0)} Workspaces you may want to join!`,
          content: <WelcomeSuggestions organizationSlug={organizationSlug}
            suggestions={organizationSuggestions?.data || []}
            onJoinOrg={navigateToOrganization}
            onRequestAccess={async () => refetchOrganizationSuggestions()}
            onCancelRequest={refetchOrganizationSuggestions}
            onCreateOwnOrganization={() => {
              refetchCompanyDetails();
              setForceCreate(true);
            }}
          />
        };
      }

      return {
        title: '🗂️ Next, create a Workspace.',
        subtitle: `A Workspace is a hub for you and others you want to collaborate with. Users in the same workspace can view each other's Meetingflows. A Workspace can be used for your entire company, a specific team, or just yourself — and users can be a member of multiple Workspaces.`,
        content: <WelcomeCreateOrganization companyName={companyDetails?.data?.name || companyDetails?.data?.legalName} onOrganizationCreated={navigateToOrganization} />
      };
    } else {
      if (doesUserHaveOrganizationSuggestions) {
        return {
          title: 'Join Workspace',
          subtitle: 'Please join your Workspace.',
          content: <WelcomeOrganizationJoin onJoinOrg={refetchAll} onRequestAccess={refetchOrganizationSuggestions} onCancelRequest={refetchOrganizationSuggestions} organization={organizationSuggestions!.data[0]} />
        };
      }

      return {
        content: <WelcomeOrganizationForbidden />
      };
    }
  }, [organizationSuggestions, areCompanyDetailsFetched, areOrganizationSuggestionsFetched, areCompanyDetailsLoading, areOrganizationSuggestionsLoading, userProfile, pendingInvites, organizationSlug, refetchUser, userRole, refetchInvites, forceCreate, companyDetails?.data?.name, companyDetails?.data?.legalName, navigateToOrganization, refetchOrganizationSuggestions, refetchCompanyDetails, refetchAll]);

  return (
    <BaseModal
      isOpen
      onDismiss={async () => {
        await refetchAll();
      }}
      isBlocking
      contentPadding="0"
      styles={{
        root: {
          margin: '0 auto',
        },

        main: {
          height: 'auto',
          boxSizing: 'border-box',
          borderRadius: '.25rem',
        },
      }}
    >
      <div className={modelContentClass()}>
        <div className={`welcome-modal-header`}>
          <h2>{wizardPage.title}</h2>
          <p className=" subheader">{wizardPage.subtitle}</p>
        </div>
        <div className="wizard-page">{wizardPage.content}</div>
      </div>
    </BaseModal>
  );
};

export default WelcomeWizard;
