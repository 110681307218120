import { DatePickerProps } from '@mui/x-date-pickers';
import { DSDatePickerRoot } from './DSDatePicker.styles';
import { DateTime } from 'luxon';
import { DSTextFieldSize } from './DSTextField';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export type DSDatePickerProps = DatePickerProps<DateTime> & {
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: DSTextFieldSize;
};

const DSDatePicker = ({ size, sx, ...props }: DSDatePickerProps) => {
  const muiSize = size === 'xsmall' ? 'small' : size;
  const customSx =
    size === 'xsmall'
      ? {
          '& .MuiInputBase-root': {
            backgroundColor: DEALROOMS_COLORS.neutralLighter,
            height: '32px',
            minWidth: '100px',
            maxWidth: '115px',
            fontSize: '0.75rem',
          },
          '& .MuiInputBase-input': {
            padding: '4px 8px',
            paddingRight: '0',
            fontSize: '0.875rem',
          },
          '& .MuiInputLabel-root': {
            transform: 'translate(8px, -8px) scale(0.75)',
            fontSize: '0.875rem',
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(8px, -8px) scale(0.75)',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1.2rem',
            color: DEALROOMS_COLORS.cloudburst,
          },
          '& .MuiInputAdornment-root': {
            marginLeft: '0',
            marginRight: '2px',
          },
          '& .MuiIconButton-root': {
            padding: '4px',
          },
          '& .Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.1) !important',
              backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
            },
          },
          ...sx,
        }
      : {
          '& .Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.1)',
              backgroundColor: '#f0f0f2',
            },
          },
          ...sx,
        };

  return (
    <DSDatePickerRoot
      {...props}
      slotProps={{
        textField: {
          sx: customSx,
          size: muiSize,
        },
      }}
    />
  );
};

export default DSDatePicker;
