import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Button,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DealRoomMilestoneType } from '@meetingflow/common/Api/data-contracts';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { FileFormat } from '../../../types/MilestoneTypes';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';
import { StyledDialogHeader } from '../DS/DSResponsiveModal.styles';

type RequestAttachmentsDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  onResolve: (value: unknown) => void;
  onDismiss: () => void;
  milestoneType: DealRoomMilestoneType;
};

const formatOptions: {
  text: string;
  key: FileFormat;
}[] = [
  { text: 'PDF', key: 'PDF' },
  { text: 'Excel', key: 'Excel' },
  { text: 'Word', key: 'Doc' },
  { text: 'PPT', key: 'PPT' },
];

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 'calc(100vw - 3rem)',
    maxWidth: '538px',
    borderRadius: '.5rem',
    backgroundColor: DEALROOMS_COLORS.white,
    boxShadow: '0px 8px 16px 0px #00000024',
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem 1.5rem',
  color: DEALROOMS_COLORS.themePrimary,
  fontSize: '1.5rem',
  fontWeight: '400',
});

const StyledDialogContent = styled(DialogContent)({
  padding: '0 1.5rem 1.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

export const RequestAttachmentsDialog = ({
  organizationSlug,
  dealRoomId,
  onResolve,
  onDismiss,
  milestoneType,
}: RequestAttachmentsDialogProps) => {
  const [description, setDescription] = useState('');
  const [ownerId, setOwnerId] = useState<number | null>(null);
  const [selectedMilestoneType, setSelectedMilestoneType] =
    useState<DealRoomMilestoneType>(milestoneType || '');
  const [fileFormat, setFileFormat] = useState<FileFormat | null>(null);

  useEffect(() => {
    setSelectedMilestoneType(milestoneType);
  }, [milestoneType]);

  const { getAccessTokenSilently } = useAuth0();

  const { dealRoom } = useDealRoom(organizationSlug, dealRoomId);
  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const { milestonesSummary, refetch: refetchMilestonsSummary } =
    useMilestonesSummary(organizationSlug, dealRoomId);

  const dealRoomContactsOptions = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return [];

    return dealRoom.contacts.map((contact) => ({
      key: contact.id,
      text: contact.name || contact?.email,
    }));
  }, [dealRoom?.contacts]);

  const isRequestButtonDisabled = useMemo(() => {
    return !description || ownerId === null || fileFormat === null;
  }, [description, fileFormat, ownerId]);

  const handleRequestAttachment = useCallback(async () => {
    try {
      if (ownerId === null || fileFormat === null) return;

      // Convert contact ID to user ID for the API
      const assigneeContact = dealRoom?.contacts?.find(
        (contact) => contact.id === ownerId,
      );

      if (!assigneeContact?.userId) {
        console.error(
          '[RequestAttachmentsDialog] Contact not found or missing userId:',
          {
            contactId: ownerId,
            availableContacts: dealRoom?.contacts,
          },
        );
        return toast.error(
          'Selected user is not available in this Decision Site. Please try again.',
        );
      }

      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.createMilestoneAttachmentRequest(
          organizationSlug,
          dealRoomId,
          selectedMilestoneType,
          {
            assigneeId: assigneeContact.userId,
            details: description,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Requesting Artifact',
          success: () => {
            onResolve(null);
            Promise.all([refetchMilestonsSummary(), refetchMutualPlan()]);
            return 'Successfully requested the artifact';
          },
          error: 'Something went wrong requesting the artifact',
        },
      );
    } catch (err) {}
  }, [
    dealRoomId,
    description,
    fileFormat,
    getAccessTokenSilently,
    onResolve,
    organizationSlug,
    ownerId,
    refetchMilestonsSummary,
    refetchMutualPlan,
    selectedMilestoneType,
    dealRoom?.contacts,
  ]);

  return (
    <StyledDialog open onClose={onDismiss} maxWidth="md">
      <StyledDialogTitle>
        Request Artifact
        <IconButton
          edge="end"
          color="inherit"
          onClick={onDismiss}
          aria-label="close"
          size="small"
          sx={{ color: DEALROOMS_COLORS.themePrimary }}
        >
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>

      <StyledDialogContent>
        <Typography
          variant="body1"
          //align="center"
          sx={{
            fontWeight: 500,
            fontSize: '.9375rem',
            lineHeight: '1.5rem',
            color: DEALROOMS_COLORS.neutralDark,
            // maxWidth: '340px',
            margin: '0 auto',
          }}
        >
          Requesting an Artifact creates a task assigned to the person you're
          requesting from
        </Typography>

        <FormControl fullWidth required>
          <InputLabel>Request Artifact from...</InputLabel>
          <Select
            value={ownerId || ''}
            label="Request Artifact From"
            onChange={(e) => setOwnerId(e.target.value as number)}
          >
            {dealRoomContactsOptions.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          required
          label="Details"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <FormControl required>
          <InputLabel sx={{ transform: 'translate(0, 0rem)' }}>
            Document format
          </InputLabel>
          <RadioGroup
            row
            value={fileFormat || ''}
            onChange={(e) => setFileFormat(e.target.value as FileFormat)}
            sx={{ gap: '1.5rem', marginTop: '2rem' }}
          >
            {formatOptions.map((option) => (
              <FormControlLabel
                key={option.key}
                value={option.key}
                control={<Radio />}
                label={option.text}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </StyledDialogContent>

      <DialogActions sx={{ padding: '1rem 1.5rem', gap: '1rem' }}>
        <Button
          variant="outlined"
          onClick={onDismiss}
          sx={{
            color: DEALROOMS_COLORS.darkerGray,
            borderColor: 'transparent',
            '&:hover': {
              borderColor: 'transparent',
              backgroundColor: DEALROOMS_COLORS.financialLighterGray,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleRequestAttachment}
          disabled={isRequestButtonDisabled}
          sx={{
            backgroundColor: DEALROOMS_COLORS.themePrimary,
            color: DEALROOMS_COLORS.white,
            '&:hover': {
              backgroundColor: DEALROOMS_COLORS.themeSecondary,
            },
          }}
        >
          Request
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
