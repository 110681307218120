import React from 'react';
import { Summarize } from '@mui/icons-material';
import { DSTooltip } from '../../DealRoom/DS/DSTooltip';
import {
  MeetingflowIconContainer,
  MeetingflowIcon,
} from '../DecisionSiteMeetingflowCard.styles';
import { DetailedMeetingflow } from '@meetingflow/common/Api/data-contracts';

interface Props {
  meetingflow: DetailedMeetingflow;
  hideSummaryIcon?: boolean;
}

export const DecisionSiteMeetingflowCardSummaryIcon: React.FC<Props> = ({
  meetingflow,
  hideSummaryIcon,
}) => {
  if (!meetingflow?.textSummary || hideSummaryIcon) {
    return null;
  }

  return (
    <DSTooltip title={meetingflow.textSummary}>
      <MeetingflowIconContainer>
        <MeetingflowIcon>
          <Summarize
            className="summary"
            titleAccess="Meeting Summary"
            fontSize="small"
          />
        </MeetingflowIcon>
      </MeetingflowIconContainer>
    </DSTooltip>
  );
};
