import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  styled,
  IconButton,
  FormControlLabel,
} from '@mui/material';

interface StyledDialogProps {
  isMobile: boolean;
  fullScreenOnMobile: boolean;
  customMaxWidth?: number;
}

export const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) =>
    !['isMobile', 'fullScreenOnMobile', 'customMaxWidth'].includes(
      prop as string,
    ),
})<StyledDialogProps>(
  ({ theme, isMobile, fullScreenOnMobile, customMaxWidth }) => ({
    '& .MuiDialog-paper': {
      borderRadius:
        fullScreenOnMobile && isMobile ? 0 : theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[24],
      padding: 0,
      margin: fullScreenOnMobile && isMobile ? 0 : theme.spacing(2),
      maxWidth: customMaxWidth ? `${customMaxWidth}px` : undefined,
      height: fullScreenOnMobile && isMobile ? '100%' : 'auto',
      maxHeight: '100%',
    },
  }),
);

export const StyledDialogHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 'normal',
  color: theme.palette.text.primary,
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
}));

export const StyledDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) =>
    !['isMobile', 'fullScreenOnMobile'].includes(prop as string),
})<Omit<StyledDialogProps, 'customMaxWidth'>>(
  ({ theme, isMobile, fullScreenOnMobile }) => ({
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  }),
);

export const StyledDialogFooter = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2, 2, 2),
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
}));

export const StyledFooterButtonGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  minWidth: 0,
  alignSelf: 'flex-end',
  '& .MuiButton-root': {
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    flexShrink: 0,
  },
}));

interface StyledContentStackProps {
  isMobile: boolean;
  fullScreenOnMobile: boolean;
}

export const StyledContentStack = styled(Stack, {
  shouldForwardProp: (prop) =>
    !['isMobile', 'fullScreenOnMobile'].includes(prop as string),
})<StyledContentStackProps>(({ theme, isMobile, fullScreenOnMobile }) => ({
  padding: theme.spacing(1, 0, 1),
  flex: 1,
  overflow: 'auto',
  justifyContent:
    fullScreenOnMobile && isMobile ? 'space-between' : 'flex-start',
}));

export const StyledPrimaryGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: theme.spacing(1),
  '& .MuiButton-root': {
    flexShrink: 0,
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
}));

export const StyledKeepOpenLabel = styled(FormControlLabel)(({ theme }) => ({
  gap: 4,
  flexDirection: 'row',
  alignItems: 'center',
  margin: 0,
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: 'text.secondary',
    lineHeight: 1.2,
    marginLeft: theme.spacing(1),
  },
}));

export const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

export const StyledHeaderContent = styled('div')({
  flex: 1,
});
