import { mergeStyles, NeutralColors, ThemeProvider } from '@fluentui/react';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import HeaderNav from '../HeaderNav';
import {
  lightTheme,
  darkTheme,
  demoTheme,
  MEETINGFLOW_COLORS,
} from '../../Themes/Themes';
import { useLocation } from 'react-router';

export const APP_HEADER_HEIGHT = '3.5rem';
export const APP_MAX_WIDTH_INT = 1800;
export const APP_MAX_WIDTH = `${APP_MAX_WIDTH_INT}px`;

export interface BaseLayoutProps {
  children?: React.ReactNode;
  contentViewportHeight?: string;
  fullWidth?: boolean;
}

export const BaseLayout: React.FunctionComponent<BaseLayoutProps> = ({
  children,
  contentViewportHeight,
  fullWidth = false,
}) => {
  const { isDark } = useLightOrDarkMode();

  const location = useLocation();
  const isDealRoom = location.pathname.includes('/decisionsite/');

  const routeContentClass = mergeStyles({
    backgroundColor: isDark
      ? NeutralColors.black
      : MEETINGFLOW_COLORS.purpleUltraLight,
    height: `calc(100vh - ${APP_HEADER_HEIGHT})`,
    position: 'relative',

    width: '100vw',
    overflow: 'auto',
    padding: '0',
    '.content-viewport': {
      maxWidth: fullWidth ? undefined : APP_MAX_WIDTH,
      overflow: 'hidden',
      height: contentViewportHeight ? contentViewportHeight : `100%`,
      margin: '0 auto',
      padding: fullWidth ? '0 .5rem' : undefined,
    },
  });

  return (
    <ThemeProvider
      applyTo="body"
      theme={isDealRoom ? demoTheme : isDark ? darkTheme : lightTheme}
    >
      <HeaderNav />
      <div className={routeContentClass}>
        <div className="content-viewport">{children}</div>
      </div>
    </ThemeProvider>
  );
};
