import { Icon } from '@fluentui/react';
import { DateTime } from 'luxon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
  DealRoomArtifact,
  DealRoomMeetingflowArtifact,
  DetailedDealRoom,
} from '@meetingflow/common/Api/data-contracts';
import { getMUIIconForArtifactType } from '../../../Helpers/IconHelpers';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { ArtifactsTableActions } from './ArtifactsTableActions';
import { useCallback, useMemo, useState } from 'react';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import useDeviceType from '../../../Hooks/deviceDetection';
import {
  MainContainerStyles,
  MainContainerWrapperStyles,
  detailsHeader,
  artifactName,
  deletedArtifact,
} from './DealRoomArtifactsTable.style';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { useHandleOnFeature } from './useArtifactsOnFeature';

type DealRoomArtifactsTableProps = {
  onClick: (id: DealRoomArtifact['id']) => unknown;
  onDelete?: (id: DealRoomArtifact['id']) => Promise<unknown>;
  organizationSlug: string;
  dealRoomId: number;
  refreshDealRoom: () => Promise<unknown>;
  artifacts: DealRoomArtifact[];
  refetchArtifacts: () => Promise<unknown>;
};

export type TableRowItem = {
  key: DealRoomArtifact['id'];
  name: DealRoomArtifact['name'];
  description: DealRoomArtifact['description'];
  sharedOn: DealRoomArtifact['createdAt'];
  artifactId: DealRoomArtifact['id'];
  deletedAt: DealRoomArtifact['deletedAt'];
  updatedAt: DealRoomArtifact['updatedAt'];
  featuredAt: DealRoomArtifact['featuredAt'];
  featured: boolean;
  type: DealRoomArtifact['type'];
  creator: DealRoomArtifact['creator'];
  owner: string; // creator's name
  lastModified: string;
  mimeType: string | null | undefined;
  meetingflow?: DealRoomMeetingflowArtifact['meetingflow'];
  upvotes?: DealRoomArtifact['upvoteCount'];
  upvoted?: DealRoomArtifact['upvoted'];
  downvotes?: DealRoomArtifact['downvoteCount'];
  downvoted?: DealRoomArtifact['downvoted'];
};

const getFormattedRows = (artifacts: DetailedDealRoom['artifacts']) =>
  artifacts
    .sort((a, b) => {
      // Sort featured items first
      if (a.featuredAt && !b.featuredAt) return -1;
      if (!a.featuredAt && b.featuredAt) return 1;
      // If both are featured or both are not featured, sort by creation date
      return (
        DateTime.fromISO(b.createdAt).toMillis() -
        DateTime.fromISO(a.createdAt).toMillis()
      );
    })
    .map((artifact) => {
      const artifactData: TableRowItem = {
        key: artifact.id,
        name: artifact.label || artifact.name,
        description: artifact.description,
        sharedOn: DateTime.fromISO(artifact.createdAt).toFormat('M/d/yy'),
        artifactId: artifact.id,
        deletedAt: artifact.deletedAt,
        updatedAt: artifact.updatedAt,
        featuredAt: artifact.featuredAt,
        featured: artifact.featuredAt ? true : false,
        type: artifact.type,
        mimeType: 'mimeType' in artifact ? artifact.mimeType : undefined,
        creator: artifact.creator,
        owner: artifact.creator?.name || '',
        lastModified: artifact.updatedAt,
        meetingflow:
          'meetingflow' in artifact ? artifact.meetingflow : undefined,
        upvotes: artifact.upvoteCount,
        upvoted: artifact.upvoted,
        downvotes: artifact.downvoteCount,
        downvoted: artifact.downvoted,
      };

      return artifactData;
    });

export const DealRoomArtifactsTable = ({
  onClick,
  organizationSlug,
  dealRoomId,
  refreshDealRoom,
  onDelete,
  artifacts,
  refetchArtifacts,
}: DealRoomArtifactsTableProps) => {
  const { isMobile } = useDeviceType();
  const breakpoints = useBreakpoints();

  const { userId } = useUserProfile();

  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);

  const [orderBy, setOrderBy] = useState<keyof TableRowItem>('lastModified');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const mainContainerStyles = MainContainerStyles(isMobile, breakpoints.xl);
  const mainContainerWrapperStyles = MainContainerWrapperStyles();

  const { handleOnFeature } = useHandleOnFeature({
    organizationSlug,
    dealRoomId,
    refetchArtifacts,
  });

  const handleRequestSort = (property: keyof TableRowItem) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const renderArtifactIcon = useCallback((data: TableRowItem) => {
    const IconComponent = getMUIIconForArtifactType(
      data.type,
      data.mimeType || undefined,
    );
    return (
      <IconComponent
        sx={{ color: DEALROOMS_COLORS.cloudburst, fontSize: '1.25rem' }}
      />
    );
  }, []);
  const renderLastModified = (data: string) =>
    humanizeDateTime(data, {
      displayComponents: ['date'],
      dateFormatOptions: {
        dateStyle: 'medium',
      },
      useRelativeDates: true,
    });

  const rows: TableRowItem[] = useMemo(() => {
    return getFormattedRows(artifacts);
  }, [artifacts]);

  const sortedRows = useMemo(() => {
    const comparator = (a: TableRowItem, b: TableRowItem, orderBy: string) => {
      let aValue = undefined;
      let bValue = undefined;

      // Special handling for different columns
      switch (orderBy) {
        case 'name':
          aValue = a.name?.toLowerCase();
          bValue = b.name?.toLowerCase();
          break;
        case 'description':
          aValue = a.description?.toLowerCase();
          bValue = b.description?.toLowerCase();
          break;
        case 'creator':
          aValue = a.owner?.toLowerCase();
          bValue = b.owner?.toLowerCase();
          break;
        case 'updatedAt':
          aValue = new Date(a.lastModified).getTime();
          bValue = new Date(b.lastModified).getTime();
          break;
        case 'upvotes':
          aValue = (a.upvotes || 0) - (a.downvotes || 0);
          bValue = (b.upvotes || 0) - (b.downvotes || 0);
          break;
        case 'featured':
          aValue = a.featured ? 1 : 0;
          bValue = b.featured ? 1 : 0;
          break;
        default:
          aValue = a[orderBy as keyof typeof a];
          bValue = b[orderBy as keyof typeof b];
      }

      // Handle null/undefined values
      if (aValue === null || aValue === undefined) aValue = '';
      if (bValue === null || bValue === undefined) bValue = '';

      // Compare the values
      if (bValue < aValue) {
        return order === 'desc' ? -1 : 1;
      }
      if (bValue > aValue) {
        return order === 'desc' ? 1 : -1;
      }
      return 0;
    };

    return [...rows].sort((a, b) => comparator(a, b, orderBy));
  }, [rows, order, orderBy]);

  return (
    <div className={mainContainerWrapperStyles}>
      <div className={mainContainerStyles}>
        <TableContainer
          sx={{
            width: '100%',
            overflowX: 'auto',
            boxShadow: 'none',
          }}
        >
          <Table
            aria-label="artifacts table"
            size="small"
            sx={{ tableLayout: 'auto' }}
          >
            <TableHead>
              <TableRow>
                <TableCell className="featured_artifact"></TableCell>
                <TableCell
                  className={detailsHeader}
                  sx={{
                    width: 'auto',
                    minWidth: !breakpoints.md ? 'none' : '100px',
                  }}
                >
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>

                {breakpoints.md && (
                  <>
                    {breakpoints.lg && (
                      <TableCell className={detailsHeader}>
                        <TableSortLabel
                          active={orderBy === 'description'}
                          direction={orderBy === 'description' ? order : 'asc'}
                          onClick={() => handleRequestSort('description')}
                        >
                          Description
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell className={detailsHeader} align="left">
                      <TableSortLabel
                        active={orderBy === 'creator'}
                        direction={orderBy === 'creator' ? order : 'asc'}
                        onClick={() => handleRequestSort('creator')}
                      >
                        Owner
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={detailsHeader} align="center">
                      <TableSortLabel
                        active={orderBy === 'updatedAt'}
                        direction={orderBy === 'updatedAt' ? order : 'asc'}
                        onClick={() => handleRequestSort('updatedAt')}
                      >
                        Last Modified
                      </TableSortLabel>
                    </TableCell>

                    {breakpoints.lg && (
                      <TableCell
                        className={detailsHeader}
                        align="center"
                        sx={{ width: '50px', minWidth: '50px' }}
                      >
                        <TableSortLabel
                          active={orderBy === 'upvotes'}
                          direction={orderBy === 'upvotes' ? order : 'asc'}
                          onClick={() => handleRequestSort('upvotes')}
                        >
                          Votes
                        </TableSortLabel>
                      </TableCell>
                    )}
                  </>
                )}
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows.map((row, rowIndex) => (
                <TableRow
                  key={`${row.key}_${rowIndex}`}
                  sx={
                    {
                      // '&:last-child td, &:last-child th': { border: 0 }
                    }
                  }
                  className="table_row"
                  onClick={(e) => {
                    if (row.deletedAt) return;
                    onClick(row.artifactId);
                  }}
                >
                  <TableCell className="featured_artifact">
                    {!row.deletedAt && (
                      <Icon
                        iconName={
                          !!row.featuredAt ? 'FavoriteStarFill' : 'FavoriteStar'
                        }
                        styles={{
                          root: {
                            color: DEALROOMS_COLORS.themeSecondary,
                            fontSize: '16px',
                            opacity: row.featured ? '1' : '0.4',
                            cursor:
                              dealRoomRole === 'SELLER'
                                ? 'pointer'
                                : 'not-allowed',
                            padding: '5px 10px',
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (dealRoomRole === 'SELLER') {
                            handleOnFeature(!row.featuredAt, row.artifactId);
                          }
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    className="fit_table_cell"
                    align="left"
                    sx={{
                      width: breakpoints.md ? 'auto' : '100px',
                      minWidth: breakpoints.md ? 'auto' : '100px',
                    }}
                  >
                    <Box className={artifactName}>
                      {renderArtifactIcon(row)}
                      <span
                        className={`${row.deletedAt ? deletedArtifact : ''}`}
                      >
                        {row.name}
                      </span>
                    </Box>
                  </TableCell>

                  {breakpoints.md && (
                    <>
                      {breakpoints.lg && (
                        <TableCell
                          className="fit_table_cell"
                          align="left"
                          sx={{
                            width: '100px',
                            minWidth: '100px',
                          }}
                        >
                          {row.description || '-'}
                        </TableCell>
                      )}
                      <TableCell
                        className="fit_table_cell"
                        align="left"
                        sx={{ width: '100px', minWidth: '100px' }}
                      >
                        {row.owner}
                      </TableCell>
                      <TableCell
                        className="fit_table_cell"
                        align="center"
                        sx={{ width: '150px', minWidth: '120px' }}
                      >
                        {renderLastModified(row.lastModified)}
                      </TableCell>
                      {breakpoints.lg && (
                        <TableCell
                          className="fit_table_cell"
                          align="center"
                          sx={{
                            width: '50px',
                            minWidth: '50px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              '& > div': {
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                              },
                            }}
                          >
                            <Box>
                              <ThumbUpIcon
                                sx={{
                                  fontSize: '16px',
                                  color: DEALROOMS_COLORS.cloudburst,
                                }}
                              />
                              {row.upvotes || 0}
                            </Box>

                            <Box>
                              <ThumbDownIcon
                                sx={{
                                  fontSize: '16px',
                                  color: DEALROOMS_COLORS.cloudburst,
                                }}
                              />
                              {row.downvotes || 0}
                            </Box>
                          </Box>
                        </TableCell>
                      )}
                    </>
                  )}

                  <TableCell
                    className="fit_table_cell actions_table_cell"
                    align="right"
                  >
                    <ArtifactsTableActions
                      useForTable={true}
                      userId={userId}
                      dealRoomRole={dealRoomRole}
                      itemData={row}
                      organizationSlug={organizationSlug}
                      dealRoomId={dealRoomId}
                      refreshDealRoom={refreshDealRoom}
                      refetchArtifacts={refetchArtifacts}
                      onDelete={onDelete ? onDelete : undefined}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
