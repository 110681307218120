import { useAuth0 } from '@auth0/auth0-react';
import {
  CommandButton,
  ContextualMenuItemType,
  FontIcon,
  FontSizes,
  FontWeights,
  IButtonStyles,
  ICommandBarItemProps,
  IContextualMenuItem,
  IconButton,
  Link,
  Modal,
  NeutralColors,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  TeachingBubble,
  Text,
  mergeStyles,
  useTheme,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  CallRecordingStatus,
  CallRecordingStatusSubCode,
  Company,
  Contact,
  DetailedMeetingflow,
  ExternalServiceObjectType,
  MeetingflowSummaryType,
  MeetingflowTemplate,
  MeetingPlanTemplatePrompt,
  MeetingPlanTemplatePromptType,
  PatchTaskPayload,
  PostTaskPayload,
  Resource,
  Task,
  UserActivity
} from '@meetingflow/common/Api/data-contracts';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import { tryParseConferenceInfo } from '@meetingflow/common/StringHelpers';
import { tryMatchAttendeesToParticipants } from '@meetingflow/common/TranscriptHelpers';
import { Truthy, isBoolean, toBoolean } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { YjsEditor, yTextToSlateElement } from '@slate-yjs/core';
import { syncedStore } from '@syncedstore/core';
import { useSyncedStore } from '@syncedstore/react';
import { AxiosResponse } from 'axios';
import saveAs from 'file-saver';
import { isArray, isString } from 'lodash';
import { DateTime } from 'luxon';
import randomColor from 'randomcolor';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Confetti from 'react-confetti';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import rehypeExternalLinks, {
  Options as RehypeExternalLinksOptions,
} from 'rehype-external-links';
import remarkGfm from 'remark-gfm';
import { Editor, Transforms } from 'slate';
import * as Y from 'yjs';
import { EMPTY_ARRAY } from '../../Constants';
import { isAxiosErrorResponse } from '../../Helpers/AxiosHelpers';
import { readClipboardContents } from '../../Helpers/ClipboardHelpers';
import { buildDocx, toBlob } from '../../Helpers/Docx';
import {
  externalPlanContacts,
  externalPlanDomains,
  isExternalEventOrMeetingflow,
} from '../../Helpers/MeetingPlanHelpers';
import { hasContent as slateHasContent } from '../../Helpers/SlateHelpers';
import { sendWSMessage } from '../../Helpers/WSHelpers';
import { hasContent } from '../../Helpers/yjsHelpers';
import { useConfirmationDialog } from '../../Hooks/Modals/useConfirmationDialog';
import { useGetConferenceJoinLinkDialog } from '../../Hooks/Modals/useGetConferenceJoinLinkDialog';
import { useLinkDialog } from '../../Hooks/Modals/useLinkDialog';
import useSummarizeMeetingflowDialog from '../../Hooks/Modals/useSummarizeMeetingflowDialog';
import useAddIntegrationModal from '../../Hooks/useAddIntegrationModal';
import useBreakpoints from '../../Hooks/useBreakpoints';
import { useCollabProvider } from '../../Hooks/useCollabProvider';
import { useDeferredPromise } from '../../Hooks/useDeferredPromise';
import { useExternalServiceConfigurations } from '../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../Hooks/useLocalStorageState';
import { useOrganization } from '../../Hooks/useOrganization';
import { useUserProfile } from '../../Hooks/useProfile';
import { useStack } from '../../Hooks/useStack';
import { useTitle } from '../../Hooks/useTitle';
import { HubSpotPlanContext } from '../../Models/HubSpot/HubSpotPlanContext';
import { SalesforcePlanContext } from '../../Models/Salesforce/SalesforcePlanContext';
import {
  HubSpotPlanContextQuery,
  MeetingPlanCallRecorderStatusQuery,
  MeetingPlanQuery,
  MeetingPlanTasksQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationTemplatesQuery,
  OrganizationUpcomingMeetings,
  SalesforcePlanContextQuery,
} from '../../QueryNames';
import {
  ApiClient,
  MeetingflowsApiClient,
  TasksApiClient,
  TemplatesApiClient,
} from '../../Services/NetworkCommon';
import hubspotIcon from '../../Static/Images/hubspot.png';
import teamsIcon from '../../Static/Images/ms-teams.png';
import salesforceIcon from '../../Static/Images/salesforce.png';
import slackIcon from '../../Static/Images/slack.png';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import {
  MeetingPlanHubSpotBrowserPanelContext,
  MeetingPlanHubSpotObjectPanelContext,
  MeetingPlanPanelContext,
  MeetingPlanSalesforceBrowserPanelContext,
  MeetingPlanSalesforceObjectPanelContext,
} from '../../types/MeetingPlanPanelContext';
import { SalesforcePanelContext } from '../../types/SalesforcePanelContext';
import EditorFrame from '../Collab/EditorFrame';
import { getYjsEditor } from '../Collab/Helpers/EditorHelpers';
import { AsyncPrimaryButton } from '../HOC/AsyncButton';
import { APP_MAX_WIDTH } from '../Layouts/BaseLayout';
import { PresenceFacepile } from '../PresenceFacepile';
import { CallRecordingPlayer } from './CallRecordingPlayer';
import { useCollaborateDialog } from './Dialogs/CollaborateDialog';
import { MeetingPlanEditDetailsForm } from './Dialogs/EditDetailsForm';
import { TemplateNameDialog } from './Dialogs/TemplateNameDialog';
import { MeetingflowCard } from './MeetingflowCard';
import { MeetingPlanResources } from './Sections/MeetingPlanResources';
import { ActionItemsSidePanel } from './SidePanels/ActionItemsSidePanel';
import { ChatBotSidePanel } from './SidePanels/ChatBotSidePanel';
import { CompaniesSidePanel } from './SidePanels/CompaniesSidePanel';
import { CompanySidePanel } from './SidePanels/CompanySidePanel';
import { ContactSidePanel } from './SidePanels/ContactSidePanel';
import { ContactsSidePanel } from './SidePanels/ContactsSidePanel';
import { DossierSidePanel } from './SidePanels/DossierSidePanel';
import { EmailFollowupSidePanel } from './SidePanels/EmailFollowupSidePanel';
import { FollowUpSidePanel } from './SidePanels/FollowUpSidePanel';
import { HubSpotBrowserSidePanel } from './SidePanels/HubSpot/HubSpotBrowserSidePanel';
import { HubSpotObjectSidePanel } from './SidePanels/HubSpot/HubSpotObjectSidePanel';
import { LogMeetingToHubSpotSidePanel } from './SidePanels/HubSpot/LogMeetingToHubSpotSidePanel';
import { InsightsSidePanel } from './SidePanels/InsightsSidePanel';
import { InviteAndNotifySidePanel } from './SidePanels/InviteAndNotifySidePanel';
import { PlanSidePanel } from './SidePanels/PlanSidePanel';
import { PlansSidePanel } from './SidePanels/PlansSidePanel';
import { LogMeetingToSalesforceSidePanel } from './SidePanels/Salesforce/LogMeetingToSalesforceSidePanel';
import { SalesforceBrowserSidePanel } from './SidePanels/Salesforce/SalesforceBrowserSidePanel';
import { SalesforceObjectSidePanel } from './SidePanels/Salesforce/SalesforceObjectSidePanel';
import { SlackSidePanel } from './SidePanels/SlackSidePanel';
import { SummarySidePanel } from './SidePanels/SummarySidePanel';
import { TeamsSidePanel } from './SidePanels/TeamsSidePanel';
import { TimelineSidePanel } from './SidePanels/TimelineSidePanel';

const DEFAULT_SALESFORCE_BROWSER_CONTEXT = {
  type: 'salesforce-browser',
  defaultTab: 'PINNED',
} satisfies MeetingPlanSalesforceBrowserPanelContext;

const DEFAULT_HUBSPOT_BROWSER_CONTEXT = {
  type: 'hubspot-browser',
  defaultTab: 'PINNED',
} satisfies MeetingPlanHubSpotBrowserPanelContext;

export type MeetingPlanPageProps = {
  organizationSlug: string;
  meetingPlanId: string;
};

export const MeetingPlanPage = ({
  organizationSlug,
  meetingPlanId,
}: MeetingPlanPageProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { name, email, picture } = user!;
  const [templateSaving, setTemplateSaving] = useState(false);
  const [selectedNotesTab, setSelectedNotesTab] = useState<string>('');
  const [
    editDetailsModalOpen,
    { setTrue: showEditDetailsModal, setFalse: hideEditDetailsModal },
  ] = useBoolean(false);

  const {
    user: mfUser,
    workspacePreferences,
    updateWorkspacePreferencesAsync,
  } = useUserProfile();

  const layoutRef = useRef<HTMLDivElement>(null);
  const mainColumn = useRef<HTMLDivElement>(null);
  const sidebarColumn = useRef<HTMLDivElement>(null);
  const resizerColumn = useRef<HTMLDivElement>(null);
  const [mainColumnWidth, setMainColumnWidth] = useLocalStorageState(
    'meetingflow-main-column-width',
    '60',
  );
  const [
    addActionItemCoachmarkVisible,
    {
      setTrue: setAddActionItemCoachmarkVisible,
      setFalse: setAddActionItemCoachmarkHidden,
    },
  ] = useBoolean(false);
  const addActionItemCoachmarkTarget = React.useRef<HTMLDivElement>(null);

  const breakpoints = useBreakpoints();

  const {
    createDeferred: deferTemplateName,
    resolve: templateNameResolve,
    reject: templateNameReject,
    deferred: templateNameDeferred,
  } = useDeferredPromise<{ name: string; description: string }>();

  const [confettiShown, { setTrue: showConfetti, setFalse: hideConfetti }] =
    useBoolean(false);

  const [
    panelVisible,
    { setTrue: setPanelVisible, setFalse: setPanelCollapsed },
  ] = useBoolean(!breakpoints?.md);

  const [
    topSectionVisible,
    { setTrue: setTopSectionVisible, setFalse: setTopSectionCollapsed },
  ] = useBoolean(true);

  const [topSectionSelectedTab, setTopSectionSelectedTab] =
    useState<string>('');

  const [
    isEditingSummary,
    { setTrue: setEditingSummaryTrue, setFalse: setIsEditingFalse },
  ] = useBoolean(false);

  const {
    head: panelContext,
    stack: panelStack,
    push: pushToPanelStack,
    pop: popPanelStack,
    reset: resetPanelStack,
    count: panelStackCount,
  } = useStack<MeetingPlanPanelContext>();

  const theme = useTheme();

  const client = useQueryClient();
  const navigate = useNavigate();

  const { isDark } = useLightOrDarkMode();

  const { userId } = useUserProfile();
  const {
    canCreatePlans,
    internalDomains,
    isAdmin,
    isGuest,
    organization,
    hasEntitlement,
  } = useOrganization(organizationSlug);

  const [
    layeredPanel,
    { setTrue: setHasLayeredPanel, setFalse: setHasColumnarPanel },
  ] = useBoolean(!breakpoints.md);

  const appInsights = useAppInsightsContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const summaryRef = useRef<HTMLSpanElement>(null);

  const [templateChatSuggestions, setTemplateChatSuggestions] =
    useState<MeetingPlanTemplatePrompt[]>([]);
  const [templateEditorSuggestions, setTemplateEditorSuggestions] =
    useState<MeetingPlanTemplatePrompt[]>([]);

  const {
    configurationsWithToken,
    refetch: refetchExternalServiceConfigurations,
    hasToken,
  } = useExternalServiceConfigurations({ withToken: true });

  const {
    configurationsWithToken: salesforceConfigurations,
    hasToken: hasSalesforceToken,
    refetch: refetchSalesforceConfigurations,
  } = useExternalServiceConfigurations({
    app: 'SALESFORCE',
    withToken: true,
  });

  const {
    configurationsWithToken: hubspotConfigurations,
    hasToken: hasHubspotToken,
    refetch: refetchHubSpotConfigurations,
  } = useExternalServiceConfigurations({
    app: 'HUBSPOT',
    withToken: true,
  });

  const {
    createDeferred: createConfirmApplyTemplateDeferred,
    dialog: confirmApplyTemplateDialog,
  } = useConfirmationDialog({
    title: `Apply template?`,
    subText:
      'Applying this template will overwrite your current links, objectives and agenda, and notes. It will not overwrite calendar event information such as attendees, title, video conference link, and start/end dates.',
    primaryAction: 'CONFIRM',
  });

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete plan?`,
    subText:
      'Deleting this Meetingflow will delete it for all users and cannot be undone. Are you sure you want to delete this Meetingflow?',
    primaryAction: 'CANCEL',
  });

  const {
    createDeferred: createConfirmDeleteRecordingDeferred,
    dialog: confirmCreateRecordingDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete call recording?`,
    subText:
      'Deleting this call recording will permanently delete the recording, transcript and any associated metadata.  Are you sure you want to delete this call recording?',
    primaryAction: 'CANCEL',
  });

  const { createDeferred: createLinkDialogDeferred, dialog: linkDialog } =
    useLinkDialog();

  const {
    createDeferred: createAddIntegrationDeferred,
    dialog: addIntegrationDialog,
  } = useAddIntegrationModal();

  const {
    createDeferred: createGetConferenceJoinLinkDeferred,
    dialog: getConferenceJoinLinkDialog,
  } = useGetConferenceJoinLinkDialog();

  const {
    createDeferred: createSummarizeMeetingflowDeferred,
    dialog: summarizeMeetingflowDialog,
  } = useSummarizeMeetingflowDialog({
    organizationSlug,
    meetingflowId: meetingPlanId,
  });

  const {
    createDeferred: createProviderReconnectDeferred,
    deferred: providerReconnectDeferred,
    resolve: resolveProviderReconnect,
  } = useDeferredPromise<boolean>();

  const {
    data: meetingPlanData,
    // isLoading: meetingPlanIsLoading,
    isFetched: meetingPlanFetched,
    refetch: refetchMeetingPlan,
  } = useQuery(
    MeetingPlanQuery(organizationSlug, meetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getMeetingflow(
        organizationSlug,
        meetingPlanId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );

  const meetingPlan = meetingPlanData?.data;
  const isAssociatedWithEvent = meetingPlan?.externalId;

  useEffect(() => {
    const fetchPrompts = async () => {
      if (!organizationSlug) { return; }
      if (!meetingPlan) { return; }

      const token = await getAccessTokenSilently();
      // filter meetingPlan userActivity by type APPLY_TEMPLATE
      const templateApplication: UserActivity[] = meetingPlan.userActivity.filter(
        (activity) => activity.type === 'APPLY_TEMPLATE',
      );

      if (!templateApplication) { return; }

      // sort templates by createdAt - most recent ones first 
      const sortedTemplates = templateApplication.sort((a, b) => {
        return a.createdAt > b.createdAt ? -1 : 1;
      });

      if (sortedTemplates.length === 0) { return; }

      // take the most recent user activity 
      const mostRecentUserActivity = sortedTemplates[0];

      const templateId = mostRecentUserActivity.additionalDetails.templateId as number;

      if (!templateId) { return; }

      // get template details 
      const prompts = await TemplatesApiClient.getPrompts(organizationSlug, templateId, {
        headers: { Authorization: `Bearer ${token}` },
      })

      if (!prompts?.data) { return; }

      setTemplateChatSuggestions(prompts.data.filter((prompt) => prompt.promptType === 'CHAT'));
      setTemplateEditorSuggestions(prompts.data.filter((prompt) => prompt.promptType === 'EDITOR'));
    }

    fetchPrompts();
  }, [meetingPlan, getAccessTokenSilently, organizationSlug]);

  const internalAttendees = useMemo(
    () =>
      meetingPlan?.attendees.filter((a) =>
        internalDomains.includes(a.emailDomain),
      ),
    [meetingPlan, internalDomains],
  );

  const externalAttendees = useMemo(
    () =>
      meetingPlan?.attendees.filter(
        (a) => !internalDomains.includes(a.emailDomain),
      ),
    [meetingPlan, internalDomains],
  );

  const { data: salesforcePlanContext, refetch: refetchSalesforcePlanContext } =
    useQuery(
      SalesforcePlanContextQuery(
        organizationSlug,
        meetingPlanId,
        salesforceConfigurations[0]?.id,
      ),
      async () => {
        const token = await getAccessTokenSilently();
        return ApiClient.get<SalesforcePlanContext>(
          `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurations[0]?.id}/plan/${meetingPlanId}/context`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      { enabled: hasSalesforceToken() },
    );

  const { data: hubspotPlanContext, refetch: refetchHubspotPlanContext } =
    useQuery(
      HubSpotPlanContextQuery(
        organizationSlug,
        meetingPlanId,
        hubspotConfigurations[0]?.id,
      ),
      async () => {
        const token = await getAccessTokenSilently();
        return ApiClient.get<HubSpotPlanContext>(
          `/organization/${organizationSlug}/external/hubspot/configuration/${hubspotConfigurations[0]?.id}/plan/${meetingPlanId}/context`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      { enabled: hasHubspotToken() },
    );

  const companyNamesList = meetingPlan?.companies
    ?.map((c) => c.name)
    ?.join(',');
  const contactEmailList = meetingPlan?.attendees
    ?.map((a) => a?.email)
    ?.join(',');

  const beforeEnd =
    meetingPlan && meetingPlan.endTime
      ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds > 0
      : false;
  const afterEnd = meetingPlan
    ? DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds < 0
    : false;

  useEffect(() => {
    if (hasSalesforceToken()) {
      refetchSalesforcePlanContext();
    }
    if (hasHubspotToken()) {
      refetchHubspotPlanContext();
    }
  }, [
    companyNamesList,
    contactEmailList,
    hasHubspotToken,
    hasSalesforceToken,
    refetchHubspotPlanContext,
    refetchSalesforcePlanContext,
  ]);

  const pushPanelContext = useCallback(
    (context: MeetingPlanPanelContext) => {
      pushToPanelStack(context);
      setPanelVisible();
      appInsights.trackEvent({
        name: `VIEW_SIDE_PANEL_${context.type.toUpperCase()}`,
        properties: {
          organizationSlug,
          meetingPlanId,
          panelType: context.type,
        },
      });
    },
    [
      appInsights,
      meetingPlanId,
      organizationSlug,
      pushToPanelStack,
      setPanelVisible,
    ],
  );

  const setPanelContext = useCallback(
    (context?: MeetingPlanPanelContext | MeetingPlanPanelContext[]) => {
      resetPanelStack(context);

      if (!context) {
        setPanelCollapsed();
        return;
      }

      breakpoints?.md && setPanelVisible();
      if (!isArray(context)) {
        appInsights.trackEvent({
          name: `VIEW_SIDE_PANEL_${context.type.toUpperCase()}`,
          properties: {
            organizationSlug,
            meetingPlanId,
            panelType: context.type,
          },
        });
      } else if (context.length) {
        appInsights.trackEvent({
          name: `VIEW_SIDE_PANEL_${context[0].type.toUpperCase()}`,
          properties: {
            organizationSlug,
            meetingPlanId,
            panelType: context[0].type,
          },
        });
      }
    },
    [
      appInsights,
      meetingPlanId,
      organizationSlug,
      resetPanelStack,
      setPanelCollapsed,
      setPanelVisible,
      breakpoints?.md,
    ],
  );

  const setPanelContextAndLog = useCallback(
    (context: MeetingPlanPanelContext) => {
      setPanelContext(context);
      appInsights.trackEvent({
        name: `VIEW_SIDE_PANEL_${context.type.toUpperCase()}`,
        properties: {
          organizationSlug,
          meetingPlanId,
          panelType: context.type,
        },
      });
    },
    [appInsights, meetingPlanId, organizationSlug, setPanelContext],
  );

  const isUnassociatedWithEventAndNew =
    meetingPlan?.title.startsWith('Meetingflow - ') &&
    DateTime.fromISO(meetingPlan?.startTime).diffNow('minutes').minutes < 10;

  const defaultMentionSuggestions = useMemo(() => {
    if (!meetingPlan) {
      return [];
    }

    return meetingPlan.attendees
      .filter((attendee) => internalDomains.includes(attendee.emailDomain))
      .slice(0, 5)
      .map((contact) => {
        const salesforceName =
          salesforcePlanContext?.data?.contacts?.[contact.id]?.Name;
        const hubspotName =
          hubspotPlanContext?.data?.contacts?.[contact.id]?.properties
            ?.firstname &&
            hubspotPlanContext?.data?.contacts?.[contact.id]?.properties?.lastname
            ? [
              hubspotPlanContext?.data.contacts[contact.id].properties
                .firstname,
              hubspotPlanContext?.data.contacts[contact.id].properties
                .lastname,
            ].join(' ')
            : undefined;

        return {
          id: contact.id,
          name: salesforceName || hubspotName || contact.name,
          email: contact.email,
        };
      });
  }, [
    hubspotPlanContext?.data.contacts,
    internalDomains,
    meetingPlan,
    salesforcePlanContext?.data?.contacts,
  ]);

  const externalDomains = useMemo(
    () => externalPlanDomains(meetingPlan, internalDomains),
    [internalDomains, meetingPlan],
  );

  const externalContactIds = useMemo(
    () => externalPlanContacts(meetingPlan, internalDomains).map((c) => c.id),
    [internalDomains, meetingPlan],
  );

  const [lastSalesforcePanelContext, setLastSalesforcePanelContext] =
    useLocalStorageState<
      | MeetingPlanSalesforceObjectPanelContext
      | MeetingPlanSalesforceBrowserPanelContext
    >(
      `MP_${organizationSlug}_${meetingPlan?.id}_LAST_SF_PANEL_CONTEXT`,
      DEFAULT_SALESFORCE_BROWSER_CONTEXT,
    );

  const [lastHubSpotPanelContext, setLastHubSpotPanelContext] =
    useLocalStorageState<
      | MeetingPlanHubSpotObjectPanelContext
      | MeetingPlanHubSpotBrowserPanelContext
    >(
      `MP_${organizationSlug}_${meetingPlan?.id}_LAST_HS_PANEL_CONTEXT`,
      DEFAULT_HUBSPOT_BROWSER_CONTEXT,
    );

  useEffect(() => {
    if (
      panelContext?.type === 'salesforce-browser' ||
      panelContext?.type === 'salesforce-object'
    ) {
      setLastSalesforcePanelContext(panelContext);
    }
  }, [panelContext, panelContext?.type, setLastSalesforcePanelContext]);

  useEffect(() => {
    if (
      panelContext?.type === 'hubspot-browser' ||
      panelContext?.type === 'hubspot-object'
    ) {
      setLastHubSpotPanelContext(panelContext);
    }
  }, [panelContext, panelContext?.type, setLastHubSpotPanelContext]);

  useTitle(meetingPlan?.title);

  useLayoutEffect(() => {
    if (!breakpoints.md) {
      setHasLayeredPanel();
    } else {
      setHasColumnarPanel();
    }
  });

  useLayoutEffect(() => {
    if (!breakpoints.md && meetingPlan?.id) {
      setHasLayeredPanel();
      setPanelCollapsed();
    } else {
      setHasColumnarPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakpoints.md, meetingPlan?.id]);

  // Set panel context to insights once the Meetingflow has loaded initially.
  useEffect(() => {
    if (!panelContext) {
      setPanelContext({ type: 'insights' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingPlan?.id]);

  // Set selectNotesTab once the Meetingflow has loaded initially. It should be 'notes-summary' if there is a transcriptSummary and 'notes-notes' otherwise.
  useEffect(() => {
    if (!selectedNotesTab) {
      setSelectedNotesTab(
        meetingPlan?.textSummary && meetingPlan?.textSummary.length > 0
          ? 'notes-summary'
          : 'notes-notes',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set selected tab when summary changes
    if (meetingPlan?.textSummary && meetingPlan?.textSummary.length > 0) {
      setSelectedNotesTab('notes-summary');
    } else {
      setSelectedNotesTab('notes-notes');
    }
  }, [meetingPlan?.textSummary, meetingPlan?.textSummary?.length]);

  // Set topSectionSelectTab once the Meetingflow has loaded initially. It should be 'attendees' if there is an event and  no call recording.
  useEffect(() => {
    if (!topSectionSelectedTab) {
      setTopSectionSelectedTab(
        meetingPlan?.callRecording?.id && afterEnd
          ? 'call-recording'
          : 'attendees',
      );
    }

    if (!isAssociatedWithEvent) {
      setTopSectionCollapsed();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingPlan?.id, topSectionSelectedTab, meetingPlan?.callRecording]);

  const {
    data: tasksData,
    isLoading: tasksLoading,
    refetch: refetchTasks,
  } = useQuery(
    MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.listTasks(
        {
          organizationSlug,
          meetingPlanId,
          sortBy: 'createdAt',
          sortOrder: 'asc',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  );

  const suggestedActionItems =
    meetingPlan?.callRecording?.transcriptActionItems;

  const suggestedActionItemsAsTasks = useMemo(() => {
    if (!meetingPlan || !meetingPlan.callRecording || !suggestedActionItems) {
      return [];
    }

    let recordingActionItems = [...suggestedActionItems];

    const participantNames =
      meetingPlan.callRecording.participants?.map((p) => p.name) ?? [];

    const matches = tryMatchAttendeesToParticipants(
      participantNames,
      meetingPlan.attendees,
    );

    const internalParticipants = (participantNames || []).filter(
      (p) =>
        p in matches &&
        internalDomains.includes(
          meetingPlan.attendees.find((a) => a.id === matches[p].id)
            ?.emailDomain || '',
        ),
    );

    if (internalParticipants.length) {
      // If there is an assignee, check the *assignee* is an internal participant
      // Else check the speaker is an internal participant
      recordingActionItems = recordingActionItems?.filter((item) =>
        item.assignee
          ? internalParticipants.some((p) => item.assignee!.includes(p))
          : internalParticipants.some((p) => item.speaker.includes(p)),
      );
    }

    return recordingActionItems?.map((t) => {
      const task = {
        text: t.text,
        dueDate: DateTime.now().plus({ days: 7 }).toISO(), // Don't trust GPT due dates, just pick something simple as a default
        meetingPlanId: meetingPlanId,
      } as Task;
      return task;
    });
  }, [internalDomains, meetingPlan, meetingPlanId, suggestedActionItems]);

  const {
    data: callRecorderStatus,
    isLoading: callRecorderStatusLoading,
    refetch: refetchCallRecorderStatus,
  } = useQuery(
    MeetingPlanCallRecorderStatusQuery(organizationSlug, meetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getCallRecordingStatus(
        organizationSlug,
        meetingPlanId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      refetchOnWindowFocus:
        !!organizationSlug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        !!meetingPlan?.callRecording?.id &&
        !meetingPlan.callRecording.recordingFileName,
      refetchOnMount:
        !!organizationSlug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        !!meetingPlan?.callRecording?.id &&
        !meetingPlan.callRecording.recordingFileName,
      refetchOnReconnect:
        !!organizationSlug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        !!meetingPlan?.callRecording?.id &&
        !meetingPlan.callRecording.recordingFileName,
      enabled:
        !!organizationSlug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        !!meetingPlan?.callRecording?.id,
    },
  );

  const { mutate: addTask } = useMutation({
    mutationFn: async (task: PostTaskPayload) => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.postTask(organizationSlug, meetingPlanId, task, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    onMutate: async (_task: PostTaskPayload) => {
      await client.cancelQueries({
        queryKey: MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
      });

      const previous = client.getQueryData(
        MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
      ) as AxiosResponse<Task[]>;

      return {
        previous,
      };
    },
    onError: (err, _task, ctx) => {
      toast.error(`Something went wrong creating new Task`);

      appInsights.trackException({
        exception: err instanceof Error ? err : new Error(`${err}`),
        properties: {
          organizationSlug,
          status: isAxiosErrorResponse(err) ? err.response?.status : undefined,
          statusText: isAxiosErrorResponse(err)
            ? err.response?.statusText
            : undefined,
        },
      });

      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
          ctx.previous,
        );
      }
    },
    onSuccess: (response, task, ctx) => {
      sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_ACTION_ITEMS);

      appInsights.trackEvent({
        name: 'ADD_ACTION_ITEM',
        properties: {
          organizationSlug,
          meetingPlanId,
          id: response.data.id,
        },
      });

      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
          {
            ...ctx.previous,
            data: [...(ctx.previous.data || []), response.data],
          },
        );
      }
    },
    onSettled: () => {
      client.invalidateQueries(
        MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
      );
    },
  });

  const { mutate: patchTask } = useMutation({
    mutationFn: async (update: { id: Task['id'] } & PatchTaskPayload) => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.patchTask(
        organizationSlug,
        meetingPlanId,
        update.id,
        {
          text: update.text,
          completed: update.completed,
          dueDate: update.dueDate,
          assigneeIdOrEmail: update.assigneeIdOrEmail,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    onMutate: async (update) => {
      await client.cancelQueries({
        queryKey: MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
      });

      const previous = client.getQueryData(
        MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
      ) as AxiosResponse<Task[]>;

      if (previous.data) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
          {
            ...previous,
            data: previous.data?.map((t) =>
              t.id !== update.id
                ? t
                : ({
                  ...t,
                  text: update.text ?? t.text,
                  completed: update.completed ?? t.completed,
                  dueDate: update.dueDate ?? t.dueDate,
                } satisfies Task),
            ),
          },
        );
      }

      return {
        previous,
      };
    },
    onError: (err, update, ctx) => {
      toast.error(`Something went wrong updating Task`);

      appInsights.trackException({
        exception: err instanceof Error ? err : new Error(`${err}`),
        properties: {
          organizationSlug,
          status: isAxiosErrorResponse(err) ? err.response?.status : undefined,
          statusText: isAxiosErrorResponse(err)
            ? err.response?.statusText
            : undefined,
        },
      });

      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
          ctx.previous,
        );
      }
    },
    onSuccess: (response, update, ctx) => {
      sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_ACTION_ITEMS);
      appInsights.trackEvent({
        name: 'UPDATE_ACTION_ITEM',
        properties: {
          organizationSlug,
          meetingPlanId,
          id: update.id,
          completed: response.data.completed,
        },
      });
      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
          {
            ...ctx.previous,
            data: ctx.previous.data?.map((t) =>
              t.id !== update.id ? t : response.data,
            ),
          },
        );
      }
    },
    onSettled: () => {
      client.invalidateQueries(
        MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
      );
    },
  });

  const { mutate: deleteTask } = useMutation({
    mutationFn: async (id: Task['id']) => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.deleteTask(organizationSlug, meetingPlanId, id, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    onMutate: async (id) => {
      await client.cancelQueries({
        queryKey: MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
      });

      const previous = client.getQueryData(
        MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
      ) as AxiosResponse<Task[]>;

      if (previous.data) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
          {
            ...previous,
            data: previous.data?.filter((t) => t.id !== id),
          },
        );
      }

      return {
        previous,
      };
    },
    onError: (err, id, ctx) => {
      toast.error(`Something went wrong deleting Task`);

      appInsights.trackException({
        exception: err instanceof Error ? err : new Error(`${err}`),
        properties: {
          organizationSlug,
          status: isAxiosErrorResponse(err) ? err.response?.status : undefined,
          statusText: isAxiosErrorResponse(err)
            ? err.response?.statusText
            : undefined,
        },
      });

      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
          ctx.previous,
        );
      }
    },
    onSuccess: (_response, id, _ctx) => {
      sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_ACTION_ITEMS);

      appInsights.trackEvent({
        name: 'REMOVE_ACTION_ITEM',
        properties: {
          organizationSlug,
          meetingPlanId,
          id,
        },
      });
    },
    onSettled: () => {
      client.invalidateQueries(
        MeetingPlanTasksQuery(organizationSlug, meetingPlanId),
      );
    },
  });

  const {
    data: meetingPlanTemplates,
    isLoading: meetingPlanTemplatesLoading,
    refetch: refetchMeetingPlanTemplates,
  } = useQuery(
    OrganizationTemplatesQuery(organizationSlug),
    async () => {
      const token = await getAccessTokenSilently();
      const templates = await TemplatesApiClient.getTemplates(
        { organizationSlug },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      return templates;
    },
    {
      enabled: canCreatePlans,
    },
  );

  const { mutate: setDefaultTemplate } = useMutation({
    mutationKey: 'setDefaultTemplate',
    mutationFn: async (update: {
      id: MeetingflowTemplate['id'];
      defaultType: 'user' | 'organization';
    }) => {
      const token = await getAccessTokenSilently();
      switch (update.defaultType) {
        case 'organization':
          return TemplatesApiClient.setOrgDefaultTemplate(
            organizationSlug!,
            {
              templateId: update.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );

        case 'user':
          TemplatesApiClient.setUserDefaultTemplate(
            organizationSlug!,
            userId!,
            {
              templateId: update.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
      }
    },
    onMutate: async ({ id, defaultType }) => {
      const previousTemplates = client.getQueryData<
        AxiosResponse<MeetingflowTemplate[]>
      >(OrganizationTemplatesQuery(organizationSlug));

      client.setQueryData(
        OrganizationTemplatesQuery(organizationSlug),
        (old: AxiosResponse<MeetingflowTemplate[]> | undefined) => {
          if (!old) {
            return {
              config: {},
              headers: {},
              status: 404,
              statusText: '',
              request: {},
              data: [] as MeetingflowTemplate[],
            } as AxiosResponse<MeetingflowTemplate[]>;;
          }
          return {
            ...old,
            data: old.data.map((template) => ({
              ...template,
              isOrgDefault:
                defaultType === 'organization'
                  ? template.id === id
                    ? true
                    : false
                  : template.isOrgDefault,
              isUserDefault:
                defaultType === 'user'
                  ? template.id === id
                    ? true
                    : false
                  : template.isUserDefault,
            })),
          } satisfies AxiosResponse<MeetingflowTemplate[]>;
        },
      );

      return { previousTemplates };
    },
    onError: (err, { defaultType }, context) => {
      if (context?.previousTemplates) {
        client.setQueryData(
          OrganizationTemplatesQuery(organizationSlug),
          context.previousTemplates,
        );
      }
      console.error(err);
      toast.error(
        `Something went wrong updating the default ${defaultType} template, please try again.`,
      );
    },
    onSuccess: (data, { defaultType }) => {
      const updatedTemplate = data?.data;
      if (updatedTemplate) {
        client.setQueryData(
          OrganizationTemplatesQuery(organizationSlug),
          (old: AxiosResponse<MeetingflowTemplate[]> | undefined) => {
            if (!old) {
              return {
                config: {},
                headers: {},
                status: 200,
                statusText: '',
                request: {},
                data: [updatedTemplate],
              } as AxiosResponse<MeetingflowTemplate[]>;;
            }

            return {
              ...old,
              data:
                old?.data?.map((template) =>
                  template.id === updatedTemplate.id
                    ? updatedTemplate
                    : template,
                ) || [],
            } as AxiosResponse<MeetingflowTemplate[]>;;
          },
        );
      }
      toast.success(`Default ${defaultType} template was updated`);
    },
    onSettled: () => {
      client.refetchQueries(OrganizationTemplatesQuery(organizationSlug));
    },
  });

  const { mutate: unsetDefaultTemplate } = useMutation({
    mutationKey: 'unsetDefaultTemplate',
    mutationFn: async (update: { defaultType: 'user' | 'organization' }) => {
      const token = await getAccessTokenSilently();
      switch (update.defaultType) {
        case 'organization':
          return TemplatesApiClient.unsetOrgDefaultTemplate(organizationSlug!, {
            headers: { Authorization: `Bearer ${token}` },
          });

        case 'user':
          TemplatesApiClient.unsetUserDefaultTemplate(
            organizationSlug!,
            userId!,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
      }
    },
    onMutate: async ({ defaultType }) => {
      const previousTemplates = client.getQueryData<
        AxiosResponse<MeetingflowTemplate[]>
      >(OrganizationTemplatesQuery(organizationSlug));

      client.setQueryData(
        OrganizationTemplatesQuery(organizationSlug),
        (old: AxiosResponse<MeetingflowTemplate[]> | undefined) => {
          if (!old) {
            return {
              config: {},
              headers: {},
              status: 404,
              statusText: '',
              request: {},
              data: [] as MeetingflowTemplate[],
            } as AxiosResponse<MeetingflowTemplate[]>;
          }
          return {
            ...old,
            data: old.data.map((template) => ({
              ...template,
              isOrgDefault:
                defaultType === 'organization' ? false : template.isOrgDefault,
              isUserDefault:
                defaultType === 'user' ? false : template.isUserDefault,
            })),
          } as AxiosResponse<MeetingflowTemplate[]>;
        },
      );

      return { previousTemplates };
    },
    onError: (err, { defaultType }, context) => {
      if (context?.previousTemplates) {
        client.setQueryData(
          OrganizationTemplatesQuery(organizationSlug),
          context.previousTemplates,
        );
      }
      console.error(err);
      toast.error(
        `Something went wrong updating the default ${defaultType} template, please try again.`,
      );
    },
    onSuccess: (data, { defaultType }) => {
      const updatedTemplate = data?.data;
      if (updatedTemplate) {
        client.setQueryData(
          OrganizationTemplatesQuery(organizationSlug),
          (old: AxiosResponse<MeetingflowTemplate[]> | undefined) => {
            if (!old) {
              return {
                config: {},
                headers: {},
                status: 200,
                statusText: '',
                request: {},
                data: [updatedTemplate],
              } as AxiosResponse<MeetingflowTemplate[]>;
            }

            return {
              ...old,
              data:
                old?.data?.map((template) =>
                  template.id === updatedTemplate.id
                    ? updatedTemplate
                    : template,
                ) || [],
            } as AxiosResponse<MeetingflowTemplate[]>;
          }
        );
      }
      toast.success(`Default ${defaultType} template was updated`);
    },
    onSettled: () => {
      client.refetchQueries(OrganizationTemplatesQuery(organizationSlug));
    },
  });

  useEffect(() => {
    if (meetingPlan?.source && meetingPlan?.externalId) {
      (async () => {
        const token = await getAccessTokenSilently();
        const response = await MeetingflowsApiClient.syncMeetingflow(
          organizationSlug,
          meetingPlanId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        if (response.status === 200) {
          sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_PLAN);
          client.setQueryData(
            MeetingPlanQuery(organizationSlug, meetingPlanId),
            response,
          );
          refetchMeetingPlan();
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingPlan?.source, meetingPlan?.externalId]);

  useEffect(() => {
    const utmSource = searchParams.get('utm_source');
    const panelView = searchParams.get('view');
    const summaryType = searchParams.get('summaryType');

    appInsights.trackEvent({
      name: 'VIEW_PLAN',
      properties: {
        organizationSlug,
        meetingPlanId,
        utmSource: utmSource ?? undefined,
      },
    });
    if (!(!!utmSource || !!panelView || !!summaryType)) {
      return;
    }

    const newParams = new URLSearchParams(searchParams);

    if (!!utmSource) {
      newParams.delete('utm_source');
    }

    if (!!panelView) {
      switch (panelView) {
        case 'insights': {
          setPanelContext({ type: 'insights' });
          break;
        }
        case 'dossier': {
          setPanelContext({ type: 'dossier' });
          break;
        }
        case 'contacts': {
          setPanelContext({ type: 'contacts' });
          break;
        }
        case 'companies': {
          setPanelContext({ type: 'contacts' });
          break;
        }
        case 'plans': {
          setPanelContext({ type: 'plans' });
          break;
        }
        case 'timeline': {
          setPanelContext({ type: 'timeline' });
          break;
        }
        default:
      }

      newParams.delete('view');
    }

    console.info(`summaryType: ${summaryType}`);
    if (
      !!summaryType &&
      [
        'MEETING_SUMMARY',
        'SUGGESTED_NOTES',
        'PRE_MEETING_EMAIL',
        'FOLLOWUP_EMAIL',
        'REENGAGEMENT_EMAIL',
        'SLACK_UPDATE',
        'TEAMS_UPDATE',
        'SALESFORCE_SUMMARY',
        'HUBSPOT_SUMMARY',
      ].includes(summaryType)
    ) {
      console.info(`Creating summarize meetingflow deferred ${summaryType}`);
      createSummarizeMeetingflowDeferred({
        summaryType: summaryType as MeetingflowSummaryType,
      })
        .promise.then((result) => {
          switch (summaryType) {
            case 'MEETING_SUMMARY': {
              break;
            }
            case 'PRE_MEETING_EMAIL':
            case 'FOLLOWUP_EMAIL':
            case 'REENGAGEMENT_EMAIL': {
              pushPanelContext({
                type: 'email-followup',
                summaryText: result.summary,
              });
              break;
            }
            case 'SLACK_UPDATE': {
              pushPanelContext({
                type: 'slack',
                summaryText: result.summary,
              });
              break;
            }
            case 'TEAMS_UPDATE': {
              pushPanelContext({
                type: 'ms-teams',
                summaryText: result.summary,
              });
              break;
            }
            case 'SALESFORCE_SUMMARY': {
              break;
            }
            case 'HUBSPOT_SUMMARY': {
              pushPanelContext({
                type: 'hubspot-log-meeting',
                summaryText: result.summary,
              });
              break;
            }
            case 'SUGGESTED_NOTES': {
              break;
            }
          }
        })
        .catch((err) => { });

      newParams.delete('summaryType');
    }

    setSearchParams(newParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, meetingPlanId]);

  // Notes might have changed on the company or contact side bar,
  // If we already fetched the Meetingflow once, trigger a refetch to make sure the insights panel is up to date if we navigate back to it.
  useEffect(() => {
    if (panelContext?.type === 'insights' && meetingPlanFetched) {
      refetchMeetingPlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelContext?.type]);

  useEffect(() => {
    if (
      !!organizationSlug &&
      !!meetingPlanId &&
      hasEntitlement('CALL_RECORDING') &&
      !!meetingPlan?.callRecording?.id &&
      !meetingPlan.callRecording?.recordingFileName
    ) {
      client.removeQueries(
        MeetingPlanCallRecorderStatusQuery(organizationSlug, meetingPlanId),
      );

      refetchCallRecorderStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    meetingPlanId,
    organizationSlug,
    meetingPlan?.callRecording?.id,
    meetingPlan?.callRecording?.lastStatus,
  ]);

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, meetingPlanId]);

  const objectivesYArray = useMemo(() => {
    return ydoc.get('objectives', Y.XmlText) as Y.XmlText;
  }, [ydoc]);

  const notesYArray = useMemo(() => {
    return ydoc.get('notes', Y.XmlText) as Y.XmlText;
  }, [ydoc]);

  const { createDeferred: showCollaborateDialog, dialog: collaborateDialog } =
    useCollaborateDialog({
      organizationSlug,
      meetingPlanId,
      meetingPlan: meetingPlanData?.data,
      internalDomains,
      notesYArray,
      tasks: tasksData?.data || EMPTY_ARRAY,
    });

  // if there is a ?accessTab query param, open the collaborate dialog
  useEffect(() => {
    const accessTab = searchParams.get('accessTab');
    if (!!accessTab) {
      showCollaborateDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCollaborateDialog, searchParams]);

  const syncStore = useMemo(() => {
    return syncedStore(
      {
        resources: [] as Resource[],
      },
      ydoc,
    );
  }, [ydoc]);

  const store = useSyncedStore(syncStore);

  const onWSMessage = useCallback(
    (messageType: number) => {
      switch (messageType) {
        case WS_MESSAGE.REFRESH_PLAN: {
          refetchMeetingPlan();
          return true;
        }
        case WS_MESSAGE.REFRESH_ACTION_ITEMS: {
          refetchTasks();
          return true;
        }
        case WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS: {
          if (
            !!organizationSlug &&
            !!meetingPlanId &&
            hasEntitlement('CALL_RECORDING') &&
            !!meetingPlan?.callRecording?.id
          ) {
            client.removeQueries(
              MeetingPlanCallRecorderStatusQuery(
                organizationSlug,
                meetingPlanId,
              ),
            );

            refetchCallRecorderStatus();
          }
          return true;
        }

        case WS_MESSAGE.HEARTBEAT:
        case WS_MESSAGE.RECREATE:
        case WS_MESSAGE.SAVE: {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    [
      client,
      hasEntitlement,
      meetingPlanId,
      organizationSlug,
      meetingPlan?.callRecording?.id,
      refetchCallRecorderStatus,
      refetchMeetingPlan,
      refetchTasks,
    ],
  );

  const {
    provider,
    awarenessStates,
    hasConnected: collabHasConnected,
    isConnected: collabIsConnected,
    isSynced: collabIsSynced,
    isError: collabIsError,
    recreateProvider,
  } = useCollabProvider({
    providerName: 'MEETINGPLAN',
    documentName: `MeetingPlan__${organizationSlug}__${meetingPlanId}`,
    ydoc,
    color,
    email: email!,
    name: mfUser?.name || user?.name,
    picture: mfUser?.avatarFileUrl || mfUser?.avatarUrl || user?.picture,
    onMessage: onWSMessage,
  });

  useEffect(() => {
    if (
      !providerReconnectDeferred?.isPending &&
      collabHasConnected &&
      !collabIsConnected
    ) {
      toast.promise(createProviderReconnectDeferred().promise, {
        loading: 'Trying to reconnect...',
        success: 'Connected',
        error: 'Unable to reconnect',
      });
    } else if (!!providerReconnectDeferred?.isPending && collabIsConnected) {
      resolveProviderReconnect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    providerReconnectDeferred?.isPending,
    collabHasConnected,
    collabIsConnected,
  ]);

  const objectivesEditor = useMemo(() => {
    if (!provider) {
      return undefined;
    }
    return getYjsEditor(
      objectivesYArray,
      provider,
      {
        cursorStateField: 'objectivesCursorState',
        cursorDataField: 'objectivesCursorData',
        data: {
          alphaColor: isDark
            ? color.slice(0, -2) + '0.5)'
            : color.slice(0, -2) + '0.2)',
          color,
          name,
          email: email!,
          picture,
        },
      },
      getAccessTokenSilently,
      organizationSlug,
      createLinkDialogDeferred,
      meetingPlanId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectivesYArray, provider]);

  const notesEditor = useMemo(() => {
    if (!provider) {
      return undefined;
    }
    return getYjsEditor(
      notesYArray,
      provider,
      {
        cursorStateField: 'notesCursorState',
        cursorDataField: 'notesCursorData',
        data: {
          alphaColor: isDark
            ? color.slice(0, -2) + '0.5)'
            : color.slice(0, -2) + '0.2)',
          color,
          name,
          email: email!,
          picture,
        },
      },
      getAccessTokenSilently,
      organizationSlug,
      createLinkDialogDeferred,
      meetingPlanId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesYArray, provider, selectedNotesTab, meetingPlan?.textSummary]);

  const SHOW_AGENDA_EDITOR = useMemo(() => {
    return (
      !!meetingPlan &&
      !!collabIsSynced &&
      !!objectivesEditor &&
      !!slateHasContent(yTextToSlateElement(objectivesYArray))
    );
  }, [meetingPlan, collabIsSynced, objectivesEditor, objectivesYArray]);

  const SHOW_MAIN_CONTENT_TABS =
    SHOW_AGENDA_EDITOR || !!meetingPlan?.textSummary;

  useEffect(() => {
    if (!objectivesEditor) {
      return;
    }
    YjsEditor.connect(objectivesEditor);
    return () => {
      YjsEditor.disconnect(objectivesEditor);
    };
  }, [objectivesEditor, selectedNotesTab]);

  useEffect(() => {
    if (!notesEditor) {
      return;
    }
    YjsEditor.connect(notesEditor);
    return () => {
      YjsEditor.disconnect(notesEditor);
    };
  }, [notesEditor, selectedNotesTab]);

  const applyTemplate = useCallback(
    (template: MeetingflowTemplate) => {
      if (!(!!template.resources || !!template.notes)) {
        return;
      }

      const apply = () => {
        ydoc.transact(() => {
          store.resources.splice(0, store.resources.length);
          if (template.resources?.length) {
            store.resources.push(...template.resources);
          }
        });

        if (notesEditor) {
          Editor.withoutNormalizing(notesEditor, () => {
            Transforms.removeNodes(notesEditor, {
              at: [],
              hanging: true,
              voids: true,
              mode: 'highest',
              match: (node) => !Editor.isEditor(node),
            });
            Transforms.insertNodes(
              notesEditor,
              template.notes || { type: 'paragraph', children: [{ text: '' }] },
              { select: false },
            );
          });
        }

        if (template.prompts) {
          setTemplateEditorSuggestions(
            template.prompts.filter((p) => p.promptType === 'EDITOR'),
          );
          setTemplateChatSuggestions(
            template.prompts.filter((p) => p.promptType === 'CHAT'),
          );
        }

        toast.success(`${template.name} successfully applied.`);

        getAccessTokenSilently()
          .then((token) =>
            TemplatesApiClient.trackTemplateApplied(
              organizationSlug,
              template.id,
              { meetingPlanId },
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
          )
          .catch();

        appInsights.trackEvent({
          name: `APPLY_TEMPLATE`,
          properties: {
            organizationSlug,
            planId: meetingPlan?.id,
            templateId: template.id,
          },
        });
      };

      if (
        store.resources.length ||
        hasContent(objectivesYArray) ||
        hasContent(notesYArray)
      ) {
        createConfirmApplyTemplateDeferred()
          .promise.then((confirm) => {
            if (confirm) {
              apply();
            }
          })
          .catch();
      } else {
        apply();
      }
    },
    [
      appInsights,
      createConfirmApplyTemplateDeferred,
      getAccessTokenSilently,
      meetingPlan?.id,
      meetingPlanId,
      notesEditor,
      notesYArray,
      objectivesYArray,
      organizationSlug,
      store.resources,
      ydoc,
    ],
  );

  const refreshPlan = useCallback(() => {
    sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_PLAN);
    return refetchMeetingPlan();
  }, [provider?.ws, refetchMeetingPlan]);

  const refreshPlanWithConfetti = useCallback(() => {
    showConfetti();
    return refreshPlan();
  }, [refreshPlan, showConfetti]);

  const onCompanyClick = useCallback(
    (companyId: Company['id']) => {
      setPanelContextAndLog({
        type: 'company',
        companyId,
      });
      setPanelVisible();
    },
    [setPanelContextAndLog, setPanelVisible],
  );

  const onContactClick = useCallback(
    (contactId: Contact['id']) => {
      setPanelContextAndLog({
        type: 'contact',
        contactId,
      });
      setPanelVisible();
    },
    [setPanelContextAndLog, setPanelVisible],
  );

  const scheduleCallRecording = useCallback(() => {
    if (!meetingPlan) return;

    const fiveMinutesBeforeStart =
      DateTime.fromISO(meetingPlan.startTime).diffNow('minutes').minutes > 5;
    const beforeEnd =
      DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds > 0;

    if (!meetingPlan.callRecording && !beforeEnd) return;

    const botStatus = !meetingPlan.callRecording
      ? undefined
      : (callRecorderStatus?.data ?? {
        code: meetingPlan.callRecording.lastStatus,
        subCode: meetingPlan.callRecording.lastStatusSubCode,
      } ?? { code: 'unknown' });

    const headers = (token: string) => ({ Authorization: `Bearer ${token}` });
    const validateStatus = (status: number) => [200, 201].includes(status);
    const deleteStatus = (status: number) => [204].includes(status);

    if (
      beforeEnd &&
      (!meetingPlan.callRecording || botStatus?.code === 'deleted')
    ) {
      getAccessTokenSilently().then((token) =>
        toast.promise(
          MeetingflowsApiClient.startOrScheduleCallRecording(
            organizationSlug,
            meetingPlanId,
            undefined,
            {
              headers: headers(token),
              validateStatus,
            },
          ),
          {
            loading: fiveMinutesBeforeStart
              ? `Scheduling Call Recording`
              : `Starting Call Recording`,
            error: (err) => {
              console.error(err);
              return fiveMinutesBeforeStart
                ? `Something went wrong scheduling call recording`
                : `Something went wrong starting call recording`;
            },
            success: (result) => {
              sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_PLAN);
              sendWSMessage(
                provider?.ws,
                WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS,
              );

              client.invalidateQueries(
                MeetingPlanCallRecorderStatusQuery(
                  organizationSlug,
                  meetingPlanId,
                ),
              );

              refetchCallRecorderStatus();
              refetchMeetingPlan();
              appInsights.trackEvent({
                name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                properties: {
                  organizationSlug,
                  meetingPlanId,
                  change: result.status === 201 ? 'scheduled' : 'started',
                },
              });
              if (
                meetingPlan &&
                workspacePreferences?.orgPreferenceAutomaticCallRecording ===
                'NONE' &&
                ['PAID', 'VIP', 'INTERNAL'].includes(organization?.type || '')
              ) {
                const isExternal = isExternalEventOrMeetingflow(
                  meetingPlan,
                  internalDomains,
                );

                return (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {result.status === 201
                      ? `Successfully scheduled call recording`
                      : `Successfully started call recording`}
                    <br />
                    <br />
                    <Link
                      style={{ display: 'contents' }}
                      onClick={() => {
                        if (!updateWorkspacePreferencesAsync) {
                          return;
                        }

                        toast.promise(
                          updateWorkspacePreferencesAsync({
                            orgPreferenceAutomaticCallRecording: isExternal
                              ? 'EXTERNAL'
                              : 'ALL',
                          }),
                          {
                            loading: 'Enabling automatic call recording',
                            error:
                              'Something went wrong enabling automatic call recording, please try again later',
                            success: () => {
                              appInsights.trackEvent({
                                name: 'ENABLE_AUTOMATIC_CALL_RECORDING_FROM_TOAST',
                                properties: {
                                  organizationSlug,
                                  type: isExternal ? 'EXTERNAL' : 'ALL',
                                },
                              });

                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  Successfully enabled automatic call recording.
                                  <br />
                                  <br />
                                  You can change this setting at any time in
                                  your{' '}
                                  <Link
                                    style={{ display: 'contents' }}
                                    as={'a'}
                                    onClick={() => {
                                      navigate(
                                        `/organization/${organizationSlug}/settings?tab=user`,
                                      );
                                    }}
                                  >
                                    workspace preferences
                                  </Link>
                                  .
                                </div>
                              );
                            },
                          },
                        );
                      }}
                    >
                      Click here
                    </Link>{' '}
                    to automatically record all
                    {isExternal ? ' external ' : ' internal and external '}
                    meetings on your calendar.
                  </div>
                );
              }

              return result.status === 201
                ? `Successfully scheduled call recording`
                : `Successfully started call recording`;
            },
          },
          {
            success: {
              duration:
                workspacePreferences?.orgPreferenceAutomaticCallRecording ===
                  'NONE' &&
                  ['PAID', 'VIP', 'INTERNAL'].includes(organization?.type || '')
                  ? 10000
                  : undefined,
            },
          },
        ),
      );
    } else if (
      meetingPlan.callRecording &&
      [
        'scheduled',
        'ready',
        'joining_call',
        'in_waiting_room',
        'in_call_not_recording',
        'in_call_recording',
      ].includes(botStatus?.code || '')
    ) {
      getAccessTokenSilently().then((token) =>
        toast.promise(
          MeetingflowsApiClient.stopOrCancelCallRecording(
            organizationSlug,
            meetingPlanId,
            {
              headers: headers(token),
              validateStatus: deleteStatus,
            },
          ),
          {
            loading: fiveMinutesBeforeStart
              ? `Cancelling scheduled call recording`
              : `Stopping call recording`,
            error: (err) => {
              console.error(err);
              return fiveMinutesBeforeStart
                ? `Something went wrong cancelling scheduled call recording`
                : `Something went wrong stopping call recording`;
            },
            success: (result) => {
              sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_PLAN);
              sendWSMessage(
                provider?.ws,
                WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS,
              );

              client.removeQueries(
                MeetingPlanCallRecorderStatusQuery(
                  organizationSlug,
                  meetingPlanId,
                ),
              );

              refetchMeetingPlan();

              appInsights.trackEvent({
                name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                properties: {
                  organizationSlug,
                  meetingPlanId,
                  change: fiveMinutesBeforeStart ? 'cancelled' : 'stopped',
                },
              });

              return fiveMinutesBeforeStart
                ? `Successfully cancelled scheduled call recording`
                : `Successfully stopped call recording`;
            },
          },
        ),
      );
    } else if (botStatus?.code !== 'unknown') {
      createConfirmDeleteRecordingDeferred().promise.then((shouldDelete) => {
        if (shouldDelete) {
          getAccessTokenSilently().then((token) =>
            toast.promise(
              MeetingflowsApiClient.deleteCallRecording(
                organizationSlug,
                meetingPlanId,
                {
                  headers: headers(token),
                  validateStatus: deleteStatus,
                },
              ),
              {
                loading: `Deleting Call Recording`,
                error: (err) => {
                  console.error(err);
                  return `Something went wrong deleting call recording`;
                },
                success: () => {
                  sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_PLAN);
                  refetchMeetingPlan();
                  appInsights.trackEvent({
                    name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                    properties: {
                      organizationSlug,
                      meetingPlanId,
                      change: 'deleted',
                    },
                  });
                  return `Successfully deleted call recording`;
                },
              },
            ),
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    callRecorderStatus?.data,
    createConfirmDeleteRecordingDeferred,
    getAccessTokenSilently,
    meetingPlan,
    meetingPlanId,
    organizationSlug,
    provider?.ws,
    refetchMeetingPlan,
  ]);

  const renderCallRecordingShareToast = useCallback(
    (updatedMeetingplan: DetailedMeetingflow) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <strong
            style={{
              fontWeight: FontWeights.semibold,
              color: MEETINGFLOW_COLORS.teal,
            }}
          >
            Call recording and transcript shared
          </strong>
          Shared call recording URL has been copied to your clipboard.{' '}
          {updatedMeetingplan?.recordingShareType === 'ATTENDEES_ONLY'
            ? 'All attendees can access it, provided they have the URL.'
            : updatedMeetingplan?.recordingShareType === 'ATTENDEE_DOMAINS'
              ? 'Anyone with the same email domain as an attendee can access it, provided they have the URL.'
              : 'All attendees can access it, provided they have the URL'}
          <Link
            onClick={() => {
              showCollaborateDialog();
              navigate(`?accessTab=RecordingAccess`);
            }}
          >
            Change Access
          </Link>
        </div>
      );
    },
    [showCollaborateDialog, navigate],
  );

  const shareCallRecording = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      await toast.promise(
        ApiClient.patch(
          `/organization/${organizationSlug}/plan/${meetingPlanId}/recordingShare`,
          {
            recordingShareType: 'ATTENDEE_DOMAINS',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
        {
          loading: 'Updating recording sharing settings...',

          success: (result) => {
            appInsights.trackEvent({
              name: 'SHARE_CALL_RECORDING',
              properties: {
                organizationSlug,
                meetingPlanId,
              },
            });

            refetchMeetingPlan();

            const shareUrl = import.meta.env.DEV
              ? `${window.location.origin}/share/${organizationSlug}/${meetingPlanId}`.replace(
                'localhost:3000',
                'localhost:3001',
              )
              : `${window.location.origin}/share/${organizationSlug}/${meetingPlanId}`;

            setTimeout(() => navigator.clipboard.writeText(shareUrl), 500);

            return renderCallRecordingShareToast(meetingPlan!);
          },
          error: (err) => {
            return `Something went wrong updating the shared call recording settings. Please try again.`;
          },
        },
      );
    } catch { }
  }, [
    meetingPlanId,
    organizationSlug,
    appInsights,
    getAccessTokenSilently,
    refetchMeetingPlan,
    meetingPlan,
    renderCallRecordingShareToast,
  ]);

  useEffect(() => {
    // If the searchParams have a scheduleCallRecording, and the callRecording is not yet scheduled, schedule it upon page load
    if (
      searchParams.has('scheduleCallRecording') &&
      isBoolean(searchParams.get('scheduleCallRecording')) &&
      !!toBoolean(searchParams.get('scheduleCallRecording')) &&
      beforeEnd &&
      meetingPlan &&
      meetingPlan.conferenceInfo &&
      !['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
        meetingPlan.conferenceInfo.type,
      ) &&
      !!meetingPlan.conferenceInfo.joinUri &&
      (!meetingPlan.callRecording ||
        meetingPlan.callRecording.lastStatus === 'deleted')
    ) {
      scheduleCallRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, meetingPlan]);

  const callRecordingButtonLabel = useMemo(() => {
    if (!meetingPlan) return;

    const fiveMinutesBeforeStart =
      DateTime.fromISO(meetingPlan.startTime).diffNow('minutes').minutes > 5;
    const beforeEnd =
      DateTime.fromISO(meetingPlan.endTime).diffNow().milliseconds > 0;

    if (!meetingPlan.callRecording && !beforeEnd) return;

    const botStatus = !meetingPlan.callRecording
      ? undefined
      : (callRecorderStatus?.data ?? {
        code: meetingPlan.callRecording.lastStatus,
        subCode: meetingPlan.callRecording.lastStatusSubCode,
      } ?? { code: 'unknown' });

    if (botStatus?.code === 'unknown') return;

    if (
      beforeEnd &&
      (!meetingPlan.callRecording || botStatus?.code === 'deleted')
    ) {
      return 'Record';
    }

    if (
      meetingPlan.callRecording &&
      [
        'scheduled',
        'ready',
        'joining_call',
        'in_waiting_room',
        'in_call_not_recording',
        'in_call_recording',
      ].includes(botStatus?.code || '')
    ) {
      return fiveMinutesBeforeStart ? 'Cancel' : 'Stop';
    }

    if (meetingPlan.callRecording) {
      return 'Delete Recording';
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callRecorderStatus?.data, meetingPlan?.callRecording?.lastStatus]);

  const onCallRecorderStatusChanged = useCallback(
    ({
      code,
      subCode,
    }: {
      code: CallRecordingStatus;
      subCode?: CallRecordingStatusSubCode;
    }) => {
      switch (code) {
        case 'call_ended':
        case 'analysis_done':
        case 'done':
        case 'deleted':
        case 'scheduled':
        case 'ready':
        case 'joining_call':
        case 'in_waiting_room':
        case 'in_call_not_recording':
        case 'in_call_recording':
        case 'fatal': {
          sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_PLAN);
          sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS);

          client.removeQueries(
            MeetingPlanCallRecorderStatusQuery(organizationSlug, meetingPlanId),
          );

          refetchCallRecorderStatus();
          return refetchMeetingPlan();
        }
        case 'unknown':
        default: {
          return Promise.resolve(undefined);
        }
      }
    },
    [
      client,
      meetingPlanId,
      organizationSlug,
      provider?.ws,
      refetchCallRecorderStatus,
      refetchMeetingPlan,
    ],
  );

  const getMeetingflowURL = useMemo(() => {
    if (!meetingPlan) {
      return;
    }
    return `http://app.meetingflow.com/organization/${organizationSlug}/plan/${meetingPlan.id}`;
  }, [meetingPlan, organizationSlug]);

  const menuProps = useMemo(
    () => ({
      calloutProps: {
        styles: {
          root: {
            '[role=separator]': {
              backgroundColor: isDark
                ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
                : MEETINGFLOW_COLORS.purpleGrey,
            },
          },
        },
      },
      items: [
        {
          key: 'edit',
          text: 'Edit Meetingflow Details...',
          disabled: !canCreatePlans || isAssociatedWithEvent,
          onClick: showEditDetailsModal,
        },
        navigator.clipboard &&
          'writeText' in navigator.clipboard &&
          typeof navigator.clipboard.writeText === 'function'
          ? {
            key: 'copy-meetingflow-url',
            text: 'Copy Meetingflow URL...',
            onClick: (e: React.MouseEvent) => {
              navigator.clipboard
                .writeText(getMeetingflowURL!)
                .then(() =>
                  toast.success(
                    `Copied ${getMeetingflowURL} to the clipboard`,
                  ),
                );
            },
          }
          : undefined,
        navigator.clipboard &&
          'writeText' in navigator.clipboard &&
          typeof navigator.clipboard.writeText === 'function' &&
          !!meetingPlan?.conferenceInfo?.joinUri
          ? {
            key: 'copy-conferenceinfo-joinurl',
            text: 'Copy Call Join URL...',
            onClick: () => {
              if (!meetingPlan?.conferenceInfo?.joinUri) {
                return;
              }

              appInsights.trackEvent({
                name: 'MEETINGFLOW_TOOLBAR_MENU_CLICK_COPY_JOIN_URL',
                properties: {
                  meetingPlanId: meetingPlanId,
                  userId: user?.id,
                  surface: 'ToolbarShareButton',
                },
              });
              navigator.clipboard
                .writeText(meetingPlan.conferenceInfo.joinUri)
                .then(() =>
                  toast.success(`Copied Meeting Join URL to the clipboard`),
                );
              return true; // closes menu
            },
          }
          : undefined,
        {
          key: 'export',
          text: 'Export as Word document...',
          onClick: async () => {
            if (!user || !meetingPlan || !ydoc) {
              return;
            }
            try {
              const document = buildDocx(
                user,
                meetingPlan,
                tasksData?.data ?? EMPTY_ARRAY,
                ydoc,
              );
              const blob = await toBlob(document);
              saveAs(
                blob,
                `${meetingPlan.title}_${meetingPlan.startTime}.docx`,
              );
              appInsights.trackEvent({
                name: 'EXPORT_PLAN_TO_DOCX',
                properties: {
                  organizationSlug,
                  meetingPlanId,
                },
              });
            } catch (e: unknown) {
              toast.error('Failed to export Meetingflow to docx');
              console.error(e);
            }
          },
        },
        {
          key: 'export-zip',
          text: 'Export Meetingflow...',
          onClick: async () => {
            if (!user || !meetingPlan) {
              return;
            }
            try {
              const token = await getAccessTokenSilently();

              const headers = new Headers();
              headers.append('Authorization', `Bearer ${token}`);
              headers.append(
                'X-Tz',
                Intl.DateTimeFormat().resolvedOptions().timeZone,
              );
              const fetchPromise = fetch(
                `/api/organization/${organizationSlug}/plan/${meetingPlanId}/export`,
                {
                  method: 'GET',
                  headers,
                },
              );

              const response = await fetchPromise;

              if (response.ok) {
                const blobPromise = response.blob();

                toast.promise(blobPromise, {
                  loading: 'Exporting Meetingflow',
                  success: 'Successfully exported Meetingflow',
                  error: 'Something went wrong exporting Meetingflow',
                });

                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(
                  await blobPromise,
                );
                downloadLink.download = `${meetingPlan.startTime} ${meetingPlan.title.replaceAll('/', '-')}.zip`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                appInsights.trackEvent({
                  name: 'EXPORT_PLAN',
                  properties: {
                    organizationSlug,
                    meetingPlanId,
                  },
                });
              }
            } catch (e: unknown) {
              toast.error('Failed to export Meetingflow to docx');
              console.error(e);
            }
          },
        },
        {
          key: 'div-divider',
          itemType: ContextualMenuItemType.Divider,
        },
        hasEntitlement('CALL_RECORDING') &&
          canCreatePlans &&
          callRecordingButtonLabel === 'Record' &&
          !meetingPlan?.conferenceInfo
          ? {
            key: 'call-recording',
            text: callRecordingButtonLabel,
            disabled: !callRecordingButtonLabel,
            onClick: async () => {
              try {
                const clipboardContent = await readClipboardContents();
                const initialJoinUri = clipboardContent
                  ? tryParseConferenceInfo(clipboardContent) || undefined
                  : undefined;
                const { conferenceInfo } =
                  await createGetConferenceJoinLinkDeferred({
                    joinURI: initialJoinUri?.joinUri,
                  }).promise;

                if (!conferenceInfo) {
                  return;
                }

                if (!conferenceInfo.joinUri) {
                  return;
                }

                if (
                  ['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
                    conferenceInfo.type,
                  )
                ) {
                  switch (conferenceInfo.type) {
                    case 'ciscoWebEx':
                      toast.error(`WebEx is not a supported platform`);
                      return;
                    case 'skypeForBusiness':
                      toast.error(
                        `Skype For Business is not a supported platform`,
                      );
                      return;
                    case 'skype':
                      toast.error(`Skype is not a supported platform`);
                      return;
                  }
                  return;
                }

                const headers = (token: string) => ({
                  Authorization: `Bearer ${token}`,
                });
                const validateStatus = (status: number) =>
                  [200, 201].includes(status);

                const token = await getAccessTokenSilently();
                await toast.promise(
                  MeetingflowsApiClient.startOrScheduleCallRecording(
                    organizationSlug,
                    meetingPlanId,
                    {
                      joinURI: conferenceInfo.joinUri,
                    },
                    {
                      headers: headers(token),
                      validateStatus,
                    },
                  ),
                  {
                    loading: `Starting Call Recording`,
                    error: (err) => {
                      console.error(err);
                      return `Something went wrong starting call recording`;
                    },
                    success: (result) => {
                      sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_PLAN);
                      sendWSMessage(
                        provider?.ws,
                        WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS,
                      );

                      client.removeQueries(
                        MeetingPlanCallRecorderStatusQuery(
                          organizationSlug,
                          meetingPlanId,
                        ),
                      );

                      refetchCallRecorderStatus();
                      refetchMeetingPlan();

                      appInsights.trackEvent({
                        name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                        properties: {
                          organizationSlug,
                          meetingPlanId,
                          change: 'started',
                        },
                      });
                      if (
                        meetingPlan &&
                        workspacePreferences?.orgPreferenceAutomaticCallRecording ===
                        'NONE' &&
                        ['PAID', 'VIP', 'INTERNAL'].includes(
                          organization?.type || '',
                        )
                      ) {
                        const isExternal = isExternalEventOrMeetingflow(
                          meetingPlan,
                          internalDomains,
                        );

                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            {result.status === 201
                              ? `Successfully scheduled call recording`
                              : `Successfully started call recording`}
                            <br />
                            <br />
                            <Link
                              style={{ display: 'contents' }}
                              as={'a'}
                              onClick={() => {
                                if (!updateWorkspacePreferencesAsync) {
                                  return;
                                }

                                toast.promise(
                                  updateWorkspacePreferencesAsync({
                                    orgPreferenceAutomaticCallRecording:
                                      isExternal ? 'EXTERNAL' : 'ALL',
                                  }),
                                  {
                                    loading:
                                      'Enabling automatic call recording',
                                    error:
                                      'Something went wrong enabling automatic call recording, please try again later',
                                    success: () => {
                                      appInsights.trackEvent({
                                        name: 'ENABLE_AUTOMATIC_CALL_RECORDING_FROM_TOAST',
                                        properties: {
                                          organizationSlug,
                                          type: isExternal
                                            ? 'EXTERNAL'
                                            : 'ALL',
                                        },
                                      });

                                      return (
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                        >
                                          Successfully enabled automatic call
                                          recording.
                                          <br />
                                          <br />
                                          You can change this setting at any
                                          time in your{' '}
                                          <Link
                                            style={{ display: 'contents' }}
                                            as={'a'}
                                            onClick={() => {
                                              navigate(
                                                `/organization/${organizationSlug}/settings?tab=user`,
                                              );
                                            }}
                                          >
                                            workspace preferences
                                          </Link>
                                          .
                                        </div>
                                      );
                                    },
                                  },
                                );
                              }}
                            >
                              Click here
                            </Link>{' '}
                            to automatically record all
                            {isExternal
                              ? ' external '
                              : ' internal and external '}
                            meetings on your calendar.
                          </div>
                        );
                      }

                      return result.status === 201
                        ? `Successfully scheduled call recording`
                        : `Successfully started call recording`;
                    },
                  },
                  {
                    success: {
                      duration:
                        workspacePreferences?.orgPreferenceAutomaticCallRecording ===
                          'NONE' &&
                          ['PAID', 'VIP', 'INTERNAL'].includes(
                            organization?.type || '',
                          )
                          ? 10000
                          : undefined,
                    },
                  },
                );
              } catch (err: unknown) { }
            },
          }
          : undefined,
        hasEntitlement('CALL_RECORDING') &&
          canCreatePlans &&
          callRecordingButtonLabel &&
          (callRecordingButtonLabel !== 'Record' ||
            (meetingPlan?.conferenceInfo &&
              !['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
                meetingPlan.conferenceInfo.type,
              ) &&
              meetingPlan.conferenceInfo.joinUri))
          ? {
            key: 'call-recording',
            text: callRecordingButtonLabel,
            disabled: !callRecordingButtonLabel,
            onClick: scheduleCallRecording,
          }
          : undefined,
        import.meta.env.DEV &&
          hasEntitlement('CALL_RECORDING') &&
          meetingPlan?.callRecording
          ? {
            key: 'sync-recording',
            text: 'Sync Recording',
            disabled:
              !meetingPlan?.callRecording ||
              [
                'scheduled',
                'ready',
                'joining_call',
                'in_waiting_room',
                'in_call_not_recording',
                'unknown',
                'deleted',
              ].includes(callRecorderStatus?.data?.code || 'unknown'),
            onClick: () => {
              getAccessTokenSilently().then((token) =>
                toast.promise(
                  MeetingflowsApiClient.syncRecording(
                    organizationSlug,
                    meetingPlanId,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                      validateStatus: (status) => [200, 201].includes(status),
                    },
                  ),
                  {
                    loading: `Syncing Call Recording`,
                    error: (err) => {
                      console.error(err);
                      return `Something went wrong syncing call recording`;
                    },
                    success: (response) => {
                      sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_PLAN);
                      sendWSMessage(
                        provider?.ws,
                        WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS,
                      );

                      client.removeQueries(
                        MeetingPlanCallRecorderStatusQuery(
                          organizationSlug,
                          meetingPlanId,
                        ),
                      );

                      refetchCallRecorderStatus();
                      refetchMeetingPlan();

                      return `Successfully synced call recording`;
                    },
                  },
                ),
              );
            },
          }
          : undefined,
        {
          key: 'save new',
          text: 'Save Meetingflow as new template',
          disabled: !canCreatePlans || templateSaving || !ydoc,
          onClick: () => {
            if (!ydoc) {
              return;
            }

            const notes = yTextToSlateElement(
              ydoc.get('notes', Y.XmlText) as unknown as Y.XmlText,
            );
            if (!(store.resources.length || slateHasContent(notes))) {
              return;
            }
            setTemplateSaving(true);
            (async () => {
              if (meetingPlanTemplates?.data === undefined) {
                return;
              }
              try {
                const { name: templateName, description } =
                  await deferTemplateName().promise;
                const token = await getAccessTokenSilently();
                await toast.promise(
                  TemplatesApiClient.postTemplate(
                    organizationSlug,
                    {
                      name: templateName,
                      description,
                      resources: store.resources.length
                        ? store.resources
                        : undefined,
                      notes: slateHasContent(notes)
                        ? notes.children
                        : undefined,
                    },
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    },
                  ),
                  {
                    loading: 'Saving new template',
                    success: (result) => {
                      appInsights.trackEvent({
                        name: 'CREATE_TEMPLATE',
                        properties: {
                          organizationSlug,
                          meetingPlanId,
                          templateId: result.data.id,
                        },
                      });

                      refetchMeetingPlanTemplates();

                      return `Meetingflow saved as new template ${templateName}`;
                    },
                    error: (err: unknown) => {
                      return `Something went wrong saving Meetingflow as new template, please try again later`;
                    },
                  },
                );
              } catch { }
            })().finally(() => {
              setTemplateSaving(false);
            });
          },
        },
        {
          key: 'templates',
          text: 'Apply Template',
          disabled:
            !canCreatePlans ||
            meetingPlanTemplatesLoading ||
            !meetingPlanTemplates?.data?.length,
          subMenuProps: {
            items:
              meetingPlanTemplates?.data?.map((t: MeetingflowTemplate) => {
                return {
                  key: `${t.id}`,
                  split: true,
                  text: t.name,
                  onClick: () => applyTemplate(t),
                } as IContextualMenuItem;
              }) ?? [],
          },
        },
        {
          key: 'div-divider',
          itemType: ContextualMenuItemType.Divider,
        },
        {
          key: 'privacy',
          text: 'Access & Privacy',
          disabled: !canCreatePlans,
          onClick: () => {
            showCollaborateDialog({
              type: 'ShareAccess',
            })
              .promise.then((success) => {
                if (success) {
                  refetchMeetingPlan();
                }
              })
              .catch();
          },
        },
        {
          key: 'delete',
          text: 'Delete Meetingflow',
          disabled:
            !(
              isAdmin ||
              meetingPlan?.ownerId === userId ||
              (meetingPlan?.creator &&
                meetingPlan?.creator?.email === user?.email) ||
              (meetingPlan?.organizer?.email &&
                meetingPlan?.organizer?.email === user?.email)
            ) || !!meetingPlan?.sampleType,
          onClick: () => {
            createConfirmDeleteDeferred()
              .promise.then(async (shouldDelete) => {
                if (shouldDelete) {
                  const token = await getAccessTokenSilently();
                  const response =
                    await MeetingflowsApiClient.deleteMeetingflow(
                      organizationSlug,
                      meetingPlanId,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      },
                    );
                  if (response.status === 204) {
                    navigate(`/organization/${organizationSlug}`);

                    client.invalidateQueries(
                      MeetingPlanQuery(organizationSlug, meetingPlanId),
                    );
                    client.invalidateQueries(
                      MeetingPlanCallRecorderStatusQuery(
                        organizationSlug,
                        meetingPlanId,
                      ),
                    );
                    client.invalidateQueries(
                      OrganizationUpcomingMeetings(organizationSlug),
                    );
                    client.invalidateQueries(
                      OrganizationMeetingsHappeningSoon(organizationSlug),
                    );
                    client.invalidateQueries(
                      OrganizationMeetingPlansQuery(organizationSlug),
                    );

                    appInsights.trackEvent({
                      name: 'DELETE_PLAN',
                      properties: {
                        organizationSlug,
                        planId: meetingPlanId,
                      },
                    });
                  }
                }
              })
              .catch();
          },
        },
        {
          key: 'div-divider',
          itemType: ContextualMenuItemType.Divider,
        },
        import.meta.env.DEV
          ? {
            key: 'force recreate provider',
            text: 'Force recreate ws provider',
            disabled: !provider,
            onClick: recreateProvider,
          }
          : undefined,
        import.meta.env.DEV
          ? {
            key: 'connect provider',
            text: 'Connect ws provider',
            disabled: collabIsConnected === true,
            onClick: () => provider?.connect(),
          }
          : undefined,
        import.meta.env.DEV
          ? {
            key: 'disconnect provider',
            text: 'Disconnect ws provider',
            disabled: collabIsConnected === false,
            onClick: () => provider?.disconnect(),
          }
          : undefined,
      ].filter(Truthy) as IContextualMenuItem[],
    }),
    [
      isDark,
      canCreatePlans,
      isAssociatedWithEvent,
      showEditDetailsModal,
      meetingPlan,
      hasEntitlement,
      callRecordingButtonLabel,
      scheduleCallRecording,
      callRecorderStatus?.data?.code,
      templateSaving,
      ydoc,
      meetingPlanTemplatesLoading,
      meetingPlanTemplates?.data,
      isAdmin,
      userId,
      user,
      provider,
      recreateProvider,
      collabIsConnected,
      getMeetingflowURL,
      appInsights,
      meetingPlanId,
      tasksData?.data,
      organizationSlug,
      createGetConferenceJoinLinkDeferred,
      getAccessTokenSilently,
      workspacePreferences?.orgPreferenceAutomaticCallRecording,
      organization?.type,
      refetchCallRecorderStatus,
      refetchMeetingPlan,
      internalDomains,
      updateWorkspacePreferencesAsync,
      navigate,
      store.resources,
      deferTemplateName,
      refetchMeetingPlanTemplates,
      applyTemplate,
      showCollaborateDialog,
      createConfirmDeleteDeferred,
      client,
    ],
  );

  const sidePanelContent = useMemo(() => {
    if (!meetingPlan) {
      return null;
    }
    switch (panelContext?.type) {
      case 'insights': {
        return (
          <InsightsSidePanel
            key={meetingPlanId}
            color={color}
            organizationSlug={organizationSlug!}
            meetingflowId={meetingPlanId}
            meetingflow={meetingPlan!}
            templateChatSuggestions={templateChatSuggestions ?? []}
            templateEditorSuggestions={templateEditorSuggestions ?? []}
            editor={notesEditor}
            callRecordingStatus={callRecorderStatus?.data?.code}
            internalDomains={internalDomains}
            externalDomains={externalDomains}
            externalContactIds={externalContactIds}
            salesforcePlanContext={salesforcePlanContext?.data}
            hubspotPlanContext={hubspotPlanContext?.data}
            pushPanelContext={pushPanelContext}
            setChatboxContext={(context) =>
              setPanelContext({ type: 'chatbot', context })
            }
            onRelatedPlansViewAllClick={() => {
              pushPanelContext({
                type: 'plans',
              });
            }}
            onMeetingflowClick={(panelMeetingPlanId: string) => {
              pushPanelContext({
                type: 'plan',
                meetingPlanId: panelMeetingPlanId,
              });
            }}
            // onContactClick={onContactClick}
            onContactsViewAllClick={() => {
              pushPanelContext({
                type: 'contacts',
              });
            }}
            onCompanyClick={onCompanyClick}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            pushSalesforcePanel={(context) => {
              pushPanelContext({
                type: 'salesforce-object',
                salesforceContext: context,
              });
            }}
            pushHubSpotPanel={(context) => {
              pushPanelContext({
                type: 'hubspot-object',
                context,
              });
            }}
            showSalesforceObjectPicker={(
              defaultTab?: ExternalServiceObjectType | 'PINNED',
            ) => {
              if (defaultTab === 'PINNED') {
                pushPanelContext(DEFAULT_SALESFORCE_BROWSER_CONTEXT);
              } else {
                pushPanelContext({
                  type: 'salesforce-browser',
                  defaultTab,
                });
              }
            }}
            showHubSpotObjectPicker={(
              defaultTab?: ExternalServiceObjectType | 'PINNED',
            ) => {
              if (defaultTab === 'PINNED') {
                pushPanelContext(DEFAULT_HUBSPOT_BROWSER_CONTEXT);
              } else {
                pushPanelContext({
                  type: 'hubspot-browser',
                  defaultTab,
                });
              }
            }}
            onPinnedSalesforceObject={() => refetchMeetingPlan()}
            onPinnedHubSpotObject={() => refetchMeetingPlan()}
            onPickedSalesforceObject={(associatedObject) => {
              refetchMeetingPlan();
              const newPanelContext = {
                type: 'salesforce-object',
                salesforceContext: {
                  associatedWithPlan: meetingPlan?.associations?.some(
                    (association) =>
                      association.externalId === associatedObject.externalId,
                  ),
                  objectId: associatedObject.externalId,
                  name: associatedObject.name,
                  objectType: associatedObject.objectType,
                  serviceConfigurationId:
                    associatedObject.serviceConfigurationId,
                  serviceInstanceId:
                    associatedObject.serviceConfiguration.instanceId,
                },
              } as MeetingPlanSalesforceObjectPanelContext;
              if (panelVisible) {
                pushPanelContext(newPanelContext);
              } else {
                setPanelContext(newPanelContext);
              }
            }}
            onPickedHubSpotObject={(associatedObject) => {
              refetchMeetingPlan();
              const newPanelContext = {
                type: 'hubspot-object',
                context: {
                  associatedWithPlan: meetingPlan?.associations?.some(
                    (association) =>
                      association.externalId === associatedObject.externalId,
                  ),
                  objectId: associatedObject.externalId,
                  name: associatedObject.name,
                  objectType: associatedObject.objectType,
                  serviceConfigurationId:
                    associatedObject.serviceConfigurationId,
                  serviceInstanceId:
                    associatedObject.serviceConfiguration.instanceId,
                },
              } as MeetingPlanHubSpotObjectPanelContext;
              if (panelVisible) {
                pushPanelContext(newPanelContext);
              } else {
                setPanelContext(newPanelContext);
              }
            }}
            refetchMeetingflow={refetchMeetingPlan}
          />
        );
      }
      case 'dossier': {
        return (
          <DossierSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug!}
            meetingPlanId={meetingPlanId}
            internalDomains={internalDomains}
            meetingplan={meetingPlan}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            onGeneratedDossier={refreshPlan}
          />
        );
      }
      case 'contacts': {
        return (
          <ContactsSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug!}
            meetingPlan={meetingPlan}
            internalDomains={internalDomains}
            salesforcePlanContext={salesforcePlanContext?.data}
            hubspotPlanContext={hubspotPlanContext?.data}
            onContactClick={(c: Contact['id']) => {
              pushPanelContext({
                type: 'contact',
                contactId: c,
              });
            }}
            onCompanyClick={(id: Company['id']) => {
              const clickedCompany = meetingPlan?.companies.find(
                (c) => c.id === id,
              );

              if (clickedCompany) {
                pushPanelContext({
                  type: 'company',
                  companyId: clickedCompany.id,
                });
              }
            }}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            refetchMeetingPlan={refetchMeetingPlan}
          />
        );
      }
      case 'contact': {
        return panelContext.contactId ? (
          <ContactSidePanel
            key={panelContext.contactId}
            color={color}
            contactId={panelContext.contactId}
            organizationSlug={organizationSlug!}
            salesforcePlanContext={salesforcePlanContext?.data}
            hubspotPlanContext={hubspotPlanContext?.data}
            onCompanyClick={(companyId) => {
              pushPanelContext({
                type: 'company',
                companyId: companyId,
              });
            }}
            onContactClick={(contactId) => {
              pushPanelContext({
                type: 'contact',
                contactId,
              });
            }}
            meetingPlan={meetingPlan}
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                setPanelContext({
                  type: 'contacts',
                });
              }
            }}
            onClose={setPanelCollapsed}
            onMeetingflowClick={(panelMeetingPlanId: string) => {
              pushPanelContext({
                type: 'plan',
                meetingPlanId: panelMeetingPlanId,
              });
            }}
          />
        ) : null;
      }
      case 'companies': {
        return (
          <CompaniesSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug}
            companies={meetingPlan?.companies}
            salesforcePlanContext={salesforcePlanContext?.data}
            hubspotPlanContext={hubspotPlanContext?.data}
            internalDomains={internalDomains}
            onCompanyClick={onCompanyClick}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
          />
        );
      }
      case 'company': {
        return panelContext.companyId ? (
          <CompanySidePanel
            key={panelContext.companyId}
            meetingPlan={meetingPlan!}
            color={color}
            companyId={panelContext.companyId}
            salesforcePlanContext={salesforcePlanContext?.data}
            hubspotPlanContext={hubspotPlanContext?.data}
            organizationSlug={organizationSlug!}
            onContactClick={(contactId) => {
              pushPanelContext({
                type: 'contact',
                contactId: contactId,
              });
            }}
            onSalesforceAccountClick={(
              salesforceContext: SalesforcePanelContext,
            ) => {
              pushPanelContext({
                type: 'salesforce-object',
                salesforceContext,
              });
            }}
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                setPanelContext({
                  type: 'companies',
                });
              }
            }}
            onClose={setPanelCollapsed}
          />
        ) : null;
      }
      case 'plans': {
        return (
          <PlansSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug!}
            meetingflowId={meetingPlanId}
            title={panelContext.title}
            filters={panelContext.filters}
            meetingflow={meetingPlan}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            onContactClick={(c: Contact['id']) => {
              pushPanelContext({
                type: 'contact',
                contactId: c,
              });
            }}
            onMeetingflowClick={(panelMeetingPlanId: string) => {
              pushPanelContext({
                type: 'plan',
                meetingPlanId: panelMeetingPlanId,
              });
            }}
            color={color}
          />
        );
      }
      case 'plan': {
        return panelContext?.meetingPlanId ? (
          <PlanSidePanel
            key={panelContext?.meetingPlanId}
            organizationSlug={organizationSlug!}
            meetingPlan={meetingPlan}
            panelMeetingPlanId={panelContext?.meetingPlanId}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            pushPanelContext={pushPanelContext}
            setChatboxContext={(context) =>
              setPanelContext({ type: 'chatbot', context })
            }
            color={color}
          />
        ) : null;
      }
      case 'timeline': {
        return (
          <TimelineSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug!}
            meetingPlan={meetingPlan}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            onContactClick={(c: Contact['id']) => {
              pushPanelContext({
                type: 'contact',
                contactId: c,
              });
            }}
          />
        );
      }
      case 'salesforce-object': {
        return (
          <SalesforceObjectSidePanel
            key={panelContext.salesforceContext.objectId}
            organizationSlug={organizationSlug!}
            meetingPlan={meetingPlan}
            salesforceContext={panelContext.salesforceContext}
            defaultNewOppName={meetingPlan?.title}
            showObjectPicker={(
              defaultTab?: ExternalServiceObjectType | 'PINNED',
            ) => {
              if (defaultTab === 'PINNED') {
                pushPanelContext(DEFAULT_SALESFORCE_BROWSER_CONTEXT);
              } else {
                pushPanelContext({
                  type: 'salesforce-browser',
                  defaultTab,
                });
              }
            }}
            pushSalesforcePanel={(context) => {
              pushPanelContext({
                type: 'salesforce-object',
                salesforceContext: context,
              });
            }}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            onLogCall={(context) =>
              pushPanelContext({
                type: 'salesforce-log-meeting',
                context,
              })
            }
            onTimelineClick={
              meetingPlan
                ? () => {
                  setPanelContext({ type: 'timeline' });
                }
                : undefined
            }
          />
        );
      }
      case 'salesforce-browser': {
        return (
          <SalesforceBrowserSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug!}
            meetingPlanId={meetingPlanId}
            associatedObjects={meetingPlan.associations.filter(
              (a) => a.serviceConfiguration.app === 'SALESFORCE',
            )}
            defaultNewOppName={meetingPlan?.title}
            externalDomains={externalDomains}
            externalContactIds={externalContactIds}
            defaultTab={panelContext.defaultTab}
            pushSalesforcePanel={(context) => {
              pushPanelContext({
                type: 'salesforce-object',
                salesforceContext: context,
              });
            }}
            onPinnedObject={(_e) => refetchMeetingPlan()}
            onPickedObject={(associatedObject) => {
              refetchMeetingPlan();
              const newPanelContext = {
                type: 'salesforce-object',
                salesforceContext: {
                  associatedWithPlan: meetingPlan.associations
                    .map((o) => o.externalId)
                    .includes(associatedObject.externalId),
                  objectId: associatedObject.externalId,
                  name: associatedObject.name,
                  objectType: associatedObject.objectType,
                  serviceConfigurationId:
                    associatedObject.serviceConfigurationId,
                  serviceInstanceId:
                    associatedObject.serviceConfiguration.instanceId,
                },
              } as MeetingPlanSalesforceObjectPanelContext;
              if (panelVisible) {
                pushPanelContext(newPanelContext);
              } else {
                setPanelContext(newPanelContext);
              }
            }}
            onTabChanged={(tab) => {
              if (tab === 'PINNED') {
                setLastSalesforcePanelContext(
                  DEFAULT_SALESFORCE_BROWSER_CONTEXT,
                );
              } else {
                setLastSalesforcePanelContext({
                  type: 'salesforce-browser',
                  defaultTab: tab,
                });
              }
            }}
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                setPanelContext(DEFAULT_SALESFORCE_BROWSER_CONTEXT);
              }
            }}
            onClose={setPanelCollapsed}
          />
        );
      }
      case 'salesforce-log-meeting': {
        return (
          <LogMeetingToSalesforceSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlanId}
            meetingPlan={meetingPlan}
            ydoc={ydoc}
            internalDomains={internalDomains}
            notesYArray={notesYArray}
            tasks={tasksData?.data || EMPTY_ARRAY}
            onSuccess={showConfetti}
            onSalesforceObjectClick={(
              salesforceContext: SalesforcePanelContext,
            ) => {
              pushPanelContext({
                type: 'salesforce-object',
                salesforceContext,
              });
            }}
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                setPanelContext(DEFAULT_SALESFORCE_BROWSER_CONTEXT);
              }
            }}
            onClose={setPanelCollapsed}
            {...panelContext.context}
          />
        );
      }
      case 'hubspot-object': {
        return panelContext.context ? (
          <HubSpotObjectSidePanel
            key={panelContext.context.objectId}
            organizationSlug={organizationSlug!}
            meetingPlan={meetingPlan}
            panelContext={panelContext.context}
            pushHubSpotPanel={(context) =>
              pushPanelContext({ type: 'hubspot-object', context })
            }
            showObjectPicker={(defaultTab) => {
              pushPanelContext({
                type: 'hubspot-browser',
                defaultTab,
              });
            }}
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                setPanelContext({ type: 'hubspot-browser' });
              }
            }}
            onClose={setPanelCollapsed}
            onLogCall={() => pushPanelContext({ type: 'hubspot-log-meeting' })}
            onTimelineClick={() => pushPanelContext({ type: 'timeline' })}
            onPinClick={() => refetchMeetingPlan()}
          />
        ) : null;
      }
      case 'hubspot-browser': {
        return (
          <HubSpotBrowserSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug!}
            meetingPlanId={meetingPlan.id}
            associatedObjects={meetingPlan.associations.filter(
              (a) => a.serviceConfiguration.app === 'HUBSPOT',
            )}
            externalDomains={externalDomains}
            externalContactIds={externalContactIds}
            defaultTab={panelContext.defaultTab}
            pushHubSpotPanel={(context) => {
              pushPanelContext({
                type: 'hubspot-object',
                context: context,
              });
            }}
            onPinnedObject={() => refetchMeetingPlan()}
            onPickedObject={(associatedObject) => {
              refetchMeetingPlan();
              const newPanelContext = {
                type: 'hubspot-object',
                context: {
                  associatedWithPlan: meetingPlan.associations
                    .map((o) => o.externalId)
                    .includes(associatedObject.externalId),
                  objectId: associatedObject.externalId,
                  name: associatedObject.name,
                  objectType: associatedObject.objectType,
                  serviceConfigurationId:
                    associatedObject.serviceConfigurationId,
                  serviceInstanceId:
                    associatedObject.serviceConfiguration.instanceId,
                },
              } as MeetingPlanHubSpotObjectPanelContext;
              if (panelVisible) {
                pushPanelContext(newPanelContext);
              } else {
                setPanelContext(newPanelContext);
              }
            }}
            onLogMeetingToHubSpot={() =>
              pushPanelContext({
                type: 'hubspot-log-meeting',
              })
            }
            onTabChanged={(tab) => {
              if (tab === 'PINNED') {
                setLastHubSpotPanelContext(DEFAULT_HUBSPOT_BROWSER_CONTEXT);
              } else {
                setLastHubSpotPanelContext({
                  type: 'hubspot-browser',
                  defaultTab: tab,
                });
              }
            }}
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                setPanelContext(DEFAULT_SALESFORCE_BROWSER_CONTEXT);
              }
            }}
            onClose={setPanelCollapsed}
          />
        );
      }
      case 'hubspot-log-meeting': {
        return (
          <LogMeetingToHubSpotSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlanId}
            meetingPlan={meetingPlan}
            ydoc={ydoc}
            internalDomains={internalDomains}
            notesYArray={notesYArray}
            hubspotPlanContext={hubspotPlanContext?.data}
            tasks={tasksData?.data || EMPTY_ARRAY}
            initialSummary={panelContext.summaryText}
            onSuccess={showConfetti}
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                setPanelContext(DEFAULT_HUBSPOT_BROWSER_CONTEXT);
              }
            }}
            onClose={setPanelCollapsed}
          />
        );
      }
      case 'slack': {
        return (
          <SlackSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlanId}
            internalDomains={internalDomains}
            notesYArray={notesYArray}
            meetingPlan={meetingPlan}
            tasks={tasksData?.data || EMPTY_ARRAY}
            initialSummary={panelContext.summaryText}
            onSendSummary={refreshPlanWithConfetti}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
          />
        );
      }
      case 'ms-teams': {
        return (
          <TeamsSidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlanId}
            internalDomains={internalDomains}
            notesYArray={notesYArray}
            meetingPlan={meetingPlan}
            tasks={tasksData?.data || EMPTY_ARRAY}
            initialSummary={panelContext.summaryText}
            onSendSummary={refreshPlanWithConfetti}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
          />
        );
      }

      case 'email-followup': {
        return (
          <EmailFollowupSidePanel
            key={meetingPlanId}
            notesYArray={notesYArray}
            meetingplan={meetingPlan}
            internalDomains={internalDomains}
            organizationSlug={organizationSlug}
            tasks={tasksData?.data || EMPTY_ARRAY}
            initialSummary={panelContext.summaryText}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            onSuccess={async () => {
              showConfetti();
            }}
          />
        );
      }
      case 'internal-summary': {
        return (
          <SummarySidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug}
            meetingPlan={meetingPlan}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            notesYArray={notesYArray}
            tasks={tasksData?.data || EMPTY_ARRAY}
            internalDomains={internalDomains}
            initialSummary={panelContext.summaryText}
            onSaveSummary={async () => {
              refreshPlanWithConfetti();
              setPanelContext({ type: 'insights' });
            }}
          />
        );
      }
      case 'invite-and-notify': {
        return (
          <InviteAndNotifySidePanel
            key={meetingPlanId}
            organizationSlug={organizationSlug}
            meetingPlan={meetingPlan}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            notesYArray={notesYArray}
            tasks={tasksData?.data || EMPTY_ARRAY}
            internalDomains={internalDomains}
            onDone={refreshPlan}
          />
        );
      }
      case 'chatbot': {
        return (
          <ChatBotSidePanel
            key={meetingPlanId}
            chatboxContext={panelContext.context}
            setChatBoxContext={(context) =>
              setPanelContext({ type: 'chatbot', context })
            }
            meetingPlan={meetingPlan!}
            organizationSlug={organizationSlug!}
            internalDomains={internalDomains}
            externalDomains={externalDomains}
            externalContactIds={externalContactIds}
            onMeetingflowClick={(panelMeetingPlanId: string) => {
              pushPanelContext({
                type: 'plan',
                meetingPlanId: panelMeetingPlanId,
              });
            }}
            onContactClick={onContactClick}
            onCompanyClick={onCompanyClick}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
          />
        );
      }
      case 'follow-up': {
        return (
          <FollowUpSidePanel
            key={meetingPlanId}
            meetingPlan={meetingPlan!}
            organizationSlug={organizationSlug!}
            pushPanelContext={pushPanelContext}
            setPanelContext={setPanelContext}
            panelVisible={panelVisible}
            lastSalesforcePanelContext={lastSalesforcePanelContext}
            lastHubSpotPanelContext={lastHubSpotPanelContext}
            onContactClick={onContactClick}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            createAddIntegrationDeferred={createAddIntegrationDeferred}
          />
        );
      }
      case 'action-items': {
        return (
          <ActionItemsSidePanel
            key={meetingPlanId}
            meetingPlan={meetingPlan!}
            organizationSlug={organizationSlug!}
            onContactClick={onContactClick}
            onBack={panelStackCount > 1 ? popPanelStack : undefined}
            onClose={setPanelCollapsed}
            loading={tasksLoading}
            tasks={tasksData?.data || EMPTY_ARRAY}
            suggestedTasks={suggestedActionItemsAsTasks}
            onAdd={addTask}
            onUpdate={(id, update) => patchTask({ id, ...update })}
            onDelete={deleteTask}
            actionItemsContext={panelContext}
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [
    meetingPlan,
    panelContext,
    meetingPlanId,
    color,
    organizationSlug,
    notesEditor,
    callRecorderStatus?.data?.code,
    internalDomains,
    externalDomains,
    externalContactIds,
    salesforcePlanContext?.data,
    hubspotPlanContext?.data,
    pushPanelContext,
    onCompanyClick,
    panelStackCount,
    popPanelStack,
    setPanelCollapsed,
    refetchMeetingPlan,
    setPanelContext,
    panelVisible,
    refreshPlan,
    setLastSalesforcePanelContext,
    ydoc,
    notesYArray,
    tasksData?.data,
    showConfetti,
    setLastHubSpotPanelContext,
    refreshPlanWithConfetti,
    onContactClick,
    lastSalesforcePanelContext,
    lastHubSpotPanelContext,
    createAddIntegrationDeferred,
    tasksLoading,
    suggestedActionItemsAsTasks,
    addTask,
    deleteTask,
    patchTask,
    templateChatSuggestions,
    templateEditorSuggestions,
  ]);

  const handleDragResize = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (mainColumn?.current && sidebarColumn?.current && layoutRef?.current) {
      const { x: mainColumnPositionX } =
        mainColumn.current.getBoundingClientRect();
      const newMainColumnWidth = e.clientX - mainColumnPositionX;
      const { width: layoutWidth } = layoutRef.current.getBoundingClientRect();
      const newMainColumnWidthPercentage = Math.round(
        (newMainColumnWidth / layoutWidth) * 100,
      );
      if (newMainColumnWidthPercentage > 0) {
        setMainColumnWidth(newMainColumnWidthPercentage.toString());
      }
    }
  };

  useLayoutEffect(() => {
    const resizerColumnCurrent = resizerColumn?.current;
    resizerColumnCurrent?.addEventListener('mousedown', (event) => {
      document.addEventListener('mousemove', handleDragResize, false);
      document.addEventListener(
        'mouseup',
        () => {
          document.removeEventListener('mousemove', handleDragResize, false);
        },
        false,
      );
    });

    return () => {
      resizerColumnCurrent?.removeEventListener('mousedown', (event) => {
        document.addEventListener('mousemove', handleDragResize, false);
        document.addEventListener(
          'mouseup',
          () => {
            document.removeEventListener('mousemove', handleDragResize, false);
          },
          false,
        );
      });
    };
  });

  useLayoutEffect(() => {
    if (!breakpoints?.md) {
      if (mainColumn?.current?.style && sidebarColumn?.current?.style) {
        mainColumn.current.style.flexBasis = `100%`;
        sidebarColumn.current.style.flexBasis = '100%';
      }
    } else if (!panelVisible) {
      if (mainColumn?.current?.style) {
        mainColumn.current.style.flexBasis = '100%';
      }
    } else {
      if (mainColumn?.current?.style && sidebarColumn?.current?.style) {
        mainColumn.current.style.flexBasis = `${mainColumnWidth}%`;
        sidebarColumn.current.style.flexBasis = `${100 - parseInt(mainColumnWidth)
          }%`;
      }
    }
  }, [panelVisible, mainColumnWidth, layeredPanel, breakpoints]);

  const isNowOrSoon = meetingPlan
    ? meetingPlan?.conferenceInfo &&
    DateTime.fromISO(meetingPlan?.startTime).diffNow('minutes').minutes <
    60 &&
    DateTime.fromISO(meetingPlan?.endTime).diffNow('minutes').minutes > -10
    : false;

  const SIDE_PANEL_MENU_WIDTH = layeredPanel ? '45px' : '90px';
  const SIDE_PANEL_MENU_ICON_WIDTH = layeredPanel ? '24px' : '42px';
  const SIDE_PANEL_MENU_ICON_HEIGHT = layeredPanel ? '36px' : '56px';

  const coachPromptButtonClass = mergeStyles({
    display: 'inline-block',
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semibold,
    height: '1.5rem',
    lineHeight: '1rem',
    color: 'white',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.purpleDark
      : MEETINGFLOW_COLORS.purpleMedium,
    padding: '0 0 0 .25rem',
    border: 'none !important',
    borderRadius: '.25rem',
    transition: '.3s ease-in-out all',
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    ':disabled': {
      opacity: '.25',
      'i, span': {
        color: isDark ? NeutralColors.gray80 : NeutralColors.gray160,
      },
    },

    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleDark,
      'i, span': {
        color: 'white',
      },
    },

    i: {
      position: 'relative',
      top: '2px',
      float: 'right',
      display: 'inline-block',
      fontSize: FontSizes.large,
      color: MEETINGFLOW_COLORS.white,
      fontWeight: FontWeights.regular,
      height: '1.5rem',
      width: '1.5rem',
      lineHeight: '1.5rem',
      marginLeft: '.25rem',
      transition: '.3s ease-in-out all',
    },

    span: {
      display: 'inline-block',
      height: '1.5rem',
      lineHeight: '1.5rem',
      position: 'relative',
      top: '-.5px',
      transition: '.3s ease-in-out all',
    },
  });

  const templateSuggestionClass = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    '.title': {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '.5rem',
      fontWeight: FontWeights.semibold,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',

      '.text': {
        flexBasis: '100%',
      },

      '.star': {
        flexBasis: '1rem',
        color: NeutralColors.gray100,
      },
    },
    '.description': {
      display: 'block',
      fontSize: FontSizes.small,
      color: isDark ? NeutralColors.gray80 : NeutralColors.gray120,
      fontWeight: FontWeights.regular,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  });

  const layoutClass = mergeStyles({
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    margin: 0,
    padding: 0,
    maxWidth: APP_MAX_WIDTH,
    alignContent: 'stretch',
    justifyContent: 'stretch',
    transition: '.3s ease-in-out all',
    containerType: 'inline-size',
  });

  const containerClass = mergeStyles({
    width: '100%',
    height: '100%',
    flexShrink: 0,
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    alignContent: 'stretch',
    justifyContent: 'stretch',
    transition: '.3s ease-in-out all',
    marginTop: '0',
  });

  const sidebarClass = mergeStyles({
    display: 'flex',
    padding: 0,
    minWidth: layeredPanel ? undefined : panelVisible ? '350px' : 0,
    width:
      layeredPanel && panelVisible
        ? '100%'
        : !panelVisible
          ? `calc(${SIDE_PANEL_MENU_WIDTH})`
          : '50%',
    maxWidth:
      layeredPanel && panelVisible
        ? '100%'
        : !panelVisible
          ? `calc(${SIDE_PANEL_MENU_WIDTH})`
          : '50%',
    overflowY: 'hidden',
    alignContent: 'stretch',
    justifyContent: 'stretch',
    transition: '.3s ease-in-out all',

    borderLeft: !panelVisible
      ? isDark
        ? `1px solid ${NeutralColors.gray170}`
        : `1px solid ${MEETINGFLOW_COLORS.purpleGreyer}`
      : undefined,
  });

  const sidebarWrapperClass = mergeStyles({
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    minWidth: panelVisible ? '200px' : SIDE_PANEL_MENU_WIDTH,
    width: panelVisible ? '100%' : SIDE_PANEL_MENU_WIDTH,
    position: layeredPanel ? 'absolute' : undefined,
    top: 0,
    right: 0,
  });

  const sidebarMenuClass = mergeStyles({
    paddingTop: layeredPanel ? '24px' : '0',
    overflowY: 'auto',
    backgroundColor: isDark
      ? NeutralColors.gray210
      : MEETINGFLOW_COLORS.lightModeMeetingflowSidebarMenuBackgroundGrey,
    borderRight: isDark
      ? `1px solid ${NeutralColors.gray190}`
      : `1px solid ${MEETINGFLOW_COLORS.purpleGreyer}`,
    borderLeft: isDark
      ? `1px solid ${NeutralColors.gray170}`
      : `1px solid ${MEETINGFLOW_COLORS.purpleGreyer}`,

    display: 'block',
    position: 'absolute',
    transition: '.3s ease-in-out all',
    right: 0,
    top: 0,
    width: SIDE_PANEL_MENU_WIDTH,
    height: layeredPanel ? `calc(100% - 1.6rem)` : `100%`,
    zIndex: 15,

    button: {
      backgroundColor: 'transparent !important',
      margin: '0 auto',
      height: SIDE_PANEL_MENU_ICON_WIDTH,
      width: SIDE_PANEL_MENU_ICON_WIDTH,
      lineHeight: SIDE_PANEL_MENU_WIDTH,
      '.ms-Icon': {
        display: 'grid',
        alignItems: 'center',
        margin: 0,
        height: SIDE_PANEL_MENU_ICON_WIDTH,
        width: SIDE_PANEL_MENU_ICON_WIDTH,
        lineHeight: SIDE_PANEL_MENU_ICON_WIDTH,
        fontSize: FontSizes.size24,
        textAlign: 'center',
        color: `${isDark
          ? MEETINGFLOW_COLORS.purpleDark
          : MEETINGFLOW_COLORS.purpleMedium
          } !important`,
        ':hover': {
          color: isDark ? 'white !important' : undefined,
        },
      },
      i: {
        margin: 0,
        height: SIDE_PANEL_MENU_ICON_WIDTH,
        width: SIDE_PANEL_MENU_ICON_WIDTH,
        lineHeight: SIDE_PANEL_MENU_ICON_WIDTH,
        fontSize: FontSizes.size24,
        textAlign: 'center',
      },
    },

    '.sidebar-menu-group': {
      padding: '.5rem 0 0 0',
      margin: '0',
      width: SIDE_PANEL_MENU_WIDTH,
      borderBottom: `1px solid ${isDark ? NeutralColors.gray190 : MEETINGFLOW_COLORS.purpleGrey
        }`,
    },

    '.sidebar-menu-item[data-active="true"]': {
      borderLeft: panelVisible
        ? `3px solid ${MEETINGFLOW_COLORS.purpleTertiary} !important`
        : undefined,

      ':hover': {
        borderLeft: panelVisible
          ? `3px solid ${isDark
            ? MEETINGFLOW_COLORS.purpleTertiary
            : MEETINGFLOW_COLORS.purpleTertiary
          } !important`
          : undefined,
      },

      'button i, .sidebar-menu-label': {
        color: isDark
          ? 'white !important'
          : `${MEETINGFLOW_COLORS.purpleDarker} !important`,
      },
    },

    '.sidebar-menu-item': {
      position: 'relative',
      height: SIDE_PANEL_MENU_ICON_HEIGHT,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      textAlign: 'center',
      width: '100%',
      transition: '.3s ease-in-out all',
      padding: '0',
      marginBottom: '.5rem',
      boxSizing: 'border-box',
      borderRight: `3px solid transparent !important`,
      borderLeft: `3px solid transparent !important`,
      ':hover': {
        borderLeft: `3px solid ${isDark ? NeutralColors.gray100 : MEETINGFLOW_COLORS.purpleMedium
          } !important`,
        '.sidebar-menu-label, button i': {
          color: isDark
            ? NeutralColors.gray170
            : `${MEETINGFLOW_COLORS.purpleDarker} !important`,
        },
      },

      '.sidebar-menu-label': {
        display: layeredPanel ? 'none' : 'block',
        transition: '.3s ease-in-out all',
        fontSize: FontSizes.xSmall,
        fontWeight: FontWeights.semibold,
        textTransform: 'uppercase',
        position: 'relative',
        top: '-.25rem',
        textAlign: 'center',
        width: '100%',
        color: isDark
          ? `${NeutralColors.gray80} !important`
          : `${MEETINGFLOW_COLORS.purpleMedium} !important`,
      },

      'button i': {
        position: 'relative',
        top: layeredPanel ? '.25rem' : '-.25rem',
        transition: '.1s ease-in-out all',
        color: isDark
          ? `${NeutralColors.gray80} !important`
          : `${MEETINGFLOW_COLORS.purpleMedium} !important`,
      },
    },
  });

  const sidebarContentClass = mergeStyles({
    height: '100%',
    width: '100%',
    paddingRight: SIDE_PANEL_MENU_WIDTH,
    overflowY: 'auto',
    display: panelVisible ? 'flex' : 'none',
    backgroundColor: isDark
      ? NeutralColors.gray210
      : MEETINGFLOW_COLORS.purpleUltraSuperLightish,
  });

  const resizerClass = mergeStyles({
    flexBasis: '8px',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    zIndex: '2',
    cursor: 'col-resize',
    background: isDark
      ? NeutralColors.gray210
      : MEETINGFLOW_COLORS.purpleUltraSuperLightish,
    margin: '0',
    padding: ' 0',
    boxSizing: 'border-box',
    display: breakpoints.md && panelVisible ? 'flex' : 'none',
    transition: '.3s ease-in-out all',
    borderLeft: isDark
      ? `1px solid ${NeutralColors.gray170}`
      : `1px solid ${MEETINGFLOW_COLORS.purpleGrey}`,
  });

  const mainContentClass = mergeStyles({
    flexBasis: !panelVisible ? '100%' : '50%',
    minWidth: '50%',
    height: '100%',
    flexDirection: 'column',
    position: 'relative',
    display: 'flex',
    margin: '0',
    padding: breakpoints.md ? '0 1rem 0 0' : '0 .5rem',
    width: 'auto',
    transition: '.3s ease-in-out all',
    boxSizing: 'border-box',
    boxShadow: '-2px 0px 5px 1px inset rgba(0,0,0,.0125)',
  });

  const meetingflowContentClass = mergeStyles({
    padding: '0',
    marginTop: '0px',
    marginLeft: breakpoints?.md ? '1rem' : undefined,
    position: 'relative',
    flexBasis: '100%',
    minHeight: '2rem',

    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,
    boxSizing: 'border-box',
    overflowY: 'hidden',
    border: `1px solid ${isDark ? NeutralColors.gray190 : `rgba(0, 0, 0, 0.075)`
      }`,
    borderRadius: '.25rem',
    containerType: 'inline-size',

    '.attendees-section': {
      containerType: 'inline-size',
    },

    '.meeting-plan-section-header': {
      display: 'block',
      marginBottom: '1.5rem',
      fontSize: FontSizes.medium,
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.white : NeutralColors.gray180,
      lineHeight: '1.25rem',

      span: {
        fontWeight: FontWeights.bold,
        fontSize: FontSizes.medium,
        textTransform: 'lowercase',
        backgroundColor: isDark ? NeutralColors.gray150 : NeutralColors.gray70,
        borderRadius: '.75rem',
        color: MEETINGFLOW_COLORS.white,
        width: '1.25rem',
        height: '1.25rem',
        lineHeight: '1.1rem',
        display: 'inline-block',
        textAlign: 'center',
      },
    },

    '.markdown-content': {
      whiteSpace: 'normal',
      margin: 0,
      padding: 0,
      lineHeight: '1.25rem',

      h2: {
        fontFamily: 'Helvetica',
        fontSize: FontSizes.xLarge,
        margin: '0 0 .5rem 0',
        lineHeight: '1rem',
        fontWeight: FontWeights.semibold,
        color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
      },

      h3: {
        fontSize: FontSizes.large,
        margin: '0 0 .5rem 0',
        lineHeight: '1rem',
        fontWeight: FontWeights.semibold,
        color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
      },

      h4: {
        fontSize: FontSizes.mediumPlus,
        margin: '0 0 .5rem 0',
        lineHeight: '1rem',
        fontWeight: FontWeights.semibold,
        color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
      },

      h5: {
        fontSize: FontSizes.medium,
        margin: '0 0 0 .65rem',
        lineHeight: '1rem',
        fontWeight: FontWeights.semibold,
        color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
      },

      h6: {
        fontSize: FontSizes.small,
        margin: '0 0 0 .65rem',
        lineHeight: '1rem',
        fontWeight: FontWeights.semibold,
        color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
      },

      strong: {
        fontWeight: FontWeights.semibold,
        color: isDark ? 'white' : NeutralColors.black,
      },

      blockquote: {
        backgroundColor: isDark ? NeutralColors.gray200 : NeutralColors.gray30,
        marginLeft: '1rem',
        marginRight: '1rem',
        marginTop: 0,
        marginBottom: '.5rem',
        padding: '.5rem',
        position: 'relative',
        borderRadius: '.5rem',
        fontFamily: 'Georgia, serif',
        fontStyle: 'italic',
        'p:only-child': {
          marginBottom: 0,
        },
      },

      'p, ol, ul': {
        fontSize: '13px',
        lineHeight: '1.2rem',
        margin: '0 0 .5rem 0',
        color: isDark ? NeutralColors.gray30 : NeutralColors.gray200,

        span: {
          height: '1.2rem',
        },
      },

      'ol, ul': {
        marginLeft: '0',
        padding: `0 0 0 1rem`,
      },

      'ol ol, ul ul': {
        marginLeft: 0,
        padding: `0 0 0 1rem`,
        marginBottom: 0,
      },

      li: {
        marginLeft: 0,
      },

      img: {
        margin: '0',
      },

      '> div': {
        margin: '0 0 .5rem 0 !important',
      },

      br: {
        margin: '0 0 .5rem 0 !important',
      },

      'span[data-slate-length="0"]': {
        height: `0 !important`,
      },

      a: {
        display: 'inline-block',
      },
    },
  });

  const showCRMBadge = !breakpoints.md || !panelVisible;

  const useFullWidthToolbar =
    !breakpoints?.md || (!breakpoints?.lg && panelVisible);

  const meetingflowToolbarClass = mergeStyles({
    position: useFullWidthToolbar ? 'static' : 'absolute',
    top: useFullWidthToolbar ? '0' : '.125rem',
    right: useFullWidthToolbar ? '0' : '52px',
    direction: useFullWidthToolbar ? 'ltr' : 'rtl',
    flexBasis: '24px',
    minHeight: '24px',
    maxHeight: '24px',
    margin: useFullWidthToolbar
      ? breakpoints?.md
        ? '.25rem 0 .5rem 1rem'
        : '0 0 .5rem 0'
      : '.25rem 0 0 0',
    display: 'flex',
    columnGap: '.25rem',
    overflowX: 'auto',
    zIndex: 500,
    button: {
      direction: 'ltr',
    },
    '.ms-Button': {
      minHeight: '24px',
      maxHeight: '24px',
    },
  });

  const toolbarButtonStyles = {
    root: {
      height: '24px',
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleDark
        : MEETINGFLOW_COLORS.purpleMedium,
      border: 'none !important',
      outline: 'none !important',
      transition: '.3s ease-in-out all',
      borderRadius: '1rem',
      padding: '0 .5rem !important',
      margin: '0 !important',
      maxWidth: useFullWidthToolbar ? '5rem' : undefined,
    },
    rootHovered: {
      color: isDark ? MEETINGFLOW_COLORS.white : undefined,
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleDarkest
        : MEETINGFLOW_COLORS.purpleGrey,
    },
    rootPressed: {
      color: isDark ? MEETINGFLOW_COLORS.white : undefined,
    },
    rootHasMenu: {
      color: isDark ? MEETINGFLOW_COLORS.white : undefined,
    },
    rootFocused: {
      color: isDark ? MEETINGFLOW_COLORS.white : undefined,
    },
    label: {
      fontSize: useFullWidthToolbar ? FontSizes.mini : FontSizes.small,
      maxHeight: '2rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: 0,
      position: 'relative',
      top: '-1px',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      maxWidth: '100%',
    },
    icon: {
      position: 'relative',
      height: '1rem',
      width: '1rem',
    },
  } as Partial<IButtonStyles>;

  const iconToolbarButtonStyles = {
    ...toolbarButtonStyles,
    root: {
      // @ts-ignore
      ...toolbarButtonStyles.root,
      paddingLeft: 0,
    },
  };

  const iconOnlyToolbarButtonStyles = {
    ...toolbarButtonStyles,
    root: {
      // @ts-ignore
      ...toolbarButtonStyles.root,
      padding: 0,
      width: '32px',
      minWidth: '32px',
    },
  };

  const joinConferenceToolbarButtonStyles = {
    ...iconOnlyToolbarButtonStyles,
    root: {
      // @ts-ignore
      ...iconOnlyToolbarButtonStyles.root,
      backgroundColor: MEETINGFLOW_COLORS.teal,
    },
  };

  const addActionItemToolbarButtonStyles = {
    ...iconOnlyToolbarButtonStyles,
    root: {
      // @ts-ignore
      ...iconOnlyToolbarButtonStyles.root,
      backgroundColor: MEETINGFLOW_COLORS.orange,
      minWidth: '2.5rem',
      minHeight: '24px',

      span: {
        display: 'inline-block',
        width: '100%',
      },

      i: {
        position: 'relative',
        top: '3px',
        left: '-2px',
        fontSize: FontSizes.xLarge,
      },
    },
  };

  const menuToolbarButtonStyles = {
    ...toolbarButtonStyles,
    root: {
      // @ts-ignore
      ...toolbarButtonStyles.root,
      paddingRight: '.5rem',
    },
  };

  const toolbarMenuStyles = {
    root: {
      backgroundColor: isDark
        ? NeutralColors.gray200
        : MEETINGFLOW_COLORS.purpleGreyLight,
    },
    container: {},
    subComponentStyles: {
      menuItem: {
        root: {
          height: 'auto',
          lineHeight: '1.25rem',
          paddingTop: '.25rem',
          paddingBottom: '.25rem',
          paddingLeft: '.25rem',
          paddingRight: '.25rem',
          backgroundColor: isDark
            ? NeutralColors.gray210
            : MEETINGFLOW_COLORS.purpleGreyLight,
          borderBottom: `1px solid ${isDark ? NeutralColors.gray190 : MEETINGFLOW_COLORS.purpleGrey
            }`,
          fontSize: FontSizes.small,
          transition: '.3s ease-in-out all',
          fontWeight: FontWeights.semibold,

          ':hover': {
            backgroundColor: isDark
              ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
              : MEETINGFLOW_COLORS.purpleGrey,
          },
        },
      },
    },
  };

  const meetingflowToolbarContextualMenuItemClass = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '.5rem',
    borderBottom: `1px solid ${isDark ? NeutralColors.gray170 : MEETINGFLOW_COLORS.purpleGrey
      }`,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',

    ':hover': {
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
        : MEETINGFLOW_COLORS.purpleGrey,
    },
  });

  const headerExtraIconsWidth = breakpoints?.md ? '35px' : '110px';
  const MEETINGFLOW_HEADER_HEIGHT =
    meetingPlan?.location &&
      meetingPlan?.location !== meetingPlan?.conferenceInfo?.joinUri
      ? '72px'
      : '56px';

  const headerClass = mergeStyles({
    padding: breakpoints?.md ? '0 .35rem 0 1rem' : 0,
    marginBottom: '0',
    boxSizing: 'border-box',
    top: 0,
    flexBasis: MEETINGFLOW_HEADER_HEIGHT,
    minHeight: MEETINGFLOW_HEADER_HEIGHT,
    maxHeight: MEETINGFLOW_HEADER_HEIGHT,
    display: 'flex',

    '.meetingflow-card-wrapper': {
      display: 'flex',
      flexBasis: '100%',
      width: `calc(100% - ${headerExtraIconsWidth})`,

      '.meetingflow-title': {
        maxWidth: !useFullWidthToolbar
          ? showCRMBadge
            ? 'calc(100% - 340px)'
            : 'calc(100% - 280px)'
          : undefined,
      },

      '> div': {
        width: '100%',
        padding: 0,
        height: MEETINGFLOW_HEADER_HEIGHT,
      },
    },

    '.extra-icons': {
      textAlign: 'right',
      flexBasis: headerExtraIconsWidth,
      flexShrink: 1,
      flexGrow: 1,
      minWidth: headerExtraIconsWidth,
      maxWidth: headerExtraIconsWidth,

      '#meetingflow-header-overflow-menu-button': {
        position: 'relative',
        top: '0 !important',
      },
    },

    '.edit-details-button': {
      marginTop: '.75rem',
      marginRight: '.5rem',
      height: '32px',
      width: '32px',
      backgroundColor: isDark
        ? NeutralColors.gray160
        : MEETINGFLOW_COLORS.purpleGreyMediumAlt,
      borderRadius: '50%',
      transition: '.3s ease-in-out all',

      i: {
        color: MEETINGFLOW_COLORS.white,
        transition: '.3s ease-in-out all',
      },

      ':hover': {
        backgroundColor: MEETINGFLOW_COLORS.teal,
      },
    },
  });

  const presenceFacepileContainerClass = mergeStyles({
    position: 'relative',
    with: '100%',
    top: '-.75rem',
    left: '.25rem',
    zIndex: 5000,
    backgroundColor: 'green',
    textAlign: 'RIGHT',
    marginLeft: '1rem',
    height: '0',

    '.ms-Facepile-itemContainer': {
      height: '24px',
      top: 0,
    },
  });

  const EDITOR_TAB_POSITION_1 = 'calc(1rem - 3px)';
  const EDITOR_TAB_POSITION_2 = 'calc(6.5rem - 3px)';
  const EDITOR_TAB_POSITION_3 = 'calc(12rem - 3px)';
  const FOOTER_BAR_HEIGHT = '48px';

  const meetingflowEditorPivotClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    height: `100%`,
    position: 'relative',
    '.meetingflow-editor-tabs': {
      backgroundColor: isDark
        ? NeutralColors.gray210
        : MEETINGFLOW_COLORS.purpleGrey,
      height: '100%',
      display: !SHOW_MAIN_CONTENT_TABS ? 'none' : 'flex',
      flexDirection: 'column',
      whiteSpace: 'nowrap',
      top: '0',
      right: '100%',
      textAlign: 'right',
      width: '1.5rem',

      '.meetingflow-editor-tabs-tab': {
        display: 'block',
        padding: '0 .25rem',
        backgroundColor: isDark
          ? NeutralColors.gray180
          : MEETINGFLOW_COLORS.purpleGreyMedium,
        transformOrigin: `100% 100%`,
        transform: `rotate(-90deg)`,
        position: 'absolute',
        top: '0',
        left: 'calc(-4.25rem - .5px)',
        width: '5.75rem',
        height: '1.25rem',
        textAlign: 'center',
        cursor: 'pointer',
        fontSize: FontSizes.small,
        fontWeight: FontWeights.semibold,
        borderTopLeftRadius: '.25rem',
        borderTopRightRadius: '.25rem',
        color: isDark ? NeutralColors.gray60 : NeutralColors.white,
        borderBottom: 'none !important',
        boxSizing: 'border-box',
        lineHeight: '1.25rem',
        transition: '.3s ease-in-out all',

        i: {
          marginRight: '.5rem',
          display: 'inline-block',
        },

        ':hover': {
          backgroundColor: isDark
            ? NeutralColors.gray140
            : MEETINGFLOW_COLORS.purpleMedium,
        },
      },

      '.tab-selected': {
        backgroundColor: `${isDark
          ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
          : MEETINGFLOW_COLORS.white
          } !important`,
        color: isDark ? NeutralColors.white : MEETINGFLOW_COLORS.black,
        border: `1px solid transparent`,
        zIndex: 5,
      },

      '.meetingflow-editor-tabs-tab-agenda': {
        top: EDITOR_TAB_POSITION_1,
      },

      '.meetingflow-editor-tabs-tab-notes': {
        top: SHOW_AGENDA_EDITOR ? EDITOR_TAB_POSITION_2 : EDITOR_TAB_POSITION_1,
      },

      '.meetingflow-editor-tabs-tab-summary': {
        top: SHOW_AGENDA_EDITOR ? EDITOR_TAB_POSITION_3 : EDITOR_TAB_POSITION_2,
      },

      '.tab-selected.meetingflow-editor-tabs-tab-agenda': {
        i: {
          color: MEETINGFLOW_COLORS.orange,
        },
      },

      '.tab-selected.meetingflow-editor-tabs-tab-notes': {
        i: {
          color: MEETINGFLOW_COLORS.magenta,
        },
      },

      '.tab-selected.meetingflow-editor-tabs-tab-summary': {
        i: {
          color: MEETINGFLOW_COLORS.teal,
        },
      },
    },
    '.meetingflow-editor-tabs-panel': {
      width: '100%',
      flexBasis: '100%',
      border: `1px solid ${isDark
        ? NeutralColors.gray200
        : MEETINGFLOW_COLORS.purpleGreyMediumLight
        }`,
      borderLeft: 'none',
      borderBottomRightRadius: '.25rem',
    },
    '.meetingflow-editor-tabs-panel-agenda': {
      display: selectedNotesTab === 'notes-agenda' ? 'block' : 'none',
    },
    '.meetingflow-editor-tabs-panel-notes': {
      display: selectedNotesTab === 'notes-notes' ? 'block' : 'none',
    },
    '.meetingflow-editor-tabs-panel-summary': {
      display: selectedNotesTab === 'notes-summary' ? 'block' : 'none',
    },
  });

  const bottomToolbarClass = mergeStyles({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.25rem',
    flexBasis: FOOTER_BAR_HEIGHT,
    height: FOOTER_BAR_HEIGHT,
    zIndex: 500,
    width: '100%',
    boxSizing: 'border-box',
    padding: SHOW_MAIN_CONTENT_TABS
      ? '.75rem 0 .75rem 2.5rem'
      : '.75rem 0 .75rem 1rem',

    div: {
      display: 'flex',
      columnGap: '.25rem',
    },

    '.resources': {
      flexBasis: '100%',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',

      'span.ms-layer': {
        display: 'none',
      },
    },
  });

  const meetingPlanTemplateMenuItems = useMemo(
    () =>
      meetingPlanTemplates?.data?.length
        ? meetingPlanTemplates?.data?.map((t) => ({
          key: t.id.toString(),
          text: (
            <span className={templateSuggestionClass}>
              <span className="title">
                <span className="text">{t.name}</span>
                <FontIcon
                  className="star"
                  iconName={
                    t.isUserDefault ? 'FavoriteStarFill' : 'FavoriteStar'
                  }
                  style={{
                    color: t.isUserDefault
                      ? MEETINGFLOW_COLORS.orange
                      : undefined,
                  }}
                  title={
                    t.isUserDefault
                      ? 'Unset as your default template'
                      : 'Set as your default template'
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (t.isUserDefault) {
                      unsetDefaultTemplate({ defaultType: 'user' });
                    } else {
                      setDefaultTemplate({ id: t.id, defaultType: 'user' });
                    }
                  }}
                />
              </span>
              <span className="description">{t.description}</span>
            </span>
          ),
          onClick: (
            e: React.MouseEvent,
            option: { key: string; text: string; onClick: React.MouseEvent },
          ) => {
            applyTemplate(
              meetingPlanTemplates!.data!.find(
                (t) => t.id.toString() === option?.key,
              )!,
            );
          },
        }))
        : ([] as ICommandBarItemProps[]),
    [
      applyTemplate,
      meetingPlanTemplates,
      setDefaultTemplate,
      templateSuggestionClass,
      unsetDefaultTemplate,
    ],
  );

  const shareMenuIconClass = mergeStyles({
    fontSize: FontSizes.large,
    color: isDark
      ? MEETINGFLOW_COLORS.purpleMedium
      : MEETINGFLOW_COLORS.purpleSecondary,
    height: '1.5rem',
    width: '1.5rem',
    marginLeft: '.5rem',
    marginRight: '.5rem',
    position: 'relative',
    top: '.5rem',
  });

  const shareMenuImageIconClass = mergeStyles({
    height: '1.5rem',
    width: '1.5rem',
    marginLeft: '.5rem',
    marginRight: '.5rem',
    position: 'relative',
    top: '.5rem',
    color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
  });

  const onRenderShareMenuItem: (
    item: IContextualMenuItem,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dismissMenu: (ev?: any, dismissAll?: boolean | undefined) => void,
  ) => React.ReactNode = useCallback(
    (item, dismissMenu) => {
      if (item.hideIf) return null;

      return (
        <div
          className={meetingflowToolbarContextualMenuItemClass}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            item?.onClick && item.onClick(e, item);
            dismissMenu();
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          {item?.iconProps?.iconName ? (
            <FontIcon
              iconName={item?.iconProps?.iconName}
              className={shareMenuIconClass}
            />
          ) : null}
          {item?.iconProps?.imageProps?.src ? (
            <img
              src={item?.iconProps?.imageProps?.src}
              style={item?.iconProps?.imageProps?.style}
              className={shareMenuImageIconClass}
            />
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Text style={{ fontWeight: FontWeights.semibold }}>
              {item?.text}
            </Text>
            <Text
              variant="small"
              style={{
                color: NeutralColors.gray120,
                paddingRight: '1rem',
              }}
            >
              {item?.secondaryText}
            </Text>
          </div>
        </div>
      );
    },
    [
      meetingflowToolbarContextualMenuItemClass,
      shareMenuIconClass,
      shareMenuImageIconClass,
    ],
  );

  const shareMenuItems = useMemo(() => {
    return [
      {
        key: 'InviteShare',
        text: 'Invite collaborators',
        iconProps: {
          iconName: 'PeopleAdd',
        },
        secondaryText:
          'Invite others to join your Workspace and collaborate on this meeting',
        onRender: onRenderShareMenuItem,
        onClick: () => {
          appInsights.trackEvent({
            name: 'MEETINGFLOW_TOOLBAR_MENU_CLICK_INVITE',
            properties: {
              meetingPlanId: meetingPlanId,
              userId: user?.id,
              surface: 'ToolbarShareButton',
            },
          });
          setPanelVisible();
          setPanelContext({
            type: 'invite-and-notify',
          });
          return true; // closes menu
        },
      },
      {
        key: 'share-call-recording',
        text: 'Share recording and transcript',
        iconProps: {
          iconName: 'Video',
        },
        secondaryText:
          'Share the video of this meeting with people outside your Workspace',
        onRender: onRenderShareMenuItem,
        onClick: () => {
          appInsights.trackEvent({
            name: 'MEETINGFLOW_TOOLBAR_MENU_CLICK_SHARE_CALL_RECORDING',
            properties: {
              meetingPlanId: meetingPlanId,
              userId: user?.id,
              surface: 'ToolbarShareButton',
            },
          });
          shareCallRecording();
          return true; // closes menu
        },
      },
      {
        key: 'summarize-and-share',
        text: 'Share summary',
        iconProps: {
          iconName: 'TextDocumentShared',
        },
        secondaryText:
          'Summarize this meeting, and optional share the summary to Slack',
        onRender: onRenderShareMenuItem,
        onClick: () => {
          appInsights.trackEvent({
            name: 'MEETINGFLOW_TOOLBAR_MENU_CLICK_SUMMARIZE',
            properties: {
              meetingPlanId: meetingPlanId,
              userId: user?.id,
              surface: 'ToolbarShareButton',
            },
          });
          setPanelVisible();
          setPanelContext({
            type: 'internal-summary',
          });
          return true; // closes menu
        },
      },
      {
        key: 'log-to-hubspot',
        text: 'Log meeting to HubSpot',
        iconProps: {
          imageProps: {
            src: hubspotIcon,
            style: {
              marginLeft: '.25rem',
              marginRight: '.75rem',
              width: '1.5rem',
            },
          },
        },
        secondaryText: `Log this meeting to HubSpot`,
        hideIf: !hasHubspotToken(),
        onRender: onRenderShareMenuItem,
        onClick: () => {
          appInsights.trackEvent({
            name: 'MEETINGFLOW_TOOLBAR_MENU_CLICK_LOG_TO_CRM',
            properties: {
              meetingPlanId: meetingPlanId,
              userId: user?.id,
              surface: 'ToolbarShareButton',
              CRM: 'Hubspot',
            },
          });
          setPanelVisible();
          setPanelContext({
            type: 'hubspot-log-meeting',
          });
          return true; // closes menu
        },
      },
      {
        key: 'log-to-salesforce',
        text: 'Log meeting to Salesforce',
        secondaryText: `Log this meeting to Salesforce`,
        iconProps: {
          imageProps: {
            src: salesforceIcon,
            style: {
              marginLeft: 0,
              width: '2rem',
            },
          },
        },
        hideIf: !hasSalesforceToken(),
        onRender: onRenderShareMenuItem,
        onClick: () => {
          appInsights.trackEvent({
            name: 'MEETINGFLOW_TOOLBAR_MENU_CLICK_LOG_TO_CRM',
            properties: {
              meetingPlanId: meetingPlanId,
              userId: user?.id,
              surface: 'ToolbarShareButton',
              CRM: 'Salesforce',
            },
          });
          setPanelVisible();
          setPanelContext({
            type: 'salesforce-browser',
          });
          return true; // closes menu
        },
      },
      navigator.clipboard &&
        'writeText' in navigator.clipboard &&
        typeof navigator.clipboard.writeText === 'function'
        ? {
          key: 'copy-url',
          text: 'Copy Meetingflow URL',
          iconProps: {
            iconName: 'PageLink',
          },
          secondaryText: 'Copy the URL of this Meetingflow to your clipboard',
          onRender: onRenderShareMenuItem,
          onClick: () => {
            appInsights.trackEvent({
              name: 'MEETINGFLOW_TOOLBAR_MENU_CLICK_COPY_URL',
              properties: {
                meetingPlanId: meetingPlanId,
                userId: user?.id,
                surface: 'ToolbarShareButton',
              },
            });
            navigator.clipboard
              .writeText(
                `${window.location.origin}/organization/${organizationSlug}/plan/${meetingPlanId}`,
              )
              .then(() =>
                toast.success(`Copied Meetingflow URL to the clipboard`),
              );
            return true; // closes menu
          },
        }
        : undefined,
    ].filter(Truthy) as IContextualMenuItem[];
  }, [
    appInsights,
    meetingPlanId,
    organizationSlug,
    shareCallRecording,
    user,
    setPanelVisible,
    setPanelContext,
    onRenderShareMenuItem,
    hasHubspotToken,
    hasSalesforceToken,
  ]);

  if (
    !organizationSlug ||
    !meetingPlanId ||
    !objectivesEditor ||
    !notesEditor
  ) {
    return <Spinner />;
  }

  const sidebarContent = (
    <div className={sidebarWrapperClass}>
      <div id="meetingflow-sidebar-content" className={sidebarContentClass}>
        {sidePanelContent}
      </div>
      <div id="meetingflow-sidebar-menu" className={sidebarMenuClass}>
        <div className="sidebar-menu-group">
          <div
            id="meetingflow-sidebar-menu-insights"
            className="sidebar-menu-item"
            data-active={panelVisible && panelContext?.type === 'insights'}
            onClick={() => {
              appInsights.trackEvent({
                name: 'MEETINGFLOW_SIDEBAR_CLICK_INSIGHTS',
                properties: {
                  meetingPlanId: meetingPlanId,
                  userId: user?.id,
                },
              });
              setPanelContext({
                type: 'insights',
              });
            }}
          >
            <IconButton
              iconProps={{
                iconName: 'CRMCustomerInsightsApp',
                style: {
                  color:
                    panelContext?.type === 'insights'
                      ? theme.palette.themePrimary
                      : NeutralColors.gray90,
                },
              }}
            />
            <Text className="sidebar-menu-label">Overview</Text>
          </div>
        </div>

        <div className="sidebar-menu-group">
          <div
            id="meetingflow-sidebar-menu-chatbot"
            className="sidebar-menu-item"
            data-active={panelVisible && panelContext?.type === 'chatbot'}
            onClick={() => {
              appInsights.trackEvent({
                name: 'MEETINGFLOW_SIDEBAR_CLICK_CHATBOT',
                properties: {
                  meetingPlanId: meetingPlanId,
                  userId: user?.id,
                },
              });
              setPanelContext({
                type: 'chatbot',
              });
            }}
          >
            <IconButton
              iconProps={{
                iconName: 'AISparkle',
                style: {
                  fontSize: '42px',
                  color:
                    panelContext?.type === 'chatbot'
                      ? theme.palette.themePrimary
                      : NeutralColors.gray90,
                },
              }}
            />
            <Text className="sidebar-menu-label">AI Chat</Text>
          </div>
        </div>

        <div className="sidebar-menu-group">
          {/* <div
            id="meetingflow-sidebar-menu-contacts"
            className="sidebar-menu-item"
            data-active={panelVisible && panelContext?.type === 'contacts'}
            onClick={() => {
              appInsights.trackEvent({
                name: 'MEETINGFLOW_SIDEBAR_CLICK_ATTENDEES',
                properties: {
                  meetingPlanId: meetingPlanId,
                  userId: user?.id,
                },
              });
              setPanelContext({
                type: 'contacts',
              });
            }}
          >
            <IconButton
              iconProps={{
                iconName: 'Contact',
                style: {
                  color: ['contacts', 'contact'].includes(panelContext!.type!)
                    ? theme.palette.themePrimary
                    : NeutralColors.gray90,
                },
              }}
            />
            <Text className="sidebar-menu-label">Attendees</Text>
          </div> */}

          <div
            id="meetingflow-sidebar-menu-action-items"
            className="sidebar-menu-item"
            data-active={panelVisible && panelContext?.type === 'action-items'}
            onClick={() => {
              appInsights.trackEvent({
                name: 'MEETINGFLOW_SIDEBAR_CLICK_ACTION_ITEMS',
                properties: {
                  meetingPlanId: meetingPlanId,
                  userId: user?.id,
                },
              });
              setPanelContext({
                type: 'action-items',
                defaultEditItem: {
                  text: '',
                  assigneeIdOrEmail: email?.toLowerCase(),
                },
              });
            }}
          >
            <IconButton
              iconProps={{
                iconName: 'TaskLogo',
                style: {
                  color:
                    panelContext?.type === 'action-items'
                      ? theme.palette.themePrimary
                      : NeutralColors.gray90,
                },
              }}
            />
            <Text className="sidebar-menu-label">Action Items</Text>
          </div>

          <div
            id="meetingflow-sidebar-menu-timeline"
            className="sidebar-menu-item"
            data-active={panelVisible && panelContext?.type === 'timeline'}
            onClick={
              meetingPlan
                ? () => {
                  appInsights.trackEvent({
                    name: 'MEETINGFLOW_SIDEBAR_CLICK_TIMELINE',
                    properties: {
                      meetingPlanId: meetingPlanId,
                      userId: user?.id,
                    },
                  });
                  setPanelContext({ type: 'timeline' });
                }
                : undefined
            }
          >
            <IconButton
              iconProps={{
                iconName: 'TimelineDelivery',
                style: {
                  color:
                    panelContext?.type === 'timeline'
                      ? theme.palette.themePrimary
                      : NeutralColors.gray90,
                },
              }}
            />
            <Text className="sidebar-menu-label">Timeline</Text>
          </div>
        </div>

        <div className="sidebar-menu-group">
          <div
            id="meetingflow-sidebar-menu-meetingflows"
            className="sidebar-menu-item"
            data-active={panelVisible && panelContext?.type === 'plans'}
            onClick={() => {
              appInsights.trackEvent({
                name: 'MEETINGFLOW_SIDEBAR_CLICK_MEETINGFLOWS',
                properties: {
                  meetingPlanId: meetingPlanId,
                  userId: user?.id,
                },
              });
              setPanelContext({ type: 'plans' });
            }}
          >
            <IconButton
              iconProps={{
                iconName: 'DietPlanNotebook',
                style: {
                  color:
                    panelContext?.type === 'plans'
                      ? theme.palette.themePrimary
                      : NeutralColors.gray90,
                },
              }}
            />
            <Text className="sidebar-menu-label">Meetingflows</Text>
          </div>
        </div>
        {configurationsWithToken?.length ? (
          <div className="sidebar-menu-group">
            {hasSalesforceToken() ? (
              <div
                id="meetingflow-sidebar-menu-salesforce"
                className="sidebar-menu-item"
                data-active={
                  panelVisible &&
                  (panelContext?.type === 'salesforce-browser' ||
                    panelContext?.type === 'salesforce-object') &&
                  panelVisible
                }
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'MEETINGFLOW_SIDEBAR_CLICK_SALESFORCE',
                    properties: {
                      meetingPlanId: meetingPlanId,
                      userId: user?.id,
                    },
                  });
                  if (panelVisible) {
                    pushPanelContext(lastSalesforcePanelContext);
                  } else {
                    setPanelContext(lastSalesforcePanelContext);
                  }
                }}
              >
                <IconButton
                  style={{
                    margin: 0,
                    height: SIDE_PANEL_MENU_ICON_WIDTH,
                    width: SIDE_PANEL_MENU_ICON_WIDTH,
                    lineHeight: SIDE_PANEL_MENU_ICON_WIDTH,
                    fontSize: FontSizes.size24,
                    textAlign: 'center',
                  }}
                  iconProps={{
                    imageProps: {
                      src: salesforceIcon,
                      width: '90%',
                      styles: {
                        root: {
                          display: 'inline-block',
                          margin: '0 auto',
                        },
                      },
                    },
                  }}
                />
                <Text className="sidebar-menu-label">Salesforce</Text>
              </div>
            ) : null}
            {hasHubspotToken() ? (
              <div
                id="meetingflow-sidebar-menu-hubspot"
                className="sidebar-menu-item"
                data-active={
                  (panelVisible && panelContext?.type === 'hubspot-object') ||
                  panelContext?.type === 'hubspot-browser' ||
                  panelContext?.type === 'hubspot-log-meeting'
                }
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'MEETINGFLOW_SIDEBAR_CLICK_HUBSPOT',
                    properties: {
                      meetingPlanId: meetingPlanId,
                      userId: user?.id,
                    },
                  });
                  setPanelContext(lastHubSpotPanelContext);
                }}
              >
                <IconButton
                  style={{
                    margin: 0,
                    height: SIDE_PANEL_MENU_ICON_WIDTH,
                    width: SIDE_PANEL_MENU_ICON_WIDTH,
                    lineHeight: SIDE_PANEL_MENU_ICON_WIDTH,
                    fontSize: FontSizes.size24,
                    textAlign: 'center',
                  }}
                  iconProps={{
                    imageProps: {
                      src: hubspotIcon,
                      width: '100%',
                      styles: {
                        root: {
                          display: 'inline-block',
                          margin: '0 auto',
                        },
                      },
                    },
                  }}
                />
                <Text className="sidebar-menu-label">HubSpot</Text>
              </div>
            ) : null}
            {hasToken('SLACK') ? (
              <div
                id="meetingflow-sidebar-menu-slack"
                className="sidebar-menu-item"
                data-active={panelVisible && panelContext?.type === 'slack'}
              >
                <IconButton
                  style={{
                    margin: 0,
                    height: SIDE_PANEL_MENU_ICON_WIDTH,
                    width: SIDE_PANEL_MENU_ICON_WIDTH,
                    lineHeight: SIDE_PANEL_MENU_ICON_WIDTH,
                    fontSize: FontSizes.size24,
                    textAlign: 'center',
                  }}
                  iconProps={{
                    imageProps: {
                      src: slackIcon,
                      width: '90%',
                      styles: {
                        root: {
                          display: 'inline-block',
                          margin: '0 auto',
                        },
                      },
                    },
                  }}
                  onClick={() => {
                    appInsights.trackEvent({
                      name: 'MEETINGFLOW_SIDEBAR_CLICK_SLACK',
                      properties: {
                        meetingPlanId: meetingPlanId,
                        userId: user?.id,
                      },
                    });
                    setPanelContext({
                      type: 'slack',
                    });
                    setPanelVisible();
                  }}
                />
                <Text className="sidebar-menu-label">Slack</Text>
              </div>
            ) : null}
            {hasToken('TEAMS') ? (
              <div
                id="meetingflow-sidebar-menu-teams"
                className="sidebar-menu-item"
                data-active={panelVisible && panelContext?.type === 'ms-teams'}
              >
                <IconButton
                  style={{
                    margin: 0,
                    height: SIDE_PANEL_MENU_ICON_WIDTH,
                    width: SIDE_PANEL_MENU_ICON_WIDTH,
                    lineHeight: SIDE_PANEL_MENU_ICON_WIDTH,
                    fontSize: FontSizes.size24,
                    textAlign: 'center',
                  }}
                  iconProps={{
                    imageProps: {
                      src: teamsIcon,
                      width: '90%',
                      styles: {
                        root: {
                          display: 'inline-block',
                          margin: '0 auto',
                        },
                      },
                    },
                  }}
                  onClick={() => {
                    appInsights.trackEvent({
                      name: 'MEETINGFLOW_SIDEBAR_CLICK_TEAMS',
                      properties: {
                        meetingPlanId: meetingPlanId,
                        userId: user?.id,
                      },
                    });
                    setPanelContext({
                      type: 'ms-teams',
                    });
                    setPanelVisible();
                  }}
                />
                <Text className="sidebar-menu-label">Microsoft Teams</Text>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="sidebar-menu-group">
          <div
            id="meetingflow-sidebar-menu-add-integration"
            className="sidebar-menu-item"
            onClick={() => {
              appInsights.trackEvent({
                name: 'MEETINGFLOW_SIDEBAR_CLICK_INTEGRATIONS',
                properties: {
                  meetingPlanId: meetingPlanId,
                  userId: user?.id,
                },
              });
              createAddIntegrationDeferred()
                .promise.then((result) => {
                  if (result === undefined) {
                    return;
                  }
                  refetchExternalServiceConfigurations();

                  switch (result.app) {
                    case 'SLACK': {
                      // If we added Slack, show the Slack panel
                      if (result.added) {
                        setPanelContext({ type: 'slack' });
                        setPanelVisible();

                        // if we removed slack and are showing a slack panel, hide it
                      } else if (panelContext?.type === 'slack') {
                        setPanelContext(
                          panelStack.filter(
                            (context) => context.type !== 'slack',
                          ),
                        );
                        setPanelVisible();
                      }
                      break;
                    }
                    case 'TEAMS': {
                      // If we added Teams, show the Teams panel
                      if (result.added) {
                        setPanelContext({ type: 'ms-teams' });
                        setPanelVisible();

                        // if we removed slack and are showing a slack panel, hide it
                      } else if (panelContext?.type === 'ms-teams') {
                        setPanelContext(
                          panelStack.filter(
                            (context) => context.type !== 'ms-teams',
                          ),
                        );
                        setPanelVisible();
                      }
                      break;
                    }

                    case 'SALESFORCE': {
                      refetchSalesforceConfigurations();
                      // If we added Salesforce, show the salesforce panel
                      if (result.added) {
                        if (panelVisible) {
                          pushPanelContext(lastSalesforcePanelContext);
                          setPanelVisible();
                        } else {
                          setPanelContext(lastSalesforcePanelContext);
                          setPanelVisible();
                        }
                        // if we removed Salesforce and are showing a Salesforce panel, hide it
                      } else {
                        setLastSalesforcePanelContext(
                          DEFAULT_SALESFORCE_BROWSER_CONTEXT,
                        );

                        const panels = panelStack.filter(
                          (context) =>
                            !(
                              [
                                'salesforce-browser',
                                'salesforce-object',
                                'salesforce-log-meeting',
                              ] as MeetingPlanPanelContext['type'][]
                            ).includes(context.type),
                        );
                        setPanelContext(
                          panels.length ? panels : { type: 'insights' },
                        );
                        setPanelVisible();
                      }
                      break;
                    }
                    case 'HUBSPOT': {
                      // If we added HubSpot, show the HubSpot panel
                      refetchHubSpotConfigurations();

                      if (result.added) {
                        if (panelVisible) {
                          pushPanelContext(lastHubSpotPanelContext);
                        } else {
                          setPanelContext(lastHubSpotPanelContext);
                          setPanelVisible();
                        }
                        // if we removed HubSpot and are showing a HubSpot panel, hide it
                      } else {
                        const panels = panelStack.filter(
                          (context) =>
                            !(
                              [
                                'hubspot-browser',
                                'hubspot-object',
                                'hubspot-log-meeting',
                              ] as MeetingPlanPanelContext['type'][]
                            ).includes(context.type),
                        );

                        setLastHubSpotPanelContext(
                          DEFAULT_HUBSPOT_BROWSER_CONTEXT,
                        );
                        setPanelContext(
                          panels.length ? panels : { type: 'insights' },
                        );
                        setPanelVisible();
                      }
                      break;
                    }
                    default: {
                      break;
                    }
                  }
                })
                .catch(() => toast.error('Failed add integration'));
            }}
          >
            <IconButton
              iconProps={{
                iconName: 'AddIn',
                style: {
                  color: NeutralColors.gray90,
                },
              }}
            />
            <Text className="sidebar-menu-label">Integrations</Text>
          </div>
        </div>
      </div>
    </div>
  );

  const sidebarAsColumns = (
    <>
      <div ref={resizerColumn} className={resizerClass}>
        <svg
          width="8px"
          height="16px"
          viewBox="0 0 8 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ position: 'relative', left: '-1px', top: '-4rem' }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 4.625C6.12132 4.625 6.625 4.12132 6.625 3.5C6.625 2.87868 6.12132 2.375 5.5 2.375C4.87868 2.375 4.375 2.87868 4.375 3.5C4.375 4.12132 4.87868 4.625 5.5 4.625ZM9.5 4.625C10.1213 4.625 10.625 4.12132 10.625 3.5C10.625 2.87868 10.1213 2.375 9.5 2.375C8.87868 2.375 8.375 2.87868 8.375 3.5C8.375 4.12132 8.87868 4.625 9.5 4.625ZM10.625 7.5C10.625 8.12132 10.1213 8.625 9.5 8.625C8.87868 8.625 8.375 8.12132 8.375 7.5C8.375 6.87868 8.87868 6.375 9.5 6.375C10.1213 6.375 10.625 6.87868 10.625 7.5ZM5.5 8.625C6.12132 8.625 6.625 8.12132 6.625 7.5C6.625 6.87868 6.12132 6.375 5.5 6.375C4.87868 6.375 4.375 6.87868 4.375 7.5C4.375 8.12132 4.87868 8.625 5.5 8.625ZM10.625 11.5C10.625 12.1213 10.1213 12.625 9.5 12.625C8.87868 12.625 8.375 12.1213 8.375 11.5C8.375 10.8787 8.87868 10.375 9.5 10.375C10.1213 10.375 10.625 10.8787 10.625 11.5ZM5.5 12.625C6.12132 12.625 6.625 12.1213 6.625 11.5C6.625 10.8787 6.12132 10.375 5.5 10.375C4.87868 10.375 4.375 10.8787 4.375 11.5C4.375 12.1213 4.87868 12.625 5.5 12.625Z"
            fill={
              isDark ? NeutralColors.gray120 : MEETINGFLOW_COLORS.purpleMedium
            }
          />
        </svg>
      </div>

      <div ref={sidebarColumn} className={sidebarClass}>
        {sidebarContent}
      </div>
    </>
  );

  const sidebarAsLayer = (
    <Panel
      isLightDismiss
      type={PanelType.largeFixed}
      isOpen={panelVisible}
      onDismiss={() => {
        setPanelCollapsed();
      }}
      styles={{
        main: {
          width: '95% !important',
          height: '95% !important',
          top: '2.5%',
          boxShadow: '0 0 5px 0 rgba(0,0,0,.2)',
          borderTopLeftRadius: '.25rem',
          borderBottomLeftRadius: '.25rem',
        },
        content: { height: '0', padding: '0' },
        contentInner: {},
        scrollableContent: {
          overflowY: 'hidden', // Sidebar provides its own scrolling
          height: 0,
        },
        navigation: { display: 'none' },
        closeButton: {},
        header: {},
        overlay: {
          backgroundColor: isDark ? `rgba(0,0,0,.85)` : `rgba(255,255,255,.85)`,
        },
        commands: {
          display: 'none',
        },
      }}
      layerProps={{
        styles: {
          root: {},
          content: {
            display: 'flex',
            flex: '1 1 auto',
            boxSizing: 'border-box',
            padding: '0',
            backgroundColor: isDark
              ? `rgba(0,0,0,.85)`
              : `rgba(255,255,255,.65)`,
            '> div': {
              width: '100%',
              height: '100%',
            },
          },
        },
      }}
    >
      <div ref={sidebarColumn} className={sidebarClass}>
        {sidebarContent}
      </div>
    </Panel>
  );

  const sidebar = layeredPanel ? sidebarAsLayer : sidebarAsColumns;

  return (
    <div className={layoutClass} ref={layoutRef}>
      {confettiShown ? (
        <Confetti
          recycle={false}
          numberOfPieces={600}
          onConfettiComplete={hideConfetti}
        />
      ) : null}
      <div className={containerClass}>
        <div ref={mainColumn} className={mainContentClass}>
          <div
            id="meetingflow-header"
            role="heading"
            aria-level={1}
            className={headerClass}
          >
            {meetingPlan ? (
              <>
                <div className={'meetingflow-card-wrapper'}>
                  {!isAssociatedWithEvent ? (
                    <>
                      <IconButton
                        id="meetingplan-header-edit-details-button"
                        className={'edit-details-button'}
                        onClick={showEditDetailsModal}
                        iconProps={{
                          iconName: 'Edit',
                          styles: { root: { fontWeight: '900' } },
                        }}
                      />

                      {editDetailsModalOpen ? (
                        <Modal
                          titleAriaId={'editDetails'}
                          isOpen={editDetailsModalOpen}
                          onDismiss={() => {
                            hideEditDetailsModal();
                          }}
                          isBlocking={false}
                          styles={{
                            main: {
                              height: 'auto',
                              minWidth: breakpoints.md ? '512px' : '300px',
                              maxWidth: 'calc(768px + 2rem)',
                              borderRadius: '.25rem',
                              backgroundColor: isDark
                                ? NeutralColors.gray200
                                : MEETINGFLOW_COLORS.white,
                            },
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: isDark
                                ? NeutralColors.gray180
                                : MEETINGFLOW_COLORS.purpleGrey,
                              padding: '.25rem .75rem',
                              color: isDark
                                ? MEETINGFLOW_COLORS.purpleGrey
                                : MEETINGFLOW_COLORS.purpleDarkest,
                              borderBottom: `1px solid ${isDark
                                ? NeutralColors.gray160
                                : MEETINGFLOW_COLORS.purpleLight
                                }`,
                              fontWeight: FontWeights.semibold,
                              fontSize: FontSizes.small,
                              textTransform: 'uppercase',
                            }}
                          >
                            Edit Meetingflow Details
                          </div>
                          <div
                            style={{
                              padding: '.75rem',
                            }}
                          >
                            <MeetingPlanEditDetailsForm
                              key={meetingPlan.id}
                              meetingPlan={meetingPlan}
                              organizationSlug={organizationSlug}
                              notesYArray={notesYArray}
                              onCancel={hideEditDetailsModal}
                              fields={['date', 'title']}
                              onSuccess={() => {
                                hideEditDetailsModal();
                                sendWSMessage(
                                  provider?.ws,
                                  WS_MESSAGE.REFRESH_PLAN,
                                );
                                refetchMeetingPlan();
                              }}
                            />
                          </div>
                        </Modal>
                      ) : null}
                    </>
                  ) : null}
                  <MeetingflowCard
                    meetingflowId={meetingPlan.id}
                    organizationSlug={organizationSlug}
                    meetingflowObj={meetingPlan}
                    refetchMeetingflow={refetchMeetingPlan}
                    showEndTime
                    large
                    alwaysShowConferenceJoinButton={false}
                    onTextClick={
                      !isAssociatedWithEvent ? showEditDetailsModal : undefined
                    }
                    noTitleLink
                    hideShowConferenceJoinButton
                    hideCRMIcon={!showCRMBadge}
                    hideEventIcon
                    hideSummaryIcon
                    hideCallRecordingIcon
                    showLocation
                    inlineIcons={showCRMBadge}
                  />
                </div>

                <div className={'extra-icons'}>
                  <IconButton
                    id="meetingflow-header-side-panel-open-button"
                    iconProps={{
                      iconName: 'OpenPane',
                      style: {
                        color: isDark
                          ? NeutralColors.gray120
                          : MEETINGFLOW_COLORS.purpleSecondary,
                      },
                    }}
                    onClick={() => {
                      if (panelVisible) {
                        setPanelCollapsed();
                      } else {
                        setPanelVisible();
                      }
                    }}
                    styles={{
                      root: {
                        display: !layeredPanel ? 'none' : 'inline-block',
                        position: 'relative',
                        marginLeft:
                          (!breakpoints.lg &&
                            breakpoints.md &&
                            !panelVisible) ||
                            breakpoints.lg
                            ? undefined
                            : '10px',
                        top: !breakpoints?.md ? '.35rem' : undefined,
                        minWidth: 'auto',
                        transition: '.3s ease-in-out all',
                        backgroundColor: 'transparent',
                        border: 'none !important',
                        height: '32px',
                        width: '32px',

                        // padding:
                        //   (breakpoints.md && !panelVisible) || breakpoints.lg
                        //     ? '0 8px'
                        //     : '0 4px',

                        i: {
                          fontSize: FontSizes.size24,
                          color: isDark
                            ? 'white !important'
                            : MEETINGFLOW_COLORS.purpleSecondary,
                        },
                      },
                      rootHovered: {
                        backgroundColor: isDark
                          ? MEETINGFLOW_COLORS.purpleDark
                          : MEETINGFLOW_COLORS.purpleSecondary,
                      },
                      labelHovered: {
                        color: 'white !important',
                      },
                      label: {
                        color: isDark
                          ? 'white !important'
                          : MEETINGFLOW_COLORS.purpleSecondary,
                        margin:
                          (breakpoints.md && !panelVisible) || breakpoints.lg
                            ? undefined
                            : 0,
                      },
                    }}
                  />
                  <CommandButton
                    id="meetingflow-header-overflow-menu-button"
                    iconProps={{
                      iconName: 'More',
                      style: {
                        color: 'white !important',
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    menuProps={menuProps}
                    style={{
                      display: 'inline-block',
                      position: 'relative',
                      top: breakpoints?.md ? '.35rem' : '2px',
                      width: 'auto',
                      marginLeft: '.5rem',
                    }}
                    styles={{
                      root: {
                        border: 'none !important',
                        transition: '.3s ease-in-out all',
                        backgroundColor: 'transparent',
                        marginLeft: '.25rem',

                        'i.ms-Button-menuIcon': {
                          display: 'none',
                        },

                        i: {
                          color: isDark
                            ? 'white !important'
                            : MEETINGFLOW_COLORS.purpleSecondary,
                        },
                      },
                      rootExpanded: {
                        i: {
                          color: 'white !important',
                        },
                        backgroundColor: isDark
                          ? MEETINGFLOW_COLORS.purpleDark
                          : MEETINGFLOW_COLORS.purpleSecondary,
                      },
                      rootHovered: {
                        backgroundColor: 'transparent',
                      },
                      rootFocused: {
                        backgroundColor: 'transparent',
                      },
                      labelHovered: {
                        color: 'white !important',
                      },
                      label: {
                        color: 'white !important',
                      },
                    }}
                  />
                </div>
              </>
            ) : null}
          </div>

          <div className="extra-icons"></div>

          <div className={meetingflowToolbarClass}>
            {isNowOrSoon ? (
              <AsyncPrimaryButton
                size={16}
                onClick={async () => {
                  if (!meetingPlan?.conferenceInfo?.joinUri) {
                    return;
                  }

                  window.open(meetingPlan.conferenceInfo.joinUri, '_blank');
                  appInsights.trackEvent({
                    name: 'JOIN_CONFERENCE_CALL',
                    properties: {
                      organizationId: organization?.id,
                      type: meetingPlan?.conferenceInfo?.type,
                    },
                  });
                }}
                styles={joinConferenceToolbarButtonStyles}
                iconProps={{
                  iconName: 'Video',
                }}
              />
            ) : null}

            {hasEntitlement('CALL_RECORDING') &&
              canCreatePlans &&
              callRecordingButtonLabel &&
              callRecordingButtonLabel === 'Record' &&
              meetingPlan &&
              !meetingPlan.conferenceInfo &&
              (!meetingPlan.callRecording ||
                meetingPlan.callRecording.lastStatus === 'deleted') ? (
              <AsyncPrimaryButton
                size={16}
                onClick={async () => {
                  try {
                    const clipboardContent = await readClipboardContents();
                    const initialJoinUri = clipboardContent
                      ? tryParseConferenceInfo(clipboardContent) || undefined
                      : undefined;
                    const { conferenceInfo } =
                      await createGetConferenceJoinLinkDeferred({
                        joinURI: initialJoinUri?.joinUri,
                      }).promise;

                    if (!conferenceInfo) {
                      return;
                    }

                    if (!conferenceInfo.joinUri) {
                      return;
                    }

                    if (
                      ['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
                        conferenceInfo.type,
                      )
                    ) {
                      switch (conferenceInfo.type) {
                        case 'ciscoWebEx':
                          toast.error(`WebEx is not a supported platform`);
                          return;
                        case 'skypeForBusiness':
                          toast.error(
                            `Skype For Business is not a supported platform`,
                          );
                          return;
                        case 'skype':
                          toast.error(`Skype is not a supported platform`);
                          return;
                      }
                      return;
                    }

                    const headers = (token: string) => ({
                      Authorization: `Bearer ${token}`,
                    });
                    const validateStatus = (status: number) =>
                      [200, 201].includes(status);

                    const token = await getAccessTokenSilently();
                    await toast.promise(
                      MeetingflowsApiClient.startOrScheduleCallRecording(
                        organizationSlug,
                        meetingPlanId,
                        {
                          joinURI: conferenceInfo.joinUri,
                        },
                        {
                          headers: headers(token),
                          validateStatus,
                        },
                      ),
                      {
                        loading: `Starting Call Recording`,
                        error: (err) => {
                          console.error(err);
                          return `Something went wrong starting call recording`;
                        },
                        success: (result) => {
                          sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_PLAN);
                          sendWSMessage(
                            provider?.ws,
                            WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS,
                          );

                          client.removeQueries(
                            MeetingPlanCallRecorderStatusQuery(
                              organizationSlug,
                              meetingPlanId,
                            ),
                          );

                          refetchCallRecorderStatus();
                          refetchMeetingPlan();

                          appInsights.trackEvent({
                            name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                            properties: {
                              organizationSlug,
                              meetingPlanId,
                              change: 'started',
                            },
                          });

                          if (
                            meetingPlan &&
                            workspacePreferences?.orgPreferenceAutomaticCallRecording ===
                            'NONE' &&
                            ['PAID', 'VIP', 'INTERNAL'].includes(
                              organization?.type || '',
                            )
                          ) {
                            const isExternal = isExternalEventOrMeetingflow(
                              meetingPlan,
                              internalDomains,
                            );

                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                {result.status === 201
                                  ? `Successfully scheduled call recording`
                                  : `Successfully started call recording`}
                                <br />
                                <br />
                                <Link
                                  style={{ display: 'contents' }}
                                  as={'a'}
                                  onClick={() => {
                                    if (!updateWorkspacePreferencesAsync) {
                                      return;
                                    }

                                    toast.promise(
                                      updateWorkspacePreferencesAsync({
                                        orgPreferenceAutomaticCallRecording:
                                          isExternal ? 'EXTERNAL' : 'ALL',
                                      }),
                                      {
                                        loading:
                                          'Enabling automatic call recording',
                                        error:
                                          'Something went wrong enabling automatic call recording, please try again later',
                                        success: () => {
                                          appInsights.trackEvent({
                                            name: 'ENABLE_AUTOMATIC_CALL_RECORDING_FROM_TOAST',
                                            properties: {
                                              organizationSlug,
                                              type: isExternal
                                                ? 'EXTERNAL'
                                                : 'ALL',
                                            },
                                          });

                                          return (
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                              }}
                                            >
                                              Successfully enabled automatic
                                              call recording.
                                              <br />
                                              <br />
                                              You can change this setting at any
                                              time in your{' '}
                                              <Link
                                                style={{ display: 'contents' }}
                                                as={'a'}
                                                onClick={() => {
                                                  navigate(
                                                    `/organization/${organizationSlug}/settings?tab=user`,
                                                  );
                                                }}
                                              >
                                                workspace preferences
                                              </Link>
                                              .
                                            </div>
                                          );
                                        },
                                      },
                                    );
                                  }}
                                >
                                  Click here
                                </Link>{' '}
                                to automatically record all
                                {isExternal
                                  ? ' external '
                                  : ' internal and external '}
                                meetings on your calendar.
                              </div>
                            );
                          }

                          return result.status === 201
                            ? `Successfully scheduled call recording`
                            : `Successfully started call recording`;
                        },
                      },
                      {
                        success: {
                          duration:
                            workspacePreferences?.orgPreferenceAutomaticCallRecording ===
                              'NONE' &&
                              ['PAID', 'VIP', 'INTERNAL'].includes(
                                organization?.type || '',
                              )
                              ? 10000
                              : undefined,
                        },
                      },
                    );
                  } catch (err: unknown) { }
                }}
                styles={iconToolbarButtonStyles}
                style={{
                  backgroundColor: MEETINGFLOW_COLORS.magenta,
                }}
                iconProps={{
                  iconName: 'CircleFill',
                }}
              >
                {callRecordingButtonLabel || 'Record'}
              </AsyncPrimaryButton>
            ) : null}

            {hasEntitlement('CALL_RECORDING') &&
              canCreatePlans &&
              callRecordingButtonLabel &&
              (callRecordingButtonLabel !== 'Record' ||
                (meetingPlan?.conferenceInfo &&
                  !['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
                    meetingPlan.conferenceInfo.type,
                  ) &&
                  meetingPlan.conferenceInfo.joinUri)) ? (
              <PrimaryButton
                size={16}
                onClick={scheduleCallRecording}
                styles={iconToolbarButtonStyles}
                style={{
                  display:
                    callRecordingButtonLabel === 'Delete Recording'
                      ? 'none'
                      : undefined,
                  backgroundColor:
                    callRecordingButtonLabel === 'Cancel'
                      ? MEETINGFLOW_COLORS.teal
                      : MEETINGFLOW_COLORS.magenta,
                }}
                iconProps={{
                  iconName: 'CircleFill',
                }}
              >
                {callRecordingButtonLabel || 'Record'}
              </PrimaryButton>
            ) : null}

            <PrimaryButton
              size={16}
              styles={menuToolbarButtonStyles}
              iconProps={{
                iconName: 'Share',
              }}
              style={{
                backgroundColor: MEETINGFLOW_COLORS.orange,
              }}
              menuProps={{
                styles: toolbarMenuStyles,
                items: shareMenuItems,
              }}
            >
              Share
            </PrimaryButton>

            <AsyncPrimaryButton
              size={16}
              onClick={async () => { }}
              styles={menuToolbarButtonStyles}
              menuProps={{
                // @ts-ignore
                items: meetingPlanTemplateMenuItems,
                styles: toolbarMenuStyles,
              }}
            >
              Template
            </AsyncPrimaryButton>
            {/* {!isGuest ? (
              <PrimaryButton
                size={16}
                styles={toolbarButtonStyles}
                menuProps={{
                  styles: toolbarMenuStyles,
                  onItemClick: (e, item) => {
                    showCollaborateDialog({
                      type: item?.key as
                        | 'InviteShare'
                        | 'ShareAccess'
                        | 'EmailShare'
                        | 'PrepMeeting',
                    }).promise.then(() => {
                      return;
                    });
                  },
                  items: [
                    {
                      key: 'InviteShare',
                      text: 'Invite and Notify',
                    },
                    {
                      key: 'ShareAccess',
                      text: 'View Access',
                    },
                  ],
                }}
              >
                Collaborate
              </PrimaryButton>
            ) : null} */}
            <AsyncPrimaryButton
              size={16}
              onClick={async () => { }}
              styles={toolbarButtonStyles}
              menuProps={{
                styles: toolbarMenuStyles,
                onItemClick: (e, item) => {
                  if (item?.key === 'draft-email') {
                    appInsights.trackEvent({
                      name: 'MEETINGFLOW_TOOLBAR_MENU_CLICK_FOLLOWUP',
                      properties: {
                        meetingPlanId: meetingPlanId,
                        userId: user?.id,
                      },
                    });
                    setPanelVisible();
                    setPanelContext({
                      type: 'email-followup',
                    });
                  } else if (item?.key === 'summarize-and-share') {
                    appInsights.trackEvent({
                      name: 'MEETINGFLOW_TOOLBAR_MENU_CLICK_SUMMARIZE',
                      properties: {
                        meetingPlanId: meetingPlanId,
                        userId: user?.id,
                      },
                    });
                    setPanelVisible();
                    setPanelContext({
                      type: 'internal-summary',
                    });
                  } else if (item?.key === 'action-items') {
                    appInsights.trackEvent({
                      name: 'MEETINGFLOW_TOOLBAR_MENU_CLICK_ACTION_ITEMS',
                      properties: {
                        meetingPlanId: meetingPlanId,
                        userId: user?.id,
                      },
                    });
                    setPanelVisible();
                    setPanelContext({
                      type: 'action-items',
                      defaultEditItem: {
                        text: '',
                        assigneeIdOrEmail: email?.toLowerCase(),
                      },
                    });
                  }
                },

                items: [
                  {
                    key: 'draft-email',
                    text: 'Draft Follow Up Email',
                  },
                  {
                    key: 'summarize-and-share',
                    text: 'Summarize Meeting and Share',
                  },
                  {
                    key: 'action-items',
                    text: 'View/Edit Action Items',
                  },
                ],
              }}
            >
              Follow Up
            </AsyncPrimaryButton>
          </div>

          {hasEntitlement('CALL_RECORDING') &&
            ((meetingPlan?.callRecording &&
              meetingPlan?.callRecording?.lastStatus !== 'deleted') ||
              (callRecordingButtonLabel === 'Record' &&
                meetingPlan?.conferenceInfo &&
                !['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
                  meetingPlan.conferenceInfo.type,
                ) &&
                meetingPlan.conferenceInfo.joinUri)) ? (
            <div
              id="meetingflow-about-call-recording"
              className="meeting-plan-section call-recording-section"
              style={{ marginLeft: breakpoints?.md ? '1rem' : '0' }}
            >
              <CallRecordingPlayer
                organizationSlug={organizationSlug}
                meetingPlanId={meetingPlanId}
                scheduleCallRecording={
                  meetingPlan?.conferenceInfo &&
                    !['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
                      meetingPlan.conferenceInfo.type,
                    ) &&
                    meetingPlan.conferenceInfo.joinUri
                    ? scheduleCallRecording
                    : undefined
                }
                onRecorderStateChanged={onCallRecorderStatusChanged}
                showCollaborateDialog={showCollaborateDialog}
                onContactClick={onContactClick}
              />
            </div>
          ) : null}

          <div className={presenceFacepileContainerClass}>
            <PresenceFacepile awarenessStates={awarenessStates} />
          </div>

          <div className={meetingflowContentClass}>
            <div className={meetingflowEditorPivotClass}>
              <div className="meetingflow-editor-tabs">
                {SHOW_AGENDA_EDITOR ? (
                  <div
                    className={`meetingflow-editor-tabs-tab meetingflow-editor-tabs-tab-agenda ${selectedNotesTab === 'notes-agenda' ? 'tab-selected' : ''
                      }`}
                    onClick={() => setSelectedNotesTab('notes-agenda')}
                  >
                    <FontIcon
                      className="meetingflow-editor-tabs-tab-icon"
                      iconName="BulletedList2"
                    />
                    Plan
                  </div>
                ) : null}
                <div
                  className={`meetingflow-editor-tabs-tab meetingflow-editor-tabs-tab-notes ${selectedNotesTab === 'notes-notes' ? 'tab-selected' : ''
                    }`}
                  onClick={() => setSelectedNotesTab('notes-notes')}
                >
                  <FontIcon
                    className="meetingflow-editor-tabs-tab-icon"
                    iconName="BulletedList2"
                  />
                  Notes
                </div>
                {meetingPlan?.textSummary ? (
                  <div
                    className={`meetingflow-editor-tabs-tab meetingflow-editor-tabs-tab-summary ${selectedNotesTab === 'notes-summary' ? 'tab-selected' : ''
                      }`}
                    onClick={() => setSelectedNotesTab('notes-summary')}
                  >
                    <FontIcon
                      className="meetingflow-editor-tabs-tab-icon"
                      iconName="BulletedList2"
                    />
                    Summary
                  </div>
                ) : null}
              </div>
              {SHOW_AGENDA_EDITOR ? (
                <div className="meetingflow-editor-tabs-panel meetingflow-editor-tabs-panel-agenda">
                  <EditorFrame
                    name="MeetingPlanAgenda"
                    readonly={collabIsError}
                    organizationSlug={organizationSlug}
                    meetingflowId={meetingPlanId}
                    placeholder="Enter your meeting plan here"
                    editor={objectivesEditor}
                    additionalEventProps={{
                      organizationSlug,
                      meetingPlanId,
                    }}
                    setPanelContext={setPanelContext}
                    allowTags
                    allowMentions
                    showPlaceholderHint
                    defaultMentionSuggestions={defaultMentionSuggestions}
                    backgroundColor={'transparent'}
                    editorMaxHeight={'100%'}
                  />
                </div>
              ) : null}

              <div className="meetingflow-editor-tabs-panel meetingflow-editor-tabs-panel-notes">
                <EditorFrame
                  name="MeetingPlanNotes"
                  readonly={collabIsError}
                  organizationSlug={organizationSlug}
                  meetingflowId={meetingPlanId}
                  placeholder="Enter your meeting notes here"
                  editor={notesEditor}
                  additionalEventProps={{
                    organizationSlug,
                    meetingPlanId,
                  }}
                  setPanelContext={setPanelContext}
                  createAddIntegrationDeferred={createAddIntegrationDeferred}
                  allowTags
                  allowMentions
                  showPlaceholderHint
                  defaultMentionSuggestions={defaultMentionSuggestions}
                  backgroundColor={'transparent'}
                  editorMaxHeight={'100%'}
                />
              </div>

              {meetingPlan?.textSummary ? (
                <div className="meetingflow-editor-tabs-panel meetingflow-editor-tabs-panel-summary">
                  <>
                    {isEditingSummary ? (
                      <div style={{ padding: '1rem' }}>
                        <MeetingPlanEditDetailsForm
                          key={meetingPlan.id}
                          meetingPlan={meetingPlan}
                          organizationSlug={organizationSlug}
                          notesYArray={notesYArray}
                          onCancel={setIsEditingFalse}
                          fields={['summary']}
                          submitButtonLabel={'Save Summary'}
                          hideLabels
                          onSuccess={() => {
                            setIsEditingFalse();
                            refreshPlan();
                          }}
                        />
                      </div>
                    ) : (
                      <Text
                        id="meetingflow-summary-edit-button"
                        block
                        styles={{
                          root: {
                            margin: '.5rem',
                            padding: '.5rem',
                            borderRadius: '.25rem',
                            fontSize: FontSizes.medium,
                            lineHeight: '1.5',
                            whiteSpace: 'pre-line',
                            transition: '.3s ease-in-out all',
                            cursor: 'pointer',
                            outline: '1px solid transparent !important',
                            ':hover': {
                              outline: `1px solid ${isDark
                                ? NeutralColors.gray160
                                : MEETINGFLOW_COLORS.purpleLight
                                } !important`,
                              backgroundColor: isDark
                                ? NeutralColors.gray190
                                : MEETINGFLOW_COLORS.purpleGreyLight,
                            },
                          },
                        }}
                        onClick={() => {
                          setEditingSummaryTrue();
                          appInsights.trackEvent({
                            name: 'CLICK_EDIT_SUMMARY_INLINE',
                            properties: {
                              meetingPlanId: meetingPlanId,
                              userId: user?.id,
                            },
                          });
                          pushPanelContext({ type: 'insights' });
                        }}
                      >
                        <div className="markdown-content">
                          <ReactMarkdown
                            rehypePlugins={[
                              [
                                rehypeExternalLinks,
                                {
                                  target: '_blank',
                                  rel: ['noopener', 'nofollow', 'noreferrer'],
                                } satisfies RehypeExternalLinksOptions,
                              ],
                            ]}
                            remarkPlugins={[remarkGfm]}
                          >
                            {meetingPlan.textSummary}
                          </ReactMarkdown>
                        </div>
                      </Text>
                    )}
                  </>
                </div>
              ) : null}
            </div>
          </div>

          <div className={bottomToolbarClass}>
            <div className="resources">
              <MeetingPlanResources
                showAdd
                organizationSlug={organizationSlug}
                meetingPlanId={meetingPlanId}
                eventAttachments={meetingPlan?.eventAttachments}
                resources={store.resources}
                onAdd={(item) => {
                  store.resources.push(item);
                  appInsights.trackEvent({
                    name: 'ADD_RESOURCE_TO_MEETING_PLAN',
                    properties: {
                      meetingPlanId: meetingPlanId,
                    },
                  });
                }}
                onUpdate={(idOrIdx, props) => {
                  if (isString(idOrIdx)) {
                    const index = store.resources.findIndex(
                      (r) => r.id === idOrIdx,
                    );
                    if (index !== -1) {
                      Object.assign(store.resources[index], props);
                    }
                  } else {
                    Object.assign(store.resources[idOrIdx], props);
                  }
                }}
                onDelete={(idOrIdx) => {
                  if (isString(idOrIdx)) {
                    const index = store.resources.findIndex(
                      (r) => r.id === idOrIdx,
                    );
                    if (index !== -1) {
                      store.resources.splice(index, 1);
                    }
                  } else {
                    store.resources.splice(idOrIdx, 1);
                  }
                }}
              />

              <div ref={addActionItemCoachmarkTarget}>
                <AsyncPrimaryButton
                  size={16}
                  onMouseOver={setAddActionItemCoachmarkVisible}
                  onMouseOut={setAddActionItemCoachmarkHidden}
                  onClick={async () => {
                    setPanelVisible();
                    setPanelContext({
                      type: 'action-items',
                      defaultEditItem: {
                        text: '',
                        assigneeIdOrEmail: email?.toLowerCase(),
                      },
                    });
                    appInsights.trackEvent({
                      name: 'CLICK_ADD_ACTION_ITEM_TOOLBAR_BUTTON',
                      properties: {
                        organizationId: organization?.id,
                        type: meetingPlan?.conferenceInfo?.type,
                      },
                    });
                  }}
                  styles={addActionItemToolbarButtonStyles}
                  iconProps={{
                    iconName: 'TaskLogo',
                  }}
                />
              </div>

              {addActionItemCoachmarkVisible ? (
                <TeachingBubble
                  target={addActionItemCoachmarkTarget.current}
                  //@ts-ignore
                  headline={
                    <span style={{ color: 'white' }}>Add an Action Item</span>
                  }
                  onDismiss={setAddActionItemCoachmarkHidden}
                  ariaDescribedBy="example-description1"
                  ariaLabelledBy="example-label1"
                  styles={{
                    root: {
                      margin: '0 !important',
                      padding: '0 !important',
                    },
                  }}
                >
                  <span style={{ color: 'white' }}>
                    Make a note of tasks to do done, and assign them to yourself
                    or your teammates.
                  </span>
                </TeachingBubble>
              ) : null}
            </div>
          </div>
        </div>
        {sidebar}
      </div>

      <div>
        {templateNameDeferred?.isPending ? (
          <TemplateNameDialog
            key={meetingPlanId}
            hidden={!templateNameDeferred || !templateNameDeferred.isPending}
            onEntered={templateNameResolve}
            onCancel={templateNameReject}
          />
        ) : null}
        {collaborateDialog}
      </div>
      {confirmApplyTemplateDialog}
      {addIntegrationDialog}
      {confirmDeleteDialog}
      {confirmCreateRecordingDeleteDialog}
      {linkDialog}
      {getConferenceJoinLinkDialog}
      {summarizeMeetingflowDialog}
    </div>
  );
};

export default MeetingPlanPage;
