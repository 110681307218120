import { Select, Box, SxProps, Theme } from '@mui/material';
import { DSContactAvatar, DSMenuItem } from '../DS';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { BasicContact, DealRole } from '@meetingflow/common/Api/data-contracts';

type DealRoomContact = BasicContact & { role?: DealRole | null };

interface ActionItemOwnerContainerProps {
  contacts?: DealRoomContact[];
  selectedContactId?: number;
  onOwnerChange: (newOwner: { userId: number | null }) => void;
  sx?: SxProps<Theme>;
}

const getContactDisplayName = (contact: DealRoomContact | BasicContact) => {
  return contact.name || contact.email;
};

const defaultContact: BasicContact = {
  id: 0,
  email: 'unassigned@example.com',
  name: 'Unassigned',
  timesMet30d: null,
  timesMet90d: null,
  avatarFileUrl: null,
  avatarUrl: null,
  emailDomain: 'example.com',
  firstMet: null,
  lastMeetingDate: null,
  lastMet: null,
  linkedInUrl: null,
  nextMeetingDate: null,
  openTasks: null,
  statsUpdated: null,
  userId: null,
};

/**
 * Component for displaying and selecting the owner of an action item.
 * This is a pure UI component that receives contacts and selection state from its parent.
 */
export const ActionItemOwnerContainer = ({
  contacts,
  selectedContactId,
  onOwnerChange,
  sx,
}: ActionItemOwnerContainerProps) => {
  const selectedContact = selectedContactId
    ? contacts?.find((contact) => contact.id === selectedContactId)
    : undefined;

  return (
    <Select
      value={selectedContactId || ''}
      onChange={(e) => {
        const newContactId = e.target.value as number | '';
        const newContact = contacts?.find((c) => c.id === newContactId);
        onOwnerChange({ userId: newContact?.userId || null });
      }}
      size="small"
      displayEmpty
      renderValue={() => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            height: '100%',
            width: '100%',
          }}
        >
          <DSContactAvatar
            contact={selectedContact || defaultContact}
            size={20}
          />
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: 1,
            }}
          >
            {selectedContact?.name ||
            selectedContact?.email ||
            defaultContact?.name
              ? selectedContact?.name ||
                selectedContact?.email ||
                defaultContact?.name
              : 'Unassigned'}
          </span>
        </Box>
      )}
      sx={{
        height: '32px',
        minWidth: '120px',
        maxWidth: '120px',
        fontSize: '0.75rem',
        backgroundColor: 'white',
        '& .MuiSelect-select': {
          fontSize: '0.75rem',
          backgroundColor: DEALROOMS_COLORS.neutralLighter,
          paddingRight: '1rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiSelect-icon': {
          color: DEALROOMS_COLORS.woodsmoke,
        },
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <DSMenuItem
        value=""
        primaryText={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DSContactAvatar contact={defaultContact} size={24} />
            Unassigned
          </Box>
        }
      />
      {contacts?.map((contact) => (
        <DSMenuItem
          key={contact.id}
          value={contact.id}
          disabled={!contact.userId}
          primaryText={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <DSContactAvatar contact={contact} size={24} />
              {getContactDisplayName(contact)}
            </Box>
          }
        />
      ))}
    </Select>
  );
};
