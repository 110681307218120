import { styled } from '@mui/material/styles';
import { TableCell, Typography } from '@mui/material';

export const StyledMeetingflowListRoot = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  gap: 1rem;

  width: 100%;

  .MuiTableContainer-root {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }

  table {
    width: 100%;
    table-layout: fixed;
  }

  .mf-creator-container {
    container-type: inline-size;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;

    @container (max-width: 10rem) {
      span {
        display: none;
      }
    }
  }
`;

export const StyledMeetingflowList = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  container-type: inline-size;

  table {
    width: 100%;

    .meetingflow,
    .meeting {
      padding-left: 0;
      padding-right: 0;
    }

    @container (max-width: 40rem) {
      td.creator,
      th.creator,
      td.organizer,
      th.organizer {
        display: none;
      }
    }

    @container (max-width: 25rem) {
      td.attendees,
      th.attendees {
        display: none;
      }
    }

    .mf-creator-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
    }
  }

  .MuiTableContainer-root {
    border-radius: 8px;
    box-shadow: none;
  }
`;

export const StyledTableHeader = styled(TableCell)(({ theme }) => ({
  fontWeight: 400,
}));

export const StyledEmptyStateContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: rgba(0, 0, 0, 0.6);
`;

export const StyledEmptyStateText = styled(Typography)`
  margin-top: 0.5rem;
`;
