import { mergeStyleSets } from '@fluentui/react';
import type { IStyle } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

interface ICalloutCardStyles {
  root: IStyle;
  dragHandle: IStyle;
  header: IStyle;
  headerContent: IStyle;
}

export const getCalloutCardStyles = (isDragging?: boolean) =>
  mergeStyleSets<ICalloutCardStyles>({
    root: {
      padding: 20,
      border: `1px solid ${DEALROOMS_COLORS.lightGray}`,
      backgroundColor: isDragging
        ? DEALROOMS_COLORS.neutralGray
        : DEALROOMS_COLORS.white,
      borderRadius: '4px',
      boxShadow: isDragging
        ? '0 12px 24px rgba(0,0,0,0.15)'
        : '0 2px 4px rgba(0,0,0,0.1)',
      marginBottom: 10,
      transition: 'transform 200ms cubic-bezier(0.2, 0, 0, 1)',
      transform: isDragging ? 'scale(1.02)' : 'scale(1)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerContent: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      touchAction: 'none',
      userSelect: 'none',
      '& h3': {
        margin: 0,
      },
    },
    dragHandle: {
      cursor: 'grab',
      padding: '.5rem',
      minWidth: '2rem',
      height: '2rem',
      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralGray,
      },
      ':active': { cursor: 'grabbing' }, // Change cursor while dragging
      '@media (hover: none)': {
        backgroundColor: DEALROOMS_COLORS.neutralGray,
        padding: '0.75rem', // Larger touch target on mobile
        minWidth: '2.5rem',
        height: '2.5rem',
      },
    },
  });
