import { IconButton, Text } from '@fluentui/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { BackIconProps, CancelIconProps } from '../../../../utils/iconProps';
import { DealRoomTaskCommentsPanel } from './DealRoomTaskCommentsPanel';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { useCommentsContainerStyles } from '../../Components/ConversationsTab/useCommentsContainerStyles';
import { useBoolean } from '@fluentui/react-hooks';
import { useMutualPlan } from '../../../../Hooks/useMutualPlan';
import { DSResponsiveDrawer } from '../../../Common/DSResponsiveDrawer';

type DealRoomTaskCommentsContainerProps = {
  currentTaskId: number | null;
  onDismiss: () => void;
  organizationSlug: string;
  dealRoomId: number;
};

export const DealRoomTaskCommentsContainer = ({
  currentTaskId,
  onDismiss,
  organizationSlug,
  dealRoomId,
}: DealRoomTaskCommentsContainerProps) => {
  const [currentThreadId, setCurrentThreadId] = useState<number | undefined>(
    undefined,
  );
  const [
    isMentionsPopupOpen,
    { setTrue: setIsMentionPopupOpen, setFalse: setIsMentionPopupClose },
  ] = useBoolean(false);
  const dealRoomPanelRef = useRef<{ backToConvList: Function }>(null);

  const { user: mfUser } = useUserProfile();
  const { dealRoom } = useDealRoom(organizationSlug, dealRoomId);
  const { mutualPlan } = useMutualPlan(organizationSlug, dealRoomId);

  const { currentContactId, currentContactCompanyId } = useMemo(() => {
    if (!dealRoom || !mfUser?.email)
      return {
        currentContactId: undefined,
        currentContactCompanyId: undefined,
      };

    const currentContact = dealRoom.contacts?.find(
      (contact) => contact.email.toLowerCase() === mfUser.email.toLowerCase(),
    );

    const currentCompany = dealRoom.companies?.[0];

    return {
      currentContactId: currentContact?.id,
      currentContactCompanyId: currentCompany?.id,
    };
  }, [dealRoom, mfUser?.email]);

  const handleCheckForMentionsPopup = useCallback(
    (isPopupOpen: boolean) => {
      if (isPopupOpen) {
        setIsMentionPopupOpen();
      } else {
        setIsMentionPopupClose();
      }
    },
    [setIsMentionPopupClose, setIsMentionPopupOpen],
  );

  const taskTitle = useMemo(() => {
    // Get the current task title

    if (currentTaskId === null) return '';

    if (!Array.isArray(mutualPlan?.milestones)) return '';

    const actionItems = mutualPlan.milestones.flatMap((milestone) =>
      Array.isArray(milestone.actionItems) ? milestone.actionItems : [],
    );

    if (!actionItems.length) return '';

    const currentActionItem = actionItems.find(
      (actionItem) => actionItem.id === currentTaskId,
    );

    if (!currentActionItem) return '';

    return currentActionItem.actionItem;
  }, [currentTaskId, mutualPlan]);

  const styles = useCommentsContainerStyles();

  return (
    <DSResponsiveDrawer
      anchor="right"
      open={currentTaskId !== null}
      onClose={isMentionsPopupOpen ? undefined : onDismiss}
      padding="1rem 0 0"
    >
      <div className={styles.panel}>
        <div className={styles.mainContainer}>
          <IconButton
            className={styles.headerButton}
            iconProps={CancelIconProps}
            onClick={onDismiss}
          />
          <div className={styles.content}>
            {taskTitle ? (
              <Text className={styles.taskName}>{taskTitle}</Text>
            ) : null}
            <div className={styles.titleContainer}>
              {currentThreadId !== undefined ? (
                <>
                  <IconButton
                    onClick={() => {
                      if (dealRoomPanelRef?.current?.backToConvList) {
                        dealRoomPanelRef.current.backToConvList();
                      }
                    }}
                    iconProps={BackIconProps}
                  />
                  <Text className={styles.title}>Thread</Text>
                </>
              ) : (
                <Text className={styles.title}>Comments</Text>
              )}
            </div>

            {currentTaskId !== null && (
              <DealRoomTaskCommentsPanel
                ref={dealRoomPanelRef}
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                currentThreadId={currentThreadId}
                setCurrentThreadId={setCurrentThreadId}
                taskId={currentTaskId}
                currentContactCompanyId={currentContactId}
                currentContactId={currentContactCompanyId}
                handleCheckForMentionsPopup={handleCheckForMentionsPopup}
              />
            )}
          </div>
        </div>
      </div>
    </DSResponsiveDrawer>
  );
};
