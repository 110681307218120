import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const getStyles = () =>
  mergeStyleSets({
    contentContainer: {
      width: '100%',
      padding: '1rem 1.5rem 1.5rem',
      backgroundColor: DEALROOMS_COLORS.white,
      gap: '1.5rem',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      fontWeight: '400',
    },
    closeButton: {
      i: {
        fontSize: '.75rem',
      },
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      paddingTop: '1rem',
    },
    contentTitle: {
      '.ms-TextField-fieldGroup': {
        borderRadius: '.25rem',
        border: 'none !important',
        height: 'auto',
        '::after': {
          borderRadius: '.25rem',
          border: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
        },
      },
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
      input: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.375',
        borderRadius: '.25rem',
        padding: '9px 14px',
        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },
    contentDescription: {
      '.ms-TextField-fieldGroup': {
        borderRadius: '4px',
        border: 'none !important',
        height: 'auto',

        '::after': {
          borderRadius: '.25rem',
          border: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
        },
      },
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
      textarea: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '22px',
        borderRadius: '.25rem',
        padding: '9px 14px',
        height: '96px',
        maxHeight: '96px',
        overflowY: 'auto',

        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },
    footerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
      flexWrap: 'wrap',
      '> .cancel-button': {
        padding: '0.5rem 1rem',
        justifyContent: 'center',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.darkerGray,
        },
      },
      '> .save-button': {
        padding: '0.5rem 1rem',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.white,
        },
      },
      '> .save-button-disabled': {
        backgroundColor: DEALROOMS_COLORS.neutralDark,
        color: DEALROOMS_COLORS.lightGray,
        borderColor: DEALROOMS_COLORS.neutralDark,
        cursor: 'not-allowed',
      },
    },
    uploadSection: {
      display: 'flex',
      gap: '1rem',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      input: {
        display: 'none',
      },
    },
    uploadSectionMessages: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    uploadSectionFileName: {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      color: DEALROOMS_COLORS.themePrimary,
      wordBreak: 'break-word',
      textAlign: 'center',
    },
    uploadSectionMessage: {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      color: DEALROOMS_COLORS.neutralDark,
      wordBreak: 'break-word',
      textAlign: 'center',
    },
    highlighted: {
      color: DEALROOMS_COLORS.uploadAttachmentHighLightedText,
    },
    dropContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    draggableContainer: {
      maxWidth: '307px',
    },
    selectFileButton: {
      padding: '.375rem .75rem',
    },
  });
