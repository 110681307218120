import { useCallback } from 'react';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';

type HandleOnFeatureProps = {
  organizationSlug: string | undefined;
  dealRoomId: number | undefined;
  refetchArtifacts: () => Promise<unknown>;
};

export const useHandleOnFeature = ({
  organizationSlug,
  dealRoomId,
  refetchArtifacts,
}: HandleOnFeatureProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const handleOnFeature = useCallback(
    async (feature: boolean, artifactId: number) => {
      try {
        if (
          !organizationSlug ||
          !dealRoomId ||
          !artifactId ||
          !getAccessTokenSilently
        ) {
          return;
        }

        const token = await getAccessTokenSilently();
        if (feature) {
          await DealRoomsApiClient.featureArtifact(
            {
              organizationSlug,
              dealRoomId,
              artifactId: artifactId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        } else {
          await DealRoomsApiClient.unfeatureArtifact(
            organizationSlug,
            dealRoomId,
            artifactId,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        }
        await refetchArtifacts();
      } catch (err: unknown) {}
    },
    [organizationSlug, dealRoomId, getAccessTokenSilently, refetchArtifacts],
  );
  return { handleOnFeature };
};
