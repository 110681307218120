import {
  styled,
  keyframes,
  Box,
  List,
  ListSubheader,
  ListItem,
  Typography,
  Avatar,
  AvatarGroup,
  Badge,
  Stack,
} from '@mui/material';
import { StarBorderRounded, StarRounded } from '@mui/icons-material';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { NeutralColors } from '@fluentui/react';

export const MeetingflowCardRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.background.default,
  flexGrow: 1,
  gap: '0.25rem',
  position: 'relative',
  minWidth: 0, // Required for text truncation
  width: '100%', // Ensure full width

  '.meetingflow-card': {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.25rem',
    flexGrow: 1,
    minWidth: 0, // Required for text truncation
    width: '100%', // Ensure full width
  },

  '.meetingflow-actions': {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.25rem',
    flexBasis: '2rem',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 0, // Required for text truncation
    width: '100%', // Ensure full width
    color: theme.palette.text.secondary,
  },
}));

export const MeetingflowCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(1),
  minHeight: '24px',

  position: 'relative',
  '.meetingflow-details': {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0, // Required for text truncation
    overflow: 'hidden',

    '.meetingflow-title': {
      height: '32px',
      lineHeight: '32px',
      paddingRight: '.5rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      fontSize: '14px',

      '& a': {
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },

    '.short-time, .mf-datetime': {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: 'regular',
      color: theme.palette.text.secondary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },

  '.meetingflow-actions-column': {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: theme.spacing(5),
  },
}));

export const MeetingflowCard = styled('div')<{
  large?: boolean;
  showCreator?: boolean;
  hasClickHandler?: boolean;
  isDark?: boolean;
  inlineIcons?: boolean;
  titlePadding?: string;
}>(
  ({
    theme,
    large = false,
    showCreator = false,
    hasClickHandler = false,
    isDark = false,
    inlineIcons = false,
    titlePadding = '0',
  }) => ({
    height: large ? '48px' : 'auto',
    paddingBottom: '.25rem',
    backgroundColor: 'transparent',
    position: 'relative',
    borderRadius: '.25rem',
    cursor: hasClickHandler ? 'pointer' : undefined,
    display: 'flex',
    flexDirection: 'column',
    columnGap: '.5rem',
    margin: '0',
    transition: '.3s ease-in-out all',
    width: 'auto',
    minWidth: 0, // Required for text truncation

    '.card-content': {
      display: 'block',
      width: showCreator ? `calc(100% - 64px)` : '100%',
      flexBasis: 'auto',
      minWidth: 0, // Required for text truncation
    },

    '&:hover': {
      backgroundColor: hasClickHandler
        ? isDark
          ? theme.palette.grey[800]
          : 'transparent'
        : undefined,
      '& img': {
        filter: 'grayscale(0%) !important',
        opacity: '1 !important',
      },
      '& div.meetingflow-icons': {
        opacity: '1 !important',
      },

      '& .meetingflow-title': {
        cursor: hasClickHandler ? 'pointer' : 'default',
        color: isDark ? theme.palette.common.white : undefined,
      },
    },

    '.meetingflow-title': {
      display: inlineIcons ? 'inline-block' : 'block',
      height: large ? '32px' : '20px',
      lineHeight: large ? '32px' : '20px',
      paddingRight: inlineIcons ? '.5rem' : titlePadding,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: isDark ? theme.palette.common.white : undefined,
      boxSizing: 'border-box',
      fontSize: '14px',
      flexGrow: 1,
      minWidth: 0, // Required for text truncation
    },
  }),
);

export const CompanyWrapper = styled('div')(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(0.5),
}));

export const StyledCompanyName = styled('span')(({ theme }) => ({
  transition: '.3s ease-in-out all',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export const StyledAttendeeName = styled('div')<{ isDark?: boolean }>(
  ({ theme, isDark }) => ({
    marginLeft: theme.spacing(3.5), // 28px
    marginBottom: theme.spacing(0.25), // 2px
    color: isDark ? theme.palette.grey[300] : theme.palette.primary.main,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  }),
);

export const MeetingflowIconContainer = styled(Box)(({ theme }) => ({
  minWidth: '16px',
  height: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
}));

export const MeetingflowIcon = styled(Box)(({ theme }) => ({
  color: DEALROOMS_COLORS.cloudburst,
  cursor: 'pointer',
  height: '16px',
  width: '16px',
  borderRadius: '50%',
  '& svg': {
    color: 'inherit',
    height: '16px',
    width: '16px',
  },
}));

export const AttendeesList = styled(List)(({ theme }) => ({
  py: 0,
}));

export const AttendeesListSubheader = styled(ListSubheader)(({ theme }) => ({
  py: 0,
  lineHeight: 1.2,
}));

export const AttendeesListItem = styled(ListItem)(({ theme }) => ({
  py: 0.25,
  minHeight: 0,
}));

export const AttendeesTypography = styled(Typography)(({ theme }) => ({
  lineHeight: 1.2,
  paddingLeft: '1rem',
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  position: 'relative',
}));

export const AttendeesStar = styled(StarRounded)(({ theme }) => ({
  position: 'absolute',
  left: '-.75rem',
  fontSize: '1rem',
  color: DEALROOMS_COLORS.white,
}));

export const AttendeesAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  fontSize: '0.875rem',
}));

export const AttendeesBadge = styled(Badge)(({ theme }) => ({
  marginLeft: '.5rem',
  '& .MuiBadge-badge': {
    fontSize: '0.65rem',
    height: '16px',
    lineHeight: '16px',
    minWidth: '16px',
    padding: '0 4px',
    textAlign: 'center',
    backgroundColor: DEALROOMS_COLORS.white,
    color: DEALROOMS_COLORS.cloudburst,
  },
}));

export const MeetingflowCardStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  minWidth: 0,

  '.mf-details': {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0,
    overflow: 'hidden',

    '.meetingflow-title': {
      height: '24px',
      lineHeight: '24px',
      paddingRight: '.5rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      fontSize: '14px',
      minWidth: 0, // Required for text truncation
    },

    '.short-time, .mf-datetime': {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: 'regular',
      color: theme.palette.text.secondary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

export const CompanyLogoContainer = styled('div')({
  display: 'inline-flex',
  height: '20px',
  position: 'relative',
  top: '2px',
});

export const CompanyLogoListContainer = styled('div')({
  display: 'flex',
  height: '20px',
  marginTop: '.5rem',
});

export const DateTimeContainer = styled('div')({
  display: 'block',
  color: NeutralColors.gray120,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  boxSizing: 'content-box',
});

export const CompletedEventIcon = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: '0px',
  right: '-.25rem',
}));

export const StyledAvatarGroup = styled(AvatarGroup)({
  '& .MuiAvatar-root': {
    width: '16px',
    height: '16px',
    fontSize: '10px',
    border: '1px solid white',
    marginRight: '4px',
  },
});

export const JoinConferenceButton = styled('button')(({ theme }) => ({
  backgroundColor: `${DEALROOMS_COLORS.peacock}80`,
  cursor: 'pointer',
  fontSize: '10px',
  textTransform: 'uppercase',
  display: 'flex',
  gap: '0.25rem',
  color: DEALROOMS_COLORS.white,
  padding: '0 0.5rem',
  borderRadius: '0.5rem',
  fontWeight: '500',
  lineHeight: '16px',
  border: 'none',
  alignItems: 'center',
  transition: '.3s ease-in-out all',
  '&:hover': {
    backgroundColor: DEALROOMS_COLORS.peacock,
  },
}));
