import { DealRoomActionItemStatus } from '@meetingflow/common/Api/data-contracts';
import { DateTime } from 'luxon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

interface StatusOption {
  key: DealRoomActionItemStatus;
  text: string;
  disabled?: boolean;
}

type DealRoomActionItemsStatusValues = {
  [key: string]: StatusOption;
};

export const dealRoomActionItemStatusValues: DealRoomActionItemsStatusValues = {
  notStarted: {
    key: 'NOT_STARTED',
    text: 'Not Started',
  },
  docRequested: {
    key: 'DOC_REQUESTED',
    text: 'Requested',
  },
  onHold: {
    key: 'ON_HOLD',
    text: 'On Hold',
  },
  inProgress: {
    key: 'IN_PROGRESS',
    text: 'In Progress',
  },
  completed: {
    key: 'COMPLETED',
    text: 'Completed',
  },
};

export const dealRoomActionItemStatusOptions = [
  dealRoomActionItemStatusValues.notStarted,
  dealRoomActionItemStatusValues.docRequested,
  dealRoomActionItemStatusValues.onHold,
  dealRoomActionItemStatusValues.inProgress,
  dealRoomActionItemStatusValues.completed,
];

export const getStatusDropdownBackgroundColor = (
  status: DealRoomActionItemStatus | undefined,
) => {
  switch (status) {
    case 'COMPLETED':
      return DEALROOMS_COLORS.actionItemStatusCompleted;
    case 'DOC_REQUESTED':
      return DEALROOMS_COLORS.actionItemStatusNotStarted;
    case 'IN_PROGRESS':
      return DEALROOMS_COLORS.actionItemStatusInProgress;
    case 'ON_HOLD':
      return DEALROOMS_COLORS.actionItemStatusInProgress;
    case 'NOT_STARTED':
      return DEALROOMS_COLORS.actionItemStatusNotStarted;
    default:
      return DEALROOMS_COLORS.actionItemStatusNotStarted;
  }
};

export const isSameDueDate = (date1: string | null, date2: string | null) => {
  if (!date1 || !date2) return false;

  const currentDate1 = DateTime.fromISO(date1);
  const currentDate2 = DateTime.fromISO(date2);

  return currentDate1.hasSame(currentDate2, 'day');
};

export const getStatusBackgroundColor = (status?: DealRoomActionItemStatus) => {
  const opacity = '40'; // Using 40 in hex = 25% opacity
  switch (status) {
    case 'NOT_STARTED':
      return `${DEALROOMS_COLORS.crimson}${opacity}`;
    case 'DOC_REQUESTED':
      return `${DEALROOMS_COLORS.frost}${opacity}`;
    case 'ON_HOLD':
      return `${DEALROOMS_COLORS.cloudburst}${opacity}`;
    case 'IN_PROGRESS':
      return `${DEALROOMS_COLORS.orchid}${opacity}`;
    case 'COMPLETED':
      return `${DEALROOMS_COLORS.peacock}${opacity}`;
    default:
      return 'transparent';
  }
};

export const getStatusTextColor = (status?: DealRoomActionItemStatus) => {
  switch (status) {
    case 'NOT_STARTED':
    case 'DOC_REQUESTED':
    case 'ON_HOLD':
    case 'IN_PROGRESS':
    case 'COMPLETED':
      return DEALROOMS_COLORS.woodsmoke;
    default:
      return DEALROOMS_COLORS.woodsmoke;
  }
};
