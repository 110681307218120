import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { AgGridReact } from 'ag-grid-react';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { mergeStyles } from '@fluentui/react';
import { IconButton } from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';

import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import { useUserProfile } from '../../../../../Hooks/useProfile';
import useDeviceType from '../../../../../Hooks/deviceDetection';
import { ListDealRoomContactsData } from '@meetingflow/common/Api/data-contracts';
import { useOrganizationSlug } from '../../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../../Hooks/useDealRoomId';
import useBreakpoints from '../../../../../Hooks/useBreakpoints';

interface UserSettingsMembersTableProps {
  dealRoomContactsData: ListDealRoomContactsData | undefined;
  refetchContactsData: () => void;
}

const MemberRenderer = (props: ICellRendererParams) => {
  const data = props.data;
  return (
    <div
      style={{
        color: DEALROOMS_COLORS.themePrimary,
        fontSize: '14px',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        overflow: 'hidden',
        // whiteSpace: 'wrap',
      }}
    >
      {data.name || data.email}
    </div>
  );
};

const EmailRenderer = (props: ICellRendererParams) => {
  const data = props.data;
  return (
    <div
      style={{
        color: DEALROOMS_COLORS.themeSecondary,
        fontSize: '14px',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        overflow: 'hidden',
        // whiteSpace: 'wrap',
      }}
    >
      {data.email}
    </div>
  );
};

const ActionRenderer = (
  props: ICellRendererParams & { onDelete: (id: string) => void },
) => {
  const data = props.data;
  const dealRoom = props.context.dealRoom;

  if (data.id === dealRoom?.owner?.id) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '8px',
      }}
    >
      <IconButton
        onClick={() => props.context.onDelete(data.id)}
        color="error"
        size="small"
        title="Remove member"
      >
        <RemoveCircle />
      </IconButton>
    </div>
  );
};

const gridOptions: GridOptions = {
  singleClickEdit: false,
  domLayout: 'autoHeight',
  suppressMovableColumns: true,
  suppressClickEdit: true,
  readOnlyEdit: true,
  enableCellTextSelection: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  defaultColDef: {
    resizable: false,
    sortable: true,
    filter: false,
    flex: 1,
  },
  headerHeight: 48,
  rowHeight: 48,
  rowStyle: { cursor: 'default' },
};

export const UserSettingsMembersTable = ({
  dealRoomContactsData,
  refetchContactsData,
}: UserSettingsMembersTableProps) => {
  const { isMobile } = useDeviceType(600, 1100);
  const breakpoints = useBreakpoints();
  const { user } = useUserProfile();
  const { dealRoom, dealRoomRole, refetch } = useDealRoom();
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { internalDomains, role: orgRole } = useOrganization();

  const currentUserEmailDomain = user?.email.split('@')[1].toLowerCase() || '';
  const currentUserIsDealRoomOwner = dealRoom?.owner?.id === user?.id;
  const currentUserIsSeller =
    dealRoomRole === 'SELLER' ||
    currentUserIsDealRoomOwner ||
    (internalDomains.includes(currentUserEmailDomain) &&
      ['ADMIN', 'CREATOR', 'COLLABORATOR'].includes(orgRole || ''));

  const showActionsColumn =
    currentUserIsSeller &&
    dealRoomContactsData?.length &&
    dealRoomContactsData?.length > 1 &&
    dealRoomContactsData?.some((contact) => contact.id !== dealRoom?.owner?.id);

  const handleDeleteContact = async (contactId: string) => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }
    const token = await getAccessTokenSilently();
    try {
      const result = await DealRoomsApiClient.removeContactFromDealRoom(
        organizationSlug,
        dealRoomId,
        contactId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (result.status === 204) {
        toast.success(`Contact has been removed.`);
      }
      refetch();
      refetchContactsData();
    } catch (e) {
      toast.error(`Something went wrong removing the contact.`);
    }
  };

  const columns = [
    ...(isMobile
      ? []
      : [
          {
            headerName: 'Name',
            field: 'name',
            cellStyle: {
              textAlign: 'left',
            },
            cellClass: 'no-border',
            cellRenderer: MemberRenderer,
            flex: 1,
          },
        ]),
    {
      headerName: 'Email',
      field: 'email',
      cellStyle: {
        textAlign: 'left',
      },
      cellClass: 'no-border',
      cellRenderer: EmailRenderer,
      flex: 1,
    },
    ...(showActionsColumn
      ? [
          {
            headerName: 'Actions',
            field: 'actions',
            cellRenderer: ActionRenderer,
            cellRendererParams: {
              onDelete: handleDeleteContact,
            },
            minWidth: 170,
            maxWidth: 170,
          },
        ]
      : []),
  ];

  const containerStyle = mergeStyles({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '.ag-root-wrapper': {
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
    },
    '.ag-cell': {
      span: {
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
      },
    },
  });

  return (
    <div className={containerStyle}>
      <AgGridReact
        gridOptions={gridOptions}
        rowData={dealRoomContactsData}
        columnDefs={columns}
        context={{
          dealRoom,
          onDelete: handleDeleteContact,
        }}
        overlayNoRowsTemplate="No members found"
      />
    </div>
  );
};
