import { useEffect } from 'react';
import { useMatch, useParams, useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';

export type RedirectProps = {
  to: string;
};
export const Redirect = ({ to }: RedirectProps) => {
  const navigate = useNavigate();
  const match = useMatch({
    path: '/organization/:organizationSlug',
    end: false,
  });
  const organizationSlug = match?.params.organizationSlug;

  useEffect(() => {
    if (to.includes(':organizationSlug')) {
      if (!organizationSlug) {
        throw Error(
          'Trying to replace a path with workspace slug outside of an workspace path!',
        );
      }

      navigate(to.replace(':organizationSlug', organizationSlug));
    } else {
      navigate(to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export function DealRoomRedirect() {
  const { dealRoomId } = useParams();
  const match = useMatch('organization/:organizationSlug/dealroom/:dealRoomId/*');

  // match.params['*'] will capture anything after `dealroom/:dealRoomId/`
  const restOfPath = match?.params['*'] || '';

  return (
    <Navigate
      replace
      to={`../decisionsite/${dealRoomId}/${restOfPath}`}
    />
  );
}