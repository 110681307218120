import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  mergeStyles,
  mergeStyleSets,
  Text,
} from '@fluentui/react';
import {
  ListDealRoomsData,
  Meetingflow,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import { useQuery } from 'react-query';
import { EMPTY_ARRAY } from '../../../Constants';
import { OrganizationDealRoomsQuery } from '../../../QueryNames';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { useNavigate } from '../../../Hooks/useNavigate';

type RelatedDealRoomsListProps = {
  organizationSlug: Organization['slug'];
  meetingflowId: Meetingflow['id'];
};

export const RelatedDealRoomsList = ({
  organizationSlug,
  meetingflowId,
}: RelatedDealRoomsListProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const {
    data: relatedDealRoomsData,
    isLoading: relatedDealRoomsLoading,
    isFetched: relatedDealRoomsFetched,
  } = useQuery(
    OrganizationDealRoomsQuery(organizationSlug, meetingflowId),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRooms(
        {
          organizationSlug,
          meetingflowId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
  );

  const relatedDealRooms: ListDealRoomsData =
    relatedDealRoomsData?.data ?? EMPTY_ARRAY;

  const styles = mergeStyleSets({
    list: {
      position: 'relative',
      animationName: 'fadeInSlideAnimation',
      animationDuration: '1s',
      transitionTimingFunction: 'linear',
      animationIterationCount: '1',
      animationFillMode: 'forwards',
      width: 'auto',
      transition: '.3s ease-in-out all',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      rowGap: '.125rem',
      marginBottom: '1rem',
    },
    sectionHeader: {
      margin: '0 0 .5rem 0',
      fontSize: FontSizes.medium,
      fontWeight: FontWeights.regular,
      color: DEALROOMS_COLORS.cloudburst,
      position: 'relative',
      display: 'block',
      width: '100%',

      userSelect: 'none',
      webkitUserSelect: 'none',
    },
    dealRoomItem: {
      cursor: 'pointer',
    },
  });

  return (
    <div className={styles.list}>
      <Text block className={styles.sectionHeader}>
        Decision Sites
      </Text>

      {relatedDealRooms.length > 0 ? (
        relatedDealRooms.map((dealRoom) => (
          <div
            key={dealRoom.id}
            className={styles.dealRoomItem}
            onClick={() =>
              navigate(
                `/organization/${organizationSlug}/decisionsite/${dealRoom.id}`,
              )
            }
          >
            <Text variant="medium" block>
              {dealRoom.name}
            </Text>
            {dealRoom.description ? (
              <Text variant="small" block>
                {dealRoom.description}
              </Text>
            ) : null}
          </div>
        ))
      ) : (
        <Text variant="small" block>
          No related Decision Sites found.
        </Text>
      )}
    </div>
  );
};
