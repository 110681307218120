// Import required dependencies
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Spinner, Text } from '@fluentui/react';
import { DSTabs, DSTab } from './DS';
import saveAs from 'file-saver';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useShareDealRoomArtifactDialog } from '../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { useDealRoomArtifactId } from '../../Hooks/useDealRoomArtifactId';
import { useOrganization } from '../../Hooks/useOrganization';
import { OrganizationDealRoomArtifactQuery } from '../../QueryNames';
import { DealRoomsApiClient } from '../../Services/NetworkCommon';
import '../../Styles/DealRoom/core.scss';
import { SplitViewLayout } from '../Layouts/SplitViewLayout';
import { ArtifactsSidebar } from './Artifacts/ArtifactsSidebar/ArtifactsSidebar';
import { DealRoomArtifactCommentsPanel } from './Artifacts/DealRoomArtifactCommentsPanel';
import { DealRoomArtifactAssistantPanel } from './Assistant/DealRoomArtifactAssistantPanel';
import { useTitle } from '../../Hooks/useTitle';
import {
  ArtifactActions,
  ArtifactMetadata,
  ArtifactPreview,
  ArtifactTitle,
} from './Components/ArtifactDetailsViewComponents';
import { getStyles } from './Components/ArtifactDetailsViewComponents/styles';
import useDeviceType from '../../Hooks/deviceDetection';
import { useUserProfile } from '../../Hooks/useProfile';
import { BackIconProps } from '../../utils/iconProps';
import { useHandleOnFeature } from './Artifacts/useArtifactsOnFeature';

/**
 * DealRoomArtifactDetailView Component
 *
 * A comprehensive view for displaying and interacting with deal room artifacts.
 * Features include:
 * - Artifact preview (documents, images, videos, etc.)
 * - Metadata display (creation date, updates, etc.)
 * - Interactive actions (download, share, voting)
 * - AI assistant and comments integration
 */
export const DealRoomArtifactDetailView = () => {
  const { isMobile } = useDeviceType();

  // Hooks and state
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { slug: organizationSlug } = useOrganization();
  const { user: mfUser } = useUserProfile();
  const [sidePanelTab, setSidePanelTab] = useState<'ask-ai' | 'comment'>(
    'ask-ai',
  );
  const [currentThreadId, setCurrentThreadId] = useState<number | undefined>(
    undefined,
  );
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const dealRoomPanelRef = useRef<{ backToConvList: Function }>(null);
  const styles = getStyles(isMobile);

  useTitle(`Artifact`);

  // URL parameters and routing
  const urlArtifactId = useDealRoomArtifactId();
  const [currentArtifactId, setCurrentArtifactId] = useState<
    number | undefined
  >(urlArtifactId);
  const {
    dealRoomId,
    dealRoomRole,
    refetch: refreshDealRoom,
    dealRoom,
  } = useDealRoom();

  // Fetch artifact data
  const {
    data: artifactResponse,
    isLoading: artifactLoading,
    refetch: refetchArtifact,
  } = useQuery(
    OrganizationDealRoomArtifactQuery(
      organizationSlug!,
      dealRoomId!,
      currentArtifactId!,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.getArtifact(
        organizationSlug!,
        dealRoomId!,
        currentArtifactId!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!dealRoomId && !!currentArtifactId,
    },
  );

  const artifact = useMemo(() => artifactResponse?.data, [artifactResponse]);

  const [title, setTitle] = useState<string | undefined>(
    artifact?.label || artifact?.name,
  );

  const [description, setDescription] = useState<string | undefined>(
    artifact?.description || undefined,
  );

  // Set initial title when artifact is loaded
  useEffect(() => {
    setTitle(artifact?.label || artifact?.name);
  }, [artifact]);

  // Set initial description when artifact is loaded
  useEffect(() => {
    setDescription(artifact?.description || undefined);
  }, [artifact]);

  // Share dialog setup
  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug: organizationSlug!,
    artifactName: artifact?.name,
  });

  // Function to handle viewing artifact
  const handleViewArtifact = useCallback(async () => {
    if (!currentArtifactId || !organizationSlug || !dealRoomId) return;

    const token = await getAccessTokenSilently();
    await DealRoomsApiClient.viewArtifact(
      organizationSlug,
      dealRoomId,
      currentArtifactId,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    await refetchArtifact();
  }, [
    currentArtifactId,
    dealRoomId,
    getAccessTokenSilently,
    organizationSlug,
    refetchArtifact,
  ]);

  useEffect(() => {
    handleViewArtifact();
  }, [handleViewArtifact]); // Only re-run when currentArtifactId changes

  // Handle feature action
  const { handleOnFeature } = useHandleOnFeature({
    organizationSlug,
    dealRoomId,
    refetchArtifacts: refetchArtifact,
  });

  // Handle download action
  const handleOnDownloadArtifact = useCallback(async () => {
    try {
      if (artifact && 'fileUrl' in artifact) {
        // Get filename from either name, label, or use a default name
        const filename = artifact.name || artifact.label || 'downloaded-file';

        // Fetch the artifact file from the provided URL
        const fileResponse = await fetch(artifact.fileUrl);

        // Convert the response to a Blob object
        const fileBlob = await fileResponse.blob();

        // Create a new File object with the Blob data and appropriate metadata
        const downloadableFile = new File([fileBlob], filename, {
          type: fileBlob.type,
        });

        // Initiate the file download
        saveAs(downloadableFile);
      }
    } catch (err: unknown) {}
  }, [artifact]);

  // const handleOnShare = useCallback(async () => {
  //   try {
  //     if (
  //       !createConfirmShareDeferred ||
  //       typeof organizationSlug !== 'string' ||
  //       !organizationSlug ||
  //       typeof dealRoomId !== 'number' ||
  //       typeof currentArtifactId !== 'number' ||
  //       !getAccessTokenSilently
  //     ) {
  //       return;
  //     }
  //
  //     const confirmShare = await createConfirmShareDeferred().promise;
  //
  //     if (!confirmShare) {
  //       return;
  //     }
  //
  //     const token = await getAccessTokenSilently();
  //     await toast.promise(
  //       DealRoomsApiClient.shareArtifact(
  //         organizationSlug,
  //         dealRoomId,
  //         currentArtifactId,
  //         {
  //           subject: confirmShare.subject,
  //           message: confirmShare.message,
  //           contacts: confirmShare.contacts,
  //         },
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         },
  //       ),
  //       {
  //         loading: 'Sharing Artifact',
  //         success: (_response) => {
  //           return 'Successfully shared Artifact';
  //         },
  //         error: (err: unknown) => {
  //           return 'Something went wrong sharing Artifact, please try again later';
  //         },
  //       },
  //     );
  //   } catch (err: unknown) {}
  // }, [
  //   organizationSlug,
  //   dealRoomId,
  //   currentArtifactId,
  //   createConfirmShareDeferred,
  //   getAccessTokenSilently,
  // ]);

  // Handle upvote/downvote action
  const handleOnVote = useCallback(
    async (upVote?: boolean) => {
      try {
        if (
          !artifact ||
          typeof organizationSlug !== 'string' ||
          typeof dealRoomId !== 'number' ||
          typeof currentArtifactId !== 'number' ||
          !getAccessTokenSilently ||
          !refetchArtifact
        ) {
          return;
        }

        const token = await getAccessTokenSilently();
        await (
          upVote
            ? artifact.upvoted
              ? DealRoomsApiClient.removeArtifactUpvote
              : DealRoomsApiClient.upvoteArtifact
            : artifact.downvoted
              ? DealRoomsApiClient.removeArtifactDownvote
              : DealRoomsApiClient.downvoteArtifact
        )(organizationSlug, dealRoomId, currentArtifactId, {
          headers: { Authorization: `Bearer ${token}` },
        });
        await refetchArtifact();
      } catch (err: unknown) {}
    },
    [
      artifact,
      organizationSlug,
      dealRoomId,
      currentArtifactId,
      getAccessTokenSilently,
      refetchArtifact,
    ],
  );

  // Handle title change
  const handleOnBlurName = useCallback(async () => {
    try {
      if (
        !organizationSlug ||
        !dealRoomId ||
        !currentArtifactId ||
        !title ||
        title === artifact?.name ||
        title === artifact?.label
      ) {
        setIsEditingTitle(false);
        return;
      }

      let dataToSend: {};
      if (artifact?.label) {
        dataToSend = { label: title };
      } else {
        dataToSend = { name: title };
      }

      const token = await getAccessTokenSilently();
      await DealRoomsApiClient.updateArtifact(
        organizationSlug,
        dealRoomId,
        currentArtifactId,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      await refetchArtifact();
      refreshDealRoom();
      toast.success('Artifact title updated successfully');

      setIsEditingTitle(false);
    } catch (err: unknown) {}
  }, [
    organizationSlug,
    dealRoomId,
    currentArtifactId,
    getAccessTokenSilently,
    title,
    artifact?.label,
    artifact?.name,
    refetchArtifact,
    refreshDealRoom,
  ]);

  const handleOnBlurDescription = useCallback(async () => {
    try {
      if (
        !organizationSlug ||
        !dealRoomId ||
        !currentArtifactId ||
        description === undefined ||
        description === artifact?.description
      ) {
        setIsEditingDescription(false);
        return;
      }

      const token = await getAccessTokenSilently();
      await DealRoomsApiClient.updateArtifact(
        organizationSlug,
        dealRoomId,
        currentArtifactId,
        {
          description,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      await refetchArtifact();
      refreshDealRoom();
      toast.success('Artifact description updated successfully');

      setIsEditingDescription(false);
    } catch (err: unknown) {}
  }, [
    organizationSlug,
    dealRoomId,
    currentArtifactId,
    getAccessTokenSilently,
    description,
    artifact?.description,
    refetchArtifact,
    refreshDealRoom,
  ]);

  const handleArtifactSelect = useCallback(
    (newArtifactId: number) => {
      if (newArtifactId === currentArtifactId) {
        return;
      }
      // Update URL without navigation
      window.history.pushState(
        null,
        '',
        `/organization/${organizationSlug}/decisionsite/${dealRoomId}/artifact/${newArtifactId}`,
      );
      // Update local state
      setCurrentArtifactId(newArtifactId);
    },
    [organizationSlug, dealRoomId, currentArtifactId],
  );

  // Redirect if IDs are missing
  useEffect(() => {
    if (!dealRoomId && !currentArtifactId) {
      navigate(`/organization/${organizationSlug}/library/decisionsites`);
    } else if (!currentArtifactId) {
      navigate(`/organization/${organizationSlug}/decisionsite/${dealRoomId}`);
    } else if (!dealRoomId) {
      navigate(`/organization/${organizationSlug}/library/decisionsites`);
    }
  }, [currentArtifactId, dealRoomId, navigate, organizationSlug]);

  const { currentContactId, currentContactCompanyId } = useMemo(() => {
    if (!dealRoom || !mfUser?.email)
      return {
        currentContactId: undefined,
        currentContactCompanyId: undefined,
      };

    const currentContact = dealRoom.contacts?.find(
      (contact) => contact.email.toLowerCase() === mfUser.email.toLowerCase(),
    );

    const currentCompany = dealRoom.companies?.[0];

    return {
      currentContactId: currentContact?.id,
      currentContactCompanyId: currentCompany?.id,
    };
  }, [dealRoom, mfUser?.email]);

  // Render side panel content based on selected tab
  const sidePanelContent = useMemo(() => {
    if (!organizationSlug || !dealRoomId || !currentArtifactId) {
      return null;
    }

    switch (sidePanelTab) {
      case 'ask-ai':
        return (
          <DealRoomArtifactAssistantPanel
            key={currentArtifactId}
            organizationSlug={organizationSlug!}
            dealRoomId={dealRoomId}
            artifactId={currentArtifactId}
          />
        );
      case 'comment':
        return (
          <DealRoomArtifactCommentsPanel
            ref={dealRoomPanelRef}
            organizationSlug={organizationSlug!}
            dealRoomId={dealRoomId}
            artifactId={currentArtifactId}
            currentThreadId={currentThreadId}
            setCurrentThreadId={(convId: number) => setCurrentThreadId(convId)}
            currentContactId={currentContactId}
            currentContactCompanyId={currentContactCompanyId}
          />
        );
    }
  }, [
    organizationSlug,
    dealRoomId,
    currentArtifactId,
    sidePanelTab,
    currentThreadId,
    currentContactId,
    currentContactCompanyId,
  ]);

  const previewArtifact = useMemo(() => {
    if (!artifact || !organizationSlug || !dealRoomId) return null;

    return (
      <ArtifactPreview
        artifact={artifact}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
      />
    );
  }, [artifact, organizationSlug, dealRoomId]);

  // Component rendering
  if (artifactLoading) return <Spinner />;
  if (!organizationSlug || !dealRoomId || !currentArtifactId || !artifact)
    return null;

  const askAICommentsContainer = (
    <SplitViewLayout
      rootClassName={styles.sidebarRootLayout}
      layoutName="DealRoomArtifactDetailViewSidePanel"
      header={
        currentThreadId === undefined ? (
          <DSTabs
            className={styles.pivotContainer}
            value={sidePanelTab}
            onChange={(_, value) =>
              setSidePanelTab(value as 'ask-ai' | 'comment')
            }
          >
            <DSTab value="ask-ai" label="Ask AI" panel={null} />
            <DSTab value="comment" label="Comments" panel={null} />
          </DSTabs>
        ) : (
          <div className="backToConvsContainer">
            <IconButton
              onClick={() => {
                if (dealRoomPanelRef?.current?.backToConvList) {
                  dealRoomPanelRef.current.backToConvList();
                }
              }}
              iconProps={BackIconProps}
            />
            <Text className="backToConvsText">Thread</Text>
          </div>
        )
      }
    >
      {sidePanelContent}
    </SplitViewLayout>
  );

  return (
    <div className={styles.wrapper}>
      <ArtifactsSidebar
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        artifactId={currentArtifactId}
        onArtifactSelect={handleArtifactSelect}
      />
      <div className={styles.viewContainer}>
        <SplitViewLayout
          rootClassName={styles.splitViewRootLayout}
          layoutName="DealRoomArtifactDetailView"
          fullWidthHeader
          header={
            <ArtifactTitle
              title={title}
              isEditingTitle={isEditingTitle}
              onStartEditTitle={() => setIsEditingTitle(true)}
              onBlurTitle={handleOnBlurName}
              onChangeTitle={setTitle}
              description={description}
              isEditingDescription={isEditingDescription}
              onStartEditDescription={() => setIsEditingDescription(true)}
              onBlurDescription={handleOnBlurDescription}
              onChangeDescription={setDescription}
              artifact={artifact}
            />
          }
          sidebar={!isMobile && askAICommentsContainer}
          contentClassName={styles.content}
          sidebarWidthPercent={35}
          gap="1rem"
          footer={isMobile && askAICommentsContainer}
        >
          <>
            <div className="content-title">
              <ArtifactMetadata artifact={artifact} />
              <ArtifactActions
                artifact={artifact}
                dealRoomRole={dealRoomRole}
                onFeature={handleOnFeature}
                onDownload={handleOnDownloadArtifact}
                // onShare={handleOnShare}
                onUpvote={() => handleOnVote(true)}
                onDownvote={() => handleOnVote(false)}
              />
            </div>
            {previewArtifact}
          </>
        </SplitViewLayout>
        {confirmShareDialog}
      </div>
    </div>
  );
};
