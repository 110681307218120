import { useMemo } from 'react';
import { useParams } from 'react-router';

export const useDealRoomArtifactId = () => {
  const { artifactId } = useParams<{ artifactId: string }>();
  if (!artifactId) {
    return undefined;
  }

  const parsedArtifactId = parseInt(artifactId, 10);

  if (isNaN(parsedArtifactId)) {
    return undefined;
  }

  return parsedArtifactId;
};
