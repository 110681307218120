import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { MeetingPlanTemplatePromptType, MeetingPlanTemplatePrompt } from '@meetingflow/common/Api/data-contracts';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TemplatesApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { AsyncPrimaryButton } from '../../../HOC/AsyncButton';
import { BaseModal } from '../../../MeetingPlans/Dialogs/BaseModal';
import { useAuth0 } from '@auth0/auth0-react';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';

// Style configurations for the component
const styles = {
  container: mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '0rem',
  }),
  promptList: mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }),
  promptItem: mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    padding: '1rem',
    borderRadius: '4px',
  }),
  promptHeader: mergeStyles({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  promptActions: mergeStyles({
    display: 'flex',
    gap: '0.5rem',
  }),
  addPromptButton: mergeStyles({
    marginTop: '1rem',
  }),
  editor: mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    borderRadius: '4px',
  }),
  openButton: mergeStyles({

  }),
};

const promptTypeOptions: IDropdownOption[] = ['CHAT', 'EDITOR'].map(
  (type) => ({
    key: type,
    text: type.charAt(0) + type.slice(1).toLowerCase(),
  })
);

interface TemplatePromptEditorProps {
  organizationSlug: string;
  templateId: number;
}

export const TemplatePromptEditor: React.FC<TemplatePromptEditorProps> = ({
  organizationSlug,
  templateId,
}: TemplatePromptEditorProps) => {
  // Initialize auth and theme hooks
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();
  const queryClient = useQueryClient();

  // State management for modal and editing
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newPrompt, setNewPrompt] = useState<{
    label: string;
    prompt: string;
    promptType: MeetingPlanTemplatePromptType;
  }>({
    label: '',
    prompt: '',
    promptType: 'CHAT',
  });
  const [editingPrompt, setEditingPrompt] = useState<MeetingPlanTemplatePrompt | null>(null);

  // Style configurations for form elements based on theme
  const textFieldStyles = useMemo(() => ({
    fieldGroup: {},
    label: {
      color: isDark ? '#ffffff' : '#323130'
    }
  }), [isDark]);

  const dropdownStyles = useMemo(() => ({
    label: {
      color: isDark ? '#ffffff' : '#323130'
    }
  }), [isDark]);

  const themeStyles = useMemo(() => ({
    promptItem: mergeStyles(styles.promptItem, {
      backgroundColor: isDark ? MEETINGFLOW_COLORS.purpleMediumDarker : MEETINGFLOW_COLORS.purpleLighter,
    }),
    editor: mergeStyles(styles.editor, {
      backgroundColor: isDark ? MEETINGFLOW_COLORS.purpleMediumDarker : MEETINGFLOW_COLORS.purpleLighter,
    }),
    openButton: mergeStyles(styles.openButton, {
      backgroundColor: isDark ? MEETINGFLOW_COLORS.tealDark : MEETINGFLOW_COLORS.tealMedium,
      borderColor: isDark ? MEETINGFLOW_COLORS.tealDark : MEETINGFLOW_COLORS.tealMedium,
      color: `${MEETINGFLOW_COLORS.white} !important`,
      '&:hover': {
        backgroundColor: isDark ? MEETINGFLOW_COLORS.tealDarker : MEETINGFLOW_COLORS.tealDark,
      },
    }),
  }), [isDark]);

  // Fetch template data using React Query
  const { data: templateData, isLoading, refetch } = useQuery(
    ['template', organizationSlug, templateId],
    async () => {
      const token = await getAccessTokenSilently();
      return TemplatesApiClient.getTemplate(
        organizationSlug,
        templateId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      enabled: !!organizationSlug && !!templateId,
    }
  );

  const prompts = templateData?.data.prompts;

  const createPromptMutation = useMutation(
    async (data: {
      label: string;
      prompt: string;
      promptType: MeetingPlanTemplatePromptType;
    }) => {
      const token = await getAccessTokenSilently();
      return TemplatesApiClient.postPrompt(
        organizationSlug,
        templateId,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: async () => {
        await refetch();
        queryClient.invalidateQueries(['template', organizationSlug, templateId]);
        toast.success('Prompt created successfully');
        setIsEditing(false);
        setNewPrompt({
          label: '',
          prompt: '',
          promptType: 'CHAT',
        });
      },
    }
  );

  // Mutation for updating existing prompts
  const updatePrompt = useMutation(
    async (data: {
      id: number;
      label: string;
      prompt: string;
      promptType: MeetingPlanTemplatePromptType;
    }) => {
      const token = await getAccessTokenSilently();
      return TemplatesApiClient.patchPrompt(
        organizationSlug,
        templateId,
        data.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: async () => {
        await refetch();
        queryClient.invalidateQueries(['template', organizationSlug, templateId]);
        toast.success('Prompt updated successfully');
        setIsEditing(false);
        setEditingPrompt(null);
        setNewPrompt({
          label: '',
          prompt: '',
          promptType: 'CHAT',
        });
      },
    }
  );

  // Mutation for deleting prompts
  const deletePrompt = useMutation(
    async (promptId: number) => {
      const token = await getAccessTokenSilently();
      return TemplatesApiClient.deletePrompt(
        organizationSlug,
        templateId,
        promptId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: async () => {
        await refetch();
        queryClient.invalidateQueries(['template', organizationSlug, templateId]);
        toast.success('Prompt deleted successfully');
      },
    }
  );

  // Handler for saving prompts (both new and edited)
  const handleSave = useCallback(async () => {
    try {
      if (editingPrompt) {
        await updatePrompt.mutateAsync(editingPrompt);
      } else {
        await createPromptMutation.mutateAsync(newPrompt);
      }
    } catch (error) {
      toast.error('Failed to save prompt');
      console.error('Error saving prompt:', error);
    }
  }, [editingPrompt, newPrompt, createPromptMutation, updatePrompt]);

  // Handler for editing existing prompts
  const handleEdit = useCallback((prompt: MeetingPlanTemplatePrompt) => {
    setIsEditing(true);
    setEditingPrompt(prompt);
    setNewPrompt({
      label: '',
      prompt: '',
      promptType: 'CHAT',
    });
  }, []);

  // Handler for deleting prompts with confirmation
  const handleDelete = useCallback(async (promptId: number) => {
    if (window.confirm('Are you sure you want to delete this prompt?')) {
      try {
        await deletePrompt.mutateAsync(promptId);
      } catch (error) {
        toast.error('Failed to delete prompt');
        console.error('Error deleting prompt:', error);
      }
    }
  }, [deletePrompt]);

  // Handler for canceling edit/create operations
  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setEditingPrompt(null);
    setNewPrompt({
      label: '',
      prompt: '',
      promptType: 'CHAT',
    });
  }, []);

  // Handler for modal dismissal
  const handleDismiss = useCallback(() => {
    setIsModalOpen(false);
    handleCancel();
  }, [handleCancel]);

  // Loading state handler
  if (isLoading || !(templateData?.data)) {
    return <div>Loading...</div>;
  }

  // Component render
  return (
    <>
      {/* Button to open the template prompt editor modal */}
      <DefaultButton
        className={themeStyles.openButton}
        onClick={() => setIsModalOpen(true)}
        iconProps={{ iconName: 'Add' }}
        style={{
          width: '250px',
        }}
      >
        Manage Prompts
      </DefaultButton>

      {/* Modal for editing template prompts */}
      <BaseModal
        isOpen={isModalOpen}
        onDismiss={handleDismiss}
        title={`'${templateData?.data.name}' Prompts`}
        modalWidth="800px"
      >
        <div className={styles.container}>
          {/* List view of existing prompts */}
          {!isEditing && (
            <>
              <div className={styles.promptList}>
                {prompts?.map((prompt: MeetingPlanTemplatePrompt) => (
                  <div key={prompt.id} className={themeStyles.promptItem}>
                    {/* Prompt header with title and action buttons */}
                    <div className={styles.promptHeader}>
                      <h3>{prompt.label}</h3>
                      <div className={styles.promptActions}>
                        <DefaultButton
                          onClick={() => handleEdit(prompt)}
                          iconProps={{ iconName: 'Edit' }}
                        >
                          Edit
                        </DefaultButton>
                        <DefaultButton
                          onClick={() => handleDelete(prompt.id)}
                          iconProps={{ iconName: 'Delete' }}
                        >
                          Delete
                        </DefaultButton>
                      </div>
                    </div>
                    {/* Prompt content with truncation */}
                    <p>{prompt.prompt.length > 650 ? `${prompt.prompt.slice(0, 650)}...` : prompt.prompt}</p>
                    {/* Display prompt type with proper capitalization */}
                    <span>Type: {prompt.promptType.charAt(0) + prompt.promptType.slice(1).toLowerCase()}</span>
                  </div>
                ))}
              </div>
              {/* Button to add new prompt */}
              <DefaultButton
                className={styles.addPromptButton}
                onClick={() => setIsEditing(true)}
                iconProps={{ iconName: 'Add' }}
              >
                Add Prompt
              </DefaultButton>
            </>
          )}

          {/* Edit/Create prompt form */}
          {isEditing && (
            <div className={themeStyles.editor}>
              {/* Label input field */}
              <TextField
                label="Label"
                styles={textFieldStyles}
                value={editingPrompt ? editingPrompt.label : newPrompt.label}
                onChange={(_, value) =>
                  editingPrompt
                    ? setEditingPrompt(prev => prev ? { ...prev, label: value || '' } : null)
                    : setNewPrompt(prev => ({ ...prev, label: value || '' }))
                }
                required
              />
              {/* Prompt content input field */}
              <TextField
                label="Prompt"
                styles={textFieldStyles}
                multiline
                rows={4}
                value={editingPrompt ? editingPrompt.prompt : newPrompt.prompt}
                onChange={(_, value) =>
                  editingPrompt
                    ? setEditingPrompt(prev => prev ? { ...prev, prompt: value || '' } : null)
                    : setNewPrompt(prev => ({ ...prev, prompt: value || '' }))
                }
                required
              />
              {/* Prompt type dropdown */}
              <Dropdown
                label="Prompt Type"
                styles={dropdownStyles}
                selectedKey={editingPrompt ? editingPrompt.promptType : newPrompt.promptType}
                options={promptTypeOptions}
                onChange={(_, option) =>
                  editingPrompt
                    ? setEditingPrompt(prev => prev ? { ...prev, promptType: option?.key as MeetingPlanTemplatePromptType } : null)
                    : setNewPrompt(prev => ({ ...prev, promptType: option?.key as MeetingPlanTemplatePromptType }))
                }
              />
              {/* Action buttons for saving/canceling */}
              <div className={styles.promptActions}>
                <AsyncPrimaryButton
                  onClick={handleSave}
                  disabled={editingPrompt
                    ? !editingPrompt.label || !editingPrompt.prompt
                    : !newPrompt.label || !newPrompt.prompt
                  }
                >
                  {editingPrompt ? 'Update' : 'Create'}
                </AsyncPrimaryButton>
                <DefaultButton onClick={handleCancel}>Cancel</DefaultButton>
              </div>
            </div>
          )}
        </div>
      </BaseModal>
    </>
  );
};

export default TemplatePromptEditor;
