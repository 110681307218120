import { MeetingflowSummaryType } from '@meetingflow/common/Api/data-contracts';
import { useMemo } from 'react';
import { Descendant } from 'slate';
import { v4 } from 'uuid';
import { SummarizeMeetingflowDialog } from '../../Components/MeetingPlans/Dialogs/SummarizeMeetingflowDialog';
import { useDeferredPromise } from '../useDeferredPromise';

export type SummarizeMeetingflowResult = {
  meetingflowType: string;
  summaryType: MeetingflowSummaryType | 'CUSTOM';
  summary: string;
  summaryHtml?: string;
  summarySlateNodes?: Descendant[];
};

export type SummarizeMeetingflowOptions = {
  organizationSlug: string;
  meetingflowId: string;
  title?: string;
};

export type SummarizeMeetingflowDeferredOptions = {
  summaryType: MeetingflowSummaryType | 'CUSTOM';
  prompt?: string;
  title?: string;
};

export const useSummarizeMeetingflowDialog = ({
  organizationSlug,
  meetingflowId,
}: SummarizeMeetingflowOptions) => {
  const { createDeferred, resolve, reject, deferred } = useDeferredPromise<
    SummarizeMeetingflowResult,
    SummarizeMeetingflowDeferredOptions
  >();

  const dialog = useMemo(() => {
    if (!deferred || !deferred.isPending) {
      return null;
    }

    let title = 'Summarize Meetingflow';
    switch (deferred.context.summaryType) {
      case 'MEETING_SUMMARY': {
        title = 'Draft Summary';
        break;
      }
      case 'PRE_MEETING_EMAIL': {
        title = 'Draft pre-meeting email';
        break;
      }
      case 'FOLLOWUP_EMAIL': {
        title = 'Draft followup email';
        break;
      }
      case 'REENGAGEMENT_EMAIL': {
        title = 'Draft reengagement email';
        break;
      }
      case 'SUGGESTED_NOTES': {
        title = 'Draft meeting notes';
        break;
      }
      case 'SLACK_UPDATE': {
        title = 'Draft Slack update';
        break;
      }
      case 'TEAMS_UPDATE': {
        title = 'Draft Microsoft Teams update';
        break;
      }
      case 'HUBSPOT_SUMMARY': {
        title = 'Draft HubSpot meeting summary';
        break;
      }
      case 'SALESFORCE_SUMMARY': {
        title = 'Draft Salesforce meeting summary';
        break;
      }
    }

    return (
      <SummarizeMeetingflowDialog
        key={v4()}
        summaryType={deferred.context.summaryType}
        title={title}
        prompt={deferred.context.prompt}
        organizationSlug={organizationSlug}
        meetingflowId={meetingflowId}
        onAccept={({
          meetingflowType,
          summary,
          summaryHtml,
          summarySlateNodes,
        }) => {
          resolve({
            summaryType: deferred.context.summaryType,
            meetingflowType,
            summary,
            summaryHtml,
            summarySlateNodes,
          });
        }}
        onDismiss={(reason) => reject(reason)}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deferred,
    deferred?.isPending,
    organizationSlug,
    meetingflowId,
    reject,
    resolve,
  ]);

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};

export default useSummarizeMeetingflowDialog;
