import { styled, Button } from '@mui/material';

export const DSButtonRoot = styled(Button)(({ theme, size }) => ({
    fontWeight: theme.typography?.fontWeightRegular,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.25rem',
    lineHeight: 1,
    minWidth: 'unset',
    '&.MuiButton-root': {
        ...(size === 'small' && {
            fontSize: '0.8125rem',
            height: '24px',
            minHeight: '24px',
            maxHeight: '24px',
            padding: '4px 8px',
        }),
        ...(size === 'medium' && {
            fontSize: '0.875rem',
            height: '32px',
            minHeight: '32px',
            maxHeight: '32px',
            padding: '4px 12px',
        }),
        ...(size === 'large' && {
            fontSize: '0.9375rem',
            height: '44px',
            minHeight: '44px',
            maxHeight: '44px',
            padding: '8px 16px',
        }),
    }
}));
