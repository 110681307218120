import { useAuth0 } from '@auth0/auth0-react';
import {
  DefaultButton,
  ITextFieldStyles,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Resource,
  SlateDescendant,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { Descendant } from 'slate';
import * as yup from 'yup';
import { useConfirmationDialog } from '../../../../Hooks/Modals/useConfirmationDialog';
import { useLinkDialog } from '../../../../Hooks/Modals/useLinkDialog';
import { useAddIntegrationModal } from '../../../../Hooks/useAddIntegrationModal';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import {
  OrganizationTemplateQuery,
  OrganizationTemplatesQuery,
} from '../../../../QueryNames';
import { TemplatesApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { OrganizationSlugRouteParams } from '../../../../types/RouteParams';
import EditorFrame from '../../../Collab/EditorFrame';
import { getCoreEditor } from '../../../Collab/Helpers/EditorHelpers';
import {
  AsyncDefaultButton,
  AsyncPrimaryButton,
} from '../../../HOC/AsyncButton';
import { MeetingPlanResources } from '../../../MeetingPlans/Sections/MeetingPlanResources';
import { TemplatePromptEditor } from './TemplatePromptEditor';

const defaultEditorState = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
] as Descendant[];

export type MeetingPlanTemplateForm = {
  name: string;
  description: string;
};

const formSchema = yup
  .object({
    name: yup.string().required(),
    description: yup.string().optional(),
  })
  .required();

export const TemplatePreview = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const client = useQueryClient();
  const appInsights = useAppInsightsContext();
  const { organizationSlug, objectId } = useParams<
    OrganizationSlugRouteParams & {
      objectId?: string;
    }
  >();

  const { createDeferred: createLinkDialogDeferred, dialog: linkDialog } =
    useLinkDialog();

  const {
    createDeferred: createAddIntegrationDeferred,
    dialog: addIntegrationDialog,
  } = useAddIntegrationModal();

  const creatingNew = objectId?.toLowerCase() === 'new';

  const { data: templateData, refetch: refetchTemplate } = useQuery(
    OrganizationTemplateQuery(organizationSlug!, objectId!),
    async () => {
      const token = await getAccessTokenSilently();
      return TemplatesApiClient.getTemplate(
        organizationSlug!,
        parseInt(objectId!),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!objectId && !creatingNew,
    },
  );

  const template = templateData?.data;

  const [newResources, setNewResources] = useState<Resource[] | undefined>(
    creatingNew ? [] : undefined,
  );
  const [newNotes, setNewNotes] = useState<Descendant[] | undefined>(
    creatingNew ? defaultEditorState : undefined,
  );

  const { isDark } = useLightOrDarkMode();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors },
  } = useForm<MeetingPlanTemplateForm>({
    resolver: yupResolver(formSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    if (template) {
      reset();
    }
  }, [template, reset]);

  const onSubmit = handleSubmit(async (data) => {
    if (!isValid) {
      return;
    }

    if (creatingNew) {
      const token = await getAccessTokenSilently();
      const result = await TemplatesApiClient.postTemplate(
        organizationSlug!,
        {
          name: data.name,
          description: data.description,
          resources: newResources,
          notes: newNotes as SlateDescendant[] | null | undefined,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (result.status === 201) {
        toast.success('Template created');

        if (data.name !== template?.name) {
          client.invalidateQueries(
            OrganizationTemplatesQuery(organizationSlug!),
          );
        }

        setEditingMode(false);

        appInsights.trackEvent({
          name: 'CREATE_TEMPLATE',
          properties: {
            organizationSlug,
            templateId: result.data.id,
          },
        });

        navigate(
          `/organization/${organizationSlug}/library/templates/${result.data.id}`,
        );
      } else {
        toast.error(
          'Something went wrong saving new template, try again later',
        );
      }
    } else {
      const token = await getAccessTokenSilently();
      const result = await TemplatesApiClient.putTemplate(
        organizationSlug!,
        parseInt(objectId!),
        {
          name: data.name,
          description: data.description,
          resources: newResources,
          notes: newNotes as SlateDescendant[] | null | undefined,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (result.status === 200) {
        toast.success('Template updated');

        if (data.name !== template?.name) {
          client.invalidateQueries(
            OrganizationTemplatesQuery(organizationSlug!),
          );
        }

        setEditingMode(false);

        refetchTemplate();

        appInsights.trackEvent({
          name: 'UPDATE_TEMPLATE',
          properties: {
            organizationSlug,
            objectId,
          },
        });
      } else {
        toast.error('Something went wrong saving template, try again later');
      }
    }
  });

  const {
    createDeferred: createConfirmDeleteTemplateDeferrred,
    dialog: confirmDeleteTemplateDialog,
  } = useConfirmationDialog({
    title: `Delete template?`,
    subText:
      'Deleting this template will delete it for all users.  Are you sure you want to delete?',
    primaryAction: 'CONFIRM',
  });

  const isEditing =
    !template || newResources !== undefined || newNotes !== undefined;

  useEffect(() => {
    if (!creatingNew) {
      refetchTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, objectId]);

  const setEditingMode = useCallback(
    (edit?: boolean) => {
      if (edit === true || (edit === undefined && !isEditing)) {
        !newResources && setNewResources(template?.resources || []);
        !newNotes && setNewNotes(template?.notes || defaultEditorState);
      } else {
        reset();
        setNewNotes(undefined);
        setNewResources(undefined);
      }
    },
    [
      isEditing,
      newNotes,
      newResources,
      reset,
      template?.notes,
      template?.resources,
    ],
  );

  const setDescendents = useCallback(
    (objectives: Descendant[] | undefined) => {
      if (!isEditing) {
        setEditingMode(true);
      }

      setNewNotes(objectives);
    },
    [isEditing, setEditingMode],
  );

  const notesEditor = useMemo(
    () =>
      getCoreEditor(
        getAccessTokenSilently,
        organizationSlug!,
        createLinkDialogDeferred,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getAccessTokenSilently,
      organizationSlug,
      createLinkDialogDeferred,
      isEditing,
    ],
  );

  useEffect(() => {
    notesEditor.children = cloneDeep(template?.notes || defaultEditorState);
  }, [notesEditor, template, isEditing]);

  if (!creatingNew && !template) {
    return <div></div>;
  }

  const saveFooterHeight = '48px';
  const editorHeight = `calc(100vh - ${saveFooterHeight} - 120px)`;

  const meetingPlanPreviewStyles = mergeStyles({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    '.template-preview-header': {
      backgroundColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      rowGap: '.5rem',
      columnGap: '1rem',
      flexBasis: '48px',
      boxSizing: 'border-box',
      padding: '0 0 .5rem 0',

      '.template-preview-actions': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '#meetingflow-resources': {
          position: 'relative',
          top: '0',
          right: '.5rem',
          zIndex: '5000',
        },

        '> div:last-child': {
          top: '.5rem',
          position: 'relative',
        },
      },
    },

    '.template-preview-editor': {
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
        : MEETINGFLOW_COLORS.white,
      position: 'relative',
      maxHeight: editorHeight,
      overflow: 'hidden',
    },

    '.template-preview-save': {
      backgroundColor: 'transparent',
      flexBasis: saveFooterHeight,
      minHeight: saveFooterHeight,
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      columnGap: '.5rem',
      padding: '.5rem 0',
      boxSizing: 'border-box',
    },
  });

  const textFieldStyles = {
    root: {
      minWidth: '15rem',
    },
  } as Partial<ITextFieldStyles>;

  const meetingPlanControls = (
    <>
      <AsyncDefaultButton
        disabled={creatingNew}
        iconProps={{
          iconName: 'Delete',
        }}
        onClick={async () => {
          try {
            const shouldDelete =
              await createConfirmDeleteTemplateDeferrred().promise;

            if (!shouldDelete) {
              return;
            }

            const token = await getAccessTokenSilently();

            await toast.promise(
              TemplatesApiClient.deleteTemplate(
                organizationSlug!,
                parseInt(objectId!),
                {
                  headers: { Authorization: `Bearer ${token}` },
                },
              ),
              {
                loading: 'Deleting template',
                success: (result) => {
                  if (result.status === 204) {
                    client.invalidateQueries(
                      OrganizationTemplatesQuery(organizationSlug!),
                    );
                    client.invalidateQueries(
                      OrganizationTemplateQuery(organizationSlug!, objectId!),
                    );

                    appInsights.trackEvent({
                      name: 'DELETE_TEMPLATE',
                      properties: {
                        organizationSlug,
                        objectId,
                      },
                    });

                    navigate(
                      `/organization/${organizationSlug}/library/templates`,
                    );
                  }
                  return 'Template deleted';
                },
                error: (err: unknown) => {
                  return 'Something went wrong deleting template';
                },
              },
            );
          } catch (err: unknown) { }
        }}
      >
        Delete
      </AsyncDefaultButton>
      {isEditing ? (
        <>
          <DefaultButton
            disabled={!isEditing}
            iconProps={{
              iconName: 'Cancel',
            }}
            onClick={() => {
              if (objectId === 'new') {
                navigate(`/organization/${organizationSlug}/library/templates`);
              }
              setEditingMode(false);
            }}
          >
            Cancel
          </DefaultButton>
          <AsyncPrimaryButton
            disabled={!isEditing || !isValid}
            iconProps={{
              iconName: 'Save',
            }}
            onClick={onSubmit}
          >
            Save
          </AsyncPrimaryButton>
        </>
      ) : (
        <DefaultButton
          iconProps={{
            iconName: 'Edit',
          }}
          primary
          disabled={isEditing}
          onClick={() => setEditingMode(true)}
        >
          Edit
        </DefaultButton>
      )}
    </>
  );

  return (
    <>
      <div className={meetingPlanPreviewStyles}>
        <div className="template-preview-header">
          <Controller
            name="name"
            control={control}
            defaultValue={template?.name}
            render={({ field: { value, onBlur, onChange } }) => (
              <TextField
                placeholder="Template Name"
                required
                value={value}
                onChange={(_e, newValue) => onChange(newValue || '')}
                onBlur={onBlur}
                errorMessage={errors.name?.message}
                disabled={!isEditing}
                styles={textFieldStyles}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            defaultValue={template?.description}
            render={({ field: { value, onBlur, onChange } }) => (
              <TextField
                placeholder="Template Description"
                errorMessage={errors.description?.message}
                value={value}
                onChange={(_e, newValue) => onChange(newValue || '')}
                onBlur={onBlur}
                disabled={!isEditing}
                styles={textFieldStyles}
              />
            )}
          />

          <div className="template-preview-actions">
            <MeetingPlanResources
              organizationSlug={organizationSlug!}
              showAdd={true}
              resources={newResources || template?.resources || []}
              onAdd={(item) => {
                setNewResources([...(newResources || []), item]);
              }}
              onUpdate={(idx, props) => { }}
              onDelete={(idx) => {
                setNewResources(
                  newResources?.filter((r, i) => i !== idx) || [],
                );
              }}
            />
            {template && (
              <TemplatePromptEditor
                organizationSlug={organizationSlug!}
                templateId={template.id}
              />
            )}
          </div>
        </div>

        <div className="meeting-plan-section template-preview-editor">
          {isEditing || template?.notes?.length ? (
            <EditorFrame
              key={notesEditor.id}
              name="TemplatePreviewNotes"
              organizationSlug={organizationSlug!}
              placeholder="Enter your meeting notes here"
              editor={notesEditor}
              value={newNotes || template?.notes || defaultEditorState}
              onChange={setDescendents}
              readonly={!isEditing}
              additionalEventProps={{
                organizationSlug,
                objectId,
              }}
              showAutoSaveIndicator={false}
              allowTags
              allowMentions
              showPlaceholderHint
              backgroundColor={'transparent'}
              editorMaxHeight={editorHeight}
              setPanelContext={() => { }}
              createAddIntegrationDeferred={createAddIntegrationDeferred}
            />
          ) : null}
        </div>

        <div className="template-preview-save">{meetingPlanControls}</div>
      </div>

      {confirmDeleteTemplateDialog}
      {linkDialog}
      {addIntegrationDialog}
    </>
  );
};
