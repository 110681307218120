import { memo } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

type DocViewerMemoizedWrapperProps = {
  fileUrl: string;
};

export const DocViewerMemoizedWrapper = memo(
  ({ fileUrl = '' }: DocViewerMemoizedWrapperProps) => {
    return (
      <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={[{ uri: fileUrl }]}
        config={{
          header: { disableHeader: true },
          pdfVerticalScrollByDefault: true,
        }}
      />
    );
  },
);
