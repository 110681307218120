import React from 'react';
import { Videocam } from '@mui/icons-material';
import { DSTooltip } from '../../DealRoom/DS/DSTooltip';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { JoinConferenceButton } from '../DecisionSiteMeetingflowCard.styles';
import { DetailedMeetingflow } from '@meetingflow/common/Api/data-contracts';
import { titleCase } from '@meetingflow/common/StringHelpers';

interface Props {
  meetingflow: DetailedMeetingflow;
  onConferenceIconClick: (e: React.MouseEvent) => void;
  mini?: boolean;
}

const FullJoinConferenceButton = styled(Button)({
  height: '26px',
  padding: '0 1rem',
  borderRadius: '.25rem',
  backgroundColor: DEALROOMS_COLORS.peacock,
  color: DEALROOMS_COLORS.white,
  '&:hover': {
    backgroundColor: DEALROOMS_COLORS.woodsmoke,
  },
  '& .MuiSvgIcon-root': {
    height: '16px',
    width: '16px',
    marginRight: '8px',
  },
});

export const DecisionSiteMeetingflowCardJoinConferenceIcon: React.FC<Props> = ({
  meetingflow,
  onConferenceIconClick,
  mini = true,
}) => {
  if (!meetingflow?.conferenceInfo) {
    return null;
  }

  if (mini) {
    return (
      <DSTooltip
        title={`Join ${titleCase(meetingflow?.conferenceInfo?.type || 'Online')} Meeting`}
      >
        <JoinConferenceButton onClick={onConferenceIconClick}>
          <Videocam
            sx={{
              height: '16px',
              width: '16px',
            }}
          />
        </JoinConferenceButton>
      </DSTooltip>
    );
  }

  return (
    <DSTooltip title="Join Conference">
      <FullJoinConferenceButton onClick={onConferenceIconClick}>
        <Videocam />
        Join
      </FullJoinConferenceButton>
    </DSTooltip>
  );
};
